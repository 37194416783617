import { connect } from "react-redux"

// Functions
import * as ComponentFunctions from './SettlementGrig.Functions';

// Models
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";

// styles
import './SettlementGrid.Styles.css';
import { Label } from "@fluentui/react";

const SettlementGridComponent = (props: ComponentFunctions.Props) => {

    return (
        <div className="TableContainer adjusted">
            <table className="SettlemementGrid">
                <thead>
                    <tr>
                        <th style={{ minWidth: '4em' }}><Label>Nro de paciente en el protocolo</Label></th>
                        {props.Stages?.filter((s?: string) => s !== null && s !== null).filter((stage: string) => stage?.length > 0)?.map((stage: string) =>
                            <th key={stage}><Label>{stage}</Label></th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.Patients?.filter((patient: string) => patient?.length > 0)?.map((patient: string) => (
                        <tr key={patient}>
                            <td style={{ minWidth: '5em' }}><Label>{patient}</Label></td>
                            {props.Stages?.filter((stage: string) => stage?.length > 0)?.map((stage: string) => (
                                <td key={stage}>{ComponentFunctions.GetPatientStageCurrencies(props, patient, stage)?.map((currency: string) =>
                                    <Label>
                                        {props.SettlementDetails?.filter((detail: SettlementDetail) => 
                                            detail.patientNumberInProtocol === patient 
                                            && detail.contractStageName === stage 
                                            && detail.currency === currency 
                                            && detail.isExcluded === false
                                        )?.map((detail: SettlementDetail) => 
                                            (detail.conceptValue?.reduce(
                                                (acc, item) => acc + item.value, 0
                                            ) || 0) + (detail.value || 0)
                                        )?.reduce((a, b) => a + b, 0).toLocaleString('es-AR', { style: 'currency', currency: currency.toUpperCase() }) || '0.00'}
                                    </Label>)}</td>
                            ))}

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )

}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementGridComponent as any);