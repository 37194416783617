import { NewAgreementServiceDetail } from '../../models/Entities/AgreementServices/NewAgreementServiceDetail';
import { AgreementServicesDetail } from '../../models/Entities/AgreementServices/AgreementServicesDetail';
import { AppThunkAction } from '../reducers';
import { ErrorType } from '../../models/HttpError';

// services
import * as Services from '../../services/AgreementServicesDetails';

// actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetAllByAgreementId_Request_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetAllByAgreementId_Receive_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetAllByAgreementId_Fail_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetById_Request_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetById_Receive_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetById_Fail_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Add_Request_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Add_Receive_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Add_Fail_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Update_Request_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Update_Receive_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Update_Fail_Action;

export const actionCreators = {
  GetAllAgreementServiceDetailByAgreementId:
    (agreementId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string = '';  

      if (state.oidc?.user) {
        token = state.oidc?.user?.access_token;
      } else {
        dispatch({
          type: 'AGREEMENT_SERVICESDETAIL_GET_ALL_BY_AGREEMENT_ID_FAIL_ACTION',
          error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: []}
        });
        return;
      }


      dispatch({
        type: 'AGREEMENT_SERVICESDETAIL_GET_ALL_BY_AGREEMENT_ID_REQUEST_ACTION',
        agreementId: agreementId
      });

      Services.GetServiceDetailsByAgreementId(agreementId, token)
        .then((services: AgreementServicesDetail[]) => {
          dispatch({
            type: 'AGREEMENT_SERVICESDETAIL_GET_ALL_BY_AGREEMENT_ID_RECEIVE_ACTION',
            agreementId: agreementId,
            agreementServicesDetails: services
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AGREEMENT_SERVICESDETAIL_GET_ALL_BY_AGREEMENT_ID_FAIL_ACTION',
            error: error
          })
        );
    },

  GetAgreementServiceDetailById:
    (AgreementServiceDetailId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'AGREEMENT_SERVICESDETAIL_GET_BY_ID_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'AGREEMENT_SERVICESDETAIL_GET_BY_ID_REQUEST_ACTION',
        agreementServicesDetailId: AgreementServiceDetailId
      });

      Services.GetAgreementServicesDetailsById(AgreementServiceDetailId, token)
        .then((service: AgreementServicesDetail) => {
          dispatch({
            type: 'AGREEMENT_SERVICESDETAIL_GET_BY_ID_RECEIVE_ACTION',
            agreementServicesDetail: service
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AGREEMENT_SERVICESDETAIL_GET_BY_ID_FAIL_ACTION',
            error: error
          })
        );
    },

  AddAgreementServiceDetail:
    (
      newAgreementServiceDetail: NewAgreementServiceDetail
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'AGREEMENT_SERVICESDETAIL_ADD_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'AGREEMENT_SERVICESDETAIL_ADD_REQUEST_ACTION',
        newAgreementServicesDetail: newAgreementServiceDetail
      });

      Services.CreateAgreementServicesDetail(newAgreementServiceDetail, token)
        .then((service: AgreementServicesDetail) => {
          dispatch({
            type: 'AGREEMENT_SERVICESDETAIL_ADD_RECEIVE_ACTION',
            agreementServicesDetail: service
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AGREEMENT_SERVICESDETAIL_ADD_FAIL_ACTION',
            error: error
          })
        );
    },

  UpdateAgreementServiceDetail:
    (
      agreementServiceDetail: AgreementServicesDetail
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'AGREEMENT_SERVICESDETAIL_UPDATE_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'AGREEMENT_SERVICESDETAIL_UPDATE_REQUEST_ACTION',
        agreementServicesDetail: agreementServiceDetail
      });

      Services.UpdateAgreementServicesDetail(agreementServiceDetail, token)
        .then((service: AgreementServicesDetail) => {
          dispatch({
            type: 'AGREEMENT_SERVICESDETAIL_UPDATE_RECEIVE_ACTION',
            agreementServicesDetail: service
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AGREEMENT_SERVICESDETAIL_UPDATE_FAIL_ACTION',
            error: error
          })
        );
    }
};
