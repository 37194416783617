import { Avatar, Button, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, Field, Input, Label, OverlayDrawer, Spinner, Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOption, TagPickerProps, Text, Textarea } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { MessageBarType } from "@fluentui/react";

// component functions
import * as ComponentFunctions from './Debt.Form.Functions';

// components
import { CurrencySelectorComponent } from "../../../../commons/component/Inputs/CurrencySelector/CurrencySelectorComponent";
import { DateTimeInput } from "../../../../commons/component/Inputs/DateTimeInput/DateTimeInput";
import PersonSelectorComponent from "../../../../commons/component/Inputs/PersonSelector/PersonSelector.Component";
import { MessageComponent } from "../../../../commons/component/MessageComponent";

// models
import { Person } from "../../../../models/Entities/Person/Person";
import { NewDebt } from "../../../../models/Entities/CashFlow/Debt/NewDebt";
import { Debt } from "../../../../models/Entities/CashFlow/Debt/Debt";

interface FormValidation {
    success: boolean;
    errors: string[];
}

const DebtFormComponent = (props: ComponentFunctions.Props) => {

    const styles = ComponentFunctions.useStyles();

    // debt attributes
    const [currency, setCurrency] = useState<string | undefined>();
    const [Creditor, setCreditor] = useState<Person | undefined>();
    const [Debtor, setDebtor] = useState<Person | undefined>();
    const [DebtAmount, setAmount] = useState<number>(0);
    const [DebtDate, setDebtDate] = useState<Date | undefined>(new Date());
    const [DebtComments, setComments] = useState<string>('');

    // form status
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    // if payment id is provided, get payment by id
    useEffect(() => {
        if (props.DebtId && !props.CurrentDebt) {
            props.GetDebtById(props.DebtId);
        }
    }, [props.DebtId]);

    useEffect(() => {
        setCreditor(props.DefaultCreditor);
        setDebtor(props.DeFaultDebtor);
        setDebtDate(new Date());
    }, [props.DefaultCreditor, props.DeFaultDebtor]);

    useEffect(() => {
        if (props.CurrentDebt) {
            if (props.CurrentDebt) {
                setCurrency(props.CurrentDebt.currencyCode);
                setCreditor(props.GetPersonByIdentifier(props.CurrentDebt.creditor));
                setDebtor(props.GetPersonByIdentifier(props.CurrentDebt.debtor));
                setAmount(props.CurrentDebt.originalAmount);
                setDebtDate(props.CurrentDebt.debtDate);
                setComments(props.CurrentDebt.comments || '');
            }
        }
    }, [props.CurrentDebt]);

    useEffect(() => {
        if (props.isSaved && submittedForm) {
            ResetForm();
            props.onClose();
        }
    }, [props.isSaved]);


    useEffect(() => {
        if (props.failOnSaving) {
            setSubmittedForm(false);
        }
    }, [props.failOnSaving]);

    // form validation

    const ValidateForm = (): boolean => {
        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        if (!currency) {
            FormValidation.errors.push("Debe seleccionar una moneda");
            validationResult = false;
        }

        if (!Creditor) {
            FormValidation.errors.push("Debe seleccionar un acreedor");
            validationResult = false;
        }

        if (!Debtor) {
            FormValidation.errors.push("Debe seleccionar un deudor");
            validationResult = false;
        }

        if (!DebtAmount || DebtAmount <= 0) {
            FormValidation.errors.push("Debe ingresar un monto válido");
            validationResult = false;
        }

        if (!DebtDate) {
            FormValidation.errors.push("Debe seleccionar una fecha");
            validationResult = false;
        }

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };

    // reset form
    const ResetForm = () => {
        setSubmittedForm(false);
        setCurrency(undefined);
        setCreditor(undefined);
        setDebtor(undefined);
        setAmount(0);
        setDebtDate(new Date());
        setComments('');
        setFormValidationStatus(undefined);
    };

    //events

    const HandleCancelForm = () => {
        ResetForm();
        props.onClose();
    };
    const HandleSubmitForm = () => {

        const auxPerson: Person = {
            personIdentifier: props.OwnerId,
            id: 0,
            isDeleted: false,
            numberOfMarriages: 0,
            personType: 0,
            businessName: "Centro Médico"
        };

        setSubmittedForm(true);

        if (ValidateForm()) {
            // submit form
            if (!props.CurrentDebt) {
                const newDebt: NewDebt = {
                    creditor: Creditor!.personIdentifier,
                    debtor: Debtor!.personIdentifier,
                    debtDate: DebtDate!,
                    originalAmount: DebtAmount!,
                    currencyCode: currency!,
                    comments: DebtComments
                };
                props.CreateDebt(newDebt)
            } else {
                const debt: Debt = {
                    ...props.CurrentDebt,
                    creditor: Creditor!.personIdentifier,
                    debtor: Debtor!.personIdentifier,
                    debtDate: DebtDate!,
                    originalAmount: DebtAmount!,
                    currencyCode: currency!,
                    comments: DebtComments

                };
                props.UpdateDebt(debt);
            }
        } else {
            setSubmittedForm(false);
        }
    };

    const CreditorContent = (
        <Tag media={<Avatar name={props.DefaultCreditor?.businessName} color="colorful" />}>
            {props.DefaultCreditor?.businessName}
        </Tag>
    );

    const DebtorContent = (
        <Tag media={<Avatar name={props.DeFaultDebtor?.businessName} color="colorful" />}>
            {props.DeFaultDebtor?.businessName}
        </Tag>
    );

    const CreditorPicker = (
        <PersonSelectorComponent
            personSelected={props.CurrentDebt ? [props.CurrentDebt?.creditor] : props.DefaultCreditor ? [props.DefaultCreditor.personIdentifier] : []}
            selectionLimit={1}
            isRequired={true}
            fieldLabel={"Acreedor"}
            CustomAditionalPersonsList={[{
                id: 0,
                businessName: 'Centro Médico',
                personIdentifier: props.OwnerId,
                numberOfMarriages: 0,
                personType: 0,
                isDeleted: false   
            } as Person]}
            onChangeSelection={(selectedPersons: Person[]) => setCreditor(selectedPersons[0])}
        />
    );

    const DebtorPicker = (
        <PersonSelectorComponent
            personSelected={props.CurrentDebt ? [props.CurrentDebt?.debtor] : props.DeFaultDebtor ? [props.DeFaultDebtor.personIdentifier] : []}
            selectionLimit={1}
            isRequired={true}
            fieldLabel={"Deudor"}
            CustomAditionalPersonsList={[{
                id: 0,
                businessName: 'Centro Médico',
                personIdentifier: props.OwnerId,
                numberOfMarriages: 0,
                personType: 0,
                isDeleted: false                
            } as Person]}
            onChangeSelection={(selectedPersons: Person[]) => setDebtor(selectedPersons[0])}
        />
    );

    const FormFields = (
        <div className={styles.content}>
            <div className={styles.content}>
                <Field>
                    {props.FormType === ComponentFunctions.FormType.Creditor ? CreditorPicker : DebtorPicker}
                </Field>
                <label>Acreedor: {props?.DefaultCreditor?.personIdentifier || 'no existe creditor'}</label>
                <label>Deudor: {props?.DeFaultDebtor?.personIdentifier || 'no existe debtor'}</label>
                <Field
                    validationState={((props.FormType === ComponentFunctions.FormType.Creditor && Creditor) || (props.FormType === ComponentFunctions.FormType.Debtor && Debtor)) ? "none" : "error"}
                    validationMessage={submittedForm ? undefined : undefined}
                >
                    {props.FormType === ComponentFunctions.FormType.Creditor ? DebtorPicker : CreditorPicker}
                </Field>
            </div>
            <CurrencySelectorComponent
                errorMessage={undefined}
                defaultSelected={currency}
                label="Moneda"
                isRequired={true}
                onCurrencyChange={(selectedCurrency: string | undefined) => setCurrency(selectedCurrency)}
            />
            <Field label={"Monto"} required>
                <Input
                    placeholder="monto del pago"
                    type="number"
                    id={'nmb_debtamount'}
                    value={DebtAmount?.toString()}
                    step={0.01}
                    contentAfter={
                        <Text id={'nmb_debtamount'}>
                            {currency}
                        </Text>
                    }
                    onChange={(e) => setAmount(parseFloat(e.currentTarget.value))}
                />
            </Field>
            <DateTimeInput label={"Fecha"} placeHolder={"Fecha"} selectedDate={DebtDate} onSelectDate={(date: Date | undefined) => setDebtDate(date)} isSubmitted={submittedForm} isRequired={true} errorMessage={undefined} />
            <Field label="Comentarios">
                <Textarea
                    value={DebtComments || ''}
                    onChange={(e) => setComments(e.currentTarget.value)}
                />
            </Field>
        </div>
    );

    const Footer = () => {
        return (
            <DrawerFooter>
                <Button onClick={HandleCancelForm} disabled={submittedForm}>Cancelar</Button>
                <Button appearance="primary" onClick={HandleSubmitForm} disabled={submittedForm}>{props.isSaving && <><Spinner size="extra-tiny" />&nbsp;</>}Guardar</Button>
            </DrawerFooter>
        );
    };

    return (
        <div>
            <OverlayDrawer
                position={'end'}
                open={props.isOpen}
            >
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label="Close"
                                icon={<Dismiss24Regular />}
                                onClick={() => HandleCancelForm()}
                            />
                        }
                    >
                        Información de pago
                    </DrawerHeaderTitle>
                    {FormValidationStatus?.success === false && <MessageComponent message={"Verifique la información suministrada"} type={MessageBarType.error} itemList={FormValidationStatus?.errors} />}
                    {props.failOnSaving === true && <MessageComponent message={props.error?.ErrorMessage || 'Ocurrio un error inesperado'} type={MessageBarType.error} itemList={props.error?.Errors} />}
                </DrawerHeader>

                <DrawerBody className='ScrollShadow'>
                    {props.DebtId ? props.CurrentDebt && FormFields : FormFields}
                </DrawerBody>
                <Footer />
            </OverlayDrawer>
        </div>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(DebtFormComponent as any);