import { IIconProps } from "@fluentui/react";
import { ErrorType } from "../../../models/HttpError";
import { Sponsor } from "../../../models/Entities/Sponsor/Sponsor";


// stores
import * as SponsorStore from '../../../redux/middleware/SponsorMiddleware';

//State
import { AppState } from "../../../redux/reducers";

//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };

interface IProps {
    SponsorId: number;
}

interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    error: ErrorType | undefined;

    // associate 
    isAssociating: boolean;
    isAssociatedSuccessfully: boolean;
    failOnAssociating: boolean;

    Sponsor: Sponsor;
}

interface MedicalServicesDispatchProps {
    GetAllSponsors: typeof SponsorStore.actionCreators.GetAllSponsors;
    GetSponsorById: typeof SponsorStore.actionCreators.GetSponsorById;
    AssociateContract: typeof SponsorStore.actionCreators.AssociateContract;
    GetAllContractAssociations: typeof SponsorStore.actionCreators.GetAllContractAssociations;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.Sponsor?.isLoadingAll,
    isUpdating: state.Sponsor?.isLoadingAll,
    isUpdatedSuccessfully: state.Sponsor?.successLoadingAll,
    isLoadingSuccessfully: state.Sponsor?.successLoadingAll,
    failOnUpdating: state.Sponsor?.failOnLoadingAll,
    failOnLoading: state.Sponsor?.failOnLoadingAll,
    isDeleting: state.Sponsor?.isDeletingOne,
    isDeletedSuccessfully: state.Sponsor?.successDeletingOne,
    failOnDeleting: state.Sponsor?.failOnDeletingOne,
    error: state.Sponsor?.error,
    MedicalProtocols: state.Sponsor?.list,

    // associate
    isAssociating: state.Sponsor?.isAssociatingContract,
    isAssociatedSuccessfully: state.Sponsor?.isAssociatingContractSuccess,
    failOnAssociating: state.Sponsor?.FailAssociatingContract,

    Sponsor: state.Sponsor?.list.find((sponsor: Sponsor) => sponsor.id === ownProps.SponsorId)
});

export const mapDispatchToProps = {
    ...SponsorStore.actionCreators
};