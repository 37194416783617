import React from 'react';
import { ITag, Label, TagPicker } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { AvailableMedicalService } from '../../../../models/Entities/MedicalServices/AvailableMedicalService';

// own functions
import * as ownFunctions from './MedicalServiceSelectorFunctions';
import { connect } from 'react-redux';

const MedicalServiceSelectorComponent = (
  props: ownFunctions.Props
) => {

  const onChangeIdTextFieldValue = (items?: ITag[] | undefined) => {
    let MedicalServicesComboSelected: AvailableMedicalService[] = [];
    if (items && items.length > 0) {
      items.forEach(async (item: ITag) => {
        const MedicalServiceId = Number(item.key);
        const medicalservice: AvailableMedicalService = ownFunctions.GetMedicalServiceById(MedicalServiceId, props.MedicalServices as AvailableMedicalService[]);

        MedicalServicesComboSelected = [
          ...MedicalServicesComboSelected,
          medicalservice
        ];
        setInternalMedicalServicesSelected(MedicalServicesComboSelected);
        setMedicalServicesOptionsSelected(items);
      });
    } else {
      setInternalMedicalServicesSelected([]);
      setMedicalServicesOptionsSelected([]);
    }
  };

  const [MedicalServiceOptions, setMedicalServiceOptions] = useState<ITag[]>();
  const [MedicalServicesOptionsSelected, setMedicalServicesOptionsSelected] =
    useState<ITag[]>();

  const [InternalMedicalServicesSelected, setInternalMedicalServicesSelected] =
    useState<AvailableMedicalService[]>([]);

  useEffect(() => {
    setMedicalServiceOptions(ownFunctions.GetMedicalServicesOptions(props.MedicalServices));
  }, [props.MedicalServices]);

  useEffect(() => {
    props.onSelectedServices(InternalMedicalServicesSelected);
  }, [InternalMedicalServicesSelected]);

  useEffect(() => {
    if (props.MedicalServicesSelected && MedicalServiceOptions) {
      
      const auxMedicalServicesSelected = MedicalServiceOptions.filter(
        (option: ITag) =>
          option.key ===
          props.MedicalServicesSelectedIds?.find((id: number) => id === option.key)
      );
      console.log({medicalServicesSelected: props.MedicalServicesSelected, options: MedicalServiceOptions, selectedOptions: auxMedicalServicesSelected})
      setMedicalServicesOptionsSelected(auxMedicalServicesSelected);
    }
  }, [MedicalServiceOptions, props.MedicalServicesSelected]);

  const filterSuggestedMedicalServices = (
    filterText: string,
    tagList: ITag[] | undefined
  ): ITag[] => {
    return filterText
      ? MedicalServiceOptions?.filter(
          (tag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
            !ownFunctions.listContainsTagList(tag, tagList)
        ) || []
      : MedicalServiceOptions || [];
  };

  return (
    <>
      <Label htmlFor="SnomedOption">Seleccione una práctica médica</Label>
      <TagPicker
        removeButtonAriaLabel="Eliminar"
        selectionAriaLabel="Seleccione un servicio"
        onResolveSuggestions={filterSuggestedMedicalServices}
        onEmptyResolveSuggestions={(selectedItems?: ITag[] | undefined) => MedicalServiceOptions || []}
        getTextFromItem={ownFunctions.getTextFromItem}
        pickerSuggestionsProps={ownFunctions.pickerSuggestionsProps}
        itemLimit={props.MultiselectEnable ? undefined : 1}
        onChange={onChangeIdTextFieldValue}
        pickerCalloutProps={{ doNotLayer: false }}
        inputProps={{
          id: 'ServicesOption'
        }}
        selectedItems={MedicalServicesOptionsSelected}
      />
      {props.noteText && <small>{props.noteText}</small>}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(MedicalServiceSelectorComponent as any);
