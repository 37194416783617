import { ErrorType } from '../models/HttpError';
import { IResult } from '../models/HttpResult';

// return query param object
export const GetQueryStringParams = (
  pageNumber?: number,
  pageSize?: number,
  searchText?: string
): URLSearchParams => {
  let paramsResult: URLSearchParams = new URLSearchParams();

  if (pageNumber && !pageSize && !searchText) {
    paramsResult = new URLSearchParams({
      PageNumber: String(pageNumber)
    });
  }

  if (pageSize && !pageNumber && !searchText) {
    paramsResult = new URLSearchParams({
      PageSize: String(pageSize)
    });
  }

  if (searchText && !pageNumber && !pageSize) {
    paramsResult = new URLSearchParams({
      SearchText: searchText
    });
  }

  if (pageNumber && pageSize && !searchText) {
    paramsResult = new URLSearchParams({
      PageNumber: String(pageNumber),
      PageSize: String(pageSize)
    });
  }

  if (pageNumber && searchText && !pageSize) {
    paramsResult = new URLSearchParams({
      PageNumber: String(pageNumber),
      SearchText: searchText
    });
  }

  if (pageSize && searchText && !pageNumber) {
    paramsResult = new URLSearchParams({
      PageSize: String(pageSize),
      SearchText: searchText
    });
  }

  if (pageNumber && pageSize && searchText) {
    paramsResult = new URLSearchParams({
      PageNumber: String(pageNumber),
      PageSize: String(pageSize),
      SearchText: searchText
    });
  }

  return paramsResult;
};

// process http error
export const HttpErrorHandler = (
  body: string | null,
  response: any
): ErrorType => {
  let CurrentResponse: ErrorType;
  if (body) {
    const ErrorBody: any = JSON.parse(body);

    CurrentResponse = {
      ErrorCode: ErrorBody.statusCode || response.status,
      ErrorMessage: ErrorBody.message || ErrorBody.title || 'error inesperado',
      Errors: ErrorBody.errors
    };

    console.log('Respuesta 1:', CurrentResponse);
    console.log('Error 1:', body);

    return ErrorDefinitionResponse(CurrentResponse);
  } else {
    CurrentResponse = {
      ErrorCode: response.status,
      ErrorMessage: response.statusText,
      Errors: response.errors || []
    };

    console.log('Respuesta 2:', CurrentResponse);
    console.log('Error 2:', response);

    return ErrorDefinitionResponse(CurrentResponse);
  }
};

const ErrorDefinitionResponse = (error: ErrorType): ErrorType => {
  switch (error.ErrorCode) {
    case 401:
      error.ErrorMessage = 'No Autorizado';
      break;
    case 404:
      error.ErrorMessage = 'Servidor fuera de linea o Recurso no encontrado';
      break;
    case 405:
      error.ErrorMessage = 'Metodo no encontrado al peticionar el recurso';
      break;
    case 409:
    case 400:
      error.ErrorMessage = error.ErrorMessage ? error.ErrorMessage : 
        'Error al procesar la solicitud, verifique la informacion suministrada'; 
      break;
    case 500:
      error.ErrorMessage =
        error.ErrorMessage ? error.ErrorMessage : 'Ocurrio un error inesperado al consultar por el recurso';
      break;
    default:
      return error;
  }

  return error;
};

export const DynamicSort = (property: any) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a: any, b: any) {
    if ((a === null || a === undefined) || (
      b === null || b === undefined
    ) ) return 0;
    const result =
      a[property]?.toString().toUpperCase() <
      b[property]?.toString().toUpperCase()
        ? -1
        : a[property] > b[property]
        ? 1
        : 0;
    return result * sortOrder;
  };
};

export const DynamicSortNumeric = (property: any) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a: any, b: any) {
    const result =
      a[property] < b[property]
        ? -1
        : a[property] > b[property]
        ? 1
        : 0;
    return result * sortOrder;
  };
}

export function getNameInitials(name: string): string {
  const names = name.split(' ');

  var Initials = '';

  var Name1 = names[0].charAt(0);
  var Name2 = names[names.length > 1 ? 1 : 0 ].charAt(0);

  if (Name1 === Name2) {
    Initials = Name1.toUpperCase();
  } else {
    Initials = Name1.toUpperCase() +
    Name2.toUpperCase()
  }

  return (
    Initials
  );
}

export async function ProcessError(error: any) {
  if (error.text) {
    return await error
      .text()
      .then((body: any) => HttpErrorHandler(body, error));
  } else return HttpErrorHandler(null, error);
}
