import { IIconProps, PrimaryButton } from '@fluentui/react';
import { useState } from 'react';
import { MedicalInsuranceAgreement } from '../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';
import { useBoolean } from '@fluentui/react-hooks';
import { MedicalInsuranceAgreementFormComponent } from '../../components/MedicalInsuranceAgreement/MedicalInsuranceAgreementForm/MedicalInsuranceAgreementFormComponent';
import { useParams } from 'react-router-dom';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const AgreementsPage = () => {
  const { MedicalInsuranceId } = useParams();
  const AddIcon: IIconProps = { iconName: 'CirclePlus' };
  const [selectedItem, setSelectedItem] = useState<MedicalInsuranceAgreement>();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  //useTranslation()
  const [t] = useTranslation();

  // events
  // const HandleEditEvent = (agreement: MedicalInsuranceAgreement) => {
  //   setSelectedItem(agreement);
  //   openPanel();
  // };

  const handleClosePanel = () => {
    setSelectedItem(undefined);
    dismissPanel();
  };

  const handleAddClick = () => {
    openPanel();
  };

  return (
    <div className="Section">
      <PrimaryButton
        secondaryText={t('agreementsPage.button.addAgreement')} 
        onClick={handleAddClick}
        text={t('agreementsPage.button.addAgreement')}
        iconProps={AddIcon}
        className="actionButton"
      />
      <MedicalInsuranceAgreementFormComponent
        isOpen={isOpen}
        dismissPanel={handleClosePanel}
        openPanel={openPanel}
        MedicalInsuranceId={Number(MedicalInsuranceId)}
        MedicalInsuranceAgreement={selectedItem}
      />
    </div>
  );
};
