import { MedicalInsuranceAgreementsPriceList } from './../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreementsPriceList';
import { NewMedicalInsuranceAgreement } from '../models/Entities/MedicalInsuranceAgreements/NewMedicalInsuranceAgreement';
import { IPagedResult } from '../models/HttpPagedResult';
import { IResult } from '../models/HttpResult';
import { MedicalInsuranceAgreement } from '../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';

// Error Tracer
import * as Sentry from '@sentry/react';

// Functions
import * as Functions from '../functions/common';
import { useSelector } from 'react-redux';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'agreements/';
const BaseURLAggregator =
  process.env.REACT_APP_BILLER_API_GATEWAY +
  'agreements.aggregator/agreements/';

export const GetAgreementsByMedicalInsuranceId = async (
  medicalInsuranceId: number,
  pageNumber: number,
  pageSize: number,
  searchText: string | undefined
, token: string): Promise<MedicalInsuranceAgreement[]> => {

  let params: URLSearchParams = Functions.GetQueryStringParams(
    pageNumber,
    pageSize,
    searchText
  );

  let RequestURL: string =
    BaseURL + '?MedicalInsuranceId=' + medicalInsuranceId;

  if (params) RequestURL += '&' + params.toString();

  try {
    const Response: MedicalInsuranceAgreement[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IPagedResult<MedicalInsuranceAgreement[]>
          >;
        }
      })
      .then((data: IPagedResult<MedicalInsuranceAgreement[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetAgreementById = async (
  AgreementId: number
, token: string): Promise<MedicalInsuranceAgreement> => {

  const RequestURL: string = BaseURLAggregator + AgreementId;

  try {
    const Response: MedicalInsuranceAgreement = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsuranceAgreement>>;
        }
      })
      .then((data: IResult<MedicalInsuranceAgreement>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AddMedicalInsuranceAgreement = async (
  newAgreement: NewMedicalInsuranceAgreement
, token: string): Promise<MedicalInsuranceAgreement> => {
  
  const RequestURL: string = BaseURL;

  try {
    const Response: MedicalInsuranceAgreement = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(newAgreement)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsuranceAgreement>>;
        }
      })
      .then((data: IResult<MedicalInsuranceAgreement>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const UpdateMedicalInsuranceAgreement = async (
  agreement: MedicalInsuranceAgreement
, token: string): Promise<MedicalInsuranceAgreement> => {

  const RequestURL: string = BaseURL;

  try {
    const Response: MedicalInsuranceAgreement = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(agreement)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsuranceAgreement>>;
        }
      })
      .then((data: IResult<MedicalInsuranceAgreement>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DeleteMedicalInsuranceAgreement = async (
  AgreementId: number
, token: string): Promise<null> => {

  const RequestURL: string = BaseURL + AgreementId;

  try {
    const Response: null = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<null>>;
        }
      })
      .then((data: IResult<null>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const SetPriceListStartValidityDate = async (
  AgreementPriceListId: number,
  StartDate: Date
, token: string): Promise<MedicalInsuranceAgreementsPriceList> => {

  const RequestURL: string =
    BaseURL + 'PriceList/' + AgreementPriceListId + '/startvalidity';

  try {
    const Response: MedicalInsuranceAgreementsPriceList = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'POST',
        body: JSON.stringify(StartDate)
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<MedicalInsuranceAgreementsPriceList>
          >;
        }
      })
      .then((data: IResult<MedicalInsuranceAgreementsPriceList>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const SetPriceListEndValidityDate = async (
  AgreementPriceListId: number,
  EndDate: Date
, token: string): Promise<MedicalInsuranceAgreementsPriceList> => {
  
  const RequestURL: string = BaseURL + 'PriceList/' + AgreementPriceListId + '/endvalidity';

  try {
    const Response: MedicalInsuranceAgreementsPriceList = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'POST',
        body: JSON.stringify(EndDate)
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<MedicalInsuranceAgreementsPriceList>
          >;
        }
      })
      .then((data: IResult<MedicalInsuranceAgreementsPriceList>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AddPriceListToAgreement = async (
  AgreementId: number,
  comments: string,
  listName: string
, token: string): Promise<MedicalInsuranceAgreementsPriceList> => {
  const RequestURL: string = BaseURLAggregator + 'pricelist';

  try {
    const Response: MedicalInsuranceAgreementsPriceList = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'POST',
        body: JSON.stringify({
          agreementId: AgreementId,
          comments: comments,
          priceListName: listName
        })
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<MedicalInsuranceAgreementsPriceList>
          >;
        }
      })
      .then((data: IResult<MedicalInsuranceAgreementsPriceList>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const ClonePriceListToAgreement = async (
  AgreementPriceListId: number
, token: string): Promise<MedicalInsuranceAgreementsPriceList> => {
  
  const RequestURL: string = BaseURLAggregator + 'pricelist/' + AgreementPriceListId + '/clone';

  try {
    const Response: MedicalInsuranceAgreementsPriceList = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'GET'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<MedicalInsuranceAgreementsPriceList>
          >;
        }
      })
      .then((data: IResult<MedicalInsuranceAgreementsPriceList>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DeletePriceListFromAgreement = async (
  AgreementPriceListId: number
, token: string): Promise<null> => {
  const RequestURL: string = BaseURL + 'PriceList/' + AgreementPriceListId;

  try {
    const Response: null = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<null>>;
        }
      })
      .then((data: IResult<null>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
