import {
  FontSizes,
  Icon,
  Label,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Text
} from '@fluentui/react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { PractitionerMedicalInsuranceAssociation } from '../../../models/Entities/PractitionerAssociations/MedicalInsurances/PractitionerMedicalInsuranceAssociation';
import MedicalInsuranceMultiSelectorComponent, {
  SelectedMedicalInsuranceType
} from '../../MedicalInsurance/MedicalInsuranceMultiSelector/MedicalInsuranceMultiSelectorComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { MedicalInsurance } from '../../../models/Entities/MedicalInsurances/MedicalInsurance';

// functions
import * as ComponentFunction from './PractitionerMedicalInsuranceAssociatedListFunctions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './PractitionerMedicalInsuranceAssociatedList.css';

export const PractitionerMedicalInsuranceAssociatedListComponent = (
  props: ComponentFunction.Props
) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

    //useTranslation();
    const [t] = useTranslation();

  // useEffect(() => {
  //   if (!props.Practitioners) {
  //     props.GetAllPractitioners(1, 1000, undefined);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.PractitionerId]);

  useEffect(() => {
    props.GetMedicalInsuranceByPractitionerId(props.PractitionerId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.PractitionerId]);

  //event handlers

  const onAssociateMedicalInsurance = (medicalInsurance: MedicalInsurance) => {
    props.AssociateMedicalInsuranceToPractitioner({
      practitionerId: props.PractitionerId,
      medicalInsuranceId: medicalInsurance.id
    });
  };

  const onDisassociateMedicalInsurance = (
    medicalInsurance: SelectedMedicalInsuranceType
  ) => {
    props.DisassociateMedicalInsuranceByMedicalInsuranceId(
      medicalInsurance.medicalInsuranceId,
      props.PractitionerId
    );
  };

  // Render blocks

  const LoadingBlock = (
    <div className="LoadingBlock">
      <Spinner size={SpinnerSize.large} label={t('practitionerMedicalInsuranceAssociated.renderBlock.loadingBlock')}/>
    </div>
  );

  const ControlsBlock = (
    <div className="ControlsBlock">
      <PrimaryButton
        iconProps={ComponentFunction.AddIcon}
        text={t('practitionerMedicalInsuranceAssociated.renderBlock.selectCoverage')}
        onClick={openPanel}
        className="actionButton"
      />
      <MedicalInsuranceMultiSelectorComponent
        isOpen={isOpen}
        openPanel={openPanel}
        dismissPanel={dismissPanel}
        SelectedMedicalInsurance={ComponentFunction.GetSelectedMedicalInsurance(
          props.MedicalInsuranceAssociated
        )}
        onSelect={onAssociateMedicalInsurance}
        onUnselect={onDisassociateMedicalInsurance}
      />
    </div>
  );

  const MedicalInsuranceAssociatedBlock = (
    <div className="MedicalInsuranceAssociatedBlock">
      <ul>
        {props.MedicalInsuranceAssociated?.map(
          (medicalInsurance: PractitionerMedicalInsuranceAssociation) => (
            <li>
              <Icon
                iconName="SearchNearby"
                style={{ fontSize: FontSizes.size24 }}
              />
              <div>
                <Label style={{ fontSize: FontSizes.size16 }}>
                  {medicalInsurance.medicalInsurance.businessName}{' '}
                </Label>
                <Text>{medicalInsurance.medicalInsurance.title}</Text>
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );

  const NoContentBlock = (
    <div className="NoData">
      <Label>
        {t('practitionerMedicalInsuranceAssociated.noContent.nonMedicalCoverageProvider')} 
      </Label>
      <Separator />
      <label>{t('practitionerMedicalInsuranceAssociated.noContent.indicateCoverage')}</label>
    </div>
  );

  return (
    <>
      {ControlsBlock}
      {props.IsLoading
        ? LoadingBlock
        : props.practitionerLoadingSuccess &&
          props.MedicalInsuranceAssociated?.length > 0
        ? MedicalInsuranceAssociatedBlock
        : NoContentBlock}
    </>
  );
};

export default connect(
  ComponentFunction.mapStateToProps,
  ComponentFunction.mapDispatchToProps
)(PractitionerMedicalInsuranceAssociatedListComponent as any);
