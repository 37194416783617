//store 
import * as PaymentStore from "../../../../redux/middleware/CashFlow-Payments.Middlerware";

// models
import { Payment } from "../../../../models/Entities/CashFlow/Payment/Payment";
import { ErrorType } from "../../../../models/HttpError";
import { AppState } from "../../../../redux/reducers";
import { makeStyles, tokens } from "@fluentui/react-components";

import * as jwt_decode from "jwt-decode";
import { Person } from "../../../../models/Entities/Person/Person";

// form types
export enum FormType {
  payer =1,
  receiver = 2
}

//#region Props

interface IProps {
    FormType: FormType;
    ReferalId: string | undefined;
    FixedAssociatedEntityIdentifier: string | undefined;
    PaymentId?: number;
    isOpen: boolean;
    onClose: () => void;
  }
  
  interface ConnectedProps {
    isLoading: boolean;
    isLoadingSuccessfully: boolean;
    failOnLoading: boolean;

    isSaving: boolean;
    isSaved: boolean | undefined;
    failOnSaving: boolean;

    DefaultPayer: Person | undefined;
    DeFaultReceiver: Person | undefined;

    error: ErrorType | undefined;
    CurrentPayment: Payment;
    OwnerId: string;
    Persons: Person[];

    // methods
    GetPersonByIdentifier: (identifier: string) => Person | undefined;
  }
  
  interface MedicalServicesDispatchProps {
    GetPaymentById: typeof PaymentStore.actionCreators.GetPaymentById;
    CreatePayment: typeof PaymentStore.actionCreators.CreatePayment;
    UpdatePayment: typeof PaymentStore.actionCreators.UpdatePayment;
  }
  
  export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    var currentPayment = ownProps.PaymentId ? ownProps.FormType === FormType.payer ? state?.CashFlowPayments?.paymentsDone?.find((payment: Payment) => payment.id === ownProps.PaymentId) 
     : state?.CashFlowPayments?.paymentsReceived?.find((payment: Payment) => payment.id === ownProps.PaymentId) 
     : null ;

    const currentOwner: string = (jwt_decode.jwtDecode(state?.oidc?.user?.access_token!) as any)?.tenantId;

    if(ownProps.PaymentId && !currentPayment){
        PaymentStore.actionCreators.GetPaymentById(ownProps.PaymentId);
    }

    // person who represnets the medical center
    const ownerPerson: Person = {
      personIdentifier: currentOwner,
      id: 0,
      isDeleted: false,
      numberOfMarriages: 0,
      personType: 0,
      businessName: "Centro Médico"
    };

    const propsPayer = ownProps.FormType === FormType.payer 
    ? ownProps.ReferalId && ownProps.ReferalId === currentOwner 
      ? ownerPerson
      : GetPersonByIdentifier(state.Persons?.list || [], currentOwner, ownProps.ReferalId || '')
    : GetPersonByIdentifier(state.Persons?.list || [], currentOwner, ownProps.FixedAssociatedEntityIdentifier || '');

    const propsReceiver = ownProps.FormType === FormType.receiver
    ? ownProps.ReferalId && ownProps.ReferalId === currentOwner 
      ? ownerPerson
      : GetPersonByIdentifier(state.Persons?.list || [], currentOwner, ownProps.ReferalId || '')
    : GetPersonByIdentifier(state.Persons?.list || [], currentOwner, ownProps.FixedAssociatedEntityIdentifier || '');

    
    return ({
        isLoading: state.CashFlowPayments?.isLoadingOne,
        isLoadingSuccessfully: state.CashFlowPayments?.successLoadingOne,
        failOnLoading: state.CashFlowPayments?.failOnLoadingOne,
        
        isSaving: currentPayment ? (currentPayment.isUpdating || currentPayment.isSaving) : state.CashFlowPayments?.isAddingNewOne || false,
        isSaved: currentPayment ? (currentPayment.isUpdatedSuccessfully || currentPayment.isSavedSuccessfully) : state.CashFlowPayments?.isAddingNewOne || undefined,
        failOnSaving: currentPayment ? (currentPayment.failOnUpdating || currentPayment.failOnSaving) : state.CashFlowPayments?.failOnAddingNewOne || false,

        DefaultPayer: propsPayer,
        DeFaultReceiver: propsReceiver,
        
        error: state.CashFlowPayments?.error || currentPayment?.error || undefined,
        CurrentPayment: currentPayment,
        OwnerId: currentOwner,
        Persons: state.Persons?.list || [],
        GetPersonByIdentifier: (identifier: string) => GetPersonByIdentifier(state.Persons?.list || [], currentOwner, identifier)
    })
  };
  
  export const mapDispatchToProps = {
    ...PaymentStore.actionCreators
  };


  // styles
  export const useStyles = makeStyles({
    formField: {
        // Stack the label above the field
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        gap: "2px",
        // Prevent the example from taking the full width of the page (optional)
        maxWidth: "400px",
      },
    content: {
      display: "flex",
      flexDirection: "column",
      rowGap: tokens.spacingVerticalL,
    }
  });

// functions
export const GetPersonByIdentifier = (persons: Person[], tenantId: string, identifier: string): Person | undefined => {
   
    if(identifier === tenantId) return {
        personIdentifier: tenantId,
        businessName: 'Centro Médico'
    } as Person;
    return persons.find((person: Person) => person.personIdentifier === identifier);
}
