import { AvailableMedicalService } from './../../models/Entities/MedicalServices/AvailableMedicalService';
import { AppThunkAction } from '../reducers';
import { ErrorType } from '../../models/HttpError';

// services
import * as Services from '../../services/MedicalServices.Services';

// Actions
import * as Actions from '../actions';
import { NewAvailableMedicalService } from '../../models/Entities/MedicalServices/NewAvailableMedicalService';

type KnownAction =
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetAll_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetAll_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetAll_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdateAll_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdateAll_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdateAll_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetById_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetById_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetById_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceAdd_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceAdd_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceAdd_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdate_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdate_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdate_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceDelete_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceDelete_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceDelete_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceChangeStatus_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceChangeStatus_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceChangeStatus_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceChangeSettings_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceChangeSettings_Fail_Action;

export const actionCreators = {
  GetAllMedicalServices:
    (
      pageNumber: number,
      pageSize: number,
      only3rdParty: boolean | undefined
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      
      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'AVAILABLE_MEDICAL_SERVICE_GET_ALL_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      if(state.MedicalServices?.isLoadingAll || state.MedicalServices?.successLoadingAll) return;

      if (!state.MedicalServices?.successLoadingAll) {
        dispatch({
          type: 'AVAILABLE_MEDICAL_SERVICE_GET_ALL_REQUEST_ACTION'
        });

        Services.GetMedicalServices(pageNumber, pageSize, undefined, undefined, token)
          .then((services: AvailableMedicalService[]) => {
            dispatch({
              type: 'AVAILABLE_MEDICAL_SERVICE_GET_ALL_RECEIVE_ACTION',
              Services: services
            });
          })
          .catch((error: ErrorType) =>
            dispatch({
              type: 'AVAILABLE_MEDICAL_SERVICE_GET_ALL_FAIL_ACTION',
              error: error
            })
          );
      } else {
        dispatch({
          type: 'AVAILABLE_MEDICAL_SERVICE_UPDATE_ALL_REQUEST_ACTION'
        });

        Services.GetMedicalServices(pageNumber, pageSize, undefined, undefined, token)
          .then((services: AvailableMedicalService[]) => {
            dispatch({
              type: 'AVAILABLE_MEDICAL_SERVICE_UPDATE_ALL_RECEIVE_ACTION',
              Services: services
            });
          })
          .catch((error: ErrorType) =>
            dispatch({
              type: 'AVAILABLE_MEDICAL_SERVICE_UPDATE_ALL_FAIL_ACTION',
              error: error
            })
          );
      }
    },
  GetMedicalServiceById: (Id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'AVAILABLE_MEDICAL_SERVICE_GET_BY_ID_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'AVAILABLE_MEDICAL_SERVICE_GET_BY_ID_REQUEST_ACTION',
        AvailableMedicalServiceId: Id
      });

      Services.GetMedicalServiceById(Id, token)
        .then((service: AvailableMedicalService) => {
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_GET_BY_ID_RECEIVE_ACTION',
            AvailableMedicalService: service
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_GET_BY_ID_FAIL_ACTION',
            error: error
          })
        );
    },
  AddMedicalService:
    (newService: NewAvailableMedicalService): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'AVAILABLE_MEDICAL_SERVICE_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'AVAILABLE_MEDICAL_SERVICE_ADD_REQUEST_ACTION',
        NewAvailableMedicalService: newService
      });

      Services.AddService(newService, token)
        .then((service: AvailableMedicalService) => {
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_ADD_RECEIVE_ACTION',
            AvailableMedicalService: service
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_ADD_FAIL_ACTION',
            error: error
          })
        );
    },
  UpdateMedicalService:
    (updatedService: AvailableMedicalService): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'AVAILABLE_MEDICAL_SERVICE_UPDATE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'AVAILABLE_MEDICAL_SERVICE_UPDATE_REQUEST_ACTION',
        AvailableMedicalService: updatedService
      });

      Services.UpdateMedicalService(updatedService, token)
        .then((service: AvailableMedicalService) => {
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_UPDATE_RECEIVE_ACTION',
            AvailableMedicalService: service
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_UPDATE_FAIL_ACTION',
            error: error
          })
        );
    },
  DeleteMedicalService:
    (deletedServiceId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'AVAILABLE_MEDICAL_SERVICE_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }


      dispatch({
        type: 'AVAILABLE_MEDICAL_SERVICE_DELETE_REQUEST_ACTION',
        AvailableMedicalServiceId: deletedServiceId
      });

      Services.DeleteMedicalService(deletedServiceId, token)
        .then(() => {
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_DELETE_RECEIVE_ACTION',
            AvailableMedicalServiceId: deletedServiceId
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_DELETE_FAIL_ACTION',
            error: error
          })
        );
    },
  ChangeMedicalServiceStatus:
    (Id: number, status: boolean): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'AVAILABLE_MEDICAL_SERVICE_CHANGE_STATUS_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }


      dispatch({
        type: 'AVAILABLE_MEDICAL_SERVICE_CHANGE_STATUS_REQUEST_ACTION',
        AvailableMedicalServiceId: Id,
        newStatus: status
      });

      Services.MedicalServiceChangeStatus(Id, status, token)
        .then((newStatus: boolean) => {
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_CHANGE_STATUS_RECEIVE_ACTION',
            medicalServiceId: Id,
            status: newStatus
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_CHANGE_STATUS_FAIL_ACTION',
            error: error
          })
        );
    },
  ChangeMedicalServiceSettings:
    (medicalService: AvailableMedicalService): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'AVAILABLE_MEDICAL_SERVICE_CHANGE_SETTINGS_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'AVAILABLE_MEDICAL_SERVICE_CHANGE_SETTINGS_REQUEST_ACTION',
        MedicalService: medicalService
      });

      Services.MedicalServiceChangeSettings(medicalService, token)
        .then((UpdatedMedicalService: AvailableMedicalService) => {
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_UPDATE_RECEIVE_ACTION',
            AvailableMedicalService: UpdatedMedicalService
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AVAILABLE_MEDICAL_SERVICE_CHANGE_SETTINGS_FAIL_ACTION',
            error: error
          })
        );
    }
};
