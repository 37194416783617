import { NewPayment } from "../../models/Entities/CashFlow/Payment/NewPayment";
import { Payment } from "../../models/Entities/CashFlow/Payment/Payment";
import { ErrorType } from "../../models/HttpError";


// Get all payments done
export const CASHFLOW_PAYMENTS_DONE_GETALL_REQUEST = 'CASHFLOW_PAYMENTS_DONE_GETALL_REQUEST';
export const CASHFLOW_PAYMENTS_DONE_GETALL_SUCCESS = 'CASHFLOW_PAYMENTS_DONE_GETALL_SUCCESS';
export const CASHFLOW_PAYMENTS_DONE_GETALL_FAILURE = 'CASHFLOW_PAYMENTS_DONE_GETALL_FAILURE';

export interface CashFlowPaymentsDoneGetAllRequestAction {
    type: typeof CASHFLOW_PAYMENTS_DONE_GETALL_REQUEST;
}

export interface CashFlowPaymentsDoneGetAllSuccessAction {
    type: typeof CASHFLOW_PAYMENTS_DONE_GETALL_SUCCESS;
    payload: Payment[];
}

export interface CashFlowPaymentsDoneGetAllFailureAction {
    type: typeof CASHFLOW_PAYMENTS_DONE_GETALL_FAILURE;
    error: ErrorType;
}

// Get all payments received

export const CASHFLOW_PAYMENTS_RECEIVED_GETALL_REQUEST = 'CASHFLOW_PAYMENTS_RECEIVED_GETALL_REQUEST';
export const CASHFLOW_PAYMENTS_RECEIVED_GETALL_SUCCESS = 'CASHFLOW_PAYMENTS_RECEIVED_GETALL_SUCCESS';
export const CASHFLOW_PAYMENTS_RECEIVED_GETALL_FAILURE = 'CASHFLOW_PAYMENTS_RECEIVED_GETALL_FAILURE';

export interface CashFlowPaymentsReceivedGetAllRequestAction {
    type: typeof CASHFLOW_PAYMENTS_RECEIVED_GETALL_REQUEST;
}

export interface CashFlowPaymentsReceivedGetAllSuccessAction {
    type: typeof CASHFLOW_PAYMENTS_RECEIVED_GETALL_SUCCESS;
    payload: Payment[];
}

export interface CashFlowPaymentsReceivedGetAllFailureAction {
    type: typeof CASHFLOW_PAYMENTS_RECEIVED_GETALL_FAILURE;
    error: ErrorType;
}

// Update all payments done

export const CASHFLOW_PAYMENTS_DONE_UPDATEALL_REQUEST = 'CASHFLOW_PAYMENTS_DONE_UPDATEALL_REQUEST';
export const CASHFLOW_PAYMENTS_DONE_UPDATEALL_SUCCESS = 'CASHFLOW_PAYMENTS_DONE_UPDATEALL_SUCCESS';
export const CASHFLOW_PAYMENTS_DONE_UPDATEALL_FAILURE = 'CASHFLOW_PAYMENTS_DONE_UPDATEALL_FAILURE';

export interface CashFlowPaymentsDoneUpdateAllRequestAction {
    type: typeof CASHFLOW_PAYMENTS_DONE_UPDATEALL_REQUEST;
}

export interface CashFlowPaymentsDoneUpdateAllSuccessAction {
    type: typeof CASHFLOW_PAYMENTS_DONE_UPDATEALL_SUCCESS;
    payload: Payment[];
}

export interface CashFlowPaymentsDoneUpdateAllFailureAction {
    type: typeof CASHFLOW_PAYMENTS_DONE_UPDATEALL_FAILURE;
    error: ErrorType;
}

// Update all payments received

export const CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_REQUEST = 'CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_REQUEST';
export const CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_SUCCESS = 'CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_SUCCESS';
export const CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_FAILURE = 'CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_FAILURE';

export interface CashFlowPaymentsReceivedUpdateAllRequestAction {
    type: typeof CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_REQUEST;
}

export interface CashFlowPaymentsReceivedUpdateAllSuccessAction {
    type: typeof CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_SUCCESS;
    payload: Payment[];
}

export interface CashFlowPaymentsReceivedUpdateAllFailureAction {
    type: typeof CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_FAILURE;
    error: ErrorType;
}


// Get payment by id

export const CASHFLOW_PAYMENTS_GETBYID_REQUEST = 'CASHFLOW_PAYMENTS_GETBYID_REQUEST';
export const CASHFLOW_PAYMENTS_GETBYID_SUCCESS = 'CASHFLOW_PAYMENTS_GETBYID_SUCCESS';
export const CASHFLOW_PAYMENTS_GETBYID_FAILURE = 'CASHFLOW_PAYMENTS_GETBYID_FAILURE';

export interface CashFlowPaymentsGetByIdRequestAction {
    type: typeof CASHFLOW_PAYMENTS_GETBYID_REQUEST;
    payload: number;
}

export interface CashFlowPaymentsGetByIdSuccessAction {
    type: typeof CASHFLOW_PAYMENTS_GETBYID_SUCCESS;
    tenantId: string;
    payload: Payment;
}

export interface CashFlowPaymentsGetByIdFailureAction {
    type: typeof CASHFLOW_PAYMENTS_GETBYID_FAILURE;
    error: ErrorType;
}

// Create payment

export const CASHFLOW_PAYMENTS_CREATE_REQUEST = 'CASHFLOW_PAYMENTS_CREATE_REQUEST';
export const CASHFLOW_PAYMENTS_CREATE_SUCCESS = 'CASHFLOW_PAYMENTS_CREATE_SUCCESS';
export const CASHFLOW_PAYMENTS_CREATE_FAILURE = 'CASHFLOW_PAYMENTS_CREATE_FAILURE';

export interface CashFlowPaymentsCreateRequestAction {
    type: typeof CASHFLOW_PAYMENTS_CREATE_REQUEST;
    payload: NewPayment;
}

export interface CashFlowPaymentsCreateSuccessAction {
    type: typeof CASHFLOW_PAYMENTS_CREATE_SUCCESS;
    tenantId: string;
    payload: Payment;
}

export interface CashFlowPaymentsCreateFailureAction {
    type: typeof CASHFLOW_PAYMENTS_CREATE_FAILURE;
    error: ErrorType;
}

// Update payment

export const CASHFLOW_PAYMENTS_UPDATE_REQUEST = 'CASHFLOW_PAYMENTS_UPDATE_REQUEST';
export const CASHFLOW_PAYMENTS_UPDATE_SUCCESS = 'CASHFLOW_PAYMENTS_UPDATE_SUCCESS';
export const CASHFLOW_PAYMENTS_UPDATE_FAILURE = 'CASHFLOW_PAYMENTS_UPDATE_FAILURE';

export interface CashFlowPaymentsUpdateRequestAction {
    type: typeof CASHFLOW_PAYMENTS_UPDATE_REQUEST;
    tenantId: string;
    payload: Payment;
}

export interface CashFlowPaymentsUpdateSuccessAction {
    type: typeof CASHFLOW_PAYMENTS_UPDATE_SUCCESS;
    tenantId: string;
    payload: Payment;
}

export interface CashFlowPaymentsUpdateFailureAction {
    type: typeof CASHFLOW_PAYMENTS_UPDATE_FAILURE;
    paymentId: number;
    error: ErrorType;
}

// Delete payment

export const CASHFLOW_PAYMENTS_DELETE_REQUEST = 'CASHFLOW_PAYMENTS_DELETE_REQUEST';
export const CASHFLOW_PAYMENTS_DELETE_SUCCESS = 'CASHFLOW_PAYMENTS_DELETE_SUCCESS';
export const CASHFLOW_PAYMENTS_DELETE_FAILURE = 'CASHFLOW_PAYMENTS_DELETE_FAILURE';

export interface CashFlowPaymentsDeleteRequestAction {
    type: typeof CASHFLOW_PAYMENTS_DELETE_REQUEST;
    tenantId: string;
    payload: number;
}

export interface CashFlowPaymentsDeleteSuccessAction {
    type: typeof CASHFLOW_PAYMENTS_DELETE_SUCCESS;
    tenantId: string;
    payload: number;
}

export interface CashFlowPaymentsDeleteFailureAction {
    type: typeof CASHFLOW_PAYMENTS_DELETE_FAILURE;
    paymentId: number;
    error: ErrorType;
}

