import { MenuItem } from './SideMenuModels';
import {
  FaBookMedical,
  FaClinicMedical,
  FaHandHoldingMedical,
  FaFileContract,
  FaMoneyCheckAlt,
  FaClipboardList,
  FaCommentDollar,
  FaFileMedicalAlt,
  FaHome,
  FaPage4,
  FaRibbon,
  FaRegIdCard,
  FaMoneyBill,
  FaMoneyCheck,
  FaBuilding,
  FaLayerGroup,
  FaAngry,
  FaGitlab,
  FaPersonBooth,
  FaPeopleArrows,
  FaUserFriends,
  FaUserCircle,
  FaUsers,
  FaStudiovinari,
  FaLeanpub,
  FaFileMedical,
  FaUserCheck,
  FaUserGraduate,
  FaBusinessTime
} from 'react-icons/fa';

export const MenuItems: MenuItem[] = [
  {
    key: 'home',
    icon: <FaHome />,
    name: 'sideMenu.home',
    title: 'Panel principal',
    ariaLabel: '',
    target: ''
  },
  {
    key: 'healthInsurance',
    icon: <FaMoneyCheck />,
    name: 'sideMenu.healthInsurance',
    title: 'Add',
    ariaLabel: '',
    target: 'insurances'
  },
  {
    key: 'healthServices',
    icon: <FaBookMedical />,
    name: 'sideMenu.healthServices',
    title: 'Upload',
    ariaLabel: '',
    target: 'services'
  },
  {
    key: 'Practiotioners',
    icon: <FaUserGraduate />,
    name: 'sideMenu.practitioners',
    title: 'Practitioner',
    ariaLabel: '',
    target: 'practitioners'
  },
  {
    key: 'Providers',
    icon: <FaUserFriends />,
    name: 'sideMenu.providers',
    title: 'Providers',
    ariaLabel: '',
    target: 'Providers'
  },
  {
    key: 'Sponsors',
    icon: <FaUsers />,
    name: 'sideMenu.sponsors',
    title: 'Sponsors',
    ariaLabel: '',
    target: 'sponsors'
  },
  {
    key: 'Protocolos',
    icon: <FaLeanpub />,
    name: 'sideMenu.protocols',
    title: 'Practitioner',
    ariaLabel: '',
    target: 'protocolsagreements'
  },
  {
    key: 'Contracts',
    icon: <FaFileContract />,
    name: 'sideMenu.contracts',
    title: 'Contracts',
    ariaLabel: '',
    target: 'contracts'
  },
  {
    key: 'Settlements',
    icon: <FaMoneyCheckAlt />,
    name: 'sideMenu.settlements',
    title: 'Settlements',
    ariaLabel: '',
    target: 'settlements'
  },
  {
    key: 'CashFlow',
    icon: <FaMoneyCheckAlt />,
    name: 'sideMenu.cashflow',
    title: 'Finanzas',
    ariaLabel: '',
    target: 'cashflow'
  }
];

export const MenuItemsOverflow = [
  {
    key: 'item4',
    name: 'Overflow Link 1',
    onClick: undefined
  },
  {
    key: 'item5',
    name: 'Overflow Link 2',
    onClick: undefined
  }
];
