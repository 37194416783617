import { connect, ConnectedProps } from 'react-redux';
import { MedicalInsurance } from '../../models/Entities/MedicalInsurances/MedicalInsurance';
import { ErrorType } from '../../models/HttpError';

//store
import * as MedicalInsuranceStore from '../../redux/middleware/MedicalInsuranceMiddleware';
import { AppState } from '../../redux/reducers';

interface StateProps {
  loading: boolean | undefined;
  loadingSuccess: boolean | undefined;
  failOnLoading: boolean | undefined;
  error: ErrorType | undefined;
  MedicalInsurances: MedicalInsurance[] | undefined;
}

interface DispatchProps {
  RequestMedicalInsurances: typeof MedicalInsuranceStore.actionCreators.GetAllMedicalInsurances;
}

// connector

export const mapStateToProps = (state: AppState): StateProps => ({
  loading: state.MedicalInsuranceAgreements?.isLoadingAll,
  loadingSuccess: state.MedicalInsuranceAgreements?.successLoadingAll,
  failOnLoading: state.MedicalInsuranceAgreements?.failOnLoadingAll,
  error: state.MedicalInsuranceAgreements?.error,
  MedicalInsurances: state.MedicalInsurances?.list
});

export const mapDispatchToProps = {
  RequestMedicalInsurances:
    MedicalInsuranceStore.actionCreators.GetAllMedicalInsurances
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & DispatchProps;
