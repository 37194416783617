import { PractitionerAssociationsMedicalService } from './../../models/Entities/PractitionerAssociations/MedicalServices/PractitionerAssociationMedicalService';
import { Reducer, Action } from 'redux';
import {
  PractitionerMedicalServiceState,
  PractitionerMedicalServiceUnloadState
} from '../states/PractitionerMedicalServiceState';

// Functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_RequestAll_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_ReceiveAll_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_FailAll_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_RequestById_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_ReceiveById_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_FailById_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Add_Request_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Add_Receive_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Add_Fail_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Delete_Request_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Delete_By_Id_Receive_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Delete_By_Medical_Service_Id_Receive_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Delete_Fail_Action;

export const PractitionerMedicalServiceAssociationReducer: Reducer<
  PractitionerMedicalServiceState
> = (
  state: PractitionerMedicalServiceState | undefined,
  incomingAction: Action
): PractitionerMedicalServiceState => {
  if (state === undefined) {
    return PractitionerMedicalServiceUnloadState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'PRACTITIONER_MEDICAL_SERVICE_REQUEST_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_RECEIVE_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined,
        list: action.practitionerMedicalServices
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_FAIL_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_REQUEST_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_RECEIVE_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (medicalService: PractitionerAssociationsMedicalService) =>
              medicalService.id !== action.practitionerMedicalService.id
          ),
          action.practitionerMedicalService
        ]
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_FAIL_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [...state.list, action.practitionerMedicalService]
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_DELETE_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: state.list.filter(
          (medicalService: PractitionerAssociationsMedicalService) =>
            medicalService.id !== action.practitionerMedicalServiceId
        )
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_DELETE_BY_MEDICAL_SERVICE_ID_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: state.list.filter(
          (medicalService: PractitionerAssociationsMedicalService) =>
            medicalService.medicalServiceId !== action.MedicalServiceId
        )
      };
    case 'PRACTITIONER_MEDICAL_SERVICE_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
    default:
      return state;
  }
};
