import { connect } from 'react-redux';
import { DefaultButton, Text, FontIcon, FontSizes, FontWeights, Label, MessageBarType, Separator } from '@fluentui/react';
import { useEffect, useState } from 'react';
import MOMENT from 'react-moment';
import ProtocolContractFormComponent from '../Protocols/ProtocolContractForm/ProtocolContractFormComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { ContractToClone } from '../../../models/Entities/Contracts/ContractToClone';

//functions
import * as ComponentFunctions from './ContractHeader.Functions';


////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Styles
import './ContractHeader.Styles.css';
import { useNavigate } from 'react-router-dom';
import { ConfirmationButtonComponent } from '../../../commons/component/ConfirmationButton/ConfirmationButtonComponent';



export const ContractHeaderComponent = (props: ComponentFunctions.Props) => {

    const navigate = useNavigate();

    const [selectedContract, setSelectedContract] = useState<Contract | undefined>(undefined);

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
        useBoolean(false);

    const [Today, setToday] = useState<Date>(new Date());

    const EditContract = () => {
        setSelectedContract(props.Contract);
        openPanel();
    };

    const DeleteContract = (contract: Contract) => {
        props.DeleteContract(contract.id);
        navigate('/contracts/' + (props.Contract?.parentContractId || ''));
    };

    //useTranslation()
    const [t] = useTranslation();


    // Effects
    useEffect(() => {
        props.GetContractById(props.ContractId);
    }, [props.ContractId]);

    const handleClosePanel = () => {
        setSelectedContract(undefined);
        dismissPanel();
    };

    const Forms = (<>
        {props.Contract && <ProtocolContractFormComponent
            isOpen={isOpen}
            Contract={selectedContract}
            dismissPanel={handleClosePanel}
            openPanel={openPanel}
            mode=''
            RefererId={undefined}
        />}
    </>
    );

    const Actions = props.Contract && (
        <>
            <div className="ContractActions__Buttons">
                <DefaultButton
                    text={t('contractHeader.actions.edit')}
                    iconProps={{ iconName: 'Edit' }}
                    onClick={EditContract}
                    className='optionButton'
                />

                <ConfirmationButtonComponent
                    ButtonId={"deleteContract" + props.Contract.id}
                    ButtonIconName={"Delete"}
                    ButtonText={t('contractList.contentTable.confirmationButtonDelete.confirm')}
                    ButtonCssClass={""}
                    ConfirmationButtonText={t('contractList.contentTable.confirmationButtonDelete.confirm')}
                    ConfirmationButtonCssClass={"deleteButton"}
                    CancelButtonText={t('contractList.contentTable.confirmationButtonDelete.cancel')}
                    CancelButtonCssClass={""}
                    Title={t('contractList.contentTable.confirmationButtonDelete.deleteContract')}
                    Description={""}
                    ElementTextHeader={t('contractList.contentTable.confirmationButtonDelete.deleteContractInformation') + props.Contract.contractNumber + ' - ' + (props.Contract.parentContractId ? '( Adenda )' : '')}
                    ElementTextInformation={t('contractList.contentTable.confirmationButtonDelete.operationInfo')}
                    onConfirmation={() => DeleteContract(props.Contract!)}
                    onCancel={function (): void {
                        throw new Error('Function not implemented.');
                    }}
                    isOperationExecuting={
                        props.isDeleting
                    }
                    isOperationExecutedWithError={
                        props.failOnDeleting
                    }
                    OperationError={props.error}
                />

            </div>
        </>
    )

    const ErrorSection = props.failOnLoading && props.error && (
        <>
            <ErrorMessageComponent
                Error={props.error}
            />
        </>
    )

    const HeaderContent = (<>
        <div className={"Section ContractStatus " + ComponentFunctions.GetContractStatusClass(props.Contract?.contractStatus)}>
            <div className="ContractHeader">
                <div className="ContractHeader__Title">
                    <h2 style={{textTransform: 'capitalize'}}>
                        <FontIcon iconName={props.Contract?.isLocked ? 'Lock' : 'Unlock'} style={{
                            color: 'white',
                            paddingTop: '10',
                            fontWeight: FontWeights.bold,
                            fontSize: FontSizes.size14,
                            padding: '.5em .7em',
                            backgroundColor: 'green',
                            marginRight: '.5em',
                            borderRadius: '2em'
                        }} />
                        &nbsp;{props.Contract?.contractNumber}
                        {props.Contract?.parentContractId && <span className='AddendaBadge' >Adenda</span>}
                    </h2>
                    <p><Text>{props.Contract?.contractDescription}</Text></p>
                </div>
                <Separator />
                <div className='ContractHeaderDetails'>
                    <div>
                        <small>{t('contractHeader.headerContent.contractStatus')}</small>
                        <Label><strong>{ComponentFunctions.GetContractStatusDescripcion(props.Contract?.contractStatus)}</strong></Label>
                    </div>
                    <div>
                        <small>{t('contractHeader.headerContent.contractCurrency')}</small>
                        <Label><strong>{props.Contract?.contractCurrency}</strong></Label>
                    </div>
                    <div>
                        <small>{t('contractHeader.headerContent.contractValidity')}</small>
                        <Label><strong>{props.Contract?.contractStartDate ? (
                            <MOMENT format="DD/MM/YYYY">
                                {props.Contract?.contractStartDate}
                            </MOMENT>
                        ) : (
                            t('contractHeader.headerContent.dateNotReported')
                        )}</strong> - <strong>{props.Contract?.contractEndDate ? (
                            <MOMENT format="DD/MM/YYYY">
                                {props.Contract?.contractEndDate}
                            </MOMENT>
                        ) : (
                            t('contractHeader.headerContent.dateNotReported')
                        )}&nbsp;</strong>
                            {props.Contract && props.Contract.contractEndDate ? (
                                <strong>
                                    {t('contractHeader.headerContent.expiration')} <MOMENT to={props.Contract?.contractEndDate} unit="days">
                                        {Today}
                                    </MOMENT>
                                </strong>
                            ) : (
                                null
                            )}
                        </Label>
                    </div>
                </div>
            </div>
            <div className='ContractActions'>
                {props.Contract && Actions}
            </div>
        </div>
    </>
    );


    return (
        <>
            {ErrorSection}
            {HeaderContent}
            {Forms}
        </>
    );

}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ContractHeaderComponent as any);