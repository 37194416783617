import { IIconProps } from "@fluentui/react";

// stores
import * as SettlementDetailStore from '../../../redux/middleware/SettlementDetailsMiddleware';

//State
import { AppState } from "../../../redux/reducers";


// models
import { SettlementDetailConceptValue } from "../../../models/Entities/SettlementDetailConceptValue/SettlementDetailConceptValue";
import { ErrorType } from "../../../models/HttpError";
import { Settlement } from "../../../models/Entities/Settlements/Settlement";
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";

interface IProps {
    SettlementId: number;
    SettlementDetailId: number;
}
  
interface ConnectedProps {
isLoading: boolean;
isUpdating: boolean;
isUpdatedSuccessfully: boolean;
isLoadingSuccessfully: boolean;
failOnUpdating: boolean;
failOnLoading: boolean;
error: ErrorType | undefined;
CurrentDetail: SettlementDetail | undefined;
}

interface DispatchProps {
    GetSettlementDetailById: typeof SettlementDetailStore.actionCreators.GetSettlementDetailById;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
isLoading: state.SettlementDetails?.isLoadingAll,
isUpdating: state.SettlementDetails?.isLoadingAll,
isUpdatedSuccessfully: state.SettlementDetails?.successLoadingAll,
isLoadingSuccessfully: state.SettlementDetails?.successLoadingAll,
failOnUpdating: state.SettlementDetails?.failOnLoadingAll,
failOnLoading: state.SettlementDetails?.failOnLoadingAll,
isDeleting: state.SettlementDetails?.isDeletingOne,
isDeletedSuccessfully: state.SettlementDetails?.successDeletingOne,
failOnDeleting: state.SettlementDetails?.failOnDeletingOne,
error: state.SettlementDetails?.error,
Settlement: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId ) : undefined,
CurrentDetail: ownProps.SettlementDetailId ? 
    ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.find((details: SettlementDetail) => details.id === ownProps.SettlementDetailId) : undefined 
    : 
    undefined
});

export const mapDispatchToProps = {
...SettlementDetailStore.actionCreators
};


//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const EditIcon: IIconProps = { iconName: 'Edit' };
export const Exclude: IIconProps = { iconName: 'Share' };
export const Include: IIconProps = { iconName: 'Installation' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const VerifycationOk: IIconProps = { iconName: 'CheckMark' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };
export const ItemObservedIcon: IIconProps = { iconName: 'SkypeMessage' };

