import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractStage } from "../../../../models/Entities/Contracts/ContractStage";
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractStageStore from '../../../../redux/middleware/ContractStageMiddleware';
import * as ContractStore from '../../../../redux/middleware/ContractMiddleware';

//State
import { AppState } from "../../../../redux/reducers";
import { StageCycle } from "../../../../models/Entities/Contracts/StageCycle";
import { ContractAddendum } from "../../../../models/Entities/Contracts/ContractAddendum";


interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    ContractId: number;
    ContractAddendumId: number;
    ContractStageId: number | undefined;
    CurrentCycles: StageCycle[] | undefined;
}
  
interface ConnectedProps {
    // adding
    isSaving: boolean;
    isSavedSuccessfully: boolean;
    failOnSaving: boolean;

    error: ErrorType | undefined;

    Contract: Contract;
    Stage: ContractStage;
    TotalStages: number;
}
  
interface DispatchProps {
    GetContractStageById: typeof ContractStageStore.actionCreators.GetContractStageById;
    AddContractStage: typeof ContractStageStore.actionCreators.AddContractStage;
    UpdateContractStage: typeof ContractStageStore.actionCreators.UpdateContractStage;
    GetContractById: typeof ContractStore.actionCreators.GetContractById;
}
  
export type Props = IProps & ConnectedProps & DispatchProps;
  
export const mapStateToProps = (state: AppState, ownProps: IProps) => {
    
    const currentContract = state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId ) || undefined;
    const currentAddendum = currentContract?.addendums?.find((item: ContractAddendum) => item.id === ownProps.ContractAddendumId) || undefined;
    const currentTotalStages = currentAddendum?.stages?.length || 1;
    const currentStage = currentAddendum?.stages?.find((item: ContractStage) => item.id === ownProps.ContractStageId) || undefined;

    return {
    isSaving: state.ContractStage?.isAddingNewOne || currentStage?.isUpdating || false,
    isSavedSuccessfully: state.ContractStage?.successAddingNewOne || currentStage?.isUpdatedSuccessfully || false,
    failOnSaving: state.ContractStage?.failOnAddingNewOne || currentStage?.failOnUpdating || false,
    error: currentStage?.errorOnUpdating || state.ContractStage?.error,
    
    Contract: currentContract,
    Stage: currentStage,
    TotalStages: currentTotalStages
    }
};
  
export const mapDispatchToProps = {
    ...ContractStageStore.actionCreators,
    ...ContractStore.actionCreators
};