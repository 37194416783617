// actions
import * as Actions from '../actions';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/MedicalProtocols.Services';
import { Protocol } from '../../models/Entities/Protocols/Protocol';
import { ProtocolPractitioner } from '../../models/Entities/Protocols/ProtocolPractitioner';
import { ErrorType } from '../../models/HttpError';
import { ProtocolPractitionerStudyTask } from '../../models/Entities/Protocols/ProtocolPractitionerStudyTask';

// known action types
type KnownAction =
    | Actions.ProtocolPractitioners.ProtocolPractitiones_GetAll_Request_Action
    | Actions.ProtocolPractitioners.ProtocolPractitiones_GetAll_Receive_Action
    | Actions.ProtocolPractitioners.ProtocolPractitiones_GetAll_Fail_Action
    | Actions.ProtocolPractitioners.ProtocolPractitionesStudyTask_GetAll_Request_Action
    | Actions.ProtocolPractitioners.ProtocolPractitionesStudyTask_GetAll_Receive_Action
    | Actions.ProtocolPractitioners.ProtocolPractitionesStudyTask_GetAll_Fail_Action;

export const actionCreators = {
    GetAllProtocolPractitioners:
        (protocolId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOL_PRACTITIONERS_GETALL_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            if (state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === protocolId)) {
                dispatch({
                    type: 'PROTOCOL_PRACTITIONERS_GETALL_REQUEST_ACTION',
                    protocolId: protocolId
                });

                Services.GetProtocolPractitioners(protocolId, token)
                    .then((protocolPractitioners: ProtocolPractitioner[]) => {
                        dispatch({
                            type: 'PROTOCOL_PRACTITIONERS_GETALL_RECEIVE_ACTION',
                            protocolPractitioners: protocolPractitioners,
                            protocolId: protocolId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'PROTOCOL_PRACTITIONERS_GETALL_FAIL_ACTION',
                            error: error
                        })
                    );
            }
        },
    GetAllProtocolPractitionersStudyTask:
        (protocolId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'PROTOCOL_PRACTITIONERS_STUDYTASK_GETALL_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            if (state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === protocolId)) {
                dispatch({
                    type: 'PROTOCOL_PRACTITIONERS_STUDYTASK_GETALL_REQUEST_ACTION',
                    protocolId: protocolId
                });

                Services.GetProtocolPractitionersStudyTask(protocolId, token)
                    .then((protocolPractitioners: ProtocolPractitionerStudyTask[]) => {
                        dispatch({
                            type: 'PROTOCOL_PRACTITIONERS_STUDYTASK_GETALL_RECEIVE_ACTION',
                            protocolPractitioners: protocolPractitioners,
                            protocolId: protocolId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'PROTOCOL_PRACTITIONERS_STUDYTASK_GETALL_FAIL_ACTION',
                            error: error
                        })
                    );
            }
        }
};

  