import { useParams } from "react-router-dom";
import { PageHeader } from "../../../commons/component/PageHeader/PageHeader";
import { Label, Pivot, PivotItem, Separator } from "@fluentui/react";

// components
import ProtocolHeaderComponent from "../../../components/Protocols/ProtocolHeader/ProtocolHeader.Component";
import ProtocolContractComponent from "../../../components/Protocols/ProtocolContract/ProtocolContract.Component";

// styles
import './ProtocolDetails.Styles.css';
import SettlementListComponent from "../../../components/Settlements/SettlementList/SettlementList.Component";
import PaymentAccountDetailsComponent from "../../../components/PaymentAccount/PaymentAccountDetails/PaymentAccountDetails.Component";

////i18Next
import { useTranslation, Trans } from 'react-i18next';
import ConceptListComponent from "../../../components/Contracts/Concept/ConceptList/ConceptList.Component";
import ProtocolActivityComponent from "../../../components/Protocols/ProtocolActivity/Protocol.Activity.Component";

export const ProtocolDetailPage = () => {
    const { ProtocolId } = useParams();

    //useTranslation
    const [t] = useTranslation()


    return (
    <>
        <PageHeader title={t('protocolDetailsPage.protocolDetailsTitle')}/>
        <ProtocolHeaderComponent ProtocolId={Number(ProtocolId)} />
        <div className="Section">
            <Pivot>
                <PivotItem headerText={t('protocolDetailsPage.protocolDetailsItem.activity')}>
                    <ProtocolActivityComponent ProtocolId={Number(ProtocolId)} />
                </PivotItem>
                <PivotItem headerText={t('protocolDetailsPage.protocolDetailsItem.contract')}>
                    <div className="VisitasSection">
                        <div>
                            <SettlementListComponent ProtocolId={Number(ProtocolId)} />
                        </div>
                        <div>
                            <ProtocolContractComponent ProtocolId={Number(ProtocolId)}  />
                        </div>
                    </div>
                </PivotItem>
                {/* <PivotItem headerText={t('protocolDetailsPage.protocolDetailsItem.accountStatus')}>
                    <PaymentAccountDetailsComponent />
                </PivotItem> */}
                {/* <PivotItem headerText="Conceptos a liquidar">
                    <ConceptListComponent 
                        ContractId={Number(ContractId)}
                    />
                </PivotItem> */}
            </Pivot>
        </div>
    </>);
}