import { ProfessionalAvailability } from './../../models/Entities/ProfessionalAvailability/ProfessionalAvailability';
import { AppThunkAction } from '../reducers';

// Actions
import * as Actions from '../actions';

// Services
import * as service from '../../services/ProfessionalAvailability.Services';
import { ErrorType } from '../../models/HttpError';

type KnownAction =
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetAll_Request_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetAll_Receive_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetAll_Fail_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetById_Request_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetById_Receive_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetById_Fail_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Add_Request_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Add_Receive_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Add_Fail_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Update_Request_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Update_Receive_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Update_Fail_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Delete_Request_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Delete_Receive_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Delete_Fail_Action;

export const ActionCreators = {
  GetAllProfessionalAvailability:
    (professionalId: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      
      const appState = getState();
      var token: string = '';

      if (appState.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROFESSIONAL_AVAILABILITY_GETALL_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = appState.oidc.user.access_token;
      };

      if (appState && appState.ProfessionalAvailability) {
        service
          .GetProfessionalAvailability(professionalId, token)
          .then((list: ProfessionalAvailability[]) => {
            dispatch({
              type: 'PROFESSIONAL_AVAILABILITY_GETALL_RECEIVE_ACTION',
              professionalAvailability: list
            });
          })
          .catch((error: ErrorType) => {
            dispatch({
              type: 'PROFESSIONAL_AVAILABILITY_GETALL_FAIL_ACTION',
              error: error
            });
          });
        dispatch({
          type: 'PROFESSIONAL_AVAILABILITY_GETALL_REQUEST_ACTION',
          professionalId: professionalId
        });
      }
    },
  GetProfessionalAvailabilityById:
    (professionalAvailabilityId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      const appState = getState();
      var token: string = '';

      if (appState.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROFESSIONAL_AVAILABILITY_GETBYID_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      }
      else {
        token = appState.oidc.user.access_token;
      };

      if (appState && appState.ProfessionalAvailability) {
        service
          .GetProfessionalAvailabilityById(professionalAvailabilityId, token)
          .then((professionalAvailability: ProfessionalAvailability) => {
            dispatch({
              type: 'PROFESSIONAL_AVAILABILITY_GETBYID_RECEIVE_ACTION',
              professionalAvailability: professionalAvailability
            });
          })
          .catch((error: ErrorType) => {
            dispatch({
              type: 'PROFESSIONAL_AVAILABILITY_GETBYID_FAIL_ACTION',
              error: error
            });
          });
        dispatch({
          type: 'PROFESSIONAL_AVAILABILITY_GETBYID_REQUEST_ACTION',
          professionalAvailabilityId: professionalAvailabilityId
        });
      }
    },
  AddProfessionalAvailability:
    (
      newProfessionalAvailability: ProfessionalAvailability
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      
      const appState = getState();
      var token: string = '';

      if (appState.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROFESSIONAL_AVAILABILITY_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = appState.oidc.user.access_token;
      };
      
      if (appState && appState.ProfessionalAvailability) {
        service
          .CreateProfessionalAvailability(newProfessionalAvailability, token)
          .then((professionalAvailability: ProfessionalAvailability) => {
            dispatch({
              type: 'PROFESSIONAL_AVAILABILITY_ADD_RECEIVE_ACTION',
              professionalAvailability: professionalAvailability
            });
          })
          .catch((error: ErrorType) => {
            dispatch({
              type: 'PROFESSIONAL_AVAILABILITY_ADD_FAIL_ACTION',
              error: error
            });
          });
        dispatch({
          type: 'PROFESSIONAL_AVAILABILITY_ADD_REQUEST_ACTION',
          newProfessionalAvailability: newProfessionalAvailability
        });
      }
    },
  UpdateProfessionalAvailability:
    (
      professionalAvailability: ProfessionalAvailability
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      
      const appState = getState();
      var token: string = '';

      if (appState.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROFESSIONAL_AVAILABILITY_UPDATE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = appState.oidc.user.access_token;
      };

      if (appState && appState.ProfessionalAvailability) {
        service
          .UpdateProfessionalAvailability(professionalAvailability, token)
          .then((Availability: ProfessionalAvailability) => {
            dispatch({
              type: 'PROFESSIONAL_AVAILABILITY_UPDATE_RECEIVE_ACTION',
              professionalAvailability: Availability
            });
          })
          .catch((error: ErrorType) => {
            dispatch({
              type: 'PROFESSIONAL_AVAILABILITY_UPDATE_FAIL_ACTION',
              error: error
            });
          });
        dispatch({
          type: 'PROFESSIONAL_AVAILABILITY_UPDATE_REQUEST_ACTION',
          professionalAvailability: professionalAvailability
        });
      }
    },
  DeleteProfessionalAvailability:
    (professionalAvailabilityId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      const appState = getState();
      var token: string = '';

      if (appState.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROFESSIONAL_AVAILABILITY_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = appState.oidc.user.access_token;
      }
      
      service
        .DeleteProfessionalAvailability(professionalAvailabilityId, token)
        .then(() => {
          dispatch({
            type: 'PROFESSIONAL_AVAILABILITY_DELETE_RECEIVE_ACTION',
            professionalAvailabilityId: professionalAvailabilityId
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'PROFESSIONAL_AVAILABILITY_DELETE_FAIL_ACTION',
            error: error
          });
        });
      dispatch({
        type: 'PROFESSIONAL_AVAILABILITY_DELETE_REQUEST_ACTION',
        professionalAvailabilityId: professionalAvailabilityId
      });
      
    }
};
