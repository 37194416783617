
import { connect } from 'react-redux';
import { Persona, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOption, TagPickerProps, Tag, Field, Spinner, useTagPickerFilter } from '@fluentui/react-components';
import { useEffect, useState } from 'react';

// component functions
import * as ComponentFunctions from './UserSelector.Functions';

const UserSelectorComponent = (props: ComponentFunctions.Props) => {

    // for filtering person options
    const [query, setQuery] = useState<string>("");
    const [usersSelected, setUsersSelected] = useState<string[]>(props.SelectedUsersIds);


    useEffect(() => {
        props.onChangeSelection && props.onChangeSelection(ComponentFunctions.GetUserBySelectedIdentifier(props.Users, usersSelected));
    }, [usersSelected]);


    const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {

        if (data.value === "no-matches" || data.selectedOptions.length > props.selectionLimit) {
            setQuery("");
            return;
        } 
        setUsersSelected(data.selectedOptions);
        setQuery("");
    };

    const children = useTagPickerFilter({
        query,
        options: props.UsersOptions,
        noOptionsElement: (
            <TagPickerOption value="no-matches">
                We couldn't find any matches
            </TagPickerOption>
        ),
        renderOption: (option) => (
                <TagPickerOption
                    text={option} // Add the 'text' property with the value of 'option'
                    key={option}
                    media={
                        <Persona
                            name={ComponentFunctions.GetUserByIdentifier(props.Users, option)?.fullName || 'No existente'}
                            avatar={{ shape: "square", color: "colorful" }}
                            presence={{
                                status: ComponentFunctions.GetUserByIdentifier(props.Users, option)?.active ? "available" : "offline",
                            }}
                            secondaryText={ComponentFunctions.GetUserByIdentifier(props.Users, option)?.speciality}
                            {...props}
                        />
                    }
                    value={option}
                >

                </TagPickerOption>
        ),

        filter: (option) =>
            ComponentFunctions.GetAllUserByName(props.Users, usersSelected, query)?.some((user) => user.userId === option) || false
    });

    return (
        <Field label={props.fieldName || 'No title'} required={props.isRequired || false}>
            {props.isLoadingSuccessfully && <TagPicker
                size={"medium"}
                onOptionSelect={onOptionSelect}
                selectedOptions={usersSelected}
            >
                <TagPickerControl>
                    <TagPickerGroup>
                        {usersSelected.map((userID: string) => (

                            <Tag
                                key={userID}
                                shape="rounded"
                                value={userID}
                            >
                                <Persona
                                    size="extra-small"
                                    name={ComponentFunctions.GetUserByIdentifier(props.Users, userID)?.fullName || 'No existente'}
                                    avatar={{ shape: "square", color: "colorful" }}
                                    {...props}
                                />
                            </Tag>

                        ))}
                    </TagPickerGroup>
                    <TagPickerInput aria-label="Seleccionadas" value={query} onChange={(e) => setQuery(e.target.value)} />
                </TagPickerControl>
                <TagPickerList>
                    {children}
                </TagPickerList>
            </TagPicker>}
            {(props.isLoading) && <Spinner size="tiny" />}
        </Field>
    )

};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(UserSelectorComponent as any);

