import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';
import { Contract } from '../../models/Entities/Contracts/Contract';
import { ContractConcept } from '../../models/Entities/Contracts/ContractConcept';
import { ContractConceptPaymentSchedule } from '../../models/Entities/Contracts/ContractConceptPaymentSchedule';
import { NewContractConceptPaymentSchedule } from '../../models/Entities/Contracts/NewContractConceptPaymentSchedule';

// services
import * as Services from '../../services/ContractConceptPaymentSchedule.Services';

// actions
import * as Actions from '../actions';

type KnownAction =
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_GetAllByContractConceptId_Request_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_GetAllByContractConceptId_Receive_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_GetAllByContractConceptId_Fail_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_UpdateAllByContractConceptId_Request_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_UpdateAllByContractConceptId_Receive_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_UpdateAllByContractConceptId_Fail_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_GetById_Request_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_GetById_Receive_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_GetById_Fail_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Add_Request_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Add_Receive_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Add_Fail_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Update_Request_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Update_Receive_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Update_Fail_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Delete_Request_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Delete_Receive_Action
    | Actions.ContractConceptPaymentsSchedule.ContractConceptPaymentSchedule_Delete_Fail_Action;
    
export const actionCreators = {
    GetAllContractsConceptPaymentSchedule:
        (contractId: number, contractAddendumId: number, contractConceptId: number ): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {

            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION',
                    contractId: contractId,
                    contractConceptId: contractConceptId,
                    contractAddendumId: contractAddendumId,
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            if (!state.Contract?.successLoadingAll || 
                state.Contract?.list?.find((contract: Contract) => contract.id === contractId)?.concepts?.find((contractConcept: ContractConcept) => contractConcept.id === contractConceptId)?.paymentSchedule?.length === 0 ) {
                dispatch({
                    type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_ALL_BY_CONTRACTCONCEPTID_REQUEST_ACTION',
                    contractConceptId: contractConceptId
                });

                Services.GetContractsConceptPaymentSchedule(contractConceptId, token)
                    .then((contractConceptPaymentsSchedule: ContractConceptPaymentSchedule[]) => {
                        dispatch({
                            type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION',
                            contractConceptPaymentSchedules: contractConceptPaymentsSchedule,
                            contractConceptId: contractConceptId,
                            contractId: contractId,
                            contractAddendumId: contractAddendumId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION',
                            contractId: contractId,
                            contractConceptId: contractConceptId,
                            contractAddendumId: contractAddendumId,
                            error: error
                        })
                    );
            } else {
                dispatch({
                    type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_ALL_BY_CONTRACTCONCEPTID_REQUEST_ACTION',
                    contractConceptId: contractConceptId
                });

                Services.GetContractsConceptPaymentSchedule(contractConceptId, token)
                    .then((contractConceptPaymentSchedule: ContractConceptPaymentSchedule[]) => {
                        dispatch({
                            type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION',
                            contractConceptPaymentSchedules: contractConceptPaymentSchedule,
                            contractConceptId: contractConceptId,
                            contractId: contractId,
                            contractAddendumId: contractAddendumId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION',
                            contractId: contractId,
                            contractConceptId: contractConceptId,
                            contractAddendumId: contractAddendumId,
                            error: error
                        })
                    );
            }
        },
    GetContractConceptPaymentScheduleById:
        (id: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_BY_ID_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Unauthorized',
                        Errors: []
                    }
                });
                return;
            }

            dispatch({
                type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_BY_ID_REQUEST_ACTION',
                contractConceptPaymentScheduleId: id
            });

            Services.GetContractConceptPaymentScheduleById(id, token)
                .then((contractConceptPaymentSchedule: ContractConceptPaymentSchedule) => {
                    dispatch({
                        type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_BY_ID_RECEIVE_ACTION',
                        contractConceptPaymentSchedule: contractConceptPaymentSchedule
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_BY_ID_FAIL_ACTION',
                        error: error
                    })
                );

        },
    AddContractConceptPaymenSchedule:
        (contractId: number, addendumId: number, newContractConceptPaymentSchedule: NewContractConceptPaymentSchedule): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_ADD_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Unauthorized',
                        Errors: []
                    }
                });
                return;
            }

            dispatch({
                type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_ADD_REQUEST_ACTION',
                newContractConceptPaymentSchedule: newContractConceptPaymentSchedule,
                contactAddendumId: addendumId,
                contractId: contractId


            });

            Services.CreateContractConceptPaymentSchedule(newContractConceptPaymentSchedule, token)
                    .then((contractConceptPaymentSchedule: ContractConceptPaymentSchedule) => {
                        dispatch({
                            type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_ADD_RECEIVE_ACTION',
                            contractConceptPaymentSchedule: contractConceptPaymentSchedule,
                            contractConceptId: contractConceptPaymentSchedule.contractConceptId,
                            contactAddendumId: addendumId,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_ADD_FAIL_ACTION',
                            error: error
                        })
                    );
        },
    UpdateContractConceptPaymentSchedule:
        (contractId:number, addendumId: number, contractConceptPaymentSchedule: ContractConceptPaymentSchedule): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Unauthorized',
                        Errors: []
                    }
                });
                return;
            }

            dispatch({
                type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_REQUEST_ACTION',
                contractConceptPaymentSchedule: contractConceptPaymentSchedule,
                contractConceptId: contractConceptPaymentSchedule.contractConceptId,
                contractAddendumId: addendumId,
                contractId: contractId
            });

            Services.UpdateContractConceptPaymentSchedule(contractConceptPaymentSchedule, token)
                .then((contractConceptPaymentSchedule: ContractConceptPaymentSchedule) => {
                    dispatch({
                        type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_RECEIVE_ACTION',
                        contractConceptPaymentSchedule: contractConceptPaymentSchedule,
                        contractConceptId: contractConceptPaymentSchedule.contractConceptId,
                        contractAddendumId: addendumId,
                        contractId: contractId
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_FAIL_ACTION',
                        error: error
                    })
                );

        },
    DeleteContractConceptPaymentSchedule:
        (contractConceptPaymentScheduleId: number, contractConceptId: number, addendumId: number, contractId: number): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_DELETE_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Unauthorized',
                        Errors: []
                    }
                });
                return;
            }

            dispatch({
                type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_DELETE_REQUEST_ACTION',
                contractConceptPaymentScheduleId: contractConceptPaymentScheduleId
            });

            Services.DeleteContractConceptPaymentSchedule(contractConceptPaymentScheduleId, token)
                .then((contractConceptPaymentSchedule: ContractConceptPaymentSchedule) => {
                    dispatch({
                        type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_DELETE_RECEIVE_ACTION',
                        contractConceptPaymentScheduleId: contractConceptPaymentScheduleId,
                        contractConceptId: contractConceptId,
                        contractAddendumId: addendumId,
                        contractId: contractId
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTCONCEPTPAYMENTSCHEDULE_DELETE_FAIL_ACTION',
                        error: error
                    })
                );
            }
};
