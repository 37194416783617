import { Button, Field, Label, MessageBar, MessageBarActions, MessageBarBody, MessageBarTitle, Persona, Spinner, Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOnOptionSelectData, TagPickerOption, TagPickerProps, useTagPickerFilter } from "@fluentui/react-components"
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { DismissRegular } from "@fluentui/react-icons";

// funcions
import * as componentFunctions from './PersonSelector.Functions';

// styles
import './PersonSelector.Styles.css';


const PersonSelectorComponent = (props: componentFunctions.Props) => {

    useEffect(() => { props.GetAllPersons() }, []);

    const FixIdentificadores = () => {
        props.FixPersonIdentifiers();
    }

    // for filtering person options
    const [query, setQuery] = useState<string>("");
    const [personsSelected, setPersonsSelected] = useState<string[]>(props.PersonsSelected);


    useEffect(() => {
        props.onChangeSelection && props.onChangeSelection(componentFunctions.GetPersonsBySelectedIdentifier(props.Persons, personsSelected));
    }, [personsSelected]);


    const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {

        if (data.value === "no-matches" || data.selectedOptions.length > props.selectionLimit) {
            setQuery("");
            return;
        } 
        setPersonsSelected(data.selectedOptions);
        setQuery("");
    };

    const children = useTagPickerFilter({
        query,
        options: props.PersonsOptions,
        noOptionsElement: (
            <TagPickerOption value="no-matches">
                We couldn't find any matches
            </TagPickerOption>
        ),
        renderOption: (option) => (
                <TagPickerOption
                    text={option} // Add the 'text' property with the value of 'option'
                    key={option}
                    media={
                        <Persona
                            name={componentFunctions.GetPersonByIdentifier(props.Persons, option)?.businessName || 'No existente'}
                            avatar={{ shape: "square", color: "colorful" }}
                            {...props}
                        />
                    }
                    value={option}
                >

                </TagPickerOption>
        ),

        filter: (option) =>
            componentFunctions.GetAllPersonByName(props.Persons, personsSelected, query)?.some((person) => person.personIdentifier === option) || false
    });

    return (
        <Field label={props.fieldLabel || 'No title'} required={props.isRequired || false}>
            {!props.isErrorInPersonIdentifierDetected && (!props.isLoading || !props.isUpdating) && props.isLoadingSuccessfully && <TagPicker
                size={"medium"}
                onOptionSelect={onOptionSelect}
                selectedOptions={personsSelected}
            >
                <TagPickerControl>
                    <TagPickerGroup>
                        {personsSelected.map((personIdentifier: string) => (

                            <Tag
                                key={personIdentifier}
                                shape="rounded"
                                value={personIdentifier}
                            >
                                <Persona
                                    size="extra-small"
                                    name={componentFunctions.GetPersonByIdentifier(props.Persons, personIdentifier)?.businessName || 'No existente'}
                                    avatar={{ shape: "square", color: "colorful" }}
                                    {...props}
                                />
                            </Tag>

                        ))}
                    </TagPickerGroup>
                    <TagPickerInput aria-label="Seleccionadas" value={query} onChange={(e) => setQuery(e.target.value)} />
                </TagPickerControl>
                <TagPickerList>
                    {children}
                </TagPickerList>
            </TagPicker>}
            {props.isErrorInPersonIdentifierDetected &&
                <MessageBar as="div" layout="multiline">
                    <MessageBarBody>
                        <MessageBarTitle>Atención</MessageBarTitle>
                        Se detecto un problema con los identificadores, por favor presione en corregir{" "}
                        {/* <Link>Link</Link> */}
                    </MessageBarBody>
                    <MessageBarActions
                        containerAction={
                            <Button
                                aria-label="dismiss"
                                appearance="transparent"
                                icon={<DismissRegular />}
                            />
                        }
                    >
                        <Button onClick={FixIdentificadores}>Corregir identificadores</Button>
                    </MessageBarActions>
                </MessageBar>
            }
            {(props.isLoading || props.isUpdating) && <Spinner size="tiny" />}
        </Field>
    )
}

export default connect(
    componentFunctions.mapStateToProps,
    componentFunctions.mapDispatchToProps
)(PersonSelectorComponent as any)