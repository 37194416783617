import {
  FontWeights,
  getTheme,
  IDropdownOption,
  IIconProps,
  IStackProps,
  mergeStyleSets
} from '@fluentui/react';

import { AppState } from '../../../../redux/reducers';
import { Contract } from '../../../../models/Entities/Contracts/Contract';

//stores 
import * as ContractStore from '../../../../redux/middleware/ContractMiddleware'
import { ErrorType } from '../../../../models/HttpError';



//#region Props

interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  Contract?: Contract;
  mode: string;
  RefererId: string | number | undefined;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  isDeleting: boolean;
  isDeletedSuccessfully: boolean;
  failOnDeleting: boolean;
  isAdding: boolean;
  isAddedSuccessfully: boolean;
  error: ErrorType | undefined;
  Contracts: Contract[];
}

interface MedicalServicesDispatchProps {
  GetAllContracts: typeof ContractStore.actionCreators.GetAllContracts;
  GetContractById: typeof ContractStore.actionCreators.GetContractById;
  AddContract: typeof ContractStore.actionCreators.AddContract;
  UpdateContract: typeof ContractStore.actionCreators.UpdateContract;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.Contract?.isLoadingOne,
  isUpdating: state.Contract?.isUpdatingOne,
  isUpdatedSuccessfully: state.Contract?.successUpdatingOne,
  isLoadingSuccessfully: state.Contract?.successLoadingOne,
  failOnUpdating: state.Contract?.failOnLoadingAll,
  failOnLoading: state.Contract?.failOnLoadingAll,
  isDeleting: state.Contract?.isDeletingOne,
  isDeletedSuccessfully: state.Contract?.successDeletingOne,
  failOnDeleting: state.Contract?.failOnDeletingOne,
  isAdding: state.Contract?.isAddingNewOne,
  isAddedSuccessfully: state.Contract?.successAddingNewOne,
  error: state.Contract?.error,
  Contracts: state.Contract?.list
});

export const mapDispatchToProps = {
  ...ContractStore.actionCreators
};

//#endregion Props


// icons
export const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const buttonStyles = {
  root: { marginRight: 8, class: 'ConfirmAction' }
};

export const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

export const theme = getTheme();
export const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '8px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    //padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    //flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});

export const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};

export const ContractStatusOptions: IDropdownOption[] = [
  { key: '1', text: 'Active' },
  { key: '2', text: 'Inactive' },
  { key: '3', text: 'Suspended' },
  { key: '4', text: 'Expired' },
  { key: '5', text: 'Canceled' },
  { key: '6', text: 'Terminated' }
];

export const ContractPaymentScheduleTypeOptions: IDropdownOption[] = [
  { key: '1', text: 'Diario' },
  { key: '2', text: 'Semanal' },
  { key: '3', text: 'Mensual' },
  { key: '4', text: 'Pago Unico' },
  { key: '5', text: 'A Demanda' }
];
