// actions
import * as Actions from '../actions';
import { AppThunkAction } from '../reducers';
import { Languaje } from '../../models/Entities/Settings/Languajes';
import { ErrorType } from '../../models/HttpError';

// services
import * as Services from '../../services/Languajes.Services';

// Locale
import { useTranslation } from "react-i18next";

type KnownAction =
| Actions.Languaje.Languaje_GetAll_Request_Action
| Actions.Languaje.Languaje_GetAll_Receive_Action
| Actions.Languaje.Languaje_GetAll_Fail_Action
| Actions.UserSettings.UserSettings_Languaje_Change_Receive_Action;

export const actionCreators = {
    GetAllLanguajes:
        (): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();

            dispatch({
                type: 'LANGUAJE_GET_ALL_REQUEST_ACTION'
            });

            Services.GetLanguajes()
            .then((Languajes: Languaje[]) => {

                dispatch({
                    type: 'LANGUAJE_GET_ALL_RECEIVE_ACTION',
                    Languajes: Languajes
                });

                Services.GetCurrentUserLanguaje().then((currentLanguaje: Languaje | undefined) => {
                    dispatch({
                        type: 'USER_SETTINGS_LANGUAJE_CHANGE_RECEIVE_ACTION',
                        Language: currentLanguaje!
                    });
                });
                
                


            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'LANGUAJE_GET_ALL_FAIL_ACTION',
                    error: error
                })
            );
    }
};