// Functions
import { Label, Spinner, SpinnerSize, Toggle } from '@fluentui/react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { PractitionerAssociationSettings } from '../../../models/Entities/PractitionerAssociations/Settings/PractitionerAssociationSettings';
import * as ComponentFunctions from './PractitionerSettingsFunctions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const PractitionerSettingsComponent = (
  props: ComponentFunctions.Props
) => {
  // events
  const onToggleChange = (checked: boolean) => {
    if (props.PractitionerSettings?.id) {
      props.UpdatePractitionerSettings({
        practitionerId: props.PractitionerId,
        enableIndividualPatientAttention: checked
      } as PractitionerAssociationSettings);
    } else {
      props.AddPractitionerSettings({
        practitionerId: props.PractitionerId,
        enableIndividualPatientAttention: checked
      });
    }
  };

  //useTranslation();
  const [t] = useTranslation();

  // effects

  useEffect(() => {
    props.GetPractitionerSettings(props.PractitionerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.PractitionerId]);

  // blocks
  const contentBlock = (
    <div>
      <Toggle
        label={t('practitionerSettings.blocks.individualPatient')} 
        defaultChecked={
          props.PractitionerSettings?.enableIndividualPatientAttention || false
        }
        onText={t('practitionerSettings.blocks.yes')}
        offText={t('practitionerSettings.blocks.no')}
        onChange={(
          event: React.MouseEvent<HTMLElement, MouseEvent>,
          checked?: boolean | undefined
        ) => (checked ? onToggleChange(checked) : null)}
      />
    </div>
  );
  const LoadingBlock = (
    <div className="LoadingBlock">
      <Spinner size={SpinnerSize.large} label={t('practitionerSettings.blocks.loading')}/>
    </div>
  );

  return props.IsLoading ? LoadingBlock : contentBlock;
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(PractitionerSettingsComponent as any);
