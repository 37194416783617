import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

// state
import { CashFlowDebtState, CashFlowDebtUnloadState } from '../states/CashFlow.Debt.State';
import { Debt } from '../../models/Entities/CashFlow/Debt/Debt';

type KnownAction =
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsCreditorRequestAction
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsCreditorSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsCreditorFailureAction
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsDebtorRequestAction
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsDebtorSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsDebtorFailureAction
    | Actions.CashFlowDebts.CashFlowDebtGetbyIdRequestAction
    | Actions.CashFlowDebts.CashFlowDebtGetByIdSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtGetByIdFailureAction
    | Actions.CashFlowDebts.CashFlowDebtCreateRequestAction
    | Actions.CashFlowDebts.CashFlowDebtCreateSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtCreateFailureAction
    | Actions.CashFlowDebts.CashFlowDebtUpdateRequestAction
    | Actions.CashFlowDebts.CashFlowDebtUpdateSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtUpdateFailureAction
    | Actions.CashFlowDebts.CashFlowDebtDeleteRequestAction
    | Actions.CashFlowDebts.CashFlowDebtDeleteSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtDeleteFailureAction;

export const CashFlowDebtsReducer: Reducer<CashFlowDebtState> = (
    state: CashFlowDebtState | undefined,
    incomingAction: Action
): CashFlowDebtState => {
    if (state === undefined) {
        return CashFlowDebtUnloadState as CashFlowDebtState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CASHFLOW-DEBT_GETALLAS_CREDITOR_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CASHFLOW-DEBT_GETALLAS_CREDITOR_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                DebtsAsCreditor: action.payload,
                error: undefined
            };
        case 'CASHFLOW-DEBT_GETALLAS_CREDITOR_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CASHFLOW-DEBT_GETALLAS_DEBTOR_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CASHFLOW-DEBT_GETALLAS_DEBTOR_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                DebtsAsDebtor: action.payload,
                error: undefined
            };
        case 'CASHFLOW-DEBT_GETALLAS_DEBTOR_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CASHFLOW-DEBT_GETBYID_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: undefined,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CASHFLOW-DEBT_GETBYID_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                DebtsAsCreditor: action.payload.creditor === action.tenantId ? [...state.DebtsAsCreditor.filter((debt: Debt) => debt.id === action.payload.id), action.payload] : state.DebtsAsCreditor,
                DebtsAsDebtor: action.payload.debtor === action.tenantId ? [...state.DebtsAsDebtor.filter((debt: Debt) => debt.id === action.payload.id), action.payload] : state.DebtsAsDebtor,
                error: undefined
            };
        case 'CASHFLOW-DEBT_GETBYID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CASHFLOW-DEBT_CREATE_REQUEST':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CASHFLOW-DEBT_CREATE_SUCCESS':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                DebtsAsCreditor: action.payload.creditor === action.tenantId ? [...state.DebtsAsCreditor, action.payload] : state.DebtsAsCreditor,
                DebtsAsDebtor: action.payload.debtor === action.tenantId ? [...state.DebtsAsDebtor, action.payload] : state.DebtsAsDebtor,
                error: undefined
            };
        case 'CASHFLOW-DEBT_CREATE_FAILURE':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        case 'CASHFLOW-DEBT_UPDATE_REQUEST':

            var DebtToUpdate = action.payload.creditor === action.tenantId ? state.DebtsAsCreditor.find((debt: Debt) => debt.id === action.payload.id) : state.DebtsAsDebtor.find((debt: Debt) => debt.id === action.payload.id);

            if(DebtToUpdate){
                DebtToUpdate.isUpdating = true;
                DebtToUpdate.isUpdatedSuccessfully = undefined;
                DebtToUpdate.failOnUpdating = false;
            }

            return {
                ...state,
                isUpdatingOne: true,
                successUpdatingOne: undefined,
                failOnUpdatingOne: false,
                DebtsAsCreditor: action.payload.creditor === action.tenantId ? [...state.DebtsAsCreditor.filter((debt: Debt) => debt.id === action.payload.id), action.payload] : state.DebtsAsCreditor,
                DebtsAsDebtor: action.payload.debtor === action.tenantId ? [...state.DebtsAsDebtor.filter((debt: Debt) => debt.id === action.payload.id), action.payload] : state.DebtsAsDebtor,
                error: undefined
            };
        case 'CASHFLOW-DEBT_UPDATE_SUCCESS':

            var debtUpdated = action.payload.creditor === action.tenantId ? state.DebtsAsCreditor.find((debt: Debt) => debt.id === action.payload.id) : state.DebtsAsDebtor.find((debt: Debt) => debt.id === action.payload.id);

            if(debtUpdated){
                debtUpdated.isUpdating = false;
                debtUpdated.isUpdatedSuccessfully = true;
                debtUpdated.failOnUpdating = false;
            }

            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: true,
                failOnUpdatingOne: false,
                DebtsAsCreditor: action.payload.creditor === action.tenantId ? [...state.DebtsAsCreditor.filter((debt: Debt) => debt.id === action.payload.id), action.payload] : state.DebtsAsCreditor,
                DebtsAsDebtor: action.payload.debtor === action.tenantId ? [...state.DebtsAsDebtor.filter((debt: Debt) => debt.id === action.payload.id), action.payload] : state.DebtsAsDebtor,
                error: undefined
            };
        case 'CASHFLOW-DEBT_UPDATE_FAILURE':

            var debtFailedOnUpdate = state.DebtsAsCreditor.find((debt: Debt) => debt.id === action.debtId) || state.DebtsAsDebtor.find((debt: Debt) => debt.id === action.debtId);

            if(debtFailedOnUpdate){
                debtFailedOnUpdate.isUpdating = false;
                debtFailedOnUpdate.isUpdatedSuccessfully = false;
                debtFailedOnUpdate.failOnUpdating = true;
                debtFailedOnUpdate.error = action.error;
            }

            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: false,
                failOnUpdatingOne: true,
                DebtsAsCreditor: debtFailedOnUpdate && state.DebtsAsCreditor?.some((debt: Debt) => debt.id === action.debtId) ? [...state.DebtsAsCreditor.filter((debt: Debt) => debt.id === action.debtId), debtFailedOnUpdate] : state.DebtsAsCreditor,
                DebtsAsDebtor: debtFailedOnUpdate && state.DebtsAsDebtor?.some((debt: Debt) => debt.id === action.debtId) ? [...state.DebtsAsDebtor.filter((debt: Debt) => debt.id === action.debtId), debtFailedOnUpdate] : state.DebtsAsDebtor,
                error: action.error
            };
        case 'CASHFLOW-DEBT_DELETE_REQUEST':
            return {
                ...state,
                isDeletingOne: true,
                successDeletingOne: undefined,
                failOnDeletingOne: false,
                error: undefined
            };
        case 'CASHFLOW-DEBT_DELETE_SUCCESS':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: true,
                failOnDeletingOne: false,
                DebtsAsCreditor: state.DebtsAsCreditor?.some((debt: Debt) => debt.id === action.payload) ? [...state.DebtsAsCreditor.filter((debt: Debt) => debt.id === action.payload)] : state.DebtsAsCreditor,
                DebtsAsDebtor: state.DebtsAsDebtor?.some((debt: Debt) => debt.id === action.payload) ? [...state.DebtsAsDebtor.filter((debt: Debt) => debt.id === action.payload)] : state.DebtsAsDebtor,
                error: undefined
            };
        case 'CASHFLOW-DEBT_DELETE_FAILURE':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: false,
                failOnDeletingOne: true,
                error: action.error
            };
        default:
            return state;
    }
}