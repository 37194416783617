import { AgreementServiceDetailsUnloadedState } from './../states/AgreementServiceDetailsState';
import { AgreementServiceDetailsState } from '../states/AgreementServiceDetailsState';

// Actions
import * as Actions from '../actions';
import { AgreementServicesDetail } from '../../models/Entities/AgreementServices/AgreementServicesDetail';

type KnownAction =
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetAllByAgreementId_Request_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetAllByAgreementId_Receive_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetAllByAgreementId_Fail_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetById_Request_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetById_Receive_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_GetById_Fail_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Add_Request_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Add_Receive_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Add_Fail_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Update_Request_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Update_Receive_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Update_Fail_Action;

export const AgreementServiceDetailsReducer = (
  state: AgreementServiceDetailsState | undefined,
  action: KnownAction
): AgreementServiceDetailsState => {
  if (state === undefined) {
    return AgreementServiceDetailsUnloadedState as AgreementServiceDetailsState;
  }

  switch (action.type) {
    case 'AGREEMENT_SERVICESDETAIL_GET_ALL_BY_AGREEMENT_ID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'AGREEMENT_SERVICESDETAIL_GET_ALL_BY_AGREEMENT_ID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        list: action.agreementServicesDetails,
        error: undefined
      };
    case 'AGREEMENT_SERVICESDETAIL_GET_ALL_BY_AGREEMENT_ID_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'AGREEMENT_SERVICESDETAIL_GET_BY_ID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'AGREEMENT_SERVICESDETAIL_GET_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        list: [action.agreementServicesDetail],
        error: undefined
      };
    case 'AGREEMENT_SERVICESDETAIL_GET_BY_ID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'AGREEMENT_SERVICESDETAIL_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'AGREEMENT_SERVICESDETAIL_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        list: [...state.list, action.agreementServicesDetail],
        error: undefined
      };
    case 'AGREEMENT_SERVICESDETAIL_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'AGREEMENT_SERVICESDETAIL_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'AGREEMENT_SERVICESDETAIL_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        list: [
          ...state.list.filter(
            (item: AgreementServicesDetail) =>
              item.id !== action.agreementServicesDetail.id
          ),
          action.agreementServicesDetail
        ],
        error: undefined
      };
    case 'AGREEMENT_SERVICESDETAIL_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };

    default:
      return state;
  }
};
