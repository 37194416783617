import { MedicalInsuranceAgreement } from './../../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';
import { ErrorType } from '../../../models/HttpError';

// store
import * as MedicalInsuranceAgreementsStore from '../../../redux/middleware/MedicalInsuranceAgreementsMiddleware';
import { AppState } from '../../../redux/reducers';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';

// Props

interface OwnProps {
  MedicalInsuranceId: number;
}

interface StateProps {
  loading?: boolean;
  loadingSuccess?: boolean;
  failOnLoading?: boolean;
  error?: ErrorType;
  agreements?: MedicalInsuranceAgreement[];
}

interface DispatchProps {
  RequestAgreements: typeof MedicalInsuranceAgreementsStore.actionCreators.GetAll;
}

interface CustomEvent {
  onSelectedAgreement?: (
    agreement: MedicalInsuranceAgreement | undefined
  ) => void;
}

// connector

export const mapStateToProps = (
  state: AppState,
  _ownProps: OwnProps
): StateProps => ({
  loading: state.MedicalInsuranceAgreements?.isLoadingAll,
  loadingSuccess: state.MedicalInsuranceAgreements?.successLoadingAll,
  failOnLoading: state.MedicalInsuranceAgreements?.failOnLoadingAll,
  error: state.MedicalInsuranceAgreements?.error,
  agreements: state.MedicalInsuranceAgreements?.list.filter(
    (agreement: MedicalInsuranceAgreement) =>
      agreement.medicalInsuranceId === _ownProps.MedicalInsuranceId
  )
});

export const mapDispatchToProps = {
  RequestAgreements: MedicalInsuranceAgreementsStore.actionCreators.GetAll
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & CustomEvent & DispatchProps & OwnProps;

export type Validity = {
  status: string;
  message: string;
  endValidity: Date | undefined;
};
export function getStatus(agreement: MedicalInsuranceAgreement): Validity {
  const today = new Date();
  const endValidity: Date | undefined = agreement.agreementEndDate
    ? new Date(agreement.agreementEndDate)
    : undefined;
  const startValidity: Date | undefined = agreement.agreementStartDate
    ? new Date(agreement.agreementStartDate)
    : undefined;
  if (!endValidity && !startValidity) {
    return {
      status: 'NoValidity',
      message: 'No Validity',
      endValidity: undefined
    };
  }
  if (endValidity && today > endValidity) {
    return {
      status: 'Expired',
      message: 'Expired',
      endValidity: endValidity
    };
  } else if (startValidity && today < startValidity) {
    return {
      status: 'Pending',
      message: 'Pending',
      endValidity: endValidity
    };
  } else {
    return {
      status: 'Active',
      message: 'Active',
      endValidity: endValidity
    };
  }
}
