import { AppState } from '../../../redux/reducers';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { ErrorType } from '../../../models/HttpError';

// common functions 
import * as Functions from '../../../functions/common'

//stores 
import * as ContractStore from '../../../redux/middleware/ContractMiddleware'


//#region Props

interface IProps {
  SelectedEvent?: (contract: Contract) => void;
  searchCriteria?: string;
  FilteredContractIds?: number[];
  ShowControls: boolean;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  isDeleting: boolean;
  isDeletedSuccessfully: boolean;
  failOnDeleting: boolean;
  error: ErrorType | undefined;
  Contracts: Contract[];
}

interface MedicalServicesDispatchProps {
  GetAllContracts: typeof ContractStore.actionCreators.GetAllContracts;
  GetContractById: typeof ContractStore.actionCreators.GetContractById;
  AddContract: typeof ContractStore.actionCreators.AddContract;
  UpdateContract: typeof ContractStore.actionCreators.UpdateContract;
  CloneContract: typeof ContractStore.actionCreators.CloneContract;
  DeleteContract: typeof ContractStore.actionCreators.DeleteContract;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.Contract?.isLoadingAll,
  isUpdating: state.Contract?.isLoadingAll,
  isUpdatedSuccessfully: state.Contract?.successLoadingAll,
  isLoadingSuccessfully: state.Contract?.successLoadingAll,
  failOnUpdating: state.Contract?.failOnLoadingAll,
  failOnLoading: state.Contract?.failOnLoadingAll,
  isDeleting: state.Contract?.isDeletingOne,
  isDeletedSuccessfully: state.Contract?.successDeletingOne,
  failOnDeleting: state.Contract?.failOnDeletingOne,
  error: state.Contract?.error,
  Contracts: state.Contract?.list?.sort(Functions.DynamicSort('contractNumber'))
});

export const mapDispatchToProps = {
  ...ContractStore.actionCreators
};

//#endregion Props

export const GetContractStatusDescripcion = (status: number | undefined) => {
  switch (status) {
      case undefined:
          return "No definido";
      case 1:
          return "Activo";
      case 2:
          return "Inactivo";
      case 3:
          return "suspendido";
      case 4:
          return "Expirado";
      case 5:
          return "Cancelado";
      case 6:	
          return "Terminado";
      default:
      return "No definido";
  }
};

export const GetContractStatusClass = (status: number | undefined) => {
  switch (status) {
      case undefined:
          return "";
      case 1:
          return "Active";
      case 2:
          return "Inactive";
      case 3:
          return "Suspended";
      case 4:
          return "Expired";
      case 5:
          return "Canceled";
      case 6:
          return "Terminated";
      default:
      return "";
}
};