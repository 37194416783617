import { PageHeader } from '../../../commons/component/PageHeader/PageHeader';
import PractitionerListComponent from '../../../components/Practitioners/PractitionerList/PractitionerListComponent';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const PractitionersListPage = () => {
  
  //useTranslation()
  const [t] = useTranslation();
  return (
    <>
      <PageHeader title={t('practitionerListPage.practitionerListTitle')}/>
      <div className="Section">
        <PractitionerListComponent />
      </div>
    </>
  );
};
