import { Action, Reducer } from 'redux';

// actions
import * as Actions from '../actions/ContractConceptPaymentSchedule.Actions';

// functions
import * as Functions from '../../functions/common';

//state 
import { ContractConceptPaymentScheduleState, ContractConceptPaymentScheduleUnloadState } from '../states/ContractConceptPaymentScheduleState';

type KnownAction =
    | Actions.ContractConceptPaymentSchedule_GetAllByContractConceptId_Request_Action
    | Actions.ContractConceptPaymentSchedule_GetAllByContractConceptId_Receive_Action
    | Actions.ContractConceptPaymentSchedule_GetAllByContractConceptId_Fail_Action
    | Actions.ContractConceptPaymentSchedule_UpdateAllByContractConceptId_Request_Action
    | Actions.ContractConceptPaymentSchedule_UpdateAllByContractConceptId_Receive_Action
    | Actions.ContractConceptPaymentSchedule_UpdateAllByContractConceptId_Fail_Action
    | Actions.ContractConceptPaymentSchedule_GetById_Request_Action
    | Actions.ContractConceptPaymentSchedule_GetById_Receive_Action
    | Actions.ContractConceptPaymentSchedule_GetById_Fail_Action
    | Actions.ContractConceptPaymentSchedule_Add_Request_Action
    | Actions.ContractConceptPaymentSchedule_Add_Receive_Action
    | Actions.ContractConceptPaymentSchedule_Add_Fail_Action
    | Actions.ContractConceptPaymentSchedule_Update_Request_Action
    | Actions.ContractConceptPaymentSchedule_Update_Receive_Action
    | Actions.ContractConceptPaymentSchedule_Update_Fail_Action
    | Actions.ContractConceptPaymentSchedule_Delete_Request_Action
    | Actions.ContractConceptPaymentSchedule_Delete_Receive_Action
    | Actions.ContractConceptPaymentSchedule_Delete_Fail_Action;


export const ContractConceptPaymentScheduleReducer: Reducer<ContractConceptPaymentScheduleState> = (
    state: ContractConceptPaymentScheduleState | undefined, incomingAction: Action): ContractConceptPaymentScheduleState => {

        if (state === undefined) {
            return ContractConceptPaymentScheduleUnloadState as ContractConceptPaymentScheduleState;
        }

        const action = incomingAction as KnownAction;

        switch (action.type) {
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_ALL_BY_CONTRACTCONCEPTID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingAll: true,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: true,
                    failOnLoadingAll: false,
                    error: undefined,
                    list: action.contractConceptPaymentSchedules
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_ALL_BY_CONTRACTCONCEPTID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingAll: true,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: true,
                    failOnLoadingAll: false,
                    error: undefined,
                    list: action.contractConceptPaymentSchedules
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_BY_ID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingOne: true,
                    successLoadingOne: false,
                    failOnLoadingOne: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_BY_ID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: true,
                    failOnLoadingOne: false,
                    error: undefined,
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_GET_BY_ID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: false,
                    failOnLoadingOne: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_ADD_REQUEST_ACTION':
                return {
                    ...state,
                    isAddingNewOne: true,
                    successAddingNewOne: false,
                    failOnAddingNewOne: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_ADD_RECEIVE_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: true,
                    failOnAddingNewOne: false,
                    error: undefined,
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_ADD_FAIL_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: false,
                    failOnAddingNewOne: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: true,
                    failOnUpdatingOne: false,
                    error: undefined,
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_UPDATE_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_DELETE_REQUEST_ACTION':
                return {
                    ...state,
                    isDeletingOne: true,
                    successDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_DELETE_RECEIVE_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: true,
                    failOnDeletingOne: false,
                    error: undefined,
                };
            case 'CONTRACTCONCEPTPAYMENTSCHEDULE_DELETE_FAIL_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: false,
                    failOnDeletingOne: true,
                    error: action.error
                };
            default:
                return state;
        }

    }

        