import React from 'react';
import {
  Callout,
  Link,
  mergeStyleSets,
  FontWeights,
  ActionButton,
  FontIcon,
  Separator,
  Spinner,
  IPersonaSharedProps,
  Persona,
  PersonaSize,
  PersonaPresence,
  Label
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import LanguageSelectorComponent from '../LanguajeSelector/LanguajeSelector.Component';
import * as jwt_decode from "jwt-decode";

////i18Next
import { useTranslation } from 'react-i18next';

// styles
import './UserComponent.css';

// Common Function
import * as CommonFunction from '../../functions/common';

export const UserSessionWidgetComponent = () => {
  
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId('UserSessionMenu');
  const labelId = useId('callout-label');
  const descriptionId = useId('callout-description');
  const logoutPath = {
    pathname: `${ApplicationPaths.LogOut}`,
    state: { local: true }
  };

  const AuthState = useSelector((state: AppState) => state.oidc);

  //useTranslation()
  const [t] = useTranslation();

  const currentPersona: IPersonaSharedProps = {
    imageInitials: CommonFunction.getNameInitials(
      AuthState?.user?.profile.family_name + ' ' + AuthState?.user?.profile?.given_name
    )
  };

  return !AuthState?.user?.expired && AuthState?.user?.profile ? (
    <div className="userWidget">
      <Persona
        {...currentPersona}
        size={PersonaSize.size32}
        hidePersonaDetails={false}
        presence={!AuthState?.user?.expired ? PersonaPresence.online: PersonaPresence.offline}
      />
      <ActionButton
        allowDisabledFocus
        id={buttonId}
        onClick={toggleIsCalloutVisible}
      >
        {`${AuthState?.user?.profile?.family_name}, ${AuthState?.user?.profile?.given_name}`}
        <FontIcon
          aria-label="ChevronDown"
          iconName="ChevronDown"
          className={styles.iconClass}
        />
      </ActionButton>
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          ariaLabelledBy={labelId}
          ariaDescribedBy={descriptionId}
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          <Link
            href={process.env.REACT_APP_USERLINK_BASEURL + AuthState?.user?.profile?.sub}
            target="_blank"
            className={styles.link}
          >
            {t('userSessionWidget.myProfile')} 
          </Link>
          <Link
            href={
              process.env.REACT_APP_USERLINK_BASEURL +
              AuthState?.user?.profile?.sub +
              '/edit'
            }
            target="_blank"
            className={styles.link}
          >
            {t('userSessionWidget.editProfile')}
          </Link>
          <Link
            href={process.env.REACT_APP_USER_CHANGE_PASSWORD_URL}
            target="_blank"
            className={styles.link}
          >
            {t('userSessionWidget.changePassword')} 
          </Link>
          <Separator />
          <LanguageSelectorComponent />
          <Separator />
          <Link
            href={logoutPath.pathname}
            target="_self"
            className={styles.link}
          >
            {t('userSessionWidget.signOff')} 
          </Link>
          
        </Callout>
      )}
    </div>
  ) : (
    <div>
      <Spinner label="" />
    </div>
  );
};

const styles = mergeStyleSets({
  button: {
    width: 130
  },
  callout: {
    width: 200,
    maxWidth: '90%',
    padding: '10px 10px',
    styles: { borderRadius: '10px' },
    height: '17em'
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight
  },
  link: {
    display: 'block',
    marginTop: 5
  },
  iconClass: {
    fontSize: 10,
    height: 16,
    width: 16,
    margin: '10px 10px',
    padding: '9px 0 0 0'
  }
});
