import { Protocol } from "../../models/Entities/Protocols/Protocol";
import { CommonState } from "../common/state/commonState";


export interface MedicalProtocolsState extends CommonState<Protocol> {
    // updating all
    isUpdatingAll: boolean;
    isUpdatingAllSuccess: boolean|undefined;
    FailUpdatingAll: boolean;

    // Protocol contract association status
    isGettingProtocolContractAssociation: boolean;
    isGettingProtocolContractAssociationSuccess: boolean|undefined;
    FailGettingProtocolContractAssociation: boolean;
}

export const MedicalProtocolsUnloadState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,

    // updating all
    isUpdatingAll: false,
    isUpdatingAllSuccess: undefined,
    FailUpdatingAll: false,

    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,

    // saving
    isSaving: false,
    isSavingSuccess: undefined,
    FailSaving: false,
    
    // adding
    isAdding: false,
    isAddingSuccess: undefined,
    FailAdding: false,

    // deleting
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    FailDeletingOne: false, 

    // protocol contract association status
    isGettingProtocolContractAssociation: false,
    isGettingProtocolContractAssociationSuccess: undefined,
    FailGettingProtocolContractAssociation: false,
};