import { SettlementDetail } from '../../models/Entities/SettlementDetails/SettlementDetail';
import { NewSettlementDetail } from '../../models/Entities/SettlementDetails/NewSettlementDetail';
import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/SettlementDetails.Services';

// actions
import * as Actions from '../actions';

type KnownAction =
    | Actions.SettlementsDeatils.SettlementDetails_ResetStatus_Action
    | Actions.SettlementsDeatils.SettlementDetails_GetAll_Request_Action
    | Actions.SettlementsDeatils.SettlementDetails_GetAll_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_GetAll_Fail_Action
    | Actions.SettlementsDeatils.SettlementDetails_UpdateAll_Request_Action
    | Actions.SettlementsDeatils.SettlementDetails_UpdateAll_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_UpdateAll_Fail_Action
    | Actions.SettlementsDeatils.SettlementDetails_GetById_Request_Action
    | Actions.SettlementsDeatils.SettlementDetails_GetById_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_GetById_Fail_Action
    | Actions.SettlementsDeatils.SettlementDetails_Add_Request_Action
    | Actions.SettlementsDeatils.SettlementDetails_Add_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_Add_Fail_Action
    | Actions.SettlementsDeatils.SettlementDetails_Update_Request_Action
    | Actions.SettlementsDeatils.SettlementDetails_Update_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_Update_Fail_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeExcludeStatus_Request_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeExcludeStatus_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeExcludeStatus_Fail_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMarkForReviewStatus_Request_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMarkForReviewStatus_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMarkForReviewStatus_Fail_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMarkOkStatus_Request_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMarkOkStatus_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMarkOkStatus_Fail_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMassExcludeStatus_Request_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMassExcludeStatus_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMassExcludeStatus_Fail_Action;

export const actionCreators = {
    ResetStatus:
        (settlementId?: number, settlementDetailId?: number): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            
            dispatch({
                type: 'SETTLEMENTDETAILS_RESETSTATUS_ACTION',
                settlementId: settlementId,
                settlementDetailId: settlementDetailId
            });
        },
    GetAllSettlementDetails:
        (settlementId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'SETTLEMENTDETAILS_GETALL_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Unauthorized',
                        Errors: []
                    }
                });
                return;
            }

            if (!state.SettlementDetails?.successLoadingAll && !state.SettlementDetails?.isLoadingAll) {
                dispatch({
                    type: 'SETTLEMENTDETAILS_GETALL_REQUEST_ACTION',
                    settlementId: settlementId
                });

                Services.GetDetailsBySettlementId(settlementId, token)
                    .then((settlementDetails: SettlementDetail[]) => {
                        dispatch({
                            type: 'SETTLEMENTDETAILS_GETALL_RECEIVE_ACTION',
                            settlementDetails: settlementDetails,
                            settlementId: settlementId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'SETTLEMENTDETAILS_GETALL_FAIL_ACTION',
                            error: error
                        })
                    );
            } else {
                dispatch({
                    type: 'SETTLEMENTDETAILS_UPDATEALL_REQUEST_ACTION',
                    settlementId: settlementId
                });

                Services.GetDetailsBySettlementId(settlementId, token)
                    .then((settlementDetails: SettlementDetail[]) => {
                        dispatch({
                            type: 'SETTLEMENTDETAILS_UPDATEALL_RECEIVE_ACTION',
                            settlementDetails: settlementDetails,
                            settlementId: settlementId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'SETTLEMENTDETAILS_UPDATEALL_FAIL_ACTION',
                            error: error
                        })
                    );
            }
        },
    GetSettlementDetailById:
        (id: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTDETAILS_GETBYID_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }
            
            dispatch({
                type: 'SETTLEMENTDETAILS_GETBYID_REQUEST_ACTION',
                settlementDetailId: id
            });

            if(state.SettlementDetails?.list?.find((item: SettlementDetail) => item.id === id) === undefined) {

            Services.GetSettlementDetailsById(id, token)
                .then((settlementDetail: SettlementDetail) => {
                    dispatch({
                        type: 'SETTLEMENTDETAILS_GETBYID_RECEIVE_ACTION',
                        settlementDetail: settlementDetail
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'SETTLEMENTDETAILS_GETBYID_FAIL_ACTION',
                        error: error
                    })
                );

            }
        },
    AddSettlementDetail:
        (newSettlementDetail: NewSettlementDetail): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTDETAILS_ADD_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'SETTLEMENTDETAILS_ADD_REQUEST_ACTION',
                newSettlementDetail: newSettlementDetail
            });

            Services.CreateSettlementDetail(newSettlementDetail, token)
                .then((settlement: SettlementDetail) => {
                    dispatch({
                        type: 'SETTLEMENTDETAILS_ADD_RECEIVE_ACTION',
                        settlementDetail: settlement
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'SETTLEMENTDETAILS_ADD_FAIL_ACTION',
                        error: error
                    })
                );

        },
    UpdateSettlementDetail:
        (settlementDetail: SettlementDetail): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTDETAILS_UPDATE_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'SETTLEMENTDETAILS_UPDATE_REQUEST_ACTION',
                settlementDetail: settlementDetail
            });

            Services.UpdateSettlementDetail(settlementDetail, token)
                .then((settlement: SettlementDetail) => {
                    dispatch({
                        type: 'SETTLEMENTDETAILS_UPDATE_RECEIVE_ACTION',
                        settlementDetail: settlement
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'SETTLEMENTDETAILS_UPDATE_FAIL_ACTION',
                        error: error
                    })
                );

        },
    ChangeExcludeStatus:
        (settlementDetailId: number, newStatus: boolean): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {

            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTDETAILS_CHANGEEXCLUDESTATUS_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'SETTLEMENTDETAILS_CHANGEEXCLUDESTATUS_REQUEST_ACTION',
                settlementDetailId: settlementDetailId,
                newStatus: newStatus
            });

            Services.ExcludeSettlementDetail(settlementDetailId, newStatus, token)
                .then((settlementDetail: SettlementDetail) => {
                    dispatch({
                        type: 'SETTLEMENTDETAILS_CHANGEEXCLUDESTATUS_RECEIVE_ACTION',
                        settlementDetail: settlementDetail
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'SETTLEMENTDETAILS_CHANGEEXCLUDESTATUS_FAIL_ACTION',
                        error: error
                    })
                );

        },
    MarkForReview:
        (settlementDetailId: number, newStatus: boolean, comments: string): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTDETAILS_CHANGEMARKFORREVIEWSTATUS_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'SETTLEMENTDETAILS_CHANGEMARKFORREVIEWSTATUS_REQUEST_ACTION',
                settlementDetailId: settlementDetailId,
                coments: comments,
                newStatus: newStatus
            });

            Services.MarkForReviewSettlementDetail(settlementDetailId, newStatus, comments, token)
            .then((settlementDetail: SettlementDetail) => {
                dispatch({
                    type: 'SETTLEMENTDETAILS_CHANGEMARKFORREVIEWSTATUS_RECEIVE_ACTION',
                    settlementDetail: settlementDetail
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'SETTLEMENTDETAILS_CHANGEMARKFORREVIEWSTATUS_FAIL_ACTION',
                    error: error
                })
            );

        },
    MarkOk:
        (settlementDetailId: number, newStatus: boolean): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTDETAILS_CHANGEMARKOKSTATUS_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'SETTLEMENTDETAILS_CHANGEMARKOKSTATUS_REQUEST_ACTION',
                settlementDetailId: settlementDetailId,
                newStatus: newStatus
            });

            Services.markOkSettlementDetail(settlementDetailId, newStatus, token)
            .then((settlementDetail: SettlementDetail) => {
                dispatch({
                    type: 'SETTLEMENTDETAILS_CHANGEMARKOKSTATUS_RECEIVE_ACTION',
                    settlementDetail: settlementDetail
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'SETTLEMENTDETAILS_CHANGEMARKOKSTATUS_FAIL_ACTION',
                    error: error
                })
            );

        },
    MassExclude:
        (settlementId: number, settlementResumeId: number, newStatus: boolean): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTDETAILS_CHANGEMASSEXCLUDESTATUS_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'SETTLEMENTDETAILS_CHANGEMASSEXCLUDESTATUS_REQUEST_ACTION',
                settlementId: settlementId,
                settlmenetResumeId: settlementResumeId,
                newStatus: newStatus
            });

            Services.MassExcludeSettlementDetails(settlementResumeId, newStatus, token)
            .then(() => {
                dispatch({
                    type: 'SETTLEMENTDETAILS_CHANGEMASSEXCLUDESTATUS_RECEIVE_ACTION',
                    settlementId: settlementId,
                    settlmenetResumeId: settlementResumeId,
                    newStatus: newStatus,
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'SETTLEMENTDETAILS_CHANGEMASSEXCLUDESTATUS_FAIL_ACTION',
                    error: error
                })
            );
        },
};
