import { MedicalInsuranceAgreement } from '../../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';
import MOMENT from 'react-moment';
import { Text, Label } from '@fluentui/react';

// function

import * as AgreementFunction from '../MedicalInsuranceAgreementList/MedicalInsuranceAgreementListFunctions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './MedicalInsuranceAgreementDetails.css';

export interface IProps {
  agreement: MedicalInsuranceAgreement;
}

export const MedicalInsuranceAgreementsDetails = (props: IProps) => {
  //useTranslation
  const [t] = useTranslation();
  return (
    <div className="headerContent">
      <div>
        <small>{t('medicalInsuranceAgreementsDetails.props.supplierNumber')}</small>
        <h3>{props.agreement.healthProviderId || 'inexistente'}</h3>
      </div>
      <div>
        <Text>{t('medicalInsuranceAgreementsDetails.props.expirationAgreement')}</Text>
        <Label>
          {props.agreement.agreementEndDate ? (
            <MOMENT format="DD/MM/YYYY">
              {props.agreement.agreementEndDate}
            </MOMENT>
          ) : (
            t('medicalInsuranceAgreementsDetails.props.notInformed')
          )}
        </Label>
        <Label
          className={
            'agreement ' + AgreementFunction.getStatus(props.agreement).status
          }
        >
          {AgreementFunction.getStatus(props.agreement).message}
        </Label>
      </div>
    </div>
  );
};
