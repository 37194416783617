import { IResult } from '../models/HttpResult';
import AuthorizeService from '../components/api-authorization/AuthorizeService';
import { Settlement } from '../models/Entities/Settlements/Settlement';
import { NewAgreementSettlement, NewPractitionerSettlement, NewProtocolSettlement } from '../models/Entities/Settlements/NewSettlement';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';


const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'settlements/settlements/';

// Get All Settlements
export const GetSettlements = async (token: string): Promise<Settlement[]> => {

let RequestURL: string = BaseURL;

try {
    const Response: Settlement[] = await fetch(RequestURL, {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }
    })
    .then((response) => {
        if (!response.ok) {
        Sentry.captureException(response);
        throw response;
        } else {
        return response.json() as Promise<IResult<Settlement[]>>;
        }
    })
    .then((data: IResult<Settlement[]>) => {
        return data.data;
    })
    .catch((error: any) =>
        error.text().then((body: any) => {
        throw Functions.HttpErrorHandler(body, error);
        })
    );

    return Response;
} catch (error: any) {
    Sentry.captureException(error);
    throw error;
}
};

// Get contract By Id
export const GetSettlementById = async (id: number, token: string): Promise<Settlement> => {

    let RequestURL: string = BaseURL + id;
    
    try {
        const Response: Settlement = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<Settlement>>;
            }
        })
        .then((data: IResult<Settlement>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Create Contract
export const CreateAgreementSettlement = async (newSettlement: NewAgreementSettlement, token: string): Promise<Settlement> => {

    let RequestURL: string = BaseURL + 'agreement';
    
    try {
        const Response: Settlement = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newSettlement)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<Settlement>>;
            }
        })
        .then((data: IResult<Settlement>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

export const CreateProtocolSettlement = async (newSettlement: NewProtocolSettlement, token: string): Promise<Settlement> => {

    let RequestURL: string = BaseURL;
    
    try {
        const Response: Settlement = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newSettlement)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<Settlement>>;
            }
        })
        .then((data: IResult<Settlement>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

export const CreatePractitionerSettlement = async (newSettlement: NewPractitionerSettlement, token: string): Promise<Settlement> => {

    let RequestURL: string = BaseURL + 'practitioner';
    
    try {
        const Response: Settlement = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newSettlement)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<Settlement>>;
            }
        })
        .then((data: IResult<Settlement>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    }

// Update Contract
export const UpdateSettlement = async (settlement: Settlement, token: string): Promise<Settlement> => {

    let RequestURL: string = BaseURL;
    
    try {
        const Response: Settlement = await fetch(RequestURL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(settlement)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<Settlement>>;
            }
        })
        .then((data: IResult<Settlement>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// change status
export const ChangeSettlementStatus = async (settlementId: number, status: number, token: string): Promise<Settlement> => {

    let RequestURL: string = BaseURL + settlementId + '?Status=' + status;
    
    try {
        const Response: Settlement = await fetch(RequestURL, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<Settlement>>;
            }
        })
        .then((data: IResult<Settlement>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Delete Settlement
export const DeleteSettlement = async (settlementId: number, token: string): Promise<number> => {

    let RequestURL: string = BaseURL + settlementId;
    
    try {
        const Response: number = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<number>>;
            }
        })
        .then((data: IResult<number>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
}

// Recalculate settlement
export const RecalculateSettlement = async (settlementId: number, token: string): Promise<Settlement> => {
 
    let RequestURL: string = BaseURL +  settlementId + '?Recalculate=true';
    
    try {
        const Response: Settlement = await fetch(RequestURL, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<Settlement>>;
            }
        })
        .then((data: IResult<Settlement>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
}
