import {
  DefaultButton,
  Dropdown,
  IconButton,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  TextField
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { NewTenantPriceList } from '../../../../models/Entities/TenantPriceLists/NewTenantPriceList';
import { TenantPriceList } from '../../../../models/Entities/TenantPriceLists/TenantPriceList';
import * as ComponentFunctions from './TenantPriceListFormFunctions';
import { connect } from 'react-redux';
import { MessageComponent } from '../../../../commons/component/MessageComponent';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const TenantPriceListFormComponent = (
  props: ComponentFunctions.Props
) => {
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    ComponentFunctions.FormValidation | undefined
  >();

  // Form properties
  const [listName, setListName] = useState<string>();
  const [comments, setComments] = useState<string>();
  const [priceListType, setPriceListType] = useState<string>();

  //useTranslation()
  const [t] = useTranslation();

  // effects
  useEffect(() => {
    if (props.TenantPriceList) {
      setListName(props.TenantPriceList.priceList.listName);
      setComments(props.TenantPriceList.priceList.comments);
      setPriceListType(props.TenantPriceList.priceListType);
    } else {
      ResetForm();
    }
  }, [props.TenantPriceList]);

  useEffect(() => {
    if (submittedForm && (props.addingSuccess || props.savingSuccess)) {
      ResetForm();
      props.dismissPanel();
    }
  }, [props.addingSuccess, props.savingSuccess, submittedForm]);

  const ResetForm = () => {
    setListName(undefined);
    setComments(undefined);
    setPriceListType(undefined);
    setFormValidationStatus(undefined);
    setSubmittedForm(false);
  };

  // Events

  const onChangePriceListNameFieldValue = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setListName(newValue);
  };

  const onChangeCommentsFieldValue = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setComments(newValue);
  };

  const onChangePriceListTypeFieldValue = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    setPriceListType(option?.key.toString());
  };

  const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      var CurrentValues: TenantPriceList | NewTenantPriceList;
      if (props.TenantPriceList) {
        CurrentValues = {
          id: props.TenantPriceList.id,
          listName: listName!,
          comments: comments || '',
          priceListType: priceListType!,
          priceListId: props.TenantPriceList.priceListId
        };
        props.UpdateTenantPriceList(CurrentValues as TenantPriceList);
      } else {
        CurrentValues = {
          listName: listName!,
          comments: comments || '',
          priceListType: priceListType!
        };
        props.AddTenantPriceList(CurrentValues as NewTenantPriceList);
      }
    }
  };

  const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
  };

  // footer
  const onRenderFooterContent = () => {
    return (
      <div className={ComponentFunctions.contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'tenantPriceListForm.footer.cancelButton'}/>
        </DefaultButton>
        <PrimaryButton
          onClick={HandleSaveForm}
          styles={ComponentFunctions.buttonStyles}
          className="ConfirmAction"
        >
          <Trans i18nKey={'tenantPriceListForm.footer.saveButton'}/>
        </PrimaryButton>
      </div>
    );
  };

  const formFields = (
    <div {...ComponentFunctions.columnProps}>
      <TextField
        label={t('tenantPriceListForm.formFields.name')}
        name="txt_ListName"
        required
        type="text"
        autoComplete="off"
        onChange={onChangePriceListNameFieldValue}
        errorMessage={
          submittedForm === true && listName === undefined
            ? t('tenantPriceListForm.formFields.requiredField')
            : undefined
        }
        value={listName}
      />
      <Dropdown
        placeholder={t('tenantPriceListForm.formFields.select')}
        label={t('tenantPriceListForm.formFields.type')}
        options={ComponentFunctions.PriceListTypeOptions}
        onChange={onChangePriceListTypeFieldValue}
        errorMessage={
          submittedForm === true && priceListType === undefined
            ? t('tenantPriceListForm.formFields.requiredField')
            : undefined
        }
        selectedKey={priceListType ? priceListType : undefined}
      />
      <TextField
        label={t('tenantPriceListForm.formFields.comments')}
        name="txt_Comments"
        required
        type="text"
        autoComplete="off"
        onChange={onChangeCommentsFieldValue}
        errorMessage={
          submittedForm === true && comments === undefined
            ? t('tenantPriceListForm.formFields.requiredField')
            : undefined
        }
        value={comments}
      />
    </div>
  );

  // form validation

  const ValidateForm = (): boolean => {
    var validationResult: boolean = true;

    var FormValidation: ComponentFunctions.FormValidation = {
      success: true,
      errors: []
    };
    setFormValidationStatus(FormValidation);

    if (listName === undefined) {
      validationResult = false;
      FormValidation.errors.push(
        t('tenantPriceListForm.formValidation.requiredPriceListName')
      );
    }

    if (priceListType === undefined) {
      validationResult = false;
      FormValidation.errors.push(
        t('tenantPriceListForm.formValidation.requiredPriceListType') 
      );
    }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      isModeless={false}
      containerClassName={ComponentFunctions.contentStyles.container}
      closeButtonAriaLabel="Close"
    >
      <div className={ComponentFunctions.contentStyles.header}>
        <span>{t('tenantPriceListForm.modal.priceList')}</span>
        <IconButton
          styles={ComponentFunctions.iconButtonStyles}
          iconProps={ComponentFunctions.cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.dismissPanel}
        />
      </div>
      {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {t('tenantPriceListForm.modal.reviewInformation')}
          <ul>
            {FormValidationStatus?.errors.map(
              (Error: string, index: number) => (
                <li key={index}>{Error}</li>
              )
            )}
          </ul>
        </MessageBar>
      )}
      {submittedForm &&
        (props.failOnSaving || props.failOnAdding) &&
        props.error && (
          <MessageComponent
            message={t('tenantPriceListForm.modal.errorMessage')}
            subMessage={props.error.ErrorMessage}
            itemList={props.error.Errors}
            type={MessageBarType.error}
          />
        )}
      <div className={ComponentFunctions.contentStyles.body}>{formFields}</div>
      {onRenderFooterContent()}
    </Modal>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(TenantPriceListFormComponent as any);
