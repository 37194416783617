import { NewProvider } from '../models/Entities/Provider/NewProvider';
import { Provider } from '../models/Entities/Provider/Provider';


// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { IResult } from '../models/HttpResult';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'providers';

export const AddProvider = async (
  newProvider: NewProvider
, token: string): Promise<Provider> => {
  let RequestURL: string = BaseURL;

  try {
    const Response: Provider = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(newProvider)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Provider>>;
        }
      })
      .then((data: IResult<Provider>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const SaveProvider = async (provider: Provider, token: string): Promise<Provider> => {
  let RequestURL: string = BaseURL;

  try {
    const Response: Provider = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(provider)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Provider>>;
        }
      })
      .then((data: IResult<Provider>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProvider = async (id: number, token: string): Promise<Provider> => {
  let RequestURL: string = BaseURL + '/' + id;

  try {
    const Response: Provider = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Provider>>;
        }
      })
      .then((data: IResult<Provider>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProviders = async (
  pageNumber: number,
  pageSize: number,
  searchText: string | undefined
, token: string): Promise<Provider[]> => {
  let RequestURL: string = BaseURL;

  let params: URLSearchParams = Functions.GetQueryStringParams(
    pageNumber,
    pageSize,
    searchText
  );

  if (params) RequestURL += '?' + params.toString();

  try {
    const Response: Provider[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Provider[]>>;
        }
      })
      .then((data: IResult<Provider[]>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
