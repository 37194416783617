//#region Props

import { ErrorType } from "../../../../models/HttpError";
import { AppState } from "../../../../redux/reducers";

// stores
import * as ContractsAccessStore from "../../../../redux/middleware/ContractAccess.Middleware";
import * as UserStore from "../../../../redux/middleware/UserMiddleware";

// models
import { ContractAccess } from "../../../../models/Entities/Contracts/ContractAccess";
import { Contract } from "../../../../models/Entities/Contracts/Contract";

interface IProps {
    ContractId: number;
}

interface ConnectedProps {
    isLoading: boolean;
    isLoadingSuccessfully: boolean | undefined;
    failOnLoading: boolean;
    errorOnLoading: ErrorType | undefined;
    Contract: Contract | undefined;
    Access: ContractAccess[] | undefined;
}

interface MedicalServicesDispatchProps {
    GetContractAccessByContractId: typeof ContractsAccessStore.actionCreators.GetContractAccessByContractId;
    GetAllUsers: typeof UserStore.ActionCreators.GetAllUsers;
    DeleteContractAccess: typeof ContractsAccessStore.actionCreators.DeleteContractAccess;
    UpdateContractAccess: typeof ContractsAccessStore.actionCreators.UpdateContractAccess;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const currentContract: Contract | undefined = state.Contract?.list?.find(c => c.id === ownProps.ContractId);

    return {
        isLoading: state.ContractAccess?.isLoadingAll,
        isLoadingSuccessfully: state.ContractAccess?.successLoadingAll,
        failOnLoading: state.ContractAccess?.failOnLoadingAll,
        error: state.ContractAccess?.error,
        Contract: currentContract,
        Access: currentContract?.access?.map((access: ContractAccess) => 
            access = {...access, userName: state.Users?.list?.find(u => u.userId === access.userID)?.fullName || 'NI'}
        ).sort((a, b) => a.id - b.id)
    }
};

export const mapDispatchToProps = {
    ...ContractsAccessStore.actionCreators,
    ...UserStore.ActionCreators
};

//#endregion Props