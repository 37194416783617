import { SettlementDetailState, SettlementDetailUnloadState } from '../states/SettlementDetailState';
import { SettlementDetail } from '../../models/Entities/SettlementDetails/SettlementDetail';

// Settlement Reducer
import { Action, Reducer } from 'redux';

// actions
import * as Actions from '../actions';

type KnownAction = 
| Actions.SettlementsDeatils.SettlementDetails_ResetStatus_Action
| Actions.SettlementsDeatils.SettlementDetails_GetAll_Request_Action
| Actions.SettlementsDeatils.SettlementDetails_GetAll_Receive_Action
| Actions.SettlementsDeatils.SettlementDetails_GetAll_Fail_Action
| Actions.SettlementsDeatils.SettlementDetails_UpdateAll_Request_Action
| Actions.SettlementsDeatils.SettlementDetails_UpdateAll_Receive_Action
| Actions.SettlementsDeatils.SettlementDetails_UpdateAll_Fail_Action
| Actions.SettlementsDeatils.SettlementDetails_GetById_Request_Action
| Actions.SettlementsDeatils.SettlementDetails_GetById_Receive_Action
| Actions.SettlementsDeatils.SettlementDetails_GetById_Fail_Action
| Actions.SettlementsDeatils.SettlementDetails_Add_Request_Action
| Actions.SettlementsDeatils.SettlementDetails_Add_Receive_Action
| Actions.SettlementsDeatils.SettlementDetails_Add_Fail_Action
| Actions.SettlementsDeatils.SettlementDetails_Update_Request_Action
| Actions.SettlementsDeatils.SettlementDetails_Update_Receive_Action
| Actions.SettlementsDeatils.SettlementDetails_Update_Fail_Action
| Actions.SettlementsDeatils.SettlementDetails_Delete_Request_Action
| Actions.SettlementsDeatils.SettlementDetails_Delete_Receive_Action
| Actions.SettlementsDeatils.SettlementDetails_Delete_Fail_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeExcludeStatus_Request_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeExcludeStatus_Receive_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeExcludeStatus_Fail_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeMarkForReviewStatus_Request_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeMarkForReviewStatus_Receive_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeMarkForReviewStatus_Fail_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeMarkOkStatus_Request_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeMarkOkStatus_Receive_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeMarkOkStatus_Fail_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeMassExcludeStatus_Request_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeMassExcludeStatus_Receive_Action
| Actions.SettlementsDeatils.SettlementDetails_ChangeMassExcludeStatus_Fail_Action;

export const SettlementDetailsReducer: Reducer<SettlementDetailState> = (
    state: SettlementDetailState | undefined, incomingAction: Action): SettlementDetailState => {
    
        if (state === undefined) {
            return SettlementDetailUnloadState as SettlementDetailState;
        }
    
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case 'SETTLEMENTDETAILS_RESETSTATUS_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    isLoadingOne: false,
                    successLoadingOne: false,
                    failOnLoadingOne: false,
                    isAddingNewOne: false,
                    successAddingNewOne: false,
                    failOnAddingNewOne: false,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    isDeletingOne: false,
                    successDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_GETALL_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingAll: true,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_GETALL_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: true,
                    failOnLoadingAll: false,
                    error: undefined,
                    list: action.settlementDetails
                };
            case 'SETTLEMENTDETAILS_GETALL_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: true,
                    error: action.error
                };
            case 'SETTLEMENTDETAILS_UPDATEALL_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingAll: true,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_UPDATEALL_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: true,
                    failOnLoadingAll: false,
                    error: undefined,
                    list: action.settlementDetails
                };
            case 'SETTLEMENTDETAILS_UPDATEALL_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: true,
                    error: action.error
                };
            case 'SETTLEMENTDETAILS_GETBYID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingOne: true,
                    successLoadingOne: false,
                    failOnLoadingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_GETBYID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: true,
                    failOnLoadingOne: false,
                    error: undefined,
                    list: [...state.list.filter((settlementeDetail: SettlementDetail) => settlementeDetail.id !== action.settlementDetail.id), action.settlementDetail]
                };
            case 'SETTLEMENTDETAILS_GETBYID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: false,
                    failOnLoadingOne: true,
                    error: action.error
                };
            case 'SETTLEMENTDETAILS_ADD_REQUEST_ACTION':
                return {
                    ...state,
                    isAddingNewOne: true,
                    successAddingNewOne: false,
                    failOnAddingNewOne: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_ADD_RECEIVE_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: true,
                    failOnAddingNewOne: false,
                    error: undefined,
                    list: [...state.list, action.settlementDetail]
                };
            case 'SETTLEMENTDETAILS_ADD_FAIL_ACTION':
                return {    
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: false,
                    failOnAddingNewOne: true,
                    error: action.error
                };
            case 'SETTLEMENTDETAILS_UPDATE_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_UPDATE_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: true,
                    failOnUpdatingOne: false,
                    error: undefined,
                    list: [...state.list.filter((settlementeDetail: SettlementDetail) => settlementeDetail.id !== action.settlementDetail.id), action.settlementDetail]
                };
            case 'SETTLEMENTDETAILS_UPDATE_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: true,
                    error: action.error
                };
            case 'SETTLEMENTDETAILS_DELETE_REQUEST_ACTION':
                return {
                    ...state,
                    isDeletingOne: true,
                    successDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_DELETE_RECEIVE_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: true,
                    failOnDeletingOne: false,
                    error: undefined,
                    list: [...state.list.filter((settlementeDetail: SettlementDetail) => settlementeDetail.id !== action.settlementDetailId)]
                };
            case 'SETTLEMENTDETAILS_DELETE_FAIL_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: false,
                    failOnDeletingOne: true,
                    error: action.error
                };
            case 'SETTLEMENTDETAILS_CHANGEEXCLUDESTATUS_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_CHANGEEXCLUDESTATUS_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: true,
                    failOnUpdatingOne: false,
                    error: undefined,
                    list: [...state.list.filter((settlementeDetail: SettlementDetail) => settlementeDetail.id !== action.settlementDetail.id), action.settlementDetail]
                };
            case 'SETTLEMENTDETAILS_CHANGEEXCLUDESTATUS_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: true,
                    error: action.error
                };
            case 'SETTLEMENTDETAILS_CHANGEMARKFORREVIEWSTATUS_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_CHANGEMARKFORREVIEWSTATUS_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: true,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_CHANGEMARKFORREVIEWSTATUS_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: true,
                    error: action.error
                };
            case 'SETTLEMENTDETAILS_CHANGEMARKOKSTATUS_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_CHANGEMARKOKSTATUS_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: true,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_CHANGEMARKOKSTATUS_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: true,
                    error: action.error
                };
            case 'SETTLEMENTDETAILS_CHANGEMASSEXCLUDESTATUS_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingAll: true,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_CHANGEMASSEXCLUDESTATUS_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: true,
                    FailUpdatingAll: false,
                    error: undefined
                };
            case 'SETTLEMENTDETAILS_CHANGEMASSEXCLUDESTATUS_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: true,
                    error: action.error
                };
            default:
                return state;
        }
    }

