import { Action, Reducer } from 'redux';
import {
  MedicalInsurancePlanState,
  MedicalInsurancePlansUnloadState
} from '../states/MedicalInsurancePlanState';
import { MedicalInsurancePlan } from '../../models/Entities/MedicalInsurances/MedicalInsurancePlan';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetAllByMedicalInsuranceId_Request_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetAllByMedicalInsuranceId_Receive_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetAllByMedicalInsuranceId_Fail_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetById_Request_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetById_Receive_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetById_Fail_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansAdd_Request_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansAdd_Receive_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansAdd_Fail_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansUpdate_Request_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansUpdate_Receive_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansUpdate_Fail_Action;

export const MedicalInsurancePlansReducer: Reducer<
  MedicalInsurancePlanState
> = (
  state: MedicalInsurancePlanState | undefined,
  incomingAction: Action
): MedicalInsurancePlanState => {
  if (state === undefined) {
    return MedicalInsurancePlansUnloadState as MedicalInsurancePlanState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'MEDICAL_INSURANCE_PLANS_GET_BY_MEDICAL_INSURANCE_ID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_PLANS_GET_BY_MEDICAL_INSURANCE_ID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined,
        list: action.PlansList
      };
    case 'MEDICAL_INSURANCE_PLANS_GET_BY_MEDICAL_INSURANCE_ID_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_PLANS_GET_BY_ID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_PLANS_GET_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined,
        list: [...state.list, action.MedicalInsurancePlan]
      };
    case 'MEDICAL_INSURANCE_PLANS_GET_BY_ID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_PLANS_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_PLANS_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [...state.list, action.MedicalInsurancePlan].sort(
          Functions.DynamicSort('title')
        )
      };
    case 'MEDICAL_INSURANCE_PLANS_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_PLANS_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_PLANS_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_PLANS_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (item: MedicalInsurancePlan) => item.id !== action.Plan.id
          ),
          action.Plan
        ].sort(Functions.DynamicSort('title'))
      };
  }

  return state;
};
