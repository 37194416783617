import { AppState } from "../../../../../redux/reducers";

// stores
import * as ProtocolStore from '../../../../../redux/middleware/MedicalProtocolMiddleware';
import * as ContractConceptStore from '../../../../../redux/middleware/ContractConceptMiddleware';
import * as ContractCommisionAgentStore from '../../../../../redux/middleware/ContractCommissionAgentMiddleware';
import * as ContrectStore from '../../../../../redux/middleware/ContractMiddleware';
import * as ContractCommissionAgentConceptStore from '../../../../../redux/middleware/ContractCommissionAgentConceptMiddleware';

// models
import { ContractCommissionAgentConcept } from "../../../../../models/Entities/Contracts/ContractCommissionAgentConcept";
import { ErrorType } from "../../../../../models/HttpError";
import { Contract } from "../../../../../models/Entities/Contracts/Contract";
import { ContractCommissionAgent } from "../../../../../models/Entities/Contracts/ContractCommissionAgent";
import { ContractConcept } from "../../../../../models/Entities/Contracts/ContractConcept";


interface IProps {
  CommissionAgentConcept: ContractCommissionAgentConcept;
  disabled?: boolean;
  onClick?: (concept: ContractCommissionAgentConcept) => void;
};

interface ConnectedProps {
    isLoading: boolean;
    LoadedSuccessfully: boolean | undefined;
    FailOnLoading: boolean;
    ErrorOnLoading: ErrorType | undefined;
    Contract: Contract;
    CommissionConcept: ContractCommissionAgentConcept;
    Agent: ContractCommissionAgent | undefined;
    Concept: ContractConcept | undefined;
};

interface MedicalServicesDispatchProps {
    DeleteContractCommissionAgentConcept: (id: number) => void;
};


export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const CurrentCommissionAgent = state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.CommissionAgentConcept.contractId)?.commissionAgents?.find((commissionAgent: ContractCommissionAgent) => commissionAgent.id === ownProps.CommissionAgentConcept.id);
    const CurrentContract = state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.CommissionAgentConcept.contractId);
    const CurrentCommissionAgentConcept = CurrentCommissionAgent?.Concepts?.find((commissionAgentConcept: ContractCommissionAgentConcept) => commissionAgentConcept.id === ownProps.CommissionAgentConcept.id);
    const CurrentConcept = CurrentContract?.concepts?.find((concept: ContractConcept) => concept.id === ownProps.CommissionAgentConcept.conceptId);
    
    return {
        isLoading: state.ContractCommissionAgentConcept?.isLoadingAll,
        LoadedSuccessfully: state.ContractCommissionAgentConcept?.successLoadingAll,
        FailOnLoading: state.ContractCommissionAgentConcept?.failOnLoadingAll,
        ErrorOnLoading: state.ContractCommissionAgentConcept?.error,
        Contract: CurrentContract,
        CommissionConcept: CurrentCommissionAgentConcept,
        Concept: CurrentConcept,
        Agent: CurrentCommissionAgent,
    };
}

export const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {

    dispatch(ContractConceptStore.actionCreators.GetContractConceptById(ownProps.CommissionAgentConcept.conceptId));
    dispatch(ContractCommisionAgentStore.actionCreators.GetContractCommissionAgentById(ownProps.CommissionAgentConcept.commissionAgentId, ownProps.CommissionAgentConcept.contractId));

    return {
        DeleteContractCommissionAgentConcept: (id: number) => {
            dispatch(ContractCommissionAgentConceptStore.actionCreators.DeleteContractCommissionAgentConcept(id, ownProps.CommissionAgentConcept.contractId));
        }
    }
};