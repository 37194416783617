import { ProfessionalAvailability } from '../models/Entities/ProfessionalAvailability/ProfessionalAvailability';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { IResult } from '../models/HttpResult';

const BaseURL = process.env.REACT_APP_HC_API_ENDPOINT_AGENDA;

export const GetProfessionalAvailability = async (
  PractitionerId: string
, token: string): Promise<ProfessionalAvailability[]> => {
  const RequestURL: string = BaseURL + 'practitioner/' + PractitionerId;

  try {
    const Response: ProfessionalAvailability[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<ProfessionalAvailability[]>;
        }
      })
      .then((data: ProfessionalAvailability[]) => {
        return data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProfessionalAvailabilityById = async (
  AvailabilityId: number
, token: string): Promise<ProfessionalAvailability> => {
  const RequestURL: string = BaseURL + '/' + AvailabilityId;

  try {
    const Response: ProfessionalAvailability = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProfessionalAvailability>>;
        }
      })
      .then((data: IResult<ProfessionalAvailability>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const CreateProfessionalAvailability = async (
  Availability: ProfessionalAvailability
, token: string): Promise<ProfessionalAvailability> => {
  const RequestURL: string = BaseURL || '';

  try {
    const Response: ProfessionalAvailability = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(Availability)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<ProfessionalAvailability>;
        }
      })
      .then((data: ProfessionalAvailability) => {
        return data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const UpdateProfessionalAvailability = async (
  Availability: ProfessionalAvailability
, token: string): Promise<ProfessionalAvailability> => {
  const RequestURL: string = BaseURL || '';

  try {
    const Response: ProfessionalAvailability = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(Availability)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<ProfessionalAvailability>;
        }
      })
      .then((data: ProfessionalAvailability) => {
        return data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DeleteProfessionalAvailability = async (
  AvailabilityId: number
, token: string): Promise<number> => {
  const RequestURL: string = BaseURL! + AvailabilityId;

  try {
    const Response: any = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<null>;
        }
      })
      .then(() => {
        return AvailabilityId;
      })
      .catch((error: any) =>
        error.text
          ? error.text().then((body: any) => {
              throw Functions.HttpErrorHandler(body, error);
            })
          : console.log(error)
      );

    return Response;
  } catch (error: any) {
    //Sentry.captureException(error);
    throw error;
  }
};
