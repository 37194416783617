import { AgreementServicesDetail } from '../../../models/Entities/AgreementServices/AgreementServicesDetail';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';
import { IDropdownOption } from '@fluentui/react';
import { AgreementService } from '../../../models/Entities/AgreementServices/AgreementServices';

// store
import * as AgreementServiceDetailsStore from '../../../redux/middleware/AgreementServiceDetailMiddleware';

export interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  SelectedDetails?: AgreementServicesDetail;
  insurancePlanId?: number;
  insurancePlanReferenceName?: string;
  AgreementService?: AgreementService;
}

export interface ConnectedProps {
  isSaving: boolean;
  SavingSuccess: boolean;
  failOnSaving: boolean;
  isUpdating: boolean;
  UpdatingSuccess: boolean;
  failOnUpdating: boolean;
  error: ErrorType;
}

interface DispatchProps {
  AddAgreementServiceDetail: typeof AgreementServiceDetailsStore.actionCreators.AddAgreementServiceDetail;
  UpdateAgreementServiceDetail: typeof AgreementServiceDetailsStore.actionCreators.UpdateAgreementServiceDetail;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isSaving: state.AgreementServiceDetails?.isAddingNewOne,
  SavingSuccess: state.AgreementServiceDetails?.successAddingNewOne,
  failOnSaving: state.AgreementServiceDetails?.failOnAddingNewOne,
  isUpdating: state.AgreementServiceDetails?.isUpdatingOne,
  UpdatingSuccess: state.AgreementServiceDetails?.successUpdatingOne,
  failOnUpdating: state.AgreementServiceDetails?.failOnUpdatingOne,
  error: state.AgreementServiceDetails?.error
});

export const mapDispatchToProps = {
  ...AgreementServiceDetailsStore.actionCreators
};

// Period type options
// ---------------------------
// 1 = mensual
// 2 = bimestral
// 3 = trimestral
// 4 = semestral
// 5 = anual
// 6 = Total de la cobertura

export const PeriodTypeOptions: IDropdownOption[] = [
  { key: '1', text: 'Mensual' },
  { key: '2', text: 'Bimestral' },
  { key: '3', text: 'Trimestral' },
  { key: '4', text: 'Semestral' },
  { key: '5', text: 'Anual' },
  { key: '6', text: 'Total de la cobertura' }
];
