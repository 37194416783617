import { IIconProps } from "@fluentui/react";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";
import { ErrorType } from "../../../models/HttpError";

// stores
import * as SettlementStore from '../../../redux/middleware/SettlementMiddleware';

//State
import { AppState } from "../../../redux/reducers";
import { Settlement } from "../../../models/Entities/Settlements/Settlement";


interface IProps {
  ContractId?: number | undefined;
  PractitionerId?: string | undefined;
  ProtocolId?: number | undefined;
  SponsorId?: number | undefined;
  MedicalInsuranceId?: number | undefined;
  MedicalAgreementId?: number | undefined;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    isDeleting: boolean;
    isDeletedSuccessfully: boolean;
    failOnDeleting: boolean;
    error: ErrorType | undefined;
    Settlements: Settlement[];
  }
  
  interface SettlementDispatchProps {
    GetAllSettlements: typeof SettlementStore.actionCreators.GetAllSettlements;
    GetSettlementById: typeof SettlementStore.actionCreators.GetSettlementById;
    RefreshSettlementById: typeof SettlementStore.actionCreators.RefreshSettlementById;
    DeleteSettlementById: typeof SettlementStore.actionCreators.DeleteSettlementById;
  }
  
  export type Props = IProps & ConnectedProps & SettlementDispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.Settlement?.isLoadingAll,
    isUpdating: state.Settlement?.isLoadingAll,
    isUpdatedSuccessfully: state.Settlement?.successLoadingAll,
    isLoadingSuccessfully: state.Settlement?.successLoadingAll,
    failOnUpdating: state.Settlement?.failOnLoadingAll,
    failOnLoading: state.Settlement?.failOnLoadingAll,
    isDeleting: state.Settlement?.isDeletingOne,
    isDeletedSuccessfully: state.Settlement?.successDeletingOne,
    failOnDeleting: state.Settlement?.failOnDeletingOne,
    error: state.Settlement?.error,
    Settlements: state.Settlement?.list?.filter((settlement: Settlement) => 
    ownProps.ContractId ? settlement.contract?.some((contract) => contract.contractId === ownProps.ContractId) : 
    ownProps.MedicalAgreementId ? settlement.agreements?.some((agreement) => agreement.medicalAgreementId === ownProps.MedicalAgreementId) : 
    ownProps.PractitionerId ? settlement.practitioner?.some((practitioner) => practitioner.practitionerId === ownProps.PractitionerId) : 
    ownProps.ProtocolId ? settlement.protocol?.some((protocol) => protocol.protocolId === ownProps.ProtocolId) : 
    ownProps.SponsorId ? settlement.sponsor?.some((sponsor) => sponsor.sponsorId === ownProps.SponsorId) : true ).sort((a, b) => b.id - a.id)
  });
  
  export const mapDispatchToProps = {
    ...SettlementStore.actionCreators
  };


  //#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };
export const RefreshIcon: IIconProps = { iconName: 'SyncOccurence' };



export const GetSettlementStatusDescripcion = (status: number | undefined) => {
  switch (status) {
    case undefined:
        return 'SettlementStatus.pending'; // "Pendiente";
    case 1:
        return 'SettlementStatus.registered'; // "Registrado";
    case 2:
        return 'SettlementStatus.inProcess'; // "En proceso";
    case 3:
        return 'SettlementStatus.finished'; // "Finalizado";
    case 4:
        return 'SettlementStatus.cancelled'; // "Cancelado";
    case 5:
        return 'SettlementStatus.Confirmed'; // "Conformado";
    case 6:	
        return 'SettlementStatus.Rejected'; // "Rechazado";
    case 7:	
        return 'SettlementStatus.Fail'; // "Fail";
    default:
        return 'SettlementStatus.pending'; // "Pendiente";
  }
};

export const GetSettlementStatusClass = (status: number | undefined) => {
  switch (status) {
      case undefined:
          return " gray";
      case 1:
          return " green";
      case 2:
          return " blue";
      case 3:
          return " blue";
      case 4:
          return " red";
      case 5:
          return " green";
      case 6:	
          return " red";
      default:
      return " gray";
  }
};

export const GetSettlementStatusIcon = (status: number | undefined) => {
  switch (status) {
      case undefined:
          return 'WaitlistConfirmMirrored';
      case 1:
          return 'WaitlistConfirmMirrored';
      case 2:
          return 'SyncStatus';
      case 3:
          return 'SkypeCircleCheck';
      case 4:
          return 'StatusErrorFull';
      case 5:
          return 'SkypeCircleCheck';
      case 6:	
          return 'StatusErrorFull';
      default:
      return 'WaitlistConfirmMirrored';
  }
}