import {
  IPersonaSharedProps,
  Label,
  Pivot,
  PivotItem,
  Separator
} from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { TestImages } from '@fluentui/example-data';
import { connect } from 'react-redux';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import { useEffect, useState } from 'react';
import { AppState } from '../../../redux/reducers';
import { HeaderPersonaComponent } from '../../../commons/component/headers/HeaderPersona/HeaderPersonaComponent';
import PractitionerMedicalInsuranceAssociatedListComponent from '../../../components/Practitioners/PractitionerMedicalInsuranceAssociated/PractitionerMedicalInsuranceAssociatedComponent';
import PractitionerSettingsComponent from '../../../components/Practitioners/PractitionerSettings/PractitionerSettingsComponent';
import PractitionerMedicalServiceAssociatedListComponent from '../../../components/Practitioners/PractitionerMedicalServicesAssociated/PractitionerMedicalServiceAssociatedComponent';
import ProfessionalAvailabilityListComponent from '../../../components/ProfessionalAvailability/ProfessionalAvailabilityList/ProfessionalAvailabilityListComponent';
import { PageHeader } from '../../../commons/component/PageHeader/PageHeader';
import SettlementListComponent from '../../../components/Settlements/SettlementList/SettlementList.Component';
import PractitionerContractComponent from '../../../components/Practitioners/PractitionerContract/PractitionerContract.Component';

// Store
import * as PractitionerStore from '../../../redux/middleware/PractitionerMiddleware';
import PaymentAccountDetailsComponent from '../../../components/PaymentAccount/PaymentAccountDetails/PaymentAccountDetails.Component';

////i18Next
import { useTranslation, Trans } from 'react-i18next';
import { PractitionerActivityFeedComponent } from '../../../components/Practitioners/PractitionerActivityFeed/PractitionerActivityFeed.Component';


interface IMapsProps {
  Practitioners: Practitioner[] | undefined;
}

interface IDispatchProps {
  GetPractitionerById: typeof PractitionerStore.actionCreators.GetPractitionerById;
}

type IProps = IMapsProps & IDispatchProps;

export const PractitionerDetailsPage = ({
  Practitioners,
  GetPractitionerById
}: IProps) => {
  const { PractitionerId } = useParams();

  useEffect(() => {
    if (PractitionerId) {
      GetPractitionerById(PractitionerId);
    }
  }, [PractitionerId]);

  useEffect(() => {
    if (Practitioners) {
      setPractitioner(
        Practitioners?.find(
          (practitioner: Practitioner) =>
            practitioner.userId === String(PractitionerId)
        )
      );
    }
  }, [Practitioners, PractitionerId]);

  const [practitioner, setPractitioner] = useState<Practitioner | undefined>();

  //useTranslation()
  const [t] = useTranslation();

  const currentPractitioner: IPersonaSharedProps = {
    imageUrl: TestImages.personaFemale,
    imageInitials: 'AL',
    text: practitioner?.fullName,
    secondaryText: practitioner?.speciality
  };

  return (
    <>
      <PageHeader title={t('practitionerDetailsPage.practitionerDetailsTitle')}/>
      <div className="Content">
        {practitioner && (
          <>
            <div className="Section">
              {practitioner && (
                <HeaderPersonaComponent
                  PersonaName={practitioner?.fullName}
                  secondaryText={practitioner?.speciality}
                />
              )}
            </div>
            <div className="Section">
              <Pivot>
                <PivotItem headerText={t('practitionerDetailsPage.practitionerDetailsItem.generalInfo')}>
                  <PractitionerActivityFeedComponent practitioner={practitioner.userId} />
                </PivotItem>
                <PivotItem headerText={t('practitionerDetailsPage.practitionerDetailsItem.providersServes')}>
                  <PractitionerSettingsComponent
                    PractitionerId={practitioner.userId}
                  />
                  <Separator />
                  <PractitionerMedicalInsuranceAssociatedListComponent
                    PractitionerId={practitioner.userId}
                  />
                </PivotItem>

                <PivotItem headerText={t('practitionerDetailsPage.practitionerDetailsItem.medicalServices')}>
                  <PractitionerMedicalServiceAssociatedListComponent
                    PractitionerId={practitioner.userId}
                  />
                </PivotItem>
                <PivotItem headerText={t('practitionerDetailsPage.practitionerDetailsItem.availability')}>
                  <ProfessionalAvailabilityListComponent
                    PractitionerId={practitioner.userId}
                  />
                </PivotItem>
                <PivotItem headerText={t('practitionerDetailsPage.practitionerDetailsItem.liquidations')}>
                  <div className="VisitasSection">
                        <div>
                            <SettlementListComponent PractitionerId={PractitionerId} />
                        </div>
                        <div>
                            {PractitionerId && <PractitionerContractComponent PractitionerId={PractitionerId}/>}
                        </div>
                    </div>
                </PivotItem>
                {/* <PivotItem headerText={t('practitionerDetailsPage.practitionerDetailsItem.accountStatus')}>
                    <PaymentAccountDetailsComponent />
                </PivotItem> */}
              </Pivot>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const MapStateToProps = (state: AppState, ownProps: any) => {
  return {
    Practitioners: state.Practitioner?.list
  };
};

const MapDispatchToProps = {
  ...PractitionerStore.actionCreators
};

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(PractitionerDetailsPage as any);
