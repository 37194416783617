import { FontSizes, FontWeights, Text } from '@fluentui/react';

export const FooterBarComponent = () => {
  return (
    <div className="footerBar">
      <Text style={{color: 'gray', fontSize: FontSizes.size16}}>©2023</Text>
      <a href="https://www.ichtys.com.ar" target="_blank"><Text>ICHTYS S.A.</Text></a>
      <img height={15} style={{marginTop: 3}}
        src={require('../../assets/img/logoichtys.png')}
        alt="Ichtys"
      ></img>
    </div>
  );
};
