
import { ContractCommissionConcept } from "../../models/Entities/Contracts/ContractCommissionConcept";
import { CommonState } from "../common/state/commonState";

export interface ContractCommissionConceptsState extends CommonState<ContractCommissionConcept> {}

export const ContractCommissionConceptsUnloadState: ContractCommissionConceptsState = {
    list: [],
    error: undefined,
    isLoadingAll: false,
    successLoadingAll: undefined,
    failOnLoadingAll: false,
    isLoadingOne: false,
    successLoadingOne: undefined,
    failOnLoadingOne: false,
    isAddingNewOne: false,
    successAddingNewOne: undefined,
    failOnAddingNewOne: false,
    isUpdatingOne: false,
    successUpdatingOne: undefined,
    failOnUpdatingOne: false,
    isDeletingOne: false,
    successDeletingOne: undefined,
    failOnDeletingOne: false
}
