import { PriceDetails } from './../../models/Entities/PriceList/PriceDetail';
import { MedicalInsuranceAgreementsPriceList } from './../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreementsPriceList';
import { Action, Reducer } from 'redux';
import {
  MedicalInsuranceAgreementsState,
  MedicalInsuranceAgreementsUnloadedState
} from '../states/MedicalInsuranceAgreementState';
import { MedicalInsuranceAgreement } from '../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementsGetAllByMedicalInsuranceId_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementsGetAllByMedicalInsuranceId_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementsGetAllByMedicalInsuranceId_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementGetById_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementGetById_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementGetById_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAdd_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAdd_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAdd_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementUpdate_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementUpdate_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementUpdate_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDelete_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDelete_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDelete_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAddPriceList_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAddPriceList_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAddPriceList_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementClonePriceList_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementClonePriceList_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementClonePriceList_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDeletePriceList_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDeletePriceList_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDeletePriceList_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDeletePriceList_Reset_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateStart_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateStart_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateStart_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateEnd_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateEnd_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateEnd_Fail_Action
  | Actions.PriceLists.PriceListAddPrice_Receive_Action
  | Actions.PriceLists.PriceListUpdatePrice_Receive_Action
  | Actions.AgreementServices.AgreementServices_GetAll_Receive_Action
  | Actions.AgreementServices.AgreementServices_Add_Receive_Action
  | Actions.AgreementServices.AgreementServices_Update_Receive_Action;

export const MedicalInsuranceAgreementsReducer: Reducer<
  MedicalInsuranceAgreementsState
> = (
  state: MedicalInsuranceAgreementsState | undefined,
  incomingAction: Action
): MedicalInsuranceAgreementsState => {
  if (state === undefined) {
    return MedicalInsuranceAgreementsUnloadedState as MedicalInsuranceAgreementsState;
  }

  let auxAgreement: MedicalInsuranceAgreement | undefined;

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'MEDICAL_INSURANCE_AGREEMENTS_GET_ALL_BY_MEDICAL_INSURANCE_ID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_AGREEMENTS_GET_ALL_BY_MEDICAL_INSURANCE_ID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined,
        list: action.medicalInsuranceAgreementList
      };
    case 'MEDICAL_INSURANCE_AGREEMENTS_GET_ALL_BY_MEDICAL_INSURANCE_ID_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_GET_BY_ID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_GET_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined,
        list: [
          ...(state.list || []).filter(
            (agreement: MedicalInsuranceAgreement) =>
              agreement.id !== action.MedicalInsuranceAgreement.id
          ),
          action.MedicalInsuranceAgreement
        ]
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_GET_BY_ID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [...state.list, action.MedicalInsuranceAgreement]
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (item: MedicalInsuranceAgreement) =>
              item.id !== action.MedicalInsuranceAgreement.id
          ),
          action.MedicalInsuranceAgreement
        ].sort(Functions.DynamicSort('agreementStartDate'))
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_START_REQUEST_ACTION':
      return {
        ...state,
        isSettingValidityStartDate: true,
        setValidityStartDateSuccess: undefined,
        failOnSetValidityStartDate: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_START_RECEIVE_ACTION':
      return {
        ...state,
        isSettingValidityStartDate: false,
        setValidityStartDateSuccess: true,
        failOnSetValidityStartDate: false,
        error: undefined,
        list: state.list.map((agreement: MedicalInsuranceAgreement) =>
          agreement.id ===
          action.MedicalInsuranceAgreementsPriceList.agreementId
            ? {
                ...agreement,
                priceLists: agreement.priceLists.map(
                  (list: MedicalInsuranceAgreementsPriceList) =>
                    list.id === action.MedicalInsuranceAgreementsPriceList.id
                      ? {
                          ...list,
                          effectiveDateStart:
                            action.MedicalInsuranceAgreementsPriceList
                              .effectiveDateStart
                        }
                      : list
                )
              }
            : agreement
        )
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_START_FAIL_ACTION':
      return {
        ...state,
        isSettingValidityStartDate: false,
        setValidityStartDateSuccess: false,
        failOnSetValidityStartDate: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_END_REQUEST_ACTION':
      return {
        ...state,
        isSettingValidityEndDate: true,
        setValidityEndDateSuccess: undefined,
        failOnSetValidityEndDate: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_END_RECEIVE_ACTION':
      return {
        ...state,
        isSettingValidityEndDate: false,
        setValidityEndDateSuccess: true,
        failOnSetValidityEndDate: false,
        error: undefined,
        list: state.list.map((agreement: MedicalInsuranceAgreement) =>
          agreement.id ===
          action.MedicalInsuranceAgreementsPriceList.agreementId
            ? {
                ...agreement,
                priceLists: agreement.priceLists.map(
                  (list: MedicalInsuranceAgreementsPriceList) =>
                    list.id === action.MedicalInsuranceAgreementsPriceList.id
                      ? {
                          ...list,
                          effectiveDateEnd:
                            action.MedicalInsuranceAgreementsPriceList
                              .effectiveDateEnd
                        }
                      : list
                )
              }
            : agreement
        )
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_END_FAIL_ACTION':
      return {
        ...state,
        isSettingValidityEndDate: false,
        setValidityEndDateSuccess: false,
        failOnSetValidityEndDate: true,
        error: action.error
      };
    case 'PRICE_LIST_ADD_PRICE_RECEIVE_ACTION':
      return {
        ...state,
        list: state.list.map((agreement: MedicalInsuranceAgreement) =>
          agreement
            ? {
                ...agreement,
                priceLists: agreement.priceLists.map(
                  (list: MedicalInsuranceAgreementsPriceList) =>
                    list.priceList.id === action.Price.pricelistEntityId
                      ? {
                          ...list,
                          priceList: {
                            ...list.priceList,
                            prices: [
                              ...list.priceList.prices,
                              action.Price
                            ].sort(Functions.DynamicSort('itemReferredName'))
                          }
                        }
                      : list
                )
              }
            : agreement
        )
      };
    case 'PRICE_LIST_UPDATE_PRICE_RECEIVE_ACTION':
      return {
        ...state,
        list: (state.list || []).map((agreement: MedicalInsuranceAgreement) =>
          agreement
            ? {
                ...agreement,
                priceLists: (agreement.priceLists || []).map(
                  (list: MedicalInsuranceAgreementsPriceList) =>
                    list.priceList.id === action.Price.pricelistEntityId
                      ? {
                          ...list,
                          priceList: {
                            ...list.priceList,
                            prices: [
                              ...list.priceList.prices.filter(
                                (price: PriceDetails) =>
                                  price.id !== action.Price.id
                              ),
                              action.Price
                            ].sort(Functions.DynamicSort('itemReferredName'))
                          }
                        }
                      : list
                )
              }
            : agreement
        )
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_ADD_PRICE_LIST_REQUEST_ACTION':
      return {
        ...state,
        isAddingPriceList: true,
        addingPriceListSuccess: undefined,
        failOnAddingPriceList: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_ADD_PRICE_LIST_RECEIVE_ACTION':
      auxAgreement = state.list.find(
        (agreement: MedicalInsuranceAgreement) =>
          agreement.id ===
          action.MedicalInsuranceAgreementsPriceList.agreementId
      );
      if (auxAgreement) {
        auxAgreement.priceLists = [
          ...(auxAgreement?.priceLists || []),
          action.MedicalInsuranceAgreementsPriceList
        ];
      }

      return {
        ...state,
        isAddingPriceList: false,
        addingPriceListSuccess: true,
        failOnAddingPriceList: false,
        error: undefined,
        list: [
          ...(state.list || []).filter(
            (agreement: MedicalInsuranceAgreement) =>
              agreement.id !==
              action.MedicalInsuranceAgreementsPriceList.agreementId
          ),
          auxAgreement as MedicalInsuranceAgreement
        ]
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_ADD_PRICE_LIST_FAIL_ACTION':
      return {
        ...state,
        isAddingPriceList: false,
        addingPriceListSuccess: false,
        failOnAddingPriceList: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_DELETE_PRICE_LIST_REQUEST_ACTION':
      return {
        ...state,
        isDeletingPriceList: true,
        deletingPriceListSuccess: undefined,
        failOnDeletingPriceList: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_DELETE_PRICE_LIST_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingPriceList: false,
        deletingPriceListSuccess: true,
        failOnDeletingPriceList: false,
        error: undefined,
        list: state.list.map((agreement: MedicalInsuranceAgreement) => {
          let Result: MedicalInsuranceAgreement;

          if (agreement.id === action.AgreementId) {
            const List = agreement.priceLists.filter(
              (list: MedicalInsuranceAgreementsPriceList) => {
                return list.id !== action.MedicalInsuranceAgreementPriceListId;
              }
            );

            const AgreementFixed = { ...agreement, priceLists: List };

            Result = AgreementFixed;
          } else {
            Result = agreement;
          }

          return Result;
        })
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_DELETE_PRICE_LIST_FAIL_ACTION':
      return {
        ...state,
        isDeletingPriceList: false,
        deletingPriceListSuccess: false,
        failOnDeletingPriceList: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_DELETE_PRICE_LIST_RESET_ACTION':
      return {
        ...state,
        isDeletingPriceList: false,
        deletingPriceListSuccess: undefined,
        failOnDeletingPriceList: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_CLONE_PRICE_LIST_REQUEST_ACTION':
      return {
        ...state,
        isAddingPriceList: true,
        addingPriceListSuccess: undefined,
        failOnAddingPriceList: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_CLONE_PRICE_LIST_RECEIVE_ACTION':
      auxAgreement = state.list.find(
        (agreement: MedicalInsuranceAgreement) =>
          agreement.id === action.MedicalInsuranceAgreementPriceList.agreementId
      );
      if (auxAgreement) {
        auxAgreement.priceLists = [
          ...(auxAgreement?.priceLists || []),
          action.MedicalInsuranceAgreementPriceList
        ];
      }

      return {
        ...state,
        isAddingPriceList: false,
        addingPriceListSuccess: true,
        failOnAddingPriceList: false,
        error: undefined,
        list: [
          ...(state.list || []).filter(
            (agreement: MedicalInsuranceAgreement) =>
              agreement.id !==
              action.MedicalInsuranceAgreementPriceList.agreementId
          ),
          auxAgreement as MedicalInsuranceAgreement
        ]
      };
    case 'MEDICAL_INSURANCE_AGREEMENT_CLONE_PRICE_LIST_FAIL_ACTION':
      return {
        ...state,
        isAddingPriceList: false,
        addingPriceListSuccess: false,
        failOnAddingPriceList: true,
        error: action.error
      };
    case 'AGREEMENT_SERVICES_GETALL_RECEIVE_ACTION':
      auxAgreement = state.list.find(
        (agreement: MedicalInsuranceAgreement) =>
          agreement.id === action.agreementId
      );

      if (auxAgreement) {
        auxAgreement.services = action.agreementServices;
      }
      return {
        ...state,
        list: auxAgreement
          ? [
              ...state.list.filter(
                (agreement: MedicalInsuranceAgreement) =>
                  agreement.id !== action.agreementId
              ),
              auxAgreement
            ]
          : state.list
      };
  }

  return state;
};
