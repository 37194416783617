import { IResult } from '../models/HttpResult';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { ContractCommissionConcept } from '../models/Entities/Contracts/ContractCommissionConcept';
import { NewContractCommissionConcept } from '../models/Entities/Contracts/NewContractCommissionConcetp';
import { useSelector } from 'react-redux';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/';


// Get All Contracts
export const GetContractCommissionConcepts = async (contractId : number, token: string): Promise<ContractCommissionConcept[]> => {

    let RequestURL: string = BaseURL + contractId + '/commissionconcepts';

    try {
        const Response: ContractCommissionConcept[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionConcept[]>>;
                }
            })
            .then((data: IResult<ContractCommissionConcept[]>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Get contract commission concept by id
export const GetContractCommissionConceptById = async (commissionConceptId: number, token: string): Promise<ContractCommissionConcept> => {

    let RequestURL: string = BaseURL + '/commissionconcepts/' + commissionConceptId;

    try {
        const Response: ContractCommissionConcept = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionConcept>>;
                }
            })
            .then((data: IResult<ContractCommissionConcept>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Create Contract Commission Concept
export const CreateContractCommissionConcept = async (commissionConcept: NewContractCommissionConcept, token: string): Promise<ContractCommissionConcept> => {

    let RequestURL: string = BaseURL + 'commissionconcepts';

    try {
        const Response: ContractCommissionConcept = await fetch(RequestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(commissionConcept)
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionConcept>>;
                }
            })
            .then((data: IResult<ContractCommissionConcept>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// update Contract Commission Concept
export const UpdateContractCommissionConcept = async (commissionConcept: ContractCommissionConcept, token: string): Promise<ContractCommissionConcept> => {

    let RequestURL: string = BaseURL + 'commissionconcepts/';

    try {
        const Response: ContractCommissionConcept = await fetch(RequestURL, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(commissionConcept)
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionConcept>>;
                }
            })
            .then((data: IResult<ContractCommissionConcept>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// delete Contract Commission Concept
export const DeleteContractCommissionConcept = async (commissionConceptId: number, token: string): Promise<ContractCommissionConcept> => {

    let RequestURL: string = BaseURL + 'commissionconcepts/' + commissionConceptId;

    try {
        const Response: ContractCommissionConcept = await fetch(RequestURL, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionConcept>>;
                }
            })
            .then((data: IResult<ContractCommissionConcept>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};