import { useEffect } from 'react';
import { connect } from 'react-redux';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import {
  DefaultButton,
  FontSizes,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  mergeStyleSets,
  MessageBarType,
  Modal,
  Toggle
} from '@fluentui/react';
import { Spinner, Text, Label } from '@fluentui/react';

// Store
import * as MedicalServiceStore from '../../../redux/middleware/MedicalServicesMiddleware';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

//styles
import './MedicalServiceMultiSelectorComponent.css';

export type SelectedMedicalServiceType = {
  medicalServiceId: number;
  ReferenceId: number;
};

export interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  SelectedServices: SelectedMedicalServiceType[];
  ViewOnly3erPartyServices: boolean | undefined;
  ErrorInformed: ErrorType | undefined;
  onSelect?: (service: AvailableMedicalService) => void;
  onUnselect?: (service: SelectedMedicalServiceType) => void;
}

interface ConnectedProps {
  isLoading: boolean;
  loadingSuccess: boolean;
  failOnLoading: boolean;
  error: ErrorType;
  MedicalServices: AvailableMedicalService[];
}

interface DispatchProps {
  GetAllMedicalServices: typeof MedicalServiceStore.actionCreators.GetAllMedicalServices;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const MedicalServiceMultiSelectorComponent = ({
  MedicalServices,
  isLoading,
  failOnLoading,
  ErrorInformed,
  error,
  SelectedServices,
  isOpen,
  ViewOnly3erPartyServices,
  dismissPanel,
  onSelect,
  onUnselect,
  GetAllMedicalServices
}: Props) => {

  //useTranslation();
  const [t] = useTranslation();

  useEffect(() => {
    if (GetAllMedicalServices) GetAllMedicalServices(1, 200, undefined);
  }, [GetAllMedicalServices]);

  const HandleCancelForm = () => {
    dismissPanel();
  };

  const OnSelectService = (service: AvailableMedicalService) => {
    if (onSelect) onSelect(service);
  };
  const OnUnselectService = (
    service: SelectedMedicalServiceType | undefined
  ) => {
    if (onUnselect && service) onUnselect(service);
  };

  const optionList = (
    <>
      {ViewOnly3erPartyServices && (
        <MessageComponent
          type={MessageBarType.info}
          message={
            t('medicalServiceMultiselector.message.enableMedicalService') 
          }
        />
      )}
      <MessageComponent
        type={MessageBarType.warning}
        message={
          (t('medicalServiceMultiselector.message.medicalService')) + SelectedServices.length + t('medicalServiceMultiselector.message.selectedMedicalService')
        }
      />
      {ErrorInformed && (
        <MessageComponent
          type={MessageBarType.error}
          message={ErrorInformed.ErrorMessage}
        />
      )}
      <div className="SelectionListContainer">
        <ul>
          {MedicalServices &&
            MedicalServices.map((medicalService: AvailableMedicalService) => (
              <li key={medicalService.id}>
                <div className="SelectionList">
                  <Toggle
                    checked={
                      SelectedServices.find(
                        (service: SelectedMedicalServiceType) =>
                          service.medicalServiceId === medicalService.id
                      )
                        ? true
                        : false
                    }
                    onChange={(e, checked) =>
                      checked
                        ? OnSelectService(medicalService)
                        : OnUnselectService(
                            SelectedServices.find(
                              (service: SelectedMedicalServiceType) =>
                                service.medicalServiceId === medicalService.id
                            )
                          )
                    }
                  />
                  <div className="SelectionList-item">
                    <Label style={{ fontSize: FontSizes.size16 }}>
                      {medicalService.serviceNameReference}
                    </Label>
                    {medicalService.available ? (
                      <Text>{medicalService.comments}</Text>
                    ) : (
                      <Text style={{ color: 'red' }}>{t('medicalServiceMultiselector.selectionList.disabled')}</Text>
                    )}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );

  // footer
  const onRenderFooterContent = () => {
    return (
      <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'medicalServiceMultiselector.footer.closeButton'}/> Cerrar
        </DefaultButton>
      </div>
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={dismissPanel}
        isModeless={false}
        containerClassName={contentStyles.container}
        closeButtonAriaLabel="Close"
      >
        <div className={contentStyles.header}>
          <span><Trans i18nKey={'medicalServiceMultiselector.modal.medicalService'}/></span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={dismissPanel}
          />
        </div>
        {failOnLoading && (
          <MessageComponent
            message={error.ErrorMessage}
            type={MessageBarType.error}
          />
        )}
        {isLoading ? (
          <div>
            <Spinner
              label={t('medicalServiceMultiselector.loading.medicalServices')}
              ariaLive="assertive"
              labelPosition="top"
            />
          </div>
        ) : (
          optionList
        )}
        {onRenderFooterContent()}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: AppState, ownprops: IProps) => ({
  isLoading: state.MedicalServices?.isLoadingAll,
  loadingSuccess: state.MedicalServices?.successLoadingAll,
  failOnLoading: state.MedicalServices?.failOnLoadingAll,
  error: state.MedicalServices?.error,
  MedicalServices:
    ownprops.ViewOnly3erPartyServices === undefined
      ? state.MedicalServices?.list
      : state.MedicalServices?.list.filter(
          (service: AvailableMedicalService) =>
            service.serviceProvidedBy3rdParty ===
            ownprops.ViewOnly3erPartyServices
        )
});

const mapDispatchToProps = {
  ...MedicalServiceStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalServiceMultiSelectorComponent as any);

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10,
    Width: '600px',
    paddindright: '1em'
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '5px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
