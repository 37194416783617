import { IBasePickerSuggestionsProps, ITag, PivotItem } from '@fluentui/react';

//store 
import * as MedicalProtocolsStore from '../../../../redux/middleware/MedicalProtocolMiddleware';

// models
import { ErrorType } from '../../../../models/HttpError';
import { ProtocolPatient } from '../../../../models/Entities/Protocols/ProtocolPatient';
import { Protocol } from '../../../../models/Entities/Protocols/Protocol';
import { AppState } from '../../../../redux/reducers';

interface IProps {
  ProtocolId: number;
  onSelectedProtocolPatient: (patient: ProtocolPatient[]) => void;
  ProtocolPatientsSelectedNumber: string[] | undefined;
  MultiselectEnable: boolean;
  noteText?: string;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Protocols: Protocol[];
  ProtocolPatients: ProtocolPatient[];
  ProtocolPatientsSelected: string[];
}

interface DispatchProps {
  GetProtocolPatients: typeof MedicalProtocolsStore.actionCreators.GetProtocolPatients;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.ContractConcept?.isLoadingAll,
  isUpdating: state.ContractConcept?.isLoadingAll,
  isUpdatedSuccessfully: state.ContractConcept?.successLoadingAll,
  isLoadingSuccessfully: state.MedicalProtocols?.successLoadingAll,
  failOnUpdating: state.ContractConcept?.failOnLoadingAll,
  failOnLoading: state.ContractConcept?.failOnLoadingAll,
  isDeleting: state.ContractConcept?.isDeletingOne,
  isDeletedSuccessfully: state.ContractConcept?.successDeletingOne,
  failOnDeleting: state.ContractConcept?.failOnDeletingOne,
  error: state.ContractConcept?.error,
  Protocols: state.MedicalProtocols?.list,
  ProtocolPatients: state.MedicalProtocols?.list ? state.MedicalProtocols.list.find((item: Protocol) => item.id === ownProps.ProtocolId)?.patients : [],
  ProtocolPatientsSelected: ownProps.ProtocolPatientsSelectedNumber ? state.MedicalProtocols?.list ? state.MedicalProtocols.list.find((item: Protocol) => item.id === ownProps.ProtocolId)?.patients?.filter((patient: ProtocolPatient) => ownProps.ProtocolPatientsSelectedNumber?.includes(patient.numberInProtocol)) : [] : []
});

export const mapDispatchToProps = ({
  ...MedicalProtocolsStore.actionCreators
});


export interface InternalMedicalInsuranceSelected {
  MedicalInsuranceId: number;
  MedicalInsurancePlanID?: number;
}



export const GetProtocolPatientOptions = (
  ProtocolId: number,
  protocolPatients: ProtocolPatient[]
): ITag[] => {

  const ProtocolPatients = protocolPatients?.filter(
    (item: ProtocolPatient) => item.protocolId === ProtocolId
  );
  
  var Patients = ProtocolPatients?.map((item: ProtocolPatient) => {
    return { key: item.numberInProtocol, name: item.numberInProtocol };
  });

  console.log('Pacientes', Patients);

  return Patients as ITag[];

};

// export const filterSuggestedMedicalServices = async (
//   filterText: string
// ): Promise<ITag[]> => {
//   return filterText && filterText.length > 4
//     ? MedicalService.GetMedicalServices(1, 100, filterText).then(
//         (services: AvailableMedicalService[]) => {
//           return services.map((item: AvailableMedicalService) => {
//             return { key: item.id, name: item.serviceNameReference };
//           });
//         }
//       )
//     : [];
// };

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const GetProtocolPatientById = (
  numberInProtocol: string,
  protocolPatients: ProtocolPatient[]
): ProtocolPatient => {

  const protocolPatient: ProtocolPatient = protocolPatients?.find(
    (item: ProtocolPatient) => item.numberInProtocol === numberInProtocol
  ) as ProtocolPatient;

  return protocolPatient;
};

export async function GetSelectedProtocolPatient(
  protocolId: number,
  numberInProtocol: string[],
  protocolPatients: ProtocolPatient[]
): Promise<ITag[]> {
  const currentProtocolPatients: ITag[] = [];

  numberInProtocol.forEach(async (id: string) => {

    const patient: ProtocolPatient = GetProtocolPatientById(id, protocolPatients);

    currentProtocolPatients.push({
      key: patient.numberInProtocol,
      name: patient.numberInProtocol
    });
  });

  return [] as ITag[];;
}

export const getTextFromItem = (item: ITag) => item.name;

export const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'pacientes sugeridos',
  noResultsFoundText: 'No se encontro paciente'
};
