import { connect } from 'react-redux';
import { TenantPriceList } from '../../../../models/Entities/TenantPriceLists/TenantPriceList';
import {
  Text,
  Icon,
  IconButton,
  Label,
  MessageBarType,
  PrimaryButton,
  Spinner
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { MessageComponent } from '../../../../commons/component/MessageComponent';
import TenantPriceListFormComponent from '../TenantPriceListForm/TenantPriceListFormComponent';
import { useBoolean } from '@fluentui/react-hooks';

// Functions
import * as ComponentFunctions from './TenantPriceListFunctions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Styles
import './TenantPriceList.css';
import { ConfirmationButtonComponent } from '../../../../commons/component/ConfirmationButton/ConfirmationButtonComponent';

export const TenantPriceListComponent = (props: ComponentFunctions.Props) => {
  const navigate = useNavigate();
  const [selectedTenantPriceList, setSelectedTenantPriceList] =
    useState<TenantPriceList>();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const onViewTenantPriceListEvent = (_list: TenantPriceList) => {
    navigate('/tenantpricelist/' + _list.id);
  };

  //useTranslation()
  const [t] = useTranslation();


  useEffect(() => {
    props.GetAllTenantPriceList();
  }, []);

  // events

  const onAddTenantPriceListEvent = () => {
    setSelectedTenantPriceList(undefined);
    openPanel();
  };

  const onEditTenantPriceListEvent = (PriceList: TenantPriceList) => {
    setSelectedTenantPriceList(PriceList);
    openPanel();
  };

  const onDeleteTenantPriceListEvent = (PriceList: TenantPriceList) => {
    props.DeleteTenantPriceList(PriceList.id);
  };

  // render

  const onRenderCell = props.TenantPriceLists?.map((list: TenantPriceList) => (
    <tr key={list.id} className="TableBodyRow">
      <td
        className={
          'primary TenantPriceList ' + ComponentFunctions.getStatus(list).status
        }
      >
        <div>
          <div>
            {' '}
            {list.isBlocked ? (
              <Icon {...ComponentFunctions.LockIcon} style={{ color: 'red' }} />
            ) : (
              <Icon
                {...ComponentFunctions.UnlockIcon}
                style={{ color: 'green' }}
              />
            )}
          </div>
          <div>
            <Label>{list.priceList.listName}</Label>
            <Text>
              {ComponentFunctions.getTenantPriceListTypeDescription(
                list.priceListType
              )}
            </Text>
          </div>
        </div>
      </td>
      <td>{list.priceList.comments}</td>
      <td>
        {list.effectiveDateStart ? (
          <Moment format="DD/MM/YYYY">{list.effectiveDateStart}</Moment>
        ) : (
          <span><Trans i18nKey={'tenantPriceList.notDefined'}/></span>
        )}
      </td>
      <td>
        {list.effectiveDateEnd ? (
          <Moment format="DD/MM/YYYY">{list.effectiveDateEnd}</Moment>
        ) : (
          <span><Trans i18nKey={'tenantPriceList.notDefined'}/></span>
        )}
      </td>
      <td>
        <div className="SectionControls">
          <IconButton
            iconProps={ComponentFunctions.InfoIcon}
            title={t('tenantPriceList.buttons.detailButton')} 
            ariaLabel="Information"
            onClick={() => onViewTenantPriceListEvent(list)}
          />
          <IconButton
            iconProps={ComponentFunctions.EditIcon}
            title={t('tenantPriceList.buttons.editButton')}
            ariaLabel="Information"
            onClick={() => onEditTenantPriceListEvent(list)}
          />
          <div className="danger">
            <ConfirmationButtonComponent
              ButtonId={'MedicalCenterPriceListDeleteButton' + list.id}
              ButtonIconName={'Delete'}
              ButtonText={''}
              ButtonCssClass={''}
              ConfirmationButtonText={t('tenantPriceList.buttons.deleteButton')}
              ConfirmationButtonCssClass={'deleteButton'}
              CancelButtonText={t('tenantPriceList.buttons.cancelButton')}
              CancelButtonCssClass={''}
              Title={t('tenantPriceList.buttons.title')}
              Description={t('tenantPriceList.buttons.description')}
              ElementTextHeader={t('tenantPriceList.buttons.priceList')}
              ElementTextInformation={list.priceList.listName}
              onConfirmation={() => onDeleteTenantPriceListEvent(list)}
              onCancel={function (): void {
                throw new Error('Function not implemented.');
              }}
              isOperationExecuting={props.isDeleting || false}
              isOperationExecutedWithError={props.failOnDeleting || false}
              OperationError={props.error}
            />
          </div>
        </div>
      </td>
    </tr>
  ));

  const TenantPriceListGrid = (
    <table className="Table">
      <thead>
        <tr>
          <th>{t('tenantPriceList.name')}</th>
          <th>{t('tenantPriceList.comment')} </th>
          <th>{t('tenantPriceList.validSince')}</th>
          <th>{t('tenantPriceList.validityUntil')}</th>
          <th style={{ width: 150 }}>{t('tenantPriceList.options')}</th>
        </tr>
      </thead>
      <tbody>{onRenderCell}</tbody>
    </table>
  );

  const Loading = (
    <div>
      <Spinner
        label={t('tenantPriceList.loading.priceList')}
        ariaLive="assertive"
        labelPosition="top"
      />
    </div>
  );

  const ComponentBar = (
    <div className="SectionControls spaceBetween">
      <PrimaryButton
        onClick={onAddTenantPriceListEvent}
        text={t('tenantPriceList.buttons.addPriceList')}
        iconProps={ComponentFunctions.AddIcon}
        className="actionButton"
      />
    </div>
  );

  const FormContent = (
    <TenantPriceListFormComponent
      isOpen={isOpen}
      openPanel={() => openPanel()}
      dismissPanel={() => {
        setSelectedTenantPriceList(undefined);
        dismissPanel();
      }}
      TenantPriceList={selectedTenantPriceList}
    />
  );

  return (
    <>
      {ComponentBar}
      {/* {(props.addingSuccessfully || props.savingSuccessfully) && (
        <MessageComponent
          message={'La lista de precios se ha guardado correctamente'}
          type={MessageBarType.success}
        />
      )} */}
      {props.error && (
        <MessageComponent
          message={t('tenantPriceList.props.errorMessage')}
          subMessage={props.error?.ErrorMessage}
          type={MessageBarType.error}
          itemList={props.error?.Errors}
        />
      )}
      {props.isGettingAll ? (
        Loading
      ) : props.gettingAllSuccess ? (
        TenantPriceListGrid
      ) : (
        <>
          {/* <MessageComponent
            message={'Ocurrio un error al recuperar la lista de precios'}
            subMessage={props.error?.ErrorMessage}
            type={MessageBarType.error}
            itemList={props.error?.Errors}
          /> */}
        </>
      )}
      {FormContent}
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(TenantPriceListComponent as any);
