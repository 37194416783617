import { User } from "../../models/Entities/Authentication/User";
import { UserSettings } from "../../models/Entities/Settings/UserSettings";
import { CommonState } from "../common/state/commonState";

export interface CurrentUserState extends CommonState<UserSettings> {
    
    // Set user
    settingUser: boolean;
    setUserSuccess: boolean | undefined;
    failOnSetUser: boolean;

    settingLanguaje: boolean;
    setLanguajeSuccess: boolean | undefined;
    failOnSetLanguaje: boolean;

    CurrentUser: UserSettings | undefined;
}

export const CurrentUserUnloadedState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,

    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,

    // saving
    isSaving: false,
    isSavingSuccess: undefined,
    FailSaving: false,

    // adding
    isAdding: false,
    isAddingSuccess: undefined,
    FailAdding: false,

    // deleting
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    FailDeletingOne: false,

    isFiltered: false,
    filterCriteria: undefined,

    list: [],
    Selected: undefined,
    Error: undefined,

    // setting user
    settingUser: false,
    setUserSuccess: undefined,
    failOnSetUser: false,

    settingLanguaje: false,
    setLanguajeSuccess: undefined,
    failOnSetLanguaje: false,

    CurrentUser: {
        UserId: 'Not Defined',
        UserLanguaje: undefined
    } 
}

export interface UserState extends CommonState<User> {}

export const UserUnloadedState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,

    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,

    // saving
    isSaving: false,
    isSavingSuccess: undefined,
    FailSaving: false,

    // adding
    isAdding: false,
    isAddingSuccess: undefined,
    FailAdding: false,

    // deleting
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    FailDeletingOne: false,

    isFiltered: false,
    filterCriteria: undefined,

    list: [],
    Selected: undefined,
    Error: undefined
}