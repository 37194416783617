//Functions
import * as Functions from '../functions/common';
import { Debt } from '../models/Entities/CashFlow/Debt/Debt';
import { NewDebt } from '../models/Entities/CashFlow/Debt/NewDebt';
import { IResult } from '../models/HttpResult';

// urls
const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'cashflow/debts';

// Get all debts by creditor
export const GetDebtsByCreditor = async (token: string, creditorIdentifier: string, debitorIdentifier: string | undefined): Promise<Debt[]> => {

    let RequestURL: string = BaseURL + '?creditor=' + creditorIdentifier;

    if (debitorIdentifier) {
        RequestURL += '&debtor=' + debitorIdentifier;
    }

    try {
        const Response: Debt[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Debt[]>>;
            }
        })
        .then((data: IResult<Debt[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Get all debts by debtor
export const GetDebtsByDebtor = async (token: string, debtorIdentifier: string, creditorIdentifier: string | undefined): Promise<Debt[]> => {

    let RequestURL: string = BaseURL + '?debtor=' + debtorIdentifier;

    if (creditorIdentifier) {
        RequestURL += '&creditor=' + creditorIdentifier;
    }

    try {
        const Response: Debt[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Debt[]>>;
            }
        })
        .then((data: IResult<Debt[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Get debt by id
export const GetDebtById = async (token: string, debtId: number): Promise<Debt> => {

    let RequestURL: string = BaseURL + '/' + debtId;

    try {
        const Response: Debt = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Debt>>;
            }
        })
        .then((data: IResult<Debt>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Create debt
export const CreateDebt = async (token: string, debt: NewDebt): Promise<Debt> => {

    let RequestURL: string = BaseURL;

    try {
        const Response: Debt = await fetch(RequestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(debt)
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Debt>>;
            }
        })
        .then((data: IResult<Debt>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// update debt
export const UpdateDebt = async (token: string, debt: Debt): Promise<Debt> => {

    let RequestURL: string = BaseURL + '/' + debt.id;

    try {
        const Response: Debt = await fetch(RequestURL, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(debt)
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Debt>>;
            }
        })
        .then((data: IResult<Debt>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// delete debt

export const DeleteDebt = async (token: string, debtId: number): Promise<boolean> => {

    let RequestURL: string = BaseURL + '/' + debtId;

    try {
        const Response: boolean = await fetch(RequestURL, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<boolean>>;
            }
        })
        .then((data: IResult<boolean>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};