import {
  DefaultButton,
  FontSizes,
  IconButton,
  IIconProps,
  Label,
  PrimaryButton,
  SearchBox,
  Separator,
  TextField
} from '@fluentui/react';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MedicalInsuranceAgreementsPriceList } from '../../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreementsPriceList';
import { TenantPriceList } from '../../../models/Entities/TenantPriceLists/TenantPriceList';
import { AppState } from '../../../redux/reducers';
import { ConfirmationButtonComponent } from '../../../commons/component/ConfirmationButton/ConfirmationButtonComponent';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Store
import * as MedicalInsuranceAgreementPriceListStore from '../../../redux/middleware/MedicalInsuranceAgreementsMiddleware';
import * as PriceListStore from '../../../redux/middleware/PriceListMiddleware';
import { ErrorType } from '../../../models/HttpError';
import { ProviderPriceList } from '../../../models/Entities/ProviderAssociations/PriceLists/ProviderPriceList';

export interface IProps {
  PriceList:
    | MedicalInsuranceAgreementsPriceList
    | TenantPriceList
    | ProviderPriceList;
  onAddMedicalService?: () => void;
  onSearchService?: (searchText: string | undefined) => void;
  onDeletePriceList: (PriceListId: number) => void;
  onCancelDeletePriceList: () => void;
  onClonePriceList: (PriceListId: number) => void;
  onMassiveUpdateChange: (Active: boolean) => void;
  // status
  isDeletingExecution: boolean;
  FailOnDeleting: boolean;
  ErrorOnDeleting: ErrorType | undefined;
}

export const PriceListHeaderComponent = (props: IProps) => {
  const [activePriceListTitleForm, setActivePriceListTitleForm] =
    useState(false);

  const [massiveUpdateActive, setMassiveUpdateActive] = useState(false);

  const [priceListTitle, setPriceListTitle] = useState('');
  
  //useTranslation();
  const [t] = useTranslation();

  const HandlerAddPriceClick = () => {
    if (props.onAddMedicalService) props.onAddMedicalService();
  };

  const HandlerClonePriceClick = () => {
    if (props.onClonePriceList) props.onClonePriceList(props.PriceList.id);
  };

  const HandlerMassiveUpdateClick = () => {
    props.onMassiveUpdateChange(!massiveUpdateActive);
    setMassiveUpdateActive(!massiveUpdateActive);
  };

  const HandlerDeletePriceClick = () => {
    if (props.onDeletePriceList) props.onDeletePriceList(props.PriceList.id);
  };

  var dispatch = useDispatch();

  const AddIcon: IIconProps = { iconName: 'CirclePlus' };
  const CloneIcon: IIconProps = { iconName: 'ChromeRestore' };
  const DeleteIcon: IIconProps = { iconName: 'Delete' };
  const editIcon: IIconProps = { iconName: 'Edit' };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const okIcon: IIconProps = { iconName: 'CheckMark' };
  const MassiveUpdate: IIconProps = { iconName: 'AllAppsMirrored' };
  const SaveIcon: IIconProps = { iconName: 'Save' };

  //events
  const onChangePriceListTitleFieldValue = () => {
    if (priceListTitle) {
      let updatedPriceList = props.PriceList.priceList;
      updatedPriceList.listName = priceListTitle;
      dispatch(mapDispatchToProps.UpdatePriceList(updatedPriceList));
      setActivePriceListTitleForm(!activePriceListTitleForm);
    }
  };

  const onSearchService = (searchText: string | undefined) => {
    if (props.onSearchService) props.onSearchService(searchText);
  };

  return (
    <div className="actionHeader">
      <div className="Title">
        {!activePriceListTitleForm && (
          <>
            <Label style={{ fontSize: FontSizes.large }}>
              {props.PriceList.priceList.listName}
            </Label>
            <IconButton
              onClick={() =>
                setActivePriceListTitleForm(!activePriceListTitleForm)
              }
              iconProps={editIcon}
              title={t('priceListHeader.buttons.edit')}
              ariaLabel="edit"
            />
          </>
        )}
        {activePriceListTitleForm && (
          <>
            <TextField
              defaultValue={props.PriceList.priceList.listName}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string | undefined
              ) => (newValue ? setPriceListTitle(newValue) : null)}
            />
            <IconButton
              onClick={onChangePriceListTitleFieldValue}
              iconProps={okIcon}
              title={t('priceListHeader.buttons.confirm')}
              ariaLabel="Confirmar"
            />
            <IconButton
              onClick={() => {
                setActivePriceListTitleForm(!activePriceListTitleForm);
                //setValidityErrors([]);
              }}
              iconProps={cancelIcon}
              title={t('priceListHeader.buttons.cancel')} 
              ariaLabel="Cancelar"
            />
          </>
        )}
      </div>
      <div className="controls">
        <DefaultButton
          // text={
          //   massiveUpdateActive ? 'Grabar cambios' : 'Actualizar masivamente'
          // }
          title={t('priceListHeader.buttons.modifyPricesMassively')}
          onClick={HandlerMassiveUpdateClick}
          iconProps={massiveUpdateActive ? SaveIcon : MassiveUpdate}
          allowDisabledFocus
        />
        <DefaultButton
          // text="Modificar"
          title={t('priceListHeader.buttons.clonePrice')}
          onClick={HandlerClonePriceClick}
          iconProps={CloneIcon}
          allowDisabledFocus
        />
        <SearchBox
          placeholder={t('priceListHeader.buttons.searchService')}
          underlined={false}
          onChange={(
            event?: React.ChangeEvent<HTMLInputElement> | undefined,
            newValue?: string | undefined
          ) => onSearchService(newValue)}
          style={{ fontSize: FontSizes.medium }}
        />
        <PrimaryButton
          text={t('priceListHeader.buttons.addPrice')} 
          onClick={HandlerAddPriceClick}
          iconProps={AddIcon}
          allowDisabledFocus
          className="actionButton"
        />
        <ConfirmationButtonComponent
          ButtonId={'PriceListDeleteButton' + props.PriceList.id}
          ButtonIconName={'Delete'}
          ButtonText={t('priceListHeader.buttons.deleteButton')}
          ButtonCssClass={'deleteButton'}
          ConfirmationButtonText={t('priceListHeader.buttons.deleteButtonText')}
          ConfirmationButtonCssClass={'deleteButton'}
          CancelButtonText={t('priceListHeader.buttons.cancelButtonText')}
          CancelButtonCssClass={''}
          Title={t('priceListHeader.buttons.deletePriceListInformation')}
          Description={
            t('priceListHeader.buttons.descriptionDeletePriceList')
          }
          ElementTextHeader={t('priceListHeader.buttons.deletePriceList')}
          ElementTextInformation={props.PriceList.priceList.listName}
          onConfirmation={HandlerDeletePriceClick}
          onCancel={props.onCancelDeletePriceList}
          isOperationExecuting={props.isDeletingExecution}
          isOperationExecutedWithError={props.FailOnDeleting}
          OperationError={props.ErrorOnDeleting}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalInsuranceAgreements,
  ...state.PriceList
});

const mapDispatchToProps = {
  ...MedicalInsuranceAgreementPriceListStore.actionCreators,
  ...PriceListStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceListHeaderComponent as any);
