import { DayOfWeek, Dropdown, IDropdownOption } from '@fluentui/react';
import { useEffect, useState } from 'react';

import * as ComponentFunctions from './DaySelectorFunctions';

export interface IProps {
  selectedDay: DayOfWeek | undefined;
  onDaySelectionChanged: (selectedDays: DayOfWeek) => void;
}

export const DaySelectorComponent = (props: IProps) => {
  const [selectedDay, setSelectedDay] = useState<DayOfWeek>();
  const [selectedDaysError, setSelectedDaysError] = useState<string>();
  const [selectedDaysErrorMessage, setSelectedDaysErrorMessage] =
    useState<string>();

  useEffect(() => {
    if (props.selectedDay) {
      setSelectedDay(props.selectedDay);
    }
  }, [props.selectedDay]);

  const onChangeDay = (
    _event: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ) => {
    if (option) {
      setSelectedDay(option.key as DayOfWeek);
      props.onDaySelectionChanged(option.key as DayOfWeek);
    }
  };

  const validateDays = () => {
    if (!selectedDay) {
      setSelectedDaysError('Days are required');
      setSelectedDaysErrorMessage('Please select at least one day');
    } else {
      setSelectedDaysError(undefined);
      setSelectedDaysErrorMessage(undefined);
    }
  };

  return (
    <div>
      <Dropdown
        label="Dias"
        placeholder="Seleccione el día"
        options={ComponentFunctions.daysOptions}
        selectedKey={selectedDay?.toString()}
        onChange={onChangeDay}
        errorMessage={selectedDaysErrorMessage}
        onBlur={validateDays}
      />
    </div>
  );
};
