// Settlement Reducer
import { Action, Reducer } from 'redux';

// actions
import * as Actions from '../actions';

// functions
import * as Functions from '../../functions/common';
import { SettlementResumeState, SettlementResumeUnloadState } from '../states/SettlementResumeState';
import { SettlementResume } from '../../models/Entities/SettlementResume/SettlementResume';



type KnownAction = 
| Actions.SettlementsResume.SettlementResume_GetAll_Request_Action
| Actions.SettlementsResume.SettlementResume_GetAll_Receive_Action
| Actions.SettlementsResume.SettlementResume_GetAll_Fail_Action
| Actions.SettlementsResume.SettlementResume_UpdateAll_Request_Action
| Actions.SettlementsResume.SettlementResume_UpdateAll_Receive_Action
| Actions.SettlementsResume.SettlementResume_UpdateAll_Fail_Action
| Actions.SettlementsResume.SettlementResume_GetById_Request_Action
| Actions.SettlementsResume.SettlementResume_GetById_Receive_Action
| Actions.SettlementsResume.SettlementResume_GetById_Fail_Action
| Actions.SettlementsResume.SettlementResume_Add_Request_Action
| Actions.SettlementsResume.SettlementResume_Add_Receive_Action
| Actions.SettlementsResume.SettlementResume_Add_Fail_Action
| Actions.SettlementsResume.SettlementResume_Update_Request_Action
| Actions.SettlementsResume.SettlementResume_Update_Receive_Action
| Actions.SettlementsResume.SettlementResume_Update_Fail_Action
| Actions.SettlementsResume.SettlementResume_Delete_Request_Action
| Actions.SettlementsResume.SettlementResume_Delete_Receive_Action
| Actions.SettlementsResume.SettlementResume_Delete_Fail_Action;

export const SettlementResumeReducer: Reducer<SettlementResumeState> = (
    state: SettlementResumeState | undefined, incomingAction: Action): SettlementResumeState => {
    
        if (state === undefined) {
            return SettlementResumeUnloadState as SettlementResumeState;
        }
    
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case 'SETTLEMENTRESUME_GETALL_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingAll: true,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTRESUME_GETALL_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: true,
                    failOnLoadingAll: false,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined,
                    list: action.settlementResumes
                };
            case 'SETTLEMENTRESUME_GETALL_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: true,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: action.error
                };
            case 'SETTLEMENTRESUME_UPDATEALL_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingAll: true,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: false,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTRESUME_UPDATEALL_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: true,
                    FailUpdatingAll: false,
                    error: undefined,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    list: action.settlementResumes
                };
            case 'SETTLEMENTRESUME_UPDATEALL_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: true,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: action.error
                };
            case 'SETTLEMENTRESUME_GETBYID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingOne: true,
                    successLoadingOne: false,
                    failOnLoadingOne: false,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTRESUME_GETBYID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: true,
                    failOnLoadingOne: false,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined,
                    list: [...state.list.filter((settlementResume: SettlementResume) => settlementResume.id !== action.settlementResume.id), action.settlementResume]
                };
            case 'SETTLEMENTRESUME_GETBYID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: false,
                    failOnLoadingOne: true,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: action.error
                };
            case 'SETTLEMENTRESUME_ADD_REQUEST_ACTION':
                return {
                    ...state,
                    isAddingNewOne: true,
                    successAddingNewOne: false,
                    failOnAddingNewOne: false,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTRESUME_ADD_RECEIVE_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: true,
                    failOnAddingNewOne: false,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined,
                    list: [...state.list, action.settlementResume]
                };
            case 'SETTLEMENTRESUME_ADD_FAIL_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: false,
                    failOnAddingNewOne: true,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: action.error
                };
            case 'SETTLEMENTRESUME_UPDATE_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTRESUME_UPDATE_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: true,
                    failOnUpdatingOne: false,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined,
                    list: [...state.list.filter((settlementResume: SettlementResume) => settlementResume.id !== action.settlementResume.id), action.settlementResume]
                };
            case 'SETTLEMENTRESUME_UPDATE_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: true,
                    successDeletingOne: false,
                    isDeletingOne: false,
                    failOnDeletingOne: false,
                    error: action.error
                };
            case 'SETTLEMENTRESUME_DELETE_REQUEST_ACTION':
                return {
                    ...state,
                    isDeletingOne: true,
                    successDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'SETTLEMENTRESUME_DELETE_RECEIVE_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: true,
                    failOnDeletingOne: false,
                    error: undefined,
                    list: state.list.filter((settlementResume: SettlementResume) => settlementResume.id !== action.settlementResumeId)
                };
            case 'SETTLEMENTRESUME_DELETE_FAIL_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: false,
                    failOnDeletingOne: true,
                    error: action.error
                };
            default:
                return state;
        }
    }
