import { AppThunkAction } from '../reducers';
import { ProviderPriceList } from '../../models/Entities/ProviderAssociations/PriceLists/ProviderPriceList';
import { ErrorType } from '../../models/HttpError';

// actions
import * as Actions from '../actions';

// services
import * as Services from '../../services/ProviderPriceList.Service';

type KnownAction =
  | Actions.ProviderPriceLists.ProviderPriceList_RequestAll_Action
  | Actions.ProviderPriceLists.ProviderPriceList_ReceiveAll_Action
  | Actions.ProviderPriceLists.ProviderPriceList_FailAll_Action
  | Actions.ProviderPriceLists.ProviderPriceList_RequestById_Action
  | Actions.ProviderPriceLists.ProviderPriceList_ReceiveById_Action
  | Actions.ProviderPriceLists.ProviderPriceList_FailById_Action
  | Actions.ProviderPriceLists.ProviderPriceList_RequestByPriceListId_Action
  | Actions.ProviderPriceLists.ProviderPriceList_ReceiveByPriceListId_Action
  | Actions.ProviderPriceLists.ProviderPriceList_FailByPriceListId_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Add_Request_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Add_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Add_Fail_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_Request_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_By_Price_List_Id_Request_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_By_Price_List_Id_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_Fail_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Clone_Request_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Clone_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Clone_Fail_Action
  | Actions.ProviderPriceLists.ProviderPriceList_SetEndValidity_Request_Action
  | Actions.ProviderPriceLists.ProviderPriceList_SetEndValidity_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_SetEndValidity_Fail_Action
  | Actions.ProviderPriceLists.ProviderPriceList_SetStartValidity_Request_Action
  | Actions.ProviderPriceLists.ProviderPriceList_SetStartValidity_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_SetStartValidity_Fail_Action;

export const actionCreators = {
  GetPriceListByProviderId:
    (ProviderId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_PRICE_LIST_FAIL_ALL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_PRICE_LIST_REQUEST_ALL_ACTION',
        ProviderId: ProviderId
      });

      Services.GetAllProviderPriceLists(ProviderId, token)
        .then((ProviderPriceLists: ProviderPriceList[]) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_RECEIVE_ALL_ACTION',
            ProviderId: ProviderId,
            ProviderPriceLists: ProviderPriceLists
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_FAIL_ALL_ACTION',
            error: error
          });
        });
    },
  GetProviderPriceListByPriceListId:
    (ProviderId: number, PriceListId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_PRICE_LIST_FAIL_BY_PRICELISTID_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_PRICE_LIST_REQUEST_BY_PRICELISTID_ACTION',
        ProviderId: ProviderId,
        PriceListId: PriceListId
      });

      Services.GetProviderPriceListByPriceListId(ProviderId, PriceListId, token)
        .then((ProviderPriceList) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_RECEIVE_BY_PRICELISTID_ACTION',
            ProviderId: ProviderId,
            PriceListId: PriceListId,
            ProviderPriceList
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_FAIL_BY_PRICELISTID_ACTION',
            error: error
          });
        });
    },
  GetProviderPriceListById:
    (ProviderPriceListId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_PRICE_LIST_FAIL_BY_ID_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_PRICE_LIST_REQUEST_BY_ID_ACTION',
        ProviderPriceListId: ProviderPriceListId
      });

      Services.GetProviderPriceListById(ProviderPriceListId, token)
        .then((ProviderPriceList) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_RECEIVE_BY_ID_ACTION',
            ProviderPriceList: ProviderPriceList
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_FAIL_BY_ID_ACTION',
            error: error
          });
        });
    },
  AddPriceList:
    (
      ProviderId: number,
      PriceListName: string,
      Comments: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_PRICE_LIST_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_PRICE_LIST_ADD_REQUEST_ACTION',
        providerId: ProviderId,
        priceListName: PriceListName,
        comments: Comments
      });

      Services.AddPriceListToProvider(ProviderId, PriceListName, Comments, token)
        .then((ProviderPriceList) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_ADD_RECEIVE_ACTION',
            ProviderPriceList: ProviderPriceList
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_ADD_FAIL_ACTION',
            error: error
          });
        });
    },
  DeletePriceListById:
    (
      ProviderId: number,
      ProviderPriceListId: number
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_PRICE_LIST_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_PRICE_LIST_DELETE_REQUEST_ACTION',
        ProviderPriceListId: ProviderPriceListId
      });

      Services.DeletePriceListFromProvider(ProviderPriceListId, token)
        .then(() => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_DELETE_RECEIVE_ACTION',
            ProviderId: ProviderId,
            ProviderPriceListId: ProviderPriceListId
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_DELETE_FAIL_ACTION',
            error: error
          });
        });
    },
  DeletePriceListByPriceListId:
    (ProviderId: number, PriceListId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_PRICE_LIST_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_PRICE_LIST_DELETE_BY_PRICE_LIST_ID_REQUEST_ACTION',
        ProviderId: ProviderId,
        PriceListId: PriceListId
      });

      Services.DeletePriceListFromProviderByPriceListId(ProviderId, PriceListId, token)
        .then(() => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_DELETE_BY_PRICE_LIST_ID_RECEIVE_ACTION',
            ProviderId: ProviderId,
            PriceListId: PriceListId
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_DELETE_FAIL_ACTION',
            error: error
          });
        });
    },
  CloneProviderPriceList:
    (ProviderPriceListId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_PRICE_LIST_CLONE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_PRICE_LIST_CLONE_REQUEST_ACTION',
        ProviderPriceListId: ProviderPriceListId
      });

      Services.ClonePriceListToProvider(ProviderPriceListId, token)
        .then((priceList: ProviderPriceList) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_CLONE_RECEIVE_ACTION',
            ProviderPriceList: priceList
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_CLONE_FAIL_ACTION',
            error: error
          });
        });
    },
  SetStartValidity:
    (
      providerPriceListId: number,
      startValidity: Date
    ): AppThunkAction<KnownAction> =>
    async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_PRICE_LIST_SET_START_VALIDITY_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_PRICE_LIST_SET_START_VALIDITY_REQUEST_ACTION',
        providerPriceListId: providerPriceListId,
        startValidity: startValidity
      });

      Services.SetPriceListStartValidityDate(providerPriceListId, startValidity, token)
        .then((_newList: ProviderPriceList) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_SET_START_VALIDITY_RECEIVE_ACTION',
            ProviderPriceList: _newList
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_SET_START_VALIDITY_FAIL_ACTION',
            error: error
          });
        });
    },
  SetEndValidity:
    (
      providerPriceListId: number,
      endValidity: Date
    ): AppThunkAction<KnownAction> =>
    async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_PRICE_LIST_SET_END_VALIDITY_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_PRICE_LIST_SET_END_VALIDITY_REQUEST_ACTION',
        TenantPriceListId: providerPriceListId,
        EndValidity: endValidity
      });

      Services.SetPriceListEndValidityDate(providerPriceListId, endValidity, token)
        .then((_newList: ProviderPriceList) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_SET_END_VALIDITY_RECEIVE_ACTION',
            TenantPriceList: _newList
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'PROVIDER_PRICE_LIST_SET_END_VALIDITY_FAIL_ACTION',
            error: error
          });
        });
    }
};
