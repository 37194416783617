import { useParams, useLocation } from 'react-router-dom';

export default function WithRouter(Child: any) {
  return function WithRouter(props: any) {
    const params = useParams();
    const location = useLocation();
    return <Child {...props} params={params} location={location} />;
  };
}

export const withRouter = (Component: any) => (props: any) => {
  const location = useLocation();
  const match = { params: useParams() };
  return <Component location={location} match={match} {...props} />;
};
