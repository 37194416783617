
// models
import { PatientVisit } from "../../models/Entities/Protocols/PatientVisist";
import { CommonState } from "../common/state/commonState";

export interface PractitionerProtocolVisitsState extends CommonState<PatientVisit> {
    
};

export const PractitionerProtocolVisitsUnloadState: any = {
    // loading all
    isLoadingAll: false,
    successLoadingAll: undefined,
    failOnLoadingAll: false,

    // update all
    isUpdatingAll: false,
    UpdatingAllSuccess: undefined,
    FailUpdatingAll: false,

    // loading one
    isLoadingOne: false,
    successLoadingOne: undefined,
    failOnLoadingOne: false,

    // saving
    isSaving: false,
    successSaving: undefined,
    failOnSaving: false,

    // adding
    isAdding: false,
    successAdding: undefined,
    failOnAdding: false,

    // deleting
    isDeletingOne: false,
    successDeletingOne: undefined,
    failOnDeletingOne: false
};