import { SelectedMedicalServiceType } from './../../MedicalServices/MedicalServiceSelector/MedicalServiceMultiSelectorComponent';
import { Practitioner } from './../../../models/Entities/Practitioners/Practitioner';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';
import { IIconProps } from '@fluentui/react';
import { PractitionerAssociationsMedicalService } from '../../../models/Entities/PractitionerAssociations/MedicalServices/PractitionerAssociationMedicalService';

//stores
import * as PractitionerServiceInsuranceAssociationsStore from '../../../redux/middleware/PractitionerMedicalServiceAssociationMiddleware';
import * as PractitionerStore from '../../../redux/middleware/PractitionerMiddleware';

//#region props

interface IProps {
  PractitionerId: string;
}

interface ConnectedProps {
  //status
  IsLoading: boolean;
  LoadedSuccessfully: boolean;
  FailOnLoad: boolean;
  ErrorOnLoad: ErrorType;

  IsAssociating: boolean;
  AssociatedSuccessfully: boolean;
  FailOnAssociate: boolean;
  ErrorOnAssociate: ErrorType;

  IsDisassociating: boolean;
  DisassociatedSuccessfully: boolean;
  FailOnDisassociate: boolean;
  ErrorOnDisassociate: ErrorType;

  MedicalServicesAssociated: PractitionerAssociationsMedicalService[];
  Practitioners: Practitioner[];
}

interface DispatchProps {
  AssociateMedicalServiceToPractitioner: typeof PractitionerServiceInsuranceAssociationsStore.actionCreators.AssociateMedicalServiceToPractitioner;
  DisassociateMedicalServiceFromPractitionerByMedicalServiceId: typeof PractitionerServiceInsuranceAssociationsStore.actionCreators.DisassociateMedicalServiceFromPractitionerByMedicalServiceId;
  GetMedicalServiceByPractitionerId: typeof PractitionerServiceInsuranceAssociationsStore.actionCreators.GetMedicalServiceByPractitionerId;
  GetAllPractitioners: typeof PractitionerStore.actionCreators.GetAllPractitioners;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  MedicalServicesAssociated: state.Practitioner?.list.find(
    (practitioner: Practitioner) =>
      practitioner.userId === ownProps.PractitionerId
  )?.MedicalServicesAssociated,

  Practitioners: state?.Practitioner?.list,

  //load status
  IsLoading: state.PractitionerMedicalServiceAssociations?.isLoadingAll,
  LoadedSuccessfully:
    state.PractitionerMedicalServiceAssociations?.successLoadingAll,
  FailOnLoad: state.PractitionerMedicalServiceAssociations?.failOnLoadingAll,
  ErrorOnLoad: state.PractitionerMedicalServiceAssociations?.error,

  // associate status
  IsAssociating: state.PractitionerMedicalServiceAssociations?.isAddingNewOne,
  AssociatedSuccessfully:
    state.PractitionerMedicalServiceAssociations?.successAddingNewOne,
  FailOnAssociate:
    state.PractitionerMedicalServiceAssociations?.failOnAddingNewOne,
  ErrorOnAssociate: state.PractitionerMedicalServiceAssociations?.error,

  IsDisassociating: state.PractitionerMedicalServiceAssociations?.isDeletingOne,
  DisassociatedSuccessfully:
    state.PractitionerMedicalServiceAssociations?.successDeletingOne,
  FailOnDisassociate:
    state.PractitionerMedicalServiceAssociations?.failOnDeletingOne,
  ErrorOnDisassociate: state.PractitionerMedicalServiceAssociations?.error
});

export const mapDispatchToProps = {
  ...PractitionerServiceInsuranceAssociationsStore.actionCreators,
  ...PractitionerStore.actionCreators
};

//icons
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };

// functions

export function GetSelectedMedicalServices(
  MedicalServiceSelected: PractitionerAssociationsMedicalService[]
): SelectedMedicalServiceType[] {
  return MedicalServiceSelected
    ? MedicalServiceSelected.map(
        (mi: PractitionerAssociationsMedicalService) => ({
          medicalServiceId: mi.medicalServiceId,
          ReferenceId: mi.id
        })
      )
    : [];
}
