import * as React from 'react';
import { IChartProps, ILineChartProps, LineChart, DataVizPalette, ILineChartDataPoint } from '@fluentui/react-charting';
import { useEffect, useState } from 'react';

// service
import * as PaymentService from '../../../../services/CashFlow-Payments.Services';
import { use } from 'i18next';
import { ChartPaymentDate } from '../../../../models/Entities/CashFlow/Payment/ChartPaymentDate';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers';

interface ILineChartBasicState {
  width: number;
  height: number;
  allowMultipleShapes: boolean;
  showAxisTitles: boolean;
  useUTC: boolean;
}

export const PaymentsGraphComponent = (props: {
  startDate: Date;
  endDate: Date;
  entityIdentifier: string;
  relatedEntityIdentifier?: string | undefined;
}) => {

  const authToken: string | undefined = useSelector((state: AppState) => state?.oidc?.user?.access_token);

  const [width, setWidth] = useState(700);
  const [height, setHeight] = useState(300);
  const [allowMultipleShapes, setAllowMultipleShapes] = useState(false);
  const [showAxisTitles, setShowAxisTitles] = useState(true);
  const [useUTC, setUseUTC] = useState(true);

  const [incomeData, setIncomeData] = useState<ChartPaymentDate[]>([]);
  const [outcomeData, setOutcomeData] = useState<ChartPaymentDate[]>([]);

  // data income
  useEffect(() => {

    const fetchIncomeData = async () => {
      var incomeDataFeched = authToken && await PaymentService.GetPaymentsChart(
        PaymentService.PaymentType.income,
        props.entityIdentifier,
        props.relatedEntityIdentifier,
        props.startDate,
        props.endDate,
        authToken
      );
      setIncomeData(incomeDataFeched && incomeDataFeched.length > 0 ? incomeDataFeched : [{
        x: new Date(),
        y: 0
      }]);
    }

    const fetchOutcomeData = async () => {
      var outComeData = authToken && await PaymentService.GetPaymentsChart(
        PaymentService.PaymentType.outcome,
        props.entityIdentifier,
        props.relatedEntityIdentifier,
        props.startDate,
        props.endDate,
        authToken
      );
      setOutcomeData(outComeData && outComeData.length > 0 ? outComeData : [{
        x: new Date(),
        y: 0
      }]);
    }

    fetchIncomeData();
    fetchOutcomeData();
    
   ;
  }, [authToken, props.startDate, props.endDate, props.entityIdentifier, props.relatedEntityIdentifier]);

  const data: IChartProps = {
    chartTitle: 'Line Chart',
    lineChartData: [
      {
        legend: 'incoming',
        data: incomeData.sort(
          (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()
        ).map(data => { return { x: new Date(data.x), y: Number(data.y) }}) as ILineChartDataPoint[],
        color: DataVizPalette.color3,
        lineOptions: {
          lineBorderWidth: '4',
        },
        onLineClick: () => console.log('From_Legacy_to_O365'),
      },
      {
        legend: 'outcomming',
        data: outcomeData.sort(
          (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()
        ).map(data => { return { x: new Date(data.x), y: Number(data.y) }}) as ILineChartDataPoint[],
        color: DataVizPalette.color4,
        lineOptions: {
          lineBorderWidth: '4',
        },
      },
    ],
  };

  //const rootStyle = { width: `${width}px`, height: `${height}px` };
  const rootStyle = { width: `calc(100vw - 19em)`, height: `${height}px` };

  return (
    <>
      <div style={rootStyle}>
        <LineChart
          // Force rerender when any of the following states change
          key={`${showAxisTitles}`}
          culture={window.navigator.language}
          data={data}
          legendsOverflowText={'Overflow Items'}
          yMinValue={200}
          yMaxValue={301}
          height={height}
          width={width}
          xAxisTickCount={10}
          allowMultipleShapesForPoints={allowMultipleShapes}
          enablePerfOptimization={true}
          yAxisTitle={showAxisTitles ? 'Montos expresados en moneda local' : undefined}
          xAxisTitle={showAxisTitles ? 'Periodo' : undefined}
          useUTC={useUTC}
        />
      </div>
    </>
  );
};
  