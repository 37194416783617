import { Checkbox, TextField, Text, MessageBarType, Label, FontSizes, Separator } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { MessageComponent } from '../../../../commons/component/MessageComponent';
import { ContractConceptPaymentSchedule } from '../../../../models/Entities/Contracts/ContractConceptPaymentSchedule';
import { ContractStage } from '../../../../models/Entities/Contracts/ContractStage';

// styles
//import '../ProtocolContractFormComponent.css';

//i18Next
import { useTranslation, Trans } from 'react-i18next';

// components
import ContractStageMultiselectorItemComponent from './ContractStageMultiselectorItem/ContractStageMultiselectorItem.Component';

// Components functions
import * as ComponentFunctions from './ContractStageMultiselector.Functions';
import { connect } from 'react-redux';

interface StageSaved {
  StageId: number;
  Saved: boolean;
}

const ContractStageMultiSelectorComponent = (props: ComponentFunctions.Props) => {
  
  const [overheadValue, setOverheadValue] = useState<number>(0);
  const [conceptPaymentSchedule, setConceptPaymentSchedule] = useState<ContractConceptPaymentSchedule[]>([]);
  const [StagesStatus, setStagesStatus] = useState<StageSaved[]>([]);

  const isAllSavedHandler = (StageId: number) => {
    setStagesStatus(prevStagesStatus => prevStagesStatus.map((stage: StageSaved) => {
      if(stage.StageId === StageId){
        return {
          StageId: StageId,
          Saved: true
        } as StageSaved;
      }
      return stage as StageSaved;
    }));
  }

  //useTranslation()
  const [t] = useTranslation();

  useEffect(() => {
    StagesStatus?.filter((stage: StageSaved) => {
      return stage.Saved === false;
    }).length === 0 ? props.OnAllSaved(true) : props.OnAllSaved(false);
  }, [StagesStatus]);

  useEffect(() => {
    if(props.Stages.length > 0){
      setStagesStatus(props.Stages.map((stage: ContractStage) => {
        return {
          StageId: stage.id,
          Saved: false
        }
      }));
    }
  }, [props.Stages]);
  
  return (
    <>
      <ul>
        {props.Stages.map((stage: ContractStage, index: number) => (
          <li className={stage.isSelected ? 'StageSelectorItem Active' : 'StageSelectorItem'} key={index}>
            <ContractStageMultiselectorItemComponent
              StageId={stage.id}
              ContractConceptId={props.ContractConceptId}
              ContractAddendumId={props.ContractAddendumId}
              ContractId={props.ContractId}
              CurrencyCode={props.Contract?.contractCurrency || ''}
              ShowInputValues={props.ShowInputValues}
              ConceptSaved={props.ConceptSaved}
              isSavedItem={isAllSavedHandler}
             />
          </li>
        ))}
      </ul>
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(ContractStageMultiSelectorComponent as any);
