import { connect } from 'react-redux';
import { useEffect } from 'react';
import { Caption1Stronger, Caption2, Label, MessageBar, MessageBarBody, MessageBarTitle, Popover, PopoverSurface, PopoverTrigger, Text, Tooltip } from '@fluentui/react-components';

// component functions
import * as ComponentFunctions from './Protocol.Activity.Functions';

//icond
import { Warning16Regular } from '@fluentui/react-icons';

// styles
import './Protocol.Activity.Styles.css';

// models
import { ContractStage } from '../../../models/Entities/Contracts/ContractStage';
import { ProtocolPatient } from '../../../models/Entities/Protocols/ProtocolPatient';
import { Separator } from '@fluentui/react';
import { PatientVisit } from '../../../models/Entities/Protocols/PatientVisist';
import Moment from 'react-moment';

const ProtocolActivityComponent = (props: ComponentFunctions.Props) => {

    useEffect(() => {
        props.GetMedicalProtocolById(props.ProtocolId);
        props.GetAllPractitioners(1, 1000, '');
    }, [props.ProtocolId]);

    useEffect(() => {
        if (props.Protocol) {
            props.GetAllProtocolContractAssociations(props.Protocol.id);
            props.GetProtocolPatients(props.ProtocolId, null);
            props.GetPatientVisitByProtocolId(props.ProtocolId);
        }
    }, [props.Protocol != null]);

    useEffect(() => {
        if (props.ContractId) {
            props.GetContractById(props.ContractId);
        }
    }, [props.ContractId]);

    useEffect(() => {
        if (props.Contract) {
            props.GetAllContractsStage(props.Contract.id);
        }
    }, [props.Contract?.id]);


    const VisitGrid = (stage: ComponentFunctions.internalStage, visit: PatientVisit | null) => {

        if (visit) {
            return (
                <div>
                    <div className='statusBadge'>
                    <span className={'VisitActivity cirle '.concat(ComponentFunctions.GetVisitStatusByVisit(visit.status)).concat(!stage.stageId ? ' warning' : '')}></span><label>{ComponentFunctions.GetVisitStatusDescription(visit.status)}</label>
                    </div>
                    <Separator />
                    <h4 className={ComponentFunctions.useStyles().contentHeader}>{visit.title} | {visit.visitType === 0 ? 'Presencial' : 'Telefónica'}</h4>
                    {!stage.stageId && <>
                        <MessageBar key={'error'} intent={'error'}>
                            <MessageBarBody>
                                <MessageBarTitle>Atención: </MessageBarTitle>
                                Esta visita no esta definida en el contrato
                            </MessageBarBody>
                        </MessageBar>
                    </>
                    }
                    <Label>Paciente: </Label>
                    <Caption1Stronger>{visit.numberInProtocol}</Caption1Stronger>
                    <div>Fecha de realización: <Caption1Stronger>{<Moment date={visit.date} format='DD/MM/YYYY hh:mm a' />}</Caption1Stronger></div>
                    <div>Profesional médico: <Caption1Stronger>{props.GetPractitioner(visit.createdById)?.fullName || 'No encontrado'}</Caption1Stronger></div>
                </div>
            );
        }
        const styles = ComponentFunctions.useStyles();
        return (
            <div>
                <h4 className={styles.contentHeader}>Visita no realizada</h4>
                {!stage.stageId && <>
                    <MessageBar key={'error'} intent={'error'}>
                        <MessageBarBody>
                            <MessageBarTitle>Atención: </MessageBarTitle>
                            Esta visita no esta definida en el contrato
                        </MessageBarBody>
                    </MessageBar>
                </>
                }
            </div>
        );
    };

    return (
        <>
            {props.ProtocolPatients.length === 0 && <MessageBar>
                <MessageBarBody>
                    <MessageBarTitle>No hay pacientes registrados</MessageBarTitle>
                    No se han econtrado pacientes registrados para este protocolo.{" "}
                </MessageBarBody>
            </MessageBar>}
            {props.ContractId === null && <MessageBar>
                <MessageBarBody>
                    <MessageBarTitle>No hay contrato asociado</MessageBarTitle>
                    No se han asociado un contrato a este protocolo.{" "}
                </MessageBarBody>
            </MessageBar>}
            {ComponentFunctions.GetRealStages(props.Stages, props.Visits)?.length > 0 && <div className='tableContainer'>
                <table className='table-header-rotated'>
                    <thead>
                        <tr>
                            <th style={{ minWidth: '17em' }}>Paciente</th>
                            {ComponentFunctions.GetRealStages(props.Stages, props.Visits)?.map((stage: ComponentFunctions.internalStage) => <th className='rotate-45'>
                                <div><span>{stage.stageName}</span></div>
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {props.ProtocolPatients.map((patient: ProtocolPatient) =>
                            <tr key={patient.medicalRecordNumber}>
                                <th><Text>{patient.numberInProtocol}</Text></th>
                                {ComponentFunctions.GetRealStages(props.Stages, props.Visits)?.map((stage: ComponentFunctions.internalStage) => <td>
                                    <Popover withArrow>
                                        <PopoverTrigger disableButtonEnhancement>
                                            <div className={'VisitActivity '.concat(ComponentFunctions.GetVisitStatus(props.Visits, patient.numberInProtocol, stage)).concat(!stage.stageId ? ' warning' : '')}>
                                               
                                            </div>
                                        </PopoverTrigger>

                                        {ComponentFunctions.IsVisitExist(props.Visits, patient.numberInProtocol, stage) ? <PopoverSurface tabIndex={-1}>
                                            {VisitGrid(stage, ComponentFunctions.GetVisitByPatientAndStage(props.Visits, patient.numberInProtocol, stage))}
                                        </PopoverSurface> : <PopoverSurface tabIndex={-1}>
                                            {VisitGrid(stage, null)}
                                        </PopoverSurface>
                                        }
                                    </Popover>

                                </td>)}
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
            }
            {ComponentFunctions.GetRealStages(props.Stages, props.Visits)?.length === 0 &&
                <div className='NoDataContainer'>
                    <img
                        src={require('../../../assets/img/nodata.png')}
                        alt="No Data"
                        style={{ height: '6em' }}
                    ></img>
                    <Text className='NoData'>No hay visitas realizadas</Text>
                </div>
            }
        </>
    )

}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ProtocolActivityComponent as any);