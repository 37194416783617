import Moment from 'react-moment';
import {
  DatePicker,
  DayOfWeek,
  defaultDatePickerStrings,
  IconButton,
  IIconProps,
  MessageBar,
  MessageBarType,
  Separator,
  Text,
  Label
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { AppState } from '../../../redux/reducers';
import { connect, useDispatch, useSelector } from 'react-redux';

// styles
import './PriceListInfoComponent.css';

// Store
import * as TenantPriceListStore from '../../../redux/middleware/TenantPriceListsMiddleware';

// functions
import * as ownFunctions from '../AgreementPricelist/AgreementPriceListFunctions';
import { TenantPriceList } from '../../../models/Entities/TenantPriceLists/TenantPriceList';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export interface IProps {
  TenantPriceList: TenantPriceList;
}

export const TenantPriceListInfoComponent = (props: IProps) => {
  const [Today, setToday] = useState<Date>(new Date());

  const [activeDateFrom, setActiveDateFrom] = useState<boolean>(false);
  const [activeDateTo, setActiveDateTo] = useState<boolean>(false);

  const [validityDateStart, setValidityDateStart] = useState<Date>();
  const [validityDateEnd, setValidityDateEnd] = useState<Date>();

  const [validityErrors, setValidityErrors] = useState<string[]>([]);

  const editIcon: IIconProps = { iconName: 'Edit' };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const okIcon: IIconProps = { iconName: 'CheckMark' };

  const dispatch = useDispatch();

  const [firstDayOfWeek, setFirstDayOfWeek] = useState(DayOfWeek.Sunday);

  //useTranslation()
  const [t] = useTranslation()
  // Events
  const onChangeValidityDateStartFieldValue = (
    date: Date | null | undefined
  ) => {
    setValidityDateStart(date || new Date());
  };

  const onChangeValidityDateEndFieldValue = (date: Date | null | undefined) => {
    setValidityDateEnd(date || new Date());
  };

  // confirm date changes
  const onConfirmValidityStartClickHandler = () => {
    if (validityDateStart && ValidateValidity()) {
      dispatch(
        mapDispatchToProps.SetStartValidity(
          props.TenantPriceList.id!,
          validityDateStart
        )
      );
      setActiveDateFrom(!activeDateFrom);
    }
  };

  const onConfirmValidityEndClickHandler = () => {
    if (validityDateEnd && ValidateValidity()) {
      dispatch(
        mapDispatchToProps.SetEndValidity(
          props.TenantPriceList.id!,
          validityDateEnd
        )
      );
      setActiveDateTo(!activeDateTo);
    }
  };

  useEffect(() => {
    if (props.TenantPriceList) {
      setValidityDateStart(
        props.TenantPriceList.effectiveDateStart
          ? new Date(props.TenantPriceList.effectiveDateStart)
          : undefined
      );
      setValidityDateEnd(
        props.TenantPriceList.effectiveDateEnd
          ? new Date(props.TenantPriceList.effectiveDateEnd)
          : undefined
      );
    }
  }, [props.TenantPriceList]);

  const ValidateValidity = (): boolean => {
    var ErrorList: string[] = [];
    var ResultValidation: boolean = true;

    if (
      validityDateStart &&
      validityDateEnd &&
      validityDateStart >= validityDateEnd
    ) {
      ErrorList.push(
        t('tenantPriceListInfo.validityError.dateError')
      );
      ResultValidation = false;
    }

    setValidityErrors(ErrorList);

    return ResultValidation;
  };

  return props.TenantPriceList ? (
    <>
      {validityErrors.length > 0 && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          <Text>{t('tenantPriceListInfo.attentionTitle')}</Text>
          <ul>
            {validityErrors.map((error: string) => (
              <li>{error}</li>
            ))}
          </ul>
        </MessageBar>
      )}
      <Text>
        <small>{t('tenantPriceListInfo.validSince')}</small>
      </Text>
      <div>
        {activeDateFrom && (
          <div className="fieldCommands">
            <DatePicker
              firstDayOfWeek={firstDayOfWeek}
              placeholder={t('tenantPriceListInfo.parameters.validityStart')}
              ariaLabel="Inicio de vigencia"
              formatDate={onFormatDate}
              onSelectDate={onChangeValidityDateStartFieldValue}
              strings={defaultDatePickerStrings}
              value={validityDateStart}
            />
            <IconButton
              onClick={onConfirmValidityStartClickHandler}
              iconProps={okIcon}
              title={t('tenantPriceListInfo.parameters.confirmButton')}
              ariaLabel="Confirmar"
            />
            <IconButton
              onClick={() => {
                setActiveDateFrom(!activeDateFrom);
                setValidityErrors([]);
              }}
              iconProps={cancelIcon}
              title={t('tenantPriceListInfo.parameters.cancelButton')}
              ariaLabel="Cancelar"
            />
          </div>
        )}
        {!activeDateFrom &&
          (props.TenantPriceList && props.TenantPriceList.effectiveDateStart ? (
            <div className="fieldCommands">
              <label>
                <Moment format="DD/MM/YYYY">
                  {props.TenantPriceList.effectiveDateStart}
                </Moment>
              </label>
              <IconButton
                onClick={() => setActiveDateFrom(!activeDateFrom)}
                iconProps={editIcon}
                title={t('tenantPriceListInfo.parameters.editButton')}
                ariaLabel="edit"
              />
            </div>
          ) : (
            <div className="fieldCommands">
              <label>{t('tenantPriceListInfo.parameters.notInformed')}</label>
              <IconButton
                onClick={() => setActiveDateFrom(!activeDateFrom)}
                iconProps={editIcon}
                title={t('tenantPriceListInfo.parameters.editButton')}
                ariaLabel="edit"
              />
            </div>
          ))}
      </div>
      <Text>
        <small>{t('tenantPriceListInfo.validUntil')}</small>
      </Text>
      <div>
        {activeDateTo && (
          <div className="fieldCommands">
            <DatePicker
              firstDayOfWeek={firstDayOfWeek}
              placeholder={t('tenantPriceListInfo.parameters.endOfValidity')}
              ariaLabel="Finalización de vigencia"
              formatDate={onFormatDate}
              onSelectDate={onChangeValidityDateEndFieldValue}
              strings={defaultDatePickerStrings}
              value={validityDateEnd}
            />
            <IconButton
              onClick={onConfirmValidityEndClickHandler}
              iconProps={okIcon}
              title={t('tenantPriceListInfo.parameters.confirmButton')}
              ariaLabel="Confirmar"
            />
            <IconButton
              onClick={() => {
                setActiveDateTo(!activeDateTo);
                setValidityErrors([]);
              }}
              iconProps={cancelIcon}
              title={t('tenantPriceListInfo.parameters.cancelButton')}
              ariaLabel="Cancelar"
            />
          </div>
        )}
        {!activeDateTo &&
          (props.TenantPriceList && props.TenantPriceList.effectiveDateEnd ? (
            <div className="fieldCommands">
              <label>
                <Moment format="DD/MM/YYYY">
                  {props.TenantPriceList.effectiveDateEnd}
                </Moment>
              </label>
              <IconButton
                onClick={() => setActiveDateTo(!activeDateTo)}
                iconProps={editIcon}
                title={t('tenantPriceListInfo.parameters.editButton')}
                ariaLabel="edit"
              />
            </div>
          ) : (
            <div className="fieldCommands">
              <label>{t('tenantPriceListInfo.parameters.notInformed')}</label>
              <IconButton
                onClick={() => setActiveDateTo(!activeDateTo)}
                iconProps={editIcon}
                title={t('tenantPriceListInfo.parameters.editButton')}
                ariaLabel="edit"
              />
            </div>
          ))}
      </div>
      <Separator />
      <Text>
        <small>{t('tenantPriceListInfo.state')}</small>
      </Text>
      <div>
        {props.TenantPriceList && (
          <Label
            className={
              'priceListStatus pricelist ' +
              ownFunctions.GetPriceListStatus(props.TenantPriceList).status
            }
          >
            {ownFunctions.GetPriceListStatus(props.TenantPriceList).message}
          </Label>
        )}
      </div>
      <Separator />
      <Text>
        <small>{t('tenantPriceListInfo.expiration')}</small>
      </Text>
      <div>
        {props.TenantPriceList && props.TenantPriceList.effectiveDateEnd ? (
          <label>
            <Moment to={props.TenantPriceList.effectiveDateEnd} unit="days">
              {Today}
            </Moment>
          </label>
        ) : (
          <label>{t('tenantPriceListInfo.undetermined')}</label>
        )}
      </div>
    </>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  ...state.TenantPriceLists
});

const mapDispatchToProps = {
  ...TenantPriceListStore.ActionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantPriceListInfoComponent as any);

const onFormatDate = (date?: Date): string => {
  return !date
    ? ''
    : date.getDate().toString().padStart(2, '0') +
        '/' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getFullYear();
};
