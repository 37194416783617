import { connect } from 'react-redux';	
import { Label, Separator, Text } from '@fluentui/react';
import { useEffect, useState } from 'react';
import MOMENT from 'react-moment';

//functions
import * as ComponentFunctions from './ProtocolHeader.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Styles
import './ProtocolHeader.Styles.css';


export const ProtocolHeaderComponent = (props: ComponentFunctions.Props) => {

    const [Today, setToday] = useState<Date>(new Date());

    //useTranslation()
    const [t] = useTranslation();
    
    useEffect(() => {
        props.GetMedicalProtocolById(props.ProtocolId);
    }, []);


    return (
        <>
        <div className={ "Section" }>
            <div className="ProtocolHeader">
                <div className="ProtocolHeader__Title">
                    <h3>{props.Protocol?.title}</h3>
                    <Text>{props.Protocol?.fullTitle}</Text>
                    <Separator />
                </div>
                <div className='ProtocolHeaderDetails'>
                    <div>
                        <small>{t('protocolHeader.details.status')}</small>
                        <Label><strong>{ComponentFunctions.GetProtocolStatusDescripcion(props.Protocol?.status.id)}</strong></Label>
                    </div>
                    <div>
                        <small>{t('protocolHeader.details.phase')}</small>
                        <Label><strong>{props.Protocol?.phase.title}</strong></Label>
                    </div>
                    <div>
                        <small>{t('protocolHeader.details.specialty')}</small>
                        <Label><strong>{props.Protocol?.specialty.description}</strong></Label>
                    </div>
                </div>
                {/* <div className="ContractHeader__Buttons">
                    <DefaultButton
                        text="Editar"
                        iconProps={{ iconName: 'Edit' }}
                        onClick={() => EditContract()}
                    />
                    <DefaultButton
                        text="Asociar protocolo"
                        iconProps={{ iconName: 'Add' }}
                        onClick={() => AssociateProtocol()}
                    />
                </div> */}
            </div>
        </div>
        </>
    );

}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
  )(ProtocolHeaderComponent as any);