import { connect } from 'react-redux';
import { DefaultButton, Label, Link, Separator, Spinner, Text } from '@fluentui/react';


// styles
import './CommissionAgentAssociated.Styles.css';


// component functions
import * as ComponentFunctions from './CommissionAgentAssociated.Functions';

// components
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';
import { CommissionAgentAssociatedItemComponent } from './CommissionAgentAssociatedItem.Component';

// models
import { ContractCommissionAgent } from '../../../models/Entities/Contracts/ContractCommissionAgent';
import { useNavigate } from 'react-router-dom';

const CommissionAgentAssociatedComponent = (props: ComponentFunctions.Props) => {

    const Navigate = useNavigate();


    const Messages = props.Contract?.FailGettingCommissionAgentContractAssociation === true && (
        <>
            <ErrorMessageComponent Error={props.Contract?.ErrorGettingCommissionAgentContractAssociation} />
        </>
    );

    const Loading = props.Contract?.isGettingCommissionAgentContractAssociation === true && (
        <>
            <div>
                <Spinner
                    label={'Cargando medicos asociados al contrato'}
                    ariaLive="assertive"
                    labelPosition="top"
                />
            </div>
        </>
    );


    return (
        <div>
            {Messages}
            {Loading}
            {props.Contract?.GettingCommissionAgentContractAssociationSuccess === true && (props.Contract?.commissionAgentsAssociated || []).length > 0 && (
                <>
                <p>
                <Label>Profesionales asociados al contrato</Label>
                </p>
                {props.Contract?.commissionAgentsAssociated?.map((commissionAgent: ContractCommissionAgent) => {
                    return (
                        <div key={'CAA_' + commissionAgent.id.toString()} className='ContractAgentAssociationItem'>
                            <CommissionAgentAssociatedItemComponent commissionAgent={commissionAgent} practitioner={props.GetPractitioner(commissionAgent.commissionAgentId)} />
                            <Link target='_blank'  href={window.location.origin + '/contracts/' + commissionAgent.contractId}>ir al contrato</Link>
                        </div>
                    )
                })}
                </>
            )}
        </div>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(CommissionAgentAssociatedComponent as any); 