import { NewAgreementService } from './../models/Entities/AgreementServices/NewAgreementService';
import { IResult } from '../models/HttpResult';
import { IPagedResult } from '../models/HttpPagedResult';
import { AgreementService } from '../models/Entities/AgreementServices/AgreementServices';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'agreements/';

export const GetAllAgreementServices = async (
  agreementId: number,
  pageNumber: number,
  pageSize: number,
  searchText: string | undefined
, token: string): Promise<AgreementService[]> => {

  let params: URLSearchParams = Functions.GetQueryStringParams(
    pageNumber,
    pageSize,
    searchText
  );

  let RequestURL: string = BaseURL + agreementId + '/services';

  if (params) RequestURL += '?' + params.toString();

  try {
    const Response: AgreementService[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IPagedResult<AgreementService[]>>;
        }
      })
      .then((data: IPagedResult<AgreementService[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetAgreementServicesById = async (
  agreementServiceId: number
, token: string): Promise<AgreementService> => {

  let RequestURL: string = BaseURL + 'services/' + agreementServiceId;
  
  try {
    
    const Response: AgreementService = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AgreementService>>;
        }
      })
      .then((data: IResult<AgreementService>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AddMedicalServicesToAgreement = async (
  AgreementService: NewAgreementService
, token: string): Promise<AgreementService> => {

  let RequestURL: string = BaseURL + 'services';
  try {
    
    const Response: AgreementService = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(AgreementService)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AgreementService>>;
        }
      })
      .then((data: IResult<AgreementService>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const UpdateMedicalServiceOnAgreement = async (
  AgreementService: AgreementService
, token: string): Promise<AgreementService> => {

  let RequestURL: string = BaseURL + 'services';

  try {
    
    const Response: AgreementService = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(AgreementService)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AgreementService>>;
        }
      })
      .then((data: IResult<AgreementService>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DeleteAgreementService = async (
  AgreementServiceId: number
, token: string): Promise<number> => {

  let RequestURL: string = BaseURL + 'services/' + AgreementServiceId;
  
  try {
    
    const Response: number = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<null>>;
        }
      })
      .then((data: IResult<null>) => {
        return AgreementServiceId;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AgreementServiceChangeStatus = async (
  AgreementServiceId: number,
  newStatus: boolean
, token: string): Promise<AgreementService> => {

  let RequestURL: string =
  BaseURL + 'services/' + AgreementServiceId + '/status/' + newStatus;
  
  try {
    
    const Response: AgreementService = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AgreementService>>;
        }
      })
      .then((data: IResult<AgreementService>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
