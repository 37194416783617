import { Reducer, Action } from 'redux';
import {
  practitionerContractInitialState,
  PractitionerContractState
} from '../states/PractitionerContractState';

// Functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.PractitionerContracts.PractitionerContractsGetAll_Request_Action
  | Actions.PractitionerContracts.PractitionerContractsGetAll_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsGetAll_Fail_Action
  | Actions.PractitionerContracts.PractitionerContractsGetById_Request_Action
  | Actions.PractitionerContracts.PractitionerContractsGetById_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsGetById_Fail_Action
  | Actions.PractitionerContracts.PractitionerContractsAdd_Request_Action
  | Actions.PractitionerContracts.PractitionerContractsAdd_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsAdd_Fail_Action
  | Actions.PractitionerContracts.PractitionerContractsUpdate_Request_Action
  | Actions.PractitionerContracts.PractitionerContractsUpdate_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsUpdate_Fail_Action;

export const PractitionerContractReducer: Reducer<PractitionerContractState> = (
  state: PractitionerContractState | undefined,
  incomingAction: Action
): PractitionerContractState => {
  if (state === undefined) {
    return practitionerContractInitialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'PRACTITIONER_CONTRACTS_GET_ALL_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'PRACTITIONER_CONTRACTS_GET_ALL_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'PRACTITIONER_CONTRACTS_GET_ALL_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'PRACTITIONER_CONTRACTS_GET_BY_ID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'PRACTITIONER_CONTRACTS_GET_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'PRACTITIONER_CONTRACTS_GET_BY_ID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'PRACTITIONER_CONTRACTS_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'PRACTITIONER_CONTRACTS_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'PRACTITIONER_CONTRACTS_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'PRACTITIONER_CONTRACTS_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'PRACTITIONER_CONTRACTS_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        error: undefined,
        list: state.list.map((practitionerContract: any) => {
          if (practitionerContract.id === action.practitionerContract.id) {
            return action.practitionerContract;
          }
          return practitionerContract;
        })
      };
    case 'PRACTITIONER_CONTRACTS_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default PractitionerContractReducer;
