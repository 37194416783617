import {
  IPersonaSharedProps,
  personaSize,

} from '@fluentui/react';

import { Persona } from '@fluentui/react-components';

// Common Function
import * as CommonFunction from '../../../../functions/common';

// styles
import './HeaderPersonaComponent.css';

export type PersonaSize = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large' | 'huge' ;

export interface IProps {
  PersonImage?: string | undefined;
  PersonaName: string;
  secondaryText: string;
  size?: PersonaSize;
}

export const HeaderPersonaComponent = (props: IProps) => {
  const currentPersona: IPersonaSharedProps = {
    imageUrl: props.PersonImage,
    imageInitials: CommonFunction.getNameInitials(props.PersonaName),
    text: props.PersonaName,
    secondaryText: props.secondaryText
  };

  return (
    <>
    {/* <Persona
      {...currentPersona}
      size={PersonaSize.size48}
      hidePersonaDetails={false}
      presence={PersonaPresence.none}
      imageAlt={props.PersonaName}
    /> */}
    <Persona
        size={props.size !== undefined ? props.size! as PersonaSize : "huge"}
        secondaryText={currentPersona.secondaryText}
        name={currentPersona.text}
        avatar={{ color: "colorful" }}
      />
    </>
  );
};
