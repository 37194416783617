import { connect } from "react-redux"

// component functions
import * as OwnFunctions from './ContractAccess.Form.Functions'
import { Button, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, MessageBar, MessageBarBody, MessageBarTitle, OverlayDrawer, Spinner } from "@fluentui/react-components"
import { Dismiss24Regular } from "@fluentui/react-icons"
import { MessageComponent } from "../../../../commons/component/MessageComponent"
import { MessageBarType, Separator, Toggle } from "@fluentui/react"
import { useEffect, useState } from "react"
import { NewContractAccess } from "../../../../models/Entities/Contracts/NewContractAccess"
import { ContractAccess } from "../../../../models/Entities/Contracts/ContractAccess"
import UserSelectorComponent from "../../../../commons/component/Inputs/UserSelector/UserSelector.Component"
import { User } from "../../../../models/Entities/Authentication/User"
import { use } from "i18next"

interface FormValidation {
    success: boolean;
    errors: string[];
}

const ContractAccessFormComponent = (props: OwnFunctions.Props) => {

    // form status
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    const [userAlreadySelected, setUserAlreadySelected] = useState<boolean>(false);

    // form data
    const [UserId, setUserId] = useState<string>();
    const [ReadAccess, setReadAccess] = useState<boolean>(false);

    const onSelectUser = (users: User[]) => {
        if (users.length > 0) {
            const user = users[0];
            const userAlreadySelected = props.SelectedUsersIds?.find(a => a === user.userId);
            setUserAlreadySelected(userAlreadySelected ? true : false);
        } else {
            setUserAlreadySelected(false);
        }
        setUserId(users[0]?.userId);
    }

    useEffect(() => {
        if (props.error) {
            setSubmittedForm(false);
        }
    }, [
        props.error
    ]);

    useEffect(() => {
        if (props.isSavingSuccessfully) {
            ResetForm();
            props.dismissPanel();
        }
    }, [
        props.isSavingSuccessfully
    ]);

    // form validation

    const ValidateForm = (): boolean => {

        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        if (!UserId) {
            FormValidation.errors.push("Debe seleccionar un usuario");
            validationResult = false;
        }

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };

    // reset form
    const ResetForm = () => {
        setSubmittedForm(false);
        setUserId(undefined);
        setReadAccess(false);
    };

    //events

    const HandleCancelForm = () => {
        ResetForm();
        props.dismissPanel();
    };
    const HandleSubmitForm = () => {

        setSubmittedForm(true);

        if (ValidateForm()) {

            if (!props.CurrentAccess) {
                const newAccess: NewContractAccess = {
                    contractID: props.ContractId,
                    userID: UserId || '',
                    readAccess: ReadAccess,
                    writeAccess: false
                };
                props.CreateContractAccess(newAccess)
            } else {
                const access: ContractAccess = {
                    id: props.CurrentAccess.id,
                    contractID: props.ContractId,
                    userID: UserId || '',
                    userName: '',
                    readAccess: ReadAccess,
                    writeAccess: false
                };
                props.UpdateContractAccess(access);
            }
        } else {
            setSubmittedForm(false);
        }
    };

    const Footer = () => {
        return (
            <DrawerFooter>
                <Button onClick={HandleCancelForm} disabled={submittedForm }>Cancelar</Button>
                <Button appearance="primary" onClick={HandleSubmitForm} disabled={submittedForm || userAlreadySelected}>{props.isSaving && <><Spinner size="extra-tiny" />&nbsp;</>}Guardar</Button>
            </DrawerFooter>
        );
    };

    return (
        <OverlayDrawer
            position={'end'}
            open={props.isOpen}
        >
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => HandleCancelForm()}
                        />
                    }
                >
                    Permisos de acceso al contrato
                </DrawerHeaderTitle>
                {FormValidationStatus?.success === false && <MessageComponent message={"Verifique la información suministrada"} type={MessageBarType.error} itemList={FormValidationStatus?.errors} />}
                {props.failOnSaving === true && <MessageComponent message={props.error?.ErrorMessage || 'Ocurrio un error inesperado'} type={MessageBarType.error} itemList={props.error?.Errors} />}
            </DrawerHeader>

            <DrawerBody className='ScrollShadow'>
                <UserSelectorComponent
                    onChangeSelection={onSelectUser}
                    UserSelected={undefined}
                    SelectedUsersIds={[]}
                    fieldName={"Seleccione un usuario"}
                    selectionLimit={1}
                />
                <Separator />
                {userAlreadySelected && <MessageBar intent={"warning"}>
                    <MessageBarBody>
                        <MessageBarTitle>Atención</MessageBarTitle>
                        El usuario seleccionado ya tiene accesos al contrato.{" "}
                    </MessageBarBody>
                </MessageBar>}
                <Toggle label="Puede ver el contrato" checked={ReadAccess} onText="Si" offText="No" onChange={(
                    (ev, checked) => setReadAccess(checked || false)
                )} />
                {/* <Toggle label="Enabled and checked" defaultChecked onText="On" offText="Off" onChange={() => undefined} /> */}
            </DrawerBody>
            <Footer />
        </OverlayDrawer>
    )
}

export default connect(
    OwnFunctions.mapStateToProps,
    OwnFunctions.mapDispatchToProps
)(ContractAccessFormComponent as any)