import { IIconProps } from "@fluentui/react";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";
import { ErrorType } from "../../../models/HttpError";

//services 
import * as ContractServices from '../../../services/Contract.Services';

// stores
import * as ContractStore from '../../../redux/middleware/ContractMiddleware';
import * as MedicalProtocolStore from '../../../redux/middleware/MedicalProtocolMiddleware';

//State
import { AppState } from "../../../redux/reducers";
import { Contract } from "../../../models/Entities/Contracts/Contract";
import { ContractProtocolAssociation } from "../../../models/Entities/Contracts/ContractProtocolAssociation";

//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };

interface IProps {
  ProtocolId: number;
 }

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Protocol: Protocol;
  Protocols: Protocol[];
  ContractAssociated: ContractProtocolAssociation | undefined;
  Contract: Contract | undefined;
}

interface MedicalServicesDispatchProps {
  GetAllProtocolContractAssociations: typeof MedicalProtocolStore.actionCreators.GetAllProtocolContractAssociations;
  AssociateContractToProtocol: typeof ContractStore.actionCreators.AssociateContractToProtocol;
  DisassociateContractFromProtocol: typeof ContractStore.actionCreators.DisassociateContractFromProtocol;
  CheckProtocolContractAssociation: typeof ContractServices.CheckProtocolContractAssociation;
  GetContractById: typeof ContractStore.actionCreators.GetContractById;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.MedicalProtocols?.isLoadingAll,
  isUpdating: state.MedicalProtocols?.isLoadingAll,
  isUpdatedSuccessfully: state.MedicalProtocols?.successLoadingAll,
  isLoadingSuccessfully: state.MedicalProtocols?.successLoadingAll,
  failOnUpdating: state.MedicalProtocols?.failOnLoadingAll,
  failOnLoading: state.MedicalProtocols?.failOnLoadingAll,
  isDeleting: state.MedicalProtocols?.isDeletingOne,
  isDeletedSuccessfully: state.MedicalProtocols?.successDeletingOne,
  failOnDeleting: state.MedicalProtocols?.failOnDeletingOne,
  error: state.MedicalProtocols?.error,
  Protocols: state.MedicalProtocols?.list,
  Protocol: state.MedicalProtocols?.list?.find((item: Protocol) => item.id === ownProps.ProtocolId),
  ContractAssociated: state.MedicalProtocols?.list?.find((item: Protocol) => item.id === ownProps.ProtocolId)?.contractAssociation,
  Contract: state.Contract?.list?.find((item: Contract) => item.id === state.MedicalProtocols?.list.find((item: Protocol) => item.id === ownProps.ProtocolId)?.contractAssociation?.contractId)
});

export const mapDispatchToProps = {
  ...ContractStore.actionCreators,
  ...MedicalProtocolStore.actionCreators
};

// contracts functions 
export const GetContractStatusDescripcion = (status: number | undefined) => {
  switch (status) {
      case undefined:
          return "No definido";
      case 1:
          return "Activo";
      case 2:
          return "Inactivo";
      case 3:
          return "suspendido";
      case 4:
          return "Expirado";
      case 5:
          return "Cancelado";
      case 6:	
          return "Terminado";
      default:
      return "No definido";
  }
};

