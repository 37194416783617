import { IIconProps } from '@fluentui/react';
import { TenantPriceList } from '../../../../models/Entities/TenantPriceLists/TenantPriceList';
import { ErrorType } from '../../../../models/HttpError';
import { AppState } from '../../../../redux/reducers';

//store
import * as TenantPriceListStore from '../../../../redux/middleware/TenantPriceListsMiddleware';

// Icons
export const DetailsIcon: IIconProps = { iconName: 'StackIndicator' };
export const InfoIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const UnlockIcon: IIconProps = { iconName: 'UnlockSolid' };
export const LockIcon: IIconProps = { iconName: 'LockSolid' };
export const EditIcon: IIconProps = { iconName: 'Edit' };
export const DeleteIcon: IIconProps = { iconName: 'Delete' };

interface OwnProps {}
interface StateProps {
  isGettingAll?: boolean;
  gettingAllSuccess?: boolean;
  failOnGettingAll?: boolean;
  isDeleting?: boolean;
  deletingSuccess?: boolean;
  failOnDeleting?: boolean;
  addingSuccessfully?: boolean;
  savingSuccessfully?: boolean;
  error?: ErrorType;
  TenantPriceLists?: TenantPriceList[];
}

interface DispatchProps {
  GetAllTenantPriceList: typeof TenantPriceListStore.ActionCreators.GetAllTenantPriceList;
  DeleteTenantPriceList: typeof TenantPriceListStore.ActionCreators.DeleteTenantPriceList;
}

interface CustomEvent {}

export type Props = CustomEvent & StateProps & DispatchProps & OwnProps;

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    isGettingAll: state.TenantPriceLists?.isLoadingAll,
    gettingAllSuccess: state.TenantPriceLists?.successLoadingAll,
    failOnGettingAll: state.TenantPriceLists?.failOnLoadingAll,
    isDeleting: state.TenantPriceLists?.isDeletingOne,
    deletingSuccess: state.TenantPriceLists?.successDeletingOne,
    failOnDeleting: state.TenantPriceLists?.failOnDeletingOne,
    error: state.TenantPriceLists?.error,
    addingSuccessfully: state.TenantPriceLists?.successAddingNewOne,
    savingSuccessfully: state.TenantPriceLists?.successUpdatingOne,
    TenantPriceLists: state.TenantPriceLists?.list
  };
};

export const mapDispatchToProps = {
  ...TenantPriceListStore.ActionCreators
};

export type Validity = {
  status: string;
  message: string;
  endValidity: Date | undefined;
};

export function getStatus(tenantPriceList: TenantPriceList): Validity {
  const today = new Date();
  const endValidity: Date | undefined = tenantPriceList.effectiveDateEnd
    ? new Date(tenantPriceList.effectiveDateEnd)
    : undefined;
  const startValidity: Date | undefined = tenantPriceList.effectiveDateStart
    ? new Date(tenantPriceList.effectiveDateStart)
    : undefined;
  if (!endValidity && !startValidity) {
    return {
      status: 'NoValidity',
      message: 'No Validity',
      endValidity: undefined
    };
  }
  if (endValidity && today > endValidity) {
    return {
      status: 'Expired',
      message: 'Expired',
      endValidity: endValidity
    };
  } else if (startValidity && today < startValidity) {
    return {
      status: 'Pending',
      message: 'Pending',
      endValidity: endValidity
    };
  } else {
    return {
      status: 'Active',
      message: 'Active',
      endValidity: endValidity
    };
  }
}

// auxiliary functions
export function getTenantPriceListTypeDescription(
  PriceListType: string
): string {
  switch (PriceListType) {
    case 'P':
      return 'Pagador';
    case 'C':
      return 'Cobrador';
    default:
      return 'No definido';
  }
}
