import { AppState } from '../../../redux/reducers';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { ErrorType } from '../../../models/HttpError';


//stores 
import * as ContractsStore from '../../../redux/middleware/ContractMiddleware'
import * as ProtocolsStore from '../../../redux/middleware/MedicalProtocolMiddleware'
import * as PractitionersStore from '../../../redux/middleware/PractitionerMiddleware'
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';

//#region Props

interface IProps {
  ContractId: number;
}

interface ConnectedProps {
  isLoading: boolean;
  isLoadingSuccessfully: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Contract: Contract | undefined;
  Practitioner: Practitioner | undefined;
  Protocol: Protocol | undefined;
}

interface MedicalServicesDispatchProps {
  GetAllContracts: typeof ContractsStore.actionCreators.GetAllContracts;
  GetContractById: typeof ContractsStore.actionCreators.GetContractById;
  GetAllPractitioners: typeof PractitionersStore.actionCreators.GetAllPractitioners;
  GetAllMedicalProtocols: typeof ProtocolsStore.actionCreators.GetAllMedicalProtocols;
  DisassociateContractFromPractitioner: typeof ContractsStore.actionCreators.DisassociateContractFromPractitioner;
  DisassociateContractFromProtocol: typeof ContractsStore.actionCreators.DisassociateContractFromProtocol;
  AssociateContractToPractitioner: typeof ContractsStore.actionCreators.AssociateContractToPractitioner;
  AssociateContractToProtocol: typeof ContractsStore.actionCreators.AssociateContractToProtocol;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const ContractProtocolAssociated : number | undefined = state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId)?.protocol[0]?.protocolId;
    const ContractPractitionerAssociated : string | undefined = state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId)?.practitioners[0]?.practitionerId;

    return {
        isLoading: state.Contract?.isLoadingAll,
        isLoadingSuccessfully: state.Contract?.successLoadingAll,
        failOnLoading: state.Contract?.failOnLoadingAll,
        failOnDeleting: state.Contract?.failOnDeletingOne,
        error: state.Contract?.error,
        Contract: state.Contract?.list?.find(c => c.id === ownProps.ContractId),
        Practitioner: ContractPractitionerAssociated ? state.Practitioner?.list?.find((practitioner: Practitioner) => practitioner.userId === ContractPractitionerAssociated) : undefined,
        Protocol: ContractProtocolAssociated ? state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ContractProtocolAssociated) : undefined
    }
};

export const mapDispatchToProps = {
    ...ContractsStore.actionCreators,
    ...ProtocolsStore.actionCreators,
    ...PractitionersStore.actionCreators
};

//#endregion Props