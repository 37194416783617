import { Action, Reducer } from 'redux';
import {
  initialState,
  PractitionerSettingsState
} from '../states/PractitionerSettingsState';

// Functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.PractitionerSettings.PractitionerSettings_Update_Request_Action
  | Actions.PractitionerSettings.PractitionerSettings_Update_Receive_Action
  | Actions.PractitionerSettings.PractitionerSettings_Update_Fail_Action
  | Actions.PractitionerSettings.PractitionerSettings_Request_Action
  | Actions.PractitionerSettings.PractitionerSettings_Receive_Action
  | Actions.PractitionerSettings.PractitionerSettings_Fail_Action
  | Actions.PractitionerSettings.PractitionerSettings_Add_Request_Action
  | Actions.PractitionerSettings.PractitionerSettings_Add_Receive_Action
  | Actions.PractitionerSettings.PractitionerSettings_Add_Fail_Action;

export const PractitionerSettingsReducer: Reducer<PractitionerSettingsState> = (
  state: PractitionerSettingsState | undefined,
  incomingAction: Action
): PractitionerSettingsState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'PRACTITIONER_SETTINGS_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isSaving: true,
        savedSuccessfully: undefined,
        failOnSave: false,
        error: undefined
      };
    case 'PRACTITIONER_SETTINGS_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isSaving: false,
        savedSuccessfully: true,
        failOnSave: false,
        error: undefined
      };
    case 'PRACTITIONER_SETTINGS_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isSaving: false,
        savedSuccessfully: false,
        failOnSave: true,
        error: action.error
      };
    case 'PRACTITIONER_SETTINGS_REQUEST_ACTION':
      return {
        ...state,
        isLoading: true,
        loadedSuccessfully: undefined,
        failOnLoad: false,
        error: undefined
      };
    case 'PRACTITIONER_SETTINGS_RECEIVE_ACTION':
      return {
        ...state,
        isLoading: false,
        loadedSuccessfully: true,
        failOnLoad: false,
        error: undefined
      };
    case 'PRACTITIONER_SETTINGS_FAIL_ACTION':
      return {
        ...state,
        isLoading: false,
        loadedSuccessfully: false,
        failOnLoad: true,
        error: action.error
      };
    case 'PRACTITIONER_SETTINGS_ADD_REQUEST_ACTION':
      return {
        ...state,
        isSaving: true,
        savedSuccessfully: undefined,
        failOnSave: false,
        error: undefined
      };
    case 'PRACTITIONER_SETTINGS_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isSaving: false,
        savedSuccessfully: true,
        failOnSave: false,
        error: undefined
      };
    case 'PRACTITIONER_SETTINGS_ADD_FAIL_ACTION':
      return {
        ...state,
        isSaving: false,
        savedSuccessfully: false,
        failOnSave: true,
        error: action.error
      };
    default:
      return state;
  }
};
