import { UserManager, WebStorageStateStore, Log, User } from 'oidc-client';
import { ApplicationName } from './ApiAuthorizationConstants';

export class AuthorizeService {
  _callbacks: { callback: any; subscription: number }[] = [];
  _nextSubscriptionId = 0;
  _user: User | null = null;
  _isAuthenticated = false;
  userManager: UserManager | undefined = undefined;

  constructor() {
    Log.logger = console;
    Log.level = Log.ERROR;
    this.ensureUserManagerInitialized();
  }

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  isAuthenticated = async () => {
    const user = await this.getUser();
    return !!user;
  };

  getUser = async () => {
    if (this._user && this._user.profile) {
      return this._user.profile;
    }

    await this.ensureUserManagerInitialized();
    const user = await this.userManager?.getUser();
    return user && user.profile;
  };

  async getAccessToken() {
    if (this._user && this._user.profile) {
      return this._user.access_token;
    }
    await this.ensureUserManagerInitialized();
    var user = await this.userManager?.getUser();
    console.log(user);
    console.log('User Token: ' + user?.access_token);
    return user && user.access_token;
  }

  signIn = async (state: any) => {
    await this.ensureUserManagerInitialized();
    try {
      var Arguments = this.createArguments(state);

      const silentUser = await this.userManager?.signinSilent(
        this.createArguments(state)
      );

      this.updateState(silentUser || null);
      return this.success(state);
    } catch (silentError) {
      try {
        await this.userManager?.signinRedirect(this.createArguments(state));
        return this.redirect();
      } catch (redirectError) {
        return this.error(redirectError as string);
      }
    }
  };

  async completeSignIn(url: string) {
    try {
      await this.ensureUserManagerInitialized();
      const user: User | undefined = await this.userManager?.signinCallback(
        url
      );
      this.updateState(user || null);

      if (user) return this.success(user && user.state);
    } catch (error) {
      return this.error('');
    }
  }

  async signOut(state: any) {
    await this.ensureUserManagerInitialized();
    try {
      if (this._popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
        );
      }

      await this.userManager?.signoutPopup(this.createArguments(state));
      this.updateState(null);
      return this.success(state);
    } catch (popupSignOutError) {
      try {
        await this.userManager?.signoutRedirect(this.createArguments(state));
        return this.redirect();
      } catch (redirectSignOutError) {
        return this.error(redirectSignOutError as string);
      }
    }
  }

  async completeSignOut(url: string) {
    await this.ensureUserManagerInitialized();
    try {
      const response = await this.userManager?.signoutCallback(url);
      this.updateState(null);
      return this.success(response);
    } catch (error) {
      return this.error(error as string);
    }
  }

  updateState(user: User | null) {
    this._user = user;
    this._isAuthenticated = !!this._user;
    this.notifySubscribers();
  }

  subscribe(callback: any) {
    this._callbacks.push({
      callback,
      subscription: this._nextSubscriptionId++
    });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId: any) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false }
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      // throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
      console.log(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`
      );
      return;
    }

    this._callbacks = this._callbacks.splice(subscriptionIndex[0].index!, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  createArguments(state: any) {
    return { useReplaceToNavigate: true, data: state };
  }

  error(message: string) {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  success(state: any) {
    return { status: AuthenticationResultStatus.Success, state };
  }

  redirect() {
    return { status: AuthenticationResultStatus.Redirect };
  }

  redirectToProfile(user: User) {
    return { status: AuthenticationResultStatus.RedirectToProfile, user };
  }

  ensureUserManagerInitialized = async () => {
    if (this.userManager !== undefined) {
      return;
    }

    let settings = {
      client_id: process.env.REACT_APP_APP_NAME,
      authority: process.env.REACT_APP_AUTHENTICATION_AUTHORITY,
      automaticSilentRenew: true,
      includeIdTokenInSilentRenew: true,
      userStore: new WebStorageStateStore({
        prefix: ApplicationName
      }),
      clockSkew: 900,
      redirect_uri: `${process.env.REACT_APP_AUTHENTICATION_CALLBACK}/authentication/login-callback`,
      post_logout_redirect_uri: `${process.env.REACT_APP_AUTHENTICATION_CALLBACK}/authentication/logout-callback`,
      response_type: 'code',
      scope:
        'openid profile IdentityServerApi protocolsapi databaseapi agendaapi notificationsapi medicalrecordapi digitalsignapi pre_biller_app'
    };

    this.userManager = new UserManager(settings);

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager?.removeUser();
      this.updateState(null);
    });
  };

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail',
  RedirectToProfile: 'redirectToProfile'
};
