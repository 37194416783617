import { IIconProps, ILabelStyles, IStyleSet } from '@fluentui/react';
import { ErrorType } from '../../models/HttpError';
import { AppState } from '../../redux/reducers';
import { MedicalInsuranceAgreement } from '../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';
import { MedicalInsurance } from '../../models/Entities/MedicalInsurances/MedicalInsurance';
import { MedicalInsuranceAgreementsPriceList } from '../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreementsPriceList';

// Store
import * as MedicalInsuranceAgreementsStore from '../../redux/middleware/MedicalInsuranceAgreementsMiddleware';
import * as MedicalInsuranceStore from '../../redux/middleware/MedicalInsuranceMiddleware';

//#region Props

export interface ConnectedProps {
  AgreementId: number;
  MedicalInsuranceId: number;
  Agreement: MedicalInsuranceAgreement;
  PriceLists: MedicalInsuranceAgreementsPriceList[];
  MedicalInsurance: MedicalInsurance;
  isAgreementLoading: boolean;
  AgreementLoadedSuccessfully: boolean;
  failOnLoadingAgreement: boolean;
  AgreementOperationError: ErrorType;
  isMedialInsuranceLoading: boolean;
  MedicalInsuranceLoadedSuccessfully: boolean;
  failOnLoadingMedicalInsurance: boolean;
  MedicalInsuranceOperationError: ErrorType;
  isDeletingPriceList: boolean;
  PriceListDeleted: boolean;
  failOnDeletePriceList: boolean;
  DeletePriceListError: ErrorType | undefined;
  isAddingPriceList: boolean;
}

interface DispatchProps {
  GetAgreementById: typeof MedicalInsuranceAgreementsStore.actionCreators.GetAgreementById;
  AddPriceList: typeof MedicalInsuranceAgreementsStore.actionCreators.AddPriceList;
  GetMedicalInsuranceById: typeof MedicalInsuranceStore.actionCreators.GetMedicalInsuranceById;
  DeletePriceListById: typeof MedicalInsuranceAgreementsStore.actionCreators.DeletePriceListById;
  ResetPriceListDeleteState: typeof MedicalInsuranceAgreementsStore.actionCreators.ResetPriceListDeleteState;
  ClonePriceList: typeof MedicalInsuranceAgreementsStore.actionCreators.ClonePriceList;
}

export type Props = ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: any) => {
  return {
    AgreementId: ownProps.params.AgreementId,
    MedicalInsuranceId: ownProps.params.MedicalInsuranceId,

    Agreement: state.MedicalInsuranceAgreements?.list.find(
      (agreement: MedicalInsuranceAgreement) =>
        agreement.id === Number(ownProps.params.AgreementId)
    ),

    MedicalInsurance: state.MedicalInsurances?.list.find(
      (medialInsurance: MedicalInsurance) =>
        medialInsurance.id === Number(ownProps.params.MedicalInsuranceId)
    ),

    PriceLists: state.MedicalInsuranceAgreements?.list.find(
      (agreement: MedicalInsuranceAgreement) =>
        agreement.id === Number(ownProps.params.AgreementId)
    )?.priceLists,

    //states
    isAgreementLoading: state.MedicalInsuranceAgreements?.isLoadingOne,
    AgreementLoadedSuccessfully: state.MedicalInsuranceAgreements?.isLoadingOne,
    failOnLoadingAgreement: state.MedicalInsuranceAgreements?.failOnLoadingOne,
    AgreementOperationError: state.MedicalInsuranceAgreements?.error,
    isMedialInsuranceLoading: state.MedicalInsurances?.isLoadingOne,
    MedicalInsuranceLoadedSuccessfully:
      state.MedicalInsurances?.successLoadingOne,
    failOnLoadingMedicalInsurance: state.MedicalInsurances?.failOnLoadingOne,
    MedicalInsuranceOperationError: state.MedicalInsurances?.error,
    isDeletingPriceList: state.MedicalInsuranceAgreements?.isDeletingPriceList,
    PriceListDeleted:
      state.MedicalInsuranceAgreements?.deletingPriceListSuccess,
    failOnDeletePriceList:
      state.MedicalInsuranceAgreements?.failOnDeletingPriceList,
    DeletePriceListError: state.MedicalInsuranceAgreements?.error,
    isAddingPriceList: state.MedicalInsuranceAgreements?.isAddingPriceList
  };
};

export const mapDispatchToProps = {
  ...MedicalInsuranceAgreementsStore.actionCreators,
  ...MedicalInsuranceStore.actionCreators
};

//#endregion

//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const CreatingIcon: IIconProps = { iconName: 'Clock' };
//#endregion

// styles
export const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
  root: { marginTop: 10 }
};
