import { MessageBar, MessageBarType, Text } from '@fluentui/react';
import { ErrorType } from '../../models/HttpError';

export interface IProps {
  Error: ErrorType | undefined;
}

export const ErrorMessageComponent = (props: IProps) => {
  return props.Error ? (
    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
      <Text>{props.Error.ErrorMessage}</Text>
      {props.Error.Errors && props.Error.Errors.length > 0 && (
        <ul>
          {props.Error.Errors.map((error: string) => (
            <li>{error}</li>
          ))}
        </ul>
      )}
    </MessageBar>
  ) : (
    <></>
  );
};
