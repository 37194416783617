import Moment from 'react-moment';
import { MedicalInsuranceAgreementsPriceList } from '../../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreementsPriceList';
import {
  DatePicker,
  DayOfWeek,
  DefaultButton,
  defaultDatePickerStrings,
  IconButton,
  IIconProps,
  MessageBar,
  MessageBarType,
  Separator,
  Text,
  Label
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { AppState } from '../../../redux/reducers';
import { connect, useDispatch, useSelector } from 'react-redux';
import { MedicalInsuranceAgreement } from '../../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './PriceListInfoComponent.css';

// Store
import * as MedicalInsuranceAgreementPriceListStore from '../../../redux/middleware/MedicalInsuranceAgreementsMiddleware';

// functions
import * as ownFunctions from '../AgreementPricelist/AgreementPriceListFunctions';

export interface IProps {
  AgreementPriceList: MedicalInsuranceAgreementsPriceList;
}

export const PriceListInfoComponent = (props: IProps) => {
  const [Today, setToday] = useState<Date>(new Date());

  const [activeDateFrom, setActiveDateFrom] = useState<boolean>(false);
  const [activeDateTo, setActiveDateTo] = useState<boolean>(false);

  const [validityDateStart, setValidityDateStart] = useState<Date>();
  const [validityDateEnd, setValidityDateEnd] = useState<Date>();

  const [validityErrors, setValidityErrors] = useState<string[]>([]);

  //useTranslation();
  const [t] = useTranslation();

  //PriceListState
  const PriceListState: MedicalInsuranceAgreementsPriceList | undefined =
    useSelector((state: AppState) =>
      state.MedicalInsuranceAgreements?.list
        .find(
          (agreement: MedicalInsuranceAgreement) =>
            agreement.id === props.AgreementPriceList.agreementId
        )
        ?.priceLists.find(
          (priceList: MedicalInsuranceAgreementsPriceList) =>
            priceList.id === props.AgreementPriceList.id
        )
    );

  const editIcon: IIconProps = { iconName: 'Edit' };
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const okIcon: IIconProps = { iconName: 'CheckMark' };

  const dispatch = useDispatch();

  const [firstDayOfWeek, setFirstDayOfWeek] = useState(DayOfWeek.Sunday);

  // Events
  const onChangeValidityDateStartFieldValue = (
    date: Date | null | undefined
  ) => {
    setValidityDateStart(date || new Date());
  };

  const onChangeValidityDateEndFieldValue = (date: Date | null | undefined) => {
    setValidityDateEnd(date || new Date());
  };

  // confirm date changes
  const onConfirmValidityStartClickHandler = () => {
    if (validityDateStart && ValidateValidity()) {
      dispatch(
        mapDispatchToProps.SetPriceListStartValidity(
          props.AgreementPriceList!,
          validityDateStart
        )
      );
      setActiveDateFrom(!activeDateFrom);
    }
  };

  const onConfirmValidityEndClickHandler = () => {
    if (validityDateEnd && ValidateValidity()) {
      dispatch(
        mapDispatchToProps.SetPriceListEndValidity(
          props.AgreementPriceList as MedicalInsuranceAgreementsPriceList,
          validityDateEnd
        )
      );
      setActiveDateTo(!activeDateTo);
    }
  };

  useEffect(() => {
    if (props.AgreementPriceList) {
      setValidityDateStart(
        props.AgreementPriceList.effectiveDateStart
          ? new Date(props.AgreementPriceList.effectiveDateStart)
          : undefined
      );
      setValidityDateEnd(
        props.AgreementPriceList.effectiveDateEnd
          ? new Date(props.AgreementPriceList.effectiveDateEnd)
          : undefined
      );
    }
  }, [props.AgreementPriceList]);

  const ValidateValidity = (): boolean => {
    var ErrorList: string[] = [];
    var ResultValidation: boolean = true;

    if (
      validityDateStart &&
      validityDateEnd &&
      validityDateStart >= validityDateEnd
    ) {
      ErrorList.push(
        t('priceListInfo.validityError.dateError')
      );
      ResultValidation = false;
    }

    setValidityErrors(ErrorList);

    return ResultValidation;
  };

  return props.AgreementPriceList ? (
    <>
      {validityErrors.length > 0 && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          <Text>{t('priceListInfo.errorAttention')} Atención:</Text>
          <ul>
            {validityErrors.map((error: string) => (
              <li>{error}</li>
            ))}
          </ul>
        </MessageBar>
      )}
      <Text>
        <small>{t('priceListInfo.validSince')}</small>
      </Text>
      <div>
        {activeDateFrom && (
          <div className="fieldCommands">
            <DatePicker
              firstDayOfWeek={firstDayOfWeek}
              placeholder={t('priceListInfo.parameters.validityStart')} 
              ariaLabel="Inicio de vigencia"
              formatDate={onFormatDate}
              onSelectDate={onChangeValidityDateStartFieldValue}
              strings={defaultDatePickerStrings}
              value={validityDateStart}
            />
            <IconButton
              onClick={onConfirmValidityStartClickHandler}
              iconProps={okIcon}
              title={t('priceListInfo.parameters.confirmButton')} 
              ariaLabel="Confirmar"
            />
            <IconButton
              onClick={() => {
                setActiveDateFrom(!activeDateFrom);
                setValidityErrors([]);
              }}
              iconProps={cancelIcon}
              title={t('priceListInfo.parameters.cancelButton')} 
              ariaLabel="Cancelar"
            />
          </div>
        )}
        {!activeDateFrom &&
          (PriceListState && PriceListState.effectiveDateStart ? (
            <div className="fieldCommands">
              <label>
                <Moment format="DD/MM/YYYY">
                  {PriceListState.effectiveDateStart}
                </Moment>
              </label>
              <IconButton
                onClick={() => setActiveDateFrom(!activeDateFrom)}
                iconProps={editIcon}
                title={t('priceListInfo.parameters.editButton')}
                ariaLabel="edit"
              />
            </div>
          ) : (
            <div className="fieldCommands">
              <label>{t('priceListInfo.parameters.notInformed')}</label>
              <IconButton
                onClick={() => setActiveDateFrom(!activeDateFrom)}
                iconProps={editIcon}
                title={t('priceListInfo.parameters.editButton')}
                ariaLabel="edit"
              />
            </div>
          ))}
      </div>
      <Text>
        <small>{t('priceListInfo.validUntil')}</small>
      </Text>
      <div>
        {activeDateTo && (
          <div className="fieldCommands">
            <DatePicker
              firstDayOfWeek={firstDayOfWeek}
              placeholder={t('priceListInfo.parameters.endOfValidity')} 
              ariaLabel="Finalización de vigencia"
              formatDate={onFormatDate}
              onSelectDate={onChangeValidityDateEndFieldValue}
              strings={defaultDatePickerStrings}
              value={validityDateEnd}
            />
            <IconButton
              onClick={onConfirmValidityEndClickHandler}
              iconProps={okIcon}
              title={t('priceListInfo.parameters.confirmButton')} 
              ariaLabel="Confirmar"
            />
            <IconButton
              onClick={() => {
                setActiveDateTo(!activeDateTo);
                setValidityErrors([]);
              }}
              iconProps={cancelIcon}
              title={t('priceListInfo.parameters.cancelButton')}
              ariaLabel="Cancelar"
            />
          </div>
        )}
        {!activeDateTo &&
          (PriceListState && PriceListState.effectiveDateEnd ? (
            <div className="fieldCommands">
              <label>
                <Moment format="DD/MM/YYYY">
                  {PriceListState.effectiveDateEnd}
                </Moment>
              </label>
              <IconButton
                onClick={() => setActiveDateTo(!activeDateTo)}
                iconProps={editIcon}
                title={t('priceListInfo.parameters.editButton')}
                ariaLabel="edit"
              />
            </div>
          ) : (
            <div className="fieldCommands">
              <label>{t('priceListInfo.parameters.notInformed')}</label>
              <IconButton
                onClick={() => setActiveDateTo(!activeDateTo)}
                iconProps={editIcon}
                title={t('priceListInfo.parameters.editButton')}
                ariaLabel="edit"
              />
            </div>
          ))}
      </div>
      <Separator />
      <Text>
        <small>{t('priceListInfo.state')}</small>
      </Text>
      <div>
        {PriceListState && (
          <Label
            className={
              'priceListStatus pricelist ' +
              ownFunctions.GetPriceListStatus(PriceListState).status
            }
          >
            {ownFunctions.GetPriceListStatus(PriceListState).message}
          </Label>
        )}
      </div>
      <Separator />
      <Text>
        <small>{t('priceListInfo.expiration')}</small>
      </Text>
      <div>
        {PriceListState && PriceListState.effectiveDateEnd ? (
          <label>
            <Moment to={PriceListState.effectiveDateEnd} unit="days">
              {Today}
            </Moment>
          </label>
        ) : (
          <label>{t('priceListInfo.undetermined')}</label>
        )}
      </div>
    </>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalInsuranceAgreements
});

const mapDispatchToProps = {
  ...MedicalInsuranceAgreementPriceListStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceListInfoComponent as any);

const onFormatDate = (date?: Date): string => {
  return !date
    ? ''
    : date.getDate().toString().padStart(2, '0') +
        '/' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getFullYear();
};
