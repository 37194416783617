import { ProviderAssociationsMedicalService } from './../../models/Entities/ProviderAssociations/MedicalServices/ProviderAssociationMedicalService';
import { Reducer, Action } from 'redux';
import {
  ProviderMedicalServiceState,
  ProviderMedicalServiceUnloadState
} from '../states/ProviderMedicalServiceState';

// Functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_RequestAll_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_ReceiveAll_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_FailAll_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_RequestById_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_ReceiveById_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_FailById_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Add_Request_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Add_Receive_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Add_Fail_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Delete_Request_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Delete_By_Id_Receive_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Delete_By_Medical_Service_Id_Receive_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Delete_Fail_Action;

export const ProviderMedicalServiceAssociationReducer: Reducer<
  ProviderMedicalServiceState
> = (
  state: ProviderMedicalServiceState | undefined,
  incomingAction: Action
): ProviderMedicalServiceState => {
  if (state === undefined) {
    return ProviderMedicalServiceUnloadState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'PROVIDER_MEDICAL_SERVICE_REQUEST_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'PROVIDER_MEDICAL_SERVICE_RECEIVE_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined,
        list: action.ProviderMedicalServices
      };
    case 'PROVIDER_MEDICAL_SERVICE_FAIL_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'PROVIDER_MEDICAL_SERVICE_REQUEST_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'PROVIDER_MEDICAL_SERVICE_RECEIVE_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (medicalService: ProviderAssociationsMedicalService) =>
              medicalService.id !== action.ProviderMedicalService.id
          ),
          action.ProviderMedicalService
        ]
      };
    case 'PROVIDER_MEDICAL_SERVICE_FAIL_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'PROVIDER_MEDICAL_SERVICE_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'PROVIDER_MEDICAL_SERVICE_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [...state.list, action.ProviderMedicalService]
      };
    case 'PROVIDER_MEDICAL_SERVICE_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'PROVIDER_MEDICAL_SERVICE_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'PROVIDER_MEDICAL_SERVICE_DELETE_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: state.list.filter(
          (medicalService: ProviderAssociationsMedicalService) =>
            medicalService.id !== action.ProviderMedicalServiceId
        )
      };
    case 'PROVIDER_MEDICAL_SERVICE_DELETE_BY_MEDICAL_SERVICE_ID_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: state.list.filter(
          (medicalService: ProviderAssociationsMedicalService) =>
            medicalService.medicalServiceId !== action.MedicalServiceId
        )
      };
    case 'PROVIDER_MEDICAL_SERVICE_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
    default:
      return state;
  }
};
