import { SettlementDetail } from '../models/Entities/SettlementDetails/SettlementDetail';
import { NewSettlementDetail } from '../models/Entities/SettlementDetails/NewSettlementDetail';
import { IResult } from '../models/HttpResult';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';


const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'settlements/settlements/';

// Get All Settlements
export const GetDetailsBySettlementId = async (settlementId: number, token: string): Promise<SettlementDetail[]> => {

let RequestURL: string = BaseURL + settlementId + '/details';

try {
    const Response: SettlementDetail[] = await fetch(RequestURL, {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }
    })
    .then((response) => {
        if (!response.ok) {
        Sentry.captureException(response);
        throw response;
        } else {
        return response.json() as Promise<IResult<SettlementDetail[]>>;
        }
    })
    .then((data: IResult<SettlementDetail[]>) => {
        return data.data;
    })
    .catch((error: any) =>
        error.text().then((body: any) => {
        throw Functions.HttpErrorHandler(body, error);
        })
    );

    return Response;
} catch (error: any) {
    Sentry.captureException(error);
    throw error;
}
};

// Get contract By Id
export const GetSettlementDetailsById = async (id: number, token: string): Promise<SettlementDetail> => {

    let RequestURL: string = BaseURL + 'details/' + id;
    
    try {
        const Response: SettlementDetail = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<SettlementDetail>>;
            }
        })
        .then((data: IResult<SettlementDetail>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Create Contract
export const CreateSettlementDetail = async (newSettlementDetail: NewSettlementDetail, token: string): Promise<SettlementDetail> => {

    let RequestURL: string = BaseURL + 'details';
    
    try {
        const Response: SettlementDetail = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newSettlementDetail)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<SettlementDetail>>;
            }
        })
        .then((data: IResult<SettlementDetail>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Update Contract
export const UpdateSettlementDetail = async (settlementDetail: SettlementDetail, token: string): Promise<SettlementDetail> => {

    let RequestURL: string = BaseURL + 'details/';
    
    try {
        const Response: SettlementDetail = await fetch(RequestURL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(settlementDetail)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<SettlementDetail>>;
            }
        })
        .then((data: IResult<SettlementDetail>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// exclude settlement detail
export const ExcludeSettlementDetail = async (settlementDetailId: number, excludeStatus: boolean, token: string): Promise<SettlementDetail> => {

    let RequestURL: string = BaseURL + 'details/' + settlementDetailId + '/exclude/' + excludeStatus;
    
    try {
        const Response: SettlementDetail = await fetch(RequestURL, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<SettlementDetail>>;
            }
        })
        .then((data: IResult<SettlementDetail>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

export const MarkForReviewSettlementDetail = async (settlementDetailId: number, markForReviewStatus: boolean, comments: string, token: string): Promise<SettlementDetail> => {

    let RequestURL: string = BaseURL + 'details/' + settlementDetailId + '/marktoreview/' + markForReviewStatus;
    if(comments.length > 0) {
        RequestURL = RequestURL + '?comments=' + comments;
    }
    
    try {
        const Response: SettlementDetail = await fetch(RequestURL, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<SettlementDetail>>;
            }
        })
        .then((data: IResult<SettlementDetail>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

export const markOkSettlementDetail = async (settlementDetailId: number, markOkStatus: boolean, token: string): Promise<SettlementDetail> => {

    let RequestURL: string = BaseURL + 'details/' + settlementDetailId + '/markok/' + markOkStatus;
    
    try {
        const Response: SettlementDetail = await fetch(RequestURL, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<SettlementDetail>>;
            }
        })
        .then((data: IResult<SettlementDetail>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
}

// masive exclude settlement details by settlement resume id
export const MassExcludeSettlementDetails = async (settlementResumeId: number, excludeStatus: boolean, token: string): Promise<SettlementDetail[]> => {

    let RequestURL: string = BaseURL + 'details/exclude/' + excludeStatus + '?SettlementResumeId=' + settlementResumeId;
    
    try {
        const Response: SettlementDetail[] = await fetch(RequestURL, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<null>>;
            }
        })
        .then((data: IResult<null>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};
