import AuthorizeService from "../components/api-authorization/AuthorizeService";
import { Languaje } from "../models/Entities/Settings/Languajes";
import { jwtDecode } from "jwt-decode";

interface TokenInfo {
    language: string;
}


// REMOVE this when the API is ready
const auxLenguajes = new Array<Languaje>(
    {
        code: 'es',
        description: 'Español'
    },
    {
        code: 'en',
        description: 'English'
    },
    {
        code: 'pr',
        description: 'Português'
    }
) 

var LanguajesPromise = new Promise<Languaje[]>((resolve, reject) => {
    resolve(auxLenguajes);
});

function GetUserLanguaje (token: string) {
    var promise = new Promise<string> ((resolve, reject)  => {
        var decodedToken = jwtDecode<TokenInfo>(token);
        console.log(decodedToken);
        var currentUserLanguaje = decodedToken.language;
        console.log(currentUserLanguaje);
        return resolve(currentUserLanguaje);
    });
    return promise;
};


export const GetLanguajes = async (): Promise<Languaje[]> => {

    return LanguajesPromise.then((data: Languaje[]) => {
        return data;
    });

};

export const GetCurrentUserLanguaje = async () => {

    const token = await AuthorizeService.getAccessToken();
    if(token){
        return GetUserLanguaje(token).then((data: string) => {
            return GetLanguajes().then((languajes: Languaje[]) => {
                return languajes.find(x => x.code === data);
            }).catch((error: any) => {
                return undefined;
            })
        });
    }
}