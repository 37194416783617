

// models
import React from 'react';
import { PractitionerDetailsMini } from '../../../commons/component/Practitioner/PractitionerDetails/PractitionerDetails.Mini';
import { ContractCommissionAgent } from '../../../models/Entities/Contracts/ContractCommissionAgent';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';



export const CommissionAgentAssociatedItemComponent = (props: {commissionAgent: ContractCommissionAgent, practitioner: Practitioner | undefined }) => {

    return (
        <div>
            {props.practitioner && <PractitionerDetailsMini key={props.practitioner.userId} practitioner={props.practitioner} />}
        </div>
    )
}