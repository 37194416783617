import { IIconProps } from "@fluentui/react";
import { ErrorType } from "../../../models/HttpError";
import { SettlementResume } from "../../../models/Entities/SettlementResume/SettlementResume";
import { Settlement } from "../../../models/Entities/Settlements/Settlement";

// stores
import * as SettlementStore from '../../../redux/middleware/SettlementMiddleware';
import * as SettlementResumeStore from '../../../redux/middleware/SettlementResumeMiddleware';
import * as SettlementDetailsStore from '../../../redux/middleware/SettlementDetailsMiddleware';

//State
import { AppState } from "../../../redux/reducers";
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";



interface IProps {
    SettlementId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    isDeletedSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    failOnDeleting: boolean;
    error: ErrorType | undefined;
    Settlement: Settlement | undefined;
    SettlementResumes: SettlementResume[] | undefined;
    SettlementDetails: SettlementDetail[] | undefined;
  }
  
  interface DispatchProps {
    GetSettlementById: typeof SettlementStore.actionCreators.GetSettlementById;
    GetSettlementDetailById: typeof SettlementDetailsStore.actionCreators.GetSettlementDetailById;
    RefreshSettlementById: typeof SettlementStore.actionCreators.RefreshSettlementById;
    GetAllSettlementResume: typeof SettlementResumeStore.actionCreators.GetAllSettlementResume;
    DeleteSettlementResume: typeof SettlementResumeStore.actionCreators.DeleteSettlementResume;
    MassExclude: typeof SettlementDetailsStore.actionCreators.MassExclude;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.SettlementResume?.isLoadingAll,
    isUpdating: state.SettlementResume?.isUpdatingAll,
    isUpdatedSuccessfully: state.SettlementResume?.successLoadingAll,
    isLoadingSuccessfully: state.SettlementResume?.successLoadingAll,
    failOnUpdating: state.SettlementResume?.failOnLoadingAll,
    failOnLoading: state.SettlementResume?.failOnLoadingAll,
    isDeleting: state.SettlementResume?.isDeletingOne,
    isDeletedSuccessfully: state.SettlementResume?.successDeletingOne,
    failOnDeleting: state.SettlementResume?.failOnDeletingOne,
    error: state.SettlementResume?.error,
    Settlement: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId ) : undefined,
    SettlementResumes: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.resumes?.sort(
        (a: SettlementResume, b: SettlementResume) => {
            if (a.id && b.id) {
                if (a.id < b.id) {
                    return -1;
                }
                if (a.id < b.id) {
                    return 1;
                }
            }
            return 0;
        }
    
    ) : undefined,
    SettlementDetails: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((details: SettlementDetail) => details.settlementResumeId === null ) : undefined,
  });
  
  export const mapDispatchToProps = {
    ...SettlementStore.actionCreators,
    ...SettlementResumeStore.actionCreators,
    ...SettlementDetailsStore.actionCreators,
  };

  /*
    Contract Status
    _____________________
    Active = 1,
    Inactive = 2,
    Suspended = 3,
    Expired = 4,
    Canceled = 5,
    Terminated = 6
*/

  export const GetSettlementStatusDescripcion = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "En revisión";
        case 1:
            return "Activo";
        case 2:
            return "Inactivo";
        case 3:
            return "suspendido";
        case 4:
            return "Expirado";
        case 5:
            return "Cancelado";
        case 6:	
            return "Terminado";
        default:
        return "En revisión";
    }
};

  export const GetContractStatusClass = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "";
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "Suspended";
        case 4:
            return "Expired";
        case 5:
            return "Canceled";
        case 6:
            return "Terminated";
        default:
        return "";
  }
};


  //#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const EditIcon: IIconProps = { iconName: 'Edit' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };
export const DeleteIcon: IIconProps = { iconName: 'Trash' };
export const Exclude: IIconProps = { iconName: 'Share' };
export const Include: IIconProps = { iconName: 'Download' };
export const RefreshIcon: IIconProps = { iconName: 'SyncOccurence' };
export const PatientDetailsIcon: IIconProps = { iconName: 'UserOptional' };