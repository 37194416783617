import React from 'react';
import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  Dropdown,
  FontWeights,
  getTheme,
  Text,
  IDropdownOption,
  IIconProps,
  IStackProps,
  Label,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Separator,
  TextField,
  IChoiceGroupOption,
  Toggle,
  on,
  Spinner,
  SpinnerSize
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ErrorMessageComponent } from '../../../Error/ErrorMessageComponent';
import { ContractConcept } from '../../../../models/Entities/Contracts/ContractConcept';
import { NewContractConcept } from '../../../../models/Entities/Contracts/NewContractConcept';
import MedicalServiceSelectorComponent from '../../../../commons/component/Inputs/MedicalServiceSelector/MedicalServiceSelectorComponent';
import { ProviderSelectorComponent } from '../../../../commons/component/Inputs/ProviderSelector/ProviderSelectorComponent';
import { MessageComponent } from '../../../../commons/component/MessageComponent';
import { DateTimeInput } from '../../../../commons/component/Inputs/DateTimeInput/DateTimeInput';
import { ProtocolConceptMultiSelectorComponent } from '../../Protocols/ProtocolContractForm/ProtocolContractConcept/ProtocolConceptMultiSelector/ProtocolConceptMultiselectorComponent';
import { AvailableMedicalService } from '../../../../models/Entities/MedicalServices/AvailableMedicalService';


// component functions
import * as ComponentFunctions from './ConceptForm.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './ConceptForm.Styles.css';
import { ContractConceptPaymentSchedule } from '../../../../models/Entities/Contracts/ContractConceptPaymentSchedule';
import { NewContractConceptConcept } from '../../../../models/Entities/Contracts/NewContractConceptConcept';
import { NewContractConceptPaymentSchedule } from '../../../../models/Entities/Contracts/NewContractConceptPaymentSchedule';
import { ContractConceptConcept } from '../../../../models/Entities/Contracts/ContractConceptConcept';
import { IValueOriginOption } from './ConceptForm.Functions';
import { MdOutlineMarkChatRead } from 'react-icons/md';
import ContractStageMultiSelectorComponent from '../ContractStageMultiselector/ContractStageMultiSelector.Component';
import { processSilentRenew } from 'redux-oidc';



interface FormValidation {
  success: boolean;
  errors: string[];
}




export const ConceptFormComponent = (props: ComponentFunctions.Props) => {

  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();

  const [ContractConceptSaved, setContractConceptSaved] = useState<boolean>(false);

  //useTranslation()
  const [t] = useTranslation();

  // new fields
  //applyToRemoteExecution: boolean,
  //applyToInFaceExecution: boolean,
  //haveDivisionFee: boolean,
  //divisionFeeValue: number,

  const [AddedConceptId, setAddedConceptId] = useState<number>();

  // state variables
  const [conceptId, setConceptId] = useState<number>();
  const [conceptName, setConceptName] = useState<string>();
  const [conceptDescription, setConceptDescription] = useState<string>();
  const [isIncludeInVisit, setIsIncludeInVisit] = useState<boolean>(false);
  const [applyToRemoteExecution, setApplyToRemoteExecution] = useState<boolean>(false);
  const [applyToInFaceExecution, setApplyToInFaceExecution] = useState<boolean>(false);
  const [haveDivisionFee, setHaveDivisionFee] = useState<boolean>(false);
  const [divisionFeeValue, setDivisionFeeValue] = useState<number>(0);
  const [isMedicalService, setIsMedicalService] = useState<boolean>(false);
  const [medicalServiceId, setMedicalServiceId] = useState<number>();
  const [settlementSettingOptionSelected, setSettlementSettingOptionSelected] = useState<ComponentFunctions.ISettlementOption | undefined>();
  const [settlementSetting, setSettlementSetting] = useState<number>();
  const [settlementPeriod, setSettlementPeriod] = useState<number>();
  const [valuationTypeOptionSelected, setValuationTypeOptionSelected] = useState<ComponentFunctions.IValueOriginOption | undefined>();
  const [valuationType, setValuationType] = useState<number>();
  const [priceValue, setPriceValue] = useState<number>();
  const [haveRepetitionPrice, setHaveRepetitionPrice] = useState<boolean>(false);
  const [repetitionPriceValue, setRepetitionPriceValue] = useState<number>();
  const [haveEarlyDiscontinuationPrice, setHaveEarlyDiscontinuationPrice] = useState<boolean>(false);
  const [earlyDiscontinuationPrice, setEarlyDiscontinuationPrice] = useState<number>();
  const [percentageValue, setPercentageValue] = useState<number>();
  const [percetageBaseOptionSelected, setPercetageBaseOptionSelected] = useState<ComponentFunctions.IPercentageOriginOption | undefined>();
  const [percentageBase, setPercentageBase] = useState<number>();
  const [maxCostPrice, setMaxCostPrice] = useState<number>();
  const [priceListOrigin, setPriceListOrigin] = useState<number>();
  const [haveCost, setHaveCost] = useState<boolean>(false);
  const [costSetting, setCostSetting] = useState<number>();
  const [costSettingOptionSelected, setCostSettingOptionSelected] = useState<ComponentFunctions.ICostOptions | undefined>();
  const [percentageCostValue, setPercentageCostValue] = useState<number>();
  const [fixedCostValue, setFixedCostValue] = useState<number>();
  const [costPriceListOrigin, setCostPriceListOrigin] = useState<number>();
  const [costProviderId, setCostProviderId] = useState<number>();
  const [amountToIncludePerSettlement, setAmountToIncludePerSettlement] = useState<number>();
  const [excludeParticularPatient, setExcludeParticularPatient] = useState<boolean>(false);
  const [takeOnlyParticularPatient, setTakeOnlyParticularPatient] = useState<boolean>(false);
  const [calculateType, setCalculateType] = useState<number>();
  const [needAggrupation, setNeedAggrupation] = useState<boolean>(false);
  const [aggrupationSegment, setAggrupationSegment] = useState<number>();
  const [quantityToSettle, setQuantityToSettle] = useState<number>(1);
  const [maxQuantityToSettle, setMaxQuantityToSettle] = useState<number>(1);
  const [haveGenderFilter, setHaveGenderFilter] = useState<boolean>(false);
  const [appliesToMen, setAppliesToMen] = useState<boolean>(true);
  const [appliesToWomen, setAppliesToWomen] = useState<boolean>(true);
  const [appliesToWomenOfFertileAge, setAppliesToWomenOfFertileAge] = useState<boolean>(true);
  const [appliesToWomenOfNonFertileAge, setAppliesToWomenOfNonFertileAge] = useState<boolean>(true);
  const [haveOverhead, setHaveOverhead] = useState<boolean>(false);
  const [overheadValue, setOverheadValue] = useState<number>(0);
  const [avergaveOverheadValue, setAvergaveOverheadValue] = useState<number>(0);
  const [initialPeriodDate, setInitialPeriodDate] = useState<Date | null | undefined>();
  const [remoteFixedValue, setRemoteFixedValue] = useState<number>();
  const [haveRetroactivity, setHaveRetroactivity] = useState<boolean>(false);
  const [retroactivityValue, setRetroactivityValue] = useState<number>();
  const [retroactivityRemoteValue, setRetroactivityRemoteValue] = useState<number>();
  const [retroactivityDateFrom, setRetroactivityDateFrom] = useState<Date | null | undefined>();
  const [haveWithdrawal, setHaveWithdrawal] = useState<boolean>(false);
  const [withdrawalValue, setWithdrawalValue] = useState<number>();
  const [averageOverheadValue, setAverageOverheadValue] = useState<number>();

  const [conceptPaymentSchedule, setConceptPaymentSchedule] = useState<ContractConceptPaymentSchedule[]>([]);
  const [conceptAssociated, setConceptsAssociated] = useState<ContractConceptConcept[] | NewContractConceptConcept[]>([]);

  //load necesary data
  useEffect(() => {
    props.GetContractById(props.ContractId);
  }, []);

  useEffect(() => {
    if (props.Contract) {
      if (props.ContractConceptId) props.GetContractConceptById(props.ContractConceptId);
    }
  }, [props.Contract === undefined]);

  useEffect(() => {
    if (props.Concept) {
      props.GetAllContractConceptConcepts && props.GetAllContractConceptConcepts(props.ContractId, props.ContractAddendumId, props.Concept.id);
      props.GetAllContractsConceptPaymentSchedule && props.GetAllContractsConceptPaymentSchedule(props.ContractId, props.ContractAddendumId, props.Concept.id);
    }
  }, [props.ContractConceptId]);


  // save control
  useEffect(() => {
    if (submittedForm && props.isAdded && props.ContractConceptId === undefined) {
      var newConcept: ContractConcept | undefined = props.Concepts?.at(-1);
      setAddedConceptId(newConcept?.id);
      if ((settlementSettingOptionSelected?.key !== '4' && valuationTypeOptionSelected?.key !== '5')) {
        ResetForm();
        props.dismissPanel();
      }
    }
  }, [submittedForm, props.isAdded]);

  // save control
  useEffect(() => {
    if (submittedForm && props.isUpdated && props.ContractConceptId !== undefined) {
      setContractConceptSaved(true);
      if ((settlementSettingOptionSelected?.key !== '4' && valuationTypeOptionSelected?.key !== '5')) {
        ResetForm();
        props.dismissPanel();
      }
    }
  }, [submittedForm, props.isUpdated]);

  useEffect(() => {
    if (AddedConceptId) {
      setContractConceptSaved(true);
    }

  }, [AddedConceptId !== undefined]);


  useEffect(() => {
    if (props.Concept) {
      setConceptId(props.Concept.concept?.id);
      setConceptName(props.Concept.concept?.conceptName);
      setConceptDescription(props.Concept.concept?.conceptDescription);
      setIsIncludeInVisit(props.Concept.isIncludeInVisit);
      setApplyToRemoteExecution(props.Concept.applyToRemoteExecution);
      setApplyToInFaceExecution(props.Concept.applyToInFaceExecution);
      setHaveDivisionFee(props.Concept.haveDivisionFee);
      setDivisionFeeValue(props.Concept.divisionFeeValue);
      setIsMedicalService(props.Concept.isMedicalService);
      setMedicalServiceId(props.Concept.medicalServiceId);
      setSettlementSetting(props.Concept.settlementSetting);
      setSettlementSettingOptionSelected(ComponentFunctions.SettlementOptions.find((option: ComponentFunctions.ISettlementOption) => option.key === t(props.Concept.settlementSetting.toString())));
      setSettlementPeriod(props.Concept.settlementPeriod);
      setValuationType(props.Concept.valuationType);
      setValuationTypeOptionSelected(ComponentFunctions.ValueOriginOptions.find((option: ComponentFunctions.IValueOriginOption) => option.key === t(props.Concept.valuationType.toString())));
      setPriceValue(props.Concept.priceValue);
      setHaveRepetitionPrice(props.Concept.haveRepetitionPrice);
      setRepetitionPriceValue(props.Concept.repetitionPriceValue);
      setHaveEarlyDiscontinuationPrice(props.Concept.haveEarlyDiscontinuationPrice);
      setEarlyDiscontinuationPrice(props.Concept.earlyDiscontinuationPrice);
      setPercentageValue(props.Concept.percentageValue);
      setPercetageBaseOptionSelected(ComponentFunctions.PercentageOriginOptions.find((option: ComponentFunctions.IPercentageOriginOption) => option.key === props.Concept.percentageBase?.toString()) || undefined);
      setMaxCostPrice(props.Concept.maxCostPrice);
      setPriceListOrigin(props.Concept.priceListOrigin);
      setHaveCost(props.Concept.haveCost);
      setCostSettingOptionSelected(ComponentFunctions.ConceptCostOptions.find((option: ComponentFunctions.ICostOptions) => option.key === props.Concept.costSetting?.toString()) || undefined);
      setCostSetting(props.Concept.costSetting);
      setPercentageCostValue(props.Concept.percentageCostValue);
      setPercentageBase(props.Concept.percentageBase);
      setFixedCostValue(props.Concept.fixedCostValue);
      setCostPriceListOrigin(props.Concept.costPriceListOrigin);
      setCostProviderId(props.Concept.costProviderId);
      setAmountToIncludePerSettlement(props.Concept.amountToIncludePerSettlement);
      setExcludeParticularPatient(props.Concept.excludeParticularPatient);
      setTakeOnlyParticularPatient(props.Concept.takeOnlyParticularPatient);
      setCalculateType(props.Concept.calculateType);
      setNeedAggrupation(props.Concept.needAgrupation);
      setAggrupationSegment(props.Concept.agroupationSegment);
      setQuantityToSettle(props.Concept.quantityToSettle);
      setMaxQuantityToSettle(props.Concept.maxQuantityToSettle);
      setHaveGenderFilter(props.Concept.haveGenderFilter);
      setAppliesToMen(props.Concept.appliesToMen);
      setAppliesToWomen(props.Concept.appliesToWomen);
      setAppliesToWomenOfFertileAge(props.Concept.appliesToWomenOfFertileAge);
      setAppliesToWomenOfNonFertileAge(props.Concept.appliesToWomenOfNonFertileAge);
      setHaveOverhead(props.Concept.haveOverhead);
      setOverheadValue(props.Concept.overheadValue);
      setAvergaveOverheadValue(props.Concept.avergaveOverheadValue);
      // added fields
      setInitialPeriodDate(props.Concept.initialPeriodDate);
      setRemoteFixedValue(props.Concept.remoteFixedValue);
      setHaveRetroactivity(props.Concept.haveRetroactivity);
      setRetroactivityValue(props.Concept.retroactivityValue);
      setRetroactivityRemoteValue(props.Concept.retroactivityRemoteValue);
      setRetroactivityDateFrom(props.Concept.retroactivityDateFrom);
      setHaveWithdrawal(props.Concept.haveWithdrawal);
      setWithdrawalValue(props.Concept.withdrawalValue);
      setNeedAggrupation(props.Concept.needAggrupation);
      setAggrupationSegment(props.Concept.aggrupationSegment);
      setAverageOverheadValue(props.Concept.averageOverheadValue);
      // end added fields
    }
  }, [props.ContractConceptId]);

  const ResetForm = () => {
    setConceptName(undefined);
    setConceptDescription(undefined);
    setSubmittedForm(false);
    setFormValidationStatus(undefined);
    setConceptId(undefined);
    setIsIncludeInVisit(false);
    setApplyToRemoteExecution(false);
    setApplyToInFaceExecution(false);
    setHaveDivisionFee(false);
    setDivisionFeeValue(0);
    setIsMedicalService(false);
    setMedicalServiceId(undefined);
    setSettlementSetting(undefined);
    setSettlementSettingOptionSelected(undefined);
    setSettlementPeriod(undefined);
    setValuationType(undefined);
    setValuationTypeOptionSelected(undefined);
    setPriceValue(undefined);
    setHaveRepetitionPrice(false);
    setRepetitionPriceValue(undefined);
    setHaveEarlyDiscontinuationPrice(false);
    setEarlyDiscontinuationPrice(undefined);
    setPercentageValue(undefined);
    setPercentageBase(undefined);
    setMaxCostPrice(undefined);
    setPriceListOrigin(undefined);
    setHaveCost(false);
    setCostSetting(undefined);
    setPercentageCostValue(undefined);
    setFixedCostValue(undefined);
    setCostPriceListOrigin(undefined);
    setCostProviderId(undefined);
    setAmountToIncludePerSettlement(undefined);
    setExcludeParticularPatient(false);
    setTakeOnlyParticularPatient(false);
    setCalculateType(undefined);
    setNeedAggrupation(false);
    setAggrupationSegment(undefined);
    setQuantityToSettle(1);
    setMaxQuantityToSettle(1);
    setHaveGenderFilter(false);
    setAppliesToMen(true);
    setAppliesToWomen(true);
    setAppliesToWomenOfFertileAge(true);
    setAppliesToWomenOfNonFertileAge(true);
    setHaveOverhead(false);
    setOverheadValue(0);
    setAvergaveOverheadValue(0);
    setInitialPeriodDate(undefined);
    setRemoteFixedValue(undefined);
    setHaveRetroactivity(false);
    setRetroactivityValue(undefined);
    setRetroactivityRemoteValue(undefined);
    setRetroactivityDateFrom(undefined);
    setHaveWithdrawal(false);
    setWithdrawalValue(undefined);
    setAverageOverheadValue(undefined);
    setContractConceptSaved(false);
    setAddedConceptId(undefined);
  };

  const onFinishedSavingHandler = (status: boolean) => {
    if (status && ContractConceptSaved) {
      ResetForm();
      props.dismissPanel();
    }
  }

  const onChangeValuationTypeSelection = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IValueOriginOption
  ) => {
    setValuationTypeOptionSelected(item);
    setValuationType(Number(item?.key));
  }

  const onChangeMedicalServiceIdSelection = (
    medicalservice?: AvailableMedicalService
  ) => {
    medicalservice && setMedicalServiceId(medicalservice.id);
  }

  const onChangePriceTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPriceValue(Number(newValue));
  }

  const onChangePercentageTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPercentageValue(Number(newValue));
  }

  const onPercentageBaseChange = (
    ev?:
      | React.FormEvent<HTMLElement | HTMLInputElement>
      | undefined,
    option?: IChoiceGroupOption | undefined
  ) => {
    setPercetageBaseOptionSelected(option);
    setPercentageBase(Number(option?.key) || undefined);
  }

  const onChangeMaxCostPriceTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setMaxCostPrice(Number(newValue));
  }

  const onChangePriceListOrigin = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    setPriceListOrigin(Number(option?.key) || undefined);
  }

  const onChangeHaveRepetitionPrice = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setHaveRepetitionPrice(checked || false);
  }

  const onChangeRepetitionPriceTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setRepetitionPriceValue(Number(newValue));
  }

  const onChangeSettlementSetting = (
    event: React.FormEvent<HTMLDivElement>,
    option?: ComponentFunctions.ISettlementOption
  ) => {
    setSettlementSettingOptionSelected(option);
    setSettlementSetting(Number(option?.key) || undefined);
  }

  const onChangeSettlementPeriod = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {

    setSettlementPeriod(Number(option?.key) || undefined);
  }

  // -----------------------------------------------------

  const onChangeConceptSelection = (
    concepts: ContractConcept[]
  ) => {
    var currentConceptAssociated: (ContractConceptConcept | NewContractConceptConcept)[] = [];

    concepts.forEach(concept => {

      currentConceptAssociated = [...currentConceptAssociated, {
        contractConceptId: props.Concept?.id,
        relatedContractConceptId: concept.id,
        isSelect: concept.isSelected
      }];
    });
    setConceptsAssociated(currentConceptAssociated);
    console.log('conceptos asociados', currentConceptAssociated);
  };

  const onAddConceptToConcept = (concept: ContractConcept) => {
    //alert('agrega concepto' + concept.id);
    // // get if concept is already associated
    // var auxConcept = props.Concept?.concepts?.find(concept => 
    //   concept.relatedContractConceptId === concept.id
    // );

    // if (!auxConcept) {
    //   setConceptsAssociated([...conceptAssociated, {
    //     contractConceptId: props.Concept.id,
    //     relatedContractConceptId: concept.id
    //   }]);
    // } else {
    //   setConceptsAssociated([...conceptAssociated, auxConcept]);
    // }
  }

  const onRemoveConceptFromConcept = (concept: ContractConcept) => {
    // alert('quita concepto' + concept.id);
    // // console.log('conceptos asociados', conceptAssociated);
    // setConceptsAssociated(conceptAssociated?.filter(conceptAssociated => conceptAssociated?.relatedContractConceptId !== concept.id));
  }

  const onChangeHaveCost = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setHaveCost(checked || false);
  }

  const onChangeCostSetting = (
    event: React.FormEvent<HTMLDivElement>,
    option?: ComponentFunctions.ICostOptions
  ) => {
    setCostSettingOptionSelected(option);
    setCostSetting(Number(option?.key) || undefined);
  }

  const onChangeFixedCostTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setFixedCostValue(Number(newValue));
  }

  const onChangePercentageCostTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPercentageCostValue(Number(newValue));
  }

  const onChangeCostPriceListOrigin = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    setCostPriceListOrigin(Number(option?.key) || undefined);
  }

  const onChangeHaveEarlyDiscontinuationPrice = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setHaveEarlyDiscontinuationPrice(checked || false);
  };

  const onChangeEarlyDiscontinuationPriceTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setEarlyDiscontinuationPrice(Number(newValue));
  }

  const onChangeCalculateType = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    setCalculateType(Number(option?.key) || undefined);
  }

  const onChangeAgroupationTag = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setNeedAggrupation(checked || false);
  }

  const onChangeAgroupationSegment = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setAggrupationSegment(Number(newValue));
  }

  const onChangePaymentSchedule = (
    paymentSchedule: ContractConceptPaymentSchedule[]
  ) => {
    console.log(paymentSchedule);
    setConceptPaymentSchedule(paymentSchedule);
  };


  const onChangeQuantityToSettle = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setQuantityToSettle(Number(newValue));
  }

  const onChangeAmountToIncludePerSettlement = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setAmountToIncludePerSettlement(Number(newValue));
  }

  const onChangeExcludeParticularPatient = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setExcludeParticularPatient(checked || false);
  }

  const onChangeTakeOnlyParticularPatient = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setTakeOnlyParticularPatient(checked || false);
  }

  const onChangeMaxQuantityToSettle = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setMaxQuantityToSettle(Number(newValue));
  }

  const onChangeHaveGenderFilter = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setHaveGenderFilter(checked || false);
  }

  const onChangeAppliesToMen = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setAppliesToMen(checked || false);
  }

  const onChangeAppliesToWomen = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setAppliesToWomen(checked || false);
  }

  const onChangeAppliesToWomenOfFertileAge = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setAppliesToWomenOfFertileAge(checked || false);
  }

  const onChangeAppliesToWomenOfNonFertileAge = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setAppliesToWomenOfNonFertileAge(checked || false);
  }

  const onChangeHaveOverhead = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setHaveOverhead(checked || false);
  }

  const onChangeOverheadValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setOverheadValue(Number(newValue));
  }

  const onChangeAvergaveOverheadValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setAvergaveOverheadValue(Number(newValue));
    var auxPriceWithoutOverheadValue = priceValue && ((priceValue * 100) / (100 + avergaveOverheadValue)) || 0;
    priceValue && setOverheadValue(priceValue - auxPriceWithoutOverheadValue);
  }

  // withdrawal fields

  const onChangeHaveWithdrawal = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setHaveWithdrawal(checked || false);
  }

  const onChangeWithdrawalValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setWithdrawalValue(Number(newValue));
  }

  const onChangeApplyToRemoteExecution = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setApplyToRemoteExecution(checked || false);
  }

  const onChangeApplyToInFaceExecution = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setApplyToInFaceExecution(checked || false);
  }

  const onChangeHaveDivisionFee = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setHaveDivisionFee(checked || false);
  }

  const onChangeDivisionFeeValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setDivisionFeeValue(Number(newValue));
  }

  // retroactivity fields
  const onChangeHaveRetroactivity = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setHaveRetroactivity(checked || false);
  }

  const onChangeRetroactivityValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setRetroactivityValue(Number(newValue));
  }

  // handler Actions
  const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      let CurrentValues: ContractConcept | NewContractConcept;
      if (props.ContractConceptId) {
        CurrentValues = {
          id: props.ContractConceptId!,
          contractId: props.ContractId,
          contractAddendumId: props.ContractAddendumId,
          conceptId: conceptId!,
          concept: {
            id: conceptId!,
            conceptName: conceptName!,
            conceptDescription: conceptDescription!,
            conceptReferenceId: '1'
          },
          conceptCurrency: props.Contract.contractCurrency!,
          isIncludeInVisit: isIncludeInVisit,
          applyToRemoteExecution: applyToRemoteExecution,
          applyToInFaceExecution: applyToInFaceExecution,
          haveDivisionFee: haveDivisionFee,
          divisionFeeValue: divisionFeeValue!,
          isMedicalService: isMedicalService!,
          medicalServiceId: medicalServiceId!,
          settlementSetting: settlementSetting!,
          settlementPeriod: settlementPeriod!,
          valuationType: valuationType!,
          priceValue: priceValue!,
          haveRepetitionPrice: haveRepetitionPrice!,
          repetitionPriceValue: repetitionPriceValue!,
          haveEarlyDiscontinuationPrice: haveEarlyDiscontinuationPrice!,
          earlyDiscontinuationPrice: earlyDiscontinuationPrice!,
          percentageValue: percentageValue!,
          maxCostPrice: maxCostPrice!,
          priceListOrigin: priceListOrigin!,
          haveCost: haveCost!,
          costSetting: costSetting!,
          percentageCostValue: percentageCostValue!,
          percentageBase: percentageBase!,
          fixedCostValue: fixedCostValue!,
          costPriceListOrigin: costPriceListOrigin!,
          costProviderId: costProviderId!,
          amountToIncludePerSettlement: amountToIncludePerSettlement!,
          excludeParticularPatient: excludeParticularPatient!,
          takeOnlyParticularPatient: takeOnlyParticularPatient!,
          calculateType: calculateType!,
          needAgrupation: needAggrupation!,
          agroupationSegment: aggrupationSegment!,
          quantityToSettle: quantityToSettle!,
          maxQuantityToSettle: maxQuantityToSettle!,
          haveGenderFilter: haveGenderFilter!,
          appliesToMen: appliesToMen!,
          appliesToWomen: appliesToWomen!,
          appliesToWomenOfFertileAge: appliesToWomenOfFertileAge!,
          appliesToWomenOfNonFertileAge: appliesToWomenOfNonFertileAge!,
          haveOverhead: haveOverhead,
          overheadValue: overheadValue,
          avergaveOverheadValue: avergaveOverheadValue,
          // added fileds
          initialPeriodDate: initialPeriodDate,
          remoteFixedValue: remoteFixedValue,
          haveRetroactivity: haveRetroactivity,
          retroactivityValue: retroactivityValue,
          retroactivityRemoteValue: retroactivityRemoteValue,
          retroactivityDateFrom: retroactivityDateFrom,
          haveWithdrawal: haveWithdrawal,
          withdrawalValue: withdrawalValue,
          needAggrupation: needAggrupation,
          aggrupationSegment: aggrupationSegment,
          averageOverheadValue: averageOverheadValue,
          // end added fields
          concepts: conceptAssociated || [],
          paymentSchedule: conceptPaymentSchedule?.map((schedule: ContractConceptPaymentSchedule) => {
            return {
              id: schedule.id,
              stage: schedule.stage,
              isSelected: schedule.isSelected,
              isUnselected: schedule.isUnselected,
              contractConceptId: props.ContractConceptId!,
              contractStageId: schedule.contractStageId,
              amountExpected: schedule.amountExpected,
              screeningFailurePrice: schedule.screeningFailurePrice,
              settlementCoefficient: schedule.settlementCoefficient,
              price: schedule.price,
              remotePrice: schedule.remotePrice,
              currency: props.Contract.contractCurrency!,
              followUpPrice: schedule.followUpPrice
            }
          }) || []
        };
        props.UpdateContractConcept(CurrentValues as ContractConcept);
      } else {
        CurrentValues = {
          contractId: props.ContractId,
          contractAddendumId: props.ContractAddendumId,
          conceptId: conceptId!,
          concept: {
            id: conceptId!,
            conceptName: conceptName!,
            conceptDescription: conceptDescription!,
            conceptReferenceId: '1'
          },
          conceptCurrency: props.Contract.contractCurrency!,
          isIncludeInVisit: isIncludeInVisit,
          applyToRemoteExecution: applyToRemoteExecution,
          applyToInFaceExecution: applyToInFaceExecution,
          haveDivisionFee: haveDivisionFee,
          divisionFeeValue: divisionFeeValue!,
          isMedicalService: isMedicalService!,
          medicalServiceId: medicalServiceId!,
          settlementSetting: settlementSetting!,
          settlementPeriod: settlementPeriod!,
          valuationType: valuationType!,
          priceValue: priceValue!,
          haveRepetitionPrice: haveRepetitionPrice!,
          repetitionPriceValue: repetitionPriceValue!,
          haveEarlyDiscontinuationPrice: haveEarlyDiscontinuationPrice!,
          earlyDiscontinuationPrice: earlyDiscontinuationPrice!,
          percentageValue: percentageValue!,
          percentageBase: percentageBase!,
          maxCostPrice: maxCostPrice!,
          priceListOrigin: priceListOrigin!,
          haveCost: haveCost!,
          costSetting: costSetting!,
          percentageCostValue: percentageCostValue!,
          fixedCostValue: fixedCostValue!,
          costPriceListOrigin: costPriceListOrigin!,
          costProviderId: costProviderId!,
          amountToIncludePerSettlement: amountToIncludePerSettlement!,
          excludeParticularPatient: excludeParticularPatient!,
          takeOnlyParticularPatient: takeOnlyParticularPatient!,
          calculateType: calculateType!,
          needAgrupation: needAggrupation!,
          agroupationSegment: aggrupationSegment!,
          quantityToSettle: quantityToSettle!,
          maxQuantityToSettle: maxQuantityToSettle!,
          haveGenderFilter: haveGenderFilter!,
          appliesToMen: appliesToMen!,
          appliesToWomen: appliesToWomen!,
          appliesToWomenOfFertileAge: appliesToWomenOfFertileAge!,
          appliesToWomenOfNonFertileAge: appliesToWomenOfNonFertileAge!,
          haveOverhead: haveOverhead,
          overheadValue: overheadValue,
          avergaveOverheadValue: avergaveOverheadValue,
          // added fileds
          initialPeriodDate: initialPeriodDate,
          remoteFixedValue: remoteFixedValue,
          haveRetroactivity: haveRetroactivity,
          retroactivityValue: retroactivityValue,
          retroactivityRemoteValue: retroactivityRemoteValue,
          retroactivityDateFrom: retroactivityDateFrom,
          haveWithdrawal: haveWithdrawal,
          withdrawalValue: withdrawalValue,
          needAggrupation: needAggrupation,
          aggrupationSegment: aggrupationSegment,
          averageOverheadValue: averageOverheadValue,
          // end added fields
          concepts: conceptAssociated || [],
          paymentSchedule: conceptPaymentSchedule?.map((schedule: ContractConceptPaymentSchedule) => {
            return {
              id: schedule.id,
              stage: schedule.stage,
              isSelected: schedule.isSelected,
              isUnselected: schedule.isUnselected,
              contractConceptId: props.ContractConceptId!,
              contractStageId: schedule.contractStageId,
              amountExpected: schedule.amountExpected,
              screeningFailurePrice: schedule.screeningFailurePrice,
              settlementCoefficient: schedule.settlementCoefficient,
              price: schedule.price,
              remotePrice: schedule.remotePrice,
              currency: props.Contract.contractCurrency!,
              followUpPrice: schedule.followUpPrice
            }
          }) || []
        }
        props.AddContractConcept(props.ContractAddendumId, CurrentValues as NewContractConcept);
      }
    }
  };

  const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
  };

  // footer
  const onRenderFooterContent = () => {
    return (
      <div>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'conceptForm.footer.cancelButton'} />
        </DefaultButton>
        <PrimaryButton
          onClick={HandleSaveForm}
          styles={buttonStyles}
          disabled={props.isAdding || props.isUpdating}
          className="ConfirmAction"
        >
          {props.isAdding || props.isUpdating ? <><Spinner size={SpinnerSize.xSmall} />&nbsp;<span><Trans i18nKey={'conceptForm.footer.saveButton'} /></span></> : 'Guardar'}
        </PrimaryButton>
      </div>
    );
  };

  const formFields = (
    <div className='flowcharSelector' {...columnProps}>
      <div className='ScrollShadow'>
        <TextField
          label={t('conceptForm.formFields.conceptName')}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
          ) => setConceptName(newValue || '')}
          value={conceptName}
        />
        <TextField
          label={t('conceptForm.formFields.description')}
          type="text"
          multiline rows={3}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
          ) => setConceptDescription(newValue || '')}
          value={conceptDescription}
        />
        <Separator />
        <Checkbox
          label={t('conceptForm.formFields.joinMedicalService')}
          onChange={(
            ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
            checked?: boolean | undefined
          ) => setIsMedicalService(checked || false)}
          defaultChecked={isMedicalService}
        />
        {isMedicalService && (
          <>
            <MedicalServiceSelectorComponent
              onSelectedServices={(services: AvailableMedicalService[]) => onChangeMedicalServiceIdSelection(services[0])}
              MedicalServicesSelectedIds={medicalServiceId ? [medicalServiceId] : []}
              MultiselectEnable={false}
            />
          </>)
        }
        <Separator />
        <Dropdown
          label={t('conceptForm.formFields.valorization')}
          options={ComponentFunctions.ValueOriginOptions.map((option: ComponentFunctions.IValueOriginOption) => {
            return {
              key: option.key,
              text: t(option.text)
            }
          })}
          defaultSelectedKey={valuationType?.toString()}
          onChange={onChangeValuationTypeSelection}
        />
        {!props.Contract?.contractCurrency && (
          <MessageComponent
            message={t('conceptForm.formFields.message')}
            type={MessageBarType.warning}
          />
        )}
        {(valuationTypeOptionSelected && valuationTypeOptionSelected.key === '1') && (
          <div>
            <TextField
              label={t('conceptForm.formFields.value')}
              type="number"
              suffix={props.Contract?.contractCurrency}
              onChange={onChangePriceTextFieldValue}
              value={priceValue?.toString()}
            />
          </div>
        )}
        {valuationTypeOptionSelected && valuationTypeOptionSelected.key === '3' && (
          <div>
            <TextField
              label={t('conceptForm.formFields.value')}
              type="number"
              suffix="%"
              value={percentageValue?.toString()}
              onChange={onChangePercentageTextFieldValue}
            />
            <ChoiceGroup
              selectedKey={percentageBase?.toString()}
              options={ComponentFunctions.PercentageOriginOptions}
              onChange={onPercentageBaseChange}
              label={t('conceptForm.formFields.percentageBase')}
            />

            {(percetageBaseOptionSelected?.takeFromConcepts) && (
              <>
                <Label>{t('conceptForm.formFields.percentageConcept')}</Label>
                <ProtocolConceptMultiSelectorComponent
                  concepts={props.Concepts || []}
                  parentConceptId={props.Concept?.id}
                  showPercentage={false}
                  onAddConcept={onAddConceptToConcept}
                  onDeleteConcept={onRemoveConceptFromConcept}
                  onChangeConceptSelection={onChangeConceptSelection}
                  selectedConceptIds={props.Concepts?.filter(concept => concept.isSelected)?.map(concept => concept.id) || []}
                />
                <small>
                  {t('conceptForm.formFields.applyPercentage')}
                </small>
              </>
            )}
          </div>
        )}
        {valuationTypeOptionSelected?.key === '2' && (
          <>
            <TextField
              label={t('conceptForm.formFields.contractValue')}
              type="number"
              suffix={props.Contract?.contractCurrency}
              defaultValue={maxCostPrice?.toString()}
              onChange={onChangeMaxCostPriceTextFieldValue}
            />
            <Text>
              {t('conceptForm.formFields.contractMessage')}
            </Text>
          </>
        )}
        {valuationTypeOptionSelected?.key === '4' && !isMedicalService &&
          <MessageComponent
            message={t('conceptForm.formFields.priceMessage')}
            type={MessageBarType.error}
          />
        }
        {(valuationTypeOptionSelected?.key === '4' || (valuationTypeOptionSelected?.isPercentage && percetageBaseOptionSelected?.takeFromPriceList)) && isMedicalService && (
          <Dropdown
            label={t('conceptForm.formFields.priceListOrigin')}
            options={ComponentFunctions.PriceListOptions}
            defaultSelectedKey={priceListOrigin?.toString()}
            onChange={onChangePriceListOrigin}
          />
        )}
        <Separator />
        <Toggle label={t('conceptForm.formFields.haveWithholding')} defaultChecked={haveWithdrawal} onChange={onChangeHaveWithdrawal} onText="Sí" offText="No" />
        {haveWithdrawal && (
          <>
            <TextField
              label={t('conceptForm.formFields.withholdingPercentage')}
              step={0.01}
              type="number"
              value={withdrawalValue?.toString()}
              suffix={'%'}
              onChange={onChangeWithdrawalValue}
            />
            <Separator />
          </>
        )}
        <Toggle label={t('conceptForm.formFields.isIncludedInVisit')} defaultChecked={isIncludeInVisit} onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => setIsIncludeInVisit(checked || false)} onText="Sí" offText="No" />
        <Toggle label={t('conceptForm.formFields.applyToRemoteExecution')} defaultChecked={applyToRemoteExecution} onChange={onChangeApplyToRemoteExecution} onText="Sí" offText="No" />
        <Toggle label={t('conceptForm.formFields.applyToInFaceExecution')} defaultChecked={applyToInFaceExecution} onChange={onChangeApplyToInFaceExecution} onText="Sí" offText="No" />
        <Toggle label={t('conceptForm.formFields.haveDivisionFee')} defaultChecked={haveDivisionFee} onChange={onChangeHaveDivisionFee} onText="Sí" offText="No" />
        {haveDivisionFee && (
          <>
            <TextField
              label={t('conceptForm.formFields.divisionFeeValue')}
              type="number"
              suffix={'%'}
              value={divisionFeeValue?.toString()}
              onChange={onChangeDivisionFeeValue}
            />
            <code>
              <Label>Porcentual institución <strong>{divisionFeeValue}%</strong></Label>
              <Label>Porcentual Investigador Principal <strong>{100 - divisionFeeValue}%</strong></Label>
            </code>
            <Separator />
          </>
        )}
        <Toggle label={t('conceptForm.formFields.includedOverhead')} defaultChecked={haveOverhead} onChange={onChangeHaveOverhead} onText="Sí" offText="No" />
        {haveOverhead && (
          <>
            <TextField
              label={t('conceptForm.formFields.overheadPercentage')}
              step={0.01}
              type="number"
              value={avergaveOverheadValue?.toString()}
              suffix={'%'}
              onChange={onChangeAvergaveOverheadValue}
            />
            {valuationTypeOptionSelected && valuationTypeOptionSelected?.key === '5' && <>
              <Label>
                {t('conceptForm.formFields.noOverhead')} {priceValue && (priceValue - overheadValue).toFixed(2) + ' ' + props.Contract?.contractCurrency}
              </Label>
              <Label>
                {t('conceptForm.formFields.overheadValue')} {overheadValue.toFixed(2) + ' ' + props.Contract?.contractCurrency}
              </Label>
              <Separator />
            </>}
            <Separator />
          </>
        )}
        <Toggle label={t('conceptForm.formFields.repeatPrice')} defaultChecked={haveRepetitionPrice} onChange={onChangeHaveRepetitionPrice} onText={t('conceptForm.formFields.repeatPriceYes')} offText={t('conceptForm.formFields.repeatPriceNo')} />
        {haveRepetitionPrice && (
          <TextField
            label={t('conceptForm.formFields.additionalValue')}
            step={0.01}
            type="number"
            value={repetitionPriceValue?.toString()}
            suffix={props.Contract?.contractCurrency}
            onChange={onChangeRepetitionPriceTextFieldValue}
          />
        )}
        <Toggle label={t('conceptForm.formFields.earlyDiscontinuationPrice')} defaultChecked={haveEarlyDiscontinuationPrice} onChange={onChangeHaveEarlyDiscontinuationPrice} onText={t('conceptForm.formFields.yes')} offText={t('conceptForm.formFields.no')} />
        {haveEarlyDiscontinuationPrice && (
          <TextField
            label={t('conceptForm.formFields.earlyDiscontinuationValue')}
            step={0.01}
            type="number"
            value={earlyDiscontinuationPrice?.toString()}
            suffix={props.Contract?.contractCurrency}
            onChange={onChangeEarlyDiscontinuationPriceTextFieldValue}
          />
        )}
        <Toggle label={t('conceptForm.formFields.haveRetroaactivity')} defaultChecked={haveRetroactivity} onChange={onChangeHaveRetroactivity} onText="Sí" offText="No" />
        {haveRetroactivity && (
          <>
            <DateTimeInput
              label={t('conceptForm.formFields.retroactivityDateFrom')}
              placeHolder={'ingrese una fecha'}
              selectedDate={retroactivityDateFrom ? new Date(retroactivityDateFrom) : new Date()}
              isSubmitted={false}
              isRequired={settlementSetting === 5}
              errorMessage={undefined}
              onSelectDate={(date: Date | null | undefined) => setRetroactivityDateFrom(date)}
            />
            <Separator />
          </>
        )}
        <Separator />
        <Label>{t('conceptForm.formFields.applicationCriteria')}</Label>
        <Toggle label={t('conceptForm.formFields.appliesToMen')} defaultChecked={appliesToMen} onChange={onChangeAppliesToMen} onText={t('conceptForm.formFields.yes')} offText={t('conceptForm.formFields.no')} />
        <Toggle label={t('conceptForm.formFields.appliestoWomen')} defaultChecked={appliesToWomen} onChange={onChangeAppliesToWomen} onText={t('conceptForm.formFields.yes')} offText={t('conceptForm.formFields.no')} />
        {appliesToWomen && (
          <>
            <Toggle label={t('conceptForm.formFields.womenFertileAge')} defaultChecked={appliesToWomenOfFertileAge} onChange={onChangeAppliesToWomenOfFertileAge} onText={t('conceptForm.formFields.yes')} offText={t('conceptForm.formFields.no')} />
            <Toggle label={t('conceptForm.formFields.womenOfNonFertileAge')} defaultChecked={appliesToWomenOfNonFertileAge} onChange={onChangeAppliesToWomenOfNonFertileAge} onText={t('conceptForm.formFields.yes')} offText={t('conceptForm.formFields.no')} />
          </>
        )}
        <Separator />
        <Dropdown
          label={t('conceptForm.formFields.settlement')}
          options={ComponentFunctions.SettlementOptions.map((option: ComponentFunctions.ISettlementOption) => {
            return {
              key: option.key,
              text: t(option.text)
            }

          })}
          onChange={onChangeSettlementSetting}
          defaultSelectedKey={settlementSetting?.toString()}
        />
        {settlementSetting === 5 && (
          <>
            <Dropdown
              label={t('conceptForm.formFields.settlementPeriod')}
              options={ComponentFunctions.PeriodOptions}
              selectedKey={settlementPeriod?.toString()}
              onChange={onChangeSettlementPeriod}
            />
            <DateTimeInput
              label={'Fecha desde que se contabiliza el período'}
              placeHolder={'ingrese una fecha'}
              selectedDate={initialPeriodDate ? new Date(initialPeriodDate) : new Date()}
              isSubmitted={false}
              isRequired={settlementSetting === 5}
              errorMessage={undefined}
              onSelectDate={(date: Date | null | undefined) => setInitialPeriodDate(date)}
            />
          </>
        )}
        <Separator />
        <TextField
          label={t('conceptForm.formFields.quantityToSettle')}
          type="number"
          suffix='Cantidad'
          value={quantityToSettle?.toString()}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
          ) => setQuantityToSettle(Number(newValue))}
        />
        <Separator />
        <Dropdown label={t('conceptForm.formFields.calculateType')}
          options={ComponentFunctions.calculateTypeOptions}
          defaultSelectedKey={calculateType?.toString()}
          onChange={onChangeCalculateType}
        />
        <Toggle label={t('conceptForm.formFields.needAgrupation')} defaultChecked={needAggrupation} onChange={onChangeAgroupationTag} onText={t('conceptForm.formFields.yes')} offText={t('conceptForm.formFields.no')} />
        {needAggrupation && (
          <TextField
            label={t('conceptForm.formFields.agroupationSegment')}
            type="number"
            suffix={ComponentFunctions.getCalculateTypeDescription(calculateType)}
            value={aggrupationSegment?.toString()}
            onChange={onChangeAgroupationSegment}
          />
        )}
        <Toggle label={t('conceptForm.formFields.excludeParticularPatient')} defaultChecked={excludeParticularPatient} onChange={onChangeExcludeParticularPatient} onText={t('conceptForm.formFields.yes')} offText={t('conceptForm.formFields.no')} />
        <Separator />
        <Toggle label={t('conceptForm.formFields.haveCost')} defaultChecked={haveCost} onChange={onChangeHaveCost} onText={t('conceptForm.formFields.yes')} offText={t('conceptForm.formFields.no')} />
        {
          haveCost && (
            <>
              <Dropdown
                label={t('conceptForm.formFields.conceptCost')}
                options={ComponentFunctions.ConceptCostOptions}
                defaultSelectedKey={costSetting?.toString()}
                onChange={onChangeCostSetting}
              />
              {costSettingOptionSelected?.isFixedPrice && (
                <TextField
                  label={t('conceptForm.formFields.costValue')}
                  step={0.01}
                  type="number"
                  value={fixedCostValue?.toString()}
                  suffix={props.Contract?.contractCurrency}
                  onChange={onChangeFixedCostTextFieldValue}
                />
              )}
              {costSettingOptionSelected?.isPercentage && (
                <TextField
                  label={t('conceptForm.formFields.costValue')}
                  step={0.01}
                  type="number"
                  value={fixedCostValue?.toString()}
                  suffix='%'
                  onChange={onChangePercentageCostTextFieldValue}
                />
              )}
              {costSettingOptionSelected?.isPriceList && !isMedicalService &&
                <MessageComponent
                  message={t('conceptForm.formFields.priceMessage')}
                  type={MessageBarType.error}
                />
              }
              {costSettingOptionSelected?.isPriceList && isMedicalService && (
                <Dropdown
                  label={t('conceptForm.formFields.selectPriceList')}
                  options={ComponentFunctions.OriginConceptCostOptions}
                  defaultSelectedKey={costPriceListOrigin?.toString()}
                  onChange={onChangeCostPriceListOrigin}
                />
              )}

              {costSettingOptionSelected?.isPriceList && costPriceListOrigin === 2 && isMedicalService && (
                <>
                  <Label>{t('conceptForm.formFields.provider')}</Label>
                  <ProviderSelectorComponent selectionLimit={1} ProviderSelected={[]} />
                </>
              )}
            </>
          )
        }
      </div>
      {(settlementSettingOptionSelected?.key === '4' || valuationTypeOptionSelected?.key === '5') && (
        <div className='flowcharContainer ScrollShadow'>
          {/* <Label>{t('conceptForm.formFields.flowChart')}</Label> */}
          <div className="FlowChart">
            <ContractStageMultiSelectorComponent
              ContractId={props.ContractId}
              ContractAddendumId={props.ContractAddendumId}
              ContractConceptId={props.ContractConceptId || AddedConceptId}
              ShowInputValues={valuationTypeOptionSelected?.key === '5' ? true : false}
              ConceptSaved={ContractConceptSaved}
              OnAllSaved={onFinishedSavingHandler}
            />
          </div>
        </div>
      )}
    </div>
  );

  // form validation

  const ValidateForm = (): boolean => {
    let validationResult: boolean = true;

    let FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  return (
    <Panel
      isOpen={props.isOpen}
      type={(settlementSettingOptionSelected?.key === '4' || valuationTypeOptionSelected?.key === '5') ? PanelType.extraLarge : PanelType.medium}
      headerText={t('conceptForm.panel.visitInformation')}
      onDismiss={props.dismissPanel}
      closeButtonAriaLabel="Close"
      onRenderFooter={undefined}
      isFooterAtBottom={true}
      onRenderFooterContent={onRenderFooterContent}
    >
      {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {t('conceptForm.panel.reviewInformation')}
          <ul>
            {FormValidationStatus?.errors.map(
              (Error: string, index: number) => (
                <li key={index}>{Error}</li>
              )
            )}
          </ul>
        </MessageBar>
      )}
      {props.failOnAdding &&
        props.error && (
          <ErrorMessageComponent Error={props.error} />
        )}
      {/* <div className={contentStyles.body}> */}
      {/* {props.failOnAdding &&
        props.error && (
          <ErrorMessageComponent Error={props.error} />
        )} */}
      {formFields}
      {/* </div> */}
    </Panel>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(ConceptFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    overflowY: 'auto',
    selectors: {
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    },
    padding: '0 1em 0 0',
    height: '85vh'
  },
  footer: {
    //flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
