import { AppThunkAction } from '../reducers';
import * as jwt_decode from "jwt-decode";

// services
import * as Services from '../../services/CashFlow-Payments.Services';

// actions
import * as Actions from '../actions';
import * as ActionEvents from '../actions/CashFlow-Payments.Actions';

// models
import { Payment } from '../../models/Entities/CashFlow/Payment/Payment';
import { NewPayment } from '../../models/Entities/CashFlow/Payment/NewPayment';

type KnownAction = 
    | Actions.CashFlowPayments.CashFlowPaymentsDoneGetAllRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsDoneGetAllSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsDoneGetAllFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedGetAllRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedGetAllSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedGetAllFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsDoneUpdateAllRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsDoneUpdateAllSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsDoneUpdateAllFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedUpdateAllRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedUpdateAllSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedUpdateAllFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsGetByIdRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsGetByIdSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsGetByIdFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsCreateRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsCreateSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsCreateFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsUpdateRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsUpdateSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsUpdateFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsDeleteRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsDeleteSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsDeleteFailureAction;

export const actionCreators = {
    GetAllPaymentsByPayer: ( payer: string, receiver: string | undefined ): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_PAYMENTS_DONE_GETALL_FAILURE,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        if(state.CashFlowPayments?.successLoadingAll) { // update

            dispatch({
                type: ActionEvents.CASHFLOW_PAYMENTS_DONE_UPDATEALL_REQUEST
            });

            Services.GetPaymentsByPayer(token, payer, receiver)
            .then((payments: Payment[]) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_DONE_UPDATEALL_SUCCESS,
                    payload: payments
                });
            })
            .catch((error) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_DONE_UPDATEALL_FAILURE,
                    error: error
                });
            });

        } else { // load
            
            dispatch({
                type: ActionEvents.CASHFLOW_PAYMENTS_DONE_GETALL_REQUEST
            });
    
            Services.GetPaymentsByPayer(token, payer, receiver)
            .then((payments: Payment[]) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_DONE_GETALL_SUCCESS,
                    payload: payments
                });
            })
            .catch((error) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_DONE_GETALL_FAILURE,
                    error: error
                });
            });
        }

        
    },
    GetAllPaymentsByReceiver: ( receiver: string, payer: string | undefined ): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_PAYMENTS_RECEIVED_GETALL_FAILURE,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        if(state.CashFlowPayments?.successLoadingAll) { // update
                
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_REQUEST
                });
    
                Services.GetPaymentsByReceiver(token, receiver, payer)
                .then((payments: Payment[]) => {
                    dispatch({
                        type: ActionEvents.CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_SUCCESS,
                        payload: payments
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: ActionEvents.CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_FAILURE,
                        error: error
                    });
                });
    
            } else { // load

        dispatch({
            type: ActionEvents.CASHFLOW_PAYMENTS_RECEIVED_GETALL_REQUEST
        });

        Services.GetPaymentsByReceiver(token, receiver, payer)
            .then((response) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_RECEIVED_GETALL_SUCCESS,
                    payload: response
                });
            })
            .catch((error) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_RECEIVED_GETALL_FAILURE,
                    error: error
                });
            });
        }
    },
    GetPaymentById: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_PAYMENTS_GETBYID_FAILURE,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        if(state.CashFlowPayments?.paymentsDone?.find((payment: Payment) => payment.id === id) || state.CashFlowPayments?.paymentsReceived?.some((payment: Payment) => payment.id === id)) return;  

        const currentOwner: string = (jwt_decode.jwtDecode(token) as any)?.tenantId;

        dispatch({
            type: ActionEvents.CASHFLOW_PAYMENTS_GETBYID_REQUEST,
            payload: id
        });

        Services.GetPaymentById(token, id)
            .then((response) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_GETBYID_SUCCESS,
                    tenantId: currentOwner,
                    payload: response
                });
            })
            .catch((error) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_GETBYID_FAILURE,
                    error: error
                });
            });
    },
    CreatePayment: (payment: NewPayment): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_PAYMENTS_CREATE_FAILURE,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        const currentOwner: string = (jwt_decode.jwtDecode(token) as any)?.tenantId;

        dispatch({
            type: ActionEvents.CASHFLOW_PAYMENTS_CREATE_REQUEST,
            payload: payment
        });

        Services.CreatePayment(token, payment)
            .then((response) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_CREATE_SUCCESS,
                    tenantId: currentOwner,
                    payload: response
                });
            })
            .catch((error) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_CREATE_FAILURE,
                    error: error
                });
            });

    },
    UpdatePayment: (payment: Payment): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_PAYMENTS_UPDATE_FAILURE,
                paymentId: payment.id,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        const currentOwner: string = (jwt_decode.jwtDecode(token) as any)?.tenantId;

        dispatch({
            type: ActionEvents.CASHFLOW_PAYMENTS_UPDATE_REQUEST,
            tenantId: currentOwner,
            payload: payment
        });

        Services.UpdatePayment(token, payment)
            .then((response) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_UPDATE_SUCCESS,
                    tenantId: currentOwner,
                    payload: response
                });
            })
            .catch((error) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_UPDATE_FAILURE,
                    paymentId: payment.id,
                    error: error
                });
            });

    },
    DeletePayment: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_PAYMENTS_DELETE_FAILURE,
                paymentId: id,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        const currentOwner: string = (jwt_decode.jwtDecode(token) as any)?.tenantId;

        dispatch({
            type: ActionEvents.CASHFLOW_PAYMENTS_DELETE_REQUEST,
            tenantId: currentOwner,
            payload: id
        });

        

        Services.DeletePayment(token, id)
            .then((deletedPayment: Payment) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_DELETE_SUCCESS,
                    tenantId: currentOwner,
                    payload: deletedPayment.id
                });
            })
            .catch((error) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_PAYMENTS_DELETE_FAILURE,
                    paymentId: id,
                    error: error
                });
            });

    }
};