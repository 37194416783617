import React from "react";
import { connect, useSelector } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../Utils/UserManager";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../Utils/Loaders";

const CallbackPage = (props: any) => {

  const navigate = useNavigate();

  const successCallback = (user: any) => {
    console.log(user);
    var locationURL = GetUrlPath(user.state?.returnUrl)
    navigate(locationURL || "/");
  };

  const errorCallback = (error: any) => {
    console.error(error);
  };


  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      <div>
        <Spinner />
      </div>
    </CallbackComponent>
  );
}

const GetUrlPath = (url: string): string => {
  var pathname = new URL(url).pathname;
  return pathname;
}

export default connect()(CallbackPage);