import SponsorListComponent from '../../../components/Sponsors/SponsorsList/SponsorListComponent';
import { PageHeader } from '../../../commons/component/PageHeader/PageHeader';

////i18Next
import { useTranslation } from 'react-i18next';

export const SponsorsListPage = () => {
  
  //useTranslation
  const [t] = useTranslation();

  return (
    <>
      <PageHeader title={t('sponsorsListPage.sponsorsListTitle')} />
      <div className="Section">
        <SponsorListComponent />
      </div>
    </>
  );
};
