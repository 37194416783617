import { AppThunkAction } from '../reducers';

// models
import { ErrorType } from '../../models/HttpError';
import { ContractCommissionAgent } from '../../models/Entities/Contracts/ContractCommissionAgent';
import { NewContractCommissionAgent } from '../../models/Entities/Contracts/NewContractCommissionAgent';
import { Contract } from '../../models/Entities/Contracts/Contract';

// services
import * as CommissionAgentServices from '../../services/ContractCommissionAgent.Services';
import * as ContractServices from '../../services/Contract.Services';

// actions
import * as Actions from '../actions';
import { CommissionAgent } from '../../models/Entities/Contracts/CommissionAgent';


type KnownAction =
    // contracts
    | Actions.Contract.Contract_GetById_Request_Action
    | Actions.Contract.Contract_GetById_Receive_Action
    | Actions.Contract.Contract_GetById_Fail_Action
    // commission agents
    | Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_Request_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_Response_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_Failure_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_GetById_Request_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_GetById_Response_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_GetById_Failure_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_Create_Request_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_Create_Response_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_Create_Failure_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_Update_Request_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_Update_Response_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_Update_Failure_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_ByAssociatedContractId_Request_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_ByAssociatedContractId_Response_Action
    | Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_ByAssociatedContractId_Failure_Action;

export const actionCreators = {

    // Get all contract commission agents
    GetAllContractCommissionAgents : (contractId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_GET_ALL_FAILURE',
                contractId: contractId,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        if (state.ContractCommissionAgent?.isLoadingAll || state.ContractCommissionAgent?.successLoadingAll) return;

        dispatch({ type: 'CONTRACT_COMMISSION_AGENT_GET_ALL_REQUEST', contractId: contractId });

        CommissionAgentServices.GetContractCommissionAgents(contractId, token)
        .then((concepts: ContractCommissionAgent[]) => {
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_GET_ALL_SUCCESS',
                contractId: contractId,
                contractCommissionAgents: concepts
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_GET_ALL_FAILURE',
                contractId: contractId,
                error: error
            })
        );
    },

    // Get contract commission agent by id
    GetContractCommissionAgentById : (commissionAgentId: number, contractId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        
        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_GET_BY_ID_FAILURE',
                contractCommissionAgentId: commissionAgentId,
                contractId: contractId,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        if (state.Contract?.list?.find(
            (contract: Contract) => contract.id === contractId)?.commissionAgents?.find(
                (agent: ContractCommissionAgent) => agent.id === commissionAgentId)) return;
        
        
        dispatch({ type: 'CONTRACT_COMMISSION_AGENT_GET_BY_ID_REQUEST', contractCommissionAgentId: commissionAgentId });

        CommissionAgentServices.GetContractCommissionAgentById(commissionAgentId, token)
        .then((concept: ContractCommissionAgent) => {
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_GET_BY_ID_SUCCESS',
                contractCommissionAgent: concept
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_GET_BY_ID_FAILURE',
                contractCommissionAgentId: commissionAgentId,
                contractId: contractId,
                error: error
            })
        );
    },

    // Create contract commission agent
    CreateContractCommissionAgent : (contractCommissionAgent: NewContractCommissionAgent): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;

       

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_CREATE_FAILURE',
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };
        
        dispatch({ type: 'CONTRACT_COMMISSION_AGENT_CREATE_REQUEST', contractId: contractCommissionAgent.contractId, contractCommissionAgent: contractCommissionAgent });

        CommissionAgentServices.CreateContractCommissionAgent(contractCommissionAgent, token)
        .then((agent: ContractCommissionAgent) => {
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_CREATE_SUCCESS',
                contractCommissionAgent: agent
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_CREATE_FAILURE',
                error: error
            })
        );
    },

    // Update contract commission agent
    UpdateContractCommissionAgent : (contractCommissionAgent: ContractCommissionAgent): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        
        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_UPDATE_FAILURE',
                contractId: contractCommissionAgent.contractId,
                contractCommissionAgentId: contractCommissionAgent.id,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };
        
        dispatch({ type: 'CONTRACT_COMMISSION_AGENT_UPDATE_REQUEST', contractCommissionAgent: contractCommissionAgent });

        CommissionAgentServices.UpdateContractCommissionAgent(contractCommissionAgent, token)
        .then(() => {
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_UPDATE_SUCCESS',
                contractCommissionAgent: contractCommissionAgent
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_UPDATE_FAILURE',
                contractId: contractCommissionAgent.contractId,
                contractCommissionAgentId: contractCommissionAgent.id,
                error: error
            })
        );
    },
    // get commission agents by associated contract id
    GetCommissionAgentsByAssociatedContractId : (
        associatedContractId: number
    ) : AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_GET_ALL_BY_ASSOCIATED_CONTRACT_ID_FAILURE',
                contractId: associatedContractId,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        var contract = state.Contract?.list?.find((contract: Contract) => contract.id === associatedContractId);

        if(contract === undefined) {
            dispatch({
                type: 'CONTRACT_GET_BY_ID_REQUEST_ACTION',  
                contractId: associatedContractId
            });
            ContractServices.GetContractById(associatedContractId, token).then((contract: Contract) => {
                dispatch({
                    type: 'CONTRACT_GET_BY_ID_RECEIVE_ACTION',
                    contract: contract
                });

                retrieveContractCommissionAgentsByContractId();

            }).catch((error: ErrorType) => {
                dispatch({
                    type: 'CONTRACT_GET_BY_ID_FAIL_ACTION',
                    error: error
                });
            });
        } else {
            retrieveContractCommissionAgentsByContractId();
        };

        function retrieveContractCommissionAgentsByContractId() {
            dispatch({ type: 'CONTRACT_COMMISSION_AGENT_GET_ALL_BY_ASSOCIATED_CONTRACT_ID_REQUEST', contractId: associatedContractId });

            CommissionAgentServices.GetContractCommissionAgentsByAssociatedContractId(associatedContractId, token)
                .then((concepts: ContractCommissionAgent[]) => {
                    dispatch({
                        type: 'CONTRACT_COMMISSION_AGENT_GET_ALL_BY_ASSOCIATED_CONTRACT_ID_SUCCESS',
                        contractId: associatedContractId,
                        contractCommissionAgents: concepts
                    });
                })
                .catch((error: ErrorType) => dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_GET_ALL_BY_ASSOCIATED_CONTRACT_ID_FAILURE',
                    contractId: associatedContractId,
                    error: error
                })
                );
        }
    }
} 



