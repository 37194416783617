import { IBasePickerSuggestionsProps, ITag } from '@fluentui/react';

//stores 
import * as UserStore from '../../../../redux/middleware/UserMiddleware';
import { AppState } from '../../../../redux/reducers';

// models
import { User } from '../../../../models/Entities/Authentication/User';
import { ErrorType } from '../../../../models/HttpError';

//#region Props

interface IProps {
    fieldName: string;
    isRequired?: boolean;
    UserSelected: number[] | undefined;
    disabled?: boolean;
    SelectedUsersIds: string[];
    selectionLimit: number;
    onChangeSelection?: (
        selectedPersons: User[]
    ) => void;
}

interface ConnectedProps {
  isLoading: boolean;
  isLoadingSuccessfully: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Users: User[]
  UsersOptions: string[];
}

interface MedicalServicesDispatchProps {
  GetAllUsers: typeof UserStore.ActionCreators.GetAllUsers;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.Users?.isLoadingAll,
    isLoadingSuccessfully: state.Users?.successLoadingAll,
    failOnLoading: state.Users?.failOnLoadingAll,
    error: state.Users?.error,
    Users: state.Users?.list,
    UsersOptions: state.Users?.list?.map((user: User) => user.userId) || []
});

export const mapDispatchToProps = (dispatch: any) => {

  dispatch(UserStore.ActionCreators.GetAllUsers(1, 1000, undefined));

  return {
    ...UserStore.ActionCreators
  }
};


export const GetUserByIdentifier = (users: User[], identifier: string): User | undefined => {
    return users.find((user: User) => user.userId === identifier);
}

export const GetUserBySelectedIdentifier = (users: User[], selectedUsers: string[]): User[] => {
    return users.filter((user: User) => selectedUsers.includes(user.userId)) || [];
}

export const GetAllUserByName = (users: User[], selectedUsers: string[], Name: string): User[] | undefined => {
    return users.filter((user: User) => !selectedUsers.includes(user.userId) && user.fullName?.toLowerCase()?.includes(Name.toLowerCase()));
}