import { IResult } from '../models/HttpResult';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

// models
import { ContractCommissionAgentConcept } from '../models/Entities/Contracts/ContractCommissionAgentConcept';
import { NewContractCommissionAgentConcept } from '../models/Entities/Contracts/NewContractCommissionAgentConcept';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/commissionagents/';


// Get All Contract Commission Agent Concepts by Agent Id
export const GetContractCommissionAgentConceptsByAgentId = async (agentId: number, token: string): Promise<ContractCommissionAgentConcept[]> => {

    let RequestURL: string = BaseURL + agentId + '/concepts';

    try {
        const Response: ContractCommissionAgentConcept[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionAgentConcept[]>>;
                }
            })
            .then((data: IResult<ContractCommissionAgentConcept[]>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// get contract commission agent concept by id
export const GetContractCommissionAgentConceptById = async (conceptId: number, token: string): Promise<ContractCommissionAgentConcept> => {

    let RequestURL: string = BaseURL + 'concept/s' + conceptId;

    try {
        const Response: ContractCommissionAgentConcept = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionAgentConcept>>;
                }
            })
            .then((data: IResult<ContractCommissionAgentConcept>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// create contract commission agent concept
export const CreateContractCommissionAgentConcept = async (concept: NewContractCommissionAgentConcept, token: string): Promise<ContractCommissionAgentConcept> => {

    let RequestURL: string = BaseURL + 'concepts';

    try {
        const Response: ContractCommissionAgentConcept = await fetch(RequestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(concept)
        })
        .then((response) => {
            if (!response.ok) {
                Sentry.captureException(response);
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractCommissionAgentConcept>>;
            }
        })
        .then((data: IResult<ContractCommissionAgentConcept>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// update contract commission agent concept
export const UpdateContractCommissionAgentConcept = async (concept: ContractCommissionAgentConcept, token: string): Promise<ContractCommissionAgentConcept> => {

    let RequestURL: string = BaseURL + 'concepts/';

    try {
        const Response: ContractCommissionAgentConcept = await fetch(RequestURL, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(concept)
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionAgentConcept>>;
                }
            })
            .then((data: IResult<ContractCommissionAgentConcept>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// delete contract commission agent concept
export const DeleteContractCommissionAgentConcept = async (conceptId: number, token: string): Promise<boolean> => {

    let RequestURL: string = BaseURL + 'concepts/' + conceptId;

    try {
        const Response: boolean = await fetch(RequestURL, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<boolean>;
                }
            })
            .then((data: boolean) => {
                return data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};