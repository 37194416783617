import { IconButton, IIconProps, Label, Text } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

export interface PageHeaderProps {
  title: string;
  subtitle?: string | undefined;
}

export const PageHeader = (props: PageHeaderProps) => {
  const BackIcon: IIconProps = { iconName: 'ChevronLeftSmall' };

  const history = useNavigate();

  // Event
  const handleBackClick = () => {
    history(-1);
  };

  return (
    <div className="PageHeader">
      <IconButton iconProps={BackIcon} onClick={handleBackClick} />
      <div>
        <Label>{props.title}</Label>
        {props.subtitle && <Text>{props.subtitle}</Text>}
      </div>
    </div>
  );
};
