import { connect } from 'react-redux';
import { ProtocolPractitionerItemComponent } from './ProtocolPractitioner.Item';
import { useEffect } from 'react';
import { FontIcon, IconButton, Label, Separator, TextField, Toggle } from '@fluentui/react';

// functions 
import * as ComponentFunctions from './ProtocolPractitioner.List.Functions';

//components
import PractitionerSettingsForm from '../PractitionerSettingsForm/PractitionerSettingsForm.Component';

////i18Next
import { useTranslation } from 'react-i18next';

//styles
import './ProtocolPractitioner.style.css';


const ProtocolParactitionersListComponent = (props: ComponentFunctions.Props) => {

    //useTranslation()
    const [t] = useTranslation();

    useEffect(() => {
        if (!props.Protocol)
            props.GetMedicalProtocolById(props.ProtocolId);
    }, [props.ProtocolId !== undefined]);

    useEffect(() => {
        if (props.Protocol)
            props.GetAllProtocolPractitioners(props.Protocol.id);
    }, [props.Protocol !== undefined]);

    useEffect(() => {
        props.GetAllContractsConcept(props.ContractId);
        props.GetAllContractCommissionAgents(props.ContractId);
    }, [props.ContractId !== undefined]);

    return (
        <>
            {props.Practitioners?.map((practitioner, index) =>
                <div>
                    <ProtocolPractitionerItemComponent
                        practitioner={practitioner}
                        contractId={props.ContractId}
                        GetPractiontioner={props.GetPractiontioner}
                        Protocol={props.Protocol}
                    />
                    <div className='MedicalAcctionsSector'>
                        <IconButton
                            iconProps={{ iconName: 'Settings' }}
                            title={t('protocolPractitionerlist.practitioner.settings')}
                            ariaLabel={t('protocolPractitionerlist.practitioner.settings')}
                            onClick={() => props.GetPractiontioner(practitioner.practitionerUserId)}
                        />
                    </div>
                </div>
            )}
            {props.Practitioners?.length === 0 &&
                <div className='MedicalTeamCard'>
                    <Label>{t('protocolPractitionerlist.practitioner.noPractitioners')}</Label>
                </div>
            }
        </>
    );

};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ProtocolParactitionersListComponent as any)
