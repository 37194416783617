import { Button, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, Field, Input, Label, OverlayDrawer, Spinner, Textarea } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

// components functions
import * as ComponentFunctions from './Addendum.Form.Functions';

// components
import { MessageComponent } from "../../../../commons/component/MessageComponent";
import { MessageBarType } from "@fluentui/react";
import { DateTimeInput } from "../../../../commons/component/Inputs/DateTimeInput/DateTimeInput";
import { NewContractAddendum } from "../../../../models/Entities/Contracts/NewContractAddendum";
import { ContractAddendum } from "../../../../models/Entities/Contracts/ContractAddendum";

interface FormValidation {
    success: boolean;
    errors: string[];
}

const AddendumFormComponent = (props: ComponentFunctions.Props) => {

    // form status
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    // form fields
    const [AddendumName, setAddendumName] = useState<string | undefined>();
    const [AddendumDescription, setAddendumDescription] = useState<string | undefined>();
    const [AddendumStartDate, setAddendumStartDate] = useState<Date | undefined>();
    const [AddendumEndDate, setAddendumEndDate] = useState<Date | undefined>();

    useEffect(() => {
        setAddendumName(props.CurrentAddendum?.contractAddendumName);
        setAddendumDescription(props.CurrentAddendum?.contractAddendumDescription);
        setAddendumStartDate(props.CurrentAddendum?.contractAddendumExpiryStartDate);
        setAddendumEndDate(props.CurrentAddendum?.contractAddendumExpiryEndDate);
    }, [props.isOpen === true, props.CurrentAddendum]);

    useEffect(() => {
        if (props.isUpdatedSuccessfully || props.isCreatingAddendumSuccessfully) {
            ResetForm();
            props.onClose();
        }
    }, [props.isUpdatedSuccessfully, props.isCreatingAddendumSuccessfully]);


    const HandleCancelForm = () => {
        ResetForm();
        props.onClose();
    };

    const ValidateForm = (): boolean => {

        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        if (!AddendumName || AddendumName === '') {
            FormValidation.errors.push("Debe informar un nombre de adenda");
            validationResult = false;
        }

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };
    
    const HandleSubmitForm = () => {

        setSubmittedForm(true);

        if (ValidateForm()) {
            // submit form
            if (!props.CurrentAddendum) {
                const newAddendum: NewContractAddendum = { 
                    contractId: props.ContractId,
                    contractAddendumName: AddendumName || '',
                    contractAddendumDescription: AddendumDescription || '',
                    contractAddendumExpiryStartDate: AddendumStartDate,
                    contractAddendumExpiryEndDate: AddendumEndDate                  
                };
                props.CreateContractAddendum(props.ContractId, newAddendum)
            } else {
                const updatedAddendum: ContractAddendum = {       
                    id: props.CurrentAddendum.id,
                    contractId: props.ContractId,
                    contractAddendumName: AddendumName || '',
                    contractAddendumDescription: AddendumDescription || '',
                    contractAddendumExpiryStartDate: AddendumStartDate,
                    contractAddendumExpiryEndDate: AddendumEndDate            
                };
                props.UpdateContractAddendum(props.ContractId, updatedAddendum);
            }
        } else {
            setSubmittedForm(false);
        }
    };

    // reset form
    const ResetForm = () => {
        setSubmittedForm(false);
        setFormValidationStatus(undefined);
        setAddendumName(undefined);
        setAddendumDescription(undefined);
        setAddendumStartDate(undefined);
        setAddendumEndDate(undefined);
    };

    const Footer = () => {
        return (
            <DrawerFooter>
                <Button onClick={HandleCancelForm} disabled={submittedForm}>Cancelar</Button>
                <Button appearance="primary" onClick={HandleSubmitForm} disabled={submittedForm}>{(props.isCreatingAddendum || props.isUpdating) && <><Spinner size="extra-tiny" />&nbsp;</>}Guardar</Button>
            </DrawerFooter>
        );
    };

    const FormFields = (
        <>
            <Field label={"Nombre de adenda"} required>
                <Input
                    type="text"
                    id={'txt_addendumname'}
                    value={AddendumName}
                    required={true}
                    maxLength={200}
                    onChange={(e) => setAddendumName(e.currentTarget.value)}
                    onFocus={(e) => e.currentTarget.select()}
                />
            </Field>
            <Field label={"Descripcion"}>
                <Textarea
                    id={'txt_addendumdescription'}
                    value={AddendumDescription}
                    onChange={(e) => setAddendumDescription(e.currentTarget.value)}
                    onFocus={(e) => e.currentTarget.select()}
                />
            </Field>
            <DateTimeInput label={"Vigencia desde"} placeHolder={"dd/mm/yyyy"} selectedDate={AddendumStartDate ? new Date(AddendumStartDate) : undefined} onSelectDate={(date: Date | undefined) => setAddendumStartDate(date) } isSubmitted={submittedForm} isRequired={false} errorMessage={undefined} />
            <DateTimeInput label={"Vigencia hasta"} placeHolder={"dd/mm/yyy"} selectedDate={AddendumEndDate ? new Date(AddendumEndDate): undefined} onSelectDate={(date: Date | undefined) => setAddendumEndDate(date) } isSubmitted={submittedForm} isRequired={false} errorMessage={undefined} />
        </>
    );

    return (
        <div>
            <OverlayDrawer
                position={'end'}
                open={props.isOpen}
            >
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label="Close"
                                icon={<Dismiss24Regular />}
                                onClick={() => HandleCancelForm()}
                            />
                        }
                    >
                        Información de Adenda
                    </DrawerHeaderTitle>
                    {FormValidationStatus?.success === false && <MessageComponent message={"Verifique la información suministrada"} type={MessageBarType.error} itemList={FormValidationStatus?.errors} />}
                    {(props.failOnCreatingAddendum === true || props.failOnUpdating) && <MessageComponent message={(props.errorOnCreatingAddendum || props.errorOnUpdating)?.ErrorMessage || 'Ocurrio un error inesperado'} type={MessageBarType.error} itemList={(props.errorOnCreatingAddendum || props.errorOnUpdating)?.Errors} />}
                </DrawerHeader>

                <DrawerBody className='ScrollShadow'>
                    {props.AddendumId ? props.CurrentAddendum && FormFields : FormFields}
                </DrawerBody>
                <Footer />
            </OverlayDrawer>
        </div>
    );

};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(AddendumFormComponent as any);