import { Action, Reducer } from 'redux';


// actions
import * as Actions from '../actions/ContractStageCycle.Actions';

// functions
import * as Functions from '../../functions/common';
import { ContractStageCycleState, ContractStageCycleUnloadState } from '../states/ContractStageCycleState';

type KnownAction = 
| Actions.ContractStageCycle_GetAllBycontractId_Request_Action
| Actions.ContractStageCycle_GetAllBycontractId_Receive_Action
| Actions.ContractStageCycle_GetAllBycontractId_Fail_Action
| Actions.ContractStageCycle_UpdateAllByContractId_Request_Action
| Actions.ContractStageCycle_UpdateAllByContractId_Receive_Action
| Actions.ContractStageCycle_UpdateAllByContractId_Fail_Action
| Actions.ContractStageCycle_GetById_Request_Action
| Actions.ContractStageCycle_GetById_Receive_Action
| Actions.ContractStageCycle_GetById_Fail_Action
| Actions.ContractStageCycle_Add_Request_Action
| Actions.ContractStageCycle_Add_Receive_Action
| Actions.ContractStageCycle_Add_Fail_Action
| Actions.ContractStageCycle_Update_Request_Action
| Actions.ContractStageCycle_Update_Receive_Action
| Actions.ContractStageCycle_Update_Fail_Action
| Actions.ContractStageCycle_Delete_Request_Action
| Actions.ContractStageCycle_Delete_Receive_Action
| Actions.ContractStageCycle_Delete_Fail_Action;

export const ContractStageCycleReducer: Reducer<ContractStageCycleState> = (
    state: ContractStageCycleState | undefined, incomingAction: Action): ContractStageCycleState => {

        if (state === undefined) {
            return ContractStageCycleUnloadState as ContractStageCycleState;
        }

        const action = incomingAction as KnownAction;

        switch (action.type) {
            case 'CONTRACTSTAGECYCLE_GET_ALL_BY_CONTRACTID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingAll: true,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    error: undefined
                };
            case 'CONTRACTSTAGECYCLE_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: true,
                    failOnLoadingAll: false,
                    error: undefined,
                };
            case 'CONTRACTSTAGECYCLE_GET_ALL_BY_CONTRACTID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: true,
                    error: action.error
                };
            case 'CONTRACTSTAGECYCLE_UPDATE_ALL_BY_CONTRACTID_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingAll: true,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: false,
                    error: undefined
                };
            case 'CONTRACTSTAGECYCLE_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: true,
                    FailUpdatingAll: false,
                    error: undefined,
                };
            case 'CONTRACTSTAGECYCLE_UPDATE_ALL_BY_CONTRACTID_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: true,
                    error: action.error
                };
            case 'CONTRACTSTAGECYCLE_GET_BY_ID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingOne: true,
                    successLoadingOne: false,
                    failOnLoadingOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGECYCLE_GET_BY_ID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: true,
                    failOnLoadingOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGECYCLE_GET_BY_ID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: false,
                    failOnLoadingOne: true,
                    error: action.error
                };
            case 'CONTRACTSTAGECYCLE_ADD_REQUEST_ACTION':
                return {
                    ...state,
                    isAddingNewOne: true,
                    successAddingNewOne: false,
                    failOnAddingNewOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGECYCLE_ADD_RECEIVE_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: true,
                    failOnAddingNewOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGECYCLE_ADD_FAIL_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: false,
                    failOnAddingNewOne: true,
                    error: action.error
                };
            case 'CONTRACTSTAGECYCLE_UPDATE_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGECYCLE_UPDATE_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: true,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGECYCLE_UPDATE_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: true,
                    error: action.error
                };
            case 'CONTRACTSTAGECYCLE_DELETE_REQUEST_ACTION':
                return {
                    ...state,
                    isDeletingOne: true,
                    successDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGECYCLE_DELETE_RECEIVE_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: true,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGECYCLE_DELETE_FAIL_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: false,
                    failOnDeletingOne: true,
                    error: action.error
                };
            default:
                return state;
        }
}
