import { AppThunkAction } from '../reducers';

// Actions
import * as Actions from '../actions';

// Services
import { Languaje } from '../../models/Entities/Settings/Languajes';
import { GetAllUsers } from '../../services/User.Services';


type KnownAction = 
  | Actions.UserSettings.UserSettings_Languaje_Request_Action
  | Actions.UserSettings.UserSettings_Languaje_Receive_Action
  | Actions.UserSettings.UserSettings_Languaje_Fail_Action
  | Actions.UserSettings.UserSettings_Languaje_Change_Request_Action
  | Actions.UserSettings.UserSettings_Languaje_Change_Receive_Action
  | Actions.UserSettings.UserSettings_Languaje_Change_Fail_Action
  | Actions.Users.UsersGetAllRequestAction
  | Actions.Users.UsersGetAllSuccessAction
  | Actions.Users.UsersGetAllFailureAction;

 export const ActionCreators = {
    SetUserLanguaje:
      (newLanguaje: Languaje): AppThunkAction<KnownAction> => async (dispatch: any) => {
         dispatch({
           type: 'USER_SETTINGS_LANGUAJE_CHANGE_REQUEST_ACTION',
           Language: newLanguaje
         });
  
        // esto tiene que ser reemplazado por un servicio para persistir la informacion de lenguaje
        dispatch({
          type: 'USER_SETTINGS_LANGUAJE_CHANGE_RECEIVE_ACTION',
          Language: newLanguaje
        });
      },
    GetAllUsers: 
      (pageNumber: number, pageSize: number, searchText: string | undefined): AppThunkAction<KnownAction> => async (dispatch: any, getState) => {
        
        var state = getState();
        var token: string;

        if (state.oidc?.user?.access_token === undefined) {
          dispatch({
            type: 'USERS_GETALL_FAILURE',
            error: {
              ErrorCode: 401,
              ErrorMessage: 'Not authorized',
              Errors: []
            }
          });
          return;
        } else {
          token = state.oidc.user.access_token;
        }
        
        dispatch({
          type: 'USERS_GETALL_REQUEST'
        });
  
        try {
          const users = await GetAllUsers(pageNumber, pageSize, searchText, token);
  
          dispatch({
            type: 'USERS_GETALL_SUCCESS',
            users: users
          });
        } catch (error) {
          dispatch({
            type: 'USERS_GETALL_FAILURE',
            error: error
          });
        }
      }
}