import React from 'react';
import { ITag, Label, TagPicker } from '@fluentui/react';
import { useEffect, useState } from 'react';

// own functions
import * as ownFunctions from './ProtocolPatientSelector.Functions';

// models 
import { ProtocolPatient } from '../../../../models/Entities/Protocols/ProtocolPatient';
import { connect } from 'react-redux';


export const ProtocolPatientSelectorComponent = (
  props: ownFunctions.Props
) => {
  
  const onChangeIdTextFieldValue = (items?: ITag[] | undefined) => {
    let ProtocolPatientComboSelected: ProtocolPatient[] = [];
    if (items && items.length > 0) {

      items.forEach(async (item: ITag) => {
        const ProtocolPatientNumberInProtocol = String(item.key);
        const patient: ProtocolPatient = ownFunctions.GetProtocolPatientById(item.key.toString(), props.ProtocolPatients);

        ProtocolPatientComboSelected = [
          ...ProtocolPatientComboSelected,
          patient
        ];
        setInternalProtocolPatientsSelected(ProtocolPatientComboSelected);
        setProtocolPatientsOptionsSelected(items);

        console.log('paciente seleccionado', ProtocolPatientComboSelected);

        props.onSelectedProtocolPatient && props.onSelectedProtocolPatient(ProtocolPatientComboSelected);
      });
    } else {
        setInternalProtocolPatientsSelected([]);
        setProtocolPatientsOptionsSelected([]);
        props.onSelectedProtocolPatient && props.onSelectedProtocolPatient([]);
    }
  };

  const [ProtocolPatientsOptions, setProtocolPatientsOptions] = useState<ITag[]>();
  const [ProtocolPatientsOptionsSelected, setProtocolPatientsOptionsSelected] =
    useState<ITag[]>();

  const [InternalProtocolPatientsSelected, setInternalProtocolPatientsSelected] =
    useState<ProtocolPatient[]>([]);

  useEffect(() => { 
    props.GetProtocolPatients(props.ProtocolId, '');
  }, []);

  useEffect(() => {
      setProtocolPatientsOptions(ownFunctions.GetProtocolPatientOptions(props.ProtocolId, props.ProtocolPatients));
  }, [props.ProtocolPatients?.length > 0]);

  useEffect(() => {
    props.onSelectedProtocolPatient(InternalProtocolPatientsSelected);
  }, [InternalProtocolPatientsSelected]);

  useEffect(() => {
    if (props.ProtocolPatientsSelectedNumber && ProtocolPatientsOptions) 

      //console.log('props.ProtocolPatientsSelected 24', props.ProtocolPatientsSelectedNumber[0]);

      //var auxProtocolPatientSelected = 

      //console.log('auxProtocolPatientSelected', auxProtocolPatientSelected?.length);
      //console.log('props.ProtocolPatientsSelected', props.ProtocolPatientsSelectedNumber?.length);

      setProtocolPatientsOptionsSelected(ProtocolPatientsOptions?.filter(
        (option: ITag) =>
          option.key ===
          props.ProtocolPatientsSelectedNumber?.find((id: string) => id === option.key)
      ));
      
      var protocolPatient = props.ProtocolPatientsSelectedNumber?.length && ownFunctions.GetProtocolPatientById(props.ProtocolPatientsSelectedNumber[0], props.ProtocolPatients);

      setInternalProtocolPatientsSelected(protocolPatient ? [protocolPatient] : []);

      props.onSelectedProtocolPatient && protocolPatient && props.onSelectedProtocolPatient([protocolPatient]);
      
  }, [ProtocolPatientsOptions]);

  const filterSuggestedProtocolPatientsServices = async (
    filterText: string,
    tagList: ITag[] | undefined
  ): Promise<ITag[]> => {
    return filterText
      ? ProtocolPatientsOptions?.filter(
          (tag) =>
            tag.name.toLowerCase().includes(filterText.toLowerCase()) &&
            !ownFunctions.listContainsTagList(tag, tagList)
        ) || []
      : [];
  };

  return (
    <>
      <Label htmlFor="SnomedOption">Seleccione un paciente</Label>
      <TagPicker
        removeButtonAriaLabel="Eliminar"
        selectionAriaLabel="Seleccione un concepto"
        onResolveSuggestions={filterSuggestedProtocolPatientsServices}
        onEmptyResolveSuggestions={(selectedItems?: ITag[] | undefined) => ProtocolPatientsOptions || []}
        getTextFromItem={ownFunctions.getTextFromItem}
        pickerSuggestionsProps={ownFunctions.pickerSuggestionsProps}
        itemLimit={props.MultiselectEnable ? undefined : 1}
        onChange={onChangeIdTextFieldValue}
        pickerCalloutProps={{ doNotLayer: false }}
        inputProps={{
          id: 'ConceptsOption'
        }}
        selectedItems={ProtocolPatientsOptionsSelected}
      />
      {props.noteText && <small>{props.noteText}</small>}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(ProtocolPatientSelectorComponent as any);
