import { Procedure } from '../models/Entities/Procedures/Procedure';
import { IResult } from '../models/HttpResult';


// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'procedures/';

export const GetProcedures = async (
  searchTerm: string, token: string
): Promise<Procedure[]> => {
  const RequestURL: string = BaseURL + searchTerm;

  try {
    const Response: Procedure[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Procedure[]>>;
        }
      })
      .then((data: IResult<Procedure[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
