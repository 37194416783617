import { NewTenantPriceList } from '../models/Entities/TenantPriceLists/NewTenantPriceList';
import { IResult } from '../models/HttpResult';
import { TenantPriceList } from '../models/Entities/TenantPriceLists/TenantPriceList';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'tenant/';

export const GetTenantPriceList = async (token: string): Promise<TenantPriceList[]> => {
  const RequestURL: string = BaseURL + 'aggregator/pricelists';

  try {
    const Response: TenantPriceList[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<TenantPriceList[]>>;
        }
      })
      .then((data: IResult<TenantPriceList[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetTenantPriceListById = async (
  TenantPriceListId: number
, token: string): Promise<TenantPriceList> => {
  const RequestURL: string =
    BaseURL + 'aggregator/pricelists/' + TenantPriceListId;

  try {
    const Response: TenantPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<TenantPriceList>>;
        }
      })
      .then((data: IResult<TenantPriceList>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const CreateTenantPriceList = async (
  newTenantPriceList: NewTenantPriceList
, token: string): Promise<TenantPriceList> => {
  const RequestURL: string = BaseURL + 'aggregator/pricelists';

  try {
    const Response: TenantPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(newTenantPriceList)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<TenantPriceList>>;
        }
      })
      .then((data: IResult<TenantPriceList>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const SetPriceListStartValidityDate = async (
  TenantPriceListId: number,
  StartDate: Date
, token: string): Promise<TenantPriceList> => {
  const RequestURL: string =
    BaseURL + 'pricelists/' + TenantPriceListId + '/validitystart';

  try {
    const Response: TenantPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(StartDate)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<TenantPriceList>>;
        }
      })
      .then((data: IResult<TenantPriceList>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const SetPriceListEndValidityDate = async (
  TenantPriceListId: number,
  EndDate: Date
, token: string): Promise<TenantPriceList> => {
  const RequestURL: string =
    BaseURL + 'pricelists/' + TenantPriceListId + '/validityend';

  try {
    const Response: TenantPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(EndDate)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<TenantPriceList>>;
        }
      })
      .then((data: IResult<TenantPriceList>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const UpdateTenantPriceList = async (
  TenantPriceList: TenantPriceList
, token: string): Promise<TenantPriceList> => {
  const RequestURL: string = BaseURL + 'aggregator/pricelists';

  try {
    const Response: TenantPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(TenantPriceList)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<TenantPriceList>>;
        }
      })
      .then((data: IResult<TenantPriceList>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DeleteTenantPriceList = async (
  TenantPriceListId: number
, token: string): Promise<void> => {
  const RequestURL: string = BaseURL + 'pricelists/' + TenantPriceListId;

  try {
    await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<void>>;
        }
      })
      .then((data: IResult<void>) => {
        return TenantPriceListId;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
