import { AppState } from "../../../../redux/reducers";

// stores
import * as ProtocolStore from '../../../../redux/middleware/MedicalProtocolMiddleware';
import * as ContractConceptStore from '../../../../redux/middleware/ContractConceptMiddleware';
import * as ContractCommisionAgentStore from '../../../../redux/middleware/ContractCommissionAgentMiddleware';
import * as ContrectStore from '../../../../redux/middleware/ContractMiddleware';
import * as MedicalServicesStore from '../../../../redux/middleware/MedicalServicesMiddleware';
import * as ContractCommisionAgentConceptStore from '../../../../redux/middleware/ContractCommissionAgentConceptMiddleware';

// models
import { ContractCommissionAgentConcept } from "../../../../models/Entities/Contracts/ContractCommissionAgentConcept";
import { ErrorType } from "../../../../models/HttpError";
import { Protocol } from "../../../../models/Entities/Protocols/Protocol";
import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractConcept } from "../../../../models/Entities/Contracts/ContractConcept";
import { ContractCommissionAgent } from "../../../../models/Entities/Contracts/ContractCommissionAgent";
import { IValueOriginOption } from "../../../Contracts/Concept/ConceptForm/ConceptForm.Functions";
import { AvailableMedicalService } from "../../../../models/Entities/MedicalServices/AvailableMedicalService";
import { NewContractCommissionAgentConcept } from "../../../../models/Entities/Contracts/NewContractCommissionAgentConcept";
import { CreateConcept } from "../../../../services/ContractConcept.Services";

interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    CommissionAgentId: number | undefined;
    CommissionAgentConceptId: number | undefined;
    ContractId: number;
};

interface ConnectedProps {
    isSaving: boolean;
    SavingSuccessfully: boolean | undefined;
    FailOnSaving: boolean;
    ErrorOnSaving: ErrorType | undefined;
    Contract: Contract;
    CommissionAgent: ContractCommissionAgent;
    CommissionAgentConcept: ContractCommissionAgentConcept;
    Concept: ContractConcept;
    MedicalService: AvailableMedicalService;
};

interface MedicalServicesDispatchProps {
    // GetAllContractsConcept: typeof ContractConceptStore.actionCreators.GetAllContractsConcept;
    // GetAllContractCommissionAgents: typeof ContractCommisionAgentStore.actionCreators.GetAllContractCommissionAgents;
    // GetAllContracts: typeof ContrectStore.actionCreators.GetAllContracts;

    //CreateConcept: (newConcept: NewContractCommissionAgentConcept) => void;
    //UpdateConcept: (concept: ContractCommissionAgentConcept) => void;

    // commission agent concept
    CreateContractCommissionAgentConcept: typeof ContractCommisionAgentConceptStore.actionCreators.CreateContractCommissionAgentConcept;
    UpdateContractCommissionAgentConcept: typeof ContractCommisionAgentConceptStore.actionCreators.UpdateContractCommissionAgentConcept;
};


export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    var CurrentContract = state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId);
    var CurrentCommissionAgent = CurrentContract?.commissionAgents?.find((commissionAgent: ContractCommissionAgent) => commissionAgent.id === ownProps.CommissionAgentId);
    var CurrentCommissionAgentConcept = CurrentCommissionAgent?.Concepts?.find((commissionAgentConcept: ContractCommissionAgentConcept) => commissionAgentConcept.id === ownProps.CommissionAgentConceptId);
    var CurrentMedicalService = state.MedicalServices?.list?.find((medicalService) => medicalService.id === CurrentCommissionAgentConcept?.medicalServiceId);
        
    return {
        isSaving: state.ContractCommissionAgentConcept?.isAddingNewOne || state.ContractCommissionAgentConcept?.isUpdatingOne,
        SavingSuccessfully: state.ContractCommissionAgentConcept?.successAddingNewOne || state.ContractCommissionAgentConcept?.successUpdatingOne,
        FailOnSaving: state.ContractCommissionAgentConcept?.failOnAddingNewOne || state.ContractCommissionAgentConcept?.failOnUpdatingOne,
        ErrorOnSaving: state.ContractCommissionAgentConcept?.error,
        Contract: CurrentContract,
        CommissionAgent: CurrentCommissionAgent,
        CommissionAgentConcept: CurrentCommissionAgentConcept,
        MedicalService: CurrentMedicalService
    };
};

export const mapDispatchToProps = {
    ...ProtocolStore.actionCreators,
    ...ContractConceptStore.actionCreators,
    ...ContractCommisionAgentStore.actionCreators,
    ...ContrectStore.actionCreators,
    ...MedicalServicesStore.actionCreators,
    ...ContractCommisionAgentConceptStore.actionCreators
};

export const ValueOriginOptions: IValueOriginOption[] = [
    { key: '1', text: 'ValueOriginOptions.FixedValue', isFixedPrice: true, isPercentage: false, isPriceList: false, isDefinedByVisit: false, isCostValue: false },
    // { key: '2', text: 'ValueOriginOptions.ValueByCost', isFixedPrice: false, isPercentage: false, isPriceList: false, isDefinedByVisit: false, isCostValue: true },
    { key: '3', text: 'ValueOriginOptions.ValueByPercentage', isFixedPrice: false, isPercentage: true, isPriceList: false, isDefinedByVisit: false, isCostValue: false },
    { key: '4', text: 'ValueOriginOptions.ValueByPriceList', isFixedPrice: false, isPercentage: false, isPriceList: true, isDefinedByVisit: false, isCostValue: false },
    // { key: '5', text: 'ValueOriginOptions.ValueByStage', isFixedPrice: false, isPercentage: false, isPriceList: false, isDefinedByVisit: true, isCostValue: false }
];

export const GetValueOriginOptionByKey = (key: number): IValueOriginOption => {
    return ValueOriginOptions.find((option) => option.key === key.toString()) || ValueOriginOptions[0];
}