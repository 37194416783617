//#region Props

import { ErrorType } from "../../../../models/HttpError";
import { AppState } from "../../../../redux/reducers";

// stores
import * as ContractsAccessStore from "../../../../redux/middleware/ContractAccess.Middleware";

// models
import { ContractAccess } from "../../../../models/Entities/Contracts/ContractAccess";
import { Contract } from "../../../../models/Entities/Contracts/Contract";

interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    ContractAccessId: number | undefined;
    ContractId: number;
    SelectedUsersIds?: string[];
}

interface ConnectedProps {
    isLoading: boolean;
    isLoadingSuccessfully: boolean | undefined;
    failOnLoading: boolean;
    isSaving: boolean;
    isSavingSuccessfully: boolean;
    failOnSaving: boolean;
    error: ErrorType | undefined;
    Contract: Contract | undefined;
    CurrentAccess: ContractAccess | undefined;
}

interface MedicalServicesDispatchProps {
    GetContractAccessById: typeof ContractsAccessStore.actionCreators.GetContractAccessById;
    CreateContractAccess: typeof ContractsAccessStore.actionCreators.CreateContractAccess;
    UpdateContractAccess: typeof ContractsAccessStore.actionCreators.UpdateContractAccess;
    
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const currentContract: Contract | undefined = state.Contract?.list?.find(c => c.id === ownProps.ContractId);

    return {
        isLoading: state.ContractAccess?.isLoadingOne,
        isLoadingSuccessfully: state.ContractAccess?.successLoadingOne,
        failOnLoading: state.ContractAccess?.failOnLoadingOne,
        error: state.ContractAccess?.error,
        isSaving: state.ContractAccess?.isAddingNewOne,
        isSavingSuccessfully: state.ContractAccess?.successAddingNewOne,
        failOnSaving: state.ContractAccess?.failOnAddingNewOne || state.ContractAccess?.failOnUpdatingOne,
        Contract: currentContract,
        CurrentAccess: currentContract?.access?.find(a => a.id === ownProps.ContractAccessId)
    }
};

export const mapDispatchToProps = {
    ...ContractsAccessStore.actionCreators
};

//#endregion Props