import { IIconProps } from "@fluentui/react";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";
import { ErrorType } from "../../../models/HttpError";

//services 
import * as ContractServices from '../../../services/Contract.Services';

// stores
import * as ContractStore from '../../../redux/middleware/ContractMiddleware';
import * as PractitionerStore from '../../../redux/middleware/PractitionerMiddleware';
import * as PractitionerContractStore from '../../../redux/middleware/PractitionerContractsMiddleware'

//State
import { AppState } from "../../../redux/reducers";
import { Contract } from "../../../models/Entities/Contracts/Contract";
import { Practitioner } from "../../../models/Entities/Practitioners/Practitioner";
import { ContractPractitionerAssociation } from "../../../models/Entities/Contracts/ContractPractitionerAssociation";

//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };

interface IProps {
  PractitionerId: string;
 }

interface ConnectedProps {
  isLoading: boolean;
  Loaded: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Practitioner: Practitioner | undefined;
  Practitioners: Practitioner[];
  ContractAssociated: ContractPractitionerAssociation | undefined;
  Contract: Contract | undefined;
}

interface MedicalServicesDispatchProps {
  getAllPractitionerContracts: typeof PractitionerContractStore.ActionCreators.getAllPractitionerContracts;
  addPractitionerContract: typeof PractitionerContractStore.ActionCreators.addPractitionerContract;
  deletePractitionerContract: typeof PractitionerContractStore.ActionCreators.deletePractitionerContract;
  GetContractById: typeof ContractStore.actionCreators.GetContractById;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.PractitionerContracts?.isLoadingAll,
  Loaded: state.PractitionerContracts?.successLoadingAll,
  failOnLoading: state.PractitionerContracts?.failOnLoadingAll,
  isDeleting: state.PractitionerContracts?.isDeletingOne,
  isDeletedSuccessfully: state.PractitionerContracts?.successDeletingOne,
  failOnDeleting: state.PractitionerContracts?.failOnDeletingOne,
  error: state.PractitionerContracts?.error,
  Practitioner: state.Practitioner?.list?.find((item: Practitioner) => item.userId === ownProps.PractitionerId),
  Practitioners: state.Practitioner?.list,
  ContractAssociated: state.Practitioner?.list.find((item: Practitioner) => item.userId === ownProps.PractitionerId)?.ContractAssociation,
  Contract: state.Contract?.list?.find((item: Contract) => item.id === state.Practitioner?.list.find((item: Practitioner) => item.userId === ownProps.PractitionerId)?.ContractAssociation?.contractId),
});

export const mapDispatchToProps = {
  ...ContractStore.actionCreators,
  ...PractitionerStore.actionCreators,
  ...PractitionerContractStore.ActionCreators
};


//contract chek associations
//export const CheckContractAssociation = async (contractId: number): Promise<string | null | undefined> => await ContractServices.CheckPractitionerContractAssociation(contractId);


// contracts functions 
export const GetContractStatusDescripcion = (status: number | undefined) => {
  switch (status) {
      case undefined:
          return "No definido";
      case 1:
          return "Activo";
      case 2:
          return "Inactivo";
      case 3:
          return "suspendido";
      case 4:
          return "Expirado";
      case 5:
          return "Cancelado";
      case 6:	
          return "Terminado";
      default:
      return "No definido";
  }
};

