import { AppThunkAction } from '../reducers';
import { ErrorType } from '../../models/HttpError';
import { NewMedicalInsuranceAgreement } from '../../models/Entities/MedicalInsuranceAgreements/NewMedicalInsuranceAgreement';
import { MedicalInsuranceAgreement } from '../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';
import { MedicalInsuranceAgreementsPriceList } from '../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreementsPriceList';

// services
import * as Services from '../../services/MedicalInsuranceAgreements.Services';

// actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementsGetAllByMedicalInsuranceId_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementsGetAllByMedicalInsuranceId_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementsGetAllByMedicalInsuranceId_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementGetById_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementGetById_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementGetById_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAdd_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAdd_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAdd_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementUpdate_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementUpdate_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementUpdate_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDelete_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDelete_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDelete_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAddPriceList_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAddPriceList_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementAddPriceList_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementClonePriceList_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementClonePriceList_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementClonePriceList_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDeletePriceList_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDeletePriceList_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDeletePriceList_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementDeletePriceList_Reset_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateStart_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateStart_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateStart_Fail_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateEnd_Request_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateEnd_Receive_Action
  | Actions.MedicalInsuranceAgreements.MedicalInsuranceAgreementSetPriceListValidityDateEnd_Fail_Action;

export const actionCreators = {
  GetAll:
    (
      MedicalInsuranceId: number,
      pageNumber: number,
      pageSize: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_AGREEMENTS_GET_ALL_BY_MEDICAL_INSURANCE_ID_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_AGREEMENTS_GET_ALL_BY_MEDICAL_INSURANCE_ID_REQUEST_ACTION',
        medicalInsuranceId: MedicalInsuranceId
      });

      Services.GetAgreementsByMedicalInsuranceId(
        MedicalInsuranceId,
        pageNumber,
        pageSize,
        undefined,
        token
      )
        .then((agreements: MedicalInsuranceAgreement[]) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENTS_GET_ALL_BY_MEDICAL_INSURANCE_ID_RECEIVE_ACTION',
            medicalInsuranceAgreementList: agreements
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENTS_GET_ALL_BY_MEDICAL_INSURANCE_ID_FAIL_ACTION',
            error: error
          })
        );
    },
  GetAgreementById:
    (Id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_AGREEMENT_GET_BY_ID_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_AGREEMENT_GET_BY_ID_REQUEST_ACTION',
        MedicalInsuranceAgreementId: Id
      });

      Services.GetAgreementById(Id, token)
        .then((agreement: MedicalInsuranceAgreement) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_GET_BY_ID_RECEIVE_ACTION',
            MedicalInsuranceAgreement: agreement
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_GET_BY_ID_FAIL_ACTION',
            error: error
          })
        );
    },
  Add:
    (newAgreement: NewMedicalInsuranceAgreement): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_AGREEMENT_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_AGREEMENT_ADD_REQUEST_ACTION',
        NewMedicalInsuranceAgreement: newAgreement
      });

      Services.AddMedicalInsuranceAgreement(newAgreement, token)
        .then((agreement: MedicalInsuranceAgreement) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_ADD_RECEIVE_ACTION',
            MedicalInsuranceAgreement: agreement
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_ADD_FAIL_ACTION',
            error: error
          })
        );
    },
  Save:
    (
      updatedAgreement: MedicalInsuranceAgreement
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_AGREEMENT_UPDATE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_AGREEMENT_UPDATE_REQUEST_ACTION',
        MedicalInsuranceAgreement: updatedAgreement
      });

      Services.UpdateMedicalInsuranceAgreement(updatedAgreement, token)
        .then((agreement: MedicalInsuranceAgreement) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_UPDATE_RECEIVE_ACTION',
            MedicalInsuranceAgreement: agreement
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_UPDATE_FAIL_ACTION',
            error: error
          })
        );
    },
  AddPriceList:
    (
      agreementId: number,
      comments: string,
      listName: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_AGREEMENT_ADD_PRICE_LIST_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_AGREEMENT_ADD_PRICE_LIST_REQUEST_ACTION',
        MedicalInsuranceAgreementId: agreementId,
        NewPriceList: { comments: comments, listName: listName }
      });
      Services.AddPriceListToAgreement(agreementId, comments, listName, token)
        .then((list: MedicalInsuranceAgreementsPriceList) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_ADD_PRICE_LIST_RECEIVE_ACTION',
            MedicalInsuranceAgreementsPriceList: list
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_ADD_PRICE_LIST_FAIL_ACTION',
            error: error
          });
        });
    },
  ClonePriceList:
    (AgreementPriceListId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_AGREEMENT_CLONE_PRICE_LIST_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_AGREEMENT_CLONE_PRICE_LIST_REQUEST_ACTION',
        MedicalInsuranceAgreementPriceListId: AgreementPriceListId
      });
      Services.ClonePriceListToAgreement(AgreementPriceListId, token)
        .then((list: MedicalInsuranceAgreementsPriceList) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_CLONE_PRICE_LIST_RECEIVE_ACTION',
            MedicalInsuranceAgreementPriceList: list
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_CLONE_PRICE_LIST_FAIL_ACTION',
            error: error
          });
        });
    },
  SetPriceListStartValidity:
    (
      AgreementPriceList: MedicalInsuranceAgreementsPriceList,
      StartDate: Date
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_START_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };


      dispatch({
        type: 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_START_REQUEST_ACTION',
        MedicalInsuranceAgreementPriceListId: AgreementPriceList.id,
        DateStart: StartDate
      });

      Services.SetPriceListStartValidityDate(AgreementPriceList.id, StartDate, token)
        .then((PriceList: MedicalInsuranceAgreementsPriceList) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_START_RECEIVE_ACTION',
            MedicalInsuranceAgreementsPriceList: PriceList
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_START_FAIL_ACTION',
            error: error
          })
        );
    },
  SetPriceListEndValidity:
    (
      AgreementPriceList: MedicalInsuranceAgreementsPriceList,
      EndDate: Date
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_END_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_END_REQUEST_ACTION',
        MedicalInsuranceAgreementPriceListId: AgreementPriceList.id,
        DateEnd: EndDate
      });

      Services.SetPriceListEndValidityDate(AgreementPriceList.id, EndDate, token)
        .then((PriceList: MedicalInsuranceAgreementsPriceList) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_END_RECEIVE_ACTION',
            MedicalInsuranceAgreementsPriceList: PriceList
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_SET_PRICE_LIST_VALIDITY_DATE_END_FAIL_ACTION',
            error: error
          })
        );
    },
  DeletePriceListById:
    (
      AgreementId: number,
      AgreementPriceListId: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_AGREEMENT_DELETE_PRICE_LIST_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };


      dispatch({
        type: 'MEDICAL_INSURANCE_AGREEMENT_DELETE_PRICE_LIST_REQUEST_ACTION',
        AgreementId: AgreementId,
        MedicalInsuranceAgreementPriceListId: AgreementPriceListId
      });

      Services.DeletePriceListFromAgreement(AgreementPriceListId, token)
        .then(() => {
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_DELETE_PRICE_LIST_RECEIVE_ACTION',
            AgreementId: Number(AgreementId),
            MedicalInsuranceAgreementPriceListId: AgreementPriceListId
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_AGREEMENT_DELETE_PRICE_LIST_FAIL_ACTION',
            error: error
          })
        );
    },
  ResetPriceListDeleteState:
    (): AppThunkAction<KnownAction> => async (dispatch) => {
      dispatch({
        type: 'MEDICAL_INSURANCE_AGREEMENT_DELETE_PRICE_LIST_RESET_ACTION'
      });
    }
};
