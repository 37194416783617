import { Sponsor } from '../../models/Entities/Sponsor/Sponsor';
import { CommonState } from '../common/state/commonState';

export interface SponsorState extends CommonState<Sponsor> {

  // sync
  isSyncing: boolean;
  isSyncingSuccess: boolean | undefined;
  FailSyncing: boolean;

  // contracts
  isLoadingAllContracts: boolean;
  isLoadingAllContractsSuccess: boolean | undefined;
  FailGettingAllContracts: boolean;

  // contract association
  isAssociatingContract: boolean;
  isAssociatingContractSuccess: boolean | undefined;
  FailAssociatingContract: boolean;

  // contract disassociation
  isDisassociatingContract: boolean;
  isDisassociatingContractSuccess: boolean | undefined;
  FailDisassociatingContract: boolean;


}

export const SponsorUnloadState: any = {

  // sync
  isSyncing: false,
  isSyncingSuccess: undefined,
  FailSyncing: false,

  // loading all
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  FailGettingAll: false,

  // loading one
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  FailGettingOne: false,

  // saving
  isSaving: false,
  isSavingSuccess: undefined,
  FailSaving: false,

  // adding
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,

  isFiltered: false,
  filterCriteria: undefined,

  list: [],
  Selected: undefined,
  Error: undefined,

  // contracts
  isLoadingAllContracts: false,
  isLoadingAllContractsSuccess: undefined,
  FailGettingAllContracts: false,

  // contract association
  isAssociatingContract: false,
  isAssociatingContractSuccess: undefined,
  FailAssociatingContract: false,

  // contract disassociation
  isDisassociatingContract: false,
  isDisassociatingContractSuccess: undefined,
  FailDisassociatingContract: false
};
