import { AppThunkAction } from '../reducers';
import * as jwt_decode from 'jwt-decode';

// services
import * as Services from '../../services/CashFlow-Debts.Services';

// actions
import * as Actions from '../actions';
import * as ActionEvents from '../actions/CashFlow-Debt.Actions';

// models
import { Debt } from '../../models/Entities/CashFlow/Debt/Debt';
import { ErrorType } from '../../models/HttpError';
import { NewDebt } from '../../models/Entities/CashFlow/Debt/NewDebt';

type KnownAction =
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsCreditorRequestAction
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsCreditorSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsCreditorFailureAction
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsDebtorRequestAction
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsDebtorSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtGetAllAsDebtorFailureAction
    | Actions.CashFlowDebts.CashFlowDebtGetbyIdRequestAction
    | Actions.CashFlowDebts.CashFlowDebtGetByIdSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtGetByIdFailureAction
    | Actions.CashFlowDebts.CashFlowDebtCreateRequestAction
    | Actions.CashFlowDebts.CashFlowDebtCreateSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtCreateFailureAction
    | Actions.CashFlowDebts.CashFlowDebtUpdateRequestAction
    | Actions.CashFlowDebts.CashFlowDebtUpdateSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtUpdateFailureAction
    | Actions.CashFlowDebts.CashFlowDebtDeleteRequestAction
    | Actions.CashFlowDebts.CashFlowDebtDeleteSuccessAction
    | Actions.CashFlowDebts.CashFlowDebtDeleteFailureAction;

export const actionCreators = {
    GetAllDebtsByCreditor: (Creditor: string, Debtor: string | undefined): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_GETALLAS_CREDITOR_FAILURE,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        dispatch({
            type: ActionEvents.CASHFLOW_DEBT_GETALLAS_CREDITOR_REQUEST
        });

        Services.GetDebtsByCreditor(token, Creditor, Debtor)
            .then((response: Debt[]) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_DEBT_GETALLAS_CREDITOR_SUCCESS,
                    payload: response
                });
            })
            .catch((error: ErrorType) => {
                dispatch({
                    type: ActionEvents.CASHFLOW_DEBT_GETALLAS_CREDITOR_FAILURE,
                    error: error
                });
            });
    },
    GetAllDebtsByDebtor: (Debtor: string, Creditor: string | undefined): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_GETALLAS_DEBTOR_FAILURE,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        dispatch({
            type: ActionEvents.CASHFLOW_DEBT_GETALLAS_DEBTOR_REQUEST
        });

        Services.GetDebtsByDebtor(token, Debtor, Creditor)
        .then((response: Debt[]) => {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_GETALLAS_DEBTOR_SUCCESS,
                payload: response
            });
        })
        .catch((error: ErrorType) => {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_GETALLAS_DEBTOR_FAILURE,
                error: error
            });
        });
    },
    GetDebtById: (debtId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_GETBYID_FAILURE,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        const currentOwner: string = (jwt_decode.jwtDecode(token) as any)?.tenantId;

        dispatch({
            type: ActionEvents.CASHFLOW_DEBT_GETBYID_REQUEST,
            payload: debtId
        });

        Services.GetDebtById(token, debtId)
        .then((response) => {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_GETBYID_SUCCESS,
                tenantId: currentOwner,
                payload: response
            });
        })
        .catch((error) => {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_GETBYID_FAILURE,
                error: error
            });
        });

    },
    CreateDebt: (debt: NewDebt): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_CREATE_FAILURE,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        const currentOwner: string = (jwt_decode.jwtDecode(token) as any)?.tenantId;

        dispatch({
            type: ActionEvents.CASHFLOW_DEBT_CREATE_REQUEST,
            payload: debt
        });

        Services.CreateDebt(token, debt)
        .then((response) => {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_CREATE_SUCCESS,
                tenantId: currentOwner,
                payload: response
            });
        })
        .catch((error) => {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_CREATE_FAILURE,
                error: error
            });
        });

    },
    UpdateDebt: (debt: Debt): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_UPDATE_FAILURE,
                debtId: debt.id,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        const currentOwner: string = (jwt_decode.jwtDecode(token) as any)?.tenantId;

        dispatch({
            type: ActionEvents.CASHFLOW_DEBT_UPDATE_REQUEST,
            tenantId: currentOwner,
            payload: debt
        });

        Services.UpdateDebt(token, debt)
        .then((debt: Debt) => {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_UPDATE_SUCCESS,
                tenantId: currentOwner,
                payload: debt
            });
        })
        .catch((error) => {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_UPDATE_FAILURE,
                debtId: debt.id,
                error: error
            });
        });

    },
    DeleteDebt: (debtId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

        if (token === null) {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_DELETE_FAILURE,
                debtId: debtId,
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            })
            return;
        }

        dispatch({
            type: ActionEvents.CASHFLOW_DEBT_DELETE_REQUEST,
            payload: debtId
        });

        Services.DeleteDebt(token, debtId)
        .then((response) => {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_DELETE_SUCCESS,
                payload: debtId
            });
        })
        .catch((error) => {
            dispatch({
                type: ActionEvents.CASHFLOW_DEBT_DELETE_FAILURE,
                debtId: debtId,
                error: error
            });
        });

    }
};

