//stores
import * as PractitionerSettingsAssociationsStore from '../../../redux/middleware/PractitionerSettingsMiddleware';
import * as PractitionerStore from '../../../redux/middleware/PractitionerMiddleware';

//#region props

import { PractitionerAssociationSettings } from '../../../models/Entities/PractitionerAssociations/Settings/PractitionerAssociationSettings';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';

interface IProps {
  PractitionerId: string;
}

interface ConnectedProps {
  //status
  IsLoading: boolean;
  LoadedSuccessfully: boolean;
  FailOnLoad: boolean;
  ErrorOnLoad: ErrorType;

  IsSaving: boolean;
  SavedSuccessfully: boolean;
  FailOnSave: boolean;
  ErrorOnSave: ErrorType;

  PractitionerSettings: PractitionerAssociationSettings;
  Practitioners: Practitioner[];
}

interface DispatchProps {
  GetPractitionerSettings: typeof PractitionerSettingsAssociationsStore.actionCreators.GetPractitionerSettings;
  UpdatePractitionerSettings: typeof PractitionerSettingsAssociationsStore.actionCreators.UpdatePractitionerSettings;
  AddPractitionerSettings: typeof PractitionerSettingsAssociationsStore.actionCreators.AddPractitionerSettings;
  GetAllPractitioners: typeof PractitionerStore.actionCreators.GetAllPractitioners;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  PractitionerSettings: state.Practitioner?.list.find(
    (practitioner: Practitioner) =>
      practitioner.userId === ownProps.PractitionerId
  )?.Settings,

  Practitioners: state?.Practitioner?.list,

  // status
  IsLoading: state.PractitionerSettings?.isLoading,
  LoadedSuccessfully: state.PractitionerSettings?.loadedSuccessfully,
  FailOnLoad: state.PractitionerSettings?.failOnLoad,
  ErrorOnLoad: state.PractitionerSettings?.error,

  IsSaving: state.PractitionerSettings?.isSaving,
  SavedSuccessfully: state.PractitionerSettings?.savedSuccessfully,
  FailOnSave: state.PractitionerSettings?.failOnSave,
  ErrorOnSave: state.PractitionerSettings?.error
});

export const mapDispatchToProps = {
  ...PractitionerSettingsAssociationsStore.actionCreators,
  ...PractitionerStore.actionCreators
};
