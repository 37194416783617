import { DefaultButton, FontIcon, FontSizes, IconButton, IIconProps, Label, MessageBarType, PrimaryButton, SearchBox, Separator, Spinner, Toggle } from '@fluentui/react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBoolean } from '@fluentui/react-hooks';

// models
import { Sponsor } from '../../../models/Entities/Sponsor/Sponsor';

// functions
import * as ComponentFunctions from './SponsorList.Functions';

// styles
import './SponsorList.Styles.css';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

//components
import { SponsorFormComponent } from '../SponsorForm/SponsorFormCcomponent.';
import { MessageComponent } from '../../../commons/component/MessageComponent';

export const SponsorListComponent = (props: ComponentFunctions.Props) => {
  const EditIcon: IIconProps = { iconName: 'Edit' };
  const AddIcon: IIconProps = { iconName: 'CirclePlus' };
  const AgreementsIcon: IIconProps = { iconName: 'StackIndicator' };

  const navigate = useNavigate();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  //useTranslation()
  const [t] = useTranslation();

  //events
  const onEditEvent = (contract: any) => {
    //if (props.editEvent) props.editEvent(contract);
  };

  const onAddEvent = () => {
    //if (props.addEvent) props.addEvent();
  };

  const onViewContractEvent = (sponsor: Sponsor) => {
    navigate('/sponsors/' + sponsor.id);
  };

  //effects
  useEffect(() => {
    props.GetAllSponsors(0, 100, undefined);
  }, []);

  // events 

  const onRefreshSettlementEvent = () => {
    props.SyncSponsor();
  };

  const handleClosePanel = () => {
    dismissPanel();
  };

  const HandleAddEvent = () => {
    openPanel();
  };

  //#region Subcontent definition

  // component searchbox
  // const Searchbox = <SearchBox
  //   placeholder="Buscar..."
  //   //onChange={OnSearch}
  //   underlined={false}
  //   onChange={(
  //     event?: React.ChangeEvent<HTMLInputElement> | undefined,
  //     newValue?: string | undefined
  //   ) => (newValue ? onSearch(newValue) : undefined)}
  //   onClear={() => onSearch('')}
  // />

  // Principal error message
  const PrincipalErrorMessage = props.failOnLoading && (
    <MessageComponent
      message={
        t('sponsorList.errorMessage.error')
      }
      subMessage={props.error?.ErrorMessage || ''}
      type={MessageBarType.error}
      itemList={props.error?.Errors || []}
    />
  );

  const Controls = (
    <div className='SponsorControls'>
      <PrimaryButton
        secondaryText={t('sponsorsListPage.button.addSponsor')}
        onClick={HandleAddEvent}
        text={t('sponsorsListPage.button.addSponsor')}
        iconProps={AddIcon}
        className="actionButton"
      />
      <DefaultButton
          onClick={(event) => {
            event.stopPropagation();
            onRefreshSettlementEvent();
          }}
          iconProps={ComponentFunctions.RefreshIcon}
        />
    </div>
  );

  const Forms = (
    <>
      <SponsorFormComponent
        isOpen={isOpen}
        openPanel={openPanel}
        dismissPanel={handleClosePanel}
        Sponsor={undefined}
      />
    </>
  )



  // Loading
  const Loading = props.isLoading && (
    <div>
      <Spinner
        label={t('sponsorList.loading.loadingSponsors')}
        ariaLive="assertive"
        labelPosition="top"
      />
    </div>
  );

  // Table of content
  const TableOfContent = props.isLoadingSuccessfully && (
    <table className="Table">
      <thead>
        <tr>
          <th></th>
          <th>{t('sponsorList.parameters.businessName')}</th>
          <th>{t('sponsorList.parameters.options')}</th>
        </tr>
      </thead>
      <tbody>
        {props.Sponsors?.map((item: Sponsor) => (
          <tr key={item.id} className="TableBodyRow">
            <td><FontIcon iconName='TestUserSolid' style={{ fontSize: FontSizes.size24, padding: 10 }} /></td>
            <td className="primary" onClick={() => onViewContractEvent(item)}>
              <Label>{item.person?.businessName.toUpperCase()}</Label>
            </td>
            <td>
              <IconButton
                iconProps={EditIcon}
                title={t('sponsorList.parameters.buttons.modifyButton')}
                ariaLabel="Edit"
                onClick={() => onEditEvent(item)}
              />
              <IconButton
                iconProps={AgreementsIcon}
                title={t('sponsorList.parameters.buttons.agreementsButton')}
                ariaLabel="AddAgreements"
                onClick={() => onViewContractEvent(item)}
              />
            </td>
          </tr>
        ))
        }
      </tbody>
    </table>
  );

  //#endregion


  return (
    <>
      {Controls}
      <Separator />
      {PrincipalErrorMessage}
      {TableOfContent}
      {Loading}
      {Forms}
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(SponsorListComponent as any);
