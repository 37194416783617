import { ErrorType } from "../../../models/HttpError";
import { Settlement } from "../../../models/Entities/Settlements/Settlement";
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";

// stores
import * as SettlementStore from '../../../redux/middleware/SettlementMiddleware';
import * as SettlementDetailStore from '../../../redux/middleware/SettlementDetailsMiddleware';

//State
import { AppState } from "../../../redux/reducers";

// common functions
import * as Functions from '../../../functions/common';

interface IProps {
    SettlementId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    error: ErrorType | undefined;
    Settlement: Settlement | undefined;
    SettlementDetails: SettlementDetail[] | undefined;
    Patients: string[];
    Stages: string[];
  }
  
  interface DispatchProps {
    GetSettlementById: typeof SettlementStore.actionCreators.GetSettlementById;
    GetAllSettlementDetails: typeof SettlementDetailStore.actionCreators.GetAllSettlementDetails;
    ChangeExcludeStatus: typeof SettlementDetailStore.actionCreators.ChangeExcludeStatus;
    MarkOk: typeof SettlementDetailStore.actionCreators.MarkOk;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.SettlementDetails?.isLoadingAll,
    isUpdating: state.SettlementDetails?.isLoadingAll,
    isUpdatedSuccessfully: state.SettlementDetails?.successLoadingAll,
    isLoadingSuccessfully: state.SettlementDetails?.successLoadingAll,
    failOnUpdating: state.SettlementDetails?.failOnLoadingAll,
    failOnLoading: state.SettlementDetails?.failOnLoadingAll,
    isDeleting: state.SettlementDetails?.isDeletingOne,
    isDeletedSuccessfully: state.SettlementDetails?.successDeletingOne,
    failOnDeleting: state.SettlementDetails?.failOnDeletingOne,
    error: state.SettlementDetails?.error,
    Settlement: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId ) : undefined,
    SettlementDetails: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details : [],
    Patients: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.map((detail: SettlementDetail) => detail.patientNumberInProtocol).filter(uniqueFilter) : [] ,
    Stages: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.sort(
      (a: SettlementDetail, b: SettlementDetail) => (a.contractStage || 100000) - (b.contractStage || 100000)
    ).filter((detail: SettlementDetail) => detail.contractStageName
    )?.map((detail: SettlementDetail) => detail.contractStageName).filter(uniqueFilter) : []
  });

 
  export const mapDispatchToProps = {
    ...SettlementStore.actionCreators,
    ...SettlementDetailStore.actionCreators
  };

  function uniqueFilter(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  };

  export const GetPatientStageTotal = (props: Props, patient: string, stage: string, currencyCode: string):number | undefined => {
     var sum = props.SettlementDetails?.filter((item: SettlementDetail )=> item.patientNumberInProtocol === patient && item.contractStageName === stage && item.currency === currencyCode && item.isExcluded === false)
          .reduce((sum, current) => sum + current.value, 0);
      return sum;
  };

  export const GetPatientStageCurrencies = (props: Props, patient: string, stage: string):string[] | undefined => {
    var currencies = props.SettlementDetails?.filter((item: SettlementDetail )=> item.patientNumberInProtocol === patient && item.contractStageName === stage)
         .map((item: SettlementDetail) => item.currency).filter(uniqueFilter);
     return currencies;
  };
