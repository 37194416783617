import { ProviderAssociationsMedicalService } from '../models/Entities/ProviderAssociations/MedicalServices/ProviderAssociationMedicalService';

import { IResult } from '../models/HttpResult';
import { NewProviderAssociationsMedicalService } from '../models/Entities/ProviderAssociations/MedicalServices/NewProviderAssociationMedicalService';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'providers/';

export const GetAllProviderServices = async (
  ProviderId: number
, token: string): Promise<ProviderAssociationsMedicalService[]> => {
  let RequestURL: string =
    BaseURL + 'aggregator/' + ProviderId + '/medicalservices';


  try {
    const Response: ProviderAssociationsMedicalService[] = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'GET'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<ProviderAssociationsMedicalService[]>
          >;
        }
      })
      .then((data: IResult<ProviderAssociationsMedicalService[]>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AssociateMedicalService = async (
  MedicalServiceAssociation: NewProviderAssociationsMedicalService
, token: string): Promise<ProviderAssociationsMedicalService> => {
  let RequestURL: string = BaseURL + 'aggregator/medicalservices';

  try {
    const Response: ProviderAssociationsMedicalService = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'POST',
        body: JSON.stringify(MedicalServiceAssociation)
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<ProviderAssociationsMedicalService>
          >;
        }
      })
      .then((data: IResult<ProviderAssociationsMedicalService>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DisassociateMedicalServiceById = async (
  MedicalServiceAssociationId: number
, token: string): Promise<ProviderAssociationsMedicalService> => {
  let RequestURL: string =
    BaseURL + 'medicalservices/' + MedicalServiceAssociationId;

  try {
    const Response: ProviderAssociationsMedicalService = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'DELETE'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<ProviderAssociationsMedicalService>
          >;
        }
      })
      .then((data: IResult<ProviderAssociationsMedicalService>) => {
        return data.data;
      })
      .catch(async (error: any) => {
        throw await Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DisassociateMedicalServiceByMedicalServiceId = async (
  ProviderId: number,
  MedicalServiceId: number
, token: string): Promise<ProviderAssociationsMedicalService> => {
  let RequestURL: string =
    BaseURL +
    'associations/' +
    ProviderId +
    '/medicalservices/' +
    MedicalServiceId;

  try {
    const Response: ProviderAssociationsMedicalService = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'DELETE'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<ProviderAssociationsMedicalService>
          >;
        }
      })
      .then((data: IResult<ProviderAssociationsMedicalService>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
