// functions
import { connect } from 'react-redux';
import * as ComponentFunctions from './ProtocolContract.Functions';
import { FontIcon, Icon, Label, PrimaryButton, Separator, Text, Spinner, FontSizes } from '@fluentui/react';
import { Link, useNavigate } from 'react-router-dom';
import ContractsSelectorComponent from '../../../commons/component/Inputs/ContractSelector/ContractSelectorComponent';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import { Contract } from '../../../models/Entities/Contracts/Contract';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Styles
import './ProtocolContract.Styles.css';
import { ProtocolContract } from '../../../models/Entities/Protocols/ProtocolContracts';
import { ConfirmationButtonComponent } from '../../../commons/component/ConfirmationButton/ConfirmationButtonComponent';


export const ProtocolContractComponent = (props: ComponentFunctions.Props) => {

    const navigate = useNavigate();
    const [selectedContractToAssociate, setSelectedContractToAssociate] = useState<Contract | undefined>(undefined);
    const [verificationStatus, setVerificationStatus] = useState<boolean | undefined >(undefined);
    const [ContractProtocolAssociated, setContractProtocolAssociated] = useState<Protocol | undefined >(undefined);
    const [ContractAssociated, setContractAssociated] = useState<Contract | undefined >(undefined);

    //useTranslation()
    const [t] = useTranslation();

    // effects
    useEffect(() => {
        setVerificationStatus(selectedContractToAssociate?.id === null ? true : false); 
        if(selectedContractToAssociate?.id !== null) {
            setContractProtocolAssociated(props.Protocols.find((protocol: Protocol) => protocol.id === selectedContractToAssociate?.id));
        }
        
    }, [selectedContractToAssociate !== undefined]);

    useEffect(() => {
        props.Protocol && props.GetAllProtocolContractAssociations(props.ProtocolId);
    }, [props.ProtocolId, props.Protocol !== undefined]);

    useEffect(() => {
        props.Protocol?.contractAssociation?.contractId && props.GetContractById(props.Protocol?.contractAssociation.contractId);
    }, [props.Protocol?.contractAssociation?.contractId]);

    //events
    const onViewcontractDetailsEvent = (contractId: number) => {
        navigate(`/contracts/${contractId}`);
    };  

    const setProtocolContract = (contractId: number) => {
        props.AssociateContractToProtocol(contractId, props.Protocol?.id);
    };

    const onDisassociateContract = (contractAssociation: ProtocolContract) => {
        console.log('desasociar contrato', contractAssociation);
        props.DisassociateContractFromProtocol(contractAssociation);
    }


    const onVerification = (
        <Spinner 
            label={t('protocolContract.onVerification.verifying')}  
            ariaLive="assertive" 
            labelPosition="right" 
            style={{ marginTop: '20px' }} 
        />
    );

    const verificationOk = (
        <>
        <Icon 
            iconName="Accept"
            style={{ color: 'green', fontSize: '30px', fontWeight: 'bold' }}
        />
        <Text>{t('protocolContract.onVerification.verificationOk')}</Text>
        </>
    )

    const verificationNoOk = (
        <>
        <Icon 
            iconName="Cancel"
            style={{ color: 'red', fontSize: '30px', fontWeight: 'bold' }}
        />
        <Text>{t('protocolContract.onVerification.verificationNoOk')}</Text>
        </>
    )

    const Loading = (
        <Spinner 
            label={t('protocolContract.onVerification.loading')} 
            ariaLive="assertive" 
            labelPosition="right" 
            style={{ marginTop: '20px' }} 
        />
    )

    const noContract = (
        <>
            <Label>{t('protocolContract.noContract.contract')}</Label>
            <Text onClick={() => onViewcontractDetailsEvent(8)} > {t('protocolContract.noContract.unassociatedProtocol')}</Text>
            <Separator />
            <ContractsSelectorComponent 
            onSelectedContract={(selectedContrats: Contract[]) => setSelectedContractToAssociate(selectedContrats[0])} 
            ContractsSelected={props.Protocol?.contractAssociation?.contractId ? [props.Protocol?.contractAssociation.protocolId] : []} 
            MultiselectEnable={false} />
            {
                selectedContractToAssociate && (
                    <div> 
                        <Label><FontIcon className='LabelIcon' iconName='Info'/>{t('protocolContract.noContract.contractInformation')}</Label>
                        <div className='contractInfo'>
                            <Link to={`/contracts/${selectedContractToAssociate?.id}`} target='_tab' rel="noopener noreferrer"><Label>{selectedContractToAssociate?.contractNumber}</Label></Link>
                            <small>Estado</small>
                            <Label>{ComponentFunctions.GetContractStatusDescripcion(selectedContractToAssociate?.contractStatus)}</Label>
                            {selectedContractToAssociate?.contractDescription &&<p>
                            <Text>{selectedContractToAssociate?.contractDescription}</Text>
                            </p>}
                            <Label style={{display: 'flex'}}><FontIcon iconName='Calendar' style={{ marginTop: 3,  fontSize: FontSizes.medium}}/>
                                &nbsp;{t('protocolContract.noContract.validity')}&nbsp;
                                <strong>
                                    {selectedContractToAssociate?.contractStartDate ? moment(selectedContractToAssociate?.contractStartDate).format('DD/MM/YYYY') : t('protocolContract.contractStartDate.notDefined')}
                                </strong>
                                &nbsp;a&nbsp;
                                <strong>
                                    {selectedContractToAssociate?.contractEndDate ? moment(selectedContractToAssociate?.contractEndDate).format('DD/MM/YYYY') : t('protocolContract.contractEndDate.notDefined')}
                                </strong>
                            </Label>
                        </div>
                        <div className='verificationstatus'>
                            {verificationStatus === undefined && onVerification}
                            {verificationStatus ? verificationOk : verificationStatus !== undefined ? verificationNoOk : null}
                        </div>
                        <div>
                            <PrimaryButton disabled={!verificationStatus} onClick={() => setProtocolContract(selectedContractToAssociate.id)} ><Trans i18nKey={'protocolContract.buttons.associateContract'}/></PrimaryButton>
                        </div>
                    </div>
                )
            }
            
        </>
    );

    const Contract = (
        <>
        <div> 
            <Label><FontIcon className='LabelIcon' iconName='Info'/>{t('protocolContract.contract.contractInformation')}</Label>
            <div className='contractInfo'>
            <div className='DisassociateOption'>
                <ConfirmationButtonComponent 
                        ButtonId={'disassociateContract'} 
                        ButtonIconName={'RemoveLink'} 
                        ButtonText={''} 
                        ButtonCssClass={'danger'} 
                        ConfirmationButtonText={t('protocolContract.buttons.disassociate')} 
                        ConfirmationButtonCssClass={'danger'} 
                        CancelButtonText={t('protocolContract.buttons.cancelButton')} 
                        CancelButtonCssClass={''} 
                        Title={t('protocolContract.buttons.disassociateContract')} 
                        Description={` Va a desasociar el contrato del médico ${props.Contract?.contractNumber} del médico ${props.Protocol?.fullTitle}`} 
                        ElementTextHeader={props.Contract?.contractNumber || ''} 
                        ElementTextInformation={t('protocolContract.buttons.information')} 
                        isOperationExecuting={false} 
                        isOperationExecutedWithError={false} 
                        OperationError={undefined} 
                        onConfirmation={() => props.Protocol?.contractAssociation && onDisassociateContract(props.Protocol?.contractAssociation)} 
                        onCancel={function (): void {
                                throw new Error('Function not implemented.');
                        } }
                />
            </div>
                <Link to={`/contracts/${props.Contract?.id}`} target='_tab' rel="noopener noreferrer"><Label>{props.Contract?.contractNumber}</Label></Link>
                <small>{t('protocolContract.contract.status')}</small>
                <Label>{ComponentFunctions.GetContractStatusDescripcion(props.Contract?.contractStatus)}</Label>
                {selectedContractToAssociate?.contractDescription &&<p>
                <Text>{props.Contract?.contractDescription}</Text>
                </p>}
                <Label style={{display: 'flex'}}><FontIcon iconName='Calendar' style={{ marginTop: 3,  fontSize: FontSizes.medium}}/>
                    &nbsp;{t('protocolContract.contract.validity')}&nbsp;
                    <strong>
                        {props.Contract?.contractStartDate ? moment(props.Contract?.contractStartDate).format('DD/MM/YYYY') : t('protocolContract.contractStartDate.notDefined')}
                    </strong>
                    &nbsp;a&nbsp;
                    <strong>
                        {props.Contract?.contractEndDate ? moment(props.Contract?.contractEndDate).format('DD/MM/YYYY') : t('protocolContract.contractEndDate.notDefined')}
                    </strong>
                </Label>
            </div>
        </div>
        </>
    );

    return (
    <div className='Section Internal'>
        {props.Protocol ? props.Protocol?.contractAssociation?.contractId ? Contract : noContract : Loading}
    </div> 
    );
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ProtocolContractComponent as any);