import { DefaultButton, FontWeights, IIconProps, IStackProps, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, getTheme, mergeStyleSets } from "@fluentui/react"
import { useState } from "react";
import ContractsSelectorComponent from "../../../commons/component/Inputs/ContractSelector/ContractSelectorComponent";

// component functions 
import * as ComponentFunctions from './ContractAssociationForm.Functions';
import { Contract } from "../../../models/Entities/Contracts/Contract";

////i18Next
import { useTranslation, Trans } from 'react-i18next';

interface FormValidation {
    success: boolean;
    errors: string[];
}



export const ContractAssociationForm = (props: ComponentFunctions.IProps) => {

    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    const [selectedContract, setSelectedContract] = useState<Contract>();
    
    //useTranslation()
    const [t] = useTranslation();
    
    // events
    const HandleCancelForm = () => {
        props.dismissPanel();
    };

    const HandleSaveForm = () => {
        setSubmittedForm(true);
        if (ValidateForm()) {
            props.AssociateContract && selectedContract && props.AssociateContract(selectedContract);
        }
    };

    const SelectedContractActionHadler = (contracts: Contract[]) => {
        setSelectedContract(contracts[0] ? contracts[0] : undefined);
    }


    // form validation
    const ValidateForm = (): boolean => {
        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        if (!selectedContract) {
            FormValidation.errors.push(t('contractAssociation.formValidation.selectContract'));
            validationResult = false;
        }

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };

    const formFields = (
        <ContractsSelectorComponent ContractsSelected={[]} MultiselectEnable={false} onSelectedContract={(contracts: Contract[]) => SelectedContractActionHadler(contracts)} />
      );


    // footer
    const onRenderFooterContent = () => {
        return (
            <div className={contentStyles.footer}>
                <DefaultButton onClick={HandleCancelForm} className="OtherOption">
                    <Trans i18nKey={'contractAssociation.footer.cancelButton'}/>
                </DefaultButton>
                <PrimaryButton
                    onClick={HandleSaveForm}
                    styles={buttonStyles}
                    className="ConfirmAction"
                >
                    <Trans i18nKey={'contractAssociation.footer.cancelButton'}/>
                </PrimaryButton>
            </div>
        );
    };

    return (
        <Panel
            isOpen={props.isOpen}
            type={PanelType.medium}
            headerText={t('contractAssociation.panel.visitInformation')}
            onDismiss={props.dismissPanel}
            closeButtonAriaLabel="Close"
            onRenderFooter={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            {submittedForm && !FormValidationStatus?.success && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {t('contractAssociation.panel.reviewInformation')}
                    <ul>
                        {FormValidationStatus?.errors.map(
                            (Error: string, index: number) => (
                                <li key={index}>{Error}</li>
                            )
                        )}
                    </ul>
                </MessageBar>
            )}
            <div className={contentStyles.body}>
                {formFields}
            </div>
        </Panel>
    )
}

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 }
    // styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'stretch',
        borderRadius: 10
    },
    header: [
        theme.fonts.mediumPlus,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '0px 12px 12px 12px',
            backgroundColor: 'lightgray',
            borderRadius: '10px 10px 0 0'
        }
    ],
    body: {
        flex: '4 4 auto',
        overflowY: 'auto',
        selectors: {
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 }
        },
        padding: '0 1em 0 0',
        height: '85vh'
    },
    footer: {
        //flex: '4 4 auto',
        padding: '0 24px 24px 24px'
    }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};
