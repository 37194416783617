import { ProfessionalAvailability } from '../../models/Entities/ProfessionalAvailability/ProfessionalAvailability';
import { CommonState } from './../common/state/commonState';

export interface ProfessionalAvailabilityState
  extends CommonState<ProfessionalAvailability> {}

export const ProfessionalAvailabilityUnloadedState: ProfessionalAvailabilityState =
  {
    // loading all
    isLoadingAll: false,
    successLoadingAll: undefined,
    failOnLoadingAll: false,

    // loading one
    isLoadingOne: false,
    successLoadingOne: undefined,
    failOnLoadingOne: false,

    // saving
    isUpdatingOne: false,
    successUpdatingOne: undefined,
    failOnUpdatingOne: false,

    // adding
    isAddingNewOne: false,
    successAddingNewOne: undefined,
    failOnAddingNewOne: false,

    // deleting
    isDeletingOne: false,
    successDeletingOne: undefined,
    failOnDeletingOne: false,

    list: [],
    error: undefined
  };
