import * as React from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { NormalPeoplePicker } from '@fluentui/react/lib/Pickers';

// ownFunctions
import * as ownFunctions from './PractitionerSelectorFunctions';
import * as commonFunctions from '../../../../functions/common';


// models
import { Practitioner } from '../../../../models/Entities/Practitioners/Practitioner';
import { connect } from 'react-redux';

const PractitionerSelectorComponent = (
  props: ownFunctions.Props
) => {
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<
    IPersonaProps[]
  >([]);
  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>([]);

  const picker = React.useRef(null);

  const onChangeSelection = (items?: IPersonaProps[] | undefined) => {
    if (props.onChangeSelection) {
      props.onChangeSelection(items?.map((practitioner: IPersonaProps) => {
        return {
          name: practitioner.text,
          userId: practitioner.key,
          fullName: practitioner.text,
          email: practitioner.tertiaryText,
          userName: practitioner.text,
          registrationNumber: Number(practitioner.optionalText),
          speciality: practitioner.secondaryText,
          photo: ''
        } as Practitioner;
      }));
    }
  };

  const onFilterChanged = (
    filterText: string,
    currentPersonas?: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
      let practitionersList = ownFunctions.removeDuplicates(
        filteredPersonas,
        currentPersonas || []
      );
      practitionersList = limitResults
        ? practitionersList.slice(0, limitResults)
        : practitionersList;
      return practitionersList || [];
    } else {
      return [];
    }
  };

  const filterPersonasByText = (
    filterText: string
  ): IPersonaProps[] => {
    return props.Practitioners?.map((item: Practitioner) => {
      return {
        key: item.userId,
        imageInitials: commonFunctions.getNameInitials(item.fullName),
        text: item.fullName,
        secondaryText: item.speciality
      } as IPersonaProps;
    })
      .filter((item: IPersonaProps) =>
        ownFunctions.doesTextStartWith(item.text as string, filterText)
      );
  };

  return (
    <div>
      <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={ownFunctions.getTextFromItem}
        pickerSuggestionsProps={ownFunctions.suggestionProps}
        onEmptyResolveSuggestions={
          (selectedItems?: IPersonaProps[] | undefined) => props.Practitioners?.map((item: Practitioner) => {
            return {
              key: item.userId,
              imageInitials: commonFunctions.getNameInitials(item.fullName),
              text: item.fullName,
              secondaryText: item.speciality
            } as IPersonaProps;
          })
        }
        className={'ms-PeoplePicker'}
        key={'normal'}
        onValidateInput={ownFunctions.validateInput}
        selectionAriaLabel={'Selected contacts'}
        removeButtonAriaLabel={'Remove'}
        componentRef={picker}
        itemLimit={props.selectionLimit}
        //onInputChange={onInputChange}
        onChange={onChangeSelection}
      />
    </div>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(PractitionerSelectorComponent as any);
