import { PractitionerAssociationSettings } from '../models/Entities/PractitionerAssociations/Settings/PractitionerAssociationSettings';
import { IResult } from '../models/HttpResult';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { NewPractitionerAssociationSettings } from '../models/Entities/PractitionerAssociations/Settings/NewPractitionerAssociationSettings';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'practitioners/';

export const GetPractitionerSettings = async (
  PractitionerId: string
, token: string): Promise<PractitionerAssociationSettings> => {
  let RequestURL: string =
    BaseURL + 'associations/' + PractitionerId + '/settings';

  try {
    const Response: PractitionerAssociationSettings = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerAssociationSettings>
          >;
        }
      })
      .then((data: IResult<PractitionerAssociationSettings>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AddPractitionerSettings = async (
  Settings: NewPractitionerAssociationSettings
, token: string): Promise<PractitionerAssociationSettings> => {
  let RequestURL: string = BaseURL + 'associations/settings';

  try {
    const Response: PractitionerAssociationSettings = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(Settings)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerAssociationSettings>
          >;
        }
      })
      .then((data: IResult<PractitionerAssociationSettings>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const SavePractitionerSettings = async (
  Settings: PractitionerAssociationSettings
, token: string): Promise<PractitionerAssociationSettings> => {
  let RequestURL: string = BaseURL + 'settings/' + Settings.practitionerId;

  try {
    const Response: PractitionerAssociationSettings = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(Settings)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerAssociationSettings>
          >;
        }
      })
      .then((data: IResult<PractitionerAssociationSettings>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
