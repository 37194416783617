import { IconButton, Label } from '@fluentui/react';
import { connect } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';

// component Functions
import * as ComponentFunctions from './PractitionerConcept.Item.Functions';

// components
import PractitionerProtocolConceptFormComponent from '../../PractitionerProtocolConceptsForm/PractitionerProtocolConceptForm.Component';

// styles
import './PractitionerConcept.Item.Styles.css';

const PractitionerConceptItem = (props: ComponentFunctions.Props) => {

    const [isOpenConceptPanel, { setTrue: openConceptPanel, setFalse: dismissConceptPanel }] =
    useBoolean(false);

    // events
    const onClick = () => {
        openConceptPanel();
        props.onClick && props.onClick(props.CommissionConcept);
    };

    const Forms = (
        <>
            <PractitionerProtocolConceptFormComponent
                isOpen={isOpenConceptPanel}
                openPanel={openConceptPanel}
                dismissPanel={dismissConceptPanel}
                CommissionAgentId={props.CommissionAgentConcept.commissionAgentId}
                CommissionAgentConceptId={props.CommissionAgentConcept.id}
                ContractId={props.CommissionAgentConcept.contractId}
            />
        </>
    )

    return (
        <div className={'CommissionAgentConceptContainer ' + (props.disabled ? ' disabled' : undefined)} onClick={!props.disabled ? onClick : undefined} >
            <Label>{props.Concept?.concept?.conceptName}</Label>
            <IconButton
                iconProps={{ iconName: 'Delete' }}
                disabled={props.disabled}
                onClick={() => props.DeleteContractCommissionAgentConcept(props.CommissionConcept.id)}
            />
            {Forms}
        </div>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(PractitionerConceptItem as any);