import { ErrorType } from "../../../models/HttpError";

// models
import { Settlement } from "../../../models/Entities/Settlements/Settlement";

// stores
import * as SettlementStore from '../../../redux/middleware/SettlementMiddleware';

//State
import { AppState } from "../../../redux/reducers";
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";

interface IProps {
    SettlementId: number;
    CurrencyCode: string;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    isRecalculating: boolean;
    isRecalculatedSuccessfully: boolean;
    failOnRecalculating: boolean;
    error: ErrorType | undefined;
    entityError: ErrorType | undefined;
    TotalToSettle: number | undefined;
    TotalWithholdings: number | undefined;
    TotalOvehead: number | undefined;
    TotalCost: number | undefined;
  }
  
  interface DispatchProps {
    GetSettlementById: typeof SettlementStore.actionCreators.GetSettlementById;
    RecalculateSettlementById: typeof SettlementStore.actionCreators.RecalculateSettlementById;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const CurrentSettlement = state.Settlement?.list?.find((item: Settlement) => item.id === ownProps.SettlementId);
    const CurrentSettlementDetails = CurrentSettlement?.details?.filter((detail: SettlementDetail) => detail.currency === ownProps.CurrencyCode) || [];

    return {
    isLoading: state.Settlement?.isLoadingAll,
    isUpdating: state.Settlement?.isLoadingAll,
    isUpdatedSuccessfully: state.Settlement?.successLoadingAll,
    isLoadingSuccessfully: state.Settlement?.successLoadingAll,
    failOnUpdating: state.Settlement?.failOnLoadingAll,
    failOnLoading: state.Settlement?.failOnLoadingAll,
    isDeleting: state.Settlement?.isDeletingOne,
    isDeletedSuccessfully: state.Settlement?.successDeletingOne,
    failOnDeleting: state.Settlement?.failOnDeletingOne,
    isRecalculating: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.isRecalculating : undefined,
    isRecalculatedSuccessfully: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.isRecalculatedSuccessfully : undefined,
    error: state.Settlement?.error,
    entityError: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.error : undefined,
    TotalToSettle: CurrentSettlementDetails.filter((detail: SettlementDetail) => detail.isExcluded === false || !detail.isExcluded)?.reduce((sum, current) => sum + current.value, 0),
    TotalWithholdings: CurrentSettlementDetails.filter((detail: SettlementDetail) => detail.isExcluded === false || !detail.isExcluded)?.reduce((sum, current) => sum + current.withHoldAmount, 0),
    TotalOvehead: CurrentSettlementDetails.filter((detail: SettlementDetail) => detail.isExcluded === false || !detail.isExcluded)?.reduce((sum, current) => sum + current.overheadValue, 0),
    TotalCost: CurrentSettlementDetails.filter((detail: SettlementDetail) => detail.isExcluded === false || !detail.isExcluded)?.reduce((sum, current) => sum + current.costValue, 0)  
        
    }
  };
  
  export const mapDispatchToProps = {
    ...SettlementStore.actionCreators
  };