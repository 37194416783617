import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';



import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { PortalCompatProvider } from '@fluentui/react-portal-compat';

// Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// Redux
import { configureStore } from './redux/store/store';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { StrictMode } from 'react';

// authentication
import { processSilentRenew } from 'redux-oidc';
import { OidcProvider } from 'redux-oidc';

//Localization
import './i18n';
import UserManager from './components/Utils/UserManager';

//import { OidcProvider } from 'redux-oidc';
//import userManager from './components/Utils/UserManager';

const history = createBrowserHistory();
const store = configureStore(history);

Sentry.init({
  dsn: 'https://9ef40bf949f341eb80dcc6a416c7ab94@o1308402.ingest.sentry.io/6553725',
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

if (window.location.pathname === '/silent_renew') {
  processSilentRenew();
} else {
  ReactDOM.render(

    <Provider store={store}>
      {/* <OidcProvider store={store} userManager={UserManager}> */}
      <BrowserRouter>
        <StrictMode>
        <FluentProvider theme={webLightTheme}>
          <PortalCompatProvider>
            <App />
          </PortalCompatProvider>
        </FluentProvider>
        </StrictMode>
      </BrowserRouter>
      {/* </OidcProvider> */}
    </Provider>
    ,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
