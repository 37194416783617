import { IIconProps, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import ContractListComponent from '../../../components/Contracts/List/ContractList.Component';
import { PageHeader } from '../../../commons/component/PageHeader/PageHeader';
import ProtocolContractFormComponent from '../../../components/Contracts/Protocols/ProtocolContractForm/ProtocolContractFormComponent';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { useNavigate } from 'react-router-dom';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const ContractListPage = () => {
  const AddIcon: IIconProps = { iconName: 'CirclePlus' };

  const navigate = useNavigate();

  const OnSelectedContractHandler = (contract: Contract) => {
    navigate('/contracts/' + contract.id);
  }

  //useTranslation() 
  const [t] = useTranslation();

  return (
    <>
      <PageHeader title={t('contractListPage.contractListTitle')}/>
      <div className="Section">
        <ContractListComponent SelectedEvent={OnSelectedContractHandler} ShowControls={true} />
      </div> 
    </>
  );
};