
import { connect } from 'react-redux';
import { PrimaryButton, Separator } from '@fluentui/react';
import { ContractAssociationForm } from '../../Contracts/ContractAssociationForm/ContractAssociationForm.Component';
import { useBoolean } from '@fluentui/react-hooks';

// functions
import * as ComponentFunctions from './SponsorContractList.Functions';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { useEffect } from 'react';
import ContractListComponent from '../../Contracts/List/ContractList.Component';
import { SponsorContract } from '../../../models/Entities/Sponsor/SponsorContracts';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

const SponsorContractList = (props: ComponentFunctions.Props) => {

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
        useBoolean(false);

    const handleClosePanel = () => {
        dismissPanel();
    };

    const HandleAddEvent = () => {
        openPanel();
    };

    const HandleOnAssociateContract = (contract: Contract) => {
        props.AssociateContract && props.AssociateContract(props.SponsorId, contract.id);
    }

    //useTranslation()
    const [t] = useTranslation();

    // efects 
    useEffect(() => {
        if(props.isAssociatedSuccessfully) {
            dismissPanel();
        }
    }, [props.isAssociatedSuccessfully]);

    useEffect(() => {
        props.GetAllContractAssociations && props.GetAllContractAssociations(props.SponsorId);
    }, []);


    const Forms = (
        <>
            <ContractAssociationForm
                isOpen={isOpen}
                openPanel={openPanel}
                dismissPanel={handleClosePanel}
                ContractId={0}
                AssociateContract={HandleOnAssociateContract} />
        </>
    );

    const Controls = (
        <>
            <PrimaryButton
                iconProps={ComponentFunctions.AddIcon}
                text={t('sponsorContractList.button.addButton')} 
                onClick={HandleAddEvent}
            />
            <Separator/>
            <ContractListComponent FilteredContractIds={props.Sponsor.contractsAssociated?.map((sponsorContract: SponsorContract) => sponsorContract.contractId)} ShowControls={false} />
        </>
    );

    return (
        <>
            {Controls}
            {Forms}
        </>
    )
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SponsorContractList as any);
