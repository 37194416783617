import { connect } from "react-redux"
import { Contract } from "../../../../models/Entities/Contracts/Contract"
import { ContractToClone } from "../../../../models/Entities/Contracts/ContractToClone"
import { CompoundButton, DefaultButton, FontIcon, Label, Separator, Spinner } from "@fluentui/react"
import { useEffect, useState } from "react"
import { ContractAddendum } from "../../../../models/Entities/Contracts/ContractAddendum"
import { useNavigate } from "react-router-dom"

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// component functions
import * as ComponentFunctions from './Addendums.Functions'

// styles
import './Addendums.Styles.css'

// components
import { ErrorMessageComponent } from "../../../Error/ErrorMessageComponent"
import { NewContractAddendum } from "../../../../models/Entities/Contracts/NewContractAddendum"
import { AddendumItemComponent } from "./Addendum.Item.Component"
import { ArrowCircleRight28Regular } from "@fluentui/react-icons"
import AddendumFormComponent from "../Addendum.Form/Addendum.Form.Component";

const AddendumsComponent = (props: ComponentFunctions.Props) => {

    const [SelectedAddendum, setSelectedAddendum] = useState<ContractAddendum | undefined>();
    
    // add or update form controll
    const [isAddendumFormOpen, setIsAddendumFormOpen] = useState(false);    

    //useTranslation()
    const [t] = useTranslation();

    // Effects
    useEffect(() => {
        props.GetContractById(props.ContractId);
    }, [props.ContractId]);

    useEffect(() => {
        props.GetContractAddendumsByContractId(props.ContractId);
    }, [props.Contract !== undefined]);

    useEffect(() => {
        if (props.Contract?.addendums?.length) {
            setSelectedAddendum(props.Contract?.addendums[0]);
            props.OnAddendumSelected(props.Contract?.addendums[0]);
        }
    }, [props.Contract?.addendums !== undefined]);


    // events ---------------------------------------------------------------------

    // Handle Create Contract Addendum
    const CreateContractAddendumHandler = () => {

        // const NewAddendum: NewContractAddendum = {
        //     contractAddendumName: props.Contract?.contractNumber + ' - Adenda ' + ((props.Contract?.addendums || []).length + 1),
        //     contractAddendumDescription: '',
        //     contractId: props.ContractId
        // };

        var AddendumToCloneId = (props.Addendums?.length && props.Addendums.length > 0) ? props.Addendums[props.Addendums?.length - 1]?.id : 0;

        props.CloneAddendum(props.ContractId, AddendumToCloneId);
    }

    const EditContractAddendum = () => {
        setIsAddendumFormOpen(true);
    }

    // Handle Select Addendum
    const SelectAddendumHandler = (addendum: ContractAddendum) => {
        setSelectedAddendum(addendum);
        props.OnAddendumSelected(addendum);
    }

    const onCloseAddendumForm = () => {
        setIsAddendumFormOpen(false);
    }


    // content

    const NoAdendums = (
        <div>

        </div>
    );

    const GetAddendumCSSClass = (addendum: ContractAddendum, contractId: number): string => {
        var CssClass = '';
        CssClass = + addendum?.contractId === contractId ? 'Active' : '';

        const isCurrent: boolean = ComponentFunctions.isCurrentAddendum(addendum);
        const isExpired: boolean = ComponentFunctions.isExpiredAddendum(addendum);


        if (isCurrent) {
            CssClass += ' CurrentAddendum';
        }

        if (isExpired) {
            CssClass += ' ExpiredAddendum';
        }

        return CssClass;
    }

    const Actions = (
        <div className="AddendumActions">
            <DefaultButton
                text="Agregar Adenda"
                iconProps={{ iconName: props.isCreatingAddendum ? 'Sync' : 'Copy', className: props.Contract?.isCloning ? 'withRotation' : '' }}
                onClick={CreateContractAddendumHandler}
                className='optionButton'
            />
            <DefaultButton
                text="Editar"
                iconProps={{ iconName: 'SingleColumnEdit'}}
                onClick={EditContractAddendum}
                className='optionButton'
            />
        </div>
    )

    const Loading = (props.isLoading || props.isGettingAddendums) && (
        <div>
            <Spinner
                ariaLive="assertive"
                labelPosition="top"
            />
        </div>
    );

    const Forms = (
        <>
            {SelectedAddendum && <AddendumFormComponent
                AddendumId={SelectedAddendum?.id}
                ContractId={props.ContractId}
                isOpen={isAddendumFormOpen}
                onClose={onCloseAddendumForm}
             />}
        </>
    )

    return (
        <>
            {props.Contract?.error && <ErrorMessageComponent Error={props.Contract?.error} />}
            <div className="AddendumsContent">
                <div className="AddendumsRow">
                    {Loading}
                    {props.Addendums ? props.Addendums.map((addendum: ContractAddendum, index: number) => <>
                        <AddendumItemComponent
                            Addendum={addendum}
                            SelectedAddendum={SelectedAddendum}
                            OnSelect={(addendum: ContractAddendum) => SelectAddendumHandler(addendum)}
                        />
                        {(index + 1) < (props.Addendums || []).length && <ArrowCircleRight28Regular />}
                    </>) : NoAdendums}
                </div>
                {Actions}
                {Forms}
            </div>
        </>

    )
}

export default connect(ComponentFunctions.mapStateToProps, ComponentFunctions.mapDispatchToProps)(AddendumsComponent as any)