//store 
import * as DebtsStore from "../../../../redux/middleware/CashFlow-Debts.Middleware";

// models
import { Person } from "../../../../models/Entities/Person/Person";
import { Debt } from "../../../../models/Entities/CashFlow/Debt/Debt";
import { ErrorType } from "../../../../models/HttpError";
import { AppState } from "../../../../redux/reducers";
import { makeStyles, tokens } from "@fluentui/react-components";

import * as jwt_decode from "jwt-decode";

// form types
export enum FormType {
  Debtor = 1,
  Creditor = 2
}

//#region Props

interface IProps {
    FormType: FormType;
    ReferalId: string | undefined;
    FixedAssociatedEntityIdentifier: string | undefined;
    DebtId?: number;
    isOpen: boolean;
    onClose: () => void;
  }
  
  interface ConnectedProps {
    isLoading: boolean;
    isLoadingSuccessfully: boolean;
    failOnLoading: boolean;

    isSaving: boolean;
    isSaved: boolean | undefined;
    failOnSaving: boolean;

    DefaultCreditor: Person | undefined;
    DeFaultDebtor: Person | undefined;

    error: ErrorType | undefined;
    CurrentDebt: Debt;
    OwnerId: string;
    Persons: Person[];

    // methods
    GetPersonByIdentifier: (identifier: string) => Person | undefined;
  }
  
  interface MedicalServicesDispatchProps {
    GetDebtById: typeof DebtsStore.actionCreators.GetDebtById;
    CreateDebt: typeof DebtsStore.actionCreators.CreateDebt;
    UpdateDebt: typeof DebtsStore.actionCreators.UpdateDebt;
  }
  
  export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    var currentDebt = ownProps.DebtId ? ownProps.FormType === FormType.Debtor ? state?.CashFlowDebts?.DebtsAsDebtor?.find((debt: Debt) => debt.id === ownProps.DebtId) 
     : state?.CashFlowDebts?.DebtsAsDebtor?.find((debt: Debt) => debt.id === ownProps.DebtId) 
     : null ;

    const currentOwner: string = (jwt_decode.jwtDecode(state?.oidc?.user?.access_token!) as any)?.tenantId;

    if(ownProps.DebtId && !currentDebt){
        DebtsStore.actionCreators.GetDebtById(ownProps.DebtId);
    }

    // person who represnets the medical center
    const ownerPerson: Person = {
      personIdentifier: currentOwner,
      id: 0,
      isDeleted: false,
      numberOfMarriages: 0,
      personType: 0,
      businessName: 'Centro Médico'
    };


    const propsCreditor = ownProps.FormType === FormType.Creditor 
    ? ownProps.ReferalId && ownProps.ReferalId === currentOwner 
      ? ownerPerson
      : GetPersonByIdentifier(state.Persons?.list || [], currentOwner, ownProps.ReferalId || '')
    : GetPersonByIdentifier(state.Persons?.list || [], currentOwner, ownProps.FixedAssociatedEntityIdentifier || '');

    const propsDebtor = ownProps.FormType === FormType.Debtor
    ? ownProps.ReferalId && ownProps.ReferalId === currentOwner 
      ? ownerPerson
      : GetPersonByIdentifier(state.Persons?.list || [], currentOwner, ownProps.ReferalId || '')
    : GetPersonByIdentifier(state.Persons?.list || [], currentOwner, ownProps.FixedAssociatedEntityIdentifier || '');



    return ({
        isLoading: state.CashFlowDebts?.isLoadingOne,
        isLoadingSuccessfully: state.CashFlowDebts?.successLoadingOne,
        failOnLoading: state.CashFlowDebts?.failOnLoadingOne,
        
        isSaving: currentDebt ? (currentDebt.isUpdating || currentDebt.isSaving) : state.CashFlowDebts?.isAddingNewOne || false,
        isSaved: currentDebt ? (currentDebt.isUpdatedSuccessfully || currentDebt.isSavedSuccessfully) : state.CashFlowDebts?.isAddingNewOne || undefined,
        failOnSaving: currentDebt ? (currentDebt.failOnUpdating || currentDebt.failOnSaving) : state.CashFlowDebts?.failOnAddingNewOne || false,

        DefaultCreditor: propsCreditor,
        DeFaultDebtor: propsDebtor,
        
        error: state.CashFlowDebts?.error || currentDebt?.error || undefined,
        CurrentDebt: currentDebt,
        OwnerId: currentOwner,
        Persons: state.Persons?.list || [],
        GetPersonByIdentifier: (identifier: string) => GetPersonByIdentifier(state.Persons?.list || [], currentOwner, identifier)
    })
  };
  
  export const mapDispatchToProps = {
    ...DebtsStore.actionCreators
  };


  // styles
  export const useStyles = makeStyles({
    formField: {
        // Stack the label above the field
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        gap: "2px",
        // Prevent the example from taking the full width of the page (optional)
        maxWidth: "400px",
      },
    content: {
      display: "flex",
      flexDirection: "column",
      rowGap: tokens.spacingVerticalL,
    }
  });

// functions
export const GetPersonByIdentifier = (persons: Person[], tenantId: string, identifier: string): Person | undefined => {
   
    if(identifier === tenantId) return {
        personIdentifier: tenantId,
        businessName: 'Centro Médico'
    } as Person;
    return persons.find((person: Person) => person.personIdentifier === identifier);
}