import * as React from 'react';
import { Label, Separator } from "@fluentui/react"
import PractitionerProtocolActivityComponent from "../PractitionerProtocolActivity/PractitionerProtocolActivity.Component"
import PractitionerProtocolVisitList from "../PractitionerProtocolVisitList/PracittionerProtocolVisit.List.Component"
import { useState } from 'react';
import { Slider } from '@fluentui/react-components';

import './PractitionerActivityFeed.Styles.css';
import ProtocolSelectorComponent from '../../../commons/component/Inputs/ProtocolSelector/ProtocolSelectorComponent';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import DateRangeSelectorComponent from '../../../commons/component/Inputs/DateRangeSelector/DateRangeSelector.Component';

export const PractitionerActivityFeedComponent = (props: { practitioner: string }) => {

    var FromDate = new Date (new Date().setMonth(new Date().getMonth() - 3));

    const [DateFrom, setDateFrom] = useState<Date>(FromDate);
    const [DateTo, setDateTo] = useState<Date>(new Date());
    const [Protocols, setProtocols] = useState<number[]>([]);

    const changeDateFrom = (newDateFrom: Date) => { 
        setDateFrom(newDateFrom);
    }

    const changeDateTo = (newDateTo: Date) => {
        setDateTo(newDateTo);
    }

    const controls = (
        <div className='ActivityControls'>
            <div>
                <Label htmlFor={'ActivityFeedSlider'}>
                    Rango de actividad
                </Label>

                <DateRangeSelectorComponent 
                    onDateChange={(dateFrom: Date | undefined, dateTo: Date | undefined) => { dateFrom && changeDateFrom(dateFrom); dateTo && changeDateTo(dateTo) }} 
                    startDateLabel={''} 
                    startDate={new Date (new Date().setMonth(new Date().getMonth() - 3))}
                    endDateLabel={''} 
                    endDate={new Date()}
                    className={'DateRangeContainer'} /> 
            </div>
            <Separator vertical />
            <div>
                <Label htmlFor={'ProtocolSelector'}>Protocolos</Label>
                <ProtocolSelectorComponent selectionLimit={undefined} onChangeSelection={(protocolsSelected: Protocol[] | undefined) => setProtocols(protocolsSelected?.map((protocol: Protocol) => { return protocol.id}) || [])} />
            </div>
        </div>
    );

    return (
        <>
            {controls}
            <Separator />
            <div className='CalendarContainer'>
                <PractitionerProtocolActivityComponent PractitionerId={props.practitioner} DateFrom={DateFrom} DateTo={DateTo} protocolsIds={Protocols} />
            </div>
            <Separator />
            <PractitionerProtocolVisitList PractitionerId={props.practitioner} DateFrom={DateFrom} DateTo={DateTo} protocolsIds={Protocols} />
        </>
    )
}