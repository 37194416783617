import { IIconProps, Label, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { PageHeader } from '../../../commons/component/PageHeader/PageHeader';
import { useParams } from 'react-router-dom';


// components
import SettlementHeaderComponent from '../../../components/Settlements/SettlementtHeader/SettlementHeader.Component';
import SettlementResumeListComponent from '../../../components/Settlements/SettlementResumeList/SettlementResumeList.Component';
import SettlementTotalComponent from '../../../components/Settlements/SettlementTotal/SettlementTotal.Component';
import SettlementGridComponent from '../../../components/Settlements/SettlementGrid/SettlementGrid.Component';

////i18Next
import { useTranslation } from 'react-i18next';

//styles
import './SettlementDetails.Styles.css';

export const SettlementDetailsPage = () => {

  const { SettlementId } = useParams();

  //useTranslation 
  const [t] = useTranslation();
 
  return (
    <>
        <PageHeader title={t('settlementDetailsPage.settlementDetailsTitle')}/>
        <SettlementHeaderComponent SettlementId={Number(SettlementId)} />
        <div className='ContentDistribution'>
            <div className="Section">
                <SettlementGridComponent SettlementId={Number(SettlementId)} />
                <SettlementResumeListComponent SettlementId={Number(SettlementId)} />
            </div>
            <div className="Section">
                <SettlementTotalComponent SettlementId={Number(SettlementId)} />
            </div>
        </div>
    </>
    );
};
