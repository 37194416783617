import { Action, Reducer } from 'redux';
import { ContractBranchState, ContractBranchUnloadState } from '../states/ContractBranchState';

// actions
import * as Actions from '../actions/';

// functions
import * as Functions from '../../functions/common';


type KnownAction = 
| Actions.ContractBranch.ContractBranch_GetAllBycontractId_Request_Action
| Actions.ContractBranch.ContractBranch_GetAllBycontractId_Receive_Action
| Actions.ContractBranch.ContractBranch_GetAllBycontractId_Error_Action
| Actions.ContractBranch.ContractBranch_UpdateALLByContractId_Request_Action
| Actions.ContractBranch.ContractBranch_UpdateALLByContractId_Receive_Action
| Actions.ContractBranch.ContractBranch_UpdateALLByContractId_Error_Action
| Actions.ContractBranch.ContractBranch_GetById_Request_Action
| Actions.ContractBranch.ContractBranch_GetById_Receive_Action
| Actions.ContractBranch.ContractBranch_GetById_Error_Action
| Actions.ContractBranch.ContractBranch_Create_Request_Action
| Actions.ContractBranch.ContractBranch_Create_Receive_Action
| Actions.ContractBranch.ContractBranch_Create_Error_Action
| Actions.ContractBranch.ContractBranch_Update_Request_Action
| Actions.ContractBranch.ContractBranch_Update_Receive_Action
| Actions.ContractBranch.ContractBranch_Update_Error_Action
| Actions.ContractBranch.ContractBranch_Delete_Request_Action
| Actions.ContractBranch.ContractBranch_Delete_Receive_Action
| Actions.ContractBranch.ContractBranch_Delete_Error_Action;


export const ContractBranchReducer: Reducer<ContractBranchState> = (state: ContractBranchState | undefined, incomingAction: Action): ContractBranchState => {

    if (state === undefined) {
        return ContractBranchUnloadState as ContractBranchState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CONTRACTBRANCH_GET_ALL_BY_CONTRACTID_REQUEST_ACTION':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: false,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CONTRACTBRANCH_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
            };
        case 'CONTRACTBRANCH_GET_ALL_BY_CONTRACTID_ERROR_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CONTRACTBRANCH_UPDATE_ALL_BY_CONTRACTID_REQUEST_ACTION':
            return {
                ...state,
                isUpdatingAll: true,
                isUpdatingAllSuccess: false,
                FailUpdatingAll: false,
                error: undefined
            };
        case 'CONTRACTBRANCH_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION':
            return {
                ...state,
                isUpdatingAll: false,
                isUpdatingAllSuccess: true,
                FailUpdatingAll: false,
                error: undefined,
            };
        case 'CONTRACTBRANCH_UPDATE_ALL_BY_CONTRACTID_ERROR_ACTION':
            return {
                ...state,
                isUpdatingAll: false,
                isUpdatingAllSuccess: false,
                FailUpdatingAll: true,
                error: action.error
            };
        case 'CONTRACTBRANCH_GET_BY_ID_REQUEST_ACTION':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: false,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CONTRACTBRANCH_GET_BY_ID_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined,
            };
        case 'CONTRACTBRANCH_GET_BY_ID_ERROR_ACTION':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CONTRACTBRANCH_CREATE_REQUEST_ACTION':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: false,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CONTRACTBRANCH_CREATE_RECEIVE_ACTION':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                error: undefined,
            };
        case 'CONTRACTBRANCH_CREATE_ERROR_ACTION':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        case 'CONTRACTBRANCH_UPDATE_REQUEST_ACTION':
            return {
                ...state,
                isUpdatingOne: true,
                successUpdatingOne: false,
                failOnUpdatingOne: false,
                error: undefined
            };
        case 'CONTRACTBRANCH_UPDATE_RECEIVE_ACTION':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: true,
                failOnUpdatingOne: false,
                error: undefined,
            };
        case 'CONTRACTBRANCH_UPDATE_ERROR_ACTION':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: false,
                failOnUpdatingOne: true,
                error: action.error
            };
        case 'CONTRACTBRANCH_DELETE_REQUEST_ACTION':
            return {
                ...state,
                isDeletingOne: true,
                successDeletingOne: false,
                failOnDeletingOne: false,
                error: undefined
            };
        case 'CONTRACTBRANCH_DELETE_RECEIVE_ACTION':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: true,
                failOnDeletingOne: false,
                error: undefined,
            };
        case 'CONTRACTBRANCH_DELETE_ERROR_ACTION':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: false,
                failOnDeletingOne: true,
                error: action.error
            };
        default:
                return state;
    }

}