import { DateTimeInput } from "../DateTimeInput/DateTimeInput";
import { useEffect, useState } from "react";
import { use } from "i18next";

const DateRangeSelectorComponent = (props: {
    startDate?: Date | undefined,
    endDate?: Date | undefined,
    onDateChange: (starDate: Date | undefined, endDate: Date | undefined) => void,
    startDateLabel: string | undefined,
    endDateLabel: string | undefined,
    className: string
}) => {

    const [startDate, setStartDate] = useState<Date | undefined>(props.startDate);
    const [endDate, setEndDate] = useState<Date | undefined>(props.endDate);
    const [dateValidation, setDateValidation] = useState<boolean>(true);

    // validate startdate and enddate
    useEffect(() => {
        if (startDate !== undefined && endDate !== undefined && (startDate.getTime() - endDate.getTime() > 0)) {
            setDateValidation(false);
        } else {
            setDateValidation(true);
        }
    }, [startDate, endDate]);

    const onChangeStartDate = (date: Date | undefined) => {
        setStartDate(date ? new Date(date) : undefined);
        dateValidation && props.onDateChange(date, endDate);
    };

    const onChangeEndDate = (date: Date | undefined) => {
        setEndDate(date ? new Date(date) : undefined);
        dateValidation && props.onDateChange(startDate, date);
    };

    return (
        <>
            <div className={props.className}>
                <DateTimeInput label={props.startDateLabel} placeHolder={"fecha de inicio"} selectedDate={startDate} isSubmitted={false} isRequired={false} errorMessage={undefined} onSelectDate={onChangeStartDate} />
                <DateTimeInput label={props.endDateLabel} placeHolder={"fecha de fin"} selectedDate={endDate} isSubmitted={false} isRequired={false} errorMessage={undefined} onSelectDate={onChangeEndDate} />

            </div>
            {!dateValidation && <small className="error">La fecha de inicio no puede ser mayor a la fecha de fin</small>}
        </>
    );

};

export default DateRangeSelectorComponent;  