import { Provider } from './../../../models/Entities/Provider/Provider';
import { IIconProps } from '@fluentui/react';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';

// common functions
import * as Functions from '../../../functions/common';

// stores
import * as ProviderStore from '../../../redux/middleware/ProviderMiddleware';


//#region Props

interface IProps {}

interface ConnectedProps {
  isLoadingAll: boolean;
  isUpdatingAll: boolean;
  isUpdatedAll: boolean;
  isLoadedAll: boolean;
  failOnLoadingAll: boolean;
  failOnUpdatingAll: boolean;
  isDeletingOne: boolean;
  isDeletedOne: boolean;
  failOnDeletingOne: boolean;
  error: ErrorType | undefined;
  Providers: Provider[];
}

interface ProvidersDispatchProps {
  GetAllProviders: typeof ProviderStore.actionCreators.GetAllProviders;
}

export type Props = IProps & ConnectedProps & ProvidersDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoadingAll: state.Provider?.isLoadingAll,
  isUpdatingAll: state.Provider?.isUpdatingAll,
  isUpdatedAll: state.Provider?.UpdatingAllSuccess,
  isLoadedAll: state.Provider?.successLoadingAll,
  failOnLoadingAll: state.Provider?.failOnLoadingAll,
  failOnUpdatingAll: state.Provider?.FailUpdatingAll,
  isDeletingOne: state.Provider?.isDeletingOne,
  isDeletedOne: state.Provider?.successDeletingOne,
  failOnDeletingOne: state.Provider?.failOnDeletingOne,
  error: state.Provider?.error,
  Providers: state.Provider?.list.sort(Functions.DynamicSort('fullName'))
});

export const mapDispatchToProps = {
  ...ProviderStore.actionCreators
};

//#endregion Props

// icons
export const EditIcon: IIconProps = { iconName: 'Edit' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const AgreementsIcon: IIconProps = { iconName: 'StackIndicator' };
