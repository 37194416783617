import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';
import { CurrentUserState, CurrentUserUnloadedState } from '../states/UserState';
import { UserSettings } from '../../models/Entities/Settings/UserSettings';

type KnownAction =
    | Actions.UserSettings.UserSettings_Languaje_Change_Request_Action
    | Actions.UserSettings.UserSettings_Languaje_Change_Receive_Action
    | Actions.UserSettings.UserSettings_Languaje_Change_Fail_Action
    | Actions.UserSettings.UserSettings_Languaje_Request_Action
    | Actions.UserSettings.UserSettings_Languaje_Receive_Action
    | Actions.UserSettings.UserSettings_Languaje_Fail_Action;

export const UserSettingsReducer: Reducer<CurrentUserState> = (
    state: CurrentUserState | undefined,
    incomingAction: Action
): CurrentUserState => {
    if (state === undefined) {
        return CurrentUserUnloadedState as CurrentUserState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'USER_SETTINGS_LANGUAJE_REQUEST_ACTION':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'USER_SETTINGS_LAGUAJE_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
                CurrentUser: {...state.CurrentUser, UserLanguaje: action.Language } as UserSettings 
            };
        case 'USER_SETTINGS_LANGUAJE_FAIL_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'USER_SETTINGS_LANGUAJE_CHANGE_REQUEST_ACTION':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'USER_SETTINGS_LANGUAJE_CHANGE_RECEIVE_ACTION':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                error: undefined,
                CurrentUser: {...state.CurrentUser, UserLanguaje: action.Language} as UserSettings
            };
        case 'USER_SETTINGS_LANGUAJE_CHANGE_FAIL_ACTION':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        
        default:
            return state;
    }
}