import { ContractCommissionAgentConcept } from '../../models/Entities/Contracts/ContractCommissionAgentConcept';
import * as Actions from '../actions/index';
import { AppThunkAction } from '../reducers';

// services 
import * as Services from '../../services/ContractCommissionAgentConcept.Services';
import { ErrorType } from '../../models/HttpError';
import { NewContractCommissionAgentConcept } from '../../models/Entities/Contracts/NewContractCommissionAgentConcept';

// commission agent concept actions 

type KnownAction =
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetAll_ByCommissionAgentId_Request_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetAll_ByCommissionAgentId_Response_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetAll_ByCommissionAgentId_Failure_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetById_Request_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetById_Response_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetById_Failure_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Create_Request_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Create_Response_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Create_Failure_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Update_Request_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Update_Response_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Update_Failure_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Delete_Request_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Delete_Response_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Delete_Failure_Action;

export const actionCreators = {
    GetAllContractCommissionAgentConceptsByCommissionAgentId:
        (commissionAgentId: number, contractId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_ALL_BY_COMMISSION_AGENT_ID_FAILURE',
                    commissionAgentId: commissionAgentId,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_ALL_BY_COMMISSION_AGENT_ID_REQUEST',
                commissionAgentId: commissionAgentId
            });

            Services.GetContractCommissionAgentConceptsByAgentId(commissionAgentId, token)
                .then((contractCommissionAgentConcepts: ContractCommissionAgentConcept[]) => {
                    dispatch({
                        type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_ALL_BY_COMMISSION_AGENT_ID_SUCCESS',
                        contractid: contractId,
                        commissionAgentId: commissionAgentId,
                        contractCommissionAgentConcepts: contractCommissionAgentConcepts
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_ALL_BY_COMMISSION_AGENT_ID_FAILURE',
                        commissionAgentId: commissionAgentId,
                        error: error
                    })
                );
        },
    GetContractCommissionAgentConceptById:
        (contractCommissionAgentConceptId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_BY_ID_FAILURE',
                    contractCommissionAgentConceptId: contractCommissionAgentConceptId,
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_BY_ID_REQUEST',
                contractCommissionAgentConceptId: contractCommissionAgentConceptId
            });

            Services.GetContractCommissionAgentConceptById(contractCommissionAgentConceptId, token).then((contractCommissionAgentConcept: ContractCommissionAgentConcept) => {
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_BY_ID_SUCCESS',
                    contractCommissionAgentConcept: contractCommissionAgentConcept
                });
            }).catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_BY_ID_FAILURE',
                    contractCommissionAgentConceptId: contractCommissionAgentConceptId,
                    error: error
                })
            );
        },
    // create contract commission agent concept
    CreateContractCommissionAgentConcept:
        (contractCommissionAgentConcept: NewContractCommissionAgentConcept): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_CREATE_FAILURE',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_CREATE_REQUEST',
                contractCommissionAgentConcept: contractCommissionAgentConcept
            });

            Services.CreateContractCommissionAgentConcept(contractCommissionAgentConcept, token).then((contractCommissionAgentConcept: ContractCommissionAgentConcept) => {
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_CREATE_SUCCESS',
                    contractCommissionAgentConcept: contractCommissionAgentConcept
                });
            }).catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_CREATE_FAILURE',
                    error: error
                })
            );
        },
    // update contract commission agent concept
    UpdateContractCommissionAgentConcept:
        (contractCommissionAgentConcept: ContractCommissionAgentConcept): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_UPDATE_FAILURE',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_UPDATE_REQUEST',
                contractCommissionAgentConcept: contractCommissionAgentConcept
            });

            Services.UpdateContractCommissionAgentConcept(contractCommissionAgentConcept, token).then((contractCommissionAgentConcept: ContractCommissionAgentConcept) => {
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_UPDATE_SUCCESS',
                    contractCommissionAgentConcept: contractCommissionAgentConcept
                });
            }).catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_UPDATE_FAILURE',
                    error: error
                })
            );
        },
    // delete contract commission agent concept
    DeleteContractCommissionAgentConcept:
        (contractCommissionAgentConceptId: number, contractId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_DELETE_FAILURE',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            }

            dispatch({
                type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_DELETE_REQUEST',
                contractCommissionAgentConceptId: contractCommissionAgentConceptId
            });

            Services.DeleteContractCommissionAgentConcept(contractCommissionAgentConceptId, token).then(() => {
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_DELETE_SUCCESS',
                    contractId: contractId,
                    contractCommissionAgentConceptId: contractCommissionAgentConceptId
                });
            }).catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACT_COMMISSION_AGENT_CONCEPT_DELETE_FAILURE',
                    error: error
                })
            );
        }
};


