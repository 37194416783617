import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

// state
import { ProceduresState, ProceduresUnloadedState } from '../states/ProceduresState';

type KnownAction = 
    | Actions.Procedures.GetAllPrceduresByCriteria_Request_Action
    | Actions.Procedures.GetAllPrceduresByCriteria_Receive_Action
    | Actions.Procedures.GetAllPrceduresByCriteria_Failure_Action;

export const ProceduresReducer: Reducer<ProceduresState> = (
    state: ProceduresState | undefined,
    incomingAction: Action
): ProceduresState => {
    if (state === undefined) {
        return ProceduresUnloadedState as ProceduresState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'GET_ALL_PROCEDURES_BY_CRITERIA_REQUEST_ACTION':
            return {
                ...state,
                isLoadingAll: true,	
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined,
                list: []
            };
        case 'GET_ALL_PROCEDURES_BY_CRITERIA_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
                list: action.procedures
            };
        case 'GET_ALL_PROCEDURES_BY_CRITERIA_FAILURE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error,
                list: []
            };
        default:
            return state;
    }
}