import { ProviderAssociationsMedicalService } from './../../../models/Entities/ProviderAssociations/MedicalServices/ProviderAssociationMedicalService';
import { Provider } from './../../../models/Entities/Provider/Provider';
import { ErrorType } from '../../../models/HttpError';

//stores
import * as ProviderMedicalServiceAssociationStore from '../../../redux/middleware/ProviderMedicalServiceAssociationMiddleware';
import * as ProviderStore from '../../../redux/middleware/ProviderMiddleware';
import { AppState } from '../../../redux/reducers';
import { IIconProps } from '@fluentui/react';
import { SelectedMedicalServiceType } from '../../MedicalServices/MedicalServiceSelector/MedicalServiceMultiSelectorComponent';

//#region props

interface IProps {
  ProviderId: number;
}

interface ConnectedProps {
  //status
  IsLoading: boolean;
  LoadedSuccessfully: boolean;
  FailOnLoad: boolean;
  ErrorOnLoad: ErrorType;

  IsAssociating: boolean;
  AssociatedSuccessfully: boolean;
  FailOnAssociate: boolean;
  ErrorOnAssociate: ErrorType;

  IsDisassociating: boolean;
  DisassociatedSuccessfully: boolean;
  FailOnDisassociate: boolean;
  ErrorOnDisassociate: ErrorType;

  MedicalServicesAssociated: ProviderAssociationsMedicalService[];
  Provider: Provider[];
}

interface DispatchProps {
  AssociateMedicalServiceToProvider: typeof ProviderMedicalServiceAssociationStore.actionCreators.AssociateMedicalServiceToProvider;
  DisassociateMedicalServiceFromProviderByMedicalServiceId: typeof ProviderMedicalServiceAssociationStore.actionCreators.DisassociateMedicalServiceFromProviderByMedicalServiceId;
  GetMedicalServiceByProviderId: typeof ProviderMedicalServiceAssociationStore.actionCreators.GetMedicalServiceByProviderId;
  GetAllProviders: typeof ProviderStore.actionCreators.GetAllProviders;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  MedicalServicesAssociated: state.Provider?.list.find(
    (provider: Provider) => provider.id === ownProps.ProviderId
  )?.MedicalServicesAssociated,

  Providers: state?.Provider?.list,

  //load status
  IsLoading: state.ProviderMedicalServiceAssociations?.isLoadingAll,
  LoadedSuccessfully:
    state.ProviderMedicalServiceAssociations?.successLoadingAll,
  FailOnLoad: state.ProviderMedicalServiceAssociations?.failOnLoadingAll,
  ErrorOnLoad: state.ProviderMedicalServiceAssociations?.error,

  // associate status
  IsAssociating: state.ProviderMedicalServiceAssociations?.isAddingNewOne,
  AssociatedSuccessfully:
    state.ProviderMedicalServiceAssociations?.successAddingNewOne,
  FailOnAssociate: state.ProviderMedicalServiceAssociations?.failOnAddingNewOne,
  ErrorOnAssociate: state.ProviderMedicalServiceAssociations?.error,

  IsDisassociating: state.ProviderMedicalServiceAssociations?.isDeletingOne,
  DisassociatedSuccessfully:
    state.ProviderMedicalServiceAssociations?.successDeletingOne,
  FailOnDisassociate:
    state.ProviderMedicalServiceAssociations?.failOnDeletingOne,
  ErrorOnDisassociate: state.ProviderMedicalServiceAssociations?.error
});

export const mapDispatchToProps = {
  ...ProviderMedicalServiceAssociationStore.actionCreators,
  ...ProviderStore.actionCreators
};

//icons
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };

// functions

export function GetSelectedMedicalServices(
  MedicalServiceSelected: ProviderAssociationsMedicalService[]
): SelectedMedicalServiceType[] {
  return MedicalServiceSelected
    ? MedicalServiceSelected.map((mi: ProviderAssociationsMedicalService) => ({
        medicalServiceId: mi.medicalServiceId,
        ReferenceId: mi.id
      }))
    : [];
}
