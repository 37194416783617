import React from 'react';
import { ITag, Label, TagPicker } from '@fluentui/react';
import { useEffect, useState } from 'react';

//models
import { StageCycle } from '../../../../models/Entities/Contracts/StageCycle';

// own functions
import * as ownFunctions from './CycleSelectorFunctions';
import { connect } from 'react-redux';

const CycleSelectorComponent = (
  props: ownFunctions.Props
) => {

  const onChangeIdTextFieldValue = (items?: ITag[] | undefined) => {
    let CycleComboSelected: StageCycle[] = [];
    if (items && items.length > 0) {
      items.forEach((item: ITag) => {
        const CycleId = Number(item.key);
        const cycle: StageCycle = ownFunctions.GetCyclesById(CycleId, props.Cycles) as StageCycle;
        CycleComboSelected = [
          ...CycleComboSelected,
          cycle
        ];
        setInternalCyclesSelected(CycleComboSelected);
        setCyclesOptionsSelected(items);
      });
    } else {
      setInternalCyclesSelected([]);
      setCyclesOptionsSelected([]);
    }
  };

  const [CycleOptions, setCycleOptions] = useState<ITag[]>();
  const [CyclesOptionsSelected, setCyclesOptionsSelected] =
    useState<ITag[]>();

  const [InternalCyclesSelected, setInternalCyclesSelected] =
    useState<StageCycle[]>([]);

  useEffect(() => {
    setCycleOptions(ownFunctions.GetCycleOptions(props.Cycles));
  }, []);

  useEffect(() => {
    props.onSelectedCycles(InternalCyclesSelected);
  }, [InternalCyclesSelected]);

  useEffect(() => {
    if (props.CyclesSelected && CycleOptions) {
      const auxCycleSelected = CycleOptions.filter(
        (option: ITag) =>
          option.key ===
          props.CyclesSelected?.find((id: number) => id === option.key)
      );
      setCyclesOptionsSelected(auxCycleSelected);
      setInternalCyclesSelected(props.CyclesSelected.map((cycleId: number) => ownFunctions.GetCyclesById(cycleId, props.Cycles)));
    }
  }, [CycleOptions]);

  useEffect(() => {

  }, [props.CyclesSelected]);

  const filterSuggestedMedicalServices = async (
    filterText: string,
    tagList: ITag[] | undefined
  ): Promise<ITag[]> => {
    return filterText
      ? CycleOptions?.filter(
        (tag) =>
          tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
          !ownFunctions.listContainsTagList(tag, tagList)
      ) || []
      : [];
  };

  return (
    <>
      <Label htmlFor="SnomedOption">Seleccione un ciclo</Label>
      <TagPicker
        removeButtonAriaLabel="Eliminar"
        selectionAriaLabel="Seleccione un ciclo"
        onResolveSuggestions={filterSuggestedMedicalServices}
        getTextFromItem={ownFunctions.getTextFromItem}
        pickerSuggestionsProps={ownFunctions.pickerSuggestionsProps}
        itemLimit={props.MultiselectEnable ? undefined : 1}
        onChange={onChangeIdTextFieldValue}
        pickerCalloutProps={{ doNotLayer: false }}
        inputProps={{
          id: 'CycleOption'
        }}
        selectedItems={CyclesOptionsSelected}
      />
      {props.noteText && <small>{props.noteText}</small>}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(CycleSelectorComponent as any);
