import { Action, Reducer } from 'redux';

// Actions
import * as Actions from '../actions';
import { ContractAccessState, ContractAccessUnloadState } from '../states/ContractAccess.State';

type KnownAction =
    | Actions.ContractAccess.ContractAccessGetAllRequestAction
    | Actions.ContractAccess.ContractAccessGetAllSuccessAction
    | Actions.ContractAccess.ContractAccessGetAllFailureAction
    | Actions.ContractAccess.ContractAccessGetByIdRequestAction
    | Actions.ContractAccess.ContractAccessGetByIdSuccessAction
    | Actions.ContractAccess.ContractAccessGetByIdFailureAction
    | Actions.ContractAccess.ContractAccessCreateRequestAction
    | Actions.ContractAccess.ContractAccessCreateSuccessAction
    | Actions.ContractAccess.ContractAccessCreateFailureAction
    | Actions.ContractAccess.ContractAccessUpdateRequestAction
    | Actions.ContractAccess.ContractAccessUpdateSuccessAction
    | Actions.ContractAccess.ContractAccessUpdateFailureAction
    | Actions.ContractAccess.ContractAccessDeleteRequestAction
    | Actions.ContractAccess.ContractAccessDeleteSuccessAction
    | Actions.ContractAccess.ContractAccessDeleteFailureAction;

export const ContractAccessReducer: Reducer<ContractAccessState> = (
    state: ContractAccessState | undefined,
    incomingAction: Action
): ContractAccessState => {
    if (state === undefined) {
        return ContractAccessUnloadState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case Actions.ContractAccess.CONTRACT_ACCESS_GETALL_REQUEST:
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_GETALL_SUCCESS:
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_GETALL_FAILURE:
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_GETBYID_REQUEST:
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: undefined,
                failOnLoadingOne: false
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_GETBYID_SUCCESS:
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_GETBYID_FAILURE:
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_CREATE_REQUEST:
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_CREATE_SUCCESS:
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_CREATE_FAILURE:
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_UPDATE_REQUEST:
            return {
                ...state,
                isUpdatingOne: true,
                successUpdatingOne: undefined,
                failOnUpdatingOne: false
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: true,
                failOnUpdatingOne: false
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_UPDATE_FAILURE:
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: false,
                failOnUpdatingOne: true,
                error: action.error
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_DELETE_REQUEST:
            return {
                ...state,
                isDeletingOne: true,
                successDeletingOne: undefined,
                failOnDeletingOne: false
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_DELETE_SUCCESS:
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: true,
                failOnDeletingOne: false,
                list: state.list.filter((item) => item.id !== action.contractAccessId)
            };
        case Actions.ContractAccess.CONTRACT_ACCESS_DELETE_FAILURE:
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: false,
                failOnDeletingOne: true,
                error: action.error
            };
        default:
            return state;
    }
}