import {
  DefaultButton,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  IStackProps,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Modal,
  Panel,
  PrimaryButton,
  TextField,
  Toggle
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers';
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';
import { Provider } from '../../../models/Entities/Provider/Provider';
import { NewProvider } from '../../../models/Entities/Provider/NewProvider';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

//store
import * as ProviderStore from '../../../redux/middleware/ProviderMiddleware';


interface FormValidation {
  success: boolean;
  errors: string[];
}

export interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  Provider?: Provider;
}

export const ProviderFormComponent = (props: IProps) => {
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();

  const dispatch = useDispatch();

  //state
  const ProviderState = useSelector((appState: AppState) => appState.Provider);



  // entity properties
  const [isCompany, setIsCompany] = useState<boolean>(true);
  const [fullName, setFullName] = useState<string>();
  //const [firstName, setFirstName] = useState<string>();
  //const [lastName, setLastName] = useState<string>();

  //useTranslation();
  const [t] = useTranslation();

  // effects
  useEffect(() => {
    if (props.Provider) {
      setFullName(props.Provider.person?.businessName);
      //setFirstName(props.Provider.firstName);
      //setLastName(props.Provider.lastName);
    } else {
      ResetForm();
    }
  }, [props.Provider]);

  // Effects

  useEffect(() => {
    if (
      submittedForm &&
      ProviderState &&
      (ProviderState.successAddingNewOne || ProviderState.successUpdatingOne)
    ) {
      ResetForm();
      props.dismissPanel();
    }
  }, [
    ProviderState?.successAddingNewOne,
    ProviderState?.successUpdatingOne,
    ProviderState,
    submittedForm,
    props
  ]);

  const ResetForm = () => {
    setFullName('');
    //setFirstName('');
    //setLastName('');
    setSubmittedForm(false);
  };

  // Events

  const onChangeFullNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setFullName(newValue);
  };

  const onChangeFirstNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    //setFirstName(newValue);
  };

  const onChangeLastNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    //setLastName(newValue);
  };

  const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      let CurrentValues: Provider | NewProvider;
      if (props.Provider) {
      //   CurrentValues = {
      //     id: props.Provider.id,
      //     fullName: fullName!,
      //     firstName: firstName!,
      //     lastName: lastName!
      //   };
      //   dispatch(mapDispatchToProps.UpdateProvider(CurrentValues as Provider));
      } else {
        CurrentValues = {
          fullName: fullName!,
          firstName: '', //firstName!,
          lastName: '', //lastName!
        } as NewProvider;
        dispatch(mapDispatchToProps.AddProvider(CurrentValues as NewProvider));
      }
    }
  };

  const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
  };

  // footer
  const onRenderFooterContent = () => {
    return (
      <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={"providerForm.footer.cancelButton"}/>
        </DefaultButton>
        <PrimaryButton
          onClick={HandleSaveForm}
          styles={buttonStyles}
          className="ConfirmAction"
        >
          <Trans i18nKey={"providerForm.footer.saveButton"}/>
        </PrimaryButton>
      </div>
    );
  };

  const formFields = (
    <div {...columnProps}>
      {isCompany && (
        <TextField
          label={t('providerForm.formFields.businessName')}
          name="txt_fullName"
          required
          type="text"
          autoComplete="off"
          onChange={onChangeFullNameTextFieldValue}
          errorMessage={
            submittedForm === true && fullName === undefined
              ? t('providerForm.formFields.requiredField') 
              : undefined
          }
          value={fullName}
        />
      )}
      {/* {!isCompany && (
        <>
          {' '}
          <TextField
            label={t('providerForm.formFields.name')} 
            name="txt_firstName"
            required
            type="text"
            autoComplete="off"
            onChange={onChangeFirstNameTextFieldValue}
            errorMessage={
              submittedForm === true && firstName === undefined
                ?  t('providerForm.formFields.requiredField')
                : undefined
            }
            value={firstName}
          />
          <TextField
            label={t('providerForm.formFields.lastName')}
            name="txt_lastName"
            required
            type="text"
            autoComplete="off"
            onChange={onChangeLastNameTextFieldValue}
            errorMessage={
              submittedForm === true && lastName === undefined
                ?  t('providerForm.formFields.requiredField')
                : undefined
            }
            value={lastName}
          />
        </>
      )} */}
      <Toggle
        label={t('providerForm.formFields.type')} 
        defaultChecked={isCompany}
        onText={t('providerForm.formFields.company')}
        offText={t('providerForm.formFields.company')}
        onChange={() => setIsCompany(!isCompany)}
      />
    </div>
  );

  // form validation

  const ValidateForm = (): boolean => {
    let validationResult: boolean = true;

    let FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    if ((fullName === undefined || fullName === '') && isCompany) {
      validationResult = false;
      FormValidation.success = false;
      FormValidation.errors.push(t('providerForm.formValidation.requiredBusinessName'));
    }

    // if ((firstName === undefined || firstName === '') && !isCompany) {
    //   validationResult = false;
    //   FormValidation.success = false;
    //   FormValidation.errors.push(t('providerForm.formValidation.requiredName'));
    // }

    // if ((lastName === undefined || lastName === '') && !isCompany) {
    //   validationResult = false;
    //   FormValidation.success = false;
    //   FormValidation.errors.push(t('providerForm.formValidation.requiredLastName'));
    // }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  return (
    <Panel
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      headerText={t('providerForm.panel.supplierInformation')}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      {/* <div className={contentStyles.header}>
        <span>Información del Proveedor</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.dismissPanel}
        />
      </div> */}
      {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
           {t('providerForm.panel.reviewInformation')} 
          <ul>
            {FormValidationStatus?.errors.map(
              (Error: string, index: number) => (
                <li key={index}>{Error}</li>
              )
            )}
          </ul>
        </MessageBar>
      )}
      <div className={contentStyles.body}>
        {ProviderState &&
          (ProviderState.failOnAddingNewOne ||
            ProviderState.failOnUpdatingOne) &&
          ProviderState.error && (
            <ErrorMessageComponent Error={ProviderState.error} />
          )}
        {formFields}
      </div>
    </Panel>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.Provider
});

const mapDispatchToProps = {
  ...ProviderStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto'
  }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
