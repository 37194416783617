
// Functions
import { useEffect, useState } from 'react';
import * as ComponentFunctions from './SettlementResumeForm.Functions';
import { Settlement } from '../../../models/Entities/Settlements/Settlement';
import { useBoolean } from '@fluentui/react-hooks';
import { DefaultButton, MessageBar, MessageBarType, Panel, PrimaryButton, Spinner, SpinnerSize, TextField, Toggle } from '@fluentui/react';
import { use } from 'i18next';
import { NewSettlementResume } from '../../../models/Entities/SettlementResume/NewSettlementResume';
import { SettlementResume } from '../../../models/Entities/SettlementResume/SettlementResume';
import { connect } from 'react-redux';


interface FormValidation {
    success: boolean;
    errors: string[];
}

const SettlementResumeFormComponent = (props: ComponentFunctions.Props) => {

    const [selectedSettlement, setSelectedSettlement] = useState<Settlement | undefined>(undefined);
    const [selectedResumeId, setSelectedResumeId] = useState<number | undefined>(undefined);

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
        useBoolean(false);

    // form status
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    // initial values
    useEffect(() => {
        if (props.SettlementResume) {
            setDescription(props.SettlementResume.description);
            setComments(props.SettlementResume.comments);
            setHaveWarning(props.SettlementResume.haveWarning);
        }
    }, [props.SettlementResumeId]);

    // control status
    useEffect(() => {
        if (props.isSuccessfullyAdded || props.isSuccessfullyUpdated) {
            props.dismissPanel();
        }
    }, [props.isSuccessfullyAdded, props.isSuccessfullyUpdated]);

    // form properties
    const [description, setDescription] = useState<string>();
    const [comments, setComments] = useState<string>();
    const [haveWarning, setHaveWarning] = useState<boolean>(false);

    // form events 
    const onChangeDescription = (event: any, newValue?: string) => {
        setDescription(newValue);
    };

    const onChangeComments = (event: any, newValue?: string) => {
        setComments(newValue);
    };

    const onChangeHaveWarning = (event: any, newValue?: boolean) => {
        setHaveWarning(newValue || false);
    };

    const FormFields = (
        <>
            <TextField
                label="Nombre"
                name="resume-name"
                required
                type="text"
                autoComplete="off"
                onChange={onChangeDescription}
                errorMessage={
                    submittedForm === true && description === undefined
                        ? 'Campo requerido'
                        : undefined
                }
                value={description}
            />
            <Toggle label="¿Marcar como observado?" onChange={onChangeHaveWarning} checked={haveWarning} />
            <TextField
                label="Comentarios"
                name="resume-comments"
                multiline
                rows={3}
                type="text"
                autoComplete="off"
                onChange={onChangeComments}
                value={comments}
            />
        </>
    );

    // form validation
    const ValidateForm = (): boolean => {
        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        if ((description === undefined || description === '')) {
            validationResult = false;
            FormValidation.errors.push('Debe informar un nombre');
        }
        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };

    const ResetForm = () => {
        setSubmittedForm(false);
        setDescription(undefined);
        setComments(undefined);
        setHaveWarning(false);
    }

    // footer
    const onRenderFooterContent = () => {
        return (
            <div className={ComponentFunctions.contentStyles.footer}>
                <DefaultButton onClick={HandleCancelForm} className="OtherOption">
                    Cancelar
                </DefaultButton>
                <PrimaryButton
                    onClick={HandleSaveForm}
                    styles={ComponentFunctions.buttonStyles}
                    className="ConfirmAction"
                    disabled={props.isAdding}
                >
                    {props.isAdding && <><Spinner size={SpinnerSize.small} />&nbsp;</>}
                    Guardar
                </PrimaryButton>

            </div>
        );
    };

    const HandleCancelForm = () => {
        ResetForm();
        props.dismissPanel();
    };

    const HandleSaveForm = () => {
        setSubmittedForm(true);
        if (ValidateForm()) {

            if (props.SettlementResumeId) {

                const UpdateSettlementResume: SettlementResume = {
                    id: props.SettlementResumeId!,
                    settlementId: props.SettlementId!,
                    description: description!,
                    comments: comments || '',
                    haveWarning: haveWarning,
                    unitValue: props.SettlementResume?.unitValue || 0,
                    //amount: props.SettlementResume?.amount || 0,
                    //total: props.SettlementResume?.total || 0,
                    //currency: props.Settlement?.currency || '',
                }

                props.UpdateSettlementResume(UpdateSettlementResume);
            } else {

                const NewSettlementResume: NewSettlementResume = {
                    settlementId: props.SettlementId!,
                    description: description!,
                    comments: comments || '',
                    haveWarning: haveWarning,
                    unitValue: props.SettlementResume?.unitValue || 0,
                    //amount: props.SettlementResume?.amount || 0,
                    //total: props.SettlementResume?.total || 0,
                    //currency: props.Settlement?.currency || '',
                }

                props.AddSettlementResume(NewSettlementResume);
            }
        };

    }

    return (
        <Panel
            isOpen={props.isOpen}
            onDismiss={props.dismissPanel}
            headerText="Información del item resumen"
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
        >
        
            {submittedForm && !FormValidationStatus?.success && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    Revise la información suministrada:
                    <ul>
                        {FormValidationStatus?.errors?.map(
                            (Error: string, index: number) => (
                                <li key={index}>{Error}</li>
                            )
                        )}
                    </ul>
                </MessageBar>
            )}
            {FormFields}
        </Panel>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementResumeFormComponent as any)

