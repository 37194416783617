import {
  FontSizes,
  Icon,
  Label,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Text
} from '@fluentui/react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import MedicalServiceMultiSelectorComponent, {
  SelectedMedicalServiceType
} from '../../MedicalServices/MedicalServiceSelector/MedicalServiceMultiSelectorComponent';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// functions
import * as ComponentFunction from './ProviderMedicalServiceAssociatedFunction';

// styles
import './ProviderMedicalServiceAssociated.css';
import { ProviderAssociationsMedicalService } from '../../../models/Entities/ProviderAssociations/MedicalServices/ProviderAssociationMedicalService';

export const ProviderMedicalServiceAssociatedComponent = (
  props: ComponentFunction.Props
) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

    //useTranslation();
    const [t] = useTranslation();

  useEffect(() => {
    props.GetMedicalServiceByProviderId(props.ProviderId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ProviderId]);

  //event handlers

  const onAssociateMedicalService = (
    medicalService: AvailableMedicalService
  ) => {
    props.AssociateMedicalServiceToProvider({
      providerId: props.ProviderId,
      medicalServiceId: medicalService.id
    });
  };

  const onDisassociateMedicalService = (
    medicalService: SelectedMedicalServiceType
  ) => {
    props.DisassociateMedicalServiceFromProviderByMedicalServiceId(
      props.ProviderId,
      medicalService.medicalServiceId
    );
  };

  // Render blocks

  const LoadingBlock = (
    <div className="LoadingBlock">
      <Spinner size={SpinnerSize.large} label={t('providerMedicalServiceAssociated.blocks.loadingBlock')}/>
    </div>
  );

  const ControlsBlock = (
    <div className="ControlsBlock">
      <PrimaryButton
        iconProps={ComponentFunction.AddIcon}
        text={t('providerMedicalServiceAssociated.blocks.button.selectMedicalService')}
        onClick={openPanel}
        className="actionButton"
      />
      <MedicalServiceMultiSelectorComponent
        isOpen={isOpen}
        openPanel={openPanel}
        dismissPanel={dismissPanel}
        SelectedServices={ComponentFunction.GetSelectedMedicalServices(
          props.MedicalServicesAssociated
        )}
        ErrorInformed={props.ErrorOnAssociate || props.ErrorOnDisassociate}
        ViewOnly3erPartyServices={true}
        onSelect={onAssociateMedicalService}
        onUnselect={onDisassociateMedicalService}
      />
    </div>
  );

  const MedicalInsuranceAssociatedBlock = (
    <div className="MedicalServiceAssociatedBlock">
      <ul>
        {props.MedicalServicesAssociated?.map(
          (medicalService: ProviderAssociationsMedicalService) => (
            <li key={medicalService.id}>
              <Icon
                iconName="FollowUser"
                style={{ fontSize: FontSizes.size24 }}
              />
              <div>
                <Label style={{ fontSize: FontSizes.size16 }}>
                  {medicalService.medicalService.serviceNameReference}
                </Label>
                {medicalService.medicalService.available ? (
                  <Text>{medicalService.medicalService.comments}</Text>
                ) : (
                  <Text style={{ color: 'red' }}>
                    {t('providerMedicalServiceAssociated.blocks.disabledMedicalService')}
                  </Text>
                )}
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );

  const NoContentBlock = (
    <div className="NoData">
      <Separator />
      <label>{t('providerMedicalServiceAssociated.blocks.MedicalServiceSupplier')}</label>
    </div>
  );

  return (
    <>
      {ControlsBlock}
      {props.IsLoading
        ? LoadingBlock
        : props.LoadedSuccessfully &&
          props.MedicalServicesAssociated?.length > 0
        ? MedicalInsuranceAssociatedBlock
        : NoContentBlock}
    </>
  );
};

export default connect(
  ComponentFunction.mapStateToProps,
  ComponentFunction.mapDispatchToProps
)(ProviderMedicalServiceAssociatedComponent as any);
