import { NewMedicalInsurance } from './../../models/Entities/MedicalInsurances/NewMedicalInsurance';
import { AppThunkAction } from '../reducers';
import { ErrorType } from '../../models/HttpError';
import { MedicalInsurance } from './../../models/Entities/MedicalInsurances/MedicalInsurance';

// services
import * as Services from '../../services/MedicalInsurances.Services';

// actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.MedicalInsurance.MedicalInsuranceAdd_Request_Action
  | Actions.MedicalInsurance.MedicalInsuranceAdd_Receive_Action
  | Actions.MedicalInsurance.MedicalInsuranceAdd_Fail_Action
  | Actions.MedicalInsurance.MedicalInsuranceUpdate_Request_Action
  | Actions.MedicalInsurance.MedicalInsuranceUpdate_Receive_Action
  | Actions.MedicalInsurance.MedicalInsuranceUpdate_Fail_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetAll_Request_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetAll_Receive_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetAll_Fail_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetById_Request_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetById_Receive_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetById_Fail_Action
  | Actions.MedicalInsurance.MedicalInsuranceDelete_Request_Action
  | Actions.MedicalInsurance.MedicalInsuranceDelete_Receive_Action
  | Actions.MedicalInsurance.MedicalInsuranceDelete_Fail_Action;

export const actionCreators = {
  GetAllMedicalInsurances:
    (pageNumber: number, pageSize: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_GET_ALL_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_GET_ALL_REQUEST_ACTION'
      });

      Services.GetMedicalInsurances(pageNumber, pageSize, undefined, token)
        .then((medicalInsurances: MedicalInsurance[]) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_GET_ALL_RECEIVE_ACTION',
            medicalInsuranceList: medicalInsurances
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_GET_ALL_FAIL_ACTION',
            error: error
          })
        );
    },
  GetMedicalInsuranceById:
    (Id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_GET_BY_ID_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_GET_BY_ID_REQUEST_ACTION',
        MedicalInsuranceId: Id
      });

      Services.GetMedicalInsuranceById(Id, token)
        .then((medicalInsurance: MedicalInsurance) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_GET_BY_ID_RECEIVE_ACTION',
            medicalInsurance: medicalInsurance
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_GET_BY_ID_FAIL_ACTION',
            error: error
          })
        );
    },
  AddMedicalInsurance:
    (NewMedicalInsurance: NewMedicalInsurance): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_ADD_REQUEST_ACTION',
        newMedicalInsurance: NewMedicalInsurance
      });

      Services.AddMedicalInsurance(NewMedicalInsurance, token)
        .then((_medicalInsurance: MedicalInsurance) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_ADD_RECEIVE_ACTION',
            MedicalInsurance: _medicalInsurance
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_ADD_FAIL_ACTION',
            error: error
          })
        );
    },
  UpdateMedicalInsurance:
    (medicalInsurance: MedicalInsurance): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      
      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_UPDATE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };	

      dispatch({
        type: 'MEDICAL_INSURANCE_UPDATE_REQUEST_ACTION',
        MedicalInsurance: medicalInsurance
      });
      Services.UpdateMedicalInsurance(medicalInsurance, token)
        .then((medicalInsurance: MedicalInsurance) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_UPDATE_RECEIVE_ACTION',
            MedicalInsurance: medicalInsurance
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_UPDATE_FAIL_ACTION',
            error: error
          })
        );
    },
  DeleteMedicalInsurance:
    (MedicalInsurance: MedicalInsurance): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_DELETE_REQUEST_ACTION',
        MedicalInsurance: MedicalInsurance
      });
      Services.DeleteMedicalInsurance(MedicalInsurance.id, token)
        .then(() => {
          dispatch({
            type: 'MEDICAL_INSURANCE_DELETE_RECEIVE_ACTION',
            MedicalInsurance: MedicalInsurance
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_DELETE_FAIL_ACTION',
            error: error
          })
        );
    }
};
