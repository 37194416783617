import { connect } from 'react-redux';
import AgreementServiceDetailFormComponent from '../AgreementServiceDetailForm/AgreementServiceDetailFormComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { AgreementServicesDetail } from '../../../models/Entities/AgreementServices/AgreementServicesDetail';
import { IconButton, Toggle } from '@fluentui/react';

// Functions
import * as ComponentFunctions from './AgreementServiceDetailsItemComponentFunctions';

export const AgreementServiceDetailItemComponent = (
  props: ComponentFunctions.Props
) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const ChangeStatusHandler = (details: AgreementServicesDetail) => {
    details.isActive = !details.isActive;
    props.UpdateAgreementServiceDetail(details);
  };

  return (
    <>
      {props.AgreementServiceDetail ? (
        <div className="serviceDetailsOptions">
          <Toggle
            checked={props.AgreementServiceDetail?.isActive}
            onClick={() => ChangeStatusHandler(props.AgreementServiceDetail)}
          />
          <IconButton
            iconProps={ComponentFunctions.ViewIcon}
            aria-label="Propiedades"
            onClick={() => openPanel()}
          />
        </div>
      ) : (
        <Toggle checked={false} onClick={() => openPanel()} />
      )}
      {isOpen && (
        <AgreementServiceDetailFormComponent
          dismissPanel={dismissPanel}
          openPanel={() => undefined}
          insurancePlanId={props.Plan?.id}
          insurancePlanReferenceName={props.Plan?.title}
          AgreementService={props.Service}
          isOpen={isOpen}
          SelectedDetails={props.AgreementServiceDetail}
        />
      )}
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(AgreementServiceDetailItemComponent as any);
