import { AgreementServicesDetail } from '../../models/Entities/AgreementServices/AgreementServicesDetail';
import { CommonState } from '../common/state/commonState';

// define state of AgreementServiceDetailsState here
export interface AgreementServiceDetailsState
  extends CommonState<AgreementServicesDetail> {}

// define initial state of AgreementServiceDetailsState here
export const AgreementServiceDetailsUnloadedState: any = {
  // loading all
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  FailGettingAll: false,

  // loading one
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  FailGettingOne: false,

  // saving
  isSaving: false,
  isSavingSuccess: undefined,
  FailSaving: false,

  // adding
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,

  // deleting
  isDeletingOne: false,
  successDeletingOne: undefined,
  failOnDeletingOne: false,

  isFiltered: false,
  filterCriteria: undefined,

  list: [],
  Error: undefined
};
