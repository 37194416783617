import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';

// stores
import * as MedicalServiceStore from '../../../redux/middleware/MedicalServicesMiddleware';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';

//#region Props

interface IProps {
  editEvent?: (service: AvailableMedicalService) => void;
  searchCriteria?: string;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  isDeleting: boolean;
  isDeletedSuccessfully: boolean;
  failOnDeleting: boolean;
  error: ErrorType | undefined;
  MedicalServices: AvailableMedicalService[];
}

interface MedicalServicesDispatchProps {
  GetAllMedicalServices: typeof MedicalServiceStore.actionCreators.GetAllMedicalServices;
  ChangeMedicalServiceStatus: typeof MedicalServiceStore.actionCreators.ChangeMedicalServiceStatus;
  DeleteMedicalService: typeof MedicalServiceStore.actionCreators.DeleteMedicalService;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.MedicalServices?.isLoadingAll,
  isUpdating: state.MedicalServices?.isLoadingAll,
  isUpdatedSuccessfully: state.MedicalServices?.successLoadingAll,
  isLoadingSuccessfully: state.MedicalServices?.successLoadingAll,
  failOnUpdating: state.MedicalServices?.failOnLoadingAll,
  failOnLoading: state.MedicalServices?.failOnLoadingAll,
  isDeleting: state.MedicalServices?.isDeletingOne,
  isDeletedSuccessfully: state.MedicalServices?.successDeletingOne,
  failOnDeleting: state.MedicalServices?.failOnDeletingOne,
  error: state.MedicalServices?.error,
  MedicalServices: state.MedicalServices?.list
});

export const mapDispatchToProps = {
  ...MedicalServiceStore.actionCreators
};

//#endregion Props
