import { IDropdownOption } from '@fluentui/react';
import { Languaje } from '../../models/Entities/Settings/Languajes';
import { ErrorType } from '../../models/HttpError';
import { AppState } from '../../redux/reducers';

//stores
import * as LanguajesStore from '../../redux/middleware/LanguajeMiddleware';
import * as UserSettingsStore from '../../redux/middleware/UserMiddleware';

// services
import * as Services from '../../services/Languajes.Services';


//#region props
interface IProps {}

interface ConnectedProps {
  //status
  IsLoading: boolean;
  LoadedSuccessfully: boolean;
  FailOnLoad: boolean;
  ErrorOnLoad: ErrorType;

  Languajes: Languaje[];
  LanguajeOptions: IDropdownOption[];
  currentLanguaje: Languaje;
}

interface DispatchProps {
    GetAllLanguajes: typeof LanguajesStore.actionCreators.GetAllLanguajes;
    SetUserLanguaje: typeof UserSettingsStore.ActionCreators.SetUserLanguaje;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    IsLoading: state.Language?.isLoadingAll,
    LoadedSuccessfully: state.Language?.successLoadingAll,
    FailOnLoad: state.Language?.failOnLoadingAll,
    ErrorOnLoad: state.Language?.error,
  
    Languajes: state.Language?.list,
    LanguajeOptions: state.Language?.list?.map((languaje) => {
      return {
        key: languaje.code,
        text: languaje.description
      };
    }),
    currentLanguaje: state.UserSettings?.CurrentUser?.UserLanguaje as Languaje
});

export const mapDispatchToProps = {
  ...LanguajesStore.actionCreators,
  ...UserSettingsStore.ActionCreators
};

export const GetCurrentUserLanguaje = async () => {
  return await Services.GetCurrentUserLanguaje();
}


