import { AppState } from "../../../../redux/reducers";

// stores
import * as ProtocolStore from '../../../../redux/middleware/MedicalProtocolMiddleware';
import * as ContractConceptStore from '../../../../redux/middleware/ContractConceptMiddleware';
import * as ContractCommisionAgentStore from '../../../../redux/middleware/ContractCommissionAgentMiddleware';
import * as ContrectStore from '../../../../redux/middleware/ContractMiddleware';
import * as ConrtractCommissionAgentConceptStore from '../../../../redux/middleware/ContractCommissionAgentConceptMiddleware';

// models
import { ContractCommissionAgentConcept } from "../../../../models/Entities/Contracts/ContractCommissionAgentConcept";
import { ErrorType } from "../../../../models/HttpError";
import { Protocol } from "../../../../models/Entities/Protocols/Protocol";
import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractCommissionAgent } from "../../../../models/Entities/Contracts/ContractCommissionAgent";


interface IProps {
  CommissionAgentId: number | undefined;
  PractitionerId: string | undefined;
  ContractId: number;
  ProtocolId: number;
  disabled?: boolean;
};

interface ConnectedProps {
    isLoading: boolean;
    LoadedSuccessfully: boolean | undefined;
    FailOnLoading: boolean;
    ErrorOnLoading: ErrorType | undefined;
    Protocol: Protocol;
    Contract: Contract;
    Concepts: ContractCommissionAgentConcept[];
    Agent: ContractCommissionAgent | undefined;
};

interface MedicalServicesDispatchProps {
    GetMedicalProtocolById: typeof ProtocolStore.actionCreators.GetMedicalProtocolById;
    GetAllContractsConcept: typeof ContractConceptStore.actionCreators.GetAllContractsConcept;
    GetAllContractCommissionAgents: typeof ContractCommisionAgentStore.actionCreators.GetAllContractCommissionAgents;
    GetAllContracts: typeof ContrectStore.actionCreators.GetAllContracts;
    GetAllContractCommissionAgentConceptsByCommissionAgentId: typeof ConrtractCommissionAgentConceptStore.actionCreators.GetAllContractCommissionAgentConceptsByCommissionAgentId;
};


export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.ContractCommissionAgentConcept?.isLoadingAll,
    LoadedSuccessfully: state.ContractCommissionAgentConcept?.successLoadingAll,
    FailOnLoading: state.ContractCommissionAgentConcept?.failOnLoadingAll,
    ErrorOnLoading: state.ContractCommissionAgentConcept?.error,
    Protocol: state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) as Protocol,
    Contract: state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId) as Contract,
    Agent: state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId)?.commissionAgents?.find((commissionAgent: ContractCommissionAgent) => commissionAgent.id === ownProps.CommissionAgentId) as ContractCommissionAgent,
    Concepts: state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId)?.commissionAgents?.find((commissionAgent: ContractCommissionAgent) => commissionAgent.id === ownProps.CommissionAgentId)?.Concepts as ContractCommissionAgentConcept[] || []
})

export const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {

    ownProps.CommissionAgentId && dispatch(ConrtractCommissionAgentConceptStore.actionCreators.GetAllContractCommissionAgentConceptsByCommissionAgentId(ownProps.CommissionAgentId, ownProps.ContractId));

    return {
    ...ProtocolStore.actionCreators,
    ...ContractConceptStore.actionCreators,
    ...ContractCommisionAgentStore.actionCreators,
    ...ContrectStore.actionCreators,
    ...ConrtractCommissionAgentConceptStore.actionCreators,
    GetAllContractCommissionAgentConceptsByCommissionAgentId: (CommissionAgentid: number, ContractId: number) => dispatch(ConrtractCommissionAgentConceptStore.actionCreators.GetAllContractCommissionAgentConceptsByCommissionAgentId(CommissionAgentid, ContractId)),
    }
};
