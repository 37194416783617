import { ContractStage } from "../../models/Entities/Contracts/ContractStage";
import { CommonState } from "../common/state/commonState";


export interface ContractStageState extends CommonState<ContractStage> {
    // updating all
    isUpdatingAll: boolean;
    isUpdatingAllSuccess: boolean|undefined;
    FailUpdatingAll: boolean;

    // AddRange
    isAddingFinishedSuccessfully: boolean | undefined;
}

export const ContractStageUnloadState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,

    // updating all
    isUpdatingAll: false,
    isUpdatingAllSuccess: undefined,
    FailUpdatingAll: false,

    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,

    // saving
    isSaving: false,
    isSavingSuccess: undefined,
    FailSaving: false,
    
    // adding
    isAdding: false,
    isAddingSuccess: undefined,
    FailAdding: false,

    // deleting
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    FailDeletingOne: false, 

    // AddRange
    isAddingFinishedSuccessfully: undefined
};