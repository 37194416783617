import {
  MedicalInsuranceState,
  MedicalInsuranceUnloadState
} from './../states/MedicalInsuranceState';
import { MedicalInsurance } from './../../models/Entities/MedicalInsurances/MedicalInsurance';
import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';
import { FaPlaneSlash } from 'react-icons/fa';
import { MedicalInsurancePlan } from '../../models/Entities/MedicalInsurances/MedicalInsurancePlan';

type KnownAction =
  | Actions.MedicalInsurance.MedicalInsuranceGetAll_Request_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetAll_Receive_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetAll_Fail_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetById_Request_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetById_Receive_Action
  | Actions.MedicalInsurance.MedicalInsuranceGetById_Fail_Action
  | Actions.MedicalInsurance.MedicalInsuranceAdd_Request_Action
  | Actions.MedicalInsurance.MedicalInsuranceAdd_Receive_Action
  | Actions.MedicalInsurance.MedicalInsuranceAdd_Fail_Action
  | Actions.MedicalInsurance.MedicalInsuranceUpdate_Request_Action
  | Actions.MedicalInsurance.MedicalInsuranceUpdate_Receive_Action
  | Actions.MedicalInsurance.MedicalInsuranceUpdate_Fail_Action
  | Actions.MedicalInsurance.MedicalInsuranceDelete_Request_Action
  | Actions.MedicalInsurance.MedicalInsuranceDelete_Receive_Action
  | Actions.MedicalInsurance.MedicalInsuranceDelete_Fail_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansAdd_Receive_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansUpdate_Receive_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetById_Receive_Action;

export const MedicalInsuranceReducer: Reducer<MedicalInsuranceState> = (
  state: MedicalInsuranceState | undefined,
  incomingAction: Action
): MedicalInsuranceState => {
  if (state === undefined) {
    return MedicalInsuranceUnloadState as MedicalInsuranceState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'MEDICAL_INSURANCE_GET_ALL_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_GET_ALL_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined,
        list: action.medicalInsuranceList.sort(
          Functions.DynamicSort('businessName')
        )
      };
    case 'MEDICAL_INSURANCE_GET_ALL_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_GET_BY_ID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_GET_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (item: MedicalInsurance) => item.id !== action.medicalInsurance.id
          ),
          action.medicalInsurance
        ]
      };
    case 'MEDICAL_INSURANCE_GET_BY_ID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [
          ...(state.list.filter(
            (item: MedicalInsurance) => item.id !== action.MedicalInsurance.id
          ) || []),
          action.MedicalInsurance
        ].sort(Functions.DynamicSort('businessName'))
      };
    case 'MEDICAL_INSURANCE_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (item: MedicalInsurance) => item.id !== action.MedicalInsurance.id
          ),
          action.MedicalInsurance
        ].sort(Functions.DynamicSort('businessName'))
      };
    case 'MEDICAL_INSURANCE_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'MEDICAL_INSURANCE_DELETE_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: state.list.filter(
          (item: MedicalInsurance) => item.id !== action.MedicalInsurance.id
        )
      };
    case 'MEDICAL_INSURANCE_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
    case 'MEDICAL_INSURANCE_PLANS_ADD_RECEIVE_ACTION':
      const auxMedicalInsurance = state.list.find(
        (item: MedicalInsurance) =>
          item.id === action.MedicalInsurancePlan.medicalInsuranceId
      );

      return {
        ...state,
        list: [
          ...state.list.filter(
            (item: MedicalInsurance) =>
              item.id !== action.MedicalInsurancePlan.medicalInsuranceId
          ),
          {
            ...state.list.find(
              (item: MedicalInsurance) =>
                item.id === action.MedicalInsurancePlan.medicalInsuranceId
            ),
            plans: [
              ...(auxMedicalInsurance?.plans || []),
              action.MedicalInsurancePlan
            ]
          } as MedicalInsurance
        ].sort(Functions.DynamicSort('businessName'))
      };
    case 'MEDICAL_INSURANCE_PLANS_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        list: [
          ...state.list.filter(
            (item: MedicalInsurance) =>
              item.id !== action.Plan.medicalInsuranceId
          ),
          {
            ...state.list.find(
              (item: MedicalInsurance) =>
                item.id === action.Plan.medicalInsuranceId
            ),
            plans: [
              ...(
                state.list.find(
                  (item: MedicalInsurance) =>
                    item.id === action.Plan.medicalInsuranceId
                )?.plans || []
              ).filter(
                (item: MedicalInsurancePlan) => item.id !== action.Plan.id
              ),
              action.Plan
            ]
          } as MedicalInsurance
        ].sort(Functions.DynamicSort('businessName'))
      };
    case 'MEDICAL_INSURANCE_PLANS_GET_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        list: [
          ...state.list.filter(
            (item: MedicalInsurance) =>
              item.id !== action.MedicalInsurancePlan.medicalInsuranceId
          ),
          {
            ...state.list.find(
              (item: MedicalInsurance) =>
                item.id === action.MedicalInsurancePlan.medicalInsuranceId
            ),
            plans: [
              ...(state.list.find(
                (item: MedicalInsurance) =>
                  item.id === action.MedicalInsurancePlan.medicalInsuranceId
              )?.plans || []),
              action.MedicalInsurancePlan
            ]
          } as MedicalInsurance
        ].sort(Functions.DynamicSort('businessName'))
      };
  }

  return state;
};
