// store
import * as ContractStageStore from "../../../../../redux/middleware/ContractStageMiddleware";
import * as ContractConceptPaymentScheduleStore from "../../../../../redux/middleware/ContractConceptPaymentScheduleMiddleware";

// models
import { ContractConceptPaymentSchedule } from "../../../../../models/Entities/Contracts/ContractConceptPaymentSchedule";
import { ContractStage } from "../../../../../models/Entities/Contracts/ContractStage";
import { AppState } from "../../../../../redux/reducers";
import { ErrorType } from "../../../../../models/HttpError";

interface ContractStageSelection extends ContractStage {
    isSelected: boolean;
    onAlreadySaved: (ready: boolean) => void;
}

//#region Props
interface IProps {
    ContractId: number;
    ContractAddendumId: number;
    StageId: number;
    ContractConceptId?: number | undefined;
    ShowInputValues: boolean;
    CurrencyCode: string;
    ConceptSaved: boolean;
    isSavedItem: (stageId: number) => void;
}
  
interface ConnectedProps {
    Stage: ContractStageSelection;
    CurrentConceptPaymentSchedule: ContractConceptPaymentSchedule;

    //status
    isSaving?: boolean;
    isSavedSuccessfully?: boolean | undefined;
    FailOnSaving?: boolean;
    errorOnSaving?: ErrorType | undefined;

    isUpdating?: boolean;
    isUpdatedSuccessfully?: boolean | undefined;
    FailOnUpdating?: boolean;
    errorOnUpdating?: ErrorType | undefined

    isDeleting?: boolean;
    isDeletedSuccessfully?: boolean | undefined;
    FailOnDeleting?: boolean;
    errorOnDeleting?: ErrorType | undefined;
}

interface MedicalServicesDispatchProps {
    GetContractStageById: typeof ContractStageStore.actionCreators.GetContractStageById;
    GetAllContractsConceptPaymentSchedule: typeof ContractConceptPaymentScheduleStore.actionCreators.GetAllContractsConceptPaymentSchedule;
    UpdateContractConceptPaymentSchedule: typeof ContractConceptPaymentScheduleStore.actionCreators.UpdateContractConceptPaymentSchedule;
    AddContractConceptPaymenSchedule: typeof ContractConceptPaymentScheduleStore.actionCreators.AddContractConceptPaymenSchedule;
    DeleteContractConceptPaymentSchedule: typeof ContractConceptPaymentScheduleStore.actionCreators.DeleteContractConceptPaymentSchedule;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const CurrentContract = state.Contract?.list?.find((item: any) => item.id === ownProps.ContractId ) || undefined;
    const CurrentAddendum = CurrentContract?.addendums?.find((item: any) => item.id === ownProps.ContractAddendumId) || undefined;
    const CurrentStage = CurrentAddendum?.stages?.find((item: any) => item.id === ownProps.StageId) || undefined;
    const CurrentConcept = CurrentAddendum?.concepts?.find((item: any) => item.id === ownProps.ContractConceptId) || undefined;
    const CurrentConceptPaymentSchedule = CurrentConcept?.paymentSchedule?.find((item: ContractConceptPaymentSchedule) => item.contractStageId === ownProps.StageId) || undefined;

    return {
        Stage: CurrentStage,
        CurrentConceptPaymentSchedule: CurrentConceptPaymentSchedule,

        //status
        isSaving: state.ContractConceptPaymentSchedule?.isAddingNewOne || false,
        isSavedSuccessfully: state.ContractConceptPaymentSchedule?.successAddingNewOne || undefined,
        FailOnSaving: state.ContractConceptPaymentSchedule?.failOnAddingNewOne || false,
        errorOnSaving: state.ContractConceptPaymentSchedule?.error || undefined,

        isUpdating: state.ContractConceptPaymentSchedule?.isUpdatingOne || CurrentConceptPaymentSchedule?.isUpdating || false,
        isUpdatedSuccessfully: state.ContractConceptPaymentSchedule?.successUpdatingOne || CurrentConceptPaymentSchedule?.isUpdatedSuccessfully || undefined,
        FailOnUpdating: state.ContractConceptPaymentSchedule?.failOnUpdatingOne || CurrentConceptPaymentSchedule?.failOnUpdating || false,
        errorOnUpdating: state.ContractConceptPaymentSchedule?.error || CurrentConceptPaymentSchedule?.errorOnUpdating || undefined,

        isDeleting: CurrentConceptPaymentSchedule?.isDeleting || false, 
        isDeletedSuccessfully: state.ContractConceptPaymentSchedule?.successDeletingOne || CurrentConceptPaymentSchedule?.isDeletedSuccessfully || undefined, 
        FailOnDeleting: CurrentConceptPaymentSchedule?.failOnDeleting || false,
        errorOnDeleting: CurrentConceptPaymentSchedule?.errorOnDeleting
    }
};

export const mapDispatchToProps = {
    ...ContractStageStore.actionCreators,
    ...ContractConceptPaymentScheduleStore.actionCreators
};