import { useEffect } from 'react';
import { Label, Pivot, PivotItem } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// models
import { MedicalInsurance } from '../../models/Entities/MedicalInsurances/MedicalInsurance';

// components
import { MedicalInsurancePlanPanel } from '../../components/MedicalInsurance/MedicalInsurancePlansPanel/MedicalInsurancePlanPanel';
import MedicalInsuranceAgreementListComponent from '../../components/MedicalInsuranceAgreement/MedicalInsuranceAgreementList/MedicalInsuranceAgreementListComponent';

// functions
import * as ownFunctions from './MedicalInsuranceDetailFunctions';
import { PageHeader } from '../../commons/component/PageHeader/PageHeader';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const MedicalInsuranceDetailPage = (props: ownFunctions.Props) => {
  const { MedicalInsuranceId } = useParams();

  //useTranslation()
  const [t] = useTranslation();
 
  const SelectedMedicalInsurance: MedicalInsurance = useSelector((state: any) =>
    state.MedicalInsurances.list.find(
      (item: MedicalInsurance) => item.id === Number(MedicalInsuranceId)
    )
  );
  useEffect(() => {
    props.RequestMedicalInsurances(1, 100);
  }, [MedicalInsuranceId]);

  return (
    <div className="content">
      <PageHeader
        title={SelectedMedicalInsurance?.businessName}
        subtitle={SelectedMedicalInsurance?.title}
      />
      {/* <div className="header">
        {SelectedMedicalInsurance && (
          <h2 className="">
            {SelectedMedicalInsurance?.businessName || ''} |{' '}
            {SelectedMedicalInsurance?.title}
            <Label>{SelectedMedicalInsurance?.taxName}</Label>
          </h2>
        )}
      </div> */}
      <div className="leftColumn">
        <div className="Section Section-principal">
          <Pivot
            aria-label="Medical insurance agreements"
            className="flex1"
            linkSize="normal"
          >
            <PivotItem headerText={t('medicalInsuranceDetailPage.medicalInsuranceDetailAgreements')}>
              {MedicalInsuranceId && (
                <MedicalInsuranceAgreementListComponent
                  MedicalInsuranceId={Number(MedicalInsuranceId)}
                />
              )}
            </PivotItem>
          </Pivot>
        </div>
        <div className="Section Section-column">
          <Label>{t('medicalInsuranceDetailPage.medicalInsuranceDetailCoveragePlans')}</Label>
          <MedicalInsurancePlanPanel
            plans={
              props.MedicalInsurances?.find(
                (medicalInsurance) =>
                  medicalInsurance.id === Number(MedicalInsuranceId)
              )?.plans || []
            }
            MedicalInsuranceId={Number(MedicalInsuranceId)}
          />
        </div>
      </div>
    </div>
  );
};

export default ownFunctions.connector(MedicalInsuranceDetailPage as any);
