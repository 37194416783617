import { PriceList } from './../models/Entities/PriceList/PriceList';
import { PriceDetails } from '../models/Entities/PriceList/PriceDetail';
import { NewPriceDetails } from '../models/Entities/PriceList/NewPriceDetail';
import { IResult } from '../models/HttpResult';
import { NewPriceList } from '../models/Entities/PriceList/NewPriceList';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'pricelists/';

export const AddPriceToPriceList = async (
  newPrice: NewPriceDetails
, token: string): Promise<PriceDetails> => {
  
  let RequestURL: string =
    process.env.REACT_APP_BILLER_API_GATEWAY +
    'agreements.aggregator/agreements/pricelist/details';
  
  try {
    const Response: PriceDetails = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(newPrice)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<PriceDetails>>;
        }
      })
      .then((data: IResult<PriceDetails>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const SavePrice = async (price: PriceDetails, token: string): Promise<PriceDetails> => {
  let RequestURL: string = BaseURL + 'details';

  console.log('SavePrice', JSON.stringify(price));

  try {
    const Response: PriceDetails = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      },
      method: 'PUT',
      body: JSON.stringify(price)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<PriceDetails>>;
        }
      })
      .then((data: IResult<PriceDetails>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const DeletePrice = async (price: PriceDetails, token: string): Promise<any> => {
  let RequestURL: string = BaseURL + 'details/' + price.id;

  try {
    const Response: PriceDetails = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<any>>;
        }
      })
      .then((data: IResult<any>) => {
        return price;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const Add = async (newPriceList: NewPriceList, token: string): Promise<PriceList> => {
  let RequestURL: string = BaseURL;

  try {
    const Response: PriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(newPriceList)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<PriceDetails>>;
        }
      })
      .then((data: IResult<PriceDetails>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const Update = async (priceList: PriceList, token: string): Promise<PriceList> => {
  let RequestURL: string = BaseURL;

  try {
    const Response: PriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(priceList)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<PriceDetails>>;
        }
      })
      .then((data: IResult<PriceDetails>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetPriceList = async (PriceListId: number, token: string): Promise<PriceList> => {
  let RequestURL: string = BaseURL + PriceListId;

  try {
    const Response: PriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<PriceList>>;
        }
      })
      .then((data: IResult<PriceList>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
