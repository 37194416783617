import { IPersonaSharedProps, Persona, PersonaSize } from "@fluentui/react";
import { Practitioner } from "../../../../models/Entities/Practitioners/Practitioner";
import { useState } from "react";

// Common Function
import * as CommonFunction from '../../../../functions/common';

export interface IProps {
    practitioner: Practitioner | undefined;
}

export const PractitionerDetailsMini = (props: IProps) => {

    const [renderDetails, updateRenderDetails] = useState(true);

    const examplePersona: IPersonaSharedProps = {
        imageUrl: props.practitioner?.photo,
        imageInitials: CommonFunction.getNameInitials(props.practitioner?.fullName || 'No encontrado'),
        text: props.practitioner?.fullName || props.practitioner?.userId  + ' - No encontrado',
        secondaryText: props.practitioner?.speciality
      };

    return (
        <>
           <Persona
                {...examplePersona}
                size={PersonaSize.size40}
                hidePersonaDetails={!renderDetails}
                imageAlt="Annie Lindqvist, no presence detected"
            />
        </>
    )
}