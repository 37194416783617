import { PractitionerAssociationSettings } from '../../models/Entities/PractitionerAssociations/Settings/PractitionerAssociationSettings';
import { ErrorType } from '../../models/HttpError';

export interface PractitionerSettingsState {
  settings: PractitionerAssociationSettings | undefined;

  // status
  isLoading: boolean;
  isSaving: boolean;
  savedSuccessfully: boolean | undefined;
  loadedSuccessfully: boolean | undefined;
  failOnLoad: boolean;
  failOnSave: boolean;
  error: ErrorType | undefined;
}

export const initialState: PractitionerSettingsState = {
  settings: undefined,

  // status
  isLoading: false,
  isSaving: false,
  savedSuccessfully: undefined,
  loadedSuccessfully: undefined,
  failOnLoad: false,
  failOnSave: false,
  error: undefined
};
