import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';
import { ContractCommissionConcept } from '../../models/Entities/Contracts/ContractCommissionConcept';


// services
import * as Services from '../../services/ContractCommissionServices';

// actions
import * as Actions from '../actions';
import { NewContractCommissionConcept } from '../../models/Entities/Contracts/NewContractCommissionConcetp';

type KnownAction =
| Actions.ContractCommissionConcepts.ContractCommissionConcept_GetAll_Request_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_GetAll_Success_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_GetAll_Failure_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_GetById_Request_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_GetById_Success_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_GetById_Failure_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Create_Request_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Create_Success_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Create_Failure_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Update_Request_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Update_Success_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Update_Failure_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Delete_Request_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Delete_Success_Action
| Actions.ContractCommissionConcepts.ContractCommissionConcept_Delete_Failure_Action;


export const actionCreators = {
    GetAllContractCommissionConcepts: (contractId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_GET_ALL_FAILURE',
                contractId: contractId,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        dispatch({ type: 'CONTRACT_COMMISSION_CONCEPT_GET_ALL_REQUEST', contractId: contractId });

        Services.GetContractCommissionConcepts(contractId, token)
        .then((concepts: ContractCommissionConcept[]) => {
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_GET_ALL_SUCCESS',
                contractId: contractId,
                contractCommissionConcepts: concepts
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_GET_ALL_FAILURE',
                contractId: contractId,
                error: error
            })
        );
    },
    GetContractCommissionConceptById: (contractId: number, commissionConceptId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_GET_BY_ID_FAILURE',
                contractCommissionConceptId: commissionConceptId,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        dispatch({ type: 'CONTRACT_COMMISSION_CONCEPT_GET_BY_ID_REQUEST', contractCommissionConceptId: commissionConceptId });

        Services.GetContractCommissionConceptById(commissionConceptId, token)
        .then((concept: ContractCommissionConcept) => {
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_GET_BY_ID_SUCCESS',
                contractCommissionConcept: concept
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_GET_BY_ID_FAILURE',
                contractCommissionConceptId: commissionConceptId,
                error: error
            })
        );
    },
    CreateContractCommissionConcept: (contractId: number, concept: NewContractCommissionConcept): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        
        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_CREATE_FAILURE',
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };
        
        dispatch({ type: 'CONTRACT_COMMISSION_CONCEPT_CREATE_REQUEST', contractId: contractId, contractCommissionConcept: concept });

        Services.CreateContractCommissionConcept(concept, token)
        .then((concept: ContractCommissionConcept) => {
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_CREATE_SUCCESS',
                contractCommissionConcept: concept
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_CREATE_FAILURE',
                error: error
            })
        );
    },
    UpdateContractCommissionConcept: (contractId: number, concept: ContractCommissionConcept): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        
        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_UPDATE_FAILURE',
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };
        
        dispatch({ type: 'CONTRACT_COMMISSION_CONCEPT_UPDATE_REQUEST', contractCommissionConcept: concept });

        Services.UpdateContractCommissionConcept(concept, token)
        .then((concept: ContractCommissionConcept) => {
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_UPDATE_SUCCESS',
                contractCommissionConcept: concept
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_UPDATE_FAILURE',
                error: error
            })
        );
    },
    DeleteContractCommissionConcept: (contractId: number, commissionConceptId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        
        var state = getState();
        var token: string;

        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_DELETE_FAILURE',
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };
        
        dispatch({ type: 'CONTRACT_COMMISSION_CONCEPT_DELETE_REQUEST', contractCommissionConceptId: commissionConceptId });

        Services.DeleteContractCommissionConcept(commissionConceptId, token)
        .then((concept: ContractCommissionConcept) => {
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_DELETE_SUCCESS',
                contractId: contractId,
                contractCommissionConceptId: commissionConceptId,
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACT_COMMISSION_CONCEPT_DELETE_FAILURE',
                error: error
            })
        );
    }
}