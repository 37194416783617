import { IBasePickerSuggestionsProps, ITag } from '@fluentui/react';

//store 
import * as MedicalInsuranceStore from '../../../../redux/middleware/MedicalInsuranceMiddleware';
import * as MedicalServicesStore from '../../../../redux/middleware/MedicalServicesMiddleware';
import * as MedicalInsurancePlanStore from '../../../../redux/middleware/MedicalInsurancePlansMiddleware';
import * as PractitionerAssociationStore from '../../../../redux/middleware/PractitionerMedicalServiceAssociationMiddleware';

// models
import { ErrorType } from '../../../../models/HttpError';
import { AppState } from '../../../../redux/reducers';
import { Practitioner } from '../../../../models/Entities/Practitioners/Practitioner';
import { AvailableMedicalService } from '../../../../models/Entities/MedicalServices/AvailableMedicalService';
import { PractitionerAssociationsMedicalService } from '../../../../models/Entities/PractitionerAssociations/MedicalServices/PractitionerAssociationMedicalService';

interface IProps {
  onSelectedServices: (services: AvailableMedicalService[]) => void;
  MedicalServicesSelected: number[] | undefined;
  MultiselectEnable: boolean;
  noteText?: string;
  PractitionerId: string;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  MedicalServices: AvailableMedicalService[];
  MedicalServicesOptions: ITag[];
  PractitionerMedicalServices: PractitionerAssociationsMedicalService[];
  SelectedMedicalServices: AvailableMedicalService[];
}

interface DispatchProps {
  GetAllMedicalServices: typeof MedicalServicesStore.actionCreators.GetAllMedicalServices;
  GetMedicalServiceByPractitionerId: typeof PractitionerAssociationStore.actionCreators.GetMedicalServiceByPractitionerId;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.ContractConcept?.isLoadingAll,
  isUpdating: state.ContractConcept?.isLoadingAll,
  isUpdatedSuccessfully: state.ContractConcept?.successLoadingAll,
  isLoadingSuccessfully: state.ContractConcept?.successLoadingAll,
  failOnUpdating: state.ContractConcept?.failOnLoadingAll,
  failOnLoading: state.ContractConcept?.failOnLoadingAll,
  isDeleting: state.ContractConcept?.isDeletingOne,
  isDeletedSuccessfully: state.ContractConcept?.successDeletingOne,
  failOnDeleting: state.ContractConcept?.failOnDeletingOne,
  error: state.ContractConcept?.error,
  MedicalServices: state.MedicalServices?.list,
  MedicalServicesOptions: state.Practitioner?.list?.find((item: Practitioner) => item.userId === ownProps.PractitionerId)?.MedicalServicesAssociated?.map((item: PractitionerAssociationsMedicalService) => {
    const medicalService = state.MedicalServices?.list?.find((service: AvailableMedicalService) => service.id === item.medicalService.id);
    return {
      key: item.medicalService.id,
      name: medicalService?.serviceNameReference
    };
  }) as ITag[],
  PractitionerMedicalServices: state.Practitioner?.list?.find((item: Practitioner) => item.userId === ownProps.PractitionerId)?.MedicalServicesAssociated,
  SelectedMedicalServices: ownProps.MedicalServicesSelected ? state.MedicalServices?.list?.filter((service: AvailableMedicalService) => ownProps.MedicalServicesSelected?.includes(service.id)) as AvailableMedicalService[] : []
});

export const mapDispatchToProps = ({
  ...MedicalInsuranceStore.actionCreators,
  ...MedicalInsurancePlanStore.actionCreators,
  ...MedicalServicesStore.actionCreators,
  ...PractitionerAssociationStore.actionCreators
});

export const GetMedicalServicesOptions =  (
  PractitionerMedicalServices: PractitionerAssociationsMedicalService[],
  MedicalServices: AvailableMedicalService[]
): ITag[] => {
  
  return PractitionerMedicalServices?.map((item: PractitionerAssociationsMedicalService) => {
    const medicalService = MedicalServices?.find((service: AvailableMedicalService) => service.id === item.medicalService.id);
    return {
      key: item.medicalService.id,
      name: medicalService?.serviceNameReference
    };
  }) as ITag[];
};

export const getTextFromItem = (item: ITag) => item.name;

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const GetMedicalServiceById = (
  medicalServiceId: number,
  medicalServices: AvailableMedicalService[]
): AvailableMedicalService => {

  const medicalService: AvailableMedicalService = medicalServices?.find(
    (item: AvailableMedicalService) => item.id === medicalServiceId
  ) as AvailableMedicalService;
  
  return medicalService;
};

export function GetSelectedMedicalService(
  medicalServicesId: number[],
  medicalServices: AvailableMedicalService[]
): ITag[] {
  const medicalServicesOptions: ITag[] = [];

  medicalServicesId.forEach((id) => {
    var currentMedicalService = medicalServices.find((service: AvailableMedicalService) => service.id === id);

    if (currentMedicalService) {
      medicalServicesOptions.push({
        key: currentMedicalService.id,
        name: currentMedicalService.serviceNameReference
      } as ITag);
    }
  });
  return medicalServicesOptions;
};

export const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'servicios sugeridos',
  noResultsFoundText: 'No se encontro servicio'
};
