// actions
import * as Actions from '../actions';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/Provider.Services';
import { Provider } from '../../models/Entities/Provider/Provider';
import { ErrorType } from '../../models/HttpError';
import { NewProvider } from '../../models/Entities/Provider/NewProvider';

type KnownAction =
  | Actions.Provider.Providers_GetAll_Request_Action
  | Actions.Provider.Providers_GetAll_Receive_Action
  | Actions.Provider.Providers_GetAll_Fail_Action
  | Actions.Provider.Providers_UpdateAll_Request_Action
  | Actions.Provider.Providers_UpdateAll_Receive_Action
  | Actions.Provider.Providers_UpdateAll_Fail_Action
  | Actions.Provider.Provider_GetById_Request_Action
  | Actions.Provider.Provider_GetById_Receive_Action
  | Actions.Provider.Provider_GetById_Fail_Action
  | Actions.Provider.Provider_Add_Request_Action
  | Actions.Provider.Provider_Add_Receive_Action
  | Actions.Provider.Provider_Add_Fail_Action
  | Actions.Provider.Provider_Update_Request_Action
  | Actions.Provider.Provider_Update_Receive_Action
  | Actions.Provider.Provider_Update_Fail_Action
  | Actions.Provider.Provider_Delete_Request_Action
  | Actions.Provider.Provider_Delete_Receive_Action
  | Actions.Provider.Provider_Delete_Fail_Action;

export const actionCreators = {
  GetAllProviders: (
    pageNumber: number,
    pageSize: number,
    searchText: string | undefined
  ): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {
      
      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDERS_GETALL_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      if (!state.Provider?.successLoadingAll) {
        dispatch({ type: 'PROVIDERS_GETALL_REQUEST_ACTION' });

        Services.GetProviders(pageNumber, pageSize, searchText, token)
          .then((providers: Provider[]) => {
            dispatch({
              type: 'PROVIDERS_GETALL_RECEIVE_ACTION',
              providers: providers
            });
          })
          .catch((error: ErrorType) =>
            dispatch({ type: 'PROVIDERS_GETALL_FAIL_ACTION', error: error })
          );
      } else {
        dispatch({
          type: 'PROVIDER_UPDATE_ALL_REQUEST_ACTION'
        });

        Services.GetProviders(pageNumber, pageSize, undefined, token)
          .then((providers: Provider[]) => {
            dispatch({
              type: 'PROVIDER_UPDATE_ALL_RECEIVE_ACTION',
              providers: providers
            });
          })
          .catch((error: ErrorType) =>
            dispatch({
              type: 'PROVIDER_UPDATE_ALL_FAIL_ACTION',
              error: error
            })
          );
      }
    };
  },
  GetProviderById: (id: number): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_GETBYID_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({ type: 'PROVIDER_GETBYID_REQUEST_ACTION', ProviderId: id });

      Services.GetProvider(id, token)
        .then((provider: Provider) => {
          dispatch({
            type: 'PROVIDER_GETBYID_RECEIVE_ACTION',
            Provider: provider
          });
        })
        .catch((error: ErrorType) =>
          dispatch({ type: 'PROVIDER_GETBYID_FAIL_ACTION', error: error })
        );
    };
  },
  AddProvider: (provider: NewProvider): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({ type: 'PROVIDER_ADD_REQUEST_ACTION', newProvider: provider });

      Services.AddProvider(provider, token)
        .then((provider: Provider) => {
          dispatch({
            type: 'PROVIDER_ADD_RECEIVE_ACTION',
            Provider: provider
          });
        })
        .catch((error: ErrorType) =>
          dispatch({ type: 'PROVIDER_ADD_FAIL_ACTION', error: error })
        );
    };
  },
  UpdateProvider: (provider: Provider): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_UPDATE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({ type: 'PROVIDER_UPDATE_REQUEST_ACTION', Provider: provider });

      Services.SaveProvider(provider, token)
        .then((provider: Provider) => {
          dispatch({
            type: 'PROVIDER_UPDATE_RECEIVE_ACTION',
            Provider: provider
          });
        })
        .catch((error: ErrorType) =>
          dispatch({ type: 'PROVIDER_UPDATE_FAIL_ACTION', error: error })
        );
    };
  },
  DeleteProvider: (id: number): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({ type: 'PROVIDER_DELETE_REQUEST_ACTION', ProviderId: id });

      //   Services.DeleteProvider(id)
      //     .then((provider: Provider) => {
      //       dispatch({
      //         type: 'PROVIDER_DELETE_RECEIVE_ACTION',
      //         ProviderId: provider.id
      //       });
      //     })
      //     .catch((error: ErrorType) =>
      //       dispatch({ type: 'PROVIDER_DELETE_FAIL_ACTION', error: error })
      //     );
    };
  }
};
