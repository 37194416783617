// functions
import { Tooltip, Text } from '@fluentui/react-components';
import * as ComponentFunctions from './PractitionerProtocolActivity.Day.Functions';
import moment from 'moment';
import { connect } from 'react-redux';
import { FontSizes, FontWeights } from '@fluentui/react';

const PractitionerProtocolActivityDayComponent = (props: ComponentFunctions.Props) => {
    return (
        <Tooltip content={moment(new Date(props.day)).format('DD/MM/YYYY')} relationship="label">
            <div className={'ActivityBox ' + (props.visit.visitCount > 0 ? 'activeVisit' : '')}><Text style={{fontSize: FontSizes.size10, fontWeight: FontWeights.semibold}}>{props.visit?.visitCount}</Text></div>
        </Tooltip>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(PractitionerProtocolActivityDayComponent as any);