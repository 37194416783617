import {
  DefaultButton,
  IIconProps,
  Text,
  Label,
  FocusTrapCallout,
  Separator,
  FocusZone,
  Stack,
  PrimaryButton,
  FocusZoneTabbableElements,
  mergeStyleSets,
  FontWeights,
  Spinner,
  SpinnerSize,
  IconButton,
  MessageBarType
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { useState } from 'react';
import { ErrorType } from '../../../models/HttpError';
import { MessageComponent } from '../MessageComponent';

export interface IConfirmationButtonProps {
  ButtonId: string;
  ButtonIconName: string;
  ButtonText: string;
  ButtonCssClass: string;
  ConfirmationButtonText: string;
  ConfirmationButtonCssClass: string;
  CancelButtonText: string;
  CancelButtonCssClass: string;
  Title: string;
  Description: string;
  ElementTextHeader: string;
  ElementTextInformation: string;
  isOperationExecuting: boolean;
  isOperationExecutedWithError: boolean;
  OperationError: ErrorType | undefined;
  onConfirmation: () => void;
  onCancel: () => void;
}

export const ConfirmationButtonComponent = (
  props: IConfirmationButtonProps
) => {
  const [
    isConfirmationButtonVisible,
    { toggle: toggleIsConfirmationButtonVisible }
  ] = useBoolean(false);

  const [isConfirming, setIsConfirming] = useState<Boolean>(false);

  const ButtonId = useId(props.ButtonId);

  const ButtonIcon: IIconProps = { iconName: props.ButtonIconName };

  const handleConfirmation = () => {
    props.onConfirmation();
    setIsConfirming(true);
  };

  const handleCancelation = () => {
    setIsConfirming(false);
    toggleIsConfirmationButtonVisible();
    props.onCancel();
  };

  return (
    <>
      {props.ButtonText === '' ? (
        <IconButton
          text={props.ButtonText}
          id={ButtonId}
          onClick={(event) => { event.stopPropagation(); toggleIsConfirmationButtonVisible();}}
          iconProps={ButtonIcon}
          allowDisabledFocus
          className={props.ButtonCssClass}
        />
      ) : (
        <DefaultButton
          text={props.ButtonText}
          id={ButtonId}
          onClick={toggleIsConfirmationButtonVisible}
          iconProps={ButtonIcon}
          allowDisabledFocus
          className={props.ButtonCssClass}
        />
      )}
      {isConfirmationButtonVisible ? (
        <FocusTrapCallout
          role="alertdialog"
          className={CallOutStyle.callout}
          gapSpace={0}
          target={`#${ButtonId}`}
          onDismiss={toggleIsConfirmationButtonVisible}
          setInitialFocus
        >
          {!props.isOperationExecuting ? (
            <>
              {props.isOperationExecutedWithError && isConfirming && (
                <>
                  <MessageComponent
                    message={'Ocurrio un error'}
                    type={MessageBarType.error}
                    subMessage={props.OperationError?.ErrorMessage}
                    itemList={props.OperationError?.Errors}
                  />
                  <Separator />
                </>
              )}
              <Text block variant="xLarge" className={CallOutStyle.title}>
                {props.Title}
              </Text>
              <Text block variant="small">
                {props.Description}
              </Text>
              <Separator />
              <Text block variant="small">
                {props.ElementTextHeader}
              </Text>
              <Label>{props.ElementTextInformation}</Label>
              <FocusZone
                handleTabKey={FocusZoneTabbableElements.all}
                isCircularNavigation
              >
                <Stack className={CallOutStyle.buttons} gap={8} horizontal>
                  <PrimaryButton
                    className={props.ConfirmationButtonCssClass}
                    onClick={handleConfirmation}
                  >
                    {props.ConfirmationButtonText}
                  </PrimaryButton>
                  <DefaultButton onClick={handleCancelation}>
                    {props.CancelButtonText}
                  </DefaultButton>
                </Stack>
              </FocusZone>
            </>
          ) : (
            <Spinner size={SpinnerSize.large} />
          )}
        </FocusTrapCallout>
      ) : null}
    </>
  );
};

const CallOutStyle = mergeStyleSets({
  callout: {
    width: 320,
    minHeight: 80,
    padding: '20px 24px',
    border: 'solid 1px lightgray'
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.regular,
    color: '#d11d1d'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20
  }
});
