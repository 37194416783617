import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';

// stores
import * as MedicalServiceStore from '../../../redux/middleware/MedicalServicesMiddleware';

//#region Props

interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  MedicalServiceId: number;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  MedicalService: AvailableMedicalService;
}

interface MedicalServicesDispatchProps {
  GetMedicalServiceById: typeof MedicalServiceStore.actionCreators.GetMedicalServiceById;
  ChangeMedicalServiceSettings: typeof MedicalServiceStore.actionCreators.ChangeMedicalServiceSettings;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.MedicalServices?.isLoadingOne,
  isUpdating: state.MedicalServices?.isUpdatingOne,
  isUpdatedSuccessfully: state.MedicalServices?.successUpdatingOne,
  isLoadingSuccessfully: state.MedicalServices?.successLoadingOne,
  failOnUpdating: state.MedicalServices?.failOnUpdatingOne,
  failOnLoading: state.MedicalServices?.failOnLoadingOne,
  error: state.MedicalServices?.error,
  MedicalService: state.MedicalServices?.list.find(
    (ms: AvailableMedicalService) => ms.id === ownProps.MedicalServiceId
  )
});

export const mapDispatchToProps = {
  ...MedicalServiceStore.actionCreators
};

//#endregion Props
