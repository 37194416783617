//#region Props

import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractAddendum } from "../../../../models/Entities/Contracts/ContractAddendum";
import { ErrorType } from "../../../../models/HttpError";
import { AppState } from "../../../../redux/reducers";

// stores
import * as ContractsStore from "../../../../redux/middleware/ContractMiddleware";
import * as ContractAddendumsStore from "../../../../redux/middleware/ContractAddendum.Middleware";


interface IProps {
    ContractId: number;
    AddendumId: number;
    isOpen: boolean;
    onClose: () => void;
}

interface ConnectedProps {
    // loading
    isLoading: boolean;
    isLoadingSuccessfully: boolean | undefined;
    failOnLoading: boolean;
    errorOnLoading: ErrorType | undefined;

    //updating
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean | undefined;
    failOnUpdating: boolean;
    errorOnUpdating: ErrorType | undefined;

    //deleting
    isDeleting: boolean;
    isDeletingSuccessfully: boolean | undefined;
    failOnDeleting: boolean;
    errorOnDeleting: ErrorType | undefined;

    //creating
    isCreatingAddendum: boolean;
    isCreatingAddendumSuccessfully: boolean | undefined;
    failOnCreatingAddendum: boolean;
    errorOnCreatingAddendum: ErrorType | undefined;

    Contract: Contract | undefined;
    CurrentAddendum: ContractAddendum | undefined;
}

interface MedicalServicesDispatchProps {
    GetContractById: typeof ContractsStore.actionCreators.GetContractById;
    GetContractAddendumsByContractId: typeof ContractAddendumsStore.actionCreators.GetContractAddendumsByContractId;
    CreateContractAddendum: typeof ContractAddendumsStore.actionCreators.CreateContractAddendum;
    UpdateContractAddendum: typeof ContractAddendumsStore.actionCreators.UpdateContractAddendum;
    DeleteContractAddendum: typeof ContractAddendumsStore.actionCreators.DeleteContractAddendum;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    var currentaddendum = state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.addendums?.find((a: ContractAddendum) => a.id === ownProps.AddendumId);

    return {
        // loading
        isLoading: state.Contract?.isLoadingOne,
        isLoadingSuccessfully: state.Contract?.successLoadingOne,
        failOnLoading: state.Contract?.failOnLoadingOne,
        errorOnLoading: state.Contract?.error,

        //updating
        isUpdating: currentaddendum?.isUpdating || false,
        isUpdatedSuccessfully: currentaddendum?.isUpdatedSuccessfully || undefined,
        failOnUpdating: currentaddendum?.FailUpdating || false,
        errorOnUpdating: currentaddendum?.ErrorUpdating || undefined,

        //deleting
        isDeleting: currentaddendum?.isDeleting,
        isDeletingSuccessfully: currentaddendum?.isDeletingSuccessfully,
        failOnDeleting: currentaddendum?.FailDeleting,
        errorOnDeleting: currentaddendum?.ErrorDeleting,

        //creating
        isCreatingAddendum: state.ContractAddendum?.isAddingNewOne || false,
        isCreatingAddendumSuccessfully: state.ContractAddendum?.successAddingNewOne || undefined,
        failOnCreatingAddendum: state.ContractAddendum?.failOnAddingNewOne || false,
        errorOnCreatingAddendum: state.ContractAddendum?.error || undefined,

        Contract: state.Contract?.list?.find(c => c.id === ownProps.ContractId),
        CurrentAddendum: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.addendums?.find((addendum: ContractAddendum) => addendum.id === ownProps.AddendumId) || undefined,
    }
};

export const mapDispatchToProps = {
    ...ContractsStore.actionCreators,
    ...ContractAddendumsStore.actionCreators
};

//#endregion Props

export const isCurrentAddendum = (addendum: ContractAddendum | undefined) => {
    const today = new Date();
    if (!addendum) return false;
    if (!addendum.contractAddendumExpiryStartDate && !addendum.contractAddendumExpiryEndDate) return false;
    if (addendum.contractAddendumExpiryStartDate && !addendum.contractAddendumExpiryEndDate) {
        return today >= new Date(addendum.contractAddendumExpiryStartDate);
    }
    if (!addendum.contractAddendumExpiryStartDate && addendum.contractAddendumExpiryEndDate) {
        return today <= new Date(addendum.contractAddendumExpiryEndDate);
    }
    return today >= new Date(addendum.contractAddendumExpiryStartDate!) && today <= new Date(addendum.contractAddendumExpiryEndDate!);
}

export const isExpiredAddendum = (addendum: ContractAddendum | undefined) => {
    const today = new Date();
    if (!addendum) return false;
    if (!addendum.contractAddendumExpiryStartDate && !addendum.contractAddendumExpiryEndDate) return false;
    if (addendum.contractAddendumExpiryStartDate && !addendum.contractAddendumExpiryEndDate) {
        return false;
    }
    if (!addendum.contractAddendumExpiryStartDate && addendum.contractAddendumExpiryEndDate) {
        return today > new Date(addendum.contractAddendumExpiryEndDate);
    }
    return today > new Date(addendum.contractAddendumExpiryEndDate!);
}