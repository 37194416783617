import { AppState } from '../../../redux/reducers';
import { connect, useDispatch, useSelector } from 'react-redux';
import { MedicalInsuranceAgreement } from '../../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';
import { MedicalInsurance } from '../../../models/Entities/MedicalInsurances/MedicalInsurance';
import { MedicalInsurancePlan } from '../../../models/Entities/MedicalInsurances/MedicalInsurancePlan';
import { useEffect, useState } from 'react';
import {
  IIconProps,
  PrimaryButton, 
  SearchBox,
  Label,
  Text
} from '@fluentui/react';
import MedicalServiceMultiSelectorComponent, {
  SelectedMedicalServiceType
} from '../../MedicalServices/MedicalServiceSelector/MedicalServiceMultiSelectorComponent';
import { AgreementService } from '../../../models/Entities/AgreementServices/AgreementServices';
import { useBoolean } from '@fluentui/react-hooks';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';
import AgreementServiceDetailItemComponent from '../../AgreementServiceDetails/AgreementServiceDetailItem/AgreementServiceDetailItemComponent';

//store
import * as MedicalServiceStore from '../../../redux/middleware/MedicalServicesMiddleware';
import * as MedicalInsuranceStore from '../../../redux/middleware/MedicalInsuranceMiddleware';
import * as AgreementServicesStore from '../../../redux/middleware/AgreementServicesMiddleware';

// functions
import * as ComponentFunctions from './AgreementServiceListFunctions';
import * as CommonFunction from '../../../functions/common';

// styles
import './AgreementServiceListComponent.css';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export interface IProps {
  AgreementId: number;
}

export const AgreementServiceListComponent = (props: IProps) => {
  const [Agreement, setAgreement] = useState<MedicalInsuranceAgreement>();
  const [Plans, setPlans] = useState<MedicalInsurancePlan[]>([]);

  const AddIcon: IIconProps = { iconName: 'CirclePlus' };

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  // events
  const HandleOnAddServicesClick = () => {
    openPanel();
  };

  const handleClosePanel = () => {
    dismissPanel();
  };

  const onSelectService = (service: AvailableMedicalService) => {
    dispatch(
      mapDispatchToProps.AddAgreementService({
        medicalServiceId: service.id,
        agreementId: Agreement!.id
      })
    );
  };

  const onUnselectService = (service: SelectedMedicalServiceType) => {
    dispatch(mapDispatchToProps.DeleteAgreementService(service.ReferenceId));
  };

  // states
  const AgreementState = useSelector(
    (state: AppState) => state.MedicalInsuranceAgreements
  );
  const MedicalInsuranceState = useSelector(
    (state: AppState) => state.MedicalInsurances
  );
  const AgreementServiceState = useSelector(
    (state: AppState) => state.AgreementServices
  );

  // useTranslation (T)
  const [t] = useTranslation();

  // effects
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      mapDispatchToProps.GetAllAgreementServiceByAgreementId(
        props.AgreementId,
        1,
        1000
      )
    );
  }, [props.AgreementId, dispatch]);

  useEffect(() => {
    if (props.AgreementId) {
      setAgreement(
        AgreementState?.list.find(
          (item: MedicalInsuranceAgreement) => item.id === props.AgreementId
        )
      );
      setPlans(
        MedicalInsuranceState?.list
          .find(
            (insurance: MedicalInsurance) =>
              insurance.id === Agreement?.medicalInsuranceId
          )
          ?.plans.sort(CommonFunction.DynamicSort('title')) || []
      );
    }
  }, [props.AgreementId, Agreement, MedicalInsuranceState, AgreementState]);

  return (
    <>
      <div>
        <div className="listHeader">
          <PrimaryButton
            secondaryText={t('agreementServiceListComponent.service.addMedicalService')}
            onClick={HandleOnAddServicesClick}
            text={t('agreementServiceListComponent.service.addMedicalService')}
            iconProps={AddIcon}
            className="actionButton"
          />
          <SearchBox
            placeholder={t('agreementServiceListComponent.service.searchService')}
            //onChange={OnSearch}
            underlined={false}
          />
        </div>
        <table className="Table">
          <thead>
            <tr>
              <th>{t('agreementServiceListComponent.service.services')}</th>
              {Plans &&
                Plans.map((plan: MedicalInsurancePlan) => (
                  <th>{plan.title.toUpperCase()}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {AgreementServiceState &&
              AgreementServiceState.list.map((service: AgreementService) => (
                <tr className="TableBodyRow">
                  <td>
                    <Text>
                      {service.medicalService.serviceNameReference.toUpperCase()}
                    </Text>
                    {service.medicalService.comments && service.medicalService.comments?.length > 0 && <Text>{service.medicalService.comments}</Text>}
                  </td>
                  {Plans &&
                    Plans.map((plan: MedicalInsurancePlan) => (
                      <td>
                        <AgreementServiceDetailItemComponent
                          Plan={plan}
                          Service={service}
                        />
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>

        <MedicalServiceMultiSelectorComponent
          SelectedServices={
            (AgreementServiceState?.list.map((service: AgreementService) => ({
              medicalServiceId: service.medicalServiceId,
              ReferenceId: service.id
            })) as SelectedMedicalServiceType[]) || []
          }
          ErrorInformed={undefined}
          ViewOnly3erPartyServices={undefined}
          isOpen={isOpen}
          openPanel={() => undefined}
          dismissPanel={handleClosePanel}
          onSelect={onSelectService}
          onUnselect={onUnselectService}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    MedicalServices: state.MedicalServices,
    MedicalInsurances: state.MedicalInsurances,
    MedicalInsurancePlans: state.MedicalInsurancePlans
  };
};
const mapDispatchToProps = {
  ...MedicalServiceStore.actionCreators,
  ...MedicalInsuranceStore.actionCreators,
  ...AgreementServicesStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementServiceListComponent);
