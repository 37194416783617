import { FontSizes, IconButton, Label, Text } from "@fluentui/react";
import { connect } from "react-redux";
import { useBoolean } from "@fluentui/react-hooks";


// Functions
import * as ComponentFunctions from './SettlementSummaryItem.Functions';

//styles
import './SettlementSummaryItem.css';

// Components
import SettlementDetailsListComponet from "../SettlementDetailsList/SettlementDetailsList.Component";
import SettlementResumeFormComponent from "../SettlementResumeForm/SettlementResumeForm.Component";
import SettlementSummaryStatistic from "../SettlementSummaryStatistics/SettlementSummaryStatistic.Component";
import SettlementMoneyChartComponent from "../SettlementMoneyChart/SettlementMoneyChart.Component";
import { CharType } from "../SettlementMoneyChart/SettlementMoneyChart.Functions";
import { SettlementSummeryTotal } from "../../../models/Entities/SettlementSummaryTotals/SettlementSummeryTotal";
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";
import { itemLayoutClassName } from "@fluentui/react-migration-v0-v9";

const SettlementSummaryItem = (props: ComponentFunctions.Props) => {

    // Details Panel 
    const [isDetailsPanelOpen, { setTrue: openDetailsPanel, setFalse: dismissDetailsPanel }] =
        useBoolean(false);

    // Summary Form
    const [isOpenSummaryForm, { setTrue: openSummaryFormPanel, setFalse: dismissSummaryFormPanel }] =
        useBoolean(false);


    const handleDetailsClosePanel = () => {
        dismissDetailsPanel();
    }

    const handleSummaryFormClosePanel = () => {
        dismissSummaryFormPanel();
    }

    const onViewDetailsBySummary = () => {
        openDetailsPanel();
    }

    const onEditSummary = () => {
        openSummaryFormPanel();
    }

    const onDeleteSettlementResume = () => {
        props.CurrentSummary && props.DeleteSettlementResume(props.SettlementId, props.CurrentSummary?.id);
    }

    const onMasiveExcludeSettlementResume = () => {
        props.CurrentSummary && props.MassExclude(props.SettlementId, props.CurrentSummary.id, true);
    }

    const onMasiveIncludeSettlementResume = () => {
        props.CurrentSummary && props.MassExclude(props.SettlementId, props.CurrentSummary.id, false);
    }

    const Options = (
        <>
            <IconButton
                iconProps={ComponentFunctions.PropertiesIcon}
                onClick={() => onViewDetailsBySummary()}
            />
            <IconButton
                iconProps={ComponentFunctions.EditIcon}
                onClick={() => onEditSummary()}
            />
            <IconButton
                iconProps={ComponentFunctions.Exclude}
                onClick={() => onMasiveExcludeSettlementResume()}
            />
            <IconButton
                iconProps={ComponentFunctions.Include}
                onClick={() => onMasiveIncludeSettlementResume()}
            />
            <IconButton
                iconProps={ComponentFunctions.DeleteIcon}
                onClick={() => onDeleteSettlementResume()}
            />
        </>
    )

    const Modals = (
        <>
            <SettlementDetailsListComponet
                isOpen={isDetailsPanelOpen}
                openPanel={openDetailsPanel}
                dismissPanel={handleDetailsClosePanel}
                SettlementId={props.SettlementId}
                SettlementResumeId={props.CurrentSummary?.id}
            />
            <SettlementResumeFormComponent
                isOpen={isOpenSummaryForm}
                openPanel={openSummaryFormPanel}
                dismissPanel={handleSummaryFormClosePanel}
                SettlementId={props.SettlementId}
                SettlementResumeId={props.CurrentSummary?.id} />
        </>
    );



    return (
        <div className={'SummaryItemContainer ' + (props.CurrentSummary?.haveWarning ? 'warning' : '')}>
            <div className="SummaryInfo">
                <Label>{props.CurrentSummary?.description}</Label>
                <ul className="TotalList">
                    {props.SummaryCurrencies?.map((currency: string) => {
                        return <li key={currency}><Label>{props.SettlementDetails?.filter((item: SettlementDetail )=>  
                             item.currency === currency 
                             && item.isExcluded === false
                        ).reduce((sum, current) => sum + current.value, 0).toLocaleString('us-US', { style: 'currency', currency: currency.toUpperCase() })} <span style={{fontSize: FontSizes.mini}}>{currency}</span></Label></li>
                    })}
                </ul>
            </div>
            <div className="AditionalInfomationContainer">
                <div className="InformacionContainer">
                    {props.CurrentSummary?.comments && <><small>Comentarios</small>
                        <Label>{props.CurrentSummary.comments}</Label></>}
                    <div className="SummaryStatistics">
                        <small>Estado de verificación</small>
                        <p>
                            {props.CurrentSummary && <SettlementSummaryStatistic SettlementId={props.SettlementId} SettlementSummaryItemId={props.CurrentSummary?.id} />}
                        </p>
                        <small>Estadísticas</small>
                        {props.CurrentSummary && props.SummaryCurrencies?.map((currency: string) => <SettlementMoneyChartComponent SettlementId={props.SettlementId} SettlementSummaryItemId={props.CurrentSummary?.id} CurrencyCode={currency} ChartType={CharType.Line}  /> )}
                    </div>
                </div>
                <div className="SummaryItemActions">
                    {Options}
                </div>
            </div>

            {Modals}
        </div>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementSummaryItem as any);

