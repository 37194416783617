import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/ConfigActions';
import { ConfigurationState } from '../states/ConfigurationState';

const unloadedState: ConfigurationState = {
  isLoading: false,
  settings: undefined
};

export const ConfigurationReducer: Reducer<ConfigurationState> = (
  state: ConfigurationState | undefined,
  incomingAction: Action
): ConfigurationState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'REQUEST_SETTINGS_ACTION':
      return {
        ...state,
        isLoading: true,
        settings: undefined
      };
    case 'RECEIVE_SETTINGS_ACTION':
      return { ...state, isLoading: false, settings: action.settings };
  }

  return state;
};
