import {
  IconButton,
  IIconProps,
  Label,
  Spinner,
  Toggle,
  Text,
  MessageBarType,
  Separator,
  FontIcon,
  FontSizes
} from '@fluentui/react';
import { useEffect } from 'react';
import { AppState } from '../../../redux/reducers';
import { MedicalInsurance } from '../../../models/Entities/MedicalInsurances/MedicalInsurance';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Store
import * as MedicalInsuranceStore from '../../../redux/middleware/MedicalInsuranceMiddleware';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import { Search } from '../../../commons/Icons/Icons';
import { ConfirmationButtonComponent } from '../../../commons/component/ConfirmationButton/ConfirmationButtonComponent';
export interface IProps {
  searchText?: string;
  editEvent?: (MedicalInsurance: MedicalInsurance) => void;
}

export const MedicalInsuranceListComponent = (props: IProps) => {
  const EditIcon: IIconProps = { iconName: 'Edit' };
  const AgreementsIcon: IIconProps = { iconName: 'StackIndicator' };
  const DeleteIcon: IIconProps = { iconName: 'Delete' };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //useTranslation()
  const [t] = useTranslation();
  const MedicalInsuranceState = useSelector(
    (state: AppState) => state.MedicalInsurances
  );

  //events
  const onEditEvent = (MedicalInsurance: MedicalInsurance) => {
    if (props.editEvent) props.editEvent(MedicalInsurance);
  };

  const onViewAgreementsEvent = (MedicalInsurance: MedicalInsurance) => {
    navigate('/insurances/' + MedicalInsurance.id);
  };

  const onDeleteAgreementsEvent = (MedicalInsurance: MedicalInsurance) => {
    dispatch(mapDispatchToProps.DeleteMedicalInsurance(MedicalInsurance));
  };

  //effects
  useEffect(() => {
    dispatch(mapDispatchToProps.GetAllMedicalInsurances(1, 100));
  }, []);

  return (
    <>
      {MedicalInsuranceState?.failOnLoadingAll &&
        MedicalInsuranceState?.error && (
          <MessageComponent
            message={t('medicalInsuranceList.message.errorMessage')}
            type={MessageBarType.error}
            itemList={[]}
            subMessage={MedicalInsuranceState?.error.ErrorMessage}
          />
        )}
      {MedicalInsuranceState?.successLoadingAll && (
        <>
          {props.searchText && (
            <MessageComponent
              message={t('medicalInsuranceList.message.filterMessage')}
              type={MessageBarType.info}
              itemList={[]}
              subMessage={
                t('medicalInsuranceList.message.search') + ' "' + props.searchText + '"'
              }
            />
          )}
          <table className="Table">
            <thead>
              <tr>
                <th></th>
                <th>{t('medicalInsuranceList.props.taxName')}</th>
                {/* <th>{t('medicalInsuranceList.props.initials')}</th> */}
                <th>{t('medicalInsuranceList.props.cuit')}</th>
                <th>{t('medicalInsuranceList.props.agreements')}</th>
                <th style={{ width: 150 }}>{t('medicalInsuranceList.props.options')}</th>
              </tr>
            </thead>
            <tbody>
              {MedicalInsuranceState && MedicalInsuranceState.list
                ? MedicalInsuranceState.list
                    .filter((item: MedicalInsurance) =>
                      props.searchText
                        ? item.businessName
                            .toLowerCase()
                            .includes(props.searchText.toLowerCase()) ||
                          item.title
                            .toLowerCase()
                            .includes(props.searchText.toLowerCase())
                        : true
                    )
                    .map((item: MedicalInsurance) => (
                      <tr key={item.id} className="TableBodyRow">
                        <td><FontIcon iconName='WebAppBuilderFragment' style={{ fontSize: FontSizes.size24, padding: 10 }} /></td>
                        <td
                          className="primary"
                          onClick={() => onViewAgreementsEvent(item)}
                        >
                          <Label
                            style={{
                              textTransform: 'uppercase',
                              fontSize: '1em',
                              fontWeight: 'bold'
                            }}
                          >
                            {item.businessName} - {item.title}
                          </Label>
                          <Text>{item.taxName || t('medicalInsuranceList.props.uninformed')}</Text>
                        </td>
                        {/* <td style={{ width: '50%' }}>
                          <Text
                            style={{
                              textTransform: 'uppercase',
                              fontSize: '1em',
                              fontWeight: 'normal'
                            }}
                          >
                            {item.businessName} - {item.title}
                          </Text>
                        </td> */}
                        <td>
                          <Text>{item.cuit || t('medicalInsuranceList.props.uninformed')}</Text>
                        </td>
                        <td>
                          <Toggle
                            label=""
                            defaultChecked={item.agreementsEnabled as boolean}
                            onText={t('medicalInsuranceList.props.enabled')} 
                            offText={t('medicalInsuranceList.props.disabled')}
                            //onChange={_onChange}
                          />
                        </td>
                        <td>
                          <div className="SectionControls">
                            <IconButton
                              iconProps={EditIcon}
                              title={t('medicalInsuranceList.props.modify')}
                              ariaLabel="Edit"
                              onClick={() => onEditEvent(item)}
                            />
                            <IconButton
                              iconProps={AgreementsIcon}
                              title={t('medicalInsuranceList.props.agreements')}
                              ariaLabel="AddAgreements"
                              onClick={() => onViewAgreementsEvent(item)}
                            />

                            <div className="danger">
                              <ConfirmationButtonComponent
                                ButtonId={'MedicalInsuranceDeleteButton'}
                                ButtonIconName={'Delete'}
                                ButtonText={''}
                                ButtonCssClass={''}
                                ConfirmationButtonText={t('medicalInsuranceList.confirmationButton.deleteButton')}
                                ConfirmationButtonCssClass={'deleteButton'}
                                CancelButtonText={t('medicalInsuranceList.confirmationButton.cancelButton')}
                                CancelButtonCssClass={''}
                                Title={t('medicalInsuranceList.confirmationButton.deleteMedicalCoverage')}
                                Description={
                                  t('medicalInsuranceList.confirmationButton.deleteInformation') 
                                }
                                ElementTextHeader={t('medicalInsuranceList.confirmationButton.medicalCoverage')}
                                ElementTextInformation={item.businessName}
                                onConfirmation={() =>
                                  onDeleteAgreementsEvent(item)
                                }
                                onCancel={function (): void {
                                  throw new Error('Function not implemented.');
                                }}
                                isOperationExecuting={
                                  MedicalInsuranceState.isDeletingOne
                                }
                                isOperationExecutedWithError={
                                  MedicalInsuranceState.failOnDeletingOne
                                }
                                OperationError={MedicalInsuranceState.error}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                : null}
            </tbody>
          </table>
        </>
      )}
      {MedicalInsuranceState?.isLoadingAll && (
        <div>
          <Spinner
            label={t('medicalInsuranceList.loading.loadingSuppliers')}
            ariaLive="assertive"
            labelPosition="top"
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalInsurances
});

const mapDispatchToProps = {
  ...MedicalInsuranceStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalInsuranceListComponent as any);
