import { IIconProps } from "@fluentui/react";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";
import { ErrorType } from "../../../models/HttpError";

// stores
import * as SettlementStore from '../../../redux/middleware/SettlementMiddleware';
import * as ContractStore from '../../../redux/middleware/ContractMiddleware';
import * as MedicalProtocolStore from '../../../redux/middleware/MedicalProtocolMiddleware';

//State
import { AppState } from "../../../redux/reducers";
import { Contract } from "../../../models/Entities/Contracts/Contract";
import { Settlement } from "../../../models/Entities/Settlements/Settlement";

interface IProps {
    SettlementId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    isChangingStatus: boolean;
    changeStatusSuccessfully: boolean;
    failOnChangeStatus: boolean;
    error: ErrorType | undefined;
    Settlement: Settlement | undefined;
  }
  
  interface DispatchProps {
    GetSettlementById: typeof SettlementStore.actionCreators.GetSettlementById;
    ChangeSettlementStatus: typeof SettlementStore.actionCreators.ChangeSettlementStatus;
    GetMedicalProtocolById: typeof MedicalProtocolStore.actionCreators.GetMedicalProtocolById;
    GetContractById: typeof ContractStore.actionCreators.GetContractById;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const currentSettlement: Settlement | undefined = state.Settlement?.list?.find((item: Settlement) => item.id === ownProps.SettlementId );

    return {
            isLoading: state.Settlement?.isLoadingAll,
            isUpdating: state.Settlement?.isLoadingAll,
            isUpdatedSuccessfully: state.Settlement?.successLoadingAll,
            isLoadingSuccessfully: state.Settlement?.successLoadingAll,
            failOnUpdating: state.Settlement?.failOnLoadingAll,
            failOnLoading: state.Settlement?.failOnLoadingAll,
            isDeleting: state.Settlement?.isDeletingOne,
            isDeletedSuccessfully: state.Settlement?.successDeletingOne,
            failOnDeleting: state.Settlement?.failOnDeletingOne,
            isChangingStatus: currentSettlement?.isChangingStatus,
            changeStatusSuccessfully: currentSettlement?.successChangingStatus,
            failOnChangeStatus: currentSettlement?.failOnChangeStatus,
            error: currentSettlement?.error,
            Settlement: currentSettlement
        }
    };
  
  export const mapDispatchToProps = {
    ...SettlementStore.actionCreators,
    ...MedicalProtocolStore.actionCreators,
    ...ContractStore.actionCreators
  };

  /*
    Contract Status
    _____________________
    Active = 1,
    Inactive = 2,
    Suspended = 3,
    Expired = 4,
    Canceled = 5,
    Terminated = 6
*/

export const GetSettlementStatusDescripcion = (status: number | undefined) => {
    switch (status) {
      case undefined:
          return 'SettlementStatus.pending'; // "Pendiente";
        case 1:
            return 'SettlementStatus.registered'; // "Registrado";
        case 2:
            return 'SettlementStatus.inProcess'; // "En proceso";
        case 3:
            return 'SettlementStatus.finished'; // "Finalizado";
        case 4:
            return 'SettlementStatus.cancelled'; // "Cancelado";
        case 5:
            return 'SettlementStatus.Confirmed'; // "Conformado";
        case 6:	
            return 'SettlementStatus.rejected'; // "Rechazado";
        default:
        return 'SettlementStatus.pending'; // "Pendiente";
    }
  };

  export const GetContractStatusClass = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "";
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "Suspended";
        case 4:
            return "Expired";
        case 5:
            return "Canceled";
        case 6:
            return "Terminated";
        default:
        return "";
  }
};

export const GetSettlementStatusIcon = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return 'WaitlistConfirmMirrored';
        case 1:
            return 'WaitlistConfirmMirrored';
        case 2:
            return 'SyncStatus';
        case 3:
            return 'SkypeCircleCheck';
        case 4:
            return 'StatusErrorFull';
        case 5:
            return 'SkypeCircleCheck';
        case 6:	
            return 'StatusErrorFull';
        default:
        return 'WaitlistConfirmMirrored';
    }
  }


  //#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };