import AddendumsComponents from "../../components/Contracts/Addendums/Addendums.List/Addendums.Components";
import { Contract } from "../../models/Entities/Contracts/Contract";
import { CommonState } from "../common/state/commonState";


export interface ContractState extends CommonState<Contract> {
    // updating all
    isUpdatingAll: boolean;
    isUpdatingAllSuccess: boolean|undefined;
    FailUpdatingAll: boolean;

    // change fee division
    isChangingFeeDivision: boolean;
    FeeDivisionChangesSuccessfully: boolean|undefined;
    FailChangingFeeDivision: boolean;

    // Practitioner contract association status
    isGettingPractitionerContractAssociation: boolean;
    isGettingPractitionerContractAssociationSuccess: boolean|undefined;
    FailGettingPractitionerContractAssociation: boolean;

    // Protocol contract association status
    isGettingProtocolContractAssociation: boolean;
    isGettingProtocolContractAssociationSuccess: boolean|undefined;
    FailGettingProtocolContractAssociation: boolean;
}

export const ContractUnloadState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,

    // updating all
    isUpdatingAll: false,
    isUpdatingAllSuccess: undefined,
    FailUpdatingAll: false,

    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,

    // saving
    isSaving: false,
    isSavingSuccess: undefined,
    FailSaving: false,
    
    // adding
    isAdding: false,
    isAddingSuccess: undefined,
    FailAdding: false,

    // deleting
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    FailDeletingOne: false, 

    // change fee division
    isChangingFeeDivision: false,
    FeeDivisionChangesSuccessfully: undefined,
    FailChangingFeeDivision: false,

    // Practitioner contract association status
    isGettingPractitionerContractAssociation: false,
    isGettingPractitionerContractAssociationSuccess: undefined,
    FailGettingPractitionerContractAssociation: false,

    // Protocol contract association status
    isGettingProtocolContractAssociation: false,
    isGettingProtocolContractAssociationSuccess: undefined,
    FailGettingProtocolContractAssociation: false,

};