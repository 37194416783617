
import { AppState } from "../../../../redux/reducers";

// store
import * as PersonsStore from "../../../../redux/middleware/Persons.Middleware";

// models
import { Person } from "../../../../models/Entities/Person/Person";
import { ErrorType } from "../../../../models/HttpError";


interface IProps {
    personSelected: string[] | undefined;
    selectionLimit: number;
    fieldLabel: string;
    isRequired?: boolean;
    CustomAditionalPersonsList?: Person[];
    onChangeSelection?: (
      selectedPersons: Person[]
    ) => void;
  }
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    error: ErrorType | undefined;
    Persons: Person[];
    isErrorInPersonIdentifierDetected: boolean;
    PersonsSelected: string[];
    PersonsOptions: string[];
  }
  
  interface DispatchProps {
    GetAllPersons: typeof PersonsStore.actionCreators.GetAllPersons;
    FixPersonIdentifiers: typeof PersonsStore.actionCreators.FixPersonIdentifiers;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const TotalPersons = [...state.Persons?.list || [], ...ownProps.CustomAditionalPersonsList || []];

    //console.log('TotalPersons', TotalPersons);
    
    return {
    isLoading: state.Persons?.isLoadingAll,
    isUpdating: state.Persons?.isLoadingAll,
    isUpdatedSuccessfully: state.Persons?.successLoadingAll,
    isLoadingSuccessfully: state.Persons?.successLoadingAll,
    failOnUpdating: state.Persons?.failOnLoadingAll,
    failOnLoading: state.Persons?.failOnLoadingAll,
    error: state.Persons?.error,
    Persons: TotalPersons.sort((a: Person, b: Person) => a.businessName?.localeCompare(b.businessName || '') || 0) || [],
    isErrorInPersonIdentifierDetected: state.Persons?.list?.some((person: Person) => person.personIdentifier === '00000000-0000-0000-0000-000000000000'),
    PersonsSelected: TotalPersons?.filter((person: Person) => ownProps.personSelected?.includes(person.personIdentifier) ? true : false)?.map(person => person.personIdentifier) || [],
    PersonsOptions: TotalPersons?.map((person: Person) => person.personIdentifier) || []
  }};
  
  export const mapDispatchToProps = ({
    ...PersonsStore.actionCreators
  });

export const GetPersonByIdentifier = (persons: Person[], identifier: string): Person | undefined => {
    return persons.find((person: Person) => person.personIdentifier === identifier);
}

export const GetPersonsBySelectedIdentifier = (persons: Person[], selectedPersons: string[]): Person[] => {
    return persons.filter((person: Person) => selectedPersons.includes(person.personIdentifier)) || [];
}

export const GetAllPersonByName = (persons: Person[], selectedPersons: string[], Name: string): Person[] | undefined => {
    return persons.filter((person: Person) => !selectedPersons.includes(person.personIdentifier) && person.businessName?.toLowerCase()?.includes(Name.toLowerCase()));
}
