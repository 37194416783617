// MedicalProtocols Reducer
import { Action, Reducer } from 'redux';

// actions
import * as Actions from '../actions/';

// functions
import * as Functions from '../../functions/common';
import { PersonState, PersonUnloadState } from '../states/PersonState';


type KnownAction = 
| Actions.Persons.PersonGetAllRequestAction
| Actions.Persons.PersonGetAllSuccessAction
| Actions.Persons.PersonGetAllFailureAction
| Actions.Persons.PersonGetbyIdRequestAction
| Actions.Persons.PersonGetByIdSuccessAction
| Actions.Persons.PersonGetByIdFailureAction
| Actions.Persons.PersonCreateRequestAction
| Actions.Persons.PersonCreateSuccessAction
| Actions.Persons.PersonCreateFailureAction
| Actions.Persons.PersonUpdateRequestAction
| Actions.Persons.PersonUpdateSuccessAction
| Actions.Persons.PersonUpdateFailureAction
| Actions.Persons.PersonDeleteRequestAction
| Actions.Persons.PersonDeleteSuccessAction
| Actions.Persons.PersonDeleteFailureAction;

export const PersonsReducer: Reducer<PersonState> = (
    state: PersonState | undefined, incomingAction: Action): PersonState => {
    
    if (state === undefined) {
        return PersonUnloadState as PersonState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'PERSON_GETALL_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: false,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'PERSON_GETALL_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                list: action.payload
            };
        case 'PERSON_GETALL_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'PERSON_GETBYID_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: false,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'PERSON_GETBYID_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                list: [...state.list, action.payload],
                error: undefined
            };
        case 'PERSON_GETBYID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'PERSON_CREATE_REQUEST':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'PERSON_CREATE_SUCCESS':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                list: [...state.list, action.payload],
                error: undefined
            };
        case 'PERSON_CREATE_FAILURE':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        case 'PERSON_UPDATE_REQUEST':
            var personToUpdate = state.list.find(person => person.id === action.payload.id);

            if(personToUpdate) {
                personToUpdate.isUpdating = true;
                personToUpdate.isUpdatedSuccessfully = undefined;
                personToUpdate.failOnUpdating = false;
                personToUpdate.error = undefined;

                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: undefined,
                    failOnUpdatingOne: false,
                    list: state.list?.map(person => person.id === action.payload.id ? personToUpdate! : person),
                    error: undefined
                };
            } else {

            return {
                ...state,
                isUpdatingOne: true,
                successUpdatingOne: undefined,
                failOnUpdatingOne: false,
                error: undefined
            };
        }
        case 'PERSON_UPDATE_SUCCESS':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: true,
                failOnUpdatingOne: false,
                list: state.list.map(person => person.id === action.payload.id ? action.payload : person),
                error: undefined
            };
        case 'PERSON_UPDATE_FAILURE':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: false,
                failOnUpdatingOne: true,
                error: action.error
            };
        case 'PERSON_DELETE_REQUEST':
            var personToDelete = state.list.find(person => person.id === action.payload);

            if(personToDelete) {
                personToDelete.isDeleting = true;
                personToDelete.isDeletedSuccessfully = undefined;
                personToDelete.failOnDeleting = false;
                personToDelete.error = undefined;

                return {
                    ...state,
                    isDeletingOne: true,
                    successDeletingOne: undefined,
                    failOnDeletingOne: false,
                    list: state.list?.map(person => person.id === action.payload ? personToDelete! : person),
                    error: undefined
                };
            } else {
                return {
                    ...state,
                    isDeletingOne: true,
                    successDeletingOne: undefined,
                    failOnDeletingOne: false,
                    error: undefined
                
                }
            }
        case 'PERSON_DELETE_SUCCESS':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: true,
                failOnDeletingOne: false,
                list: state.list.filter(person => person.id !== action.payload),
                error: undefined
            };
        case 'PERSON_DELETE_FAILURE':
            var personToDeleteFail = state.list.find(person => person.id === action.personId);

            if(personToDeleteFail) {
                personToDeleteFail.isDeleting = false;
                personToDeleteFail.isDeletedSuccessfully = false;
                personToDeleteFail.failOnDeleting = true;
                personToDeleteFail.error = action.error;

                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: false,
                    failOnDeletingOne: true,
                    list: state.list?.map(person => person.id === action.personId ? personToDeleteFail! : person),
                    error: action.error
                };
            } else {

            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: false,
                failOnDeletingOne: true,
                error: action.error
            };
        }
        default:
            return state;
    }
}