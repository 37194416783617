// styles
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers';
import './WelcomeWidgetComponent.css';
import { Trans, useTranslation } from 'react-i18next';

export const WelcomeWidgetComponent = () => {
  const authState = useSelector((state: AppState) => state.oidc);

  const [t] = useTranslation();

  return (
    <div className="welcomeText">
      <label>
        <span><Trans i18nKey="greeting" /></span> {authState?.user?.profile?.given_name || ''}
      </label>
    </div>
  );
};


