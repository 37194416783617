import { AgreementService } from '../../../models/Entities/AgreementServices/AgreementServices';
import { AgreementServicesDetail } from '../../../models/Entities/AgreementServices/AgreementServicesDetail';
import { AppState } from '../../../redux/reducers';
import { MedicalInsurancePlan } from './../../../models/Entities/MedicalInsurances/MedicalInsurancePlan';
import { IIconProps } from '@fluentui/react';

// Store
import * as AgreementServiceStore from '../../../redux/middleware/AgreementServicesMiddleware';
import * as AgreementServiceDetailsStore from '../../../redux/middleware/AgreementServiceDetailMiddleware';

//#region props

interface IProps {
  Plan: MedicalInsurancePlan;
  Service: AgreementService;
}

interface ConnectedProps {
  AgreementServiceDetail: AgreementServicesDetail;
}

interface DispatchProps {
  UpdateAgreementServiceDetail: typeof AgreementServiceDetailsStore.actionCreators.UpdateAgreementServiceDetail;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  AgreementServiceDetail: state.AgreementServices?.list
    .find((service: AgreementService) => service.id === ownProps.Service.id)
    ?.details.find(
      (item: AgreementServicesDetail) =>
        item.insurancePlanId === ownProps.Plan.id
    )
});

export const mapDispatchToProps = {
  ...AgreementServiceStore.actionCreators,
  ...AgreementServiceDetailsStore.actionCreators
};

//#endregion

//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
//#endregion
