import { IResult } from '../models/HttpResult';
import { ContractConceptPaymentSchedule } from '../models/Entities/Contracts/ContractConceptPaymentSchedule';
import { NewContractConceptPaymentSchedule } from '../models/Entities/Contracts/NewContractConceptPaymentSchedule';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';


const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/concepts/';


export const GetContractsConceptPaymentSchedule = async (ContractConceptId: number, token: string): Promise<ContractConceptPaymentSchedule[]> => {

    let RequestURL: string = BaseURL + ContractConceptId + '/paymentschedule/';

    try {
        const Response: ContractConceptPaymentSchedule[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConceptPaymentSchedule[]>>;
            }
        })
        .then((data: IResult<ContractConceptPaymentSchedule[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Get contract By Id
export const GetContractConceptPaymentScheduleById = async (ContractConceptPaymentScheduleid: number, token: string): Promise<ContractConceptPaymentSchedule> => {

    let RequestURL: string = BaseURL + 'paymentschedule/' + ContractConceptPaymentScheduleid;
    
    try {
        const Response: ContractConceptPaymentSchedule = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConceptPaymentSchedule>>;
            }
        })
        .then((data: IResult<ContractConceptPaymentSchedule>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Create Contract concept
export const CreateContractConceptPaymentSchedule = async (newContractConceptPaymentSchedule: NewContractConceptPaymentSchedule, token: string): Promise<ContractConceptPaymentSchedule> => {
    
    let RequestURL: string = BaseURL + 'paymentschedule/';
    
    try {
        const Response: ContractConceptPaymentSchedule = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newContractConceptPaymentSchedule)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConceptPaymentSchedule>>;
            }
        })
        .then((data: IResult<ContractConceptPaymentSchedule>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Update Contract concept payment schedule
export const UpdateContractConceptPaymentSchedule = async (contractConceptPaymentSchedule: ContractConceptPaymentSchedule, token: string): Promise<ContractConceptPaymentSchedule> => {
        
    let RequestURL: string = BaseURL + 'paymentschedule/';
    
    try {
        const Response: ContractConceptPaymentSchedule = await fetch(RequestURL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(contractConceptPaymentSchedule)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConceptPaymentSchedule>>;
            }
        })
        .then((data: IResult<ContractConceptPaymentSchedule>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Delete Contract concept payment schedule
export const DeleteContractConceptPaymentSchedule = async (contractConceptPaymentScheduleId: number, token: string): Promise<ContractConceptPaymentSchedule> => {
    
    let RequestURL: string = BaseURL + 'paymentschedule/' + contractConceptPaymentScheduleId;
    
    console.log("pasa por el delete", RequestURL);

    try {
        const Response: ContractConceptPaymentSchedule = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }})
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConceptPaymentSchedule>>;
            }
        })
        .then((data: IResult<ContractConceptPaymentSchedule>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
}
