import { AppState } from '../../../redux/reducers';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { ErrorType } from '../../../models/HttpError';


//stores 
import * as ContractsStore from '../../../redux/middleware/ContractMiddleware'
import * as CommissionAgentStore from '../../../redux/middleware/ContractCommissionAgentMiddleware'
import * as PractitionerStore from '../../../redux/middleware/PractitionerMiddleware'

// models
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import { CommissionAgent } from '../../../models/Entities/Contracts/CommissionAgent';

//#region Props

interface IProps {
  ContractId: number;
}

interface ConnectedProps {
  isLoading: boolean;
  isLoadingSuccessfully: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Contract: Contract | undefined;
  CommissionAgents: CommissionAgent | undefined;
  GetPractitioner: (id: string) => Practitioner | undefined;
}

interface MedicalServicesDispatchProps {
    GetCommissionAgentsByAssociatedContractId: typeof CommissionAgentStore.actionCreators.GetCommissionAgentsByAssociatedContractId;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const ContractProtocolAssociated : number | undefined = state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId)?.protocol[0]?.protocolId;
    const ContractPractitionerAssociated : string | undefined = state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId)?.practitioners[0]?.practitionerId;

    return {
        isLoading: state.Contract?.isLoadingAll,
        isLoadingSuccessfully: state.Contract?.successLoadingAll,
        failOnLoading: state.Contract?.failOnLoadingAll,
        failOnDeleting: state.Contract?.failOnDeletingOne,
        error: state.Contract?.error,
        Contract: state.Contract?.list?.find(c => c.id === ownProps.ContractId),
        Protocol: ContractProtocolAssociated ? state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ContractProtocolAssociated) : undefined,
        GetPractitioner: (id: string) => { 
            var practitioner = state.Practitioner?.list?.find((practitioner: Practitioner) => practitioner.userId === id);
            return practitioner;
        },
    }
};

export const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
    
    dispatch(CommissionAgentStore.actionCreators.GetCommissionAgentsByAssociatedContractId(ownProps.ContractId));
    dispatch(PractitionerStore.actionCreators.GetAllPractitioners(
        1,
        10000,
        ''
    ));

    return {
    ...ContractsStore.actionCreators,
    ...CommissionAgentStore.actionCreators,
    ...PractitionerStore.actionCreators
    }
};

//#endregion Props