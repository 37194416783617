import { AvailableMedicalService } from '../models/Entities/MedicalServices/AvailableMedicalService';
import { IResult } from '../models/HttpResult';
import { IPagedResult } from '../models/HttpPagedResult';
import { NewAvailableMedicalService } from '../models/Entities/MedicalServices/NewAvailableMedicalService';
import AuthorizeService from '../components/api-authorization/AuthorizeService';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'medicalServices/';

export const GetMedicalServices = async (
  pageNumber: number,
  pageSize: number,
  searchText: string | undefined,
  only3rdParty: boolean | undefined = undefined
, token: string): Promise<AvailableMedicalService[]> => {
  // 2. Define a function that returns a URLSearchParams instance

  // 1. Define a function that returns a URLSearchParams instance
  //    with the specified page number, page size, and search text.
  let params: URLSearchParams = Functions.GetQueryStringParams(
    pageNumber,
    pageSize,
    searchText
  );

  let RequestURL: string = BaseURL;

  if (params)
    RequestURL +=
      '?' +
      params.toString() +
      (only3rdParty !== undefined
        ? '&serviceProvidedBy3rdParty=' + only3rdParty
        : '');

  try {
    const Response: AvailableMedicalService[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IPagedResult<AvailableMedicalService[]>
          >;
        }
      })
      .then((data: IPagedResult<AvailableMedicalService[]>) => {
        return data.data;
      })
      .catch((error: any) => {
        if (error.text)
          return error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
          });
        else throw Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    console.log(error);
    throw error;
  }
};

export const GetMedicalServiceById = async (
  serviceId: number
, token: string): Promise<AvailableMedicalService> => {
  
  const RequestURL: string = BaseURL + serviceId;

  try {
    const Response: AvailableMedicalService = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AvailableMedicalService>>;
        }
      })
      .then((data: IResult<AvailableMedicalService>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AddService = async (
  newMedicalInsurance: NewAvailableMedicalService
, token: string): Promise<AvailableMedicalService> => {
  const RequestURL: string = BaseURL;

  try {
    const Response: AvailableMedicalService = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(newMedicalInsurance)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AvailableMedicalService>>;
        }
      })
      .then((data: IResult<AvailableMedicalService>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

// Function to update the medical service
// The function takes a medical service object and sends it to the API to update the medical service
// The function returns the updated medical service object

export const UpdateMedicalService = async (
  medicalService: AvailableMedicalService
, token: string): Promise<AvailableMedicalService> => {
  const RequestURL: string = BaseURL;

  try {
    const Response: AvailableMedicalService = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(medicalService)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AvailableMedicalService>>;
        }
      })
      .then((data: IResult<AvailableMedicalService>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const DeleteMedicalService = async (
  medicalServiceId: number
, token: string): Promise<string> => {
  const RequestURL: string = BaseURL + medicalServiceId;

  try {
    const Response: string = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<null>>;
        }
      })
      .then((data: IResult<null>) => {
        return data.message;
      })
      .catch((error: any) => {
        return error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        });
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const MedicalServiceChangeStatus = async (
  medicalInsuranceId: number,
  newStatus: boolean
, token: string): Promise<boolean> => {
  const RequestURL: string =
    BaseURL + medicalInsuranceId + '/status/' + newStatus;

  try {
    const Response: boolean = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<null>>;
        }
      })
      .then((data: IResult<null>) => {
        return newStatus;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const MedicalServiceChangeSettings = async (
  medicalService: AvailableMedicalService
, token: string): Promise<AvailableMedicalService> => {
  const RequestURL: string = BaseURL + 'settings/';

  try {
    const Response: AvailableMedicalService = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(medicalService),
      method: 'POST'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AvailableMedicalService>>;
        }
      })
      .then((data: IResult<AvailableMedicalService>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
