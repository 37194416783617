// contract stage middleware
import { Contract } from '../../models/Entities/Contracts/Contract';
import { StageCycle } from '../../models/Entities/Contracts/StageCycle';
import { NewStageCycle } from '../../models/Entities/Contracts/NewStageCycle';

import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/ContractStageCycles.Services';

// actions
import * as Actions from '../actions';


type KnownAction =
    | Actions.ContractStageCycles.ContractStageCycle_GetAllBycontractId_Request_Action
    | Actions.ContractStageCycles.ContractStageCycle_GetAllBycontractId_Receive_Action
    | Actions.ContractStageCycles.ContractStageCycle_GetAllBycontractId_Fail_Action
    | Actions.ContractStageCycles.ContractStageCycle_GetAllByAddendumId_Request_Action
    | Actions.ContractStageCycles.ContractStageCycle_GetAllByAddendumId_Receive_Action
    | Actions.ContractStageCycles.ContractStageCycle_GetAllByAddendumId_Fail_Action
    | Actions.ContractStageCycles.ContractStageCycle_UpdateAllByContractId_Request_Action
    | Actions.ContractStageCycles.ContractStageCycle_UpdateAllByContractId_Receive_Action
    | Actions.ContractStageCycles.ContractStageCycle_UpdateAllByContractId_Fail_Action
    | Actions.ContractStageCycles.ContractStageCycle_GetById_Request_Action
    | Actions.ContractStageCycles.ContractStageCycle_GetById_Receive_Action
    | Actions.ContractStageCycles.ContractStageCycle_GetById_Fail_Action
    | Actions.ContractStageCycles.ContractStageCycle_Add_Request_Action
    | Actions.ContractStageCycles.ContractStageCycle_Add_Receive_Action
    | Actions.ContractStageCycles.ContractStageCycle_Add_Fail_Action
    | Actions.ContractStageCycles.ContractStageCycle_Update_Request_Action
    | Actions.ContractStageCycles.ContractStageCycle_Update_Receive_Action
    | Actions.ContractStageCycles.ContractStageCycle_Update_Fail_Action
    | Actions.ContractStageCycles.ContractStageCycle_Delete_Request_Action
    | Actions.ContractStageCycles.ContractStageCycle_Delete_Receive_Action
    | Actions.ContractStageCycles.ContractStageCycle_Delete_Fail_Action;
    
export const actionCreators = {
    GetAllContractsStageCycles:
        (contractId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_GET_ALL_BY_CONTRACTID_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            if (!state.Contract?.successLoadingAll || 
                state.Contract?.list?.find((contract: Contract) => contract.id === contractId)?.cycles?.length === 0 ) {
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_GET_ALL_BY_CONTRACTID_REQUEST_ACTION',
                    contractId: contractId
                });

                Services.GetContractsStagesCycles(contractId, token )
                    .then((contractStageCycles: StageCycle[]) => {
                        dispatch({
                            type: 'CONTRACTSTAGECYCLE_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION',
                            contractStageCycles: contractStageCycles,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTSTAGECYCLE_GET_ALL_BY_CONTRACTID_FAIL_ACTION',
                            error: error
                        })
                    );
            } else {
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_UPDATE_ALL_BY_CONTRACTID_REQUEST_ACTION',
                    contractId: contractId
                });

                Services.GetContractsStagesCycles(contractId, token)
                    .then((contractStagesCycles: StageCycle[]) => {
                        dispatch({
                            type: 'CONTRACTSTAGECYCLE_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION',
                            contractStageCycles: contractStagesCycles,
                            contractId: contractId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'CONTRACTSTAGECYCLE_UPDATE_ALL_BY_CONTRACTID_FAIL_ACTION',
                            error: error
                        })
                    );
            }
    },
    GetContractStageCyclesByAddendumId:
        (contractId: number, addendumId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_GET_ALL_BY_ADDENDUMID_FAIL_ACTION',
                    contractId: contractId,
                    addendumId: addendumId,
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'CONTRACTSTAGECYCLE_GET_ALL_BY_ADDENDUMID_REQUEST_ACTION',
                contractId: contractId,
                addendumId: addendumId
            });

            Services.GetContractsStagesCycles(contractId, token)
            .then((contractStageCycles: StageCycle[]) => {
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_GET_ALL_BY_ADDENDUMID_RECEIVE_ACTION',
                    contractStageCycles: contractStageCycles,
                    contractId: contractId,
                    addendumId: addendumId
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_GET_ALL_BY_ADDENDUMID_FAIL_ACTION',
                    contractId: contractId,
                    addendumId: addendumId,
                    error: error
                })
            );
    },
    GetContractStageCycleById:
        (id: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_GET_BY_ID_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'CONTRACTSTAGECYCLE_GET_BY_ID_REQUEST_ACTION',
                contractStageCycleId: id
            });

            Services.GetContractStageCycleById(id, token)
                .then((contractStageCycle: StageCycle) => {
                    dispatch({
                        type: 'CONTRACTSTAGECYCLE_GET_BY_ID_RECEIVE_ACTION',
                        contractStageCycle: contractStageCycle
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTSTAGECYCLE_GET_BY_ID_FAIL_ACTION',
                        error: error
                    })
                );

    },
    AddContractStageCycle:
        (newContractStageCycle: NewStageCycle): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_ADD_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'CONTRACTSTAGECYCLE_ADD_REQUEST_ACTION',
                newContractStageCycle: newContractStageCycle
            });

            Services.CreateContractStageCycle(newContractStageCycle, token)
                .then((contractStageCycle: StageCycle) => {
                    dispatch({
                        type: 'CONTRACTSTAGECYCLE_ADD_RECEIVE_ACTION',
                        contractStageCycle: contractStageCycle
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTSTAGECYCLE_ADD_FAIL_ACTION',
                        error: error
                    })
                );

    },
    AddContractStageCycleRange:
    (contactId: number, addendumId: number, newContractStageCycles: NewStageCycle[]): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
        
        var state = getState();
        var token: string = '';

        if (state.oidc?.user) {
            token = state.oidc?.user?.access_token;
        } else {
            dispatch({
                type: 'CONTRACTSTAGECYCLE_ADD_FAIL_ACTION',
                error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
            });
            return;
        }

        newContractStageCycles.forEach((newContractStageCycles : NewStageCycle)  => {
            
            newContractStageCycles.contractId = contactId;
            newContractStageCycles.contractAddendumId = addendumId;
            
            dispatch({
                type: 'CONTRACTSTAGECYCLE_ADD_REQUEST_ACTION',
                newContractStageCycle: newContractStageCycles
            });

            Services.CreateContractStageCycle(newContractStageCycles, token)
                .then((contractStageCycle: StageCycle) => {
                    dispatch({
                        type: 'CONTRACTSTAGECYCLE_ADD_RECEIVE_ACTION',
                        contractStageCycle: contractStageCycle
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTSTAGECYCLE_ADD_FAIL_ACTION',
                        error: error
                    })
                );
        });
    },
    UpdateContractStageCycle:
        (contractStageCycle: StageCycle): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_UPDATE_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'CONTRACTSTAGECYCLE_UPDATE_REQUEST_ACTION',
                contractStageCycle: contractStageCycle
            });

            Services.UpdateContractStageCycle(contractStageCycle, token)
                .then((contractStageCycle: StageCycle) => {
                    dispatch({
                        type: 'CONTRACTSTAGECYCLE_UPDATE_RECEIVE_ACTION',
                        contractStageCycle: contractStageCycle
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'CONTRACTSTAGECYCLE_UPDATE_FAIL_ACTION',
                        error: error
                    })
                );
    },
    DeleteContractStageCycle:
        (cycle: StageCycle): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_DELETE_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'CONTRACTSTAGECYCLE_DELETE_REQUEST_ACTION',
                contractStageCycle: cycle
            });

            Services.DeleteContractStageCycle(cycle.id, token)
            .then((contractStageCycle: StageCycle) => {
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_DELETE_RECEIVE_ACTION',
                    contractStageCycleId: cycle.id,
                    contractAddendumId: cycle.contractAddendumId,
                    contractId: cycle.contractId
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'CONTRACTSTAGECYCLE_DELETE_FAIL_ACTION',
                    error: error
                })
            );
    },
};
