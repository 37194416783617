import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';
import { CashFlowAccountState, CashFlowAccountUnloadState } from '../states/CashFlow.Account.State';

type KnownAction =
| Actions.CashFlowAccounts.CashFlowAccountGetAllByOwnerIdentifierRequestAction
| Actions.CashFlowAccounts.CashFlowAccountGetAllByOwnerIdentifierSuccessAction
| Actions.CashFlowAccounts.CashFlowAccountGetAllByOwnerIdentifierFailureAction
| Actions.CashFlowAccounts.CashFlowAccountGetByIdRequestAction
| Actions.CashFlowAccounts.CashFlowAccountGetByIdSuccessAction
| Actions.CashFlowAccounts.CashFlowAccountGetByIdFailureAction
| Actions.CashFlowAccounts.CashFlowAccountCreateRequestAction
| Actions.CashFlowAccounts.CashFlowAccountCreateSuccessAction
| Actions.CashFlowAccounts.CashFlowAccountCreateFailureAction;

export const CashFlowAccountsReducer: Reducer<CashFlowAccountState> = (
    state: CashFlowAccountState | undefined,
    incomingAction: Action
): CashFlowAccountState => {
    if (state === undefined) {
        return CashFlowAccountUnloadState as CashFlowAccountState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CASHFLOWACCOUNT_GETALL_BYOWNERIDENTIFIER_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CASHFLOWACCOUNT_GETALL_BYOWNERIDENTIFIER_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                list: action.payload,
                error: undefined
            };
        case 'CASHFLOWACCOUNT_GETALL_BYOWNERIDENTIFIER_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CASHFLOWACCOUNT_GET_BYID_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: undefined,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CASHFLOWACCOUNT_GET_BYID_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CASHFLOWACCOUNT_GET_BYID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CASHFLOWACCOUNT_CREATE_REQUEST':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CASHFLOWACCOUNT_CREATE_SUCCESS':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CASHFLOWACCOUNT_CREATE_FAILURE':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        default:
            return state;
    }
}
