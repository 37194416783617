import {
  Checkbox,
  MessageBarType,
  SearchBox,
  Separator,
  TextField,
  TooltipHost
} from '@fluentui/react';
import { MessageComponent } from '../../../../../../commons/component/MessageComponent';
import { ContractConcept } from '../../../../../../models/Entities/Contracts/ContractConcept';
import { useEffect, useState } from 'react';

////i18Next
import { useTranslation, Trans } from 'react-i18next';


// styles
import '../../ProtocolContractFormComponent.css';
import { ContractCommissionConcept } from '../../../../../../models/Entities/Contracts/ContractCommissionConcept';
import { ProtocolConceptItemComponent } from './ProtocolConceptItemComponent';

export interface IProps {
  concepts: ContractConcept[];
  parentConceptId?: number;
  selectedConceptIds: number[];
  showPercentage: boolean;
  onChangeConceptSelection?: (concepts: ContractConcept[]) => void;
  onAddConcept?: (concept: ContractConcept) => void;
  onDeleteConcept?: (concept: ContractConcept) => void;
  onChangeParticipationPercentage?: (percentage: number, conceptId: number) => void;
  onCostParticipationActiveChange?: (isActive: boolean, conceptId: number) => void;
  onCostParticipationPercentageChange?: (percentage: number, conceptId: number) => void;
}

export const ProtocolConceptMultiSelectorComponent = (props: IProps) => {

  const [conceptSelected, setConceptSelected] = useState<ContractConcept[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  //useTranslation()
  const [t] = useTranslation();

  useEffect(() => {
    setConceptSelected(props.concepts.filter((concept: ContractConcept) => props.selectedConceptIds.includes(concept.id)));
  }, [props.concepts]);

  useEffect(() => {
    var auxConcepts = props.concepts?.map((concept: ContractConcept) => {
      return {
        ...concept,
        isSelected: props.selectedConceptIds.includes(concept.id)
      }
    })
    console.log('concepts', auxConcepts);
    setConceptSelected(auxConcepts);
  }, [props.parentConceptId]);



    // events
  const onCheckboxChange = (isChecked: boolean, ConceptId: number) => {
    var auxConcepts = props.concepts?.map((concept: ContractConcept) => {
      if (concept.id === ConceptId) {
        concept.isSelected = isChecked;
      }
      return concept;
    });
    setConceptSelected(auxConcepts);
    props.onChangeConceptSelection?.(auxConcepts);
  };

  function getIfConceptIsSelected (conceptId: number): boolean {
    const concept = props.concepts.find((concept: ContractConcept) => concept.id === conceptId);
    const isSelected = concept?.isSelected || false;

    //setConceptAsSelected(conceptId, isSelected);

    return isSelected;
  };

  const setConceptAsSelected = (conceptId: number, selected: boolean) => {
    const concept = props.concepts.find((concept: ContractConcept) => concept.id === conceptId);
    if (concept) {
      concept.isSelected = selected;
      concept.isUnselected = !selected;
      setConceptSelected([...conceptSelected.filter((concept: ContractConcept) => concept.id !== conceptId), concept]);
    }
  }

  const Controls = (
    <div className='Controls Concepts'>
      <SearchBox placeholder={t('protocolConceptMultiselector.controls.searchConcept')} onChange={(
        event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined
      ) => setSearchText(newValue || '') } />
      <p className='ItemCounter'>Seleccionados | <strong>{conceptSelected.filter(concept => concept.isSelected).length}</strong></p>
      <Checkbox label={t('protocolConceptMultiselector.controls.selectAll')}/>
    </div>
  );

  return (
    <>
      {Controls}
      <div className="ConceptListSelector">
        {props.concepts?.length > 0 && (
          <ul>
            {props.concepts?.filter((concept_total: ContractConcept) => {
              return concept_total.id !== props.parentConceptId && concept_total.concept.conceptName.toLowerCase().includes(searchText.toLowerCase());
            })?.map((concept: ContractConcept) => (
              <li key={'li' + concept.id}>
                <ProtocolConceptItemComponent 
                concept={concept} 
                isSelected={getIfConceptIsSelected(concept.id)} 
                onCheckboxChange={onCheckboxChange} />
              </li>
            ))}
          </ul>
        )}
        {props.concepts.length === 0 && (
          <MessageComponent
            message={t('protocolConceptMultiselector.controls.conceptMessage')}
            type={MessageBarType.info}
          />
        )}
      </div>
    </>
  );
};
