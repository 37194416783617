import { Separator } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PriceListHeaderComponent } from '../../components/PriceList/PriceListHeader/PriceListHeaderComponent';
import { PriceListItemFormComponent } from '../../components/PriceList/PricelistItemForm/PriceListItemFormComponent';
import { TenantPriceList } from '../../models/Entities/TenantPriceLists/TenantPriceList';
import { useBoolean } from '@fluentui/react-hooks';
import { AppState } from '../../redux/reducers';
import { PriceDetails } from '../../models/Entities/PriceList/PriceDetail';

//store
import * as TenantPriceListsStore from '../../redux/middleware/TenantPriceListsMiddleware';
import PricesListComponent from '../../components/PriceList/PricesList/PricesListComponent';
import { TenantPriceListInfoComponent } from '../../components/PriceList/PriceListInfo/TenantPriceListInfoComponent';
import { PageHeader } from '../../commons/component/PageHeader/PageHeader';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const TenantPriceListDetailsPage = () => {
  const TenantPriceListState = useSelector(
    (state: AppState) => state.TenantPriceLists
  );

  const [selectedPriceList, setSelectedPriceList] = useState<TenantPriceList>();
  const [filterCriteria, setFilterCriteria] = useState<string>();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const [UpdateMassivePriceIsActive, setUpdateMassivePriceIsActive] =
    useState<boolean>(false);

  const [priceToEdit, setPriceToEdit] = useState<PriceDetails>();

  const { TenantPriceListId } = useParams();

  const dispatch = useDispatch();

  //useTranslation
  const [t] = useTranslation();

  useEffect(() => {
    if (TenantPriceListId) dispatch(mapDispatchToProps.GetAllTenantPriceList());
  }, [TenantPriceListId, dispatch]);

  useEffect(() => {
    if (
      TenantPriceListState &&
      TenantPriceListState.list &&
      TenantPriceListId
    ) {
      var list = TenantPriceListState.list.find(
        (List: TenantPriceList) => List.id === Number(TenantPriceListId)
      );
      setSelectedPriceList(list);
    }
  }, [TenantPriceListState?.list, TenantPriceListId]);

  // events
  const handleAddPriceEvent = () => {
    openPanel();
  };

  const handleEditPriceEvent = () => { };

  const handleDeletePriceEvent = () => { };

  const handleClonePriceList = () => {
    alert('Method not implemented');
  };

  const handleCancelDeletePriceListEvent = () => { };

  const handleClosePanel = () => {
    setPriceToEdit(undefined);
    dismissPanel();
  };

  return (
    <>
      <PageHeader title={t('tenantPriceListDetailPage.tenantPriceListDetailTitle')} />
      <div className="Section">
        {selectedPriceList && (
          <>
            <PriceListHeaderComponent
              PriceList={selectedPriceList}
              onAddMedicalService={handleAddPriceEvent}
              onSearchService={(searchText: string | undefined) => {
                setFilterCriteria(searchText);
              }}
              onDeletePriceList={handleDeletePriceEvent}
              onCancelDeletePriceList={handleCancelDeletePriceListEvent}
              onClonePriceList={handleClonePriceList}
              onMassiveUpdateChange={(isActive: boolean) => setUpdateMassivePriceIsActive(isActive)}
              isDeletingExecution={TenantPriceListState?.isDeletingOne || false}
              FailOnDeleting={TenantPriceListState?.failOnDeletingOne || false}
              ErrorOnDeleting={TenantPriceListState?.error}
            />
            <Separator />
            <div className="priceListDetailsContent">
              <div>
                <PricesListComponent
                  PriceListId={selectedPriceList.priceList.id}
                  onEditPrice={handleEditPriceEvent}
                  ServiceFilterCriteria={filterCriteria}
                  PriceMassiveEditionIsActive={UpdateMassivePriceIsActive} 
                  showPrice={true} 
                  showRemotePrice={true} 
                  showFailPrice={true} 
                  showRandomPrice={true} 
                  showAdditionalPaymentPrice={false}                
                />
              </div>
              <div className="Section infoPanel">
                <TenantPriceListInfoComponent
                  TenantPriceList={selectedPriceList}
                />
              </div>
            </div>
          </>
        )}
        {TenantPriceListState && !selectedPriceList?.priceListId && (
          <div className="emptyMessage">
            <h3>{t('tenantPriceListDetailPage.noListSelected')}</h3>
            <h4>{t('tenantPriceListDetailPage.selectList')}</h4>
          </div>
        )}
      </div>

      {selectedPriceList && selectedPriceList?.priceList && (
        <PriceListItemFormComponent
          isOpen={isOpen}
          dismissPanel={handleClosePanel}
          openPanel={openPanel}
          PriceListId={selectedPriceList.priceList.id}
          PriceDetails={priceToEdit}
          showPrice={true}
          showRemotePrice={true}
          showFailPrice={true}
          showRandomPrice={true}
          showAdditionalPaymentPrice={true}
        />
      )}
    </>
  );
};

const mapstoProps = (state: AppState) => {
  return {
    ...state.TenantPriceLists
  };
};
const mapDispatchToProps = {
  ...TenantPriceListsStore.ActionCreators
};

export default connect(
  mapstoProps,
  mapDispatchToProps
)(TenantPriceListDetailsPage as any);
