import { AgreementServicesDetail } from './../../models/Entities/AgreementServices/AgreementServicesDetail';
import { AgreementService } from './../../models/Entities/AgreementServices/AgreementServices';
import {
  AgreementServicesState,
  AgreementServicesUnloadedState
} from './../states/AgreementServicesState';
import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.AgreementServices.AgreementServices_GetAll_Request_Action
  | Actions.AgreementServices.AgreementServices_GetAll_Receive_Action
  | Actions.AgreementServices.AgreementServices_GetAll_Fail_Action
  | Actions.AgreementServices.AgreementServices_GetById_Request_Action
  | Actions.AgreementServices.AgreementServices_GetById_Receive_Action
  | Actions.AgreementServices.AgreementServices_GetById_Fail_Action
  | Actions.AgreementServices.AgreementServices_Add_Request_Action
  | Actions.AgreementServices.AgreementServices_Add_Receive_Action
  | Actions.AgreementServices.AgreementServices_Add_Fail_Action
  | Actions.AgreementServices.AgreementServices_Update_Request_Action
  | Actions.AgreementServices.AgreementServices_Update_Receive_Action
  | Actions.AgreementServices.AgreementServices_Update_Fail_Action
  | Actions.AgreementServices.AgreementServices_changeStatus_Request_Action
  | Actions.AgreementServices.AgreementServices_changeStatus_Receive_Action
  | Actions.AgreementServices.AgreementServices_changeStatus_Fail_Action
  | Actions.AgreementServices.AgreementServices_Delete_Request_Action
  | Actions.AgreementServices.AgreementServices_Delete_Receive_Action
  | Actions.AgreementServices.AgreementServices_Delete_Fail_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Add_Receive_Action
  | Actions.AgreementServiceDetails.AgreementServicesDetail_Update_Receive_Action;

export const AgreementServiceReducer: Reducer<AgreementServicesState> = (
  state: AgreementServicesState | undefined,
  incomingAction: Action
): AgreementServicesState => {
  if (state === undefined) {
    return AgreementServicesUnloadedState as AgreementServicesState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'AGREEMENT_SERVICES_GETALL_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'AGREEMENT_SERVICES_GETALL_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        list: action.agreementServices,
        error: undefined
      };
    case 'AGREEMENT_SERVICES_GETALL_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'AGREEMENT_SERVICES_GETBYID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'AGREEMENT_SERVICES_GETBYID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        list: [...state.list, action.agreementService],
        error: undefined
      };
    case 'AGREEMENT_SERVICES_GETBYID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'AGREEMENT_SERVICES_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'AGREEMENT_SERVICES_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        list: [...state.list, action.agreementService],
        error: undefined
      };
    case 'AGREEMENT_SERVICES_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'AGREEMENT_SERVICES_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'AGREEMENT_SERVICES_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        list: [
          ...state.list.filter(
            (service: AgreementService) =>
              service.id === action.agreementService.id
          ),
          action.agreementService
        ]
      };
    case 'AGREEMENT_SERVICES_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };
    case 'AGREEMENT_SERVICES_CHANGESTATUS_REQUEST_ACTION':
      return {
        ...state,
        isChangingStatus: true,
        changeStatusSuccess: undefined,
        failOnChangingStatus: false,
        error: undefined
      };
    case 'AGREEMENT_SERVICES_CHANGESTATUS_RECEIVE_ACTION':
      return {
        ...state,
        isChangingStatus: false,
        changeStatusSuccess: true,
        failOnChangingStatus: false,
        list: [
          ...state.list.filter(
            (service: AgreementService) =>
              service.id === action.agreementService.id
          ),
          action.agreementService
        ]
      };
    case 'AGREEMENT_SERVICES_CHANGESTATUS_FAIL_ACTION':
      return {
        ...state,
        isChangingStatus: false,
        changeStatusSuccess: false,
        failOnChangingStatus: true,
        error: action.error
      };
    case 'AGREEMENT_SERVICES_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'AGREEMENT_SERVICES_DELETE_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        list: state.list.filter(
          (service: AgreementService) =>
            service.id !== action.agreementServiceId
        )
      };
    case 'AGREEMENT_SERVICES_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
    case 'AGREEMENT_SERVICESDETAIL_ADD_RECEIVE_ACTION':
      const auxAgreementService = state.list.find(
        (service: AgreementService) =>
          service.id === action.agreementServicesDetail.agreementServiceId
      );

      return {
        ...state,
        list: auxAgreementService
          ? [
              ...state.list.filter(
                (service: AgreementService) =>
                  service.id !==
                  action.agreementServicesDetail.agreementServiceId
              ),
              {
                ...auxAgreementService,
                details: [
                  ...auxAgreementService.details,
                  action.agreementServicesDetail
                ]
              } as AgreementService
            ].sort(Functions.DynamicSort('id'))
          : state.list
      };
    case 'AGREEMENT_SERVICESDETAIL_UPDATE_RECEIVE_ACTION':
      const updatedAgreementService = state.list.find(
        (service: AgreementService) =>
          service.id === action.agreementServicesDetail.agreementServiceId
      );

      return {
        ...state,
        list: updatedAgreementService
          ? [
              ...state.list.filter(
                (service: AgreementService) =>
                  service.id !==
                  action.agreementServicesDetail.agreementServiceId
              ),
              {
                ...updatedAgreementService,
                details: [
                  ...updatedAgreementService.details.filter(
                    (detail: AgreementServicesDetail) =>
                      detail.id !== action.agreementServicesDetail.id
                  ),
                  action.agreementServicesDetail
                ]
              } as AgreementService
            ].sort(Functions.DynamicSort('id'))
          : state.list
      };

    default:
      return state;
  }
};
