import { ErrorType } from './../../models/HttpError';
import { AppThunkAction } from '../reducers';
import { NewProviderAssociationsMedicalService } from '../../models/Entities/ProviderAssociations/MedicalServices/NewProviderAssociationMedicalService';
import { ProviderAssociationsMedicalService } from '../../models/Entities/ProviderAssociations/MedicalServices/ProviderAssociationMedicalService';

// actions
import * as Actions from '../actions';

// services
import * as Services from '../../services/ProviderMedicalServices.Service';
import * as ProviderServices from '../../services/Provider.Services';

type KnownAction =
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_RequestAll_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_ReceiveAll_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_FailAll_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_RequestById_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_ReceiveById_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_FailById_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Add_Request_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Add_Receive_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Add_Fail_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Delete_Request_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Delete_By_Id_Receive_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Delete_By_Medical_Service_Id_Receive_Action
  | Actions.ProviderMedicalServiceAssociations.ProviderMedicalService_Delete_Fail_Action
  | Actions.Provider.Providers_GetAll_Receive_Action;

export const actionCreators = {
  GetMedicalServiceByProviderId:
    (ProviderId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_MEDICAL_SERVICE_FAIL_ALL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      if (!state?.Provider?.successLoadingAll) {
        ProviderServices.GetProviders(1, 1000, undefined, token).then((Providers) => {
          dispatch({
            type: 'PROVIDERS_GETALL_RECEIVE_ACTION',
            providers: Providers
          });

          dispatch({
            type: 'PROVIDER_MEDICAL_SERVICE_REQUEST_ALL_ACTION',
            ProviderId: ProviderId
          });
        });
      } else {
        dispatch({
          type: 'PROVIDER_MEDICAL_SERVICE_REQUEST_ALL_ACTION',
          ProviderId: ProviderId
        });
      }

      Services.GetAllProviderServices(ProviderId, token)
        .then((ProviderMedicalServices) => {
          dispatch({
            type: 'PROVIDER_MEDICAL_SERVICE_RECEIVE_ALL_ACTION',
            ProviderId: ProviderId,
            ProviderMedicalServices
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PROVIDER_MEDICAL_SERVICE_FAIL_ALL_ACTION',
            error: error
          });
        });
    },
  AssociateMedicalServiceToProvider:
    (
      newProviderMedicalService: NewProviderAssociationsMedicalService
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_MEDICAL_SERVICE_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_MEDICAL_SERVICE_ADD_REQUEST_ACTION',
        ProviderMedicalService: newProviderMedicalService
      });

      Services.AssociateMedicalService(newProviderMedicalService, token)
        .then((ProviderMedicalService: ProviderAssociationsMedicalService) => {
          dispatch({
            type: 'PROVIDER_MEDICAL_SERVICE_ADD_RECEIVE_ACTION',
            ProviderMedicalService: ProviderMedicalService
          });
        })
        .catch((error) => {
          error.then((currentError: ErrorType) => {
            dispatch({
              type: 'PROVIDER_MEDICAL_SERVICE_ADD_FAIL_ACTION',
              error: currentError
            });
          });
        });
    },
  DisassociateMedicalServiceFromProviderById:
    (
      ProviderMedicalService: ProviderAssociationsMedicalService
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_MEDICAL_SERVICE_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_MEDICAL_SERVICE_DELETE_REQUEST_ACTION',
        ProviderMedicalServiceId: ProviderMedicalService.id
      });

      Services.DisassociateMedicalServiceById(ProviderMedicalService.id, token)
        .then((ProviderMedicalService) => {
          dispatch({
            type: 'PROVIDER_MEDICAL_SERVICE_DELETE_BY_ID_RECEIVE_ACTION',
            ProviderId: ProviderMedicalService.providerId,
            ProviderMedicalServiceId: ProviderMedicalService.id
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PROVIDER_MEDICAL_SERVICE_DELETE_FAIL_ACTION',
            error: error
          });
        });
    },
  DisassociateMedicalServiceFromProviderByMedicalServiceId:
    (
      ProviderId: number,
      MedicalServiceId: number
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PROVIDER_MEDICAL_SERVICE_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PROVIDER_MEDICAL_SERVICE_DELETE_REQUEST_ACTION',
        ProviderMedicalServiceId: MedicalServiceId
      });

      Services.DisassociateMedicalServiceByMedicalServiceId(
        ProviderId,
        MedicalServiceId,
        token
      )
        .then((ProviderMedicalService) => {
          dispatch({
            type: 'PROVIDER_MEDICAL_SERVICE_DELETE_BY_MEDICAL_SERVICE_ID_RECEIVE_ACTION',
            ProviderId: ProviderId,
            MedicalServiceId: MedicalServiceId
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PROVIDER_MEDICAL_SERVICE_DELETE_FAIL_ACTION',
            error: error
          });
        });
    }
};
