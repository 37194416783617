import {
  Checkbox,
  Label,
  TextField,
  TooltipHost,
  Dropdown,
  Separator,
  MessageBarType,
  IDropdownOption
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { IndexKind } from 'typescript';
import { MessageComponent } from '../../../../../commons/component/MessageComponent';
import { ContractStage } from '../../../../../models/Entities/Contracts/ContractStage';
import { NewContractStage } from '../../../../../models/Entities/Contracts/NewContractStage';
import { NewStageCycle } from '../../../../../models/Entities/Contracts/NewStageCycle';
import { StageCycle } from '../../../../../models/Entities/Contracts/StageCycle';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

//style
import '../ProtocolContractFormComponent.css';

// own Functions
import * as ownFunctions from './ProtocolContractStageFunctions';

export interface IProps {
  currentStages: ContractStage[];
  currentCycles: StageCycle[];
  stageName: string;
  stageAmount: number;
  CycleAmount: number;
  CycleName: string;
  onChangeStages?: (stages: NewContractStage[]) => void;
  onChangeCycles?: (cycles: NewStageCycle[]) => void;
}

export const ProtocolContractStages = (props: IProps) => {
  const [stages, setStages] = useState<NewContractStage[]>([]);
  const [cycles, setCycles] = useState<NewStageCycle[]>([]);
  const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>([]);

  //useTranslation
  const [t] = useTranslation() ;

  // event
  const handleStageNameChange = (newValue: string, index: number) => {
    let newStages = [...stages];
    newStages[index] = {
      ...newStages[index],
      stageName: newValue
    };
    setStages(newStages);
    if (props.onChangeStages) props.onChangeStages(newStages);
  };

  const handleStageCycleChange = (cycleIndex: number, stageIndex: number) => {
    let newStages = [...stages];
    newStages[stageIndex] = {
      ...newStages[stageIndex],
      cycle: cycles[cycleIndex] as StageCycle
    };
    setStages(newStages);
    if (props.onChangeStages) props.onChangeStages(newStages);
  };

  const handleCycleNameChange = (newValue: string, index: number) => {
    let newCycles = [...cycles];
    newCycles[index] = {
      ...cycles[index],
      cycleName: newValue
    };
    setCycles(newCycles);
    if (props.onChangeCycles) props.onChangeCycles(cycles);
  };

  const handleStageOnSiteMandatoryChange = (
    isMandatory: boolean,
    index: number
  ) => {
    let newStages = [...stages];
    newStages[index] = {
      ...newStages[index],
      isOnSiteMandatory: isMandatory
    };
    setStages(newStages);
    if (props.onChangeStages) props.onChangeStages(newStages);
  };

  const handleStageisOptionalChange = (isOptional: boolean, index: number) => {
    let newStages = [...stages];
    newStages[index] = {
      ...newStages[index],
      isOptional: isOptional
    };
    setStages(newStages);
    if (props.onChangeStages) props.onChangeStages(newStages);
  };

  const handleStageFailSeletionEnabledChange = (
    isFailSelectionEnabled: boolean,
    index: number
  ) => {
    let newStages = [...stages];
    newStages[index] = {
      ...newStages[index],
      failSelectionEnabled: isFailSelectionEnabled
    };
    setStages(newStages);
    if (props.onChangeStages) props.onChangeStages(newStages);
  };

  useEffect(() => {
    setCycles(
      ownFunctions.getCycles(
        cycles as StageCycle[],
        props.CycleAmount,
        props.CycleName,
      )
    );
  }, [props.CycleName, props.CycleAmount]);

  useEffect(() => {
    setDropdownOptions(
      ownFunctions.getCyclesAsDropdownOptions(cycles as StageCycle[])
    );
  }, [cycles]);

  useEffect(() => {
    setStages(
      ownFunctions.getStages(
        stages,
        props.stageAmount,
        props.stageName
      )
    );
  }, [props.stageName, props.stageAmount]);

  useEffect(() => {
    if (props.onChangeStages) props.onChangeStages(stages);
    if (props.onChangeCycles) props.onChangeCycles(cycles);
  }, [stages, cycles.length]);

  return (
    <>
      <Label>{t('protocolContractStages.cycle.cycleList')}</Label>
      <div className="ProtocolStagesNames">
        {cycles.map((cycle, index) => {
          return (
            <div className="StageItem">
              <Label>{t('protocolContractStages.cycle.cycleNumber')}{index + 1} </Label>
              <Separator vertical />
              <TextField
                defaultValue={cycle.cycleName}
                borderless={true}
                onChange={(
                  event: React.FormEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                  newValue?: string | undefined
                ) => newValue && handleCycleNameChange(newValue, index)}
              />
            </div>
          );
        })}
      </div>
      {cycles.length === 0 && (
        <MessageComponent
          message={t('protocolContractStages.cycle.informedCycles')} 
          type={MessageBarType.info}
        />
      )}
      <Label>{t('protocolContractStages.stage.visitList')}</Label>
      <div className="ProtocolStagesNames">
        {stages.map((stage: NewContractStage, index) => {
          return (
            <div className="StageItem" key={'stage' + index}>
              <Label>{t('protocolContractStages.stage.visitNumber')}{index + 1} </Label>
              <Separator vertical />
              <TextField
                borderless={true}
                key={index}
                defaultValue={stage.stageName}
                onChange={(
                  event: React.FormEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                  newValue?: string | undefined
                ) => newValue && handleStageNameChange(newValue, index)}
              />
              {cycles.length > 0 && (
                <>
                  <Separator vertical />
                  <Dropdown
                    className="stageDropDown"
                    options={ownFunctions.getCyclesAsDropdownOptions(
                      cycles as StageCycle[]
                    )}
                    onChange={(
                      event: React.FormEvent<HTMLDivElement>,
                      option?: IDropdownOption<any> | undefined,
                      cycleIndex?: number | undefined
                    ) =>
                      cycleIndex
                        ? handleStageCycleChange(cycleIndex - 1, index)
                        : null
                    }
                  />
                </>
              )}
              <Separator vertical />
              <TooltipHost content={t('protocolContractStages.stage.inPerson')}>
                <Checkbox
                  defaultChecked={stage.isOnSiteMandatory}
                  onChange={(
                    ev?:
                      | React.FormEvent<HTMLInputElement | HTMLElement>
                      | undefined,
                    checked?: boolean | undefined
                  ) =>
                    checked !== undefined
                      ? handleStageOnSiteMandatoryChange(checked, index)
                      : null
                  }
                />
              </TooltipHost>
              <TooltipHost content={t('protocolContractStages.stage.mandatoryVisit')}>
                <Checkbox
                  defaultChecked={!stage.isOptional}
                  onChange={(
                    ev?:
                      | React.FormEvent<HTMLInputElement | HTMLElement>
                      | undefined,
                    checked?: boolean | undefined
                  ) =>
                    checked !== undefined
                      ? handleStageisOptionalChange(!checked, index)
                      : null
                  }
                />
              </TooltipHost>
              <TooltipHost content={t('protocolContractStages.stage.selectionError')}>
                <Checkbox
                  defaultChecked={stage.failSelectionEnabled}
                  onChange={(
                    ev?:
                      | React.FormEvent<HTMLInputElement | HTMLElement>
                      | undefined,
                    checked?: boolean | undefined
                  ) =>
                    checked !== undefined
                      ? handleStageFailSeletionEnabledChange(checked, index)
                      : null
                  }
                />
              </TooltipHost>
            </div>
          );
        })}
        {stages.length === 0 && (
          <MessageComponent
            message={t('protocolContractStages.stage.informedVisits')} 
            type={MessageBarType.info}
          />
        )}
      </div>
    </>
  );
}

