import { connect } from 'react-redux';
import { Text, Avatar, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Label, Spinner, TableCellLayout, TableColumnDefinition, Toolbar, ToolbarButton, ToolbarDivider, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, Button, TeachingPopover, TeachingPopoverTrigger, TeachingPopoverSurface, TeachingPopoverHeader, TeachingPopoverBody, TeachingPopoverFooter, TeachingPopoverTitle } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { Separator } from '@fluentui/react';

// styles
import './Payments.List.Styles.css';

//icons 
import {
    Add16Regular,
    EditRegular,
    DeleteRegular,
    Chat20Regular

} from "@fluentui/react-icons";

// Component functions
import * as ComponentFunctions from './Payments.List.Functions';

// models
import { Payment } from '../../../../models/Entities/CashFlow/Payment/Payment';

// components
import PaymentFormComponent from '../Payment.Form/Payment.Form.Component';
import { FormType } from '../Payment.Form/Payment.Form.Function';
import moment from 'moment';

const PaymentListComponent = (props: ComponentFunctions.Props) => {

    // add or update form controll
    const [isPaymentFormOpen, setIsPaymentFormOpen] = useState(false);

    const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);

    // events    

    // on close form
    const onPaymentFormDismiss = () => {
        setSelectedPayment(null);
        setIsPaymentFormOpen(false);
    }

    // on edit payment
    const onEditPayment = (payment: Payment) => {
        setSelectedPayment(payment);
        setIsPaymentFormOpen(true);
    }

    // on delete payment
    const onDeletePayment = (payment: Payment) => {
        props.DeletePayment(payment.id);
    }

    useEffect(() => {
        props.GetAllPersons();
        switch (props.type) {
            case ComponentFunctions.PaymentListType.Payer:
                props.GetAllPaymentsByPayer(props.EntityIdentifier, props.FixedAssociatedEntityIdentifier);
                break;
            case ComponentFunctions.PaymentListType.Receiver:
                props.GetAllPaymentsByReceiver(props.EntityIdentifier, props.FixedAssociatedEntityIdentifier);
                break;
        }
    }, []);

    const Forms = (
        <>
            <PaymentFormComponent
                FormType={props.type === ComponentFunctions.PaymentListType.Payer ? FormType.payer : FormType.receiver}
                ReferalId={props.EntityIdentifier}
                FixedAssociatedEntityIdentifier={props.FixedAssociatedEntityIdentifier}
                isOpen={isPaymentFormOpen}
                PaymentId={selectedPayment?.id}
                onClose={onPaymentFormDismiss}
            />
        </>
    );

    const columns: TableColumnDefinition<Payment>[] = [
        createTableColumn<Payment>({
            columnId: "payer",
            compare: (a, b) => {
                return props.GetPersonByIdentifier(a.payer)!.businessName!.localeCompare(props.GetPersonByIdentifier(b.payer)!.businessName!);
            },
            renderHeaderCell: () => {
                return "Pagador";
            },
            renderCell: (item: Payment) => {
                return (
                    <TableCellLayout media={(
                        <Avatar
                            aria-label={item.payer}
                            name={props.GetPersonByIdentifier(item.payer)?.businessName}
                            color="colorful"
                        />
                    )}>
                        {props.GetPersonByIdentifier(item.payer)?.businessName}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Payment>({
            columnId: "date",
            compare: (a, b) => {
                return new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime();
            },
            renderHeaderCell: () => {
                return "Fecha de pago";
            },
            renderCell: (item: Payment) => {
                return (
                    <TableCellLayout>
                        {moment(new Date(item.paymentDate).toLocaleDateString()).format("DD/MM/YYYY")}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Payment>({
            columnId: "receiver",
            compare: (a, b) => {
                return a.receiver.localeCompare(b.receiver);
            },
            renderHeaderCell: () => {
                return "Cobrador";
            },
            renderCell: (item: Payment) => {
                return (
                    <TableCellLayout media={(
                        <Avatar
                            aria-label={item.receiver}
                            name={props.GetPersonByIdentifier(item.receiver)?.businessName}
                            color="colorful"
                        />
                    )}>
                        {props.GetPersonByIdentifier(item.receiver)?.businessName}
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Payment>({
            columnId: "amount",
            compare: (a, b) => {
                return (a.amount - b.amount);
            },
            renderHeaderCell: () => {
                return "Monto";
            },
            renderCell: (item: Payment) => {
                return (
                    <TableCellLayout>
                        <TeachingPopover>
                            <TeachingPopoverTrigger>
                                <strong style={{cursor: 'pointer'}} onClick={(e) => e.stopPropagation()}>{item.amount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</strong>
                            </TeachingPopoverTrigger>
                            <TeachingPopoverSurface>
                                <TeachingPopoverHeader>Comentarios</TeachingPopoverHeader>
                                <TeachingPopoverBody>
                                    <Text>{item.comments}</Text>
                                </TeachingPopoverBody>
                            </TeachingPopoverSurface>
                        </TeachingPopover>

                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Payment>({
            columnId: "imputedAmount",
            compare: (a, b) => {
                return (a.amount - b.amount);
            },
            renderHeaderCell: () => {
                return "Imputado";
            },
            renderCell: (item: Payment) => {
                return (
                    <TableCellLayout>
                        <strong style={{cursor: 'pointer'}} onClick={(e) => e.stopPropagation()}>{item.imputedAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</strong>
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Payment>({
            columnId: "pendingIndictmentAmount",
            compare: (a, b) => {
                return (a.amount - b.amount);
            },
            renderHeaderCell: () => {
                return "Pendiente";
            },
            renderCell: (item: Payment) => {
                return (
                    <TableCellLayout>
                        <strong style={{cursor: 'pointer'}} onClick={(e) => e.stopPropagation()}>{item.pendingIndictmentAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</strong>
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Payment>({
            columnId: "comments",
            compare: (a, b) => {
                return (a.comments || '').localeCompare(b.comments || '');
            },
            renderHeaderCell: () => {
                return "Comentarios";
            },
            renderCell: (item: Payment) => {
                return (
                    <TableCellLayout>
                        <TeachingPopover>
                            <TeachingPopoverTrigger>
                                <Button appearance="transparent" icon={<Chat20Regular />} onClick={(e) => e.stopPropagation()} >ver</Button>
                            </TeachingPopoverTrigger>
                            <TeachingPopoverSurface>
                                <TeachingPopoverHeader>Comentarios</TeachingPopoverHeader>
                                <TeachingPopoverBody>
                                    <Text>{item.comments}</Text>
                                </TeachingPopoverBody>
                            </TeachingPopoverSurface>
                        </TeachingPopover>

                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Payment>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "Actions";
            },
            renderCell: (item: Payment) => {
                return (
                    <>
                        <Button appearance="subtle" aria-label="Edit" icon={<EditRegular />} onClick={(event) => { event.stopPropagation(); onEditPayment(item) }} />
                        <Button appearance="subtle" aria-label="Delete" icon={<DeleteRegular style={{ color: 'red' }} />} onClick={(event) => { event.stopPropagation(); onDeletePayment(item) }} />
                    </>
                );
            },
        }),
    ];


    return (
        <>
            <Toolbar aria-label="Default" {...props}>
                <ToolbarButton
                    aria-label="Increase Font Size"
                    appearance="primary"
                    icon={<Add16Regular />}
                    onClick={() => { setIsPaymentFormOpen(true) }}

                >Agregar nuevo {props.type === ComponentFunctions.PaymentListType.Payer ? 'pago' : 'cobro'}</ToolbarButton>
            </Toolbar>
            <Separator />
            <DataGrid
                items={props.Payments}
                columns={columns}
                sortable
                selectionMode="multiselect"
                getRowId={(item) => item.id}
                focusMode="composite"
                style={{ minWidth: "550px" }}
            >
                <DataGridHeader>
                    <DataGridRow
                        selectionCell={{
                            checkboxIndicator: { "aria-label": "Select all rows" },
                        }}
                    >
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<Payment>>
                    {({ item, rowId }) => (
                        <DataGridRow<Payment>
                            key={rowId}
                            selectionCell={{
                                checkboxIndicator: { "aria-label": "Select row" },
                            }}
                        >
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
            {props.isLoading && (<div className='LoadingPayments'>
                <Spinner size="tiny" label="Cargando pagos..." />
            </div>)}
            {props.isLoadingSuccessfully && !props.Payments?.length && (
                <div className='NoDataContainer'>
                    <img
                        src={require('../../../../assets/img/nodata.png')}
                        alt="No Data"
                        style={{ height: '6em', marginBottom: '.5em' }}
                    ></img>
                    <Text className='NoData'>No hay pagos registrados</Text>
                    {/* <PrimaryButton text="Agregar pago" /> */}
                </div>
            )}
            {Forms}
        </>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(PaymentListComponent as any);
