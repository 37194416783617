import {
    DefaultButton,
    FontWeights,
    getTheme,
    IconButton,
    IIconProps,
    IStackProps,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Modal,
    Panel,
    PanelType,
    PrimaryButton,
    Separator,
    TextField,
    Toggle
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ErrorMessageComponent } from '../../../Error/ErrorMessageComponent';

// component functions
import * as ComponentFunctions from './CycleForm.Functions';
import { StageCycle } from '../../../../models/Entities/Contracts/StageCycle';
import { NewStageCycle } from '../../../../models/Entities/Contracts/NewStageCycle';
  
////i18Next
import { useTranslation, Trans } from 'react-i18next';
import { Spinner } from '@fluentui/react-components';

interface FormValidation {
    success: boolean;
    errors: string[];
}
    
export const CycleFormComponent = (props: ComponentFunctions.Props) => {

const [submittedForm, setSubmittedForm] = useState<boolean>(false);
const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
>();

// entity properties
/*
    contractId: number;
    cycle: string;
    order: number;
    cycleName: string;
*/


const [cycleName, setCycleName] = useState<string>();
const [order, setOrder] = useState<number>(0);
const [cycle, setCycle] = useState<string>();
const [cycleId, setCycleId] = useState<number>();

//useTranslation()
const [t] = useTranslation();

//load necesary data
useEffect(() => {
    props.GetContractById(props.ContractId);
    if (props.ContractStageCycleId) props.GetContractStageCycleById(props.ContractStageCycleId);
}, []);

useEffect(() => {
    if (props.isSavedSuccessfully) {
        props.dismissPanel();
        ResetForm();
    }
}, [props.isSavedSuccessfully]);


useEffect(() => {
    if (props.ContractStageCycle) {
        setCycleName(props.ContractStageCycle.cycleName);
        setOrder(props.ContractStageCycle.order);
        setCycle(props.ContractStageCycle.cycle);
        setCycleId(props.ContractStageCycle.id);
    }
}, [props.ContractStageCycle]);

const ResetForm = () => {
    setSubmittedForm(false);
    setCycleName('');
    setOrder(0);
    setCycle('');
    setCycleId(undefined);
};


// Events
const onChangeCycleNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    setCycleName(newValue);
};

const onChangeOrderTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    setOrder(Number(newValue));
};

const onChangeCycleTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    setCycle(newValue);
};


const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
    let CurrentValues: StageCycle | NewStageCycle;
    if (props.ContractStageCycle) {
      CurrentValues = {
        id: props.ContractStageCycle.id,
        contractId: props.ContractId,
        contractAddendumId: props.ContractAddendumId,
        cycleName: cycleName!,
        order: order!,
        cycle: cycle!
      };
      props.UpdateContractStageCycle(CurrentValues as StageCycle);
    } else {
      CurrentValues = {
        contractId: props.ContractId,
        contractAddendumId: props.ContractAddendumId,
        cycleName: cycleName!,
        order: order!,
        cycle: cycle!
      };
      props.AddContractStageCycle(CurrentValues as NewStageCycle);
    }
    }
};

const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
};

// footer
const onRenderFooterContent = () => {
    return (
    <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'cycleForm.footer.cancelButton'}/>   
        </DefaultButton>
        <PrimaryButton
        onClick={HandleSaveForm}
        styles={buttonStyles}
        className="ConfirmAction"
        >
          {props.isSaving && <><Spinner size="extra-tiny" />&nbsp;</>}<Trans i18nKey={'cycleForm.footer.saveButton'}/> 
        </PrimaryButton>
    </div>
    );
};

const formFields = (
    <div {...columnProps}>
    <TextField
        label={t('cycleForm.formFields.cycleName')} 
        required
        value={cycleName}
        onChange={onChangeCycleNameTextFieldValue}
        errorMessage={
        submittedForm &&
        !cycleName ?
        t('cycleForm.formFields.errorMessage') : ''
        }
    />
    </div>
    
);

// form validation

const ValidateForm = (): boolean => {
    let validationResult: boolean = true;

    let FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    if (!cycleName) {
        validationResult = false;
        FormValidation.errors.push(t('cycleForm.formFields.errorMessage'));
    }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
};

return (
    <Panel
    isOpen={props.isOpen}
    type={PanelType.medium}
    headerText={t('cycleForm.panel.cycleInformation')}
    onDismiss={props.dismissPanel}
    closeButtonAriaLabel="Close"
    onRenderFooter={onRenderFooterContent}
    isFooterAtBottom={true}
    >
    {/* <div className={contentStyles.header}>
        <span>Información del Proveedor</span>
        <IconButton
        styles={iconButtonStyles}
        iconProps={cancelIcon}
        ariaLabel="Close popup modal"
        onClick={props.dismissPanel}
        />
    </div> */}
    {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {t('cycleForm.panel.reviewInformation')} 
        <ul>
            {FormValidationStatus?.errors.map(
            (Error: string, index: number) => (
                <li key={index}>{Error}</li>
            )
            )}
        </ul>
        </MessageBar>
    )}
    <div className={contentStyles.body}>
        {props.failOnSaving &&
        props.error && (
            <ErrorMessageComponent Error={props.error} />
        )}
        {formFields}
    </div>
    </Panel>
);
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(CycleFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
tokens: { childrenGap: 15 }
// styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
},
header: [
    theme.fonts.mediumPlus,
    {
    flex: '1 1 auto',
    color: theme.palette.neutralPrimary,
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '0px 12px 12px 12px',
    backgroundColor: 'lightgray',
    borderRadius: '10px 10px 0 0'
    }
],
body: {
    flex: '4 4 auto',
    overflowY: 'auto',
    selectors: {
    'p:first-child': { marginTop: 0 },
    'p:last-child': { marginBottom: 0 }
    },
    padding: '0 1em 0 0',
    height: '85vh'
},
footer: {
    //flex: '4 4 auto',
    padding: '0 24px 24px 24px'
}
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
},
rootHovered: {
    color: theme.palette.neutralDark
}
};