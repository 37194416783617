import {
  DefaultButton,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  IStackProps,
  Label,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Text,
  Panel,
  PrimaryButton,
  Separator,
  Toggle,
  FontSizes,
  Stack
} from '@fluentui/react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';
import { NewAvailableMedicalService } from '../../../models/Entities/MedicalServices/NewAvailableMedicalService';
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';

// function component
import * as ComponentFunctions from './MedicalServiceSettingsFormFunctions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './MedicalServiceSettingsFormStyles.css';

interface FormValidation {
  success: boolean;
  errors: string[];
}

const MedicalServiceSettingsFormComponent = (
  props: ComponentFunctions.Props
) => {
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();

  // form fields state
  const [enablePatientSelfManagement, setEnablePatientSelfManagement] =
    useState<boolean>(false);
  const [serviceProvidedBy3rdParty, setServiceProvidedBy3rdParty] =
    useState<boolean>(false);
  const [enableForAppointment, setEnableForAppointment] =
    useState<boolean>(false);
  const [onlyForAdministrativeUse, setOnlyForAdministrativeUse] = useState<boolean>(false);

  //useTranslation()
  const [t] = useTranslation();

  // events

  const onChangePatientSelfManagementStatus = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setEnablePatientSelfManagement(checked!);
    var UpdatedMedicalService = props.MedicalService;
    UpdatedMedicalService.enablePatientSelfManagement = checked!;
    SaveMedicalServiceSettings(UpdatedMedicalService);
  };

  const onChangeServiceProvidedBy3rdPartyStatus = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setServiceProvidedBy3rdParty(checked!);
    var UpdatedMedicalService = props.MedicalService;
    UpdatedMedicalService.serviceProvidedBy3rdParty = checked!;
    SaveMedicalServiceSettings(UpdatedMedicalService);
  };

  const onChangeEnableForAppointmentStatus = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setEnableForAppointment(checked!);
    var UpdatedMedicalService = props.MedicalService;
    UpdatedMedicalService.enableforAppointment = checked!;
    SaveMedicalServiceSettings(UpdatedMedicalService);
  };

  const SaveMedicalServiceSettings = (
    MedicalService: AvailableMedicalService
  ) => {
    setSubmittedForm(true);
    props.ChangeMedicalServiceSettings(MedicalService);
  };

  const onChangeOnlyForAdminitrativeUseStatus = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setOnlyForAdministrativeUse(checked!);
    var UpdatedMedicalService = props.MedicalService;
    UpdatedMedicalService.onlyForAdministrativeUse = checked!;
    SaveMedicalServiceSettings(UpdatedMedicalService);
  };

  // const HandleSaveForm = () => {
  //   setSubmittedForm(true);
  //   if (ValidateForm()) {
  //     var CurrentValues: AvailableMedicalService | NewAvailableMedicalService;
  //     if (props.MedicalService) {
  //       CurrentValues = {
  //         id: props.MedicalService.id,
  //         serviceNameReference: props.MedicalService.serviceNameReference!,
  //         isLinkedToSnowmed: props.MedicalService.isLinkedToSnowmed,
  //         available: props.MedicalService.available,
  //         disableUntilDate: props.MedicalService.disableUntilDate,
  //         disableComment: props.MedicalService.disableComment,
  //         snowmedCode: props.MedicalService.snowmedCode,
  //         comments: props.MedicalService.comments,
  //         snowmedName: props.MedicalService.snowmedName,
  //         enablePatientSelfManagement: false,
  //         serviceProvidedBy3rdParty: false,
  //         enableforAppointment: true,
  //         onlyForAdministrativeUse: false
  //       };
  //       props.ChangeMedicalServiceSettings(CurrentValues);
  //     }
  //   }
  // };

  const ResetForm = () => {
    setEnablePatientSelfManagement(false);
    setServiceProvidedBy3rdParty(false);
    setEnableForAppointment(false);
    setFormValidationStatus(undefined);
    setSubmittedForm(false);
  };

  const HandleCancelForm = () => {
    props.dismissPanel();
    ResetForm();
  };

  // footer
  const onRenderFooterContent = () => {
    return (
      <div>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'medicalServiceSettingsForm.footer.closeButton'}/>
        </DefaultButton>
      </div>
    );
  };

  const formFields = (
    <div className="SeparatedList">
      <Stack>
        <Toggle
          label={t('medicalServiceSettingsForm.props.enableAppointment')}
          onText={t('medicalServiceSettingsForm.props.yes')}
          offText={t('medicalServiceSettingsForm.props.no')}
          defaultChecked={props.MedicalService?.enableforAppointment}
          onChange={onChangeEnableForAppointmentStatus}
        />
        <Text>
           {t('medicalServiceSettingsForm.props.requestMedicalService')} 
        </Text>
      </Stack>
      <Stack>
        <Toggle
          label={t('medicalServiceSettingsForm.props.enablePatientSelfManagement')}  
          onText={t('medicalServiceSettingsForm.props.yes')} 
          offText={t('medicalServiceSettingsForm.props.no')} 
          defaultChecked={props.MedicalService?.enablePatientSelfManagement}
          onChange={onChangePatientSelfManagementStatus}
        />
        <Text>
          {t('medicalServiceSettingsForm.props.enableSystem')}  
        </Text>
      </Stack>
      <Stack>
        <Toggle
          label={t('medicalServiceSettingsForm.props.serviceProvided')} 
          onText={t('medicalServiceSettingsForm.props.yes')}
          offText={t('medicalServiceSettingsForm.props.no')}
          onChange={onChangeServiceProvidedBy3rdPartyStatus}
          defaultChecked={props.MedicalService?.serviceProvidedBy3rdParty}
        />
        <Text>
        {t('medicalServiceSettingsForm.props.serviceInformation')} 
        </Text>
      </Stack>
      <Stack>
        <Toggle
          label={t('medicalServiceSettingsForm.props.onlyAdministrativeService')} 
          onText={t('medicalServiceSettingsForm.props.yes')}
          offText={t('medicalServiceSettingsForm.props.no')}
          onChange={onChangeOnlyForAdminitrativeUseStatus}
          defaultChecked={props.MedicalService?.onlyForAdministrativeUse}
        />
        <Text>
          {t('medicalServiceSettingsForm.props.onlyAdministrativeUseInformation')}
        </Text>
      </Stack>
    </div>
  );

  // form validation

  const ValidateForm = (): boolean => {
    var validationResult: boolean = true;

    var FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  return (
    <Panel
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      {props.MedicalService && (
        <div>
          <Text>{t('medicalServiceSettingsForm.props.medicalService')}</Text>
          <Label style={{ fontSize: FontSizes.mediumPlus }}>
            {props.MedicalService.serviceNameReference}
          </Label>
          <code>
            <Label>
              {props.MedicalService.isLinkedToSnowmed
                ? t('medicalServiceSettingsForm.props.linkedSnowmed')
                : t('medicalServiceSettingsForm.props.notLinkedSnowmed')}
            </Label>
            {props.MedicalService.isLinkedToSnowmed && (
              <span>
                [{props.MedicalService.snowmedCode}|
                {props.MedicalService.snowmedName}]
              </span>
            )}
          </code>
          <Separator />
        </div>
      )}
      {submittedForm &&
        (props.failOnLoading || props.failOnUpdating) &&
        props.error && <ErrorMessageComponent Error={props.error} />}
      <div className={contentStyles.body}>{formFields}</div>
    </Panel>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(MedicalServiceSettingsFormComponent as any);

// styles
const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10,
    Width: '500px'
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
