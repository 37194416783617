
//models
import { ContractAccess } from "../models/Entities/Contracts/ContractAccess";
import { IResult } from "../models/HttpResult";

//Functions
import * as Functions from '../functions/common';
import { NewContractAccess } from "../models/Entities/Contracts/NewContractAccess";

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + "contracts/"

// Get All contract Access
export const GetContractAccess = async (contractId: number, token: string): Promise<ContractAccess[]> => {

    let requestURL: string = BaseURL + contractId + "/access";

    try {
        const response = await fetch(requestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        });

        if (!response.ok) {
            const errorBody = await response.text();
            throw Functions.HttpErrorHandler(errorBody, response);
        }

        const data: IResult<ContractAccess[]> = await response.json();
        return data.data;
    } catch (error) {
        throw error;
    }
};

// Get contract Access by ID
export const GetContractAccessById = async (ContractAccessId: number, token: string): Promise<ContractAccess> => {

    let requestURL: string = BaseURL + "access/" + ContractAccessId;

    try {
        const response = await fetch(requestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        });

        if (!response.ok) {
            const errorBody = await response.text();
            throw Functions.HttpErrorHandler(errorBody, response);
        }

        const data: IResult<ContractAccess> = await response.json();
        return data.data;
    } catch (error) {
        throw error;
    }
};

// Create contract Access
export const CreateContractAccess = async (contractAccess: NewContractAccess, token: string): Promise<ContractAccess> => {

    let requestURL: string = BaseURL + "access";

    try {
        const response = await fetch(requestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(contractAccess)
        });

        if (!response.ok) {
            const errorBody = await response.text();
            throw Functions.HttpErrorHandler(errorBody, response);
        }

        const data: IResult<ContractAccess> = await response.json();
        return data.data;
    } catch (error) {
        throw error;
    }
};

// Update contract Access
export const UpdateContractAccess = async (contractAccess: ContractAccess, token: string): Promise<ContractAccess> => {

    let requestURL: string = BaseURL + "access/";

    try {
        const response = await fetch(requestURL, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(contractAccess)
        });

        if (!response.ok) {
            const errorBody = await response.text();
            throw Functions.HttpErrorHandler(errorBody, response);
        }

        const data: IResult<ContractAccess> = await response.json();
        return data.data;
    } catch (error) {
        throw error;
    }
};

// Delete contract Access
export const DeleteContractAccess = async (ContractAccessId: number, token: string): Promise<boolean> => {

    let requestURL: string = BaseURL + "access/" + ContractAccessId;

    try {
        const response = await fetch(requestURL, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        });

        if (!response.ok) {
            const errorBody = await response.text();
            throw Functions.HttpErrorHandler(errorBody, response);
        }

        return true;
    } catch (error) {
        throw error;
    }
};
