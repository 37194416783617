import { Action, Reducer } from 'redux';
import { ContractStageState, ContractStageUnloadState } from '../states/ContractStageState';

// actions
import * as Actions from '../actions/ContractStage.Actions';

// functions
import * as Functions from '../../functions/common';

type KnownAction = 
| Actions.ContractStage_GetAllBycontractId_Request_Action
| Actions.ContractStage_GetAllBycontractId_Receive_Action
| Actions.ContractStage_GetAllBycontractId_Fail_Action
| Actions.ContractStage_UpdateAllByContractId_Request_Action
| Actions.ContractStage_UpdateAllByContractId_Receive_Action
| Actions.ContractStage_UpdateAllByContractId_Fail_Action
| Actions.ContractStage_GetById_Request_Action
| Actions.ContractStage_GetById_Receive_Action
| Actions.ContractStage_GetById_Fail_Action
| Actions.ContractStage_Add_Request_Action
| Actions.ContractStage_Add_Receive_Action
| Actions.ContractStage_Add_Fail_Action
| Actions.ContractStage_AddRange_Finalized_Action
| Actions.ContractStage_Update_Request_Action
| Actions.ContractStage_Update_Receive_Action
| Actions.ContractStage_Update_Fail_Action
| Actions.ContractStage_Delete_Request_Action
| Actions.ContractStage_Delete_Receive_Action
| Actions.ContractStage_Delete_Fail_Action;


export const ContractStageReducer: Reducer<ContractStageState> = (
    state: ContractStageState | undefined, incomingAction: Action): ContractStageState => {

        if (state === undefined) {
            return ContractStageUnloadState as ContractStageState;
        }

        const action = incomingAction as KnownAction;

        switch (action.type) {
            case 'CONTRACTSTAGE_GET_ALL_BY_CONTRACTID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingAll: true,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    error: undefined
                };
            case 'CONTRACTSTAGE_GET_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: true,
                    failOnLoadingAll: false,
                    error: undefined,
                };
            case 'CONTRACTSTAGE_GET_ALL_BY_CONTRACTID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: true,
                    error: action.error
                };
            case 'CONTRACTSTAGE_UPDATE_ALL_BY_CONTRACTID_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingAll: true,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: false,
                    error: undefined
                };
            case 'CONTRACTSTAGE_UPDATE_ALL_BY_CONTRACTID_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: true,
                    FailUpdatingAll: false,
                    error: undefined,
                };
            case 'CONTRACTSTAGE_UPDATE_ALL_BY_CONTRACTID_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: true,
                    error: action.error
                };
            case 'CONTRACTSTAGE_GET_BY_ID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingOne: true,
                    successLoadingOne: false,
                    failOnLoadingOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGE_GET_BY_ID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: true,
                    failOnLoadingOne: false,
                    error: undefined,
                };
            case 'CONTRACTSTAGE_GET_BY_ID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingOne: false,
                    successLoadingOne: false,
                    failOnLoadingOne: true,
                    error: action.error
                };
            case 'CONTRACTSTAGE_ADD_RANGE_FINALIZED_ACTION':
                return {
                    ...state,
                    isAddingFinishedSuccessfully: true,
                };
            case 'CONTRACTSTAGE_ADD_REQUEST_ACTION':
                return {
                    ...state,
                    isAddingNewOne: true,
                    successAddingNewOne: false,
                    failOnAddingNewOne: false,
                    error: undefined    
                };
            case 'CONTRACTSTAGE_ADD_RECEIVE_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: true,
                    failOnAddingNewOne: false,
                    error: undefined, 
                };
            case 'CONTRACTSTAGE_ADD_FAIL_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: false,
                    failOnAddingNewOne: true,
                    error: action.error
                };
            case 'CONTRACTSTAGE_UPDATE_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingOne: true,
                    successUpdatingOne: false,
                    failOnUpdatingOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGE_UPDATE_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: true,
                    failOnUpdatingOne: false,
                    error: undefined,
                };
            case 'CONTRACTSTAGE_UPDATE_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingOne: false,
                    successUpdatingOne: false,
                    failOnUpdatingOne: true,
                    error: action.error
                };
            case 'CONTRACTSTAGE_DELETE_REQUEST_ACTION':
                return {
                    ...state,
                    isDeletingOne: true,
                    successDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'CONTRACTSTAGE_DELETE_RECEIVE_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: true,
                    failOnDeletingOne: false,
                    error: undefined,
                };
            case 'CONTRACTSTAGE_DELETE_FAIL_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: false,
                    failOnDeletingOne: true,
                    error: action.error
                };
            default:
                return state;
        }
}


