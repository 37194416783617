
import React from 'react';
import { connect } from 'react-redux';
import { FontIcon, Icon, Label, PrimaryButton, Separator, Text, Spinner, FontSizes, MessageBarType } from '@fluentui/react';
import { Link, useNavigate } from 'react-router-dom';
import ContractsSelectorComponent from '../../../commons/component/Inputs/ContractSelector/ContractSelectorComponent';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import { MessageComponent } from '../../../commons/component/MessageComponent';

// functions
import * as ComponentFunctions from './PractitionerContract.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Styles
import './PractitionerContract.Styles.css';
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';
import { ConfirmationButtonComponent } from '../../../commons/component/ConfirmationButton/ConfirmationButtonComponent';
import { ContractPractitionerAssociation } from '../../../models/Entities/Contracts/ContractPractitionerAssociation';

export const PractitionerContractComponent = (props: ComponentFunctions.Props) => {

    const navigate = useNavigate();
    const [selectedContractToAssociate, setSelectedContractToAssociate] = useState<Contract | undefined>(undefined);
    const [verificationStatus, setVerificationStatus] = useState<boolean | undefined >(undefined);
    const [PractitionerContractAssociated, setPractitionerContractAssociated] = useState<Practitioner | undefined >(undefined);
    const [ContractAssociated, setContractAssociated] = useState<Contract | undefined >(undefined);
    const [verificationError, setVerificationError] = useState<TypeError | undefined >(undefined);

    //useTranslation()
    const [t] = useTranslation()

    // effects
    // useEffect(() => {
    //         setVerificationStatus(selectedContractToAssociate?. === null  ? true : false); 
    //         if(selectedContractToAssociate?.id !== null) {
    //             setPractitionerContractAssociated(props.Practitioners?.find((practitioner: Practitioner) => practitioner.userId === selectedContractToAssociate?));
    //         }
    // }, [selectedContractToAssociate !== undefined]);

    useEffect(() => {
        props.Practitioner && props.getAllPractitionerContracts(props.PractitionerId);
    }, [props.Practitioner !== undefined]);


    useEffect(() => {
        props.Practitioner?.ContractAssociation && props.GetContractById(props.Practitioner?.ContractAssociation?.contractId);
    }, [props.Practitioner?.ContractAssociation]);

    //events
    const onViewcontractDetailsEvent = (contractId: number) => {
        navigate(`/contracts/${contractId}`);
    };  

    const setPractitionerContract = (contractId: number) => {
        props.addPractitionerContract({
            contractId: contractId,
            practitionerId: props.PractitionerId
        });
    };

    const onDisassociateContract = (association: ContractPractitionerAssociation) => {
        props.deletePractitionerContract(association.id);
    }

    const onVerification = (
        <Spinner 
            label={t('practitionerContract.onVerification.verification')}  
            ariaLive="assertive" 
            labelPosition="right" 
            style={{ marginTop: '20px' }} 
        />
    );

    const onLoading = (
        <Spinner 
            label={t('practitionerContract.onLoading.loading')} 
            ariaLive="assertive" 
            labelPosition="right" 
            style={{ marginTop: '20px' }} 
        />
    );

    const verificationOk = (
        <>
        <Icon 
            iconName="Accept"
            style={{ color: 'green', fontSize: '30px', fontWeight: 'bold' }}
        />
        <Text>{t('practitionerContract.verificationOk.conctractAssociated')}</Text>
        </>
    )

    const verificationNoOk = (
        <>
        <Icon 
            iconName="Cancel"
            style={{ color: 'red', fontSize: '30px', fontWeight: 'bold' }}
        />
        <Text>{t('practitionerContract.noVerification.contractAssociatedInformation')}</Text>
        </>
    )

    const noContract = (
        <>
            <Label>{t('practitionerContract.noContract.contractTittle')} </Label>
            <Text onClick={() => onViewcontractDetailsEvent(8)} >{t('practitionerContract.noContract.anyContractAssociated')}</Text>
            <Separator />
            <ContractsSelectorComponent 
            onSelectedContract={(selectedContrats: Contract[]) => setSelectedContractToAssociate(selectedContrats[0])} 
            ContractsSelected={props.Practitioner?.ContractAssociation?.contractId ? [props.Practitioner?.ContractAssociation?.contractId] : []} 
            MultiselectEnable={false} />
            {
                selectedContractToAssociate && (
                    <div> 
                        <Label><FontIcon className='LabelIcon' iconName='Info'/>{t('practitionerContract.selectedContract.contractInformation')}</Label>
                        <div className='contractInfo'>
                            <Link to={`/contracts/${selectedContractToAssociate?.id}`} target='_tab' rel="noopener noreferrer"><h4>{selectedContractToAssociate?.contractNumber}</h4></Link>
                            <small>{t('practitionerContract.selectedContract.state')}</small>
                            <Label>{ComponentFunctions.GetContractStatusDescripcion(selectedContractToAssociate?.contractStatus)}</Label>
                            {selectedContractToAssociate?.contractDescription &&<p>
                            <Text>{selectedContractToAssociate?.contractDescription}</Text>
                            </p>}
                            <Label style={{display: 'flex'}}><FontIcon iconName='Calendar' style={{ marginTop: 3,  fontSize: FontSizes.medium}}/>
                                &nbsp;{t('practitionerContract.selectedContract.validity')}&nbsp;
                                <Text><strong>
                                    {selectedContractToAssociate?.contractStartDate ? moment(selectedContractToAssociate?.contractStartDate).format('DD/MM/YYYY') : t('practitionerContract.selectedContract.contractStartDate.notDefined')}
                                </strong></Text>
                                &nbsp;a&nbsp;
                                <Text><strong>
                                    {selectedContractToAssociate?.contractEndDate ? moment(selectedContractToAssociate?.contractEndDate).format('DD/MM/YYYY') : t('practitionerContract.selectedContract.contractEndDate.notDefined')}
                                </strong></Text>
                            </Label>
                        </div>
                        {verificationError && <>
                            <Separator/>
                            <MessageComponent message={t('practitionerContract.selectedContract.errorMessage')} type={MessageBarType.error} subMessage={verificationError.message} />
                        </>}
                        {verificationError && <>
                            <Separator/>
                            <ErrorMessageComponent Error={props.error}  />
                        </>}
                        <div className='verificationstatus'>
                            {verificationStatus === undefined && onVerification}
                            {verificationStatus ? verificationOk : verificationStatus !== undefined ? verificationNoOk : null}
                        </div>
                        <div>
                            <PrimaryButton disabled={!verificationStatus} onClick={() => setPractitionerContract(selectedContractToAssociate.id)}><Trans i18nKey={'practitionerContract.selectedContract.associateContract'}/></PrimaryButton>
                        </div>
                    </div>
                )
            }
            
        </>
    );

    const ErrorMessage = (
        <ErrorMessageComponent Error={props.error}  />
    )

    const Contract = (
        <>
        <div> 
            <Label><FontIcon className='LabelIcon' iconName='Info'/>{t('practitionerContract.contract.contractInformation')}</Label>
            <div className='contractInfo'>
                <div className='DisassociateOption'>
                <ConfirmationButtonComponent 
                        ButtonId={'disassociateContract'} 
                        ButtonIconName={'RemoveLink'} 
                        ButtonText={''} 
                        ButtonCssClass={'danger'} 
                        ConfirmationButtonText={t('practitionerContract.contract.disassociateButton')} 
                        ConfirmationButtonCssClass={'danger'} 
                        CancelButtonText={t('practitionerContract.contract.cancelButton')} 
                        CancelButtonCssClass={''} 
                        Title={t('practitionerContract.contract.titleDisassociateContract')} 
                        Description={t(`practitionerContract.contract.associateContract ${props.Contract?.contractNumber} del médico ${props.Practitioner?.fullName}`)} 
                        ElementTextHeader={props.Contract?.contractNumber || ''} 
                        ElementTextInformation={t('practitionerContract.contract.textInformation')} 
                        isOperationExecuting={false} 
                        isOperationExecutedWithError={false} 
                        OperationError={undefined} 
                        onConfirmation={() => props.Practitioner?.ContractAssociation && onDisassociateContract(props.Practitioner?.ContractAssociation)} 
                        onCancel={() => {}}
                    />
                </div>
                <Link to={`/contracts/${props.Contract?.id}`} target='_tab' rel="noopener noreferrer"><Label>{props.Contract?.contractNumber}</Label></Link>
                <small>{t('practitionerContract.selectedContract.state')}</small>
                <Label>{ComponentFunctions.GetContractStatusDescripcion(props.Contract?.contractStatus)}</Label>
                {selectedContractToAssociate?.contractDescription &&<p>
                <Text>{props.Contract?.contractDescription}</Text>
                </p>}
                <Label style={{display: 'flex'}}><FontIcon iconName='Calendar' style={{ marginTop: 3,  fontSize: FontSizes.medium}}/>
                    &nbsp;{t('practitionerContract.selectedContract.validity')}&nbsp;
                    <Text><strong>
                        {props.Contract?.contractStartDate ? moment(props.Contract?.contractStartDate).format('DD/MM/YYYY') : t('practitionerContract.selectedContract.contractStartDate.notDefined')}
                    </strong></Text>
                    &nbsp;a&nbsp;
                    <Text><strong>
                        {props.Contract?.contractEndDate ? moment(props.Contract?.contractEndDate).format('DD/MM/YYYY') : t('practitionerContract.selectedContract.contractEndDate.notDefined')}
                    </strong></Text>
                </Label>
            </div>
        </div>
        </>
    );

    return (
    <div className='Section Internal'>
    {props.failOnLoading && ErrorMessage}
    {props.isLoading && onLoading}
    {props.Loaded && (props.Practitioner?.ContractAssociation?.contractId ? Contract : noContract)}
    </div> 
    );
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(PractitionerContractComponent as any);