import { PriceList } from './../../models/Entities/PriceList/PriceList';
import { PriceDetails } from './../../models/Entities/PriceList/PriceDetail';
import {
  PriceListState,
  PriceListUnloadedState
} from '../states/PriceListState';
import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.PriceLists.PriceListAdd_Request_Action
  | Actions.PriceLists.PriceListAdd_Receive_Action
  | Actions.PriceLists.PriceListAdd_Fail_Action
  | Actions.PriceLists.PriceListUpdate_Request_Action
  | Actions.PriceLists.PriceListUpdate_Receive_Action
  | Actions.PriceLists.PriceListUpdate_Fail_Action
  | Actions.PriceLists.PriceListGetById_Request_Action
  | Actions.PriceLists.PriceListGetById_Receive_Action
  | Actions.PriceLists.PriceListGetById_Fail_Action
  | Actions.PriceLists.PriceListAddPrice_Receive_Action
  | Actions.PriceLists.PriceListUpdatePrice_Receive_Action
  | Actions.PriceLists.PriceListDeletePrice_Receive_Action
  | Actions.PriceLists.PriceListChangePrice_Action
  | Actions.PriceLists.PriceListChangeRemotePrice_Action
  | Actions.PriceLists.PriceListChangeFailPrice_Action
  | Actions.PriceLists.PriceListChangeRandomPrice_Action
  | Actions.PriceLists.ChangeAdditionalPaymentPrice_Action;

export const PriceListDetailsReducer: Reducer<PriceListState> = (
  state: PriceListState | undefined,
  incomingAction: Action
): PriceListState => {
  if (state === undefined) {
    return PriceListUnloadedState as PriceListState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'PRICE_LIST_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'PRICE_LIST_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [...state.list, action.PriceList].sort(
          Functions.DynamicSort('businessName')
        )
      };
    case 'PRICE_LIST_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'PRICE_LIST_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'PRICE_LIST_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (item: PriceList) => item.id !== action.PriceList.id
          ),
          action.PriceList
        ].sort(Functions.DynamicSort('businessName'))
      };
    case 'PRICE_LIST_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.Error
      };
    case 'PRICE_LIST_GET_BY_ID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'PRICE_LIST_GET_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (list: PriceList) => list.id === action.PriceList.id
          ),
          action.PriceList
        ]
      };
    case 'PRICE_LIST_GET_BY_ID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.Error
      };
    case 'PRICE_LIST_ADD_PRICE_RECEIVE_ACTION':
      return {
        ...state,
        list: [
          ...state.list.filter(
            (list: PriceList) => list.id !== action.Price.pricelistEntityId
          ),
          {
            ...state.list.find(
              (list: PriceList) => list.id === action.Price.pricelistEntityId
            ),
            prices: [
              ...(state.list.find(
                (list: PriceList) => list.id === action.Price.pricelistEntityId
              )?.prices || []),
              action.Price
            ]
          } as PriceList
        ]
      };
    case 'PRICE_LIST_UPDATE_PRICE_RECEIVE_ACTION':
      return {
        ...state,
        list: [
          ...state.list.filter(
            (list: PriceList) => list.id !== action.Price.pricelistEntityId
          ),
          {
            ...state.list.find(
              (list: PriceList) => list.id === action.Price.pricelistEntityId
            ),
            prices: [
              ...(state.list
                .find(
                  (list: PriceList) =>
                    list.id === action.Price.pricelistEntityId
                )
                ?.prices.filter(
                  (price: PriceDetails) => price.id !== action.Price.id
                ) || []),
              action.Price
            ]
          } as PriceList
        ]
      };
    case 'PRICE_LIST_DELETE_PRICE_RECEIVE_ACTION':
      return {
        ...state,
        list: [
          ...state.list.filter(
            (list: PriceList) => list.id !== action.PriceListId
          ),
          {
            ...state.list.find(
              (list: PriceList) => list.id === action.PriceListId
            ),
            prices: [
              ...(state.list
                .find((list: PriceList) => list.id === action.PriceListId)
                ?.prices.filter(
                  (price: PriceDetails) => price.id !== action.PriceID
                ) || [])
            ]
          } as PriceList
        ]
      };
    case 'PRICE_LIST_CHANGE_PRICE_ACTION':
      var PriceList = state.list.find(
        (list: PriceList) => list.id === action.Price.pricelistEntityId
      );

      var Price = PriceList?.prices.find(
        (price: PriceDetails) => price.id === action.Price.id
      );

      return {
        ...state,
        list: [
          ...state.list.filter(
            (list: PriceList) => list.id !== action.Price.pricelistEntityId
          ),
          {
            ...PriceList,
            prices: [
              ...(PriceList?.prices.filter(
                (price: PriceDetails) => price.id !== action.Price.id
              ) || []),
              { ...action.Price, isChanged: true }
            ]
          } as PriceList
        ]
      };
    case 'PRICE_LIST_CHANGE_REMOTE_PRICE_ACTION':
      var RemotePriceList = state.list.find(
        (list: PriceList) => list.id === action.Price.pricelistEntityId
      );

      var RemotePrice = RemotePriceList?.prices.find(
        (price: PriceDetails) => price.id === action.Price.id
      );

      return {
        ...state,
        list: [
          ...state.list.filter(
            (list: PriceList) => list.id !== action.Price.pricelistEntityId
          ),
          {
            ...RemotePriceList,
            prices: [
              ...(RemotePriceList?.prices.filter(
                (price: PriceDetails) => price.id !== action.Price.id
              ) || []),
              { ...action.Price, isChanged: true }
            ]
          } as PriceList
        ]
      };
    case 'PRICE_LIST_CHANGE_FAIL_PRICE_ACTION':
      var FailPriceList = state.list.find(
        (list: PriceList) => list.id === action.Price.pricelistEntityId
      );

      var FailPrice = FailPriceList?.prices.find(
        (price: PriceDetails) => price.id === action.Price.id
      );

      return {
        ...state,
        list: [
          ...state.list.filter(
            (list: PriceList) => list.id !== action.Price.pricelistEntityId
          ),
          {
            ...FailPriceList,
            prices: [
              ...(FailPriceList?.prices.filter(
                (price: PriceDetails) => price.id !== action.Price.id
              ) || []),
              { ...action.Price, isChanged: true }
            ]
          } as PriceList
        ]
      };
      
    case 'PRICE_LIST_CHANGE_RANDOM_PRICE_ACTION':
      var RandomPriceList = state.list.find(
        (list: PriceList) => list.id === action.Price.pricelistEntityId
      );

      var RandomPrice = RandomPriceList?.prices.find(
        (price: PriceDetails) => price.id === action.Price.id
      );

      return {
        ...state,
        list: [
          ...state.list.filter(
            (list: PriceList) => list.id !== action.Price.pricelistEntityId
          ),
          {
            ...RandomPriceList,
            prices: [
              ...(RandomPriceList?.prices.filter(
                (price: PriceDetails) => price.id !== action.Price.id
              ) || []),
              { ...action.Price, isChanged: true }
            ]
          } as PriceList
        ]
      
      };

    case 'PRICE_LIST_CHANGE_ADDITIONAL_PAYMENT_PRICE_ACTION':
      var AdditionalPaymentPriceList = state.list.find(
        (list: PriceList) => list.id === action.Price.pricelistEntityId
      );

      var AdditionalPaymentPrice = AdditionalPaymentPriceList?.prices.find(
        (price: PriceDetails) => price.id === action.Price.id
      );

      return {
        ...state,
        list: [
          ...state.list.filter(
            (list: PriceList) => list.id !== action.Price.pricelistEntityId
          ),
          {
            ...AdditionalPaymentPriceList,
            prices: [
              ...(AdditionalPaymentPriceList?.prices.filter(
                (price: PriceDetails) => price.id !== action.Price.id
              ) || []),
              { ...action.Price, isChanged: true }
            ]
          } as PriceList
        ]
      };
    }
  return state;
};
