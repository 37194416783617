import { AvailableMedicalService } from './../../../models/Entities/MedicalServices/AvailableMedicalService';
import { MedicalInsurance } from './../../../models/Entities/MedicalInsurances/MedicalInsurance';
import { ProfessionalAvailability } from './../../../models/Entities/ProfessionalAvailability/ProfessionalAvailability';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';
import { IIconProps } from '@fluentui/react';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';

// Store
import * as ProfessionalAvailabilityStore from '../../../redux/middleware/ProfessionalAvailabilityMiddleware';
import * as PractitionerStore from '../../../redux/middleware/PractitionerMiddleware';
import * as MedicalInsuranceStore from '../../../redux/middleware/MedicalInsuranceMiddleware';
import * as MedicalServiceStore from '../../../redux/middleware/MedicalServicesMiddleware';

interface OwnProps {
  PractitionerId: string;
}

interface StateProps {
  isGettingAll?: boolean;
  gettingAllSuccess?: boolean;
  failOnGettingAll?: boolean;
  isDeleting?: boolean;
  deletingSuccess?: boolean;
  failOnDeleting?: boolean;
  error?: ErrorType;
  ProfessionalAvailabilityList?: ProfessionalAvailability[];
  Practitioner: Practitioner;
  MedicalInsurances: MedicalInsurance[];
  MedicalServices: AvailableMedicalService[];
}

interface DispatchProps {
  GetAllProfessionalAvailability: typeof ProfessionalAvailabilityStore.ActionCreators.GetAllProfessionalAvailability;
  DeleteProfessionalAvailability: typeof ProfessionalAvailabilityStore.ActionCreators.DeleteProfessionalAvailability;
  GetAllPractitioners: typeof PractitionerStore.actionCreators.GetAllPractitioners;
  GetAllMedicalInsurances: typeof MedicalInsuranceStore.actionCreators.GetAllMedicalInsurances;
  GetAllMedicalServices: typeof MedicalServiceStore.actionCreators.GetAllMedicalServices;
}

interface CustomEvent {}

export type Props = CustomEvent & StateProps & DispatchProps & OwnProps;

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    isGettingAll: state.ProfessionalAvailability?.isLoadingAll,
    gettingAllSuccess: state.ProfessionalAvailability?.successLoadingAll,
    failOnGettingAll: state.ProfessionalAvailability?.failOnLoadingAll,
    isDeleting: state.ProfessionalAvailability?.isDeletingOne,
    deletingSuccess: state.ProfessionalAvailability?.successDeletingOne,
    failOnDeleting: state.ProfessionalAvailability?.failOnDeletingOne,
    error: state.ProfessionalAvailability?.error,
    ProfessionalAvailabilityList: state.ProfessionalAvailability?.list,
    Practitioner: state.Practitioner?.list?.find(
      (p) => p.userId === ownProps.PractitionerId
    ),
    MedicalInsurances: state.MedicalInsurances?.list,
    MedicalServices: state.MedicalServices?.list
  };
};

export function GetMedicalInsuranceDescription(
  medicalInsuranceId: number,
  medicalInsurancePlanId: number | undefined,
  medicalInsurances: MedicalInsurance[]
): string {
  let PlanDescription = '';

  const medicalInsurance = medicalInsurances.find(
    (mi) => mi.id === medicalInsuranceId
  );

  if (medicalInsurance) {
    if (medicalInsurancePlanId) {
      const plan = medicalInsurance.plans.find(
        (p) => p.id === medicalInsurancePlanId
      );
      if (plan) {
        PlanDescription = plan.code;
      } else {
        PlanDescription = 'Plan no encontrado';
      }
    } else {
      PlanDescription = 'Todos los planes';
    }
  }

  return medicalInsurance
    ? medicalInsurance.businessName + ' ( ' + PlanDescription + ' )'
    : 'Cobertura no encontrada';
}

export function GetMedicalServiceDescription(
  medicalServiceId: number,
  medicalServices: AvailableMedicalService[]
): string {
  const medicalService = medicalServices.find(
    (ms) => ms.id === medicalServiceId
  );
  return medicalService
    ? medicalService.serviceNameReference
    : 'Servicio no encontrado';
}

export const mapDispatchToProps = {
  ...ProfessionalAvailabilityStore.ActionCreators,
  ...PractitionerStore.actionCreators,
  ...MedicalInsuranceStore.actionCreators,
  ...MedicalServiceStore.actionCreators
};

export type Validity = {
  status: string;
  message: string;
  endValidity: Date | undefined;
};


export function GetFilteredAvailability(
  showExpired: boolean,
  availabilityList?: ProfessionalAvailability[],
): ProfessionalAvailability[] {
  if (!availabilityList) return [];
  const today = new Date();
  return availabilityList.filter((availability) => {
    const endValidity: Date | undefined = availability.validityEnd
      ? new Date(availability.validityEnd)
      : undefined;
    const startValidity: Date | undefined = availability.validityStart
      ? new Date(availability.validityStart)
      : undefined;
    if (!endValidity && !startValidity) {
      return true;
    }
    if (endValidity && today > endValidity) {
      return showExpired;
    } else if (startValidity && today < startValidity) {
      return true;
    } else {
      return true;
    }
  });
};


export function getStatus(availability: ProfessionalAvailability): Validity {
  const today = new Date();
  const endValidity: Date | undefined = availability.validityEnd
    ? new Date(availability.validityEnd)
    : undefined;
  const startValidity: Date | undefined = availability.validityStart
    ? new Date(availability.validityStart)
    : undefined;
  if (!endValidity && !startValidity) {
    return {
      status: 'NoValidity',
      message: 'No Validity',
      endValidity: undefined
    };
  }
  if (endValidity && today > endValidity) {
    return {
      status: 'Expired',
      message: 'Expired',
      endValidity: endValidity
    };
  } else if (startValidity && today < startValidity) {
    return {
      status: 'Pending',
      message: 'Pending',
      endValidity: endValidity
    };
  } else {
    return {
      status: 'Active',
      message: 'Active',
      endValidity: endValidity
    };
  }
}

export function GetDayDescription(Day: number): string {
  switch (Day) {
    case 0:
      return 'Domingo';
    case 1:
      return 'Lunes';
    case 2:
      return 'Martes';
    case 3:
      return 'Miércoles';
    case 4:
      return 'Jueves';
    case 5:
      return 'Viernes';
    case 6:
      return 'Sábado';
    default:
      return 'Desconodido';
  }
}

// Icons
export const DetailsIcon: IIconProps = { iconName: 'StackIndicator' };
export const InfoIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const UnlockIcon: IIconProps = { iconName: 'UnlockSolid' };
export const LockIcon: IIconProps = { iconName: 'LockSolid' };
export const EditIcon: IIconProps = { iconName: 'Edit' };
export const DeleteIcon: IIconProps = { iconName: 'Delete' };
