import { IBasePickerSuggestionsProps, ITag } from '@fluentui/react';

//store 
import * as MedicalServicesStore from '../../../../redux/middleware/MedicalServicesMiddleware';

// models
import { ErrorType } from '../../../../models/HttpError';
import { AvailableMedicalService } from '../../../../models/Entities/MedicalServices/AvailableMedicalService';
import { AppState } from '../../../../redux/reducers';


interface IProps {
  onSelectedServices: (services: AvailableMedicalService[]) => void;
  MedicalServicesSelectedIds: number[] | undefined;
  MultiselectEnable: boolean;
  noteText?: string;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  MedicalServices: AvailableMedicalService[];
  MedicalServicesSelected: AvailableMedicalService[];
}

interface DispatchProps {
  GetAllMedicalInsurances: typeof MedicalServicesStore.actionCreators.GetAllMedicalServices;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.ContractConcept?.isLoadingAll,
  isUpdating: state.ContractConcept?.isLoadingAll,
  isUpdatedSuccessfully: state.ContractConcept?.successLoadingAll,
  isLoadingSuccessfully: state.ContractConcept?.successLoadingAll,
  failOnUpdating: state.ContractConcept?.failOnLoadingAll,
  failOnLoading: state.ContractConcept?.failOnLoadingAll,
  isDeleting: state.ContractConcept?.isDeletingOne,
  isDeletedSuccessfully: state.ContractConcept?.successDeletingOne,
  failOnDeleting: state.ContractConcept?.failOnDeletingOne,
  error: state.ContractConcept?.error,
  MedicalServices: state.MedicalServices?.list,
  MedicalServicesSelected: ownProps.MedicalServicesSelectedIds ? state.MedicalServices?.list?.filter((service: AvailableMedicalService) => ownProps.MedicalServicesSelectedIds?.includes(service.id)) as AvailableMedicalService[] : []
});

export const mapDispatchToProps = (dispatch: any, ownProsp: IProps) =>{

  dispatch(MedicalServicesStore.actionCreators.GetAllMedicalServices(
    1,
    10000,
    false
  ));

 
  return {
    ...MedicalServicesStore.actionCreators
  }
  
};


export const GetMedicalServicesOptions = (services: AvailableMedicalService[]): ITag[] => {
  
  return services?.map((item: AvailableMedicalService) => {
    return {
      key: item.id,
      name: item.serviceNameReference
    };
  }) as ITag[];
};

// export const filterSuggestedMedicalServices = async (
//   filterText: string
// ): Promise<ITag[]> => {
//   return filterText && filterText.length > 4
//     ? MedicalService.GetMedicalServices(1, 100, filterText).then(
//         (services: AvailableMedicalService[]) => {
//           return services.map((item: AvailableMedicalService) => {
//             return { key: item.id, name: item.serviceNameReference };
//           });
//         }
//       )
//     : [];
// };

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const GetMedicalServiceById = (
  medicalServiceId: number,
  medicalServices: AvailableMedicalService[]
): AvailableMedicalService => {

  const medicalService: AvailableMedicalService = medicalServices.find(
    (item: AvailableMedicalService) => item.id === medicalServiceId
  ) as AvailableMedicalService; 
  
  return medicalService;
};

export function GetSelectedMedicalService(
  selectedMedicalServices: AvailableMedicalService[]
): ITag[] {
  const currentMedicalServices: ITag[] = [];

  selectedMedicalServices.forEach((service) => {
    currentMedicalServices.push({
      key: service.id,
      name: service.serviceNameReference
    });
  });

  return currentMedicalServices;
}

export const getTextFromItem = (item: ITag) => item.name;

export const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'servicios sugeridos',
  noResultsFoundText: 'No se encontro servicio'
};
