import {
  DropdownMenuItemType,
  IBasePickerSuggestionsProps,
  IDropdownOption,
  ITag
} from '@fluentui/react';
import { AppState } from '../../../../redux/reducers';

// models
import { ErrorType } from '../../../../models/HttpError';
import { MedicalInsurancePlan } from './../../../../models/Entities/MedicalInsurances/MedicalInsurancePlan';
import { MedicalInsurance } from '../../../../models/Entities/MedicalInsurances/MedicalInsurance';
import { PractitionerMedicalInsuranceAssociation } from '../../../../models/Entities/PractitionerAssociations/MedicalInsurances/PractitionerMedicalInsuranceAssociation';

//store 
import * as MedicalInsuranceStore from '../../../../redux/middleware/MedicalInsuranceMiddleware';
import * as MedicalInsurancePlanStore from '../../../../redux/middleware/MedicalInsurancePlansMiddleware';
import * as PractitionerAssociationStore from '../../../../redux/middleware/PractitionerMedicalInsuranceAssociationMiddleware';
import { IdleTransaction } from '@sentry/tracing';

interface IProps {
  onChangeSelection: (
    selection: InternalMedicalInsuranceSelected[]
  ) => void;
  MedicalInsuranceMultiselectEnable: boolean;
  MedicalPlansMultiselectEnable: boolean;
  SelectPlanEnable: boolean;
  MedicalInsurancesSelected:
    | InternalMedicalInsuranceSelected[]
    | undefined;
  MedicalInsuranceNoteText?: string;
  MedicalPlanNoteText?: string;
  PractitionerId: string;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  MedicalInsurances: MedicalInsurance[];
  MedicalInsuranceOptions: ITag[];
  PractitionerMedicalInsurances: PractitionerMedicalInsuranceAssociation[];
  SelectedMedicalInsurances: MedicalInsurance[];
  SelectedMedicalInaurancesOptions: ITag[];
  SelectedMedicalInsurancePlansOtions: IDropdownOption[];

}

interface DispatchProps {
  GetAllMedicalInsurances: typeof MedicalInsuranceStore.actionCreators.GetAllMedicalInsurances;
  GetMedicalPlansByMedicalInsuranceId: typeof MedicalInsurancePlanStore.actionCreators.GetMedicalPlansByMedicalInsuranceId;
  GetMedicalInsuranceByPractitionerId: typeof PractitionerAssociationStore.actionCreators.GetMedicalInsuranceByPractitionerId;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.ContractConcept?.isLoadingAll,
  isUpdating: state.ContractConcept?.isLoadingAll,
  isUpdatedSuccessfully: state.ContractConcept?.successLoadingAll,
  isLoadingSuccessfully: state.ContractConcept?.successLoadingAll,
  failOnUpdating: state.ContractConcept?.failOnLoadingAll,
  failOnLoading: state.ContractConcept?.failOnLoadingAll,
  isDeleting: state.ContractConcept?.isDeletingOne,
  isDeletedSuccessfully: state.ContractConcept?.successDeletingOne,
  failOnDeleting: state.ContractConcept?.failOnDeletingOne,
  error: state.ContractConcept?.error,
  MedicalInsurances: state.MedicalInsurances?.list,
  MedicalInsuranceOptions: GetMedicalInsuranceOptions(state.MedicalInsurances?.list as MedicalInsurance[]),
  SelectedMedicalInsurances: ownProps.MedicalInsurancesSelected ? 
  ownProps.MedicalInsurancesSelected?.map((item) => item.MedicalInsuranceId)
  .filter((value, index, self) => self.indexOf(value) === index)?.map((id: number) => {
    return GetMedicalInsuranceById(id, state.MedicalInsurances?.list);
  }) as MedicalInsurance[] : [],
  SelectedMedicalInaurancesOptions: ownProps.MedicalInsurancesSelected ? 
    // get unique medical insurances id from selected medical insurances
    ownProps.MedicalInsurancesSelected?.map((item) => item.MedicalInsuranceId)
    .filter((value, index, self) => self.indexOf(value) === index)?.map((id: number) => {
      return {
        key: id,
        name: GetMedicalInsuranceById(id, state.MedicalInsurances?.list as MedicalInsurance[]).businessName,
        medicalInsuranceID: id
      };
    }) as ITag[] : [],
  SelectedMedicalInsurancePlansOtions: ownProps.MedicalInsurancesSelected ?
    ownProps.MedicalInsurancesSelected?.map((item: InternalMedicalInsuranceSelected) => {
      return {
        key: 'P' + item.MedicalInsurancePlanID,
        text: GetMedicalInsuranceById(item.MedicalInsuranceId, state.MedicalInsurances?.list as MedicalInsurance[]).plans.find((plan) => plan.id === item.MedicalInsurancePlanID)?.title
      };
    }) as IDropdownOption[] : []

  
});

export const mapDispatchToProps = ({
  ...MedicalInsuranceStore.actionCreators,
  ...MedicalInsurancePlanStore.actionCreators,
  ...PractitionerAssociationStore.actionCreators
});


export interface InternalMedicalInsuranceSelected {
  MedicalInsuranceId: number;
  MedicalInsurancePlanID?: number;
}

export const GetMedicalInsuranceOptions = (
  MedicalInsurnaces: MedicalInsurance[],
): ITag[] => {

  const MedicalInsuranceOptions = MedicalInsurnaces?.map(
    (item: MedicalInsurance) => {
      return {
        key: item.id,
        name: item.businessName,
        medicalInsuranceID: item.id
      };
    }
  ) as ITag[];

  return MedicalInsuranceOptions;
};

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const GetSelectedMedicalInsurance = (
  MedicalInsurancesId: InternalMedicalInsuranceSelected[],
  MedicalInsurances: MedicalInsurance[]
): MedicalInsurance[] => {

  const MedicalInsurance = MedicalInsurances?.filter(
    (medicalInsurance: MedicalInsurance) =>
      MedicalInsurancesId.findIndex(
        (medicalInsuranceId: InternalMedicalInsuranceSelected) =>
          medicalInsuranceId.MedicalInsuranceId ===
          medicalInsurance.id
      ) > -1
  ) as MedicalInsurance[];

  return MedicalInsurance;
};

export const GetMedicalInsuranceById = (
  medicalInsuranceId: number,
  MedicalInsurances?: MedicalInsurance[]
): MedicalInsurance => {

  const MedicalInsurance = MedicalInsurances?.find(
    (medicalInsurance: MedicalInsurance) => medicalInsurance.id === medicalInsuranceId
  ) as MedicalInsurance;

  return MedicalInsurance;
};

export const getTextFromItem = (item: ITag) => item.name;

export const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'cobertura sugerida',
  noResultsFoundText: 'No se encontro cobertura'
};

export function GetSelectedMedicalInsurancePlans(
  selectedMedicalInsurances: MedicalInsurance[]
): IDropdownOption[] {
  var ResponseOption: IDropdownOption[] = [];

  selectedMedicalInsurances.forEach((medicalInsurance: MedicalInsurance) => {
    ResponseOption = [
      ...ResponseOption,
      {
        key: 'M' + medicalInsurance.id,
        text: medicalInsurance.businessName,
        itemType: DropdownMenuItemType.Header
      }
    ];

    ResponseOption = [
      ...ResponseOption,
      ...medicalInsurance.plans?.map(
        (medicalInsurancePlan: MedicalInsurancePlan) => {
          return {
            key: 'P' + medicalInsurancePlan.id,
            text: medicalInsurancePlan.title
          };
        }
      )
    ];
  });
  return ResponseOption;
}
