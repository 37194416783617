
import { AppThunkAction } from '../reducers';

// services
import * as ProceduresServices from '../../services/Procedures.Services';

// Actions
import * as Actions from '../actions';

// Models
import { Procedure } from '../../models/Entities/Procedures/Procedure';
import { ErrorType } from '../../models/HttpError';

type KnownAction =
  | Actions.Procedures.GetAllPrceduresByCriteria_Request_Action
  | Actions.Procedures.GetAllPrceduresByCriteria_Receive_Action
  | Actions.Procedures.GetAllPrceduresByCriteria_Failure_Action;

export const actionCreators = {
    GetAllProceduresByCriteria:
        (criteria: string): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
    
        var state = getState();
        var token: string;
    
        if (state.oidc?.user?.access_token === undefined) {
            dispatch({
            type: 'GET_ALL_PROCEDURES_BY_CRITERIA_FAILURE_ACTION',
            error: {
                ErrorCode: 401,
                ErrorMessage: 'Not authorized',
                Errors: []
            }
            });
            return;
        } else {
            token = state.oidc.user.access_token;
        }
    
        dispatch({
            type: 'GET_ALL_PROCEDURES_BY_CRITERIA_REQUEST_ACTION',
            criteria: criteria
        });
    
        ProceduresServices.GetProcedures(criteria, token)
            .then((procedures: Procedure[]) => {
            dispatch({
                type: 'GET_ALL_PROCEDURES_BY_CRITERIA_RECEIVE_ACTION',
                procedures: procedures
            });
            })
            .catch((error: ErrorType) =>
            dispatch({
                type: 'GET_ALL_PROCEDURES_BY_CRITERIA_FAILURE_ACTION',
                error: error
            })
            );
        }
    };