import { Label, MessageBar, MessageBarType, Text } from '@fluentui/react';

export interface IProps {
  message: string;
  subMessage?: string;
  itemList?: string[];
  type: MessageBarType;
}

export const MessageComponent = (props: IProps) => {
  return (
    <MessageBar messageBarType={props.type} isMultiline={true}>
      <Label style={{ padding: '0 5px 5px 0' }}>{props.message}</Label>
      {props.subMessage && <Text>{props.subMessage}</Text>}
      {props.itemList && (
        <ul>
          {props.itemList.map ? (
            props.itemList.map((item: string) => (
              <li>
                <Text>{item}</Text>
              </li>
            ))
          ) : (
            <li>
              <Text>{JSON.stringify(props.itemList)}</Text>
            </li>
          )}
        </ul>
      )}
    </MessageBar>
  );
};
