import { CommonState } from "../common/state/commonState";

// models
import { ContractCommissionAgent } from "../../models/Entities/Contracts/ContractCommissionAgent";

export interface ContractCommissionAgentState extends CommonState<ContractCommissionAgent> {}

export const ContractCommissionAgentUnloadState: ContractCommissionAgentState = {
    list: [],
    error: undefined,
    isLoadingAll: false,
    successLoadingAll: undefined,
    failOnLoadingAll: false,
    isLoadingOne: false,
    successLoadingOne: undefined,
    failOnLoadingOne: false,
    isAddingNewOne: false,
    successAddingNewOne: undefined,
    failOnAddingNewOne: false,
    isUpdatingOne: false,
    successUpdatingOne: undefined,
    failOnUpdatingOne: false,
    isDeletingOne: false,
    successDeletingOne: undefined,
    failOnDeletingOne: false
};
