import { NewAgreementService } from './../../models/Entities/AgreementServices/NewAgreementService';
import { AgreementService } from './../../models/Entities/AgreementServices/AgreementServices';
import { AppThunkAction } from '../reducers';
import { ErrorType } from '../../models/HttpError';

// services
import * as Services from '../../services/AgreementServices.Services';

// actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.AgreementServices.AgreementServices_GetAll_Request_Action
  | Actions.AgreementServices.AgreementServices_GetAll_Receive_Action
  | Actions.AgreementServices.AgreementServices_GetAll_Fail_Action
  | Actions.AgreementServices.AgreementServices_GetById_Request_Action
  | Actions.AgreementServices.AgreementServices_GetById_Receive_Action
  | Actions.AgreementServices.AgreementServices_GetById_Fail_Action
  | Actions.AgreementServices.AgreementServices_Add_Request_Action
  | Actions.AgreementServices.AgreementServices_Add_Receive_Action
  | Actions.AgreementServices.AgreementServices_Add_Fail_Action
  | Actions.AgreementServices.AgreementServices_Update_Request_Action
  | Actions.AgreementServices.AgreementServices_Update_Receive_Action
  | Actions.AgreementServices.AgreementServices_Update_Fail_Action
  | Actions.AgreementServices.AgreementServices_changeStatus_Request_Action
  | Actions.AgreementServices.AgreementServices_changeStatus_Receive_Action
  | Actions.AgreementServices.AgreementServices_changeStatus_Fail_Action
  | Actions.AgreementServices.AgreementServices_Delete_Request_Action
  | Actions.AgreementServices.AgreementServices_Delete_Receive_Action
  | Actions.AgreementServices.AgreementServices_Delete_Fail_Action;

export const actionCreators = {
  GetAllAgreementServiceByAgreementId:
    (
      agreementId: number,
      pageNumber: number,
      pageSize: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'AGREEMENT_SERVICES_GETALL_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'AGREEMENT_SERVICES_GETALL_REQUEST_ACTION',
        agreementId: agreementId
      });

      Services.GetAllAgreementServices(
        agreementId,
        pageNumber,
        pageSize,
        undefined,
        token
      )
        .then((services: AgreementService[]) => {
          dispatch({
            type: 'AGREEMENT_SERVICES_GETALL_RECEIVE_ACTION',
            agreementId: agreementId,
            agreementServices: services
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AGREEMENT_SERVICES_GETALL_FAIL_ACTION',
            error: error
          })
        );
    },
  GetAgreementServiceById:
    (agreementServiceId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'AGREEMENT_SERVICES_GETBYID_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'AGREEMENT_SERVICES_GETBYID_REQUEST_ACTION',
        agreementServiceId: agreementServiceId
      });

      Services.GetAgreementServicesById(agreementServiceId, token)
        .then((agreementService: AgreementService) => {
          dispatch({
            type: 'AGREEMENT_SERVICES_GETBYID_RECEIVE_ACTION',
            agreementService: agreementService
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AGREEMENT_SERVICES_GETBYID_FAIL_ACTION',
            error: error
          })
        );
    },
  AddAgreementService:
    (agreementService: NewAgreementService): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'AGREEMENT_SERVICES_ADD_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'AGREEMENT_SERVICES_ADD_REQUEST_ACTION',
        newAgreementService: agreementService
      });

      Services.AddMedicalServicesToAgreement(agreementService, token)
        .then((newAgreementService: AgreementService) => {
          dispatch({
            type: 'AGREEMENT_SERVICES_ADD_RECEIVE_ACTION',
            agreementService: newAgreementService
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AGREEMENT_SERVICES_ADD_FAIL_ACTION',
            error: error
          })
        );
    },
  UpdateAgreementService:
    (updatedAgreementService: AgreementService): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'AGREEMENT_SERVICES_UPDATE_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'AGREEMENT_SERVICES_UPDATE_REQUEST_ACTION',
        agreementService: updatedAgreementService
      });

      Services.UpdateMedicalServiceOnAgreement(updatedAgreementService, token)
        .then((agreementService: AgreementService) => {
          dispatch({
            type: 'AGREEMENT_SERVICES_UPDATE_RECEIVE_ACTION',
            agreementService: agreementService
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AGREEMENT_SERVICES_UPDATE_FAIL_ACTION',
            error: error
          })
        );
    },
  DeleteAgreementService:
    (agreementServiceId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'AGREEMENT_SERVICES_DELETE_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'AGREEMENT_SERVICES_DELETE_REQUEST_ACTION',
        agreementServiceId: agreementServiceId
      });

      Services.DeleteAgreementService(agreementServiceId, token)
        .then((serviceId: number) => {
          dispatch({
            type: 'AGREEMENT_SERVICES_DELETE_RECEIVE_ACTION',
            agreementServiceId: serviceId
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AGREEMENT_SERVICES_DELETE_FAIL_ACTION',
            error: error
          })
        );
    },
  ChangeStatusAgreementService:
    (
      AgreementServiceId: number,
      newStatus: boolean
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {


      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'AGREEMENT_SERVICES_CHANGESTATUS_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'AGREEMENT_SERVICES_CHANGESTATUS_REQUEST_ACTION',
        agreementServiceId: AgreementServiceId,
        status: newStatus
      });

      Services.AgreementServiceChangeStatus(AgreementServiceId, newStatus, token)
        .then((agreementService: AgreementService) => {
          dispatch({
            type: 'AGREEMENT_SERVICES_CHANGESTATUS_RECEIVE_ACTION',
            agreementService: agreementService
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'AGREEMENT_SERVICES_CHANGESTATUS_FAIL_ACTION',
            error: error
          })
        );
    }
};
