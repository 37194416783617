import { ProviderPriceList } from '../models/Entities/ProviderAssociations/PriceLists/ProviderPriceList';

// Functions
import * as Functions from '../functions/common';
import { IResult } from '../models/HttpResult';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'providers/';
const BaseURLAssociations = process.env.REACT_APP_BILLER_API_GATEWAY + 'providers/associations/';
const BaseURLAggregator = process.env.REACT_APP_BILLER_API_GATEWAY + 'providers/aggregator/';

export const GetAllProviderPriceLists = async (ProviderId: number, token: string): Promise<ProviderPriceList[]> => {
  // Build the request URL
  let RequestURL: string = BaseURL + 'aggregator/' + ProviderId + '/pricelists';

  try {
    // Call the API and return the response
    const Response: ProviderPriceList[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProviderPriceList[]>>;
        }
      })
      .then((data: IResult<ProviderPriceList[]>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    // Sentry.captureException(error);
    throw error;
  }
};

export const GetProviderPriceListById = async (ProviderPriceListId: number, token: string): Promise<ProviderPriceList> => {
  let RequestURL: string = BaseURL + 'PriceList/' + ProviderPriceListId + '/details';

  try {
    const Response: ProviderPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProviderPriceList>>;
        }
      })
      .then((data: IResult<ProviderPriceList>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    // Sentry.captureException(error);
    throw error;
  }
};

export const GetProviderPriceListByPriceListId = async (ProviderId: number, PriceListId: number, token: string): Promise<ProviderPriceList> => {
  let RequestURL: string = BaseURL + ProviderId + 'PriceList/' + PriceListId + '/details';

  try {
    const Response: ProviderPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProviderPriceList>>;
        }
      })
      .then((data: IResult<ProviderPriceList>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    // Sentry.captureException(error);
    throw error;
  }
};

export const SetPriceListStartValidityDate = async (ProviderPriceListId: number, StartDate: Date, token: string): Promise<ProviderPriceList> => {
  const RequestURL: string = BaseURLAssociations + 'PriceList/' + ProviderPriceListId + '/startvalidity';

  try {
    const Response: ProviderPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(StartDate)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProviderPriceList>>;
        }
      })
      .then((data: IResult<ProviderPriceList>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    // Sentry.captureException(error);
    throw error;
  }
};

export const SetPriceListEndValidityDate = async (ProviderPriceListId: number, EndDate: Date, token: string): Promise<ProviderPriceList> => {
  const RequestURL: string = BaseURLAssociations + 'PriceList/' + ProviderPriceListId + '/endvalidity';

  try {
    const Response: ProviderPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(EndDate)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProviderPriceList>>;
        }
      })
      .then((data: IResult<ProviderPriceList>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    // Sentry.captureException(error);
    throw error;
  }
};

export const AddPriceListToProvider = async (ProviderId: number, comments: string, listName: string, token: string): Promise<ProviderPriceList> => {
  const RequestURL: string = BaseURLAggregator + 'pricelist';

  try {
    const Response: ProviderPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify({
        providerId: ProviderId,
        comments: comments,
        listName: listName
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProviderPriceList>>;
        }
      })
      .then((data: IResult<ProviderPriceList>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    // Sentry.captureException(error);
    throw error;
  }
};

export const ClonePriceListToProvider = async (ProviderPriceListId: number, token: string): Promise<ProviderPriceList> => {
  const RequestURL: string = BaseURLAggregator + 'pricelist/' + ProviderPriceListId + '/clone';

  try {
    const Response: ProviderPriceList = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProviderPriceList>>;
        }
      })
      .then((data: IResult<ProviderPriceList>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    // Sentry.captureException(error);
    throw error;
  }
};

export const DeletePriceListFromProvider = async (ProviderPriceListId: number, token: string): Promise<null> => {
  const RequestURL: string = BaseURLAssociations + 'PriceList/' + ProviderPriceListId;

  try {
    const Response: null = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<null>>;
        }
      })
      .then((data: IResult<null>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    // Sentry.captureException(error);
    throw error;
  }
};

export const DeletePriceListFromProviderByPriceListId = async (ProviderId: number, PriceListId: number, token: string): Promise<null> => {
  const RequestURL: string = BaseURLAssociations + ProviderId + '/PriceList/' + PriceListId;

  try {
    const Response: null = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<null>>;
        }
      })
      .then((data: IResult<null>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw Functions.ProcessError(error);
      });

    return Response;
  } catch (error: any) {
    // Sentry.captureException(error);
    throw error;
  }
};
