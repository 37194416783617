import React from 'react';
import ReactDOM from 'react-dom';
import Loader, { Circles, Oval } from 'react-loader-spinner';
import { Modal as BootstrapModal } from 'react-bootstrap';
//import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export const Spinner = () => {
  console.log('load spinner');
  return (
    <div
      className="loader center"
      style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}
    >
      <Oval color="#247dbd" secondaryColor="#ffffff" height={80} width={80} />
    </div>
  );
};

// export const ModalSpinner = ({ isShowing, hide }) => {
//   return (
//     isShowing &&
//     ReactDOM.createPortal(
//       <React.Fragment>
//         <BootstrapModal show={true} onHide={hide}>
//           <BootstrapModal.Header closeButton></BootstrapModal.Header>
//           <BootstrapModal.Body>
//             <Spinner />
//           </BootstrapModal.Body>
//         </BootstrapModal>
//       </React.Fragment>,
//       document.body
//     )
//   );
//};
