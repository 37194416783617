import { ProviderAssociationsMedicalService } from '../../models/Entities/ProviderAssociations/MedicalServices/ProviderAssociationMedicalService';
import { CommonState } from '../common/state/commonState';

export interface ProviderMedicalServiceState
  extends CommonState<ProviderAssociationsMedicalService> {}

export const ProviderMedicalServiceUnloadState: any = {
  // loading all
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  FailGettingAll: false,

  // loading one
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  FailGettingOne: false,

  // saving
  isSaving: false,
  isSavingSuccess: undefined,
  FailSaving: false,

  // adding
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,

  isFiltered: false,
  filterCriteria: undefined,

  list: [],
  Selected: undefined,
  Error: undefined
};
