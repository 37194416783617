import * as React from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { NormalPeoplePicker } from '@fluentui/react/lib/Pickers';

// ownFunctions
import * as Functions from './ProviderSelectorFunctions';
import { Provider } from '../../../../models/Entities/Provider/Provider';
import { Person } from '../../../../models/Entities/Person/Person';

export interface IProviderSelectorProps {
  selectionLimit: number;
  onChangeSelection?: (selectedPractitioners: Provider | Provider[]) => void;
  ProviderSelected: number[];
}

export const ProviderSelectorComponent = (props: IProviderSelectorProps) => {
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<
    IPersonaProps[]
  >([]);
  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>([]);

  const picker = React.useRef(null);

  const onChangeSelection = (items?: IPersonaProps[] | undefined) => {
    if (props.onChangeSelection) {
      items?.map((provider: IPersonaProps) => {
        return {
          person: {
            businessName: provider.text,
            id: provider.key,
          } as Person,
          id: provider.key,
        } as Provider;
      });
    }
  };

  const onFilterChanged = (
    filterText: string,
    currentProvider?: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    // if (filterText) {
    //   let filteredSponsor: Promise<IPersonaProps[]> =
    //     filterPersonasByText(filterText);
  
    //   return filteredSponsor.then((providers: IPersonaProps[]) => {
    //     let ProviderList = removeDuplicates(providers, currentProvider || []);
    //     ProviderList = limitResults
    //       ? ProviderList.slice(0, limitResults)
    //       : ProviderList;
    //     return ProviderList || [];
    //   });
    // } else {
    //   return [];
    // }

    return [];
  };
  
  const filterPersonasByText = (
    filterText: string
  ): IPersonaProps[] => {
    // return ProviderService.GetProviders(1, 1000, '')
    //   .then((data: Provider[]) => {
    //     return data
    //       .map((item: Provider) => {
    //         return {
    //           key: item.id,
    //           imageInitials: CommonFunctions.getNameInitials(item.fullName),
    //           text: item.fullName,
    //           secondaryText: 'Proveedor'
    //         } as IPersonaProps;
    //       })
    //       .filter((item: IPersonaProps) =>
    //         doesTextStartWith(item.text as string, filterText)
    //       );
    //   })
    //   .catch((error: any) => {
    //     return [] as IPersonaProps[];
    //   });

    return [] as IPersonaProps[];

  };
  

  return (
    <div>
      <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={Functions.getTextFromItem}
        pickerSuggestionsProps={Functions.suggestionProps}
        className={'ms-PeoplePicker'}
        key={'normal'}
        onValidateInput={Functions.validateInput}
        selectionAriaLabel={'Selected contacts'}
        removeButtonAriaLabel={'Remove'}
        componentRef={picker}
        itemLimit={props.selectionLimit}
        //onInputChange={onInputChange}
        onChange={onChangeSelection}
      />
    </div>
  );
};
