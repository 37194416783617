import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

// States
import { CashFlowAccountMovementState, CashFlowAccountMovementUnloadState } from '../states/CashFlow.AccountMovement.State';

type KnownAction =
| Actions.CashFlowAccountMovements.CashFlowAccountMovementGetAllByAccountIdRequestAction
| Actions.CashFlowAccountMovements.CashFlowAccountMovementGetAllByAccountIdSuccessAction
| Actions.CashFlowAccountMovements.CashFlowAccountMovementGetAllByAccountIdFailureAction
| Actions.CashFlowAccountMovements.CashFlowAccountMovementGetByIdRequestAction
| Actions.CashFlowAccountMovements.CashFlowAccountMovementGetByIdSuccessAction
| Actions.CashFlowAccountMovements.CashFlowAccountMovementGetByIdFailureAction
| Actions.CashFlowAccountMovements.CashFlowAccountMovementCreateRequestAction
| Actions.CashFlowAccountMovements.CashFlowAccountMovementCreateSuccessAction
| Actions.CashFlowAccountMovements.CashFlowAccountMovementCreateFailureAction;

export const CashFlowAccountMovementsReducer: Reducer<CashFlowAccountMovementState> = (
    state: CashFlowAccountMovementState | undefined,
    incomingAction: Action
): CashFlowAccountMovementState => {
    if (state === undefined) {
        return CashFlowAccountMovementUnloadState as CashFlowAccountMovementState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CASHFLOW_ACCOUNTMOVEMENT_GETALL_BYACCOUNTID_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CASHFLOW_ACCOUNTMOVEMENT_GETALL_BYACCOUNTID_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                list: action.payload,
                error: undefined
            };
        case 'CASHFLOW_ACCOUNTMOVEMENT_GETALL_BYACCOUNTID_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CASHFLOW_ACCOUNTMOVEMENT_GET_BYID_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: undefined,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CASHFLOW_ACCOUNTMOVEMENT_GET_BYID_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CASHFLOW_ACCOUNTMOVEMENT_GET_BYID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CASHFLOW_ACCOUNTMOVEMENT_CREATE_REQUEST':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CASHFLOW_ACCOUNTMOVEMENT_CREATE_SUCCESS':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CASHFLOW_ACCOUNTMOVEMENT_CREATE_FAILURE':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        default:
            return state;
    }
}