import ProvidersListComponent from '../../../components/Providers/ProvidersList/ProvidersListComponent';
import { PageHeader } from '../../../commons/component/PageHeader/PageHeader';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const ProvidersListPage = () => {
  
  //useTranslation
  const [t] = useTranslation();

  return (
    <>
      <PageHeader title={t('providerListPage.providerListTitle')}/>
      <div className="Section">
        <ProvidersListComponent />
      </div>
    </>
  );
};
