import { connect } from "react-redux";
import { FormEvent, useEffect, useState } from "react";
import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { ErrorMessageComponent } from "../../Error/ErrorMessageComponent";
import { ContractToClone } from "../../../models/Entities/Contracts/ContractToClone";


//i18Next
import { useTranslation, Trans } from 'react-i18next';

// component functions
import * as ComponentFunctions from './ContractCloneForm.Functions';
import { use } from "i18next";

interface FormValidation {
    success: boolean;
    errors: string[];
}

const ContractCloneFormComponent = (props: ComponentFunctions.Props) => {

    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    // translation
    const [t] = useTranslation();

    // clone contract entity properties
    /*
        contractToCloneId: number;
        contractName: string;
        comments: string;
        isChild: boolean;
    */

    const [contractName, setContractName] = useState<string>();
    const [comments, setComments] = useState<string>();
    const [isChild, setIsChild] = useState<boolean>(false);

    // events 
    const HandleSaveForm = () => {
        setSubmittedForm(true);
        if (ValidateForm()) {
            let CurrentValues: ContractToClone;
            CurrentValues = {
                contractToCloneId: props.ContractId,
                contractName: contractName!,
                comments: comments!,
                isChild: isChild!
            }

            props.CloneContract(CurrentValues);
        }
    };

    const HandleCancelForm = () => {
        ResetForm();
        props.dismissPanel();
    };

    useEffect(() => {
        contractName === undefined && setContractName('Clone - ' + props.Contract?.contractNumber);
    }, [props.Contract]);

    useEffect(() => {
        if (submittedForm && props.isClonedSuccessfully) {
            ResetForm();
            props.dismissPanel();
        }
    }, [submittedForm, props.isClonedSuccessfully]);

    const ValidateForm = (): boolean => {
        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        // validate contract name
        if (!contractName) {
            validationResult = false;
            FormValidation.errors.push(t('ContractCloneForm.formValidation.informName'));
        }

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };

    const ResetForm = () => {
        setSubmittedForm(false);	
        setContractName(undefined);
        setComments(undefined);
        setIsChild(false);
    }

    // footer
    const onRenderFooterContent = () => {
        return (
            <div className={ComponentFunctions.contentStyles.footer}>
                <DefaultButton onClick={HandleCancelForm} className="OtherOption">
                    <Trans i18nKey={'cycleForm.footer.cancelButton'} />
                </DefaultButton>
                <PrimaryButton
                    onClick={HandleSaveForm}
                    styles={ComponentFunctions.buttonStyles}
                    className="ConfirmAction"
                >
                    {props.isCloning && <><Spinner size={SpinnerSize.small} />&nbsp;</>}
                    <Trans i18nKey={'cycleForm.footer.saveButton'} />
                </PrimaryButton>
            </div>
        );
    };


    const formFields = (
        <div {...ComponentFunctions.columnProps}>
            <TextField
                label={t('ContractCloneForm.props.contractName')}
                required
                onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => setContractName(newValue)}
                value={contractName}
                errorMessage={
                    submittedForm &&
                        !contractName ?
                        t('ContractCloneForm.props.requiredField') : ''
                }
            />
            <TextField
                label={t('ContractCloneForm.props.comments')}
                multiline={true}
                onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => setComments(newValue)}
            />
        </div>
    );


    return (
        <Panel
            isOpen={props.isOpen}
            type={PanelType.medium}
            headerText={t('ContractCloneForm.panel.contractInformation')}
            onDismiss={props.dismissPanel}
            closeButtonAriaLabel="Close"
            onRenderFooter={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            {submittedForm && !FormValidationStatus?.success && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {t('ContractCloneForm.panel.reviewInformation')}
                    <ul>
                        {FormValidationStatus?.errors.map(
                            (Error: string, index: number) => (
                                <li key={index}>{Error}</li>
                            )
                        )}
                    </ul>
                </MessageBar>
            )}
            <div className={ComponentFunctions.contentStyles.body}>
                {props.failOnCloning &&
                    props.error && (
                        <ErrorMessageComponent Error={props.error} />
                    )}
                {formFields}
            </div>
        </Panel>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ContractCloneFormComponent as any);