import React from 'react';

import {
  DefaultButton,
  Dropdown,
  FontWeights,
  getTheme,
  IBasePickerSuggestionsProps,
  IconButton,
  IDropdownOption,
  IIconProps,
  IStackProps,
  ITag,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  TextField
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { PriceDetails } from '../../../models/Entities/PriceList/PriceDetail';
import { AppState } from '../../../redux/reducers';
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';
import MedicalInsuranceAgreementFormComponent from '../../MedicalInsuranceAgreement/MedicalInsuranceAgreementForm/MedicalInsuranceAgreementFormComponent';
import { NewPriceDetails } from '../../../models/Entities/PriceList/NewPriceDetail';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';

//store
import * as PriceStore from '../../../redux/middleware/PriceDetailsMiddleware';

import MedicalServiceSelectorComponent from '../../../commons/component/Inputs/MedicalServiceSelector/MedicalServiceSelectorComponent';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

interface FormValidation {
  success: boolean;
  errors: string[];
}

export interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  PriceListId: number;
  PriceDetails: PriceDetails | undefined;
  showPrice: boolean;
  showRemotePrice: boolean;
  showFailPrice: boolean;
  showRandomPrice: boolean;
  showAdditionalPaymentPrice: boolean;
}

export const PriceListItemFormComponent = (props: IProps) => {
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();

  const dispatch = useDispatch();

  //state
  const PriceState = useSelector((appState: AppState) => appState.PriceDetails);

  // entity properties
  const [itemReferredId, setItemReferredId] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [remotePrice, setRemotePrice] = useState<number>(0);
  const [failPrice, setFailPrice] = useState<number>(0);
  const [randomPrice, setRandomPrice] = useState<number>(0);
  const [additionalPaymentPrice, setAdditionalPaymentPrice] = useState<number>(0);
  const [currency, setCurrency] = useState<string>();
  const [priceListEntityId, setPriceListEntityId] = useState<number>();
  const [itemreferredName, setItemreferredName] = useState<string>();
  const [isEnabled, setIsEnabled] = useState<boolean>(true);

  //useTranslation()
  const [t] = useTranslation();

  // effects
  useEffect(() => {
    if (props.PriceDetails) {
      setItemReferredId(props.PriceDetails.itemReferredId);
      setPrice(props.PriceDetails.price);
      setRemotePrice(props.PriceDetails.remotePrice);
      setFailPrice(props.PriceDetails.failPrice);
      setRandomPrice(props.PriceDetails.randomPrice);
      setCurrency(props.PriceDetails.currency);
      setAdditionalPaymentPrice(props.PriceDetails.additionalPaymentPrice);
      setItemreferredName(props.PriceDetails.itemReferredName);
      setIsEnabled(props.PriceDetails.isEnabled);
    } else {
      ResetForm();
    }
  }, [props.PriceDetails]);

  // Effects

  useEffect(() => {
    if (
      submittedForm &&
      PriceState &&
      (PriceState.successAddingNewOne || PriceState.successUpdatingOne)
    ) {
      ResetForm();
      props.dismissPanel();
    }
  }, [
    PriceState?.successAddingNewOne,
    PriceState?.successUpdatingOne,
    PriceState,
    submittedForm,
    props
  ]);

  const ResetForm = () => {
    setItemReferredId(undefined);
    setItemreferredName(undefined);
    setPrice(undefined);
    setRemotePrice(0);
    setFailPrice(0);
    setRandomPrice(0);
    setAdditionalPaymentPrice(0);
    setCurrency(undefined);
    setPriceListEntityId(undefined);
    setFormValidationStatus(undefined);
    setSubmittedForm(false);
    setIsEnabled(true);
  };

  // Events

  const onChangeIdTextFieldValue = (items?: ITag[] | undefined) => {
    if (items && items.length > 0) {
      setItemReferredId(Number(items[0].key));
      setItemreferredName(items[0].name);
    }
  };

  const onChangeItemreferredNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setItemreferredName(newValue);
  };

  const onChangeCurrencyTextFieldValue = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ): void => {
    setCurrency(option?.key.toString() || undefined);
  };

  const onChangePriceTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPrice(Number(newValue) || 0);
  };

  const onChangeRemotePriceTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setRemotePrice(Number(newValue) || 0);
  };

  const onChangeFailPriceTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setFailPrice(Number(newValue) || 0);
  };

  const onChangeRandomPriceTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setRandomPrice(Number(newValue) || 0);
  };

  const onChangeAdditionalPaymentPriceTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setAdditionalPaymentPrice(Number(newValue) || 0);
  };

  const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      var CurrentValues: PriceDetails | NewPriceDetails;
      if (props.PriceDetails) {
        CurrentValues = {
          id: props.PriceDetails.id,
          itemReferredId: itemReferredId!,
          itemReferredName: itemreferredName!,
          itemReferredType:
            props.PriceDetails?.itemReferredType || 'MedicalService',
          price: price!,
          remotePrice: remotePrice!,
          failPrice: failPrice!,
          randomPrice: randomPrice!,
          additionalPaymentPrice: additionalPaymentPrice!,
          currency: currency!,
          pricelistEntityId: props.PriceListId!,
          isEnabled: isEnabled
        };

        dispatch(mapDispatchToProps.SavePrice(CurrentValues as PriceDetails));
      } else {
        CurrentValues = {
          itemReferredId: itemReferredId!,
          itemReferredName: itemreferredName!,
          itemReferredType: 'MedicalService',
          price: price!,
          remotePrice: remotePrice!,
          failPrice: failPrice!,
          randomPrice: randomPrice!,
          additionalPaymentPrice: additionalPaymentPrice!,
          currency: currency!,
          pricelistEntityId: props.PriceListId!,
          isEnabled: isEnabled
        };

        dispatch(
          mapDispatchToProps.AddNewPrice(CurrentValues as NewPriceDetails)
        );
      }
    }
  };

  const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
  };

  // footer
  const onRenderFooterContent = () => {
    return (
      <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'priceListItemForm.footer.cancelButton'}/>
        </DefaultButton>
        <PrimaryButton
          onClick={HandleSaveForm}
          styles={buttonStyles}
          className="ConfirmAction"
        >
          <Trans i18nKey={'priceListItemForm.footer.saveButton'}/>
        </PrimaryButton>
      </div>
    );
  };

  const formFields = (
    <div {...columnProps}>
      <MedicalServiceSelectorComponent
        onSelectedServices={(services: AvailableMedicalService[]) => {
          if (services && services.length > 0) {
            setItemReferredId(services[0].id);
            setItemreferredName(services[0].serviceNameReference);
          }
        }}
        MedicalServicesSelectedIds={
          itemReferredId ? [...[], itemReferredId] : undefined
        }
        MultiselectEnable={false}
      />
      <Dropdown
        placeholder={t('priceListItemForm.parameters.selectCurrency')} 
        label="Moneda"
        options={currencyOptions}
        onChange={onChangeCurrencyTextFieldValue}
        errorMessage={
          submittedForm === true && currency === undefined
            ? t('priceListItemForm.parameters.requiredField')
            : undefined
        }
        selectedKey={currency ? currency : undefined}
      />
      {props.showPrice && <TextField
        label={t('priceListItemForm.parameters.price')} 
        name="txt_price"
        required
        type="currency"
        autoComplete="off"
        onChange={onChangePriceTextFieldValue}
        errorMessage={
          submittedForm === true && price === undefined
            ? t('priceListItemForm.parameters.requiredField')
            : undefined
        }
        value={price?.toString() || '0'}
      />}
      {props.showRemotePrice && <TextField
        label={t('priceListItemForm.parameters.remotePrice')} 
        name="txt_remotePrice"
        type="currency"
        autoComplete="off"
        onChange={onChangeRemotePriceTextFieldValue}
        value={remotePrice?.toString() || '0'}
      />}
      {props.showFailPrice && <TextField
        label={t('priceListItemForm.parameters.failPrice')} 
        name="txt_failPrice"
        type="currency"
        autoComplete="off"
        onChange={onChangeFailPriceTextFieldValue}
        value={failPrice?.toString() || '0'}
      />}
      {props.showRandomPrice && <TextField
        label={t('priceListItemForm.parameters.randomPrice')} 
        name="txt_randomPrice"
        type="currency"
        autoComplete="off"
        onChange={onChangeRandomPriceTextFieldValue}
        value={randomPrice?.toString() || '0'}
      />}
      {props.showAdditionalPaymentPrice && <TextField
        label={t('priceListItemForm.parameters.additionalPaymentPrice')} 
        name="txt_additionalPaymentPrice"
        type="currency"
        autoComplete="off"
        onChange={onChangeAdditionalPaymentPriceTextFieldValue}
        value={additionalPaymentPrice?.toString() || '0'}
      />}
    </div>
  );

  // form validation

  const ValidateForm = (): boolean => {
    var validationResult: boolean = true;

    var FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    if (itemReferredId === undefined) {
      validationResult = false;
      FormValidation.errors.push(t('priceListItemForm.formValidation.selectServiceError'));
    }

    if (price === undefined) {
      validationResult = false;
      FormValidation.errors.push(t('priceListItemForm.formValidation.informPriceError'));
    }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      isModeless={false}
      containerClassName={contentStyles.container}
      closeButtonAriaLabel="Close"
    >
      <div className={contentStyles.header}>
        <span>{t('priceListItemForm.modal.priceInformation')}</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={HandleCancelForm}
        />
      </div>
      {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {t('priceListItemForm.modal.reviewInformation')}
          <ul>
            {FormValidationStatus?.errors.map(
              (Error: string, index: number) => (
                <li key={index}>{Error}</li>
              )
            )}
          </ul>
        </MessageBar>
      )}
      {submittedForm &&
        PriceState &&
        (PriceState.failOnAddingNewOne || PriceState.failOnUpdatingOne) &&
        PriceState.error && <ErrorMessageComponent Error={PriceState.error} />}
      <div className={contentStyles.body}>{formFields}</div>
      {onRenderFooterContent()}
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalInsurances
});

const mapDispatchToProps = {
  ...PriceStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalInsuranceAgreementFormComponent as any);

// const filterSuggestedTags = (
//   filterText: string,
//   medicalServiceList: AvailableMedicalService[],
//   tagList?: ITag[]
// ): ITag[] => medicalServiceList?.filter((service: AvailableMedicalService) => 
//     service.serviceNameReference.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
//  )?.map((item: AvailableMedicalService) => { return { key: item.id, name: item.serviceNameReference } as ITag}) || [];

const currencyOptions: IDropdownOption[] = [
  { key: 'ARS', text: '$ (Pesos Argentinos)' },
  { key: 'EUR', text: '€ (Euros)' },
  { key: 'USD', text: '$ (Dollars)' }
];

//const getTextFromItem = (item: ITag) => item.name;

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'servicios sugeridos',
  noResultsFoundText: 'No se encontro servicio'
};

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
