import { createTableColumn, PresenceBadgeStatus, TableColumnDefinition, TableCellLayout } from "@fluentui/react-components";
import * as jwt_decode from "jwt-decode";

//store 
import * as PaymentStore from "../../../../redux/middleware/CashFlow-Payments.Middlerware";
import * as PersonsStore from "../../../../redux/middleware/Persons.Middleware";

// models
import { Payment } from "../../../../models/Entities/CashFlow/Payment/Payment";
import { ErrorType } from "../../../../models/HttpError";
import { AppState } from "../../../../redux/reducers";
import { Person } from "../../../../models/Entities/Person/Person";


//#region Props

export enum PaymentListType {
    Payer = 1,
    Receiver = 2
}

interface IProps {
    type: PaymentListType;
    EntityIdentifier: string;
    FixedAssociatedEntityIdentifier: string | undefined;
  }
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    isDeleting: boolean;
    isDeletedSuccessfully: boolean;
    failOnDeleting: boolean;
    error: ErrorType | undefined;
    Payments: Payment[];
    GetPersonByIdentifier: (identifier: string) => Person | undefined;
  }
  
  interface MedicalServicesDispatchProps {
    GetAllPaymentsByPayer: typeof PaymentStore.actionCreators.GetAllPaymentsByPayer;
    GetAllPaymentsByReceiver: typeof PaymentStore.actionCreators.GetAllPaymentsByReceiver;
    GetAllPersons: typeof PersonsStore.actionCreators.GetAllPersons;
    DeletePayment: typeof PaymentStore.actionCreators.DeletePayment;
  }
  
  export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const CurrentTenantId = (jwt_decode.jwtDecode(state?.oidc?.user?.access_token!) as any)?.tenantId;

    var payments: Payment[] = [];
    
    switch (ownProps.type) {
      case PaymentListType.Payer:
        payments = state.CashFlowPayments?.paymentsDone || [];
        break;
      case PaymentListType.Receiver:
        payments = state.CashFlowPayments?.paymentsReceived || [];
        break;
    }

    const persons = state.Persons?.list || [];

    return {
      isLoading: state.CashFlowPayments?.isLoadingAll,
      isUpdating: state.CashFlowPayments?.isUpdatingAll,
      isUpdatedSuccessfully: state.CashFlowPayments?.successLoadingAll,
      isLoadingSuccessfully: state.CashFlowPayments?.successLoadingAll,
      failOnUpdating: state.CashFlowPayments?.failOnLoadingAll,
      failOnLoading: state.CashFlowPayments?.failOnLoadingAll,
      error: state.CashFlowPayments?.error,
      Payments: payments,
      GetPersonByIdentifier: (identifier: string) => GetPersonByIdentifier(persons, CurrentTenantId, identifier)
    }
  };
  
  export const mapDispatchToProps = {
    ...PaymentStore.actionCreators,
    ...PersonsStore.actionCreators
  };


  // grid layout
  export type FileCell = {
    label: string;
    icon: JSX.Element;
  };
  
  export type LastUpdatedCell = {
    label: string;
    timestamp: number;
  };
  
  export type LastUpdateCell = {
    label: string;
    icon: JSX.Element;
  };
  
  export type AuthorCell = {
    label: string;
    status: PresenceBadgeStatus;
  };


  // get person by identifier
const GetPersonByIdentifier = (persons: Person[], TenantId: string, identifier: string): Person | undefined => {
  if(identifier === TenantId) return {
    personIdentifier: TenantId,
    businessName: 'Centro Médico',
  } as Person;
  return persons.find((person: Person) => person.personIdentifier === identifier);
}

  