import { connect } from 'react-redux';

// component functions
import * as OwnFunctions from './ContractAccess.List.Functions';
import { use } from 'i18next';
import { useEffect, useState } from 'react';
import { AvatarGroup, AvatarGroupItem, AvatarGroupPopover, Button, Label, Persona, Popover, PopoverProps, PopoverSurface, PopoverTrigger, Spinner } from '@fluentui/react-components';
import ContractAccessFormComponent from '../ContractAccess.Form/ContractAccess.Form.Component';
import { ContractAccess } from '../../../../models/Entities/Contracts/ContractAccess';
import { Separator, Toggle } from '@fluentui/react';
import { Delete24Regular } from '@fluentui/react-icons';

//styles
import './ContractAccess.List.Styles.css';

const ContractAccessListComponent = (props: OwnFunctions.Props) => {

    const [open, setOpen] = useState(false);
    const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) =>
    setOpen(data.open || false);

    // add or update form controll
    const [isAccessFormOpen, setIsAccessFormOpen] = useState(false);

    const [selectedAccess, setSelectedAccess] = useState<ContractAccess | null>(null);

    // events 
    
    const UpdateContractAccess = (access: ContractAccess) => {
        props.UpdateContractAccess(access);
    }

    const DeleteContractAccess = (access: ContractAccess) => {
        props.DeleteContractAccess(props.ContractId, access.id);
        setOpen(false);
    }

    // on close form
    const onAccessFormDismiss = () => {
        setSelectedAccess(null);
        setIsAccessFormOpen(false);
    }

    // on edit Access
    const onEditAccess = (access: ContractAccess) => {
        setSelectedAccess(access);
        setIsAccessFormOpen(true);
    }


    useEffect(() => {
        props.GetContractAccessByContractId(props.ContractId);
        props.GetAllUsers(1, 1000, undefined);
    }, []);

    const Forms = (
        <ContractAccessFormComponent
            isOpen={isAccessFormOpen}
            openPanel={() => undefined}
            dismissPanel={onAccessFormDismiss}
            ContractAccessId={selectedAccess?.id}
            ContractId={props.ContractId}
            SelectedUsersIds={props.Contract?.access?.map(a => a.userID)}
        />
    );

    const UserAccessContent = (access: ContractAccess) => {
        //const styles = useStyles();
        return (
            <div>
                <Persona
                    presence={{ status: access.readAccess ? "available" : "blocked" }}
                    size="large"
                    name={access.userName}
                    avatar={{ color: "colorful" }}
                />
                <Separator />
                <div className='inlineToggle'>
                <Toggle label="Puede ver el contrato" defaultChecked={access.readAccess} onText="Si" offText="No" onChange={(
                    (ev: any, checked?: boolean) => UpdateContractAccess({...access, readAccess: checked ?? false})
                )} />{props.Contract?.isUpdatingContractAccess && <><Spinner size="extra-tiny" />&nbsp;</>}
                </div>
                <Separator />
                <Button id='RemoveAccessButton' icon={<Delete24Regular />} onClick={() => DeleteContractAccess(access)}>Eliminar acceso</Button>
            </div>
        );
    };


    return (
        <>
            <label>Permisos</label>
            <p><Button onClick={() => setIsAccessFormOpen(true)}>Habilitar usuarios</Button></p>
            {props.Access?.length === 0 && <p><Label>Este contrato es visible para cualquier usuario de administración</Label></p>}
            {(props.Access || [])?.length > 0 && <AvatarGroup layout="stack">
                {props.Access?.map((access: ContractAccess) =>
                    <Popover withArrow={true} key={access.userID}>
                        <PopoverTrigger disableButtonEnhancement>
                            <AvatarGroupItem style={{ cursor: 'pointer' }} name={access.userName} key={access.userID} badge={{ status: access.readAccess ? 'available' : "blocked" }}  />
                        </PopoverTrigger>
                        <PopoverSurface tabIndex={-1}>
                            <UserAccessContent {...access} />
                        </PopoverSurface>
                    </Popover>

                )}
            </AvatarGroup>}
            {Forms}
        </>
    );
};

export default connect(
    OwnFunctions.mapStateToProps,
    OwnFunctions.mapDispatchToProps
)(ContractAccessListComponent as any);