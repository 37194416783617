import {
  IBasePickerSuggestionsProps,
  ITag,
  ValidationState
} from '@fluentui/react';

//store
import * as ProtocolStore from '../../../../redux/middleware/MedicalProtocolMiddleware';

// models
import { Protocol } from '../../../../models/Entities/Protocols/Protocol';
import { ErrorType } from '../../../../models/HttpError';
import { AppState } from '../../../../redux/reducers';


interface IProps {
  selectionLimit: number | undefined;
  onChangeSelection?: (
    selectedProtocols: Protocol[] | undefined
  ) => void;
}

interface ConnectedProps {
  isLoading: boolean;
  isLoadingSuccessfully: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Protocols: Protocol[];
}

interface DispatchProps {
  GetAllMedicalProtocols: typeof ProtocolStore.actionCreators.GetAllMedicalProtocols;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.ContractConcept?.isLoadingAll,
  isLoadingSuccessfully: state.ContractConcept?.successLoadingAll,
  failOnLoading: state.ContractConcept?.failOnLoadingAll,
  error: state.ContractConcept?.error,
  Protocols: state.MedicalProtocols?.list,
});

export const mapDispatchToProps = ({
  ...ProtocolStore.actionCreators
});


export const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Protocolos sugeridos',
  mostRecentlyUsedHeaderText: 'Protocolos sugeridos',
  noResultsFoundText: 'No se econtraron protocolos',
  loadingText: 'buscando...',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'Protocolos sugeridos disponibles',
  suggestionsContainerAriaLabel: 'Protocolos sugeridos'
};

export function doesTextStartWith(text: string, filterText: string): boolean {
  return text.toLowerCase().includes(filterText.toLowerCase());
}

export function removeDuplicates(
  personas: ITag[],
  possibleDupes: ITag[]
) {
  return personas?.filter((persona) => !listContainsProtocol(persona, possibleDupes)) || []
}

function listContainsProtocol(
  protocol: ITag,
  protocols: ITag[]
) {
  if (!protocols || !protocols.length || protocols.length === 0) {
    return false;
  }
  return protocols.filter((item) => item.name === protocol.name).length > 0;
}

export function getTextFromItem(protocol: ITag): string {
  return protocol.name as string;
}

export function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}
