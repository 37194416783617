import React from 'react';
import { connect } from 'react-redux';
import { DefaultButton, Label, MessageBarType, Panel, PanelType, PrimaryButton, Separator, Spinner, SpinnerSize, Text, TextField, Toggle } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';

// styles 
import './PractitionerSettingsForm.Styles.css';

// i18next
import { Trans, useTranslation } from 'react-i18next';

// functions
import * as ComponentFunctions from './PractitionerSettingsForm.Functions';

// components
import ContractsSelectorComponent from '../../../../commons/component/Inputs/ContractSelector/ContractSelectorComponent';
import ProtocolContractFormComponent from '../../../Contracts/Protocols/ProtocolContractForm/ProtocolContractFormComponent';
import { MessageComponent } from '../../../../commons/component/MessageComponent';
import PractitionerConceptsListComponent from '../PracititionerConceptsList/PractitionerConcepts.List.Component';

// models
import { Contract } from '../../../../models/Entities/Contracts/Contract';
import { ContractCommissionAgent } from '../../../../models/Entities/Contracts/ContractCommissionAgent';
import { NewContractCommissionAgent } from '../../../../models/Entities/Contracts/NewContractCommissionAgent';

const PractitionerSettingsForm = (props: ComponentFunctions.Props) => {

    // useTranslation()
    const [t] = useTranslation();

    useEffect(() => {
        props.GetAllContracts();
    }, []);

    useEffect(() => {
        
        if (props.isOpen && !props.isLoadingAgents && props.AgentsLoaded && props.Agent?.id === undefined) {

            //alert('create agent');

            var NewAgent: NewContractCommissionAgent = {
                contractId: props.ContractId,
                commissionAgentId: props.Practitioner.practitionerUserId,
                commissionAgentName: props.GetPractiontioner(props.Practitioner.practitionerUserId)?.fullName,
                currency: 'USD',
                commissionAmount: 0,
                contractAssociatedId: undefined,
                haveContractAssociated: false,
                isCommissionAPercentage: true,
                onlyDoneHimSelf: false
            } as NewContractCommissionAgent;

            props.CreateContractCommissionAgent(NewAgent);

        }

    }, [props.isOpen === true && props.Agent === undefined]);

    useEffect(() => {
        if (props.Agent) {
            setPayFixValue(!props.Agent.isCommissionAPercentage);
            setFixValue(props.Agent.commissionAmount);
            setCurrency(props.Agent.currency);
            setPercentage(props.Agent.commissionAmount);
            setHaveContractAssociated(props.Agent.haveContractAssociated);
            setContractId(props.Agent.contractAssociatedId);
            setOnlyMadeByHimSelf(props.Agent.onlyDoneHimSelf);
        }

    }, [props.Practitioner?.practitionerUserId, props.Agent?.id]);

    useEffect(() => {
        props.dismissPanel();
    }, [props.isUpdatedSuccessfully]);

    const [isOpenContractPanel, { setTrue: openContractPanel, setFalse: dismissContractPanel }] =
        useBoolean(false);

    

    // internal marks
    const [payFixValue, setPayFixValue] = useState<boolean>(true);
    const [paySimple, setPaySimple] = useState<boolean>(false);
    const [payByConcept, setPayByConcept] = useState<boolean>(false);

    const [FixValue, setFixValue] = useState<number>(0);
    const [Currency, setCurrency] = useState<string>();
    const [Percentage, setPercentage] = useState<number>(0);
    const [haveContractAssociated, setHaveContractAssociated] = useState<boolean>(false);
    const [ContractId, setContractId] = useState<number>();
    const [onlyMadeByHimSelf, setOnlyMadeByHimSelf] = useState<boolean>(false);

    const ResetForm = () => {
        setPayFixValue(true);
        setFixValue(0);
        setCurrency(undefined);
        setPercentage(0);
        setHaveContractAssociated(false);
        setContractId(undefined);
        setOnlyMadeByHimSelf(false);
    };


    const HandleCancelForm = () => {
        //ResetForm();
        props.dismissPanel();
    };


    // events
    const HandleSaveForm = () => {

        var currentCommissionAgent: NewContractCommissionAgent | ContractCommissionAgent | undefined;
        if (props.Agent?.id) {
            currentCommissionAgent = {
                id: props.Agent?.id,
                contractId: props.ContractId,
                commissionAgentId: props.Practitioner.practitionerUserId,
                commissionAgentName: props.GetPractiontioner(props.Practitioner.practitionerUserId)?.fullName,
                currency: Currency,
                commissionAmount: payFixValue ? FixValue : Percentage,
                contractAssociatedId: ContractId,
                haveContractAssociated: haveContractAssociated || false,
                isCommissionAPercentage: !payFixValue || false,
                onlyDoneHimSelf: onlyMadeByHimSelf || false
            } as ContractCommissionAgent;

            props.UpdateContractCommissionAgent(currentCommissionAgent as ContractCommissionAgent);
        }
        else {
            currentCommissionAgent = {
                contractId: props.ContractId,
                commissionAgentId: props.Practitioner.practitionerUserId,
                commissionAgentName: props.GetPractiontioner(props.Practitioner.practitionerUserId)?.fullName,
                currency: Currency,
                commissionAmount: FixValue | Percentage,
                contractAssociatedId: ContractId,
                haveContractAssociated: haveContractAssociated || false,
                isCommissionAPercentage: !payFixValue || false,
                onlyDoneHimSelf: onlyMadeByHimSelf || false
            } as NewContractCommissionAgent;

            props.CreateContractCommissionAgent(currentCommissionAgent as NewContractCommissionAgent);
        }
    }


    const FormFields = (
        <div>
            <Toggle
                label={'Contrato asociado'}
                defaultChecked={haveContractAssociated}
                onChange={(
                    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
                    checked?: boolean | undefined
                ) => {
                    setHaveContractAssociated(checked || false)
                }
                }
            />
            <>
                <ContractsSelectorComponent
                    onSelectedContract={
                        (contracts: Contract[]) => setContractId(contracts[0]?.id)
                    }
                    ContractsSelected={
                        props.Agent?.contractAssociatedId ? [props.Agent?.contractAssociatedId] : undefined
                    }
                    MultiselectEnable={false}
                    disabled={!haveContractAssociated}
                />
                {<p>
                    <PrimaryButton
                        text={'Agregar contrato'}
                        onClick={openContractPanel}
                        disabled={props.isSaving || props.isUpdating || !haveContractAssociated}
                    />
                </p>}
            </>


            <>
                <Separator />
                {props.Practitioner && <PractitionerConceptsListComponent PractitionerId={props.Practitioner.practitionerUserId} CommissionAgentId={props.Agent?.id} ContractId={props.ContractId} ProtocolId={props.ProtocolId} disabled={haveContractAssociated} />}
                {/* {props.Agent &&
                        <>
                            {payByConcept &&
                                <>
                                    

                                </>
                            }
                            {paySimple &&
                                <>
                                    <Toggle
                                        label={'Forma de participación'}
                                        checked={payFixValue}
                                        offText='Porcentaje'
                                        onText='Monto fijo'
                                        disabled={haveContractAssociated}
                                        onChange={(
                                            event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
                                            checked?: boolean | undefined
                                        ) => setPayFixValue(checked || false)}
                                    />
                                    {payFixValue && <>
                                        <CurrencySelectorComponent
                                            errorMessage={undefined}
                                            defaultSelected={Currency}
                                            disabled={haveContractAssociated}
                                            onCurrencyChange={(
                                                currency: string
                                            ) => setCurrency(currency)}
                                        />
                                        <TextField
                                            label={'Monto fijo'}
                                            type="number"
                                            value={FixValue?.toString()}
                                            suffix={Currency}
                                            disabled={haveContractAssociated}
                                            onChange={(
                                                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                                newValue?: string | undefined
                                            ) => setFixValue(Number(newValue))}
                                        />
                                    </>}
                                    {!payFixValue && <>
                                        <TextField
                                            label={'Porcentaje'}
                                            type="number"
                                            suffix={'%'}
                                            disabled={haveContractAssociated}
                                            value={Percentage?.toString()}
                                            onChange={(
                                                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                                newValue?: string | undefined
                                            ) => setPercentage(Number(newValue))}
                                        />
                                    </>}
                                    <p>
                                        <Checkbox
                                            label={'Tomar solo los conceptos realizados por este profesional'}
                                            disabled={haveContractAssociated}
                                            defaultChecked={onlyMadeByHimSelf}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => setOnlyMadeByHimSelf(checked as boolean || false)}
                                        />
                                    </p>

                                </>
                            }
                        </>
                    } */}

            </>

        </div>
    );

    // footer
    const onRenderFooterContent = () => {
        return (
            <>
                <div className=''>
                    <DefaultButton onClick={HandleCancelForm} className="OtherOption">
                        <Trans i18nKey={'conceptForm.footer.cancelButton'} />
                    </DefaultButton>
                    <PrimaryButton
                        onClick={HandleSaveForm}
                        disabled={props.isSaving || props.isUpdating}
                        className="ConfirmAction"
                    >
                        {props.isSaving || props.isUpdating ? <><Spinner size={SpinnerSize.xSmall} />&nbsp;<span><Trans i18nKey={'conceptForm.footer.saveButton'} /></span></> : 'Guardar'}
                    </PrimaryButton>
                </div>
            </>
        );
    };

    const Forms = (
        <>
            <ProtocolContractFormComponent
                isOpen={isOpenContractPanel}
                Contract={undefined}
                dismissPanel={dismissContractPanel}
                openPanel={openContractPanel}
                mode=''
                RefererId={undefined}
            />
        </>
    )

    const ErrorMessage = props.error && (
        <>
            <MessageComponent
                message={'Ocurrio un error al grabar la informacion de la comisión'}
                subMessage={props.error.ErrorMessage}
                itemList={props.error.Errors}
                type={
                    MessageBarType.error
                }
            />
        </>
    );

    return (
        <Panel
            headerText={props.GetPractiontioner(props.Practitioner?.practitionerUserId)?.fullName || 'No Name'}
            type={PanelType.medium}
            isLightDismiss={true}
            isOpen={props.isOpen}
            onDismiss={props.dismissPanel}
            onRenderFooter={undefined}
            isFooterAtBottom={true}
            onRenderFooterContent={onRenderFooterContent}
        >
            <Text className='TextDecorated'>{props.Practitioner?.studyRole?.name || 'No title'}</Text>
            <Separator />
            {ErrorMessage}
            {FormFields}
            {Forms}
        </Panel>
    );

};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(PractitionerSettingsForm as any);

