import { createUserManager } from 'redux-oidc';

// let settings = {
//     client_id: process.env.REACT_APP_APP_NAME,
//     authority: process.env.REACT_APP_AUTHENTICATION_AUTHORITY,
//     automaticSilentRenew: true,
//     includeIdTokenInSilentRenew: true,
//     userStore: new WebStorageStateStore({
//       prefix: ApplicationName
//     }),
//     clockSkew: 900,
//     redirect_uri: `${process.env.REACT_APP_AUTHENTICATION_CALLBACK}/authentication/login-callback`,
//     post_logout_redirect_uri: `${process.env.REACT_APP_AUTHENTICATION_CALLBACK}/authentication/logout-callback`,
//     response_type: 'code',
//     scope:
//       'openid profile IdentityServerApi protocolsapi databaseapi agendaapi notificationsapi medicalrecordapi digitalsignapi pre_biller_app'
//   };

const userManagerConfig = {
  client_id: process.env.REACT_APP_APP_NAME,
  redirect_uri: `${process.env.REACT_APP_AUTHENTICATION_CALLBACK}/authentication/login-callback`,
  response_type: 'token id_token',
  response_mode: 'query',
  scope:  'openid profile IdentityServerApi protocolsapi databaseapi agendaapi notificationsapi medicalrecordapi digitalsignapi pre_biller_app',
  authority:  process.env.REACT_APP_AUTHENTICATION_AUTHORITY,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true
};

const userManager = createUserManager(userManagerConfig);

export default userManager;