import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/ContractAddendums.Services';

// actions
import * as Actions from '../actions';

// models
import { ContractAddendum } from '../../models/Entities/Contracts/ContractAddendum';
import { NewContractAddendum } from '../../models/Entities/Contracts/NewContractAddendum';


type KnownAction =
    | Actions.ContractAddendum.ContractAddendumsGetByContractIdRequestAction
    | Actions.ContractAddendum.ContractAddendumsGetByContractIdSuccessAction
    | Actions.ContractAddendum.ContractAddendumsGetByContractIdFailureAction
    | Actions.ContractAddendum.ContractAddendumGetByIdRequestAction
    | Actions.ContractAddendum.ContractAddendumGetByIdSuccessAction
    | Actions.ContractAddendum.ContractAddendumGetByIdFailureAction
    | Actions.ContractAddendum.ContractAddendumCreateRequestAction
    | Actions.ContractAddendum.ContractAddendumCreateSuccessAction
    | Actions.ContractAddendum.ContractAddendumCreateFailureAction
    | Actions.ContractAddendum.ContractAddendumUpdateRequestAction
    | Actions.ContractAddendum.ContractAddendumUpdateSuccessAction
    | Actions.ContractAddendum.ContractAddendumUpdateFailureAction
    | Actions.ContractAddendum.ContractAddendumDeleteRequestAction
    | Actions.ContractAddendum.ContractAddendumDeleteSuccessAction
    | Actions.ContractAddendum.ContractAddendumDeleteFailureAction
    | Actions.ContractAddendum.ContractAddendumCloneRequestAction
    | Actions.ContractAddendum.ContractAddendumCloneSuccessAction
    | Actions.ContractAddendum.ContractAddendumCloneFailureAction;

export const actionCreators = {
    GetContractAddendumsByContractId: (contractId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;
    
        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_ADDENDUMS_GETBYCONTRACTID_FAILURE',
                contractId: contractId,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        dispatch({ type: 'CONTRACT_ADDENDUMS_GETBYCONTRACTID_REQUEST', contractId: contractId });

        try {
            const addendums = await Services.GetAddendumsByContract(contractId, token);
            dispatch({ type: 'CONTRACT_ADDENDUMS_GETBYCONTRACTID_SUCCESS', contractId: contractId, addendums });

        } catch (error: any) {

            dispatch({ type: 'CONTRACT_ADDENDUMS_GETBYCONTRACTID_FAILURE', contractId: contractId, error });
        }
    },
    GetContractAddendumById: (contractId: number, addendumId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        
        var state = getState();
        var token: string;
    
        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_ADDENDUM_GETBYID_FAILURE',
                contractId: contractId,
                addendumId: addendumId,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };
        
        dispatch({ type: 'CONTRACT_ADDENDUM_GETBYID_REQUEST', contractId, addendumId: addendumId });

        try {

            const addendum = await Services.GetAddendumById(addendumId, token);
            dispatch({ type: 'CONTRACT_ADDENDUM_GETBYID_SUCCESS', addendum });

        } catch (error: any) {
            
            dispatch({ type: 'CONTRACT_ADDENDUM_GETBYID_FAILURE', contractId, addendumId , error });
        }
    },
    CreateContractAddendum: (contractId: number, newAddendum: NewContractAddendum): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;
    
        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_ADDENDUM_CREATE_FAILURE',
                contractId: contractId,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        };

        dispatch({ type: 'CONTRACT_ADDENDUM_CREATE_REQUEST', contractId, newAddendum });

        try {

            const addendum = await Services.CreateAddendum(contractId, newAddendum, token);
            dispatch({ type: 'CONTRACT_ADDENDUM_CREATE_SUCCESS', addendum });

        } catch (error: any) {

            dispatch({ type: 'CONTRACT_ADDENDUM_CREATE_FAILURE', contractId, error });
        }
    },
    UpdateContractAddendum: (contractId: number, addendum: ContractAddendum): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;
    
        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_ADDENDUM_UPDATE_FAILURE',
                contractId: contractId,
                addendumId: addendum.id,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        }

        dispatch({ type: 'CONTRACT_ADDENDUM_UPDATE_REQUEST', addendum });

        try {

            const updatedAddendum = await Services.UpdateAddendum(addendum, token);
            dispatch({ type: 'CONTRACT_ADDENDUM_UPDATE_SUCCESS', addendum: updatedAddendum });
        
        } catch (error: any) {
            dispatch({ type: 'CONTRACT_ADDENDUM_UPDATE_FAILURE', contractId, addendumId: addendum.id, error });
        }
    },
    DeleteContractAddendum: (contractId: number, addendumId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        
        var state = getState();
        var token: string;
    
        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_ADDENDUM_DELETE_FAILURE',
                addendumId: addendumId,
                contractId: contractId,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        }
        
        dispatch({ type: 'CONTRACT_ADDENDUM_DELETE_REQUEST', contractId, addendumId });

        try {

            await Services.DeleteAddendum(addendumId, token);
            dispatch({ type: 'CONTRACT_ADDENDUM_DELETE_SUCCESS', contractId, addendumId });
        
        } catch (error: any) {
            dispatch({ type: 'CONTRACT_ADDENDUM_DELETE_FAILURE', contractId, addendumId, error });
        }
    },
    CloneAddendum: (contractId: number, addendumId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            
        var state = getState();
        var token: string;
    
        if(state.oidc?.user?.access_token === undefined) {
            dispatch({
                type: 'CONTRACT_ADDENDUM_CLONE_FAILURE',
                contractId: contractId,
                error: { 
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []                     
                }
            })
            return;
        } else {
            token = state.oidc.user.access_token;
        }
        
        dispatch({ type: 'CONTRACT_ADDENDUM_CLONE_REQUEST', contractId, addendumId });

        try {

            const addendum = await Services.CloneAddendum(addendumId, token);
            dispatch({ type: 'CONTRACT_ADDENDUM_CLONE_SUCCESS', contractId, addendum });
        
        } catch (error: any) {
            dispatch({ type: 'CONTRACT_ADDENDUM_CLONE_FAILURE', contractId, error });
        }
    }
};