import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { SignoutCallbackComponent } from "redux-oidc";
import userManager from "../../Utils/UserManager";

const SignOutCallbackPage = () => {

    const userState = useSelector((state: any) => state.oidc.user);

    // const navigate = useNavigate();

    // const successCallback = (user: any) => {
    //     console.log(user);
    //     var locationURL = GetUrlPath(user.state?.returnUrl)
    //     navigate(locationURL || "/");
    // };

    // const errorCallback = (error: any) => {
    //     console.error(error);
    // };

    useEffect(() => {
        userManager.signoutRedirect().then(function () {
            console.log('signoutRedirect done');
        })
            .catch(function (error) {
                console.error('error while signing out user', error);
            });
    }, [userState !== undefined]);

    return (
        <></>
    );
}

export default connect()(SignOutCallbackPage);