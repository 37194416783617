import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';
import { CashFlowPaymentState } from '../states/CashFlow.Payment.State';
import { Payment } from '../../models/Entities/CashFlow/Payment/Payment';

type KnownAction =
    | Actions.CashFlowPayments.CashFlowPaymentsDoneGetAllRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsDoneGetAllSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsDoneGetAllFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedGetAllRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedGetAllSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedGetAllFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsDoneUpdateAllRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsDoneUpdateAllSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsDoneUpdateAllFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedUpdateAllRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedUpdateAllSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsReceivedUpdateAllFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsGetByIdRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsGetByIdSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsGetByIdFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsCreateRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsCreateSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsCreateFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsUpdateRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsUpdateSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsUpdateFailureAction
    | Actions.CashFlowPayments.CashFlowPaymentsDeleteRequestAction
    | Actions.CashFlowPayments.CashFlowPaymentsDeleteSuccessAction
    | Actions.CashFlowPayments.CashFlowPaymentsDeleteFailureAction;

export const CashFlowPaymentsReducer: Reducer<CashFlowPaymentState> = (
    state: CashFlowPaymentState | undefined,
    incomingAction: Action
): CashFlowPaymentState => {
    if (state === undefined) {
        return {} as CashFlowPaymentState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CASHFLOW_PAYMENTS_DONE_GETALL_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_DONE_GETALL_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                paymentsDone: action.payload,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_DONE_GETALL_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CASHFLOW_PAYMENTS_RECEIVED_GETALL_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_RECEIVED_GETALL_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                paymentsReceived: action.payload,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_RECEIVED_GETALL_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CASHFLOW_PAYMENTS_DONE_UPDATEALL_REQUEST':
            return {
                ...state,
                isUpdatingAll: true,
                isUpdatingAllSuccess: undefined,
                FailUpdatingAll: false,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_DONE_UPDATEALL_SUCCESS':
            return {
                ...state,
                isUpdatingAll: false,
                isUpdatingAllSuccess: true,
                FailUpdatingAll: false,
                paymentsDone: action.payload,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_DONE_UPDATEALL_FAILURE':
            return {
                ...state,
                isUpdatingAll: false,
                isUpdatingAllSuccess: false,
                FailUpdatingAll: true,
                error: action.error
            };
        case 'CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_REQUEST':
            return {
                ...state,
                isUpdatingAll: true,
                isUpdatingAllSuccess: undefined,
                FailUpdatingAll: false,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_SUCCESS':
            return {
                ...state,
                isUpdatingAll: false,
                isUpdatingAllSuccess: true,
                FailUpdatingAll: false,
                paymentsReceived: action.payload,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_RECEIVED_UPDATEALL_FAILURE':
            return {
                ...state,
                isUpdatingAll: false,
                isUpdatingAllSuccess: false,
                FailUpdatingAll: true,
                error: action.error
            };
        case 'CASHFLOW_PAYMENTS_GETBYID_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: undefined,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_GETBYID_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                paymentsDone: action.tenantId === action.payload.payer ? [...state.paymentsDone?.filter((payment: Payment) => payment.id !== action.payload.id) || [], action.payload] : state.paymentsDone,
                paymentsReceived: action.tenantId === action.payload.receiver ? [...state.paymentsReceived?.filter((payment: Payment) => payment.id !== action.payload.id) || [], action.payload] : state.paymentsReceived,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_GETBYID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CASHFLOW_PAYMENTS_CREATE_REQUEST':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_CREATE_SUCCESS':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                paymentsDone: action.tenantId === action.payload.payer ? [...state.paymentsDone, action.payload] : state.paymentsDone,
                paymentsReceived: action.tenantId === action.payload.receiver ? [...state.paymentsReceived, action.payload] : state.paymentsReceived,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_CREATE_FAILURE':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        case 'CASHFLOW_PAYMENTS_UPDATE_REQUEST':

            var paymentToUpdate = action.payload.payer === action.tenantId ? state.paymentsDone?.find((payment: Payment) => payment.id === action.payload.id) : state.paymentsReceived?.find((payment: Payment) => payment.id === action.payload.id);
            
            if (paymentToUpdate) {
                paymentToUpdate.isUpdating = true;
                paymentToUpdate.isUpdatedSuccessfully = undefined;
                paymentToUpdate.failOnUpdating = false;
                paymentToUpdate.error = undefined;
            }
            return {
                ...state,
                isUpdatingOne: true,
                successUpdatingOne: undefined,
                failOnUpdatingOne: false,
                paymentsDone: (state.paymentsDone?.find((payment: Payment) => payment.id === action.payload.id) && paymentToUpdate) ? [...state.paymentsDone?.filter((payment: Payment) => payment.id !== action.payload.id), paymentToUpdate] : state.paymentsDone,
                paymentsReceived: (state.paymentsReceived?.find((payment: Payment) => payment.id === action.payload.id) && paymentToUpdate) ? [...state.paymentsReceived?.filter((payment: Payment) => payment.id !== action.payload.id), paymentToUpdate] : state.paymentsReceived,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_UPDATE_SUCCESS':

            var paymentUpdated = action.payload.payer === action.tenantId ? state.paymentsDone?.find((payment: Payment) => payment.id === action.payload.id) : state.paymentsReceived?.find((payment: Payment) => payment.id === action.payload.id);

            if (paymentUpdated) {
                paymentUpdated = {...paymentUpdated, ...action.payload};
                paymentUpdated.isUpdating = false;
                paymentUpdated.isUpdatedSuccessfully = true;
                paymentUpdated.failOnUpdating = false;
                paymentUpdated.error = undefined;
            }

            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: true,
                failOnUpdatingOne: false,
                paymentsDone: (state.paymentsDone?.find((payment: Payment) => payment.id === action.payload.id) && paymentUpdated) ? [...state.paymentsDone?.filter((payment: Payment) => payment.id !== action.payload.id) || [], paymentUpdated] : state.paymentsDone,
                paymentsReceived: (state.paymentsReceived?.find((payment: Payment) => payment.id === action.payload.id) && paymentUpdated) ? [...state.paymentsReceived?.filter((payment: Payment) => payment.id !== action.payload.id) || [], paymentUpdated] : state.paymentsReceived,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_UPDATE_FAILURE':

            var paymentFailedOnUpdate = state.paymentsDone?.find((payment: Payment) => payment.id === action.paymentId) || state.paymentsReceived?.find((payment: Payment) => payment.id === action.paymentId);

            if (paymentFailedOnUpdate) {
                paymentFailedOnUpdate.isUpdating = false;
                paymentFailedOnUpdate.isUpdatedSuccessfully = false;
                paymentFailedOnUpdate.failOnUpdating = true;
                paymentFailedOnUpdate.error = action.error;
            }

            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: false,
                failOnUpdatingOne: true,
                paymentsDone: (state.paymentsDone?.find((payment: Payment) => payment.id === action.paymentId) && paymentFailedOnUpdate) ? [...state.paymentsDone?.filter((payment: Payment) => payment.id !== action.paymentId), paymentFailedOnUpdate] : state.paymentsDone,
                paymentsReceived: (state.paymentsReceived?.find((payment: Payment) => payment.id === action.paymentId) && paymentFailedOnUpdate) ? [...state.paymentsReceived?.filter((payment: Payment) => payment.id !== action.paymentId), paymentFailedOnUpdate] : state.paymentsReceived,
                error: action.error
            };
        case 'CASHFLOW_PAYMENTS_DELETE_REQUEST':

            var paymentToDelete = state.paymentsDone?.find((payment: Payment) => payment.id === action.payload) || state.paymentsReceived?.find((payment: Payment) => payment.id === action.payload);

            if (paymentToDelete) {
                paymentToDelete.isDeleting = true;
                paymentToDelete.isDeletedSuccessfully = undefined;
                paymentToDelete.failOnDeleting = false;
                paymentToDelete.error = undefined;
            }

            return {
                ...state,
                isDeletingOne: true,
                successDeletingOne: undefined,
                failOnDeletingOne: false,
                paymentsDone: paymentToDelete ? state.paymentsDone?.filter((payment: Payment) => payment.id !== action.payload) : state.paymentsDone,
                paymentsReceived: paymentToDelete ? state.paymentsReceived?.filter((payment: Payment) => payment.id !== action.payload) : state.paymentsReceived,
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_DELETE_SUCCESS':       
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: true,
                failOnDeletingOne: false,
                paymentsDone: state.paymentsDone?.filter((payment: Payment) => payment.id !== action.payload),
                paymentsReceived: state.paymentsReceived?.filter((payment: Payment) => payment.id !== action.payload),
                error: undefined
            };
        case 'CASHFLOW_PAYMENTS_DELETE_FAILURE':

            var paymentFailedOnDelete = state.paymentsDone?.find((payment: Payment) => payment.id === action.paymentId) || state.paymentsReceived?.find((payment: Payment) => payment.id === action.paymentId);

            if (paymentFailedOnDelete) {
                paymentFailedOnDelete.isDeleting = false;
                paymentFailedOnDelete.isDeletedSuccessfully = false;
                paymentFailedOnDelete.failOnDeleting = true;
                paymentFailedOnDelete.error = action.error;

            }

            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: false,
                failOnDeletingOne: true,
                paymentsDone: paymentFailedOnDelete ? [...state.paymentsDone?.filter((payment: Payment) => payment.id !== action.paymentId), paymentFailedOnDelete] : state.paymentsDone,
                paymentsReceived: paymentFailedOnDelete ? [...state.paymentsReceived?.filter((payment: Payment) => payment.id !== action.paymentId), paymentFailedOnDelete] : state.paymentsReceived,
                error: action.error
            };
        default:
            return state;
    }
}
