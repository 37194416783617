import { CommonState } from '../common/state/commonState';
import { PriceList } from '../../models/Entities/PriceList/PriceList';

export interface PriceListState extends CommonState<PriceList> {
  // Adding
  isAddingNewPrice: boolean;
  newPriceAddedSuccess: boolean | undefined;
  failOnAddNewPrice: boolean;

  // updating
  isSavingPrice: boolean;
  priceSavingSuccess: boolean | undefined;
  failOnSavePrice: boolean;
}

export const PriceListUnloadedState: any = {
  // loading all
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  FailGettingAll: false,

  // loading one
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  FailGettingOne: false,

  // saving
  isSaving: false,
  isSavingSuccess: undefined,
  FailSaving: false,

  // adding
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,

  isFiltered: false,
  filterCriteria: undefined,

  list: [],
  Selected: undefined,
  Error: undefined,

  // validity start
  isSettingValidityStartDate: false,
  SetValidityStartDateSuccess: undefined,
  FailOnSetValidityStartDate: false,

  // validity end
  isSettingValidityEndDate: false,
  SetValidityEndDateSuccess: undefined,
  FailOnSetValidityEndDate: false,

  // Adding Price
  isAddingNewPrice: false,
  newPriceAddedSuccess: undefined,
  FailOnAddNewPrice: false,

  // saving price
  isSavingPrice: false,
  PriceSavingSuccess: undefined,
  FailOnSavePrice: false
};
