import { NewStageCycle } from './../../../../../models/Entities/Contracts/NewStageCycle';
import { StageCycle } from './../../../../../models/Entities/Contracts/StageCycle';
import { IDropdownOption } from '@fluentui/react';
import { NewContractStage } from '../../../../../models/Entities/Contracts/NewContractStage';
import { ContractStage } from '../../../../../models/Entities/Contracts/ContractStage';

export function getCyclesAsDropdownOptions(
  cycles: StageCycle[]
): IDropdownOption[] {
  let options: IDropdownOption[] = [];
  options = [...options, { key: '0', text: '' }];
  options = options.concat(
    cycles.map((cycle: StageCycle) => {
      return {
        key: cycle.id,
        text: cycle.cycle + ' | ' + cycle.cycleName
      } as IDropdownOption;
    })
  );

  return options;
}

// Get the stages
export function getStages(
  currentStages: NewContractStage[],
  stageAmount: number,
  stageName: string,
): NewContractStage[] {
  let stages: NewContractStage[] = [];

  if (currentStages.length === 0) {
    if (currentStages.length === 0) {
      stages = Array.apply(null, new Array(stageAmount - stages.length)).map(
        (item, index) => {
          return {
            stageName: stageName,
            comments: '',
            isOptional: false,
            isOnSiteMandatory: false,
            failSelectionEnabled: false,
            stageOrder: index + stages.length + 1
          };
        }
      ) as NewContractStage[];
    }
  }

  if (currentStages.length > stageAmount) {
    stages = currentStages.slice(0, stageAmount).map((stage: NewContractStage) => {
      return {
        stageName: stage.stageName,
        comments: stage.comments,
        isOptional: stage.isOptional,
        isOnSiteMandatory: stage.isOnSiteMandatory,
        failSelectionEnabled: stage.failSelectionEnabled,
        stageOrder: stage.stageOrder
      };
    }) as NewContractStage[];
  }

  if (currentStages.length < stageAmount) {
    console.log('currentStages', currentStages);
    stages = currentStages.map((stage: NewContractStage) => {
      return {
        stageName: stage.stageName,
        comments: stage.comments,
        isOptional: stage.isOptional,
        isOnSiteMandatory: stage.isOnSiteMandatory,
        failSelectionEnabled: stage.failSelectionEnabled,
        stageOrder: stage.stageOrder
      };
    }) as NewContractStage[];

    stages = stages.concat(
      Array.apply(null, new Array(stageAmount - stages.length)).map(
        (item, index) => {
          return {
            stageName: stageName,
            comments: '',
            isOptional: false,
            isOnSiteMandatory: false,
            failSelectionEnabled: false,
            stageOrder: index + stages.length + 1
          };
        }
      ) as NewContractStage[]
    );
  }

  return stages;
}

export function getCycles(
  currentCycles: StageCycle[],
  cycleAmount: number,
  cycleName: string
): NewStageCycle[] {
  let cycles: NewStageCycle[] = [];

  if (currentCycles.length === 0) {
    if (currentCycles.length === 0) {
      cycles = Array.apply(null, new Array(cycleAmount - cycles.length)).map(
        (item, index) => {
          return {
            cycle: 'Ciclo ' + (index + cycles.length + 1),
            cycleName: cycleName,
            order: index + cycles.length + 1
          };
        }
      ) as NewStageCycle[];
    }
  }

  if (currentCycles.length > cycleAmount) {
    cycles = currentCycles.slice(0, cycleAmount).map((cycle: NewStageCycle) => {
      return {
        cycle: cycle.cycle,
        cycleName: cycle.cycleName,
        order: cycle.order
      };
    }) as NewStageCycle[];
  }

  if (currentCycles.length < cycleAmount) {
    cycles = currentCycles.map((cycle: NewStageCycle) => {
      return {
        cycle: cycle.cycle,
        cycleName: cycle.cycleName,
        order: cycle.order
      };
    }) as NewStageCycle[];

    cycles = cycles.concat(
      Array.apply(null, new Array(cycleAmount - cycles.length)).map(
        (item, index) => {
          return {
            cycle: 'Ciclo ' + (index + cycles.length + 1),
            cycleName: cycleName,
            order: index + cycles.length + 1
          };
        }
      ) as NewStageCycle[]
    );
  }

  return cycles;
}
