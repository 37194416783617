import { MedicalInsurancePlan } from './../../models/Entities/MedicalInsurances/MedicalInsurancePlan';
import { NewMedicalInsurancePlan } from '../../models/Entities/MedicalInsurances/NewMedicalInsurancePlan';
import { AppThunkAction } from '../reducers';
import { ErrorType } from '../../models/HttpError';

// services
import * as Services from '../../services/MedicalInsurancePlans.Services';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansAdd_Request_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansAdd_Receive_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansAdd_Fail_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansUpdate_Request_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansUpdate_Receive_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansUpdate_Fail_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetById_Request_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetById_Receive_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetById_Fail_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetAllByMedicalInsuranceId_Request_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetAllByMedicalInsuranceId_Receive_Action
  | Actions.MedicalInsurancePlans.MedicalInsurancePlansGetAllByMedicalInsuranceId_Fail_Action;

export const actionCreators = {
  GetMedicalPlansByMedicalInsuranceId:
    (Id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_PLANS_GET_BY_MEDICAL_INSURANCE_ID_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_PLANS_GET_BY_MEDICAL_INSURANCE_ID_REQUEST_ACTION',
        MedicalInsuranceId: Id
      });

      Services.GetAllMedicalInsurancePlansByMedicalInsuranceId(Id, token)
        .then((plans: MedicalInsurancePlan[]) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_PLANS_GET_BY_MEDICAL_INSURANCE_ID_RECEIVE_ACTION',
            PlansList: plans
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_PLANS_GET_BY_MEDICAL_INSURANCE_ID_FAIL_ACTION',
            error: error
          })
        );
    },
  GetMedicalInsurancePlanById: (Id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_PLANS_GET_BY_ID_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_PLANS_GET_BY_ID_REQUEST_ACTION',
        MedicalInsuranceId: Id
      });

      Services.GetMedicalInsurancePlanById(Id, token)
        .then((plan: MedicalInsurancePlan) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_PLANS_GET_BY_ID_RECEIVE_ACTION',
            MedicalInsurancePlan: plan
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_PLANS_GET_BY_ID_FAIL_ACTION',
            error: error
          })
        );
    },
  AddMedicalInsurancePlan:
    (NewPlan: NewMedicalInsurancePlan): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_PLANS_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_PLANS_ADD_REQUEST_ACTION',
        NewPlan: NewPlan
      });

      Services.AddMedicalInsurancePlan(NewPlan, token)
        .then((plan: MedicalInsurancePlan) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_PLANS_ADD_RECEIVE_ACTION',
            MedicalInsurancePlan: plan
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_PLANS_ADD_FAIL_ACTION',
            error: error
          })
        );
    },
  SaveMedicalInsurancePlan:
    (UpdatedPlan: MedicalInsurancePlan): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'MEDICAL_INSURANCE_PLANS_UPDATE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'MEDICAL_INSURANCE_PLANS_UPDATE_REQUEST_ACTION',
        Plan: UpdatedPlan
      });

      Services.UpdateMedicalInsurancePlan(UpdatedPlan, token)
        .then((plan: MedicalInsurancePlan) => {
          dispatch({
            type: 'MEDICAL_INSURANCE_PLANS_UPDATE_RECEIVE_ACTION',
            Plan: plan
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'MEDICAL_INSURANCE_PLANS_UPDATE_FAIL_ACTION',
            error: error
          })
        );
    }
};
