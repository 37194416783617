import { connect } from 'react-redux';
import { FormEvent, useEffect, useState } from 'react';
import { DefaultButton, FontSizes, Label, MessageBar, MessageBarType, Modal, PrimaryButton, Separator, Spinner, SpinnerSize, TextField, Toggle } from '@fluentui/react';

//function
import * as ComponentFunctions from './SettlementDetailsFormReview.Functions';


////i18Next
import { useTranslation, Trans } from 'react-i18next';

interface FormValidation {
    success: boolean;
    errors: string[];
}

const SettlementDetailFormReview = (props: ComponentFunctions.Props) => {

    // form status
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    // form properties
    const [isForReview, setIsForReview] = useState<boolean>(false);
    const [comments, setComments] = useState<string>();

    //useTranslation();
    const [t] = useTranslation();

    useEffect(() => {
        if (props.SettlementDetail) {
            setIsForReview(props.SettlementDetail.markForReview);
            setComments(props.SettlementDetail.comments);
        }
    }, [props.SettlementDetail]);

    useEffect(() => {
        if (submittedForm && props.isSuccessfullyUpdated ) {
            ResetForm();
            props.dismissPanel();
        }
    }, [props.isSuccessfullyUpdated]);


    // events
    const HandleChangeComments = (comment: string) => {
        setComments(comment);
    }

    const HandleChangeIsForReview = (mark: boolean) => {
        setIsForReview(mark);
    }

    const HandleSaveForm = () => {
        setSubmittedForm(true);
        if (ValidateForm()) {
            props.MarkForReview(props.SettlementDetailId, isForReview, comments!);
        }
    }

    const HandleCancelForm = () => {
        ResetForm();
        props.dismissPanel();
    };

    const ResetForm = () => {
        setComments(undefined);
        setIsForReview(false);
    };

    const ValidateForm = (): boolean => {
        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        //validate amount
        if (comments === undefined) {
            validationResult = false;
            FormValidation.errors.push("Debe informar la razón de la revisión");
        }

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };

    const formData = (
        <>
            <Toggle
                label={'Marcar para revisión'}
                defaultChecked={isForReview}
                onChange={(event: any, checked?: boolean | undefined) => checked ? HandleChangeIsForReview(true) : HandleChangeIsForReview(false)}
            />
            <TextField
                label={'Comentarios'}
                name="settlementdetails-comment"
                required
                type="text"
                multiline={true}
                autoComplete="off"
                onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => newValue ? HandleChangeComments(newValue) : HandleChangeComments('')}
                errorMessage={
                    submittedForm === true && comments === undefined
                        ? 'Este campo es requerido'
                        : undefined
                }
                value={comments || ''}
            />
        </>
    );

    // footer
    const onRenderFooterContent = () => {
        return (
            <div className={ComponentFunctions.contentStyles.footer}>
                <DefaultButton onClick={HandleCancelForm} className="OtherOption">
                    Cancelar
                </DefaultButton>
                {<PrimaryButton
                    onClick={HandleSaveForm}
                    styles={ComponentFunctions.buttonStyles}
                    className="ConfirmAction"
                    disabled={props.isUpdating}
                >
                    {props.isUpdating && <><Spinner size={SpinnerSize.small} />&nbsp;</>}
                    Guardar
                </PrimaryButton>}

            </div>
        );
    };

    return (
        <Modal isOpen={props.isOpen} containerClassName='ModalMargin'>
            <Label style={{ fontSize: FontSizes.large }}>{t('settlementDetailForm.conceptInformation')}</Label>
            <Separator />
            {submittedForm && !FormValidationStatus?.success && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {t('settlementDetailForm.message.reviewInformation')}
                    <ul>
                        {FormValidationStatus?.errors.map(
                            (Error: string, index: number) => (
                                <li key={index}>{Error}</li>
                            )
                        )}
                    </ul>
                </MessageBar>
            )}
            {formData}
            <Separator />
            {onRenderFooterContent()}
        </Modal>
    );

};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementDetailFormReview as any);

