//#region Props

import { AppState } from "../../redux/reducers";
import * as jwt_decode from "jwt-decode";

interface IProps {}

interface ConnectedProps {
  OwnerId: string;
}

interface DispatchProps {}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: any) => {

    const CurrentTenantId = (jwt_decode.jwtDecode(state?.oidc?.user?.access_token!) as any)?.tenantId;

    return {
        OwnerId: CurrentTenantId
    }
};

export const mapDispatchToProps = {};