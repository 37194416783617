import {
  IBasePickerSuggestionsProps,
  IPersonaProps,
  ValidationState
} from '@fluentui/react';
import { Provider } from '../../../../models/Entities/Provider/Provider';

// Services
import * as ProviderService from '../../../../services/Provider.Services';

// Common Functions
import * as CommonFunctions from '../../../../functions/common';

export const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Proveedores sugeridos',
  mostRecentlyUsedHeaderText: 'Proveedores sugeridos',
  noResultsFoundText: 'No se Proveedores Sponsors',
  loadingText: 'buscando...',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'Proveedores sugeridos disponibles',
  suggestionsContainerAriaLabel: 'Proveedores sugeridos'
};


function doesTextStartWith(text: string, filterText: string): boolean {
  return text.toLowerCase().includes(filterText.toLowerCase());
}

function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[]
) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

function listContainsPersona(
  persona: IPersonaProps,
  personas: IPersonaProps[]
) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}

export function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

export function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}
