//#region Props

import { AppState } from "../../../redux/reducers";
import * as jwt_decode from "jwt-decode";

// store
import * as DebtsStore from "../../../redux/middleware/CashFlow-Debts.Middleware";
import * as PaymentsStore from "../../../redux/middleware/CashFlow-Payments.Middlerware";

interface IProps {
    OwnerIdentifier: string;
    FixedAssociatedEntityIdentifier: string | undefined;
}

interface ConnectedProps {
  OwnerId: string;
  EntityIdentifier: string;
  QuantityDebtByCreditor:  number;
  QuantityDebtByDebtor:  number;
  QuantityPaymentsByPayer:  number;
  QuantityPaymentsByReceiver:  number;
}

interface DispatchProps {
  GetAllPaymentsByPayer: typeof PaymentsStore.actionCreators.GetAllPaymentsByPayer;
  GetAllPaymentsByReceiver: typeof PaymentsStore.actionCreators.GetAllPaymentsByReceiver;
  GetAllDebtsByCreditor: typeof DebtsStore.actionCreators.GetAllDebtsByCreditor;
  GetAllDebtsByDebtor: typeof DebtsStore.actionCreators.GetAllDebtsByDebtor;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: any) => {

    const CurrentTenantId = (jwt_decode.jwtDecode(state?.oidc?.user?.access_token!) as any)?.tenantId;

    return {
        OwnerId: CurrentTenantId,
        EntityIdentifier: ownProps.EntityIdentifier,
        QuantityDebtByCreditor: state.CashFlowDebts?.DebtsAsCreditor?.length || 0,
        QuantityDebtByDebtor: state.CashFlowDebts?.DebtsAsDebtor?.length || 0,
        QuantityPaymentsByPayer: state.CashFlowPayments?.paymentsDone?.length || 0,
        QuantityPaymentsByReceiver: state.CashFlowPayments?.paymentsReceived?.length || 0
    }
};

export const mapDispatchToProps = {
  ...DebtsStore.actionCreators,
  ...PaymentsStore.actionCreators
};