import { Action, Reducer } from 'redux';

// actions
import * as Actions from '../actions/';
import { LanguajeState, LanguajeUnloadedState } from '../states/LanguajeStates';


type KnownAction =
    | Actions.Languaje.Languaje_GetAll_Request_Action
    | Actions.Languaje.Languaje_GetAll_Receive_Action
    | Actions.Languaje.Languaje_GetAll_Fail_Action;


export const LanguajeReducer: Reducer<LanguajeState> = (
    state: LanguajeState | undefined, incomingAction: Action
): LanguajeState => {

    if (state === undefined) {
        return LanguajeUnloadedState as LanguajeState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'LANGUAJE_GET_ALL_REQUEST_ACTION':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: false,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'LANGUAJE_GET_ALL_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
                list: action.Languajes
            };
        case 'LANGUAJE_GET_ALL_FAIL_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        default:
            return state;
    }
}