import { ContractBranch } from "../../models/Entities/Contracts/ContractBranch";
import { CommonState } from "../common/state/commonState";


export interface ContractBranchState extends CommonState<ContractBranch> {
    // updating all
    isUpdatingAll: boolean;
    isUpdatingAllSuccess: boolean|undefined;
    FailUpdatingAll: boolean;

    // AddRange
    isAddingFinishedSuccessfully: boolean | undefined;
}

export const ContractBranchUnloadState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,

    // updating all
    isUpdatingAll: false,
    isUpdatingAllSuccess: undefined,
    FailUpdatingAll: false,

    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,

    // updating
    isUpdatingOne: false,
    successUpdatingOne: undefined,
    failOnUpdatingOne: false,
    
    // adding
    isAddingNewOne: false,
    successAddingNewOne: undefined,
    failOnAddingNewOne: false,

    // deleting
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    FailDeletingOne: false, 

    // AddRange
    isAddingFinishedSuccessfully: undefined
};