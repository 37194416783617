import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';
import { ProtocolPractitionersState, ProtocolPractitionersUnloadState } from '../states/ProtocolPractitionersState';

type KnownAction =
    | Actions.ProtocolPractitioners.ProtocolPractitiones_GetAll_Request_Action
    | Actions.ProtocolPractitioners.ProtocolPractitiones_GetAll_Receive_Action
    | Actions.ProtocolPractitioners.ProtocolPractitiones_GetAll_Fail_Action
    | Actions.ProtocolPractitioners.ProtocolPractitionesStudyTask_GetAll_Request_Action
    | Actions.ProtocolPractitioners.ProtocolPractitionesStudyTask_GetAll_Receive_Action
    | Actions.ProtocolPractitioners.ProtocolPractitionesStudyTask_GetAll_Fail_Action;

export const ProtocolPractitionersReducer: Reducer<ProtocolPractitionersState> = (
    state: ProtocolPractitionersState | undefined,
    incomingAction: Action
  ): ProtocolPractitionersState => {
    if (state === undefined) {
      return ProtocolPractitionersUnloadState;
    }
  
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'PROTOCOL_PRACTITIONERS_GETALL_REQUEST_ACTION':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'PROTOCOL_PRACTITIONERS_GETALL_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'PROTOCOL_PRACTITIONERS_GETALL_FAIL_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'PROTOCOL_PRACTITIONERS_STUDYTASK_GETALL_REQUEST_ACTION':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'PROTOCOL_PRACTITIONERS_STUDYTASK_GETALL_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'PROTOCOL_PRACTITIONERS_STUDYTASK_GETALL_FAIL_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        default:
            return state;
    }
}
    