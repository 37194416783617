import { PriceDetails } from '../../models/Entities/PriceList/PriceDetail';
import { CommonState } from '../common/state/commonState';

export interface PriceListDetailsState extends CommonState<PriceDetails> {}

export const PriceListDetailsUnloadState: any = {
  // loading all
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  FailGettingAll: false,

  // loading one
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  FailGettingOne: false,

  // saving
  isSaving: false,
  isSavingSuccess: undefined,
  FailSaving: false,

  // adding
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,

  isFiltered: false,
  filterCriteria: undefined,

  list: [],
  Selected: undefined,
  Error: undefined
};
