import { MedicalInsuranceAgreement } from '../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';
import { CommonState } from '../common/state/commonState';

export interface MedicalInsuranceAgreementsState
  extends CommonState<MedicalInsuranceAgreement> {
  // Adding Price List
  isAddingPriceList: boolean;
  addingPriceListSuccess: boolean | undefined;
  failOnAddingPriceList: boolean;

  //Deleting Price List
  isDeletingPriceList: boolean;
  deletingPriceListSuccess: boolean | undefined;
  failOnDeletingPriceList: boolean;

  // setting validity Start
  isSettingValidityStartDate: boolean;
  setValidityStartDateSuccess: boolean | undefined;
  failOnSetValidityStartDate: boolean;

  // setting validity end
  isSettingValidityEndDate: boolean;
  setValidityEndDateSuccess: boolean | undefined;
  failOnSetValidityEndDate: boolean;
}

export const MedicalInsuranceAgreementsUnloadedState: any = {
  // loading all
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  FailGettingAll: false,

  // loading one
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  FailGettingOne: false,

  // saving
  isSaving: false,
  isSavingSuccess: undefined,
  FailSaving: false,

  // adding
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,

  isFiltered: false,
  filterCriteria: undefined,

  list: [],
  Error: undefined,

  // Adding Price List
  isAddingPriceList: false,
  addingPriceListSuccess: undefined,
  failOnAddingPriceList: false,

  // validity start
  isSettingValidityStartDate: false,
  SetValidityStartDateSuccess: undefined,
  FailOnSetValidityStartDate: false,

  // validity end
  isSettingValidityEndDate: false,
  SetValidityEndDateSuccess: undefined,
  FailOnSetValidityEndDate: false
};
