import { PractitionerContract } from './../../models/Entities/PractitionerContract';
import { CommonState } from './../common/state/commonState';

export interface PractitionerContractState
  extends CommonState<PractitionerContract> {
  // setting validity Start
  isSettingValidityStartDate: boolean;
  setValidityStartDateSuccess: boolean | undefined;
  failOnSetValidityStartDate: boolean;

  // setting validity end
  isSettingValidityEndDate: boolean;
  setValidityEndDateSuccess: boolean | undefined;
  failOnSetValidityEndDate: boolean;
}

export const practitionerContractInitialState: PractitionerContractState = {
  // loading all
  isLoadingAll: false,
  successLoadingAll: undefined,
  failOnLoadingAll: false,

  // loading one
  isLoadingOne: false,
  successLoadingOne: undefined,
  failOnLoadingOne: false,

  // saving
  isUpdatingOne: false,
  successUpdatingOne: undefined,
  failOnUpdatingOne: false,

  // adding
  isAddingNewOne: false,
  successAddingNewOne: undefined,
  failOnAddingNewOne: false,

  list: [],
  error: undefined,

  // validity start
  isSettingValidityStartDate: false,
  setValidityStartDateSuccess: undefined,
  failOnSetValidityStartDate: false,

  // validity end
  isSettingValidityEndDate: false,
  setValidityEndDateSuccess: undefined,
  failOnSetValidityEndDate: false,

  // Deleting one
  isDeletingOne: false,
  successDeletingOne: undefined,
  failOnDeletingOne: false,

};
