import {
  FontSizes,
  getRTL,
  Icon,
  List,
  Label,
  Text,
  TooltipHost,
  Spinner,
  SpinnerSize
} from '@fluentui/react';
import { useSelector, connect } from 'react-redux';
import { AppState } from '../../../redux/reducers';
import { useState } from 'react';

//styles
import './ProviderPriceList.css';

// functions
import * as ownFunctions from './ProviderPriceListFunctions';
import { ProviderPriceList } from '../../../models/Entities/ProviderAssociations/PriceLists/ProviderPriceList';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export interface IProps {
  priceList: ProviderPriceList[];
  onSelectPriceList?: (PriceList: ProviderPriceList) => void;
}

export const ProviderPriceListComponent = (props: IProps) => {
  const ListPriceState = useSelector((state: AppState) => state.PriceList);
  const [Today, setToday] = useState<Date>(new Date());
  const [priceListSelected, setPriceListSelected] =
    useState<ProviderPriceList>();
  
  //useTranslation();
  const [t] = useTranslation();  

  const onSelectListHandler = (list: ProviderPriceList) => {
    setPriceListSelected(list);
    if (props.onSelectPriceList) props.onSelectPriceList(list);
  };

  const onRenderCell = (
    item: ProviderPriceList | undefined,
    index: number | undefined
  ): JSX.Element => {
    return (
      <div
        onClick={() => onSelectListHandler(item!)}
        className={
          item?.selected
            ? ownFunctions.classNames.itemSelected
            : ownFunctions.classNames.itemCell
        }
        data-is-focusable={true}
      >
        <Icon
          className={ownFunctions.classNames.listIcon}
          iconName={'PageList'}
          style={{ fontSize: FontSizes.size32 }}
        />
        <div className={ownFunctions.classNames.itemContent}>
          <div className={ownFunctions.classNames.itemName}>
            <TooltipHost
              content={item?.priceList.listName}
              calloutProps={{ gapSpace: 0 }}
            >
              <Label
                className="ellipsis"
                style={{ fontSize: FontSizes.medium }}
              >
                {item?.priceList.listName}
              </Label>
            </TooltipHost>
          </div>
          <div>
            {item && (
              <Text
                className={
                  'priceListTag pricelist ' +
                  ownFunctions.GetPriceListStatus(item).status
                }
              >
                {ownFunctions.GetPriceListStatus(item).message}
              </Text>
            )}
          </div>
        </div>
        <Icon
          className={ownFunctions.classNames.chevron}
          iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'}
        />
      </div>
    );
  };

  return ListPriceState && ListPriceState?.isLoadingAll ? (
    <>
      <div className="LoadingBlock">
        <Spinner size={SpinnerSize.large} label={t('providerPriceList.loadingBlock.loading')}/>
      </div>
    </>
  ) : (
    <>
      <List
        items={
          props.priceList?.map((list: ProviderPriceList) => {
            list.id === priceListSelected?.id
              ? (list.selected = true)
              : (list.selected = false);
            return list;
          }) || []
        }
        onRenderCell={onRenderCell}
      />
      {!props.priceList ||
        (props.priceList.length === 0 && (
          <p className="NoData">{t('providerPriceList.loadingBlock.noPriceList')}</p>
        ))}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(ProviderPriceListComponent as any);
