import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/ContractAccess.Services';

// models
import { ContractAccess } from '../../models/Entities/Contracts/ContractAccess';
import { ErrorType } from '../../models/HttpError';
import { NewContractAccess } from '../../models/Entities/Contracts/NewContractAccess';

// actions
import * as Actions from '../actions';

type KnownAction = 
    | Actions.ContractAccess.ContractAccessGetAllRequestAction
    | Actions.ContractAccess.ContractAccessGetAllSuccessAction
    | Actions.ContractAccess.ContractAccessGetAllFailureAction
    | Actions.ContractAccess.ContractAccessGetByIdRequestAction
    | Actions.ContractAccess.ContractAccessGetByIdSuccessAction
    | Actions.ContractAccess.ContractAccessGetByIdFailureAction
    | Actions.ContractAccess.ContractAccessCreateRequestAction
    | Actions.ContractAccess.ContractAccessCreateSuccessAction
    | Actions.ContractAccess.ContractAccessCreateFailureAction
    | Actions.ContractAccess.ContractAccessUpdateRequestAction
    | Actions.ContractAccess.ContractAccessUpdateSuccessAction
    | Actions.ContractAccess.ContractAccessUpdateFailureAction
    | Actions.ContractAccess.ContractAccessDeleteRequestAction
    | Actions.ContractAccess.ContractAccessDeleteSuccessAction
    | Actions.ContractAccess.ContractAccessDeleteFailureAction;

export const actionCreators = {
  GetContractAccessByContractId: (contractId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      
      const state = getState();
      const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;
  
      if (token === null) {
        dispatch({
          type: 'CONTRACT_ACCESS_GETALL_FAILURE',
          contractId: contractId,
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        })
        return;
      }
      
      dispatch({ type: 'CONTRACT_ACCESS_GETALL_REQUEST', contractId: contractId });
  
      try {
        Services.GetContractAccess(contractId, token)
          .then((contractAccess: ContractAccess[]) => {
            dispatch({
              type: 'CONTRACT_ACCESS_GETALL_SUCCESS',
              constractId: contractId,
              contractAccess: contractAccess
            });
          })
          .catch((error: ErrorType) =>
            dispatch({
              type: 'CONTRACT_ACCESS_GETALL_FAILURE',
              contractId: contractId,
              error: error
            })
          );
      } catch (error) {
        console.log(error);
    }
  },
  GetContractAccessById: (contractAccessId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

    const state = getState();
    const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

    if (token === null) {
      dispatch({
        type: 'CONTRACT_ACCESS_GETBYID_FAILURE',
        contractAccessId: contractAccessId,
        error: {
          ErrorCode: 401,
          ErrorMessage: 'Not authorized',
          Errors: []
        }
      })
      return;
    }
   
    dispatch({ type: 'CONTRACT_ACCESS_GETBYID_REQUEST', contractAccessId: contractAccessId });

    try {
      Services.GetContractAccessById(contractAccessId, token)
        .then((contractAccess: ContractAccess) => {
          dispatch({
            type: 'CONTRACT_ACCESS_GETBYID_SUCCESS',
            contractAccess: contractAccess
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'CONTRACT_ACCESS_GETBYID_FAILURE',
            contractAccessId: contractAccessId,
            error: error
          })
        );
    } catch (error) {
      console.log(error);
    }
  },
  CreateContractAccess: (newContractAccess: NewContractAccess): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    
    const state = getState();
    const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

    if (token === null) {
      dispatch({
        type: 'CONTRACT_ACCESS_CREATE_FAILURE',
        error: {
          ErrorCode: 401,
          ErrorMessage: 'Not authorized',
          Errors: []
        }
      })
      return;
    }    
    
    dispatch({ type: 'CONTRACT_ACCESS_CREATE_REQUEST', newContractAccess: newContractAccess });

    try {
      Services.CreateContractAccess(newContractAccess, token)
        .then((contractAccess: ContractAccess) => {
          dispatch({
            type: 'CONTRACT_ACCESS_CREATE_SUCCESS',
            contractAccess: contractAccess
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'CONTRACT_ACCESS_CREATE_FAILURE',
            error: error
          })
        );
    } catch (error) {
      console.log(error);
    }
  },
  UpdateContractAccess: (contractAccess: ContractAccess): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    
    const state = getState();
    const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;

    if (token === null) {
      dispatch({
        type: 'CONTRACT_ACCESS_UPDATE_FAILURE',
        error: {
          ErrorCode: 401,
          ErrorMessage: 'Not authorized',
          Errors: []
        }
      })
      return;
    }
    
    dispatch({ type: 'CONTRACT_ACCESS_UPDATE_REQUEST', contractAccess: contractAccess });

    try {
      Services.UpdateContractAccess(contractAccess, token)
        .then((contractAccess: ContractAccess) => {
          dispatch({
            type: 'CONTRACT_ACCESS_UPDATE_SUCCESS',
            contractAccess: contractAccess
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'CONTRACT_ACCESS_UPDATE_FAILURE',
            error: error
          })
        );
    } catch (error) {
      console.log(error);
    }
  },
  DeleteContractAccess: (contractId: number, contractAccessId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        
        const state = getState();
        const token: string | null = state?.oidc?.user?.access_token ? state.oidc.user.access_token : null;
    
        if (token === null) {
            dispatch({
                type: 'CONTRACT_ACCESS_DELETE_FAILURE',
                contractId: contractId,
                contractAccessId: contractAccessId,
                error: {
                ErrorCode: 401,
                ErrorMessage: 'Not authorized',
                Errors: []
                }
            })
            return;
        }
        
        dispatch({ type: 'CONTRACT_ACCESS_DELETE_REQUEST', contractId: contractId, contractAccessId: contractAccessId });
    
        try {
        Services.DeleteContractAccess(contractAccessId, token)
            .then(() => {
            dispatch({
                type: 'CONTRACT_ACCESS_DELETE_SUCCESS',
                constractId: contractId,
                contractAccessId: contractAccessId
            });
            })
            .catch((error: ErrorType) =>
            dispatch({
                type: 'CONTRACT_ACCESS_DELETE_FAILURE',
                contractId: contractId,
                contractAccessId: contractAccessId,
                error: error
            })
            );
        } catch (error) {
            console.log(error);
        }
    }
};