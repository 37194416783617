import React, { useState } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import {
  QueryParameterNames,
  LogoutActions,
  ApplicationPaths
} from './ApiAuthorizationConstants';
import { Spinner } from '../Utils/Loaders';

export interface IProps {
  action: string;
}

export const Logout = (props: IProps) => {
  const [message, setMessage] = useState<string>();

  switch (props.action) {
    case LogoutActions.Logout:
      logout(getReturnUrl());
      return (
        <div>
          <Spinner />
        </div>
      );
    // break;
    case LogoutActions.LogoutCallback:
      processLogoutCallback();
      return (
        <div>
          <Spinner />
        </div>
      );
    // break;
    case LogoutActions.LoggedOut:
      break;
    default:
      throw new Error(`Invalid action '${props.action}'`);
  }

  const ReturnAction = () => {
    switch (props.action) {
      case LogoutActions.Logout:
        return (
          <div>
            <Spinner />
          </div>
        );
      case LogoutActions.LogoutCallback:
        return (
          <div>
            <Spinner />
          </div>
        );
      case LogoutActions.LoggedOut:
        navigateToLogin();
        break;
      default:
        throw new Error(`Invalid action '${props.action}'`);
    }
  };

  return <>{ReturnAction}</>;
};

const logout = async (returnUrl: string) => {
  const isAuthenticated = await authService.isAuthenticated();
  const result = await authService.signOut(returnUrl);
  if (isAuthenticated) {
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        await navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        break;
      default:
        throw new Error('Invalid authentication result status.');
    }
  } else {
    await navigateToReturnUrl(returnUrl);
  }
};

const processLogoutCallback = async () => {
  const url = window.location.href;
  const result: any = await authService.completeSignOut(url);
  switch (result.status) {
    case AuthenticationResultStatus.Redirect:
      throw new Error('Should not redirect.');
    case AuthenticationResultStatus.Success:
      await navigateToReturnUrl(getReturnUrl('/'));
      break;
    case AuthenticationResultStatus.Fail:
      break;
    default:
      throw new Error('Invalid authentication result status.');
  }
};

// const populateAuthenticationState = async () => {
//   const authenticated = await authService.isAuthenticated();
// };

const getReturnUrl = (returnUrl?: string) => {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get(QueryParameterNames.ReturnUrl);
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    throw new Error(
      'Invalid return url. The return url needs to have the same origin as the current page.'
    );
  }
  return (
    returnUrl ||
    fromQuery ||
    `${window.location.origin}${ApplicationPaths.LoggedOut}`
  );
};

const navigateToReturnUrl = (returnUrl: string) => {
  return window.location.replace(returnUrl);
};

const navigateToLogin = () => {
  return window.location.replace(
    `${window.location.origin}${ApplicationPaths.Login}`
  );
};
