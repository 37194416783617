import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';
import { PriceDetails } from '../../../models/Entities/PriceList/PriceDetail';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';
import { FontSizes, IColumn, mergeStyleSets } from '@fluentui/react';
import { PriceList } from '../../../models/Entities/PriceList/PriceList';

// Store
import * as MedicalServicesStore from '../../../redux/middleware/MedicalServicesMiddleware';
import * as PriceListStore from '../../../redux/middleware/PriceListMiddleware';
import * as PriceDetailsStore from '../../../redux/middleware/PriceDetailsMiddleware';

//#region Props

interface IProps {
  PriceListId: number;
  ServiceFilterCriteria: string | undefined;
  onEditPrice?: (item: PriceDetails) => void;
  PriceMassiveEditionIsActive: boolean;
  showPrice: boolean;
  showRemotePrice: boolean;
  showFailPrice: boolean;
  showRandomPrice: boolean;
  showAdditionalPaymentPrice: boolean;
}

interface ConnectedProps {
  IsGettingPriceListById: boolean;
  IsSavingNewPrice: boolean;
  NewPriceRecordedSuccessfully: boolean;
  FailOnGettingAllPriceList: boolean;
  FailOnGettingPriceListById: boolean;
  FailOnRecordingNewPrice: boolean;
  PriceListOperationError: ErrorType | undefined;
  IsUpdatingPrice: boolean;
  UpdatingPriceSuccessfully: boolean;
  FailOnUpdatingPrice: boolean;
  FailOnDeletingPrice: boolean;
  PriceOperationError: ErrorType | undefined;
  PriceList: PriceList | undefined;
  PriceIsChanged: boolean;

  // Medical services
  IsGettingAllMedicalServices: boolean;
  AllMedicalServicesGottenSuccessfully: boolean;
  FailOnGettingAllMedicalServices: boolean;
  MedicalServices: AvailableMedicalService[];
  MedicalServiceOperationError: ErrorType | undefined;
}

interface PriceDispatchProps {
  GetPriceListById: typeof PriceListStore.actionCreators.GetPriceListById;
  AddPriceList: typeof PriceListStore.actionCreators.AddPriceList;
  UpdatePriceList: typeof PriceListStore.actionCreators.UpdatePriceList;
  DeletePrice: typeof PriceDetailsStore.actionCreators.DeletePrice;
  ChangePrice: typeof PriceDetailsStore.actionCreators.ChangePrice;
  ChangeRemotePrice: typeof PriceDetailsStore.actionCreators.ChangeRemotePrice;
  ChangeFailPrice: typeof PriceDetailsStore.actionCreators.ChangeFailPrice;
  ChangeRandomPrice: typeof PriceDetailsStore.actionCreators.ChangeRandomPrice;
  ChangeAdditionalPaymentPrice: typeof PriceDetailsStore.actionCreators.ChangeAdditionalPaymentPrice;
  SavePrice: typeof PriceDetailsStore.actionCreators.SavePrice;
}

interface MedicalServicesDispatchProps {
  GetAllMedicalServices: typeof MedicalServicesStore.actionCreators.GetAllMedicalServices;
}

export type Props = IProps &
  ConnectedProps &
  PriceDispatchProps &
  MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  IsGettingPriceListById: state.PriceList?.failOnLoadingOne,
  FailOnGettingAllPriceList: state.PriceList?.failOnLoadingAll,
  FailOnGettingPriceListById: state.PriceList?.failOnLoadingOne,
  IsSavingNewPrice: state.PriceList?.isAddingNewOne,
  NewPriceRecordedSuccessfully: state.PriceList?.successAddingNewOne,
  FailOnRecordingNewPrice: state.PriceList?.failOnAddingNewOne,
  PriceListOperationError: state.PriceList?.error,
  IsUpdatingPrice: state.PriceList?.isUpdatingOne,
  UpdatingPriceSuccessfully: state.PriceList?.successUpdatingOne,
  FailOnUpdatingPrice: state.PriceList?.failOnUpdatingOne,
  FailOnDeletingPrice: state.PriceDetails?.failOnDeletingOne,
  PriceOperationError: state.PriceDetails?.error,
  PriceList: state.PriceList?.list.find(
    (priceList: PriceList) => priceList.id === ownProps.PriceListId
  ),
  PriceIsChanged: state.PriceList?.list.find(
    (priceList: PriceList) => priceList.id === ownProps.PriceListId
  )?.prices?.some((price: PriceDetails) => price.isChanged),
  // Medical services
  IsGettingAllMedicalServices: state.MedicalServices?.isLoadingAll,
  AllMedicalServicesGottenSuccessfully:
    state.MedicalServices?.successLoadingAll,
  MedicalServices: state.MedicalServices?.list,
  FailOnGettingAllMedicalServices: state.MedicalServices?.failOnLoadingAll,
  MedicalServiceOperationError: state.MedicalServices?.error
});

export const mapDispatchToProps = {
  ...PriceListStore.actionCreators,
  ...MedicalServicesStore.actionCreators,
  ...PriceDetailsStore.actionCreators
};

//#endregion Props

//#region GridColumns

export const _onColumnHeaderContextMenu = (
  column: IColumn | undefined,
  ev: React.MouseEvent<HTMLElement> | undefined
): void => {
  console.log(`column ${column!.key} contextmenu opened.`);
};

export const _onItemInvoked = (
  item: PriceDetails,
  index: number | undefined
): void => {
  //navigate(item.id);
};

export function _copyAndSort<T>(
  items: T[] | undefined,
  columnKey: string,
  isSortedDescending?: boolean
): T[] | undefined {
  const key = columnKey as keyof T;
  if (items)
    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
      );
}

//#endregion GridColumns

//#region Styles

export const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px'
  },
  fileOptions: {
    padding: '0px !important',
    fontSize: '12px',
    textAlign: 'left'
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden'
      }
    }
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '25px',
    maxWidth: '25px',
    fontsize: FontSizes.size16
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px'
  },
  selectionDetails: {
    marginBottom: '20px'
  }
});

//#endregion Styles
