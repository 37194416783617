import { IIconProps } from '@fluentui/react';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';
import * as jwt_decode from 'jwt-decode';

// Store
import * as SponsorStore from '../../../redux/middleware/SponsorMiddleware';
import { Sponsor } from '../../../models/Entities/Sponsor/Sponsor';

//#region Props

export interface ConnectedProps {
  SponsorId: number;
  Sponsor: Sponsor;
  OwnerId: string;
  isSponsorLoading: boolean;
  SponsorLoadedSuccessfully: boolean;
  failOnLoadingSponsor: boolean;
  SponsorOperationError: ErrorType;
}

interface DispatchProps {
    GetSponsorById: typeof SponsorStore.actionCreators.GetSponsorById;
}

export type Props = ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: any) => {

  const SponsorId: number = Number(ownProps.match.params?.SponsorId);
  const CurrentTenantId = (jwt_decode.jwtDecode(state?.oidc?.user?.access_token!) as any)?.tenantId;

  return {
    SponsorId: SponsorId,
    Sponsor: state.Sponsor?.list.find((p) => p.id === SponsorId),
    OwnerId: CurrentTenantId,
    isSponsorLoading: state.Sponsor?.isLoadingOne,
    SponsorLoadedSuccessfully: state.Sponsor?.successLoadingOne,
    failOnLoadingSponsor: state.Sponsor?.failOnLoadingOne,
    SponsorOperationError: state.Sponsor?.error
  };
};

export const mapDispatchToProps = {
    ...SponsorStore.actionCreators
};

//#endregion

//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const CreatingIcon: IIconProps = { iconName: 'Clock' };
//#endregion

// styles
// export const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
//   root: { marginTop: 10 }
// };
