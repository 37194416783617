import { AppThunkAction } from '../reducers';
import { ContractPractitionerAssociation } from '../../models/Entities/Contracts/ContractPractitionerAssociation';
import { NewContractPractitionerAssociation } from '../../models/Entities/Contracts/NewContractPractitionerAssociation';

// services
import * as Services from '../../services/PractitionerContracts.Services';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.PractitionerContracts.PractitionerContractsAdd_Request_Action
  | Actions.PractitionerContracts.PractitionerContractsAdd_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsAdd_Fail_Action
  | Actions.PractitionerContracts.PractitionerContractsUpdate_Request_Action
  | Actions.PractitionerContracts.PractitionerContractsUpdate_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsUpdate_Fail_Action
  | Actions.PractitionerContracts.PractitionerContractsDelete_Request_Action
  | Actions.PractitionerContracts.PractitionerContractsDelete_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsDelete_Fail_Action
  | Actions.PractitionerContracts.PractitionerContractsGetAll_Request_Action
  | Actions.PractitionerContracts.PractitionerContractsGetAll_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsGetAll_Fail_Action
  | Actions.PractitionerContracts.PractitionerContractsGetById_Request_Action
  | Actions.PractitionerContracts.PractitionerContractsGetById_Receive_Action
  | Actions.PractitionerContracts.PractitionerContractsGetById_Fail_Action;

export const ActionCreators = {
  getAllPractitionerContracts:
    (practitionerId: string): AppThunkAction<KnownAction> =>
    async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'PRACTITIONER_CONTRACTS_GET_ALL_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };


      dispatch({
        type: 'PRACTITIONER_CONTRACTS_GET_ALL_REQUEST_ACTION',
        practitionerId : practitionerId
      });

      try {
        const practitionerContracts = await Services.GetPractitionerContracts(
          practitionerId,
          token
        );
        dispatch({
          type: 'PRACTITIONER_CONTRACTS_GET_ALL_RECEIVE_ACTION',
          practitionerId : practitionerId,          
          practitionerContracts
        });
      } catch (error) {
        dispatch({
          type: 'PRACTITIONER_CONTRACTS_GET_ALL_FAIL_ACTION',
          error
        });
      }
    },
  getPractitionerContractById: (practitionerContractId: number): AppThunkAction<KnownAction> =>
    async (dispatch: any, getState) => {

    var state = getState();
    var token: string;

    if(state.oidc?.user?.access_token === undefined) {
        dispatch({
            type: 'PRACTITIONER_CONTRACTS_GET_BY_ID_FAIL_ACTION',
            error: { 
                ErrorCode: 401,
                ErrorMessage: 'Not authorized',
                Errors: []                     
            }
        })
        return;
    } else {
        token = state.oidc.user.access_token;
    };

    dispatch({
      type: 'PRACTITIONER_CONTRACTS_GET_BY_ID_REQUEST_ACTION',
      practitionerContractId
    });

    try {
      const practitionerContract = await Services.GetPractitionerContractById(
        practitionerContractId,
        token
      );
      dispatch({
        type: 'PRACTITIONER_CONTRACTS_GET_BY_ID_RECEIVE_ACTION',
        practitionerContract
      });
    } catch (error) {
      dispatch({
        type: 'PRACTITIONER_CONTRACTS_GET_BY_ID_FAIL_ACTION',
        error
      });
    }
  },
  addPractitionerContract:
    (practitionerContract: NewContractPractitionerAssociation): AppThunkAction<KnownAction> =>
      async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'PRACTITIONER_CONTRACTS_ADD_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };


      dispatch({
        type: 'PRACTITIONER_CONTRACTS_ADD_REQUEST_ACTION',
        practitionerContract
      });

      try {
        const newPractitionerContract =
          await Services.CreatePractitionerContract(practitionerContract, token);
        dispatch({
          type: 'PRACTITIONER_CONTRACTS_ADD_RECEIVE_ACTION',
          newPractitionerContract
        });
      } catch (error) {
        dispatch({
          type: 'PRACTITIONER_CONTRACTS_ADD_FAIL_ACTION',
          error
        });
      }
    },
  updatePractitionerContract:
    (practitionerContract: ContractPractitionerAssociation): AppThunkAction<KnownAction> =>
      async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if(state.oidc?.user?.access_token === undefined) {
          dispatch({
              type: 'PRACTITIONER_CONTRACTS_UPDATE_FAIL_ACTION',
              error: { 
                  ErrorCode: 401,
                  ErrorMessage: 'Not authorized',
                  Errors: []                     
              }
          })
          return;
      } else {
          token = state.oidc.user.access_token;
      };

      dispatch({
        type: 'PRACTITIONER_CONTRACTS_UPDATE_REQUEST_ACTION',
        practitionerContract
      });

      try {
        const updatedPractitionerContract =
          await Services.UpdatePractitionerContract(practitionerContract, token);
        dispatch({
          type: 'PRACTITIONER_CONTRACTS_UPDATE_RECEIVE_ACTION',
          updatedPractitionerContract
        });
      } catch (error) {
        dispatch({
          type: 'PRACTITIONER_CONTRACTS_UPDATE_FAIL_ACTION',
          error
        });
      }
    },
  deletePractitionerContract: (practitionerContractId: number): AppThunkAction<KnownAction> =>
    async (dispatch: any, getState) => {

    var state = getState();
    var token: string;

    if(state.oidc?.user?.access_token === undefined) {
        dispatch({
            type: 'PRACTITIONER_CONTRACTS_DELETE_FAIL_ACTION',
            error: { 
                ErrorCode: 401,
                ErrorMessage: 'Not authorized',
                Errors: []                     
            }
        })
        return;
    } else {
        token = state.oidc.user.access_token;
    };

    dispatch({
      type: 'PRACTITIONER_CONTRACTS_DELETE_REQUEST_ACTION',
      practitionerContractId
    });

    try {
      const deletedPractitionerContract =
        await Services.DeletePractitionerContract(practitionerContractId, token);
      dispatch({
        type: 'PRACTITIONER_CONTRACTS_DELETE_RECEIVE_ACTION',
        deletedPractitionerContract
      });
    } catch (error) {
      dispatch({
        type: 'PRACTITIONER_CONTRACTS_DELETE_FAIL_ACTION',
        error
      });
    }
  }
};
