import * as React from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { NormalPeoplePicker } from '@fluentui/react/lib/Pickers';

// ownFunctions
import * as Functions from './SponsorSelectorFunctions';
import { Sponsor } from '../../../../models/Entities/Sponsor/Sponsor';
import { Person } from '../../../../models/Entities/Person/Person';

export interface ISponsorSelectorProps {
  selectionLimit: number;
  onChangeSelection?: (selectedPractitioners: Sponsor | Sponsor[]) => void;
}

export const SponsorSelectorComponent = (props: ISponsorSelectorProps) => {
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<
    IPersonaProps[]
  >([]);
  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>([]);

  const picker = React.useRef(null);

  const onChangeSelection = (items?: IPersonaProps[] | undefined) => {
    if (props.onChangeSelection) {
      items?.map((sponsor: IPersonaProps) => {
        return {
          person: {
            businessName: sponsor.text,
            id: sponsor.key,
          } as Person,
          id: sponsor.key,
        } as Sponsor;
      });
    }
  };

  const filterPersonasByText = (
    filterText: string
  ): IPersonaProps[] => {
    // return SponsorService.GetSponsors(1, 1000, '')
    //   .then((data: Sponsor[]) => {
    //     return data
    //       .map((item: Sponsor) => {
    //         return {
    //           key: item.id,
    //           imageInitials: CommonFunctions.getNameInitials(item.fullName),
    //           text: item.fullName,
    //           secondaryText: 'Sponsor'
    //         } as IPersonaProps;
    //       })
    //       .filter((item: IPersonaProps) =>
    //         doesTextStartWith(item.text as string, filterText)
    //       );
    //   })
    //   .catch((error: any) => {
    //     return [] as IPersonaProps[];
    //   });

    return [] as IPersonaProps[];
  };

  const onFilterChanged = (
    filterText: string,
    currentSponsor?: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    // if (filterText) {
    //   let filteredSponsor: Promise<IPersonaProps[]> =
    //     filterPersonasByText(filterText);
  
    //   return filteredSponsor.then((sponsors: IPersonaProps[]) => {
    //     let SponsorList = removeDuplicates(sponsors, currentSponsor || []);
    //     SponsorList = limitResults
    //       ? SponsorList.slice(0, limitResults)
    //       : SponsorList;
    //     return SponsorList || [];
    //   });
    // } else {
    //   return [];
    // }

    return [];
  };

  return (
    <div>
      <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={Functions.getTextFromItem}
        pickerSuggestionsProps={Functions.suggestionProps}
        className={'ms-PeoplePicker'}
        key={'normal'}
        onValidateInput={Functions.validateInput}
        selectionAriaLabel={'Selected contacts'}
        removeButtonAriaLabel={'Remove'}
        componentRef={picker}
        itemLimit={props.selectionLimit}
        //onInputChange={onInputChange}
        onChange={onChangeSelection}
      />
    </div>
  );
};
