import { ProtocolPractitioner } from "../../models/Entities/Protocols/ProtocolPractitioner";
import { CommonState } from "../common/state/commonState";

export interface ProtocolPractitionersState
  extends CommonState<ProtocolPractitioner> {}

export const ProtocolPractitionersUnloadState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,
    
    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,
    
    list: [],
    Selected: undefined,
    Error: undefined
}