import { IIconProps } from "@fluentui/react";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";
import { ErrorType } from "../../../models/HttpError";

// stores
import * as MedicalProtocolStore from '../../../redux/middleware/MedicalProtocolMiddleware';
import * as PractitionersStore from '../../../redux/middleware/PractitionerMiddleware';

//State
import { AppState } from "../../../redux/reducers";
import { Practitioner } from "../../../models/Entities/Practitioners/Practitioner";


interface IProps {
  SponsorId?: number;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  MedicalProtocols: Protocol[];
  GetPractiontioner: (id: string) => Practitioner | undefined; 
}

interface MedicalServicesDispatchProps {
  GetAllMedicalProtocols: typeof MedicalProtocolStore.actionCreators.GetAllMedicalProtocols;
  GetMedicalProtocolById: typeof MedicalProtocolStore.actionCreators.GetMedicalProtocolById;
  GetAllPractitioners: typeof PractitionersStore.actionCreators.GetAllPractitioners;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.MedicalProtocols?.isLoadingAll,
  isUpdating: state.MedicalProtocols?.isLoadingAll,
  isUpdatedSuccessfully: state.MedicalProtocols?.successLoadingAll,
  isLoadingSuccessfully: state.MedicalProtocols?.successLoadingAll,
  failOnUpdating: state.MedicalProtocols?.failOnLoadingAll,
  failOnLoading: state.MedicalProtocols?.failOnLoadingAll,
  isDeleting: state.MedicalProtocols?.isDeletingOne,
  isDeletedSuccessfully: state.MedicalProtocols?.successDeletingOne,
  failOnDeleting: state.MedicalProtocols?.failOnDeletingOne,
  error: state.MedicalProtocols?.error,
  MedicalProtocols: ownProps.SponsorId ? state.MedicalProtocols?.list?.filter((item: Protocol) => item.laboratoryId === ownProps.SponsorId).sort(
    (a: Protocol, b: Protocol) => a.title.localeCompare(b.title)
  ) : state.MedicalProtocols?.list,
  GetPractiontioner: (id: string) => { 
    var practitioner = state.Practitioner?.list?.find((practitioner: Practitioner) => practitioner.userId === id);
    return practitioner;
  }
});

export const mapDispatchToProps = {
  ...MedicalProtocolStore.actionCreators,
  ...PractitionersStore.actionCreators
};

//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'StackIndicator' };
