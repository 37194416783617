// Functions
import * as Functions from '../functions/common';
import AuthorizeService from '../components/api-authorization/AuthorizeService';

// Error Tracer
import * as Sentry from '@sentry/react';
import { IResult } from '../models/HttpResult';
import { NewSponsor } from '../models/Entities/Sponsor/NewSponsor';
import { Sponsor } from '../models/Entities/Sponsor/Sponsor';
import { SponsorContract } from '../models/Entities/Sponsor/SponsorContracts';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'sponsors';

export const AddSponsor = async (newSponsor: NewSponsor, token: string): Promise<Sponsor> => {
  let RequestURL: string = BaseURL;

  try {
    const Response: Sponsor = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(newSponsor)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Sponsor>>;
        }
      })
      .then((data: IResult<Sponsor>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const SaveSponsor = async (provider: Sponsor, token: string): Promise<Sponsor> => {
  let RequestURL: string = BaseURL;

  try {
    const Response: Sponsor = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(provider)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Sponsor>>;
        }
      })
      .then((data: IResult<Sponsor>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetSponsor = async (id: number, token: string): Promise<Sponsor> => {
  let RequestURL: string = BaseURL + '/' + id;

  try {
    const Response: Sponsor = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Sponsor>>;
        }
      })
      .then((data: IResult<Sponsor>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetSponsors = async (
  pageNumber: number,
  pageSize: number,
  searchText: string | undefined
, token: string): Promise<Sponsor[]> => {
  let RequestURL: string = BaseURL;

  let params: URLSearchParams = Functions.GetQueryStringParams(
    pageNumber,
    pageSize,
    searchText
  );

  if (params) RequestURL += '?' + params.toString();

  try {
    const Response: Sponsor[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Sponsor[]>>;
        }
      })
      .then((data: IResult<Sponsor[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AssociateContract = async (
  SponsorId: number,
  ContractId: number
, token: string): Promise<SponsorContract> => {
  
  let RequestURL: string = BaseURL + '/contracts/contracts';

  try {
    const Response: SponsorContract = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify({"SponsorId": SponsorId, "ContractId": ContractId})
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<SponsorContract>>;
        }
      })
      .then((data: IResult<SponsorContract>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DisassociateContract = async (
  SponsorContractId: number
, token: string): Promise<any> => {
  
  let RequestURL: string = BaseURL + '/contracts/contracts/' + SponsorContractId;

  try {
    const Response: SponsorContract = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<any>>;
        }
      })
      .then((data: IResult<any>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetSponsorContracts = async (
  SponsorId: number
, token: string): Promise<SponsorContract[]> => {
  let RequestURL: string = BaseURL + '/contracts/' + SponsorId + '/contracts';

  try {
    const Response: SponsorContract[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<SponsorContract[]>>;
        }
      })
      .then((data: IResult<SponsorContract[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const SyncSponsor = async (
  token: string
): Promise<any> => {
  
  let RequestURL: string = BaseURL + '/resync';

  try {
    const Response: any = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<any>>;
        }
      })
      .then((data: IResult<any>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
}
