import {
  getFocusStyle,
  getTheme,
  IIconProps,
  ITheme,
  mergeStyleSets
} from '@fluentui/react';
import { MedicalInsuranceAgreementsPriceList } from '../../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreementsPriceList';
import { AppState } from '../../../redux/reducers';
import { ErrorType } from '../../../models/HttpError';

//store
import * as PriceListStore from '../../../redux/middleware/PriceListMiddleware';
import { IAssociatedPriceList } from '../../../models/Entities/PriceList/IAssociatedPriceList';

export interface IProps {
  priceList: MedicalInsuranceAgreementsPriceList[];
  onSelectPriceList?: (PriceList: MedicalInsuranceAgreementsPriceList) => void;
}

export interface ConnectedProps {
  isLoading: boolean;
  LoadingSuccess: boolean;
  failOnLoading: boolean;
  error: ErrorType;
}

export type Props = IProps & ConnectedProps;

//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
//#endregion

export type Validity = {
  status: string;
  message: string;
  endValidity: Date | undefined;
};

export function GetPriceListStatus(priceList: IAssociatedPriceList) {
  const today = new Date();
  const endValidity: Date | undefined = priceList.effectiveDateEnd
    ? new Date(priceList.effectiveDateEnd)
    : undefined;
  const startValidity: Date | undefined = priceList.effectiveDateStart
    ? new Date(priceList.effectiveDateStart)
    : undefined;
  if (!endValidity && !startValidity) {
    return {
      status: 'NoValidity',
      message: 'No Validity',
      endValidity: undefined
    };
  }
  if (endValidity && today > endValidity) {
    return {
      status: 'Expired',
      message: 'Expired',
      endValidity: endValidity
    };
  } else if (startValidity && today < startValidity) {
    return {
      status: 'Pending',
      message: 'Pending',
      endValidity: endValidity
    };
  } else {
    return {
      status: 'Active',
      message: 'Active',
      endValidity: endValidity
    };
  }
}

// Redux state connector
export const mapStateToProps = (state: AppState) => ({
  ...state.PriceList
});

export const mapDispatchToProps = {
  ...PriceListStore.actionCreators
};

// Styles
const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

export const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      cursor: 'pointer',
      padding: 10,
      boxSizing: 'border-box',
      borderRadius: 5,
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: 'flex',
      selectors: {
        '&:hover': { background: palette.neutralLighter }
      }
    }
  ],
  itemSelected: {
    minHeight: 54,
    cursor: 'pointer',
    padding: 10,
    boxSizing: 'border-box',
    borderRadius: 5,
    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
    background: palette.neutralLight,
    display: 'flex'
  },
  itemImage: {
    flexShrink: 0
  },
  itemContent: {
    marginLeft: 10,
    overflow: 'hidden',
    flexGrow: 1
  },
  itemName: [
    fonts.xLarge,
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10
  },
  chevron: {
    alignSelf: 'center',
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0
  },
  listIcon: {
    alignSelf: 'center',
    marginLeft: 5,
    color: palette.neutralTertiary,
    fontSize: 50,
    flexShrink: 0
  }
});
