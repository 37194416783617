import React from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { FontIcon, IconButton, Label, Separator } from '@fluentui/react';

//component
import PractitionerSettingsForm from '../PractitionerSettingsForm/PractitionerSettingsForm.Component';
import { PractitionerDetailsMini } from "../../../../commons/component/Practitioner/PractitionerDetails/PractitionerDetails.Mini";


// models
import { ProtocolPractitioner } from "../../../../models/Entities/Protocols/ProtocolPractitioner";

// styles
import './ProtocolPractitioner.style.css';

import { Practitioner } from "../../../../models/Entities/Practitioners/Practitioner";
import { ProtocolPractitionerStudyTask } from "../../../../models/Entities/Protocols/ProtocolPractitionerStudyTask";
import { ProtocolPractitionerStudyRole } from '../../../../models/Entities/Protocols/ProtocolPractitionerStudyRol';
import { Protocol } from '../../../../models/Entities/Protocols/Protocol';

//i18Next
import { useTranslation } from 'react-i18next';



export interface IProps {
    practitioner: ProtocolPractitionerStudyRole;
    Protocol: Protocol;
    contractId: number;
    GetPractiontioner: (practitionerId: string) => Practitioner | undefined;
}


export const ProtocolPractitionerItemComponent = (props: IProps) => {

    //useTranslation()
    const [t] = useTranslation();

    // Add or eddit Concept
    const [isOpenSettingForm, { setTrue: openSettingFormPanel, setFalse: dismissSettingFormPanel }] = useBoolean(false);

    const forms = props.practitioner && (
        <>
            <PractitionerSettingsForm
                ProtocolId={props.practitioner.protocolId}
                Practitioner={props.practitioner}
                ContractId={props.contractId}
                isOpen={isOpenSettingForm}
                openPanel={openSettingFormPanel}
                dismissPanel={dismissSettingFormPanel}
            />
        </>
    );

    return (
        <>
            <div onClick={openSettingFormPanel} className={props.practitioner.practitionerUserId === props.Protocol.principalInvestigatorId ? t('protocolPractitionerlist.practitioner.primaryMedicalTeamCard') : t('protocolPractitionerlist.practitioner.medicalTeamCard')}>
                <div className='PractitionerInfo'>
                    <Label>{props.Protocol.principalInvestigatorId === props.practitioner.practitionerUserId && <FontIcon aria-label="Compass" iconName="FavoriteStarFill" />}&nbsp;{props.practitioner.studyRole.name}</Label>
                    <Separator vertical style={{ height: '2em' }} />
                    <div>
                        <PractitionerDetailsMini practitioner={props.GetPractiontioner(props.practitioner.practitionerUserId)} />
                    </div>
                    <div className='MedicalAcctionsSector'>
                        <IconButton
                            iconProps={{ iconName: 'Settings' }}
                            title={t('protocolPractitionerlist.practitioner.settings')}
                            ariaLabel={t('protocolPractitionerlist.practitioner.settings')}
                            onClick={() => props.GetPractiontioner(props.practitioner.practitionerUserId)}
                        />
                    </div>
                </div>
            </div>
            {forms}
        </>
    );
}