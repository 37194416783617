import { ErrorType } from "../../../models/HttpError";
import { Settlement } from "../../../models/Entities/Settlements/Settlement";
import { SettlementResume } from "../../../models/Entities/SettlementResume/SettlementResume";

// stores
import * as SettlementResumeStore from '../../../redux/middleware/SettlementResumeMiddleware';
import * as SettlementsStore from '../../../redux/middleware/SettlementMiddleware';

//State
import { AppState } from "../../../redux/reducers";
import { FontWeights, IIconProps, IStackProps, getTheme, mergeStyleSets } from "@fluentui/react";

interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    SettlementId: number;
    SettlementResumeId?: number;
}
  
interface ConnectedProps {
    isAdding: boolean;
    isSuccessfullyAdded: boolean;
    failOnAdding: boolean;
    isUpdating: boolean;
    isSuccessfullyUpdated: boolean;
    failOnUpdating: boolean;
    error: ErrorType | undefined;
    Settlement: Settlement | undefined;
    SettlementResume: SettlementResume | undefined;
}

interface SettlementDispatchProps {
    AddSettlementResume: typeof SettlementResumeStore.actionCreators.AddSettlementResume;
    UpdateSettlementResume: typeof SettlementResumeStore.actionCreators.UpdateSettlementResume;
}

export type Props = IProps & ConnectedProps & SettlementDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    return {
        //adding
        isAdding: state.SettlementResume?.isAddingNewOne,
        isSuccessfullyAdded: state.SettlementResume?.successAddingNewOne,
        failOnAdding: state.SettlementResume?.failOnAddingNewOne,
        
        // updating
        isUpdating: state.SettlementResume?.isUpdatingOne,
        isSuccessfullyUpdated: state.SettlementResume?.successUpdatingOne,
        failOnUpdating: state.SettlementResume?.failOnUpdatingOne,

        error: state.SettlementResume?.error,
        
        Settlement: state.Settlement?.list?.find((settlement: Settlement) => settlement.id === ownProps.SettlementId ),
        SettlementResume: state.Settlement?.list?.find((settlement: Settlement) => settlement.id === ownProps.SettlementId )?.resumes?.find((settlementResume: SettlementResume) => settlementResume.id === ownProps.SettlementResumeId ) 
    }
};

export const mapDispatchToProps = {
  ...SettlementResumeStore.actionCreators,
  ...SettlementsStore.actionCreators
};


// styles 
// styles
export const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

export const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

export const theme = getTheme();
export const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto'
  }
});

export const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
