import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

// states
import { CashflowDebtMovementState, CashFlowDebtMovementUnloadState } from '../states/CashFlow.DebtMovement.State';

type KnownAction =
    | Actions.CashFlowDebtMovements.CashFlowDebtMovementGetAllByDebtIdRequestAction
    | Actions.CashFlowDebtMovements.CashFlowDebtMovementGetAllByDebtIdSuccessAction
    | Actions.CashFlowDebtMovements.CashFlowDebtMovementGetAllByDebtIdFailureAction
    | Actions.CashFlowDebtMovements.CashFlowDebtMovementGetByIdRequestAction
    | Actions.CashFlowDebtMovements.CashFlowDebtMovementGetByIdSuccessAction
    | Actions.CashFlowDebtMovements.CashFlowDebtMovementGetByIdFailureAction
    | Actions.CashFlowDebtMovements.CashFlowDebtMovementCreateRequestAction
    | Actions.CashFlowDebtMovements.CashFlowDebtMovementCreateSuccessAction
    | Actions.CashFlowDebtMovements.CashFlowDebtMovementCreateFailureAction;

export const CashFlowDebtMovementsReducer: Reducer<CashflowDebtMovementState> = (
    state: CashflowDebtMovementState | undefined,
    incomingAction: Action
): CashflowDebtMovementState => {
    if (state === undefined) {
        return CashFlowDebtMovementUnloadState as CashflowDebtMovementState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CASHFLOW_DEBTMOVEMENT_GETALL_BYDEBTID_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CASHFLOW_DEBTMOVEMENT_GETALL_BYDEBTID_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CASHFLOW_DEBTMOVEMENT_GETALL_BYDEBTID_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CASHFLOW_DEBTMOVEMENT_GET_BYID_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: undefined,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CASHFLOW_DEBTMOVEMENT_GET_BYID_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CASHFLOW_DEBTMOVEMENT_GET_BYID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CASHFLOW_DEBTMOVEMENT_CREATE_REQUEST':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CASHFLOW_DEBTMOVEMENT_CREATE_SUCCESS':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CASHFLOW_DEBTMOVEMENT_CREATE_FAILURE':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        default:
            return state;
    }
}