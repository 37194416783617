import { IIconProps } from "@fluentui/react";
import { ErrorType } from "../../../models/HttpError";
import { Sponsor } from "../../../models/Entities/Sponsor/Sponsor";


// stores
import * as SponsorStore from '../../../redux/middleware/SponsorMiddleware';

//State
import { AppState } from "../../../redux/reducers";



//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };
export const RefreshIcon: IIconProps = { iconName: 'Refresh' };

interface IProps {}

interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    isSyncing: boolean;
    isSyncingSuccess: boolean | undefined;
    failSyncing: boolean;
    error: ErrorType | undefined;
    Sponsors: Sponsor[];
}

interface MedicalServicesDispatchProps {
    GetAllSponsors: typeof SponsorStore.actionCreators.GetAllSponsors;
    GetSponsorById: typeof SponsorStore.actionCreators.GetSponsorById;
    SyncSponsor: typeof SponsorStore.actionCreators.SyncSponsor;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.Sponsor?.isLoadingAll,
    isUpdating: state.Sponsor?.isLoadingAll,
    isUpdatedSuccessfully: state.Sponsor?.successLoadingAll,
    isLoadingSuccessfully: state.Sponsor?.successLoadingAll,
    failOnUpdating: state.Sponsor?.failOnLoadingAll,
    failOnLoading: state.Sponsor?.failOnLoadingAll,
    isDeleting: state.Sponsor?.isDeletingOne,
    isDeletedSuccessfully: state.Sponsor?.successDeletingOne,
    failOnDeleting: state.Sponsor?.failOnDeletingOne,
    isSyncing: state.Sponsor?.isSyncing,
    isSyncingSuccess: state.Sponsor?.isSyncingSuccess,
    failSyncing: state.Sponsor?.FailSyncing,
    error: state.Sponsor?.error,
    MedicalProtocols: state.Sponsor?.list,
    Sponsors: state.Sponsor?.list?.sort((a, b) => a.person.businessName.localeCompare(b.person.businessName))
});

export const mapDispatchToProps = {
    ...SponsorStore.actionCreators
};