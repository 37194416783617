import { connect } from 'react-redux';
import { Text, Avatar, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Label, Spinner, TableCellLayout, TableColumnDefinition, Toolbar, ToolbarButton, ToolbarDivider, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, Button, TeachingPopover, TeachingPopoverTrigger, TeachingPopoverSurface, TeachingPopoverHeader, TeachingPopoverBody, TeachingPopoverFooter, TeachingPopoverTitle } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { Separator } from '@fluentui/react';

// styles
import './Debts.List.Styles.css';

//icons 
import {
    Add16Regular,
    EditRegular,
    DeleteRegular,
    Comment20Regular
} from "@fluentui/react-icons";

// Component functions
import * as ComponentFunctions from './Debts.List.Functions';

// models
import { Debt } from '../../../../models/Entities/CashFlow/Debt/Debt';

// components
//import PaymentFormComponent from '../Debts.Form/Payment.Form.Component';
//import { FormType } from '../Debts.Form/Payment.Form.Function';
import moment from 'moment';
import DebtFormComponent from '../Debt.Form/Debt.Form.Component';
import { FormType } from '../Debt.Form/Debt.Form.Functions';

const DebtListComponent = (props: ComponentFunctions.Props) => {

    // add or update form controll
    const [isDebtFormOpen, setIsDebtFormOpen] = useState(false);

    const [selectedDebt, setSelectedDebt] = useState<Debt | null>(null);

    // events    

    // on close form
    const onDebtFormDismiss = () => {
        setSelectedDebt(null);
        setIsDebtFormOpen(false);
    }

    // on edit payment
    const onEditDebt = (debt: Debt) => {
        //setSelectedPayment(payment);
        //setIsPaymentFormOpen(true);
    }

    // on delete payment
    const onDeleteDebt = (debt: Debt) => {
        //props.DeletePayment(payment.id);
    }

    useEffect(() => {
        props.GetAllPersons();
        switch (props.type) {
            case ComponentFunctions.DebtListType.Debtor:
                props.GetAllDebtsByDebtor(props.EntityIdentifier, props.FixedAssociatedEntityIdentifier);
                break;
            case ComponentFunctions.DebtListType.Creditor:
                props.GetAllDebtsByCreditor(props.EntityIdentifier, props.FixedAssociatedEntityIdentifier);
                break;
        }
    }, []);

    const Forms = (
        <>
            <DebtFormComponent
                FormType={props.type === ComponentFunctions.DebtListType.Creditor ? FormType.Creditor : FormType.Debtor}
                ReferalId={props.EntityIdentifier}
                FixedAssociatedEntityIdentifier={props.FixedAssociatedEntityIdentifier}
                isOpen={isDebtFormOpen}
                DebtId={selectedDebt?.id}
                onClose={onDebtFormDismiss}
            />
        </>
    );

    const columns: TableColumnDefinition<Debt>[] = [
        createTableColumn<Debt>({
            columnId: "debtor",
            compare: (a, b) => {
                return props.GetPersonByIdentifier(a.creditor)!.businessName!.localeCompare(props.GetPersonByIdentifier(b.creditor)!.businessName!);
            },
            renderHeaderCell: () => {
                return "Deudor";
            },
            renderCell: (item: Debt) => {
                return (
                    <TableCellLayout media={(
                        <Avatar
                            aria-label={item.debtor}
                            name={props.GetPersonByIdentifier(item.debtor)?.businessName}
                            color="colorful"
                        />
                    )}>
                        {props.GetPersonByIdentifier(item.debtor)?.businessName}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Debt>({
            columnId: "date",
            compare: (a, b) => {
                return new Date(a.debtDate).getTime() - new Date(b.debtDate).getTime();
            },
            renderHeaderCell: () => {
                return "Fecha";
            },
            renderCell: (item: Debt) => {
                return (
                    <TableCellLayout>
                        {moment(item.debtDate).format("DD/MM/YYYY")}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<Debt>({
            columnId: "creditor",
            compare: (a, b) => {
                return a.creditor.localeCompare(b.creditor);
            },
            renderHeaderCell: () => {
                return "Acreedor";
            },
            renderCell: (item: Debt) => {
                return (
                    <TableCellLayout media={(
                        <Avatar
                            aria-label={item.creditor}
                            name={props.GetPersonByIdentifier(item.creditor)?.businessName}
                            color="colorful"
                        />
                    )}>
                        {props.GetPersonByIdentifier(item.creditor)?.businessName}
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Debt>({
            columnId: "originaAmount",
            compare: (a, b) => {
                return (a.originalAmount - b.originalAmount);
            },
            renderHeaderCell: () => {
                return "Monto";
            },
            renderCell: (item: Debt) => {
                return (
                    <TableCellLayout>
                        <strong>{item.originalAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</strong>
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Debt>({
            columnId: "paidAmount",
            compare: (a, b) => {
                return (a.paidAmount - b.originalAmount);
            },
            renderHeaderCell: () => {
                return "Pagado";
            },
            renderCell: (item: Debt) => {
                return (
                    <TableCellLayout>
                        <strong>{item.paidAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</strong>
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Debt>({
            columnId: "pendingAmount",
            compare: (a, b) => {
                return (a.paidAmount - b.originalAmount);
            },
            renderHeaderCell: () => {
                return "Pendiente";
            },
            renderCell: (item: Debt) => {
                return (
                    <TableCellLayout>
                        <strong>{item.pendingAmount?.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode?.toUpperCase() }) || '0.00'}</strong>
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Debt>({
            columnId: "comments",
            compare: (a, b) => {
                return (a.comments || '').localeCompare(b.comments || '');
            },
            renderHeaderCell: () => {
                return "Comentarios";
            },
            renderCell: (item: Debt) => {
                return (
                    <TableCellLayout>
                        <TeachingPopover>
                            <TeachingPopoverTrigger>
                                <Button appearance="transparent" icon={<Comment20Regular />}  onClick={(e) => e.stopPropagation()} >ver</Button>
                            </TeachingPopoverTrigger>
                            <TeachingPopoverSurface>
                                <TeachingPopoverHeader>Comentarios</TeachingPopoverHeader>
                                <TeachingPopoverBody>
                                    <Text>{item.comments}</Text>
                                </TeachingPopoverBody>
                            </TeachingPopoverSurface>
                        </TeachingPopover>
                        
                    </TableCellLayout>
                )
            },
        }),
        createTableColumn<Debt>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "Actions";
            },
            renderCell: (item: Debt) => {
                return (
                    <>
                        <Button appearance="subtle" aria-label="Edit" icon={<EditRegular />} onClick={(event) => { event.stopPropagation(); onEditDebt(item) }} />
                        <Button appearance="subtle" aria-label="Delete" icon={<DeleteRegular />} onClick={(event) => { event.stopPropagation(); onDeleteDebt(item) }} />
                    </>
                );
            },
        }),
    ];


    return (
        <>
            <Toolbar aria-label="Default" {...props}>
                <ToolbarButton
                    aria-label="Increase Font Size"
                    appearance="primary"
                    icon={<Add16Regular />}
                    onClick={() => { setIsDebtFormOpen(true) }}

                >Agregar</ToolbarButton>
            </Toolbar>
            <Separator />
            <DataGrid
                items={props.Debts}
                columns={columns}
                sortable
                selectionMode="multiselect"
                getRowId={(item) => item.id}
                focusMode="composite"
                style={{ minWidth: "550px" }}
            >
                <DataGridHeader>
                    <DataGridRow
                        selectionCell={{
                            checkboxIndicator: { "aria-label": "Select all rows" },
                        }}
                    >
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<Debt>>
                    {({ item, rowId }) => (
                        <DataGridRow<Debt>
                            key={rowId}
                            selectionCell={{
                                checkboxIndicator: { "aria-label": "Select row" },
                            }}
                        >
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
            {props.isLoading && (<div className='LoadingPayments'>
                <Spinner size="tiny" label="Cargando pagos..." />
            </div>)}
            {props.isLoadingSuccessfully && props.Debts?.length === 0 && (
                <div className='NoDataContainer'>
                    <img
                        src={require('../../../../assets/img/nodata.png')}
                        alt="No Data"
                        style={{ height: '6em', marginBottom: '.5em' }}
                    ></img>
                    <Text className='NoData'>No hay pendientes registrados</Text>
                    {/* <PrimaryButton text="Agregar pago" /> */}
                </div>
            )}
            {Forms}
        </>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(DebtListComponent as any);
