import { NewAgreementServiceDetail } from './../models/Entities/AgreementServices/NewAgreementServiceDetail';
import { AgreementServicesDetail } from './../models/Entities/AgreementServices/AgreementServicesDetail';
import { IResult } from '../models/HttpResult';


// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

const BaseURL =
  process.env.REACT_APP_BILLER_API_GATEWAY + 'agreements/services/';

export const GetServiceDetailsByAgreementId = async (
  agreementId: number
, token: string): Promise<AgreementServicesDetail[]> => {

  let RequestURL: string = BaseURL + agreementId + '/details';

  try {
    const Response: AgreementServicesDetail[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AgreementServicesDetail[]>>;
        }
      })
      .then((data: IResult<AgreementServicesDetail[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetAgreementServicesDetailsById = async (
  id: number
, token: string): Promise<AgreementServicesDetail> => {

  let RequestURL: string = BaseURL + 'details/' + id;

  try {
  
    const Response: AgreementServicesDetail = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AgreementServicesDetail>>;
        }
      })
      .then((data: IResult<AgreementServicesDetail>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const CreateAgreementServicesDetail = async (
  newAgreementServicesDetail: NewAgreementServiceDetail
, token: string): Promise<AgreementServicesDetail> => {

  let RequestURL: string = BaseURL + 'details';

  try {
    
    const Response: AgreementServicesDetail = await fetch(RequestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(newAgreementServicesDetail)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AgreementServicesDetail>>;
        }
      })
      .then((data: IResult<AgreementServicesDetail>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const UpdateAgreementServicesDetail = async (
  agreementServicesDetail: AgreementServicesDetail
, token: string): Promise<AgreementServicesDetail> => {

  let RequestURL: string = BaseURL + 'details/';
  
  try {
    const Response: AgreementServicesDetail = await fetch(RequestURL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(agreementServicesDetail)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<AgreementServicesDetail>>;
        }
      })
      .then((data: IResult<AgreementServicesDetail>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
