import { IIconProps } from '@fluentui/react';
import { Provider } from '../../../models/Entities/Provider/Provider';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';
import * as jwt_decode from 'jwt-decode';

// Store
import * as ProviderAssociationsStore from '../../../redux/middleware/ProviderPriceListAssociationMiddleware';
import * as ProviderStore from '../../../redux/middleware/ProviderMiddleware';

//#region Props

export interface ConnectedProps {
  ProviderId: number;
  Provider: Provider;
  OwnerId: string;
  isProviderLoading: boolean;
  ProviderLoadedSuccessfully: boolean;
  failOnLoadingProvider: boolean;
  ProviderOperationError: ErrorType;
  isDeletingPriceList: boolean;
  PriceListDeleted: boolean;
  failOnDeletePriceList: boolean;
  DeletePriceListError: ErrorType | undefined;
  isAddingPriceList: boolean;
  isLoadingProviderPriceList: boolean;
  ProviderPriceListLoadedSuccessfully: boolean;
}

interface DispatchProps {
  GetProviderById: typeof ProviderStore.actionCreators.GetProviderById;
  AddPriceList: typeof ProviderAssociationsStore.actionCreators.AddPriceList;
  DeletePriceListById: typeof ProviderAssociationsStore.actionCreators.DeletePriceListById;
  CloneProviderPriceList: typeof ProviderAssociationsStore.actionCreators.CloneProviderPriceList;
  GetPriceListByProviderId: typeof ProviderAssociationsStore.actionCreators.GetPriceListByProviderId;
}

export type Props = ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: any) => {

  const ProviderId: number = Number(ownProps.match.params?.ProviderId);
  const CurrentTenantId = (jwt_decode.jwtDecode(state?.oidc?.user?.access_token!) as any)?.tenantId;


  return {
    ProviderId: ProviderId,
    Provider: state.Provider?.list.find((p) => p.id === ProviderId),
    OwnerId: CurrentTenantId,
    //states
    isProviderLoading: state.Provider?.isLoadingOne,
    ProviderLoadedSuccessfully: state.Provider?.isLoadingOne,
    failOnLoadingProvider: state.Provider?.failOnLoadingOne,
    ProviderOperationError: state.Provider?.error,
    failOnLoadingProviderPriceList:
      state.ProviderPriceListAssociations?.failOnLoadingOne,
    ProviderPriceListOperationError: state.ProviderPriceListAssociations?.error,
    isDeletingPriceList: state.ProviderPriceListAssociations?.isDeletingOne,
    PriceListDeleted: state.ProviderPriceListAssociations?.successDeletingOne,
    failOnDeletePriceList:
      state.ProviderPriceListAssociations?.failOnDeletingOne,
    DeletePriceListError: state.ProviderPriceListAssociations?.error,
    isAddingPriceList: state.ProviderPriceListAssociations?.isAddingNewOne,
    isLoadingProviderPriceList: state.ProviderPriceListAssociations?.isLoadingAll,
    ProviderPriceListLoadedSuccessfully: state.ProviderPriceListAssociations?.successLoadingAll
  };
};

export const mapDispatchToProps = {
    ...ProviderAssociationsStore.actionCreators,
    ...ProviderStore.actionCreators
};

//#endregion

//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const CreatingIcon: IIconProps = { iconName: 'Clock' };
//#endregion

// styles
// export const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
//   root: { marginTop: 10 }
// };
