import { Pivot, PivotItem, PrimaryButton, Separator } from '@fluentui/react';
import { connect } from 'react-redux';
import { HeaderPersonaComponent } from '../../../commons/component/headers/HeaderPersona/HeaderPersonaComponent';
import { PageHeader } from '../../../commons/component/PageHeader/PageHeader';
import ProviderMedicalServiceAssociatedComponent from '../../../components/Providers/ProviderMedicalServiceAssociated/ProviderMedicalServiceAssociatedComponent';
import { PriceListHeaderComponent } from '../../../components/PriceList/PriceListHeader/PriceListHeaderComponent';
import PricesListComponent from '../../../components/PriceList/PricesList/PricesListComponent';
import { PriceDetails } from '../../../models/Entities/PriceList/PriceDetail';
import { ProviderPriceList } from '../../../models/Entities/ProviderAssociations/PriceLists/ProviderPriceList';
import { useBoolean } from '@fluentui/react-hooks';
import { ProviderPriceListInfoComponent } from '../../../components/PriceList/PriceListInfo/ProviderPriceListInfoComponent';
import { ProviderPriceListComponent } from '../../../components/PriceList/ProviderPriceList/ProviderPriceListComponent';
import { Provider } from '../../../models/Entities/Provider/Provider';
import { withRouter } from '../../../commons/component/withRouterCustom';
import { useDebugValue, useEffect, useState } from 'react';

// Component Functions
import * as ownFunctions from './ProviderDetailsPageFunctions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';
import CashflowGeneralComponent from '../../../components/CashFlow/General/Cashflow.General.Component';

export const ProviderDetailsPage = (props: ownFunctions.Props) => {
  
  const [searchTextCriteria, setSearchTextCriteria] = useState<string>();
  const [selectedPriceList, setSelectedPriceList] =
    useState<ProviderPriceList>();

  const [UpdateMassivePriceIsActive, setUpdateMassivePriceIsActive] =
    useState<boolean>(false);

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [priceToEdit, setPriceToEdit] = useState<PriceDetails>();

  const [Provider, setProvider] = useState<Provider>();

  //useTranslation()
  const [t] = useTranslation();

  useEffect(() => {
    props.GetProviderById(props.ProviderId);
    
  }, [props.ProviderId]);

  useEffect(() => {
    props.GetPriceListByProviderId(props.ProviderId);
  }, [props.Provider !== undefined]);

  useEffect(() => {
    setSelectedPriceList(undefined);
  }, [props.PriceListDeleted === true]);

  
  // events
  const HandleEditPriceEvent = (price: PriceDetails) => {
    setPriceToEdit(price);
    openPanel();
  };

  const HandleSearchServiceCriteriaChange = (criteria: string | undefined) => {
    setSearchTextCriteria(criteria);
  };

  const SelectPriceListHandler = (priceList: ProviderPriceList) => {
    setSelectedPriceList(priceList);
  };

  const handleClosePanel = () => {
    setPriceToEdit(undefined);
    dismissPanel();
  };

  const handleAddPriceClick = () => {
    openPanel();
  };

  const handleDeletePriceList = (
    AgreementId: number,
    MedicalAgreementPriceListId: number
  ) => {
    props.DeletePriceListById(AgreementId, MedicalAgreementPriceListId);
  };

  const handleCancelDeletePriceList = () => {
    // props.ResetPriceListDeleteState();
  };

  const handleClonePriceList = (ProviderPriceListId: number) => {
    props.CloneProviderPriceList(ProviderPriceListId);
  };

  const handleAddListClick = () => {
    if (props.Provider) {
      props.AddPriceList(
        props.Provider.id,
        t('providerDetailsPage.addList.newPriceList'),
        t('providerDetailsPage.addList.namePriceList') 
      );
    }
  };

  return (
    <>
      <PageHeader title={t('providerDetailsPage.providerDetailsTitle')}/>
      <div className="Content">
        <div className="Section">
          {props.Provider && (
            <HeaderPersonaComponent
              PersonaName={props.Provider.person?.businessName}
              secondaryText={t('providerDetailsPage.providerDetailsSubTitle')}
            />
          )}
        </div>
        <div className="Section">
          {props.Provider && (
            <Pivot>
              <PivotItem headerText={t('providerDetailsPage.providerDetailsItem.financial')}>
                <CashflowGeneralComponent EntityIdentifier={props.OwnerId} FixedAssociatedEntityIdentifier={props.Provider.person.personIdentifier} />
              </PivotItem> 
              <PivotItem headerText={t('providerDetailsPage.providerDetailsItem.medicalService')}>
                <ProviderMedicalServiceAssociatedComponent
                  ProviderId={props.Provider.id}
                />
              </PivotItem>
              <PivotItem
                headerText={t('providerDetailsPage.providerDetailsItem.priceLists')}
                headerButtonProps={{
                  'data-order': 1,
                  'data-title': 'Price Lists'
                }}
              >
                <div className="SideLayoutPage">
                  <div>
                    <div>
                      <PrimaryButton
                        secondaryText={t('providerDetailsPage.button.addPriceList')}
                        onClick={handleAddListClick}
                        text={
                          props.isAddingPriceList
                            ? t('providerDetailsPage.button.adding')
                            : t('providerDetailsPage.button.addPriceList')
                        }
                        iconProps={
                          props.isAddingPriceList
                            ? ownFunctions.CreatingIcon
                            : ownFunctions.AddIcon
                        }
                        className="actionButton"
                        disabled={props.isAddingPriceList}
                      />
                      <Separator />
                      {props.Provider && (
                        <>
                          <ProviderPriceListComponent
                            priceList={props.Provider.PriceListAssociated || []}
                            onSelectPriceList={SelectPriceListHandler}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    {selectedPriceList && (
                      <>
                        <PriceListHeaderComponent
                          PriceList={selectedPriceList}
                          onAddMedicalService={handleAddPriceClick}
                          onSearchService={HandleSearchServiceCriteriaChange}
                          onDeletePriceList={(PriceListId: number) =>
                            handleDeletePriceList(props.Provider.id, PriceListId)
                          }
                          onCancelDeletePriceList={handleCancelDeletePriceList}
                          onClonePriceList={handleClonePriceList}
                          onMassiveUpdateChange={(isActive: boolean) =>
                            setUpdateMassivePriceIsActive(isActive)
                          }
                          isDeletingExecution={props.isDeletingPriceList}
                          FailOnDeleting={props.failOnDeletePriceList}
                          ErrorOnDeleting={props.DeletePriceListError}
                        />
                        <Separator />
                        <div className="priceListDetailsContent">
                          <div>
                            <PricesListComponent
                              PriceListId={selectedPriceList.priceList.id}
                              onEditPrice={HandleEditPriceEvent}
                              ServiceFilterCriteria={searchTextCriteria}
                              PriceMassiveEditionIsActive={UpdateMassivePriceIsActive} 
                              showPrice={true} 
                              showRemotePrice={false} 
                              showFailPrice={false} 
                              showRandomPrice={false} 
                              showAdditionalPaymentPrice={false}                            />
                          </div>
                          <div className="Section infoPanel">
                            <ProviderPriceListInfoComponent
                              ProviderPriceList={props.Provider.PriceListAssociated.find((pricelist: ProviderPriceList) => pricelist.id === selectedPriceList.id)!}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {!selectedPriceList?.providerId && (
                      <div className="NoData">
                        <h3>{t('providerDetailsPage.selectList')}</h3>
                      </div>
                    )}
                  </div>
                </div>
              </PivotItem>
            </Pivot>
          )}
        </div>
      </div>
    </>
  );
};

// const MapStateToProps = (state: AppState, ownProps: any) => {
//   return {
//     Providers: state.Provider?.list
//   };
// };

// const MapDispatchToProps = {
//   ...ProviderStore.actionCreators
// };

export default withRouter(connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(ProviderDetailsPage as any));
