import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/Persons.Services';

// actions
import * as Actions from '../actions';

// models
import { ErrorType } from '../../models/HttpError';
import { NewPerson } from '../../models/Entities/Person/NewPerson';
import { Person } from '../../models/Entities/Person/Person';

type KnownAction = Actions.Persons.PersonGetAllRequestAction 
| Actions.Persons.PersonGetAllSuccessAction
| Actions.Persons.PersonGetAllFailureAction
| Actions.Persons.PersonGetbyIdRequestAction
| Actions.Persons.PersonGetByIdSuccessAction
| Actions.Persons.PersonGetByIdFailureAction
| Actions.Persons.PersonCreateRequestAction
| Actions.Persons.PersonCreateSuccessAction
| Actions.Persons.PersonCreateFailureAction
| Actions.Persons.PersonUpdateRequestAction
| Actions.Persons.PersonUpdateSuccessAction
| Actions.Persons.PersonUpdateFailureAction
| Actions.Persons.PersonDeleteRequestAction
| Actions.Persons.PersonDeleteSuccessAction
| Actions.Persons.PersonDeleteFailureAction
| Actions.Persons.PersonFixIdentifierRequestAction
| Actions.Persons.PersonFixIdentifierSuccessAction
| Actions.Persons.PersonFixIdentifierFailureAction;

export const actionCreators = {
    GetAllPersons: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;

        if (state.oidc?.user?.access_token === undefined) {
                dispatch({ type: Actions.Persons.PERSON_GETALL_FAILURE, error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            });
            return;
        } else {
            token = state.oidc.user.access_token;
        }

        if(state.Persons?.successLoadingAll) return;
       
        dispatch({ type: Actions.Persons.PERSON_GETALL_REQUEST });

        try {
            const response = await Services.GetPersons(token);
            dispatch({ type: Actions.Persons.PERSON_GETALL_SUCCESS, payload: response });
        } catch (error: ErrorType | any) {
            dispatch({ type: Actions.Persons.PERSON_GETALL_FAILURE, error });
        }
    },
    GetPersonById: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;

        if (state.oidc?.user?.access_token === undefined) {
            dispatch({ type: Actions.Persons.PERSON_GETBYID_FAILURE, 
                error: {
                    ErrorCode: 401,
                    ErrorMessage: 'Not authorized',
                    Errors: []
                }
            });
            return;
        }  else  
            token = state.oidc.user.access_token;


        dispatch({ type: Actions.Persons.PERSON_GETBYID_REQUEST, payload: id });

        try {
            const response = await Services.GetPersonById(id, token);
            dispatch({ type: Actions.Persons.PERSON_GETBYID_SUCCESS, payload: response });
        } catch (error: ErrorType | any) {
            dispatch({ type: Actions.Persons.PERSON_GETBYID_FAILURE, error });
        }
    },
    CreatePerson: (person: NewPerson): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;

        if (state.oidc?.user?.access_token === undefined) {
            dispatch({ type: Actions.Persons.PERSON_CREATE_FAILURE, error: {
                ErrorCode: 401,
                ErrorMessage: 'Not authorized',
                Errors: []
            }
            });
            return;
        }
        else
            token = state.oidc.user.access_token;


        dispatch({ type: Actions.Persons.PERSON_CREATE_REQUEST, payload: person });

        try {
            const response = await Services.CreatePerson(person, token);
            dispatch({ type: Actions.Persons.PERSON_CREATE_SUCCESS, payload: response });
        } catch (error: ErrorType | any) {
            dispatch({ type: Actions.Persons.PERSON_CREATE_FAILURE, error });
        }
    },
    UpdatePerson: (person: Person): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;

        if (state.oidc?.user?.access_token === undefined) {
            dispatch({ type: Actions.Persons.PERSON_UPDATE_FAILURE, error: {
                ErrorCode: 401,
                ErrorMessage: 'Not authorized',
                Errors: []
            }
            });
            return;
        }
        else
            token = state.oidc.user.access_token;

        dispatch({ type: Actions.Persons.PERSON_UPDATE_REQUEST, payload: person });

        try {
            const response = await Services.UpdatePerson(person, token);
            dispatch({ type: Actions.Persons.PERSON_UPDATE_SUCCESS, payload: response });
        } catch (error: ErrorType | any) {
            dispatch({ type: Actions.Persons.PERSON_UPDATE_FAILURE, error });
        }
    },
    DeletePerson: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var state = getState();
        var token: string;

        if (state.oidc?.user?.access_token === undefined) {
            dispatch({ type: Actions.Persons.PERSON_DELETE_FAILURE, personId: id, error: {
                ErrorCode: 401,
                ErrorMessage: 'Not authorized',
                Errors: []
            }
            });
            return;
        }
        else
            token = state.oidc.user.access_token;


        dispatch({ type: Actions.Persons.PERSON_DELETE_REQUEST, payload: id });

        try {
            const response = await Services.DeletePerson(id, token);
            dispatch({ type: Actions.Persons.PERSON_DELETE_SUCCESS, payload: id });
        } catch (error: ErrorType | any) {
            dispatch({ type: Actions.Persons.PERSON_DELETE_FAILURE, personId: id, error });
        }
    },
    FixPersonIdentifiers: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var state = getState();
        var token: string;

        if (state.oidc?.user?.access_token === undefined) {
            dispatch({ type: Actions.Persons.PERSON_FIX_IDENTIFIER_FAILURE, error: {
                ErrorCode: 401,
                ErrorMessage: 'Not authorized',
                Errors: []
            }
            });
            return;
        }
        else
            token = state.oidc.user.access_token;

        dispatch({ type: Actions.Persons.PERSON_FIX_IDENTIFIER_REQUEST });

        try {
            const response = await Services.FixPersonIdentifiers(token);
            actionCreators.GetAllPersons();
            dispatch({ type: Actions.Persons.PERSON_FIX_IDENTIFIER_SUCCESS });
        } catch (error: ErrorType | any) {
            dispatch({ type: Actions.Persons.PERSON_FIX_IDENTIFIER_FAILURE, error });
        }
    }
};

