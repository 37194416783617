import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import spaLang from './i18n/locales/es/translation.json';
import engLang from './i18n/locales/en/translation.json';
import porLang from './i18n/locales/pr/translation.json';

//export const defaultNS = 'spaLang';

i18next
.use(Backend)

.use(initReactI18next).init({
  debug: true,
  lng: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false
    },
  resources: {
    es : { translation: spaLang},
    en: { translation: engLang},
    pr: { translation: porLang},
  },
});

export default i18next;