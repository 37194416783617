import { connect } from "react-redux";

// component functions
import * as ComponentFunctions from "./LanguajeSelector.Functions";
import { useEffect } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { Languaje } from "../../models/Entities/Settings/Languajes";

const LanguageSelectorComponent = (props: ComponentFunctions.Props) => {

    const { t, i18n } = useTranslation();

    useEffect(() => {
        props.GetAllLanguajes();
    }, []);

    const onChangeLanguaje = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        const selectedLanguaje = props.Languajes?.find(x => x.code === option?.key);
        selectedLanguaje && props.SetUserLanguaje(selectedLanguaje);
    }

    return <>
        <Dropdown
            placeholder="seleccione un idioma"
            label="Idiomas"
            options={props.LanguajeOptions}
            defaultSelectedKey={props.currentLanguaje?.code || 'es'}
            multiSelect={false}
            onChange={onChangeLanguaje}
        />
    </>
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(LanguageSelectorComponent as any);