import { AppState } from '../../../../redux/reducers';
import { TenantPriceList } from '../../../../models/Entities/TenantPriceLists/TenantPriceList';

import * as TenantPriceListStore from '../../../../redux/middleware/TenantPriceListsMiddleware';
import {
  FontWeights,
  getTheme,
  IDropdownOption,
  IIconProps,
  IStackProps,
  mergeStyleSets
} from '@fluentui/react';
import { ErrorType } from '../../../../models/HttpError';

export interface FormValidation {
  success: boolean;
  errors: string[];
}

interface OwnProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  TenantPriceList: TenantPriceList | undefined;
}

interface StateProps {
  isAdding?: boolean;
  addingSuccess?: boolean;
  failOnAdding?: boolean;
  isSaving?: boolean;
  savingSuccess?: boolean;
  failOnSaving?: boolean;
  error?: ErrorType;
  TenantPriceList?: TenantPriceList;
}

interface DispatchProps {
  AddTenantPriceList: typeof TenantPriceListStore.ActionCreators.AddTenantPriceList;
  UpdateTenantPriceList: typeof TenantPriceListStore.ActionCreators.UpdateTenantPriceList;
}

interface CustomEvent {
  onSavedTenantPriceList?: (tenantPriceList: TenantPriceList) => void;
}

export type Props = CustomEvent & StateProps & DispatchProps & OwnProps;

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    isAdding: state.TenantPriceLists?.isAddingNewOne,
    addingSuccess: state.TenantPriceLists?.successAddingNewOne,
    failOnAdding: state.TenantPriceLists?.failOnAddingNewOne,
    isSaving: state.TenantPriceLists?.isUpdatingOne,
    savingSuccess: state.TenantPriceLists?.successUpdatingOne,
    failOnSaving: state.TenantPriceLists?.failOnUpdatingOne,
    error: state.TenantPriceLists?.error,
    TenantPriceList: ownProps.TenantPriceList
  };
};

export const mapDispatchToProps = {
  ...TenantPriceListStore.ActionCreators
};

// Price list type options
export const PriceListTypeOptions: IDropdownOption[] = [
  { key: 'P', text: 'Pagador' },
  { key: 'C', text: 'Cobrador' }
];

// Form Styles
export const buttonStyles = {
  root: { marginRight: 8, class: 'ConfirmAction' }
};

export const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

export const theme = getTheme();
export const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});

export const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
