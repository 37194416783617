import { AppState } from '../../../redux/reducers';
import { useDispatch, useSelector, connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { MedicalInsuranceAgreement } from '../../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';
import { NewMedicalInsuranceAgreement } from '../../../models/Entities/MedicalInsuranceAgreements/NewMedicalInsuranceAgreement';
import {
  DatePicker,
  DefaultButton,
  defaultDatePickerStrings,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  IStackProps,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  Stack,
  TextField
} from '@fluentui/react';
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// store
import * as MedicalInsuranceAgreementsStore from '../../../redux/middleware/MedicalInsuranceAgreementsMiddleware';

interface FormValidation {
  success: boolean;
  errors: string[];
}

export interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  MedicalInsuranceId: number;
  MedicalInsuranceAgreement?: MedicalInsuranceAgreement | undefined;
}

export const MedicalInsuranceAgreementFormComponent = (props: IProps) => {
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();

  const AgreementsState = useSelector(
    (state: AppState) => state.MedicalInsuranceAgreements
  );
  const dispatch = useDispatch();

  // MedicalInsurance state
  const [healthProviderId, setHealthProviderId] = useState<string>();
  const [agreementStartDate, setAgreementStartDate] = useState<Date>();
  const [agreementEndDate, setAgreementEndDate] = useState<Date | undefined>();

  //useTranslation()
  const [t] = useTranslation();

  // effects
  useEffect(() => {
    if (props.MedicalInsuranceAgreement) {
      setHealthProviderId(props.MedicalInsuranceAgreement.healthProviderId);
      setAgreementStartDate(
        props.MedicalInsuranceAgreement.agreementStartDate
          ? new Date(props.MedicalInsuranceAgreement.agreementStartDate)
          : undefined
      );
      setAgreementEndDate(
        props.MedicalInsuranceAgreement.agreementEndDate
          ? new Date(props.MedicalInsuranceAgreement.agreementEndDate)
          : undefined
      );
    } else {
      ResetForm();
    }
  }, [props.MedicalInsuranceAgreement]);

  useEffect(() => {
    if (
      submittedForm &&
      AgreementsState &&
      (AgreementsState.successAddingNewOne ||
        AgreementsState.successUpdatingOne)
    ) {
      ResetForm();
      props.dismissPanel();
    }
  }, [
    AgreementsState,
    AgreementsState?.successAddingNewOne,
    AgreementsState?.successUpdatingOne
  ]);

  const ResetForm = () => {
    setHealthProviderId(undefined);
    setAgreementStartDate(undefined);
    setAgreementEndDate(undefined);
    setFormValidationStatus(undefined);
    setSubmittedForm(false);
  };

  // Events

  const onChangeHealthProviderIdTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setHealthProviderId(newValue || '');
  };

  const onChangeAgreementStartDateTextFieldValue = (
    date: Date | null | undefined
  ) => {
    setAgreementStartDate(date || new Date());
  };

  const onChangeAgreementEndDateTextFieldValue = (
    date: Date | null | undefined
  ) => {
    setAgreementEndDate(date || new Date());
  };

  const onFormatDate = (date?: Date): string => {
    var currentDate = new Date(date || new Date());
    return !currentDate
      ? ''
      : currentDate.getDate().toString().padStart(2, '0') +
          '/' +
          (currentDate.getMonth() + 1).toString().padStart(2, '0') +
          '/' +
          currentDate.getFullYear();
  };

  const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      var CurrentValues:
        | MedicalInsuranceAgreement
        | NewMedicalInsuranceAgreement;
      if (props.MedicalInsuranceAgreement) {
        CurrentValues = {
          id: props.MedicalInsuranceAgreement.id,
          healthProviderId: healthProviderId!,
          agreementStartDate: agreementStartDate!,
          agreementEndDate: agreementEndDate,
          medicalInsuranceId: props.MedicalInsuranceAgreement.medicalInsuranceId
        };
        dispatch(
          mapDispatchToProps.Save(CurrentValues as MedicalInsuranceAgreement)
        );
      } else {
        CurrentValues = {
          healthProviderId: healthProviderId!,
          agreementStartDate: agreementStartDate!,
          agreementEndDate: agreementEndDate,
          medicalInsuranceId: props.MedicalInsuranceId
        };
        dispatch(mapDispatchToProps.Add(CurrentValues));
      }
    }
  };

  const HandleCancelForm = () => {
    ResetForm();

    props.dismissPanel();
  };

  // footer
  const onRenderFooterContent = () => {
    return (
      <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'medicalInsuranceAgreementForm.footer.cancelButton'}/> 
        </DefaultButton>
        <PrimaryButton
          onClick={HandleSaveForm}
          styles={buttonStyles}
          className="ConfirmAction"
        >
          <Trans i18nKey={'medicalInsuranceAgreementForm.footer.saveButton'}/> 
        </PrimaryButton>
      </div>
    );
  };

  const formFields = (
    <Stack {...columnProps}>
      <TextField
        label={t('medicalInsuranceAgreementForm.props.supplierIdentifier')} 
        name="txt_healthProvider"
        required
        autoComplete="off"
        onChange={onChangeHealthProviderIdTextFieldValue}
        errorMessage={
          (submittedForm === true && healthProviderId === undefined) ||
          (submittedForm === true &&
            healthProviderId !== undefined &&
            healthProviderId.length === 0)
            ? t('medicalInsuranceAgreementForm.props.requiredField') 
            : undefined
        }
        value={healthProviderId || ''}
      />
      <DatePicker
        label={t('medicalInsuranceAgreementForm.props.effectiveDate')} 
        placeholder={t('medicalInsuranceAgreementForm.props.selectDate')}
        ariaLabel="Seleccione una fecha"
        // DatePicker uses English strings by default. For localized apps, you must override this prop.
        strings={defaultDatePickerStrings}
        onSelectDate={onChangeAgreementStartDateTextFieldValue}
        formatDate={onFormatDate}
        value={agreementStartDate}
      />
      <DatePicker
        label={t('medicalInsuranceAgreementForm.props.endDate')} 
        placeholder={t('medicalInsuranceAgreementForm.props.selectDate')}
        ariaLabel="Seleccione una fecha"
        // DatePicker uses English strings by default. For localized apps, you must override this prop.
        strings={defaultDatePickerStrings}
        onSelectDate={onChangeAgreementEndDateTextFieldValue}
        formatDate={onFormatDate}
        value={agreementEndDate}
      />
    </Stack>
  );

  // form validation

  const ValidateForm = (): boolean => {
    var validationResult: boolean = true;

    var FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    if (healthProviderId === undefined || healthProviderId.length === 0) {
      validationResult = false;
      FormValidation.errors.push(
        t('medicalInsuranceAgreementForm.formValidation.nameInformation') 
      );
    }

    // if (agreementStartDate === undefined) {
    //   validationResult = false;
    //   FormValidation.errors.push('debe informar una fecha de vigencia desde');
    // }

    if (
      agreementStartDate &&
      agreementEndDate &&
      agreementStartDate >= agreementEndDate
    ) {
      validationResult = false;
      FormValidation.errors.push(
        t('medicalInsuranceAgreementForm.formValidation.errorDateInformation')
      );
    }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      isModeless={false}
      containerClassName={contentStyles.container}
      closeButtonAriaLabel="Close"
      //onRenderFooterContent={onRenderFooterContent}
      // Stretch panel content to fill the available height so the footer is positioned
      // at the bottom of the page
      //isFooterAtBottom={true}
    >
      <div className={contentStyles.header}>
        <span><Trans i18nKey={'medicalInsuranceAgreementForm.modal.agreementInformation'}/></span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.dismissPanel}
        />
      </div>
      {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {t('medicalInsuranceAgreementForm.modal.reviewInformation')}
          <ul>
            {FormValidationStatus?.errors.map(
              (Error: string, index: number) => (
                <li key={index}>{Error}</li>
              )
            )}
          </ul>
        </MessageBar>
      )}
      <div className={contentStyles.body}>
        {AgreementsState &&
          (AgreementsState.failOnAddingNewOne ||
            AgreementsState.failOnUpdatingOne) &&
          AgreementsState.error && (
            <ErrorMessageComponent Error={AgreementsState.error} />
          )}
        {formFields}
      </div>
      {onRenderFooterContent()}
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalInsurances
});

const mapDispatchToProps = {
  ...MedicalInsuranceAgreementsStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalInsuranceAgreementFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
