import { IResult } from '../models/HttpResult';
import { StageCycle } from '../models/Entities/Contracts/StageCycle';
import { NewStageCycle } from '../models/Entities/Contracts/NewStageCycle';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';


const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/';

// Get All cycles of contract
export const GetContractsStagesCycles = async (ContractId: number, token: string): Promise<StageCycle[]> => {

    let RequestURL: string = BaseURL + ContractId + '/cycles/';

    try {
        const Response: StageCycle[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<StageCycle[]>>;
            }
        })
        .then((data: IResult<StageCycle[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Get All cycles by addendum id
export const GetContractStagesCyclesByAddendumId = async (AddendumId: number, token: string): Promise<StageCycle[]> => {

    let RequestURL: string = BaseURL + 'addendums/' + AddendumId + '/cycles/';

    try {
        const Response: StageCycle[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<StageCycle[]>>;
            }
        })
        .then((data: IResult<StageCycle[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Get Cycle By Id
export const GetContractStageCycleById = async (id: number, token: string): Promise<StageCycle> => {

    let RequestURL: string = BaseURL + 'cycles/' + id;
    
    try {
        const Response: StageCycle = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<StageCycle>>;
            }
        })
        .then((data: IResult<StageCycle>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Create contract cycle
export const CreateContractStageCycle = async (newStageCycle: NewStageCycle, token: string): Promise<StageCycle> => {

    let RequestURL: string = BaseURL + 'cycles/';
    
    try {
        const Response: StageCycle = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newStageCycle)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<StageCycle>>;
            }
        })
        .then((data: IResult<StageCycle>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Update Contract stage cycle
export const UpdateContractStageCycle = async (StageCycle: StageCycle, token: string): Promise<StageCycle> => {

    let RequestURL: string = BaseURL + 'cycles/';
    
    try {
        const Response: StageCycle = await fetch(RequestURL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(StageCycle)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<StageCycle>>;
            }
        })
        .then((data: IResult<StageCycle>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Delete Contract stage cycle
export const DeleteContractStageCycle = async (id: number, token: string): Promise<StageCycle> => {

    let RequestURL: string = BaseURL + 'cycles/' + id;
    
    try {
        const Response: StageCycle = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<StageCycle>>;
            }
        })
        .then((data: IResult<StageCycle>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};