import { ContractAccess } from "../../models/Entities/Contracts/ContractAccess";
import { CommonState } from "../common/state/commonState";


export interface ContractAccessState extends CommonState<ContractAccess> {
    // updating all
    isUpdatingAll: boolean;
    isUpdatingAllSuccess: boolean|undefined;
    FailUpdatingAll: boolean;
};

export const ContractAccessUnloadState: ContractAccessState = {
    // loading all
    isLoadingAll: false,
    successLoadingAll: undefined,
    failOnLoadingAll: false,

    // updating all
    isUpdatingAll: false,
    isUpdatingAllSuccess: undefined,
    FailUpdatingAll: false,

    // loading one
    isLoadingOne: false,
    successLoadingOne: undefined,
    failOnLoadingOne: false,

    // updating
    isUpdatingOne: false,
    successUpdatingOne: undefined,
    failOnUpdatingOne: false,

    // adding
    isAddingNewOne: false,
    successAddingNewOne: undefined,
    failOnAddingNewOne: false,

    // deleting
    isDeletingOne: false,
    successDeletingOne: undefined,
    failOnDeletingOne: false,
    list: [],
    error: undefined
};