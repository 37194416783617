import { Label, Text } from '@fluentui/react';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

//styles
import './NotificationComponent.css';

export const NotificationComponent = () => {
  
  //useTranslation()
  const [t] = useTranslation();

  return (
    <div className="NotificacionesContent">
      <Label>{t('notificationComponent.notifications')}</Label>
      <div>
        <Text>{t('notificationComponent.noNotifications')} </Text>
      </div>
    </div>
  );
};
