// models
import { ContractAccess } from "../../models/Entities/Contracts/ContractAccess";
import { NewContractAccess } from "../../models/Entities/Contracts/NewContractAccess";	
import { ErrorType } from "../../models/HttpError";

// get all contract access
export const CONTRACT_ACCESS_GETALL_REQUEST = 'CONTRACT_ACCESS_GETALL_REQUEST';
export const CONTRACT_ACCESS_GETALL_SUCCESS = 'CONTRACT_ACCESS_GETALL_SUCCESS';
export const CONTRACT_ACCESS_GETALL_FAILURE = 'CONTRACT_ACCESS_GETALL_FAILURE';

export interface ContractAccessGetAllRequestAction {
    type: typeof CONTRACT_ACCESS_GETALL_REQUEST;
    contractId: number;
}

export interface ContractAccessGetAllSuccessAction {
    type: typeof CONTRACT_ACCESS_GETALL_SUCCESS;
    constractId: number;
    contractAccess: ContractAccess[];
}

export interface ContractAccessGetAllFailureAction {
    type: typeof CONTRACT_ACCESS_GETALL_FAILURE;
    contractId: number;
    error: ErrorType;
}

// get contract access by ID
export const CONTRACT_ACCESS_GETBYID_REQUEST = 'CONTRACT_ACCESS_GETBYID_REQUEST';
export const CONTRACT_ACCESS_GETBYID_SUCCESS = 'CONTRACT_ACCESS_GETBYID_SUCCESS';
export const CONTRACT_ACCESS_GETBYID_FAILURE = 'CONTRACT_ACCESS_GETBYID_FAILURE';

export interface ContractAccessGetByIdRequestAction {
    type: typeof CONTRACT_ACCESS_GETBYID_REQUEST;
    contractAccessId: number;
}

export interface ContractAccessGetByIdSuccessAction {
    type: typeof CONTRACT_ACCESS_GETBYID_SUCCESS;
    contractAccess: ContractAccess;
}

export interface ContractAccessGetByIdFailureAction {
    type: typeof CONTRACT_ACCESS_GETBYID_FAILURE;
    contractAccessId: number;
    error: ErrorType;
}

// create contract access
export const CONTRACT_ACCESS_CREATE_REQUEST = 'CONTRACT_ACCESS_CREATE_REQUEST';
export const CONTRACT_ACCESS_CREATE_SUCCESS = 'CONTRACT_ACCESS_CREATE_SUCCESS';
export const CONTRACT_ACCESS_CREATE_FAILURE = 'CONTRACT_ACCESS_CREATE_FAILURE';

export interface ContractAccessCreateRequestAction {
    type: typeof CONTRACT_ACCESS_CREATE_REQUEST;
    newContractAccess: NewContractAccess;
}

export interface ContractAccessCreateSuccessAction {
    type: typeof CONTRACT_ACCESS_CREATE_SUCCESS;
    contractAccess: ContractAccess;
}

export interface ContractAccessCreateFailureAction {
    type: typeof CONTRACT_ACCESS_CREATE_FAILURE;
    error: ErrorType;
}

// update contract access
export const CONTRACT_ACCESS_UPDATE_REQUEST = 'CONTRACT_ACCESS_UPDATE_REQUEST';
export const CONTRACT_ACCESS_UPDATE_SUCCESS = 'CONTRACT_ACCESS_UPDATE_SUCCESS';
export const CONTRACT_ACCESS_UPDATE_FAILURE = 'CONTRACT_ACCESS_UPDATE_FAILURE';

export interface ContractAccessUpdateRequestAction {
    type: typeof CONTRACT_ACCESS_UPDATE_REQUEST;
    contractAccess: ContractAccess;
}

export interface ContractAccessUpdateSuccessAction {
    type: typeof CONTRACT_ACCESS_UPDATE_SUCCESS;
    contractAccess: ContractAccess;
}

export interface ContractAccessUpdateFailureAction {
    type: typeof CONTRACT_ACCESS_UPDATE_FAILURE;
    error: ErrorType;
}

// delete contract access
export const CONTRACT_ACCESS_DELETE_REQUEST = 'CONTRACT_ACCESS_DELETE_REQUEST';
export const CONTRACT_ACCESS_DELETE_SUCCESS = 'CONTRACT_ACCESS_DELETE_SUCCESS';
export const CONTRACT_ACCESS_DELETE_FAILURE = 'CONTRACT_ACCESS_DELETE_FAILURE';

export interface ContractAccessDeleteRequestAction {
    type: typeof CONTRACT_ACCESS_DELETE_REQUEST;
    contractId: number;
    contractAccessId: number;
}

export interface ContractAccessDeleteSuccessAction {
    type: typeof CONTRACT_ACCESS_DELETE_SUCCESS;
    constractId: number;
    contractAccessId: number;
}

export interface ContractAccessDeleteFailureAction {
    type: typeof CONTRACT_ACCESS_DELETE_FAILURE;
    contractId: number;
    contractAccessId: number;
    error: ErrorType;
}

