import { IResult } from '../models/HttpResult';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

// models
import { ContractCommissionAgent } from '../models/Entities/Contracts/ContractCommissionAgent';
import { NewContractCommissionAgent } from '../models/Entities/Contracts/NewContractCommissionAgent';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/';

// Get All Contracts practitioner settings
export const GetContractCommissionAgentsByAssociatedContractId = async (associantedContractId: number, token: string): Promise<ContractCommissionAgent[]> => {

    let RequestURL: string = BaseURL + 'commissionagent?AssociatedContractId=' + associantedContractId;

    try {
        const Response: ContractCommissionAgent[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionAgent[]>>;
                }
            })
            .then((data: IResult<ContractCommissionAgent[]>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// get all contract commission agents
export const GetContractCommissionAgents = async (contractId: number, token: string): Promise<ContractCommissionAgent[]> => {

    let RequestURL: string = BaseURL + contractId + '/commissionagent';

    try {
        const Response: ContractCommissionAgent[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionAgent[]>>;
                }
            })
            .then((data: IResult<ContractCommissionAgent[]>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Get contract commission concept by id	
export const GetContractCommissionAgentById = async (commissionConceptId: number, token: string) : Promise<ContractCommissionAgent> => {

    let RequestURL: string = BaseURL + 'commissionagent/' + commissionConceptId;

    try {
        const Response: ContractCommissionAgent = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                Sentry.captureException(response);
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractCommissionAgent>>;
            }
        })
        .then((data: IResult<ContractCommissionAgent>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Create contract commission agent
export const CreateContractCommissionAgent = async (contractCommissionAgent: NewContractCommissionAgent, token: string): Promise<ContractCommissionAgent> => {

    let RequestURL: string = BaseURL + 'commissionagent';

    try {
        const Response: ContractCommissionAgent = await fetch(RequestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(contractCommissionAgent)
        })
        .then((response) => {
            if (!response.ok) {
                Sentry.captureException(response);
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractCommissionAgent>>;
            }
        })
        .then((data: IResult<ContractCommissionAgent>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Update contract commission agent
export const UpdateContractCommissionAgent = async (contractCommissionAgent: ContractCommissionAgent, token: string): Promise<ContractCommissionAgent> => {

    let RequestURL: string = BaseURL + 'commissionagent/';

    try {
        const Response: ContractCommissionAgent = await fetch(RequestURL, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(contractCommissionAgent)
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionAgent>>;
                }
            })
            .then((data: IResult<ContractCommissionAgent>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );
        
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// get commission agents by associated contract id
export const GetCommissionAgentsByContractId = async (contractId: number, token: string): Promise<ContractCommissionAgent[]> => {

    let RequestURL: string = BaseURL + contractId + '/commissionagent';

    try {
        const Response: ContractCommissionAgent[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureException(response);
                    throw response;
                } else {
                    return response.json() as Promise<IResult<ContractCommissionAgent[]>>;
                }
            })
            .then((data: IResult<ContractCommissionAgent[]>) => {
                return data.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

