import { Reducer, Action } from 'redux';
import {
  ProviderPriceListState,
  ProviderPriceListUnloadState
} from '../states/ProviderPriceListState';

// Functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';
import { ProviderPriceList } from '../../models/Entities/ProviderAssociations/PriceLists/ProviderPriceList';

type KnownAction =
  | Actions.ProviderPriceLists.ProviderPriceList_RequestAll_Action
  | Actions.ProviderPriceLists.ProviderPriceList_ReceiveAll_Action
  | Actions.ProviderPriceLists.ProviderPriceList_FailAll_Action
  | Actions.ProviderPriceLists.ProviderPriceList_RequestById_Action
  | Actions.ProviderPriceLists.ProviderPriceList_ReceiveById_Action
  | Actions.ProviderPriceLists.ProviderPriceList_FailById_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Add_Request_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Add_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Add_Fail_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_Request_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_By_Price_List_Id_Request_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_By_Price_List_Id_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Delete_Fail_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Clone_Request_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Clone_Receive_Action
  | Actions.ProviderPriceLists.ProviderPriceList_Clone_Fail_Action;

export const ProviderPriceListAssociationReducer: Reducer<
  ProviderPriceListState
> = (
  state: ProviderPriceListState | undefined,
  incomingAction: Action
): ProviderPriceListState => {
  if (state === undefined) {
    return ProviderPriceListUnloadState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'PROVIDER_PRICE_LIST_REQUEST_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'PROVIDER_PRICE_LIST_RECEIVE_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined,
        list: action.ProviderPriceLists
      };
    case 'PROVIDER_PRICE_LIST_FAIL_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'PROVIDER_PRICE_LIST_REQUEST_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'PROVIDER_PRICE_LIST_RECEIVE_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined,
        list: [...state.list, action.ProviderPriceList]
      };
    case 'PROVIDER_PRICE_LIST_FAIL_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'PROVIDER_PRICE_LIST_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'PROVIDER_PRICE_LIST_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [...state.list, action.ProviderPriceList]
      };
    case 'PROVIDER_PRICE_LIST_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'PROVIDER_PRICE_LIST_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'PROVIDER_PRICE_LIST_DELETE_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: state.list.filter(
          (PriceList: ProviderPriceList) =>
            PriceList.id !== action.ProviderPriceListId
        )
      };
    case 'PROVIDER_PRICE_LIST_DELETE_BY_PRICE_LIST_ID_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'PROVIDER_PRICE_LIST_DELETE_BY_PRICE_LIST_ID_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'PROVIDER_PRICE_LIST_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
    default:
      return state;
  }
};
