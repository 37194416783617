import { Settlement } from '../../models/Entities/Settlements/Settlement';
import { NewAgreementSettlement, NewPractitionerSettlement, NewProtocolSettlement } from '../../models/Entities/Settlements/NewSettlement';
import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/Settlements.Services';

// middelware
import * as SummariesMiddleware from './SettlementResumeMiddleware';
import * as DetailsMiddleware from './SettlementDetailsMiddleware';

// actions
import * as Actions from '../actions';

type KnownAction =
    | Actions.Settlements.Settlement_GetAll_Request_Action
    | Actions.Settlements.Settlement_GetAll_Receive_Action
    | Actions.Settlements.Settlement_GetAll_Fail_Action
    | Actions.Settlements.Settlement_UpdateAll_Request_Action
    | Actions.Settlements.Settlement_UpdateAll_Receive_Action
    | Actions.Settlements.Settlement_UpdateAll_Fail_Action
    | Actions.Settlements.Settlement_GetById_Request_Action
    | Actions.Settlements.Settlement_GetById_Receive_Action
    | Actions.Settlements.Settlement_GetById_Fail_Action
    | Actions.Settlements.Settlement_Add_Request_Action
    | Actions.Settlements.Settlement_Add_Receive_Action
    | Actions.Settlements.Settlement_Add_Fail_Action
    | Actions.Settlements.Settlement_Update_Request_Action
    | Actions.Settlements.Settlement_Update_Receive_Action
    | Actions.Settlements.Settlement_Update_Fail_Action
    | Actions.Settlements.Settlement_Delete_Request_Action
    | Actions.Settlements.Settlement_Delete_Receive_Action
    | Actions.Settlements.Settlement_Delete_Fail_Action
    | Actions.Settlements.Settlement_Recalculate_Request_Action
    | Actions.Settlements.Settlement_Recalculate_Receive_Action
    | Actions.Settlements.Settlement_Recalculate_Fail_Action
    | Actions.Settlements.Settlement_RefreshById_Request_Action
    | Actions.Settlements.Settlement_RefreshById_Receive_Action
    | Actions.Settlements.Settlement_RefreshById_Fail_Action
    | Actions.Settlements.Settlement_ChangeStatus_Request_Action
    | Actions.Settlements.Settlement_ChangeStatus_Receive_Action
    | Actions.Settlements.Settlement_ChangeStatus_Fail_Action;

export const actionCreators = {
    GetAllSettlements:
        (): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'SETTLEMENT_GETALL_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            if (!state?.Settlement?.successLoadingAll) {
                dispatch({
                    type: 'SETTLEMENT_GETALL_REQUEST_ACTION'
                });

                Services.GetSettlements(token)
                    .then((settlements: Settlement[]) => {
                        dispatch({
                            type: 'SETTLEMENT_GETALL_RECEIVE_ACTION',
                            settlements: settlements
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'SETTLEMENT_GETALL_FAIL_ACTION',
                            error: error
                        })
                    );
            } else {
                dispatch({
                    type: 'SETTLEMENT_UPDATEALL_REQUEST_ACTION'
                });

                Services.GetSettlements(token)
                    .then((settlements: Settlement[]) => {
                        dispatch({
                            type: 'SETTLEMENT_UPDATEALL_RECEIVE_ACTION',
                            settlements: settlements
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'SETTLEMENT_UPDATEALL_FAIL_ACTION',
                            error: error
                        })
                    );
            }
    },
    GetSettlementById:
        (id: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'SETTLEMENT_GETBYID_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            if(state.Settlement?.list?.find((settlement: Settlement) => settlement.id === id)) return;

            dispatch({
                type: 'SETTLEMENT_GETBYID_REQUEST_ACTION',
                settlementId: id
            });

            Services.GetSettlementById(id, token)
            .then((settlement: Settlement) => {
                dispatch({
                    type: 'SETTLEMENT_GETBYID_RECEIVE_ACTION',
                    settlement: settlement
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'SETTLEMENT_GETBYID_FAIL_ACTION',
                    error: error
                })
            );

    },
    AddProtocolSettlement:
        (newSettlement: NewProtocolSettlement): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'SETTLEMENT_ADD_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'SETTLEMENT_ADD_REQUEST_ACTION',
                newSettlement: newSettlement
            });

            Services.CreateProtocolSettlement(newSettlement, token)
            .then((settlement: Settlement) => {
                dispatch({
                    type: 'SETTLEMENT_ADD_RECEIVE_ACTION',
                    settlement: settlement
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'SETTLEMENT_ADD_FAIL_ACTION',
                    error: error
                })
            );

    },
    AddAgreementSettlement:
    (newSettlement: NewAgreementSettlement): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
        
        var state = getState();
        var token: string = '';

        if (state.oidc?.user) {
            token = state.oidc?.user?.access_token;
        } else {
            dispatch({
                type: 'SETTLEMENT_ADD_FAIL_ACTION',
                error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
            });
            return;
        }

        dispatch({
            type: 'SETTLEMENT_ADD_REQUEST_ACTION',
            newSettlement: newSettlement
        });

        Services.CreateAgreementSettlement(newSettlement, token)
        .then((settlement: Settlement) => {
            dispatch({
                type: 'SETTLEMENT_ADD_RECEIVE_ACTION',
                settlement: settlement
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'SETTLEMENT_ADD_FAIL_ACTION',
                error: error
            })
        );

    },
    AddPractitionerSettlement:
    (newSettlement: NewPractitionerSettlement): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
        
        var state = getState();
        var token: string = '';

        if (state.oidc?.user) {
            token = state.oidc?.user?.access_token;
        } else {
            dispatch({
                type: 'SETTLEMENT_ADD_FAIL_ACTION',
                error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
            });
            return;
        }

        dispatch({
            type: 'SETTLEMENT_ADD_REQUEST_ACTION',
            newSettlement: newSettlement
        });

        Services.CreatePractitionerSettlement(newSettlement, token)
        .then((settlement: Settlement) => {
            dispatch({
                type: 'SETTLEMENT_ADD_RECEIVE_ACTION',
                settlement: settlement
            });
        })
        .catch((error: ErrorType) =>
            dispatch({
                type: 'SETTLEMENT_ADD_FAIL_ACTION',
                error: error
            })
        );

    },
    UpdateSettlement:
        (settlement: Settlement): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'SETTLEMENT_UPDATE_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'SETTLEMENT_UPDATE_REQUEST_ACTION',
                settlement: settlement
            });

            Services.UpdateSettlement(settlement, token)
            .then((settlement: Settlement) => {
                dispatch({
                    type: 'SETTLEMENT_UPDATE_RECEIVE_ACTION',
                    settlement: settlement
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'SETTLEMENT_UPDATE_FAIL_ACTION',
                    error: error
                })
            );

    },
    RefreshSettlementById:
        (settlementId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'SETTLEMENT_REFRESHBYID_FAIL_ACTION',
                    settlementId: settlementId,
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'SETTLEMENT_REFRESHBYID_REQUEST_ACTION',
                settlementId: settlementId
            });

            Services.GetSettlementById(settlementId, token)
            .then((settlement: Settlement) => {
                dispatch({
                    type: 'SETTLEMENT_REFRESHBYID_RECEIVE_ACTION',
                    settlement: settlement
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'SETTLEMENT_REFRESHBYID_FAIL_ACTION',
                    settlementId: settlementId,
                    error: error
                })
            );

    },
    ChangeSettlementStatus:
        (settlementId: number, status: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'SETTLEMENT_CHANGESTATUS_FAIL_ACTION',
                    settlementId: settlementId,
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'SETTLEMENT_CHANGESTATUS_REQUEST_ACTION',
                status: status,
                settlementId: settlementId
            });

            Services.ChangeSettlementStatus(settlementId, status, token)
            .then((settlement: Settlement) => {
                dispatch({
                    type: 'SETTLEMENT_CHANGESTATUS_RECEIVE_ACTION',
                    NewSettlementStatus: settlement.status,
                    settlementId: settlementId
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'SETTLEMENT_CHANGESTATUS_FAIL_ACTION',
                    settlementId: settlementId,
                    error: error
                })
            );

    },
    DeleteSettlementById:
        (settlementId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'SETTLEMENT_DELETE_FAIL_ACTION',
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'SETTLEMENT_DELETE_REQUEST_ACTION',
                settlementId: settlementId
            });

            Services.DeleteSettlement(settlementId, token)
            .then(() => {
                dispatch({
                    type: 'SETTLEMENT_DELETE_RECEIVE_ACTION',
                    settlementId: settlementId
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'SETTLEMENT_DELETE_FAIL_ACTION',
                    error: error
                })
            );

    },
    RecalculateSettlementById:
        (settlementId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string = '';

            if (state.oidc?.user) {
                token = state.oidc?.user?.access_token;
            } else {
                dispatch({
                    type: 'SETTLEMENT_RECALCULATE_FAIL_ACTION',
                    settlementId: settlementId,
                    error: { ErrorCode: 401, ErrorMessage: 'Unauthorized', Errors: [] }
                });
                return;
            }

            dispatch({
                type: 'SETTLEMENT_RECALCULATE_REQUEST_ACTION',
                settlementId: settlementId
            });

            Services.RecalculateSettlement(settlementId, token)
            .then((settlement: Settlement) => {
                dispatch({
                    type: 'SETTLEMENT_RECALCULATE_RECEIVE_ACTION',
                    settlement: settlement
                });

                SummariesMiddleware.actionCreators.GetAllSettlementResume(settlementId);
                DetailsMiddleware.actionCreators.GetAllSettlementDetails(settlementId)

            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'SETTLEMENT_RECALCULATE_FAIL_ACTION',
                    settlementId: settlementId,
                    error: error
                })
            );
    }
};
