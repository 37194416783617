import { connect } from 'react-redux';	
import { DefaultButton, FontIcon, FontSizes, Label, Link, Separator } from '@fluentui/react';
import { useEffect, useState } from 'react';
import MOMENT from 'react-moment';
import { Text, Toast, ToastBody, Toaster, ToastFooter, ToastPosition, ToastTitle, useToastController } from '@fluentui/react-components';
import { List, ListItem } from "@fluentui/react-migration-v0-v9";
//functions
import * as ComponentFunctions from './SettlementHeader.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';



// Styles
import './SettlementHeader.Styles.css';



export const SettlementHeaderComponent = (props: ComponentFunctions.Props) => {

    const [Today, setToday] = useState<Date>(new Date());
    const EditContract = () => {};
    const AssociateProtocol = () => {};

    const {t} = useTranslation()

    const { dispatchToast } = useToastController('SettlementToast');
    const [position, setPosition] = useState<ToastPosition>("bottom-end");
    const [timeout, setDismissTimeout] = useState<number>(5000);

    // actions
    const ConfirmSettlement = (settlementId: number) => {
        props.ChangeSettlementStatus(settlementId, 5);
    }

    const DiscartSettlement = (settlementId: number) => {
        props.ChangeSettlementStatus(settlementId, 6);
    }

    useEffect(() => {
        props.GetSettlementById(props.SettlementId);
    }, []);

    useEffect(() => {
        if (props.failOnChangeStatus === true) {
            dispatchToast(
                <Toast appearance="inverted" className='Toast LeftBorder red'>
                  <ToastTitle>{'Error al confirmar la liquidación'}</ToastTitle>
                  <ToastBody>
                    {props.error?.ErrorMessage}
                    <Separator />
                    <List>
                        {props.error?.Errors?.map((error: string) => (
                            <ListItem>
                                {error}
                            </ListItem>
                        ))}
                        </List>
                  </ToastBody>
                  {/* <ToastFooter>
                    <Link>Action</Link>
                    <Link>Action</Link>
                  </ToastFooter> */}
                </Toast>,
                { timeout, position, intent: "error" }
              );
        }
    }, [props.failOnChangeStatus]);

    useEffect(() => {
        if (props.Settlement) {
            props.Settlement?.protocol[0]?.protocolId && props.GetMedicalProtocolById(props.Settlement?.protocol[0]?.protocolId);
            props.Settlement?.contract[0]?.contractId && props.GetContractById(props.Settlement?.contract[0]?.contractId);
        }
    }, [props.Settlement]);

    const Controls = (
        <>
            <DefaultButton
                onClick={() => ConfirmSettlement(props.SettlementId)}	
                text={t('settlementHeader.button.confirmSettlementButton')} 
            />
            <DefaultButton
                className='Danger'
                onClick={() => DiscartSettlement(props.SettlementId)}
                text={t('settlementHeader.button.discartSettlementButton')}
            />
             <Toaster toasterId={'SettlementToast'} />
        </>
    );


    return (
        <>
        <div className={"Section"}>
            <div className="ContractHeader">
                <div className="ContractHeader_Title">
                    <div>
                        <h3>{props.Settlement?.name}</h3>
                        <Label>{props.Settlement?.description}</Label>
                    </div>
                    <div className='ComponentControls'>
                        {Controls}
                    </div>
                </div>
                <Separator />
                <div className='ContractHeaderDetails'>
                    <div>
                        <small>{t('settlementHeader.contractHeaderDetails.status')}</small>
                        <Label className='ContentAlignedHorizontally'><FontIcon className={props.Settlement?.status === 2 ? 'withRotation' : ''} iconName={ComponentFunctions.GetSettlementStatusIcon(props.Settlement?.status)} style={{ fontSize: FontSizes.size16}} />&nbsp;<strong>{t(ComponentFunctions.GetSettlementStatusDescripcion(props.Settlement?.status))}</strong></Label>
                    </div>
                    <div>
                        <small>{t('settlementHeader.contractHeaderDetails.currency')}</small>
                        <Label><strong>{props.Settlement?.currency}</strong></Label>
                    </div>
                    <div>
                        <small>{t('settlementHeader.contractHeaderDetails.identifier')}</small>
                        <Label><strong>{props.Settlement?.identifier}</strong></Label>
                    </div>
                    <div>
                        <small>{t('settlementHeader.contractHeaderDetails.settledPeriod')}</small>
                        <Label><strong><MOMENT format="DD/MM/YYYY">{props.Settlement?.settlementDateRangeInit}</MOMENT></strong> - <strong><MOMENT format="DD/MM/YYYY">{props.Settlement?.settlementDateRangeEnd}</MOMENT></strong></Label>
                    </div>
                </div>
            </div>
        </div>
        </>
    );

}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
  )(SettlementHeaderComponent as any);