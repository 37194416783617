import { createTableColumn, PresenceBadgeStatus, TableColumnDefinition, TableCellLayout } from "@fluentui/react-components";
import * as jwt_decode from "jwt-decode";

//store 
import * as DebtsStore from "../../../../redux/middleware/CashFlow-Debts.Middleware";
import * as PersonsStore from "../../../../redux/middleware/Persons.Middleware";

// models
import { Payment } from "../../../../models/Entities/CashFlow/Payment/Payment";
import { ErrorType } from "../../../../models/HttpError";
import { AppState } from "../../../../redux/reducers";
import { Person } from "../../../../models/Entities/Person/Person";
import { Debt } from "../../../../models/Entities/CashFlow/Debt/Debt";


//#region Props

export enum DebtListType {
    Debtor = 1,
    Creditor = 2
}

interface IProps {
    type: DebtListType;
    EntityIdentifier: string;
    FixedAssociatedEntityIdentifier: string | undefined;
  }
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    isDeleting: boolean;
    isDeletedSuccessfully: boolean;
    failOnDeleting: boolean;
    error: ErrorType | undefined;
    Debts: Debt[];
    GetPersonByIdentifier: (identifier: string) => Person | undefined;
  }
  
  interface MedicalServicesDispatchProps {
    GetAllDebtsByCreditor: typeof DebtsStore.actionCreators.GetAllDebtsByCreditor;
    GetAllDebtsByDebtor: typeof DebtsStore.actionCreators.GetAllDebtsByDebtor;
    GetAllPersons: typeof PersonsStore.actionCreators.GetAllPersons;
  }
  
  export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const CurrentTenantId = (jwt_decode.jwtDecode(state?.oidc?.user?.access_token!) as any)?.tenantId;

    var Debts: Debt[] = [];
    
    switch (ownProps.type) {
      case DebtListType.Creditor:
        Debts = state.CashFlowDebts?.DebtsAsCreditor || [];
        break;
      case DebtListType.Debtor:
        Debts = state.CashFlowDebts?.DebtsAsDebtor || [];
        break;
    }

    const persons = state.Persons?.list || [];

    return {
      isLoading: state.CashFlowDebts?.isLoadingAll,
      isUpdating: state.CashFlowDebts?.isUpdatingAll,
      isUpdatedSuccessfully: state.CashFlowDebts?.successLoadingAll,
      isLoadingSuccessfully: state.CashFlowDebts?.successLoadingAll,
      failOnUpdating: state.CashFlowDebts?.failOnLoadingAll,
      failOnLoading: state.CashFlowDebts?.failOnLoadingAll,
      error: state.CashFlowDebts?.error,
      Debts: Debts,
      GetPersonByIdentifier: (identifier: string) => GetPersonByIdentifier(persons, CurrentTenantId, identifier)
    }
  };
  
  export const mapDispatchToProps = {
    ...DebtsStore.actionCreators,
    ...PersonsStore.actionCreators
  };


  // grid layout
  export type FileCell = {
    label: string;
    icon: JSX.Element;
  };
  
  export type LastUpdatedCell = {
    label: string;
    timestamp: number;
  };
  
  export type LastUpdateCell = {
    label: string;
    icon: JSX.Element;
  };
  
  export type AuthorCell = {
    label: string;
    status: PresenceBadgeStatus;
  };


  // get person by identifier
const GetPersonByIdentifier = (persons: Person[], TenantId: string, identifier: string): Person | undefined => {
  if(identifier === TenantId) return {
    personIdentifier: TenantId,
    businessName: 'Centro Médico',
  } as Person;
  return persons.find((person: Person) => person.personIdentifier === identifier);
}