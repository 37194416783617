// actions
import * as Actions from '../actions';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/Sponsors.Service';
import { Sponsor } from '../../models/Entities/Sponsor/Sponsor';
import { ErrorType } from '../../models/HttpError';
import { NewSponsor } from '../../models/Entities/Sponsor/NewSponsor';
import { SponsorContract } from '../../models/Entities/Sponsor/SponsorContracts';

type KnownAction =
  | Actions.Sponsor.Sponsors_GetAll_Request_Action
  | Actions.Sponsor.Sponsors_GetAll_Receive_Action
  | Actions.Sponsor.Sponsors_GetAll_Fail_Action
  | Actions.Sponsor.Sponsor_GetById_Request_Action
  | Actions.Sponsor.Sponsor_GetById_Receive_Action
  | Actions.Sponsor.Sponsor_GetById_Fail_Action
  | Actions.Sponsor.Sponsor_Add_Request_Action
  | Actions.Sponsor.Sponsor_Add_Receive_Action
  | Actions.Sponsor.Sponsor_Add_Fail_Action
  | Actions.Sponsor.Sponsor_Update_Request_Action
  | Actions.Sponsor.Sponsor_Update_Receive_Action
  | Actions.Sponsor.Sponsor_Update_Fail_Action
  | Actions.Sponsor.Sponsor_Delete_Request_Action
  | Actions.Sponsor.Sponsor_Delete_Receive_Action
  | Actions.Sponsor.Sponsor_Delete_Fail_Action
  | Actions.Sponsor.Sponsor_sync_Request_Action
  | Actions.Sponsor.Sponsor_sync_Receive_Action
  | Actions.Sponsor.Sponsor_sync_Fail_Action
  | Actions.Sponsor.Sponsor_Contract_Association_Request_Action
  | Actions.Sponsor.Sponsor_Contract_Association_Receive_Action
  | Actions.Sponsor.Sponsor_Contract_Association_Fail_Action
  | Actions.Sponsor.Sponsor_Contract_Disassociation_Request_Action
  | Actions.Sponsor.Sponsor_Contract_Disassociation_Receive_Action
  | Actions.Sponsor.Sponsor_Contract_Disassociation_Fail_Action
  | Actions.Sponsor.Sponsor_GetAll_ContractAssociations_Request_Action
  | Actions.Sponsor.Sponsor_GetAll_ContractAssociations_Receive_Action
  | Actions.Sponsor.Sponsor_GetAll_ContractAssociations_Fail_Action;



export const actionCreators = {
  GetAllSponsors: (
    pageNumber: number,
    pageSize: number,
    searchText: string | undefined
  ): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'SPONSORS_GETALL_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({ type: 'SPONSORS_GETALL_REQUEST_ACTION' });

      Services.GetSponsors(pageNumber, pageSize, searchText, token)
        .then((sponsors: Sponsor[]) => {
          dispatch({
            type: 'SPONSORS_GETALL_RECEIVE_ACTION',
            sponsors: sponsors
          });
        })
        .catch((error: ErrorType) =>
          dispatch({ type: 'SPONSORS_GETALL_FAIL_ACTION', error: error })
        );
    };
  },
  GetSponsorById: (id: number): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'SPONSOR_GETBYID_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({ type: 'SPONSOR_GETBYID_REQUEST_ACTION', SponsorId: id });

      Services.GetSponsor(id, token)
        .then((sponsor: Sponsor) => {
          dispatch({
            type: 'SPONSOR_GETBYID_RECEIVE_ACTION',
            Sponsor: sponsor
          });
        })
        .catch((error: ErrorType) =>
          dispatch({ type: 'SPONSOR_GETBYID_FAIL_ACTION', error: error })
        );
    };
  },
  AddSponsor: (sponsor: NewSponsor): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'SPONSOR_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({ type: 'SPONSOR_ADD_REQUEST_ACTION', newSponsor: sponsor });

      Services.AddSponsor(sponsor, token)
        .then((sponsor: Sponsor) => {
          dispatch({
            type: 'SPONSOR_ADD_RECEIVE_ACTION',
            Sponsor: sponsor
          });
        })
        .catch((error: ErrorType) =>
          dispatch({ type: 'SPONSOR_ADD_FAIL_ACTION', error: error })
        );
    };
  },
  UpdateSponsor: (sponsor: Sponsor): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'SPONSOR_UPDATE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({ type: 'SPONSOR_UPDATE_REQUEST_ACTION', Sponsor: sponsor });

      Services.SaveSponsor(sponsor, token)
        .then((sponsor: Sponsor) => {
          dispatch({
            type: 'SPONSOR_UPDATE_RECEIVE_ACTION',
            Sponsor: sponsor
          });
        })
        .catch((error: ErrorType) =>
          dispatch({ type: 'SPONSOR_UPDATE_FAIL_ACTION', error: error })
        );
    };
  },
  DeleteSponsor: (id: number): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'SPONSOR_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }
      
      dispatch({ type: 'SPONSOR_DELETE_REQUEST_ACTION', SponsorId: id });

      //   Services.DeleteSponsor(id)
      //     .then((sponsor: Sponsor) => {
      //       dispatch({
      //         type: 'SPONSOR_DELETE_RECEIVE_ACTION',
      //         SponsorId: sponsor.id
      //       });
      //     })
      //     .catch((error: ErrorType) =>
      //       dispatch({ type: 'SPONSOR_DELETE_FAIL_ACTION', error: error })
      //     );
    };
  },
  AssociateContract: (
    sponsorId: number,
    contractId: number
  ): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'SPONSOR_CONTRACT_ASSOCIATION_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'SPONSOR_CONTRACT_ASSOCIATION_REQUEST_ACTION',
        SponsorId: sponsorId,
        ContractId: contractId
      });

      Services.AssociateContract(sponsorId, contractId, token)
        .then((contract: SponsorContract) => {
          dispatch({
            type: 'SPONSOR_CONTRACT_ASSOCIATION_RECEIVE_ACTION',
            SponsorContract: contract
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'SPONSOR_CONTRACT_ASSOCIATION_FAIL_ACTION',
            error: error
          })
        );
    };
  },
  DisassociateContract: (
    SponsorContractId: number,
    SponsorId: number
  ): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'SPONSOR_CONTRACT_DISASSOCIATION_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'SPONSOR_CONTRACT_DISASSOCIATION_REQUEST_ACTION',
        SponsorContractId: SponsorContractId,
        SponsorId: SponsorId
      });

      Services.DisassociateContract(SponsorContractId, token)
        .then((sponsor: Sponsor) => {
          dispatch({
            type: 'SPONSOR_CONTRACT_DISASSOCIATION_RECEIVE_ACTION',
            SponsorContractId: SponsorContractId,
            SponsorId: SponsorId
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'SPONSOR_CONTRACT_DISASSOCIATION_FAIL_ACTION',
            error: error
          })
        );
    };
  },
  GetAllContractAssociations: (
    sponsorId: number
  ): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'SPONSOR_GETALL_CONTRACTASSOCIATIONS_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'SPONSOR_GETALL_CONTRACTASSOCIATIONS_REQUEST_ACTION',
        SponsorId: sponsorId
      });

      Services.GetSponsorContracts(sponsorId, token)
        .then((contracts: SponsorContract[]) => {
          dispatch({
            type: 'SPONSOR_GETALL_CONTRACTASSOCIATIONS_RECEIVE_ACTION',
            Contracts: contracts,
            SponsorId: sponsorId
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'SPONSOR_GETALL_CONTRACTASSOCIATIONS_FAIL_ACTION',
            error: error
          })
        );
    };
  },
  SyncSponsor : (): AppThunkAction<KnownAction> => {
    return async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'SPONSOR_SYNC_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({ type: 'SPONSOR_SYNC_REQUEST_ACTION' });

      Services.SyncSponsor(token)
        .then((sponsor: Sponsor) => {

          Services.GetSponsors(0, 100, undefined, token).then((sponsors: Sponsor[]) => {
            dispatch({
              type: 'SPONSOR_SYNC_RECEIVE_ACTION',
              Sponsors: sponsors
            });
          }).catch((error: ErrorType) =>
            dispatch({ type: 'SPONSOR_SYNC_FAIL_ACTION', error: error })
          );   
        })
        .catch((error: ErrorType) =>
          dispatch({ type: 'SPONSOR_SYNC_FAIL_ACTION', error: error })
        );
    };
  }
};
