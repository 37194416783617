import { IBasePickerSuggestionsProps, ITag } from '@fluentui/react';

//stores 
import * as ContractStore from '../../../../redux/middleware/ContractMiddleware';
import { AppState } from '../../../../redux/reducers';

// models
import { Contract } from '../../../../models/Entities/Contracts/Contract';
import { ErrorType } from '../../../../models/HttpError';



//#region Props

interface IProps {
  onSelectedContract: (contract: Contract[]) => void;
  ContractsSelected: number[] | undefined;
  MultiselectEnable: boolean;
  noteText?: string;
  disabled?: boolean;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Contracts: Contract[];
  SelectecContracts: number[];
}

interface MedicalServicesDispatchProps {
  GetAllContracts: typeof ContractStore.actionCreators.GetAllContracts;
  GetContractById: typeof ContractStore.actionCreators.GetContractById;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  isLoading: state.Contract?.isLoadingAll,
  isUpdating: state.Contract?.isLoadingAll,
  isUpdatedSuccessfully: state.Contract?.successLoadingAll,
  isLoadingSuccessfully: state.Contract?.successLoadingAll,
  failOnUpdating: state.Contract?.failOnLoadingAll,
  failOnLoading: state.Contract?.failOnLoadingAll,
  error: state.Contract?.error,
  Contracts: state.Contract?.list?.sort(
    (a: Contract, b: Contract) => a.contractNumber.localeCompare(b.contractNumber)
  ),
  CurrentContract: ownProps.ContractsSelected ? state.Contract?.list?.filter((contract: Contract) => contract.id in ownProps.ContractsSelected!) as Contract[] : []
});

export const mapDispatchToProps = (dispatch: any) => {

  dispatch(ContractStore.actionCreators.GetAllContracts());

  return {
    ...ContractStore.actionCreators
  }
};

//#endregion Props

export const GetContractsOptions = async (contracts: Contract[]): Promise<ITag[]> => {
  
  return contracts?.map((item: Contract) => {
    return {
      key: item.id,
      name: item.contractNumber
    };
  }) as ITag[];
};

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};


export function GetSelectedContract(
  contractsId: number[],
  contractsList: Contract[]
): ITag[] {
  const contracts: ITag[] = [];

  contractsId.forEach(async (id) => {
    var currentContact = contractsList.find((contract: Contract) => contract.id === id);
    if (currentContact) {
      contracts.push({
        key: currentContact.id,
        name: currentContact.contractNumber
      });
    };
  });

  return contracts;
}

export const getTextFromItem = (item: ITag) => item.name;

export const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'contratos sugeridos',
  noResultsFoundText: 'No se encontro contrato'
};
