import { Action, Reducer } from 'redux';
import { ContractCommissionConceptsState, ContractCommissionConceptsUnloadState } from '../states/ContractCommissionConceptState';

// actions
import * as Actions from '../actions/';

// functions
import * as Functions from '../../functions/common';


type KnownAction =
    Actions.ContractCommissionConcepts.ContractCommissionConcept_GetAll_Request_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_GetAll_Success_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_GetAll_Failure_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_GetById_Request_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_GetById_Success_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_GetById_Failure_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_Create_Request_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_Create_Success_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_Create_Failure_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_Update_Request_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_Update_Success_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_Update_Failure_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_Delete_Request_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_Delete_Success_Action |
    Actions.ContractCommissionConcepts.ContractCommissionConcept_Delete_Failure_Action;


export const ContractCommissionConceptReducer: Reducer<ContractCommissionConceptsState> = (
    state: ContractCommissionConceptsState | undefined, incomingAction: Action): ContractCommissionConceptsState => {

    if (state === undefined) {
        return ContractCommissionConceptsUnloadState as ContractCommissionConceptsState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CONTRACT_COMMISSION_CONCEPT_GET_ALL_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_CONCEPT_GET_ALL_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
                list: action.contractCommissionConcepts
            };
        case 'CONTRACT_COMMISSION_CONCEPT_GET_ALL_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_CONCEPT_GET_BY_ID_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: undefined,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_CONCEPT_GET_BY_ID_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined,
            };
        case 'CONTRACT_COMMISSION_CONCEPT_GET_BY_ID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_CONCEPT_CREATE_REQUEST':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_CONCEPT_CREATE_SUCCESS':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                error: undefined,
            };
        case 'CONTRACT_COMMISSION_CONCEPT_CREATE_FAILURE':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_CONCEPT_UPDATE_REQUEST':
            return {
                ...state,
                isUpdatingOne: true,
                successUpdatingOne: undefined,
                failOnUpdatingOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_CONCEPT_UPDATE_SUCCESS':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: true,
                failOnUpdatingOne: false,
                error: undefined,
            };
        case 'CONTRACT_COMMISSION_CONCEPT_UPDATE_FAILURE':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: false,
                failOnUpdatingOne: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_CONCEPT_DELETE_REQUEST':
            return {
                ...state,
                isDeletingOne: true,
                successDeletingOne: undefined,
                failOnDeletingOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_CONCEPT_DELETE_SUCCESS':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: true,
                failOnDeletingOne: false,
                error: undefined,
            };
        case 'CONTRACT_COMMISSION_CONCEPT_DELETE_FAILURE':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: false,
                failOnDeletingOne: true,
                error: action.error
            };
        default:
            return state;
    }
}