import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Badge, Divider, Field, Label, Tab, TabList, Text } from "@fluentui/react-components";
import { connect } from "react-redux";

// icons
import { ArrowCurveDownRight20Filled, ArrowCurveUpRight20Filled, ArrowDown20Filled, ArrowUp20Filled} from "@fluentui/react-icons";

// functions 
import * as ownFunctions from './Cashflow.General.Functions';	

import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";

// styles
import "./Cashflow.General.Styles.css";

// models
import { DebtListType } from "../../../components/CashFlow/Debts/Debt.List/Debts.List.Functions";

// components
import PaymentsListComponent from "../../../components/CashFlow/Payments/Payments.List/Payments.List.Component";
import { PaymentsGraphComponent } from "../../../components/CashFlow/Payments/Payments.Graph/Payments.Graph.Component";
import { PaymentListType } from "../../../components/CashFlow/Payments/Payments.List/Payments.List.Functions";

import DateRangeSelectorComponent from "../../../commons/component/Inputs/DateRangeSelector/DateRangeSelector.Component";
import DebtListComponent from "../../../components/CashFlow/Debts/Debt.List/Debts.List.Component";
import { use } from "i18next";

const CashFlowGeneralComponent = (props: ownFunctions.Props) => {
    const [t] = useTranslation();

  const [selectedValue, setSelectedValue] = useState<TabValue>('payments-done');

  // page attributes
  const [startDate, setStartDate] = useState<Date>(substractMonth(new Date));
  const [endDate, setEndDate] = useState<Date>(new Date());

  const handleOnDateChange = (startDate: Date | undefined, endDate: Date | undefined) => {
    setStartDate(startDate || new Date());
    setEndDate(endDate || new Date());
  }


  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };


  useEffect(() => {
    props.GetAllDebtsByCreditor(props.EntityIdentifier, props.FixedAssociatedEntityIdentifier);
    props.GetAllDebtsByDebtor(props.EntityIdentifier, props.FixedAssociatedEntityIdentifier);
    props.GetAllPaymentsByPayer(props.EntityIdentifier, props.FixedAssociatedEntityIdentifier);
    props.GetAllPaymentsByReceiver(props.EntityIdentifier, props.FixedAssociatedEntityIdentifier);
  }, [props.EntityIdentifier]);


  return (
    <>
      <div className="Section" title="Periodo informado">
            <Label>Período:</Label>
            <DateRangeSelectorComponent onDateChange={handleOnDateChange} startDateLabel={""} startDate={startDate} endDateLabel={""} endDate={endDate} className={"DateRangeElement"} />
      </div>
      <div className="Section">
        <PaymentsGraphComponent startDate={startDate} endDate={endDate} entityIdentifier={props.EntityIdentifier} relatedEntityIdentifier={props.FixedAssociatedEntityIdentifier}  />
      </div>
      <div className="SectionTabs">
        <TabList selectedValue={selectedValue} onTabSelect={onTabSelect} defaultSelectedValue={selectedValue}>
          <Tab value="payments-pending-topay" icon={<ArrowCurveDownRight20Filled />} className="TabContent">
            <Label>Pendientes de cobro<Badge style={{marginLeft: 10}} appearance="filled" color="brand">{props.QuantityDebtByCreditor}</Badge></Label>
          </Tab>
          <Tab value="payments-pending-toreceive" icon={<ArrowCurveUpRight20Filled />} >
            <Label>Pendientes de pago<Badge style={{marginLeft: 10}} appearance="filled" color="brand">{props.QuantityDebtByDebtor}</Badge></Label>
          </Tab>
          <Tab value="payments-done" icon={<ArrowUp20Filled />}>
            <Label>Pagos Realizados<Badge style={{marginLeft: 10}} appearance="filled" color="brand">{props.QuantityPaymentsByPayer}</Badge></Label>
          </Tab>
          <Tab value="payments-received" icon={<ArrowDown20Filled />}>
            <Label>Cobros Realizados<Badge style={{marginLeft: 10}} appearance="filled" color="brand">{props.QuantityPaymentsByReceiver}</Badge></Label>
          </Tab>
        </TabList>
        <div className='Section PaymentTabContent'>
          {selectedValue === 'payments-pending-topay' && <DebtListComponent type={DebtListType.Creditor} EntityIdentifier={props.EntityIdentifier} FixedAssociatedEntityIdentifier={props.FixedAssociatedEntityIdentifier} />}
          {selectedValue === 'payments-pending-toreceive' && <DebtListComponent type={DebtListType.Debtor} EntityIdentifier={props.EntityIdentifier} FixedAssociatedEntityIdentifier={props.FixedAssociatedEntityIdentifier} />}
          {selectedValue === 'payments-done' && <PaymentsListComponent type={PaymentListType.Payer} EntityIdentifier={props.EntityIdentifier} FixedAssociatedEntityIdentifier={props.FixedAssociatedEntityIdentifier} />}
          {selectedValue === 'payments-received' && <PaymentsListComponent type={PaymentListType.Receiver} EntityIdentifier={props.EntityIdentifier} FixedAssociatedEntityIdentifier={props.FixedAssociatedEntityIdentifier} />}
        </div>
      </div>

    </>
  )
};

// arrow function to substrat a month for a given date
const substractMonth = (date: Date): Date => {
    return new Date(date.setMonth(date.getMonth() - 1));
  }

export default connect(
    ownFunctions.mapStateToProps,
    ownFunctions.mapDispatchToProps
) (CashFlowGeneralComponent as any);