//store 
import * as PractitionerProtocolVisitsStore from "../../../../redux/middleware/PractitionerProtocolVisitsMiddleware";
import * as ProtocolStore from "../../../../redux/middleware/MedicalProtocolMiddleware";


// models
import { PatientVisit } from "../../../../models/Entities/Protocols/PatientVisist";
import { AppState } from "../../../../redux/reducers";
import { Practitioner, PractitionerVisit } from "../../../../models/Entities/Practitioners/Practitioner";
import moment from "moment";
import { PatientVisitCount } from "../../../../models/Entities/Protocols/PatientVisitCount";

interface IProps {
    PractitionerId: string;
    protocolIds: number[];
    day: Date
}
  
interface ConnectedProps {
    visit: PatientVisitCount
}

interface DispatchProps {
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    visit: state.Practitioner?.list?.find((practitioner: Practitioner) => practitioner.userId === ownProps.PractitionerId)?.ProtocolVisitsCount?.find((visit: PatientVisitCount) => 
     moment(visit.date).format("DDMMYYYY") === moment(ownProps.day).format("DDMMYYYY")) || []
});

export const mapDispatchToProps = {
...PractitionerProtocolVisitsStore.actionCreators,
...ProtocolStore.actionCreators
};