import { IButtonProps, ICommandBarItemProps, IIconProps } from "@fluentui/react";
import { ErrorType } from "../../../models/HttpError";
import { Settlement } from "../../../models/Entities/Settlements/Settlement";
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";

// stores
import * as SettlementStore from '../../../redux/middleware/SettlementMiddleware';
import * as SettlementDetailStore from '../../../redux/middleware/SettlementDetailsMiddleware';

//State
import { AppState } from "../../../redux/reducers";

interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    SettlementId: number;
    SettlementResumeId?: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    error: ErrorType | undefined;
    Settlement: Settlement | undefined;
    SettlementDetails: SettlementDetail[] | undefined;
  }
  
  interface DispatchProps {
    GetSettlementById: typeof SettlementStore.actionCreators.GetSettlementById;
    GetAllSettlementDetails: typeof SettlementDetailStore.actionCreators.GetAllSettlementDetails;
    ChangeExcludeStatus: typeof SettlementDetailStore.actionCreators.ChangeExcludeStatus;
    MarkOk: typeof SettlementDetailStore.actionCreators.MarkOk;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.SettlementDetails?.isLoadingAll,
    isUpdating: state.SettlementDetails?.isLoadingAll,
    isUpdatedSuccessfully: state.SettlementDetails?.successLoadingAll,
    isLoadingSuccessfully: state.SettlementDetails?.successLoadingAll,
    failOnUpdating: state.SettlementDetails?.failOnLoadingAll,
    failOnLoading: state.SettlementDetails?.failOnLoadingAll,
    isDeleting: state.SettlementDetails?.isDeletingOne,
    isDeletedSuccessfully: state.SettlementDetails?.successDeletingOne,
    failOnDeleting: state.SettlementDetails?.failOnDeletingOne,
    error: state.SettlementDetails?.error,
    Settlement: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId ) : undefined,
    SettlementDetails: ownProps.SettlementResumeId ? 
        ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((details: SettlementDetail) => details.settlementResumeId === ownProps.SettlementResumeId) : undefined 
        : 
        ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details : undefined,
  });
  
  export const mapDispatchToProps = {
    ...SettlementStore.actionCreators,
    ...SettlementDetailStore.actionCreators
  };

  /*
    Contract Status
    _____________________
    Active = 1,
    Inactive = 2,
    Suspended = 3,
    Expired = 4,
    Canceled = 5,
    Terminated = 6
*/

  export const GetSettlementStatusDescripcion = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "En revisión";
        case 1:
            return "Activo";
        case 2:
            return "Inactivo";
        case 3:
            return "suspendido";
        case 4:
            return "Expirado";
        case 5:
            return "Cancelado";
        case 6:	
            return "Terminado";
        default:
        return "En revisión";
    }
};

  export const GetContractStatusClass = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "";
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "Suspended";
        case 4:
            return "Expired";
        case 5:
            return "Canceled";
        case 6:
            return "Terminated";
        default:
        return "";
  }
};


//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const EditIcon: IIconProps = { iconName: 'Edit' };
export const Exclude: IIconProps = { iconName: 'Share' };
export const Include: IIconProps = { iconName: 'Installation' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const VerifycationOk: IIconProps = { iconName: 'CheckMark' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };
export const ItemObservedIcon: IIconProps = { iconName: 'SkypeMessage' };


// #region multiselect menu 
export const overflowProps: IButtonProps = { ariaLabel: 'More commands' };
export const _items: ICommandBarItemProps[] = [
    {
      key: 'checkok',
      text: 'Marcar como verificados',
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'CheckMark' }
    },
    {
      key: 'observe',
      text: 'Observar',
      iconProps: { iconName: 'Upload' },
    },
    {
      key: 'exclude',
      text: 'excluir',
      iconProps: { iconName: 'Share' },
      onClick: () => console.log('Share'),
    },
    {
      key: 'include',
      text: 'Incluir',
      iconProps: { iconName: 'Installation' },
      onClick: () => console.log('Download'),
    },
  ];
  
  export const _overflowItems: ICommandBarItemProps[] = [
    // { key: 'move', text: 'Move to...', onClick: () => console.log('Move to'), iconProps: { iconName: 'MoveToFolder' } },
    // { key: 'copy', text: 'Copy to...', onClick: () => console.log('Copy to'), iconProps: { iconName: 'Copy' } },
    // { key: 'rename', text: 'Rename...', onClick: () => console.log('Rename'), iconProps: { iconName: 'Edit' } },
  ];
  
  export const _farItems: ICommandBarItemProps[] = [
    // {
    //   key: 'tile',
    //   text: 'Grid view',
    //   // This needs an ariaLabel since it's icon-only
    //   ariaLabel: 'Grid view',
    //   iconOnly: true,
    //   iconProps: { iconName: 'Tiles' },
    //   onClick: () => console.log('Tiles'),
    // },
    // {
    //   key: 'info',
    //   text: 'Info',
    //   // This needs an ariaLabel since it's icon-only
    //   ariaLabel: 'Info',
    //   iconOnly: true,
    //   iconProps: { iconName: 'Info' },
    //   onClick: () => console.log('Info'),
    // },
  ];

  
  // #endregion multiselect menu