import { MedicalInsurance } from '../models/Entities/MedicalInsurances/MedicalInsurance';
import { IResult } from '../models/HttpResult';
import { MedicalInsurancePlan } from '../models/Entities/MedicalInsurances/MedicalInsurancePlan';
import { NewMedicalInsurancePlan } from '../models/Entities/MedicalInsurances/NewMedicalInsurancePlan';


// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

const BaseURL =  process.env.REACT_APP_BILLER_API_GATEWAY + 'medicalinsurances/';

export const GetAllMedicalInsurancePlansByMedicalInsuranceId = async (
  medicalInsuranceId: number, token: string): Promise<MedicalInsurancePlan[]> => {
  
    const RequestURL: string = BaseURL + medicalInsuranceId + '/plans';

  try {
    const Response: MedicalInsurancePlan[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsurancePlan[]>>;
        }
      })
      .then((data: IResult<MedicalInsurancePlan[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          Sentry.captureException(error);
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetMedicalInsurancePlanById = async (
  medicalInsurancePlanId: number, token: string): Promise<MedicalInsurancePlan> => {
  
    const RequestURL: string = BaseURL + '/plan/' + medicalInsurancePlanId;

  try {
    const Response: MedicalInsurancePlan = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsurancePlan>>;
        }
      })
      .then((data: IResult<MedicalInsurancePlan>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          Sentry.captureException(error);
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AddMedicalInsurancePlan = async (newMedicalInsurancePlan: NewMedicalInsurancePlan, token: string): Promise<MedicalInsurancePlan> => {
  
  const RequestURL: string = BaseURL;
  
  try {
    const Response: MedicalInsurancePlan = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(newMedicalInsurancePlan)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsurancePlan>>;
        }
      })
      .then((data: IResult<MedicalInsurancePlan>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          Sentry.captureException(error);
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const UpdateMedicalInsurancePlan = async (medicalInsurancePlan: MedicalInsurancePlan, token: string): Promise<MedicalInsurancePlan> => {
  
  const RequestURL: string = BaseURL;
  
  try {
    const Response: MedicalInsurancePlan = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(medicalInsurancePlan)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsurancePlan>>;
        }
      })
      .then((data: IResult<MedicalInsurancePlan>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          Sentry.captureException(error);
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DeleteMedicalInsurancePlan = async (medicalInsuranceId: number, token: string): Promise<null> => {
  
  const RequestURL: string = BaseURL + medicalInsuranceId;
  
  try {
    const Response: null = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      } else {
        return response.json() as Promise<IResult<MedicalInsurance>>;
      }
    })
    .then((data: IResult<MedicalInsurance>) => {
      return data.data;
    })
    .catch((error: any) =>
        error.text().then((body: any) => {
          Sentry.captureException(error);
          throw Functions.HttpErrorHandler(body, error);
        })
      );
    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
