import { NewPerson } from "../../models/Entities/Person/NewPerson";
import { Person } from "../../models/Entities/Person/Person";
import { ErrorType } from "../../models/HttpError";


// get all persons
export const PERSON_GETALL_REQUEST = 'PERSON_GETALL_REQUEST';
export const PERSON_GETALL_SUCCESS = 'PERSON_GETALL_SUCCESS';
export const PERSON_GETALL_FAILURE = 'PERSON_GETALL_FAILURE';

export interface PersonGetAllRequestAction {
    type: typeof PERSON_GETALL_REQUEST;
}

export interface PersonGetAllSuccessAction {
    type: typeof PERSON_GETALL_SUCCESS;
    payload: Person[];
}

export interface PersonGetAllFailureAction {
    type: typeof PERSON_GETALL_FAILURE;
    error: ErrorType;
}

// get person by id

export const PERSON_GETBYID_REQUEST = 'PERSON_GETBYID_REQUEST';
export const PERSON_GETBYID_SUCCESS = 'PERSON_GETBYID_SUCCESS';
export const PERSON_GETBYID_FAILURE = 'PERSON_GETBYID_FAILURE';

export interface PersonGetbyIdRequestAction {
    type: typeof PERSON_GETBYID_REQUEST;
    payload: number;
}

export interface PersonGetByIdSuccessAction {
    type: typeof PERSON_GETBYID_SUCCESS;
    payload: Person;
}

export interface PersonGetByIdFailureAction {
    type: typeof PERSON_GETBYID_FAILURE;
    error: ErrorType;
}

// create person

export const PERSON_CREATE_REQUEST = 'PERSON_CREATE_REQUEST';
export const PERSON_CREATE_SUCCESS = 'PERSON_CREATE_SUCCESS';
export const PERSON_CREATE_FAILURE = 'PERSON_CREATE_FAILURE';

export interface PersonCreateRequestAction {
    type: typeof PERSON_CREATE_REQUEST;
    payload: NewPerson;
}

export interface PersonCreateSuccessAction {
    type: typeof PERSON_CREATE_SUCCESS;
    payload: Person;
}

export interface PersonCreateFailureAction {
    type: typeof PERSON_CREATE_FAILURE;
    error: ErrorType;
}

// update person

export const PERSON_UPDATE_REQUEST = 'PERSON_UPDATE_REQUEST';
export const PERSON_UPDATE_SUCCESS = 'PERSON_UPDATE_SUCCESS';
export const PERSON_UPDATE_FAILURE = 'PERSON_UPDATE_FAILURE';

export interface PersonUpdateRequestAction {
    type: typeof PERSON_UPDATE_REQUEST;
    payload: Person;
}

export interface PersonUpdateSuccessAction {
    type: typeof PERSON_UPDATE_SUCCESS;
    payload: Person;
}

export interface PersonUpdateFailureAction {
    type: typeof PERSON_UPDATE_FAILURE;
    error: ErrorType;
}

// delete person

export const PERSON_DELETE_REQUEST = 'PERSON_DELETE_REQUEST';
export const PERSON_DELETE_SUCCESS = 'PERSON_DELETE_SUCCESS';
export const PERSON_DELETE_FAILURE = 'PERSON_DELETE_FAILURE';

export interface PersonDeleteRequestAction {
    type: typeof PERSON_DELETE_REQUEST;
    payload: number;
}

export interface PersonDeleteSuccessAction {
    type: typeof PERSON_DELETE_SUCCESS;
    payload: number;
}

export interface PersonDeleteFailureAction {
    type: typeof PERSON_DELETE_FAILURE;
    personId: number;
    error: ErrorType;
}

// fix person identifier

export const PERSON_FIX_IDENTIFIER_REQUEST = 'PERSON_FIX_IDENTIFIER_REQUEST';
export const PERSON_FIX_IDENTIFIER_SUCCESS = 'PERSON_FIX_IDENTIFIER_SUCCESS';
export const PERSON_FIX_IDENTIFIER_FAILURE = 'PERSON_FIX_IDENTIFIER_FAILURE';

export interface PersonFixIdentifierRequestAction {
    type: typeof PERSON_FIX_IDENTIFIER_REQUEST;
}

export interface PersonFixIdentifierSuccessAction {
    type: typeof PERSON_FIX_IDENTIFIER_SUCCESS;
}

export interface PersonFixIdentifierFailureAction {
    type: typeof PERSON_FIX_IDENTIFIER_FAILURE;
    error: ErrorType;
}




