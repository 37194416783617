import React from 'react';
import {
  Dropdown,
  IDropdownOption,
  ITag,
  Label,
  TagPicker
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { MedicalInsurance } from '../../../../models/Entities/MedicalInsurances/MedicalInsurance';
import { MedicalInsurancePlan } from '../../../../models/Entities/MedicalInsurances/MedicalInsurancePlan';

// own functions
import * as ownFunctions from './PractitionerMedicalInsuranceSelectorFunctions';
import { connect } from 'react-redux';

const PractitionerMedicalInsuranceSelectorComponent = (
  props: ownFunctions.Props
) => {

  useEffect(() => {
    props.GetAllMedicalInsurances(1,200);
    props.GetMedicalInsuranceByPractitionerId(props.PractitionerId);
  }, []);

  useEffect(() => {
    props.SelectedMedicalInaurancesOptions.forEach((insurance: ITag) => {
      props.GetMedicalPlansByMedicalInsuranceId(Number(insurance.key));      
    });
  }, []);

  const [InternalSelection, setInternalSelection] =
    useState<ownFunctions.InternalMedicalInsuranceSelected[]>();

  const [OptionsSelected, setOptionsSelected] = useState<ITag[]>();
  const [MedicalInsuranceSelected, setMedicalInsuranceSelected] = useState<
    MedicalInsurance[]
  >([]);
  const [MedicalInsuranceOption, setMedicalInsuranceOption] = useState<ITag[]>(
    []
  );

  const [OptionsPlans, setOptionsPlans] = useState<IDropdownOption[]>([]);

  const onChangeMedicalInsuranceSelector = (items?: ITag[] | undefined) => {
    let MedicalInsuranceComboSelected: MedicalInsurance[] = [];
    if (items && items.length > 0) {
      items.forEach((item: ITag) => {
        props.GetMedicalPlansByMedicalInsuranceId(Number(item.key));
        const MedicalInsuranceId = Number(item.key);
        const medicalInsurance: MedicalInsurance =
          ownFunctions.GetMedicalInsuranceById(MedicalInsuranceId, props.MedicalInsurances as MedicalInsurance[]);

        MedicalInsuranceComboSelected = [
          ...MedicalInsuranceComboSelected,
          medicalInsurance
        ];
      });

      setMedicalInsuranceSelected(MedicalInsuranceComboSelected);
      // setOptionsPlans(
      //   ownFunctions.GetSelectedMedicalInsurancePlans(
      //     MedicalInsuranceComboSelected
      //   )
      // );
    } else {
      setMedicalInsuranceSelected([]);
      setOptionsPlans([]);
      setInternalSelection([]);
    }

    if (items) {
      const removed = OptionsSelected?.filter((x: any) => !items.includes(x));
      const added = items.filter((x: any) => !OptionsSelected?.includes(x));

      let AuxInternalSelection: ownFunctions.InternalMedicalInsuranceSelected[] =
        InternalSelection || [];

      // remove deleted from selected
      if (removed && removed.length > 0) {
        removed?.forEach((item: ITag) => {
          const index = AuxInternalSelection.findIndex(
            (x) => x.MedicalInsuranceId === Number(item.key)
          );
          if (index > -1) {
            AuxInternalSelection.splice(index, 1);
          }
        });
      }

      // add new selected
      if (added.length > 0) {
        added.forEach((item: ITag) => {
          const index = AuxInternalSelection.findIndex(
            (x) => x.MedicalInsuranceId === Number(item.key)
          );
          if (index === -1) {
            AuxInternalSelection.push({
              MedicalInsuranceId: Number(item.key),
              MedicalInsurancePlanID: undefined
            });
          }
        });
      }
      setInternalSelection(AuxInternalSelection);
      props.onChangeSelection && props.onChangeSelection(AuxInternalSelection);
    }
    setOptionsSelected(items || []);
  };

  const onChangeMedicalInsurancePlanSelector = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    MedicalInsuranceSelected.forEach((medicalInsurance: MedicalInsurance) => {
      medicalInsurance.plans.forEach((plan: MedicalInsurancePlan) => {
        let AuxInternalSelection: ownFunctions.InternalMedicalInsuranceSelected[] =
          InternalSelection || [];
        if (
          plan.id ===
          Number(
            option?.key
              .toString()
              .slice(
                option?.key.toString().length -
                  (option?.key.toString().length - 1)
              )
          )
        ) {
          if (option?.selected) {
            // find medical insurance selected without plan
            const MedicalInsuranceIndex = AuxInternalSelection.findIndex(
              (x) =>
                x.MedicalInsuranceId === medicalInsurance.id &&
                !x.MedicalInsurancePlanID
            );
            if (MedicalInsuranceIndex > -1) {
              AuxInternalSelection[
                MedicalInsuranceIndex
              ].MedicalInsurancePlanID = plan.id;
            } else {
              const PlanIndex = AuxInternalSelection.findIndex(
                (x) =>
                  x.MedicalInsuranceId === medicalInsurance.id &&
                  x.MedicalInsurancePlanID === plan.id
              );
              if (PlanIndex === -1) {
                AuxInternalSelection.push({
                  MedicalInsuranceId: medicalInsurance.id,
                  MedicalInsurancePlanID: plan.id
                });
              }
            }
          } else {
            // find medical insurance selected with plan
            const MedicalInsuranceIndex = AuxInternalSelection.findIndex(
              (x) =>
                x.MedicalInsuranceId === medicalInsurance.id &&
                x.MedicalInsurancePlanID === plan.id
            );
            if (MedicalInsuranceIndex > -1) {
              const count = AuxInternalSelection.filter(
                (x) => x.MedicalInsuranceId === medicalInsurance.id
              ).length;
              if (count === 1) {
                AuxInternalSelection[
                  MedicalInsuranceIndex
                ].MedicalInsurancePlanID = undefined;
              } else {
                AuxInternalSelection.splice(MedicalInsuranceIndex, 1);
              }
            }
          }
          props.onChangeSelection &&
            props.onChangeSelection(AuxInternalSelection);
          setInternalSelection(AuxInternalSelection || []);
        }
      });
    });
  };

  useEffect(() => {
    setInternalSelection(props.MedicalInsurancesSelected || []);   
    setMedicalInsuranceOption(props.MedicalInsuranceOptions || []);
  }, []);

  useEffect(() => {
    if (InternalSelection && MedicalInsuranceOption) {
      var insurances = ownFunctions.GetSelectedMedicalInsurance(InternalSelection, props.MedicalInsurances);
      setMedicalInsuranceSelected(insurances);
      setOptionsSelected(
        MedicalInsuranceOption.filter(
          (x: ITag) =>
            x.key ===
            insurances.find((y: MedicalInsurance) => y.id === Number(x.key))
              ?.id
        )
      );
    }
  }, [InternalSelection, MedicalInsuranceOption]);

  useEffect(() => {
    if (MedicalInsuranceSelected && MedicalInsuranceSelected.length > 0) {
      setOptionsPlans(
        ownFunctions.GetSelectedMedicalInsurancePlans(MedicalInsuranceSelected)
      );
    }
  }, [MedicalInsuranceSelected]);

  useEffect(() => {
    props.onChangeSelection && props.onChangeSelection(InternalSelection || []);
  }, [InternalSelection]);

  const filterSuggestedMedicalInsurance = (
    filterText: string,
    tagList: ITag[] | undefined
  ): ITag[] => {
    return filterText
      ? MedicalInsuranceOption.filter(
          (tag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
            !ownFunctions.listContainsTagList(tag, tagList)
        )
      : [];
  };

  return (
    <>
      <Label htmlFor="SnomedOption">Seleccione una cobertura medica</Label>

      <TagPicker
        removeButtonAriaLabel="Eliminar"
        selectionAriaLabel="Seleccione un cobertura"
        onResolveSuggestions={filterSuggestedMedicalInsurance}
        getTextFromItem={ownFunctions.getTextFromItem}
        pickerSuggestionsProps={ownFunctions.pickerSuggestionsProps}
        itemLimit={props.MedicalInsuranceMultiselectEnable ? undefined : 1}
        onChange={onChangeMedicalInsuranceSelector}
        pickerCalloutProps={{ doNotLayer: false }}
        inputProps={{
          id: 'InsuranceOption'
        }}
        selectedItems={OptionsSelected}
      />
      {props.MedicalInsuranceNoteText && (
        <small>{props.MedicalInsuranceNoteText}</small>
      )}
      {props.SelectPlanEnable && (
        <>
          <Dropdown
            label="Planes"
            options={ownFunctions.GetSelectedMedicalInsurancePlans(MedicalInsuranceSelected)}
            disabled={ownFunctions.GetSelectedMedicalInsurancePlans(MedicalInsuranceSelected).length === 0}
            multiSelect={props.MedicalPlansMultiselectEnable}
            onChange={onChangeMedicalInsurancePlanSelector}
            defaultSelectedKeys={
              InternalSelection?.map(
                (plans: ownFunctions.InternalMedicalInsuranceSelected) =>
                  'P' + plans.MedicalInsurancePlanID
              ) as string[]
            }
          />
          {props.MedicalPlanNoteText && (
            <small>{props.MedicalPlanNoteText}</small>
          )}
        </>
      )}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(PractitionerMedicalInsuranceSelectorComponent as any)
