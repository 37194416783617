
import { CommonState } from "../common/state/commonState";

//models
import { ContractCommissionAgentConcept } from "../../models/Entities/Contracts/ContractCommissionAgentConcept";

export interface ContractCommissionAgentConceptState extends CommonState<ContractCommissionAgentConcept> {}

export const ContractCommissionAgentConceptUnloadState: ContractCommissionAgentConceptState = {
    list: [],
    error: undefined,
    isLoadingAll: false,
    successLoadingAll: undefined,
    failOnLoadingAll: false,
    isLoadingOne: false,
    successLoadingOne: undefined,
    failOnLoadingOne: false,
    isAddingNewOne: false,
    successAddingNewOne: undefined,
    failOnAddingNewOne: false,
    isUpdatingOne: false,
    successUpdatingOne: undefined,
    failOnUpdatingOne: false,
    isDeletingOne: false,
    successDeletingOne: undefined,
    failOnDeletingOne: false
};