import { ErrorType } from '../../models/HttpError';
import { AppThunkAction } from '../reducers';

// services
import * as Services from '../../services/SettlementResume.Services';

// actions
import * as Actions from '../actions';
import { SettlementResume } from '../../models/Entities/SettlementResume/SettlementResume';
import { NewSettlementResume } from '../../models/Entities/SettlementResume/NewSettlementResume';
import { Settlement } from '../../models/Entities/Settlements/Settlement';

type KnownAction =
    | Actions.SettlementsResume.SettlementResume_GetAll_Request_Action
    | Actions.SettlementsResume.SettlementResume_GetAll_Receive_Action
    | Actions.SettlementsResume.SettlementResume_GetAll_Fail_Action
    | Actions.SettlementsResume.SettlementResume_UpdateAll_Request_Action
    | Actions.SettlementsResume.SettlementResume_UpdateAll_Receive_Action
    | Actions.SettlementsResume.SettlementResume_UpdateAll_Fail_Action
    | Actions.SettlementsResume.SettlementResume_GetById_Request_Action
    | Actions.SettlementsResume.SettlementResume_GetById_Receive_Action
    | Actions.SettlementsResume.SettlementResume_GetById_Fail_Action
    | Actions.SettlementsResume.SettlementResume_Add_Request_Action
    | Actions.SettlementsResume.SettlementResume_Add_Receive_Action
    | Actions.SettlementsResume.SettlementResume_Add_Fail_Action
    | Actions.SettlementsResume.SettlementResume_Update_Request_Action
    | Actions.SettlementsResume.SettlementResume_Update_Receive_Action
    | Actions.SettlementsResume.SettlementResume_Update_Fail_Action
    | Actions.SettlementsResume.SettlementResume_Delete_Request_Action
    | Actions.SettlementsResume.SettlementResume_Delete_Receive_Action
    | Actions.SettlementsResume.SettlementResume_Delete_Fail_Action;

export const actionCreators = {
    GetAllSettlementResume:
        (settlementId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {

            var state = getState();
            var token: string;

            if (state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTRESUME_GETALL_FAIL_ACTION',
                    error: {
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []
                    }
                });
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            if (!state.SettlementResume?.successLoadingAll) {
                dispatch({
                    type: 'SETTLEMENTRESUME_GETALL_REQUEST_ACTION',
                    settlementId: settlementId
                });

                Services.GetResumesBySettlementId(settlementId, token)
                    .then((settlementResumes: SettlementResume[]) => {
                        dispatch({
                            type: 'SETTLEMENTRESUME_GETALL_RECEIVE_ACTION',
                            settlementResumes: settlementResumes,
                            settlementId: settlementId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'SETTLEMENTRESUME_GETALL_FAIL_ACTION',
                            error: error
                        })
                    );
            } else {
                dispatch({
                    type: 'SETTLEMENTRESUME_UPDATEALL_REQUEST_ACTION',
                    settlementId: settlementId
                });

                Services.GetResumesBySettlementId(settlementId, token)
                    .then((settlementResumes: SettlementResume[]) => {
                        dispatch({
                            type: 'SETTLEMENTRESUME_UPDATEALL_RECEIVE_ACTION',
                            settlementResumes: settlementResumes,
                            settlementId: settlementId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'SETTLEMENTRESUME_UPDATEALL_FAIL_ACTION',
                            error: error
                        })
                    );
            }
        },
    GetSettlementResumeById:
        (id: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTRESUME_GETBYID_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'SETTLEMENTRESUME_GETBYID_REQUEST_ACTION',
                settlementResumeId: id
            });

            if(state.Settlement?.list?.find((item: Settlement) => item.resumes?.find((resume: SettlementResume) => resume.id === id)) === undefined) {
                Services.GetSettlementResumeById(id, token)
                .then((settlementResume: SettlementResume) => {
                    dispatch({
                        type: 'SETTLEMENTRESUME_GETBYID_RECEIVE_ACTION',
                        settlementResume: settlementResume
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'SETTLEMENTRESUME_GETBYID_FAIL_ACTION',
                        error: error
                    })
                );
            }
        },
    AddSettlementResume:
        (newSettlementResume: NewSettlementResume): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTRESUME_ADD_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'SETTLEMENTRESUME_ADD_REQUEST_ACTION',
                newSettlementResume: newSettlementResume
            });

            Services.CreateSettlementResume(newSettlementResume, token)
                .then((settlementResume: SettlementResume) => {
                    dispatch({
                        type: 'SETTLEMENTRESUME_ADD_RECEIVE_ACTION',
                        settlementResume: settlementResume
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'SETTLEMENTRESUME_ADD_FAIL_ACTION',
                        error: error
                    })
                );

        },
    UpdateSettlementResume:
        (settlementResume: SettlementResume): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTRESUME_UPDATE_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'SETTLEMENTRESUME_UPDATE_REQUEST_ACTION',
                settlementResume: settlementResume
            });

            Services.UpdateSettlementResume(settlementResume, token)
                .then((settlementResume: SettlementResume) => {
                    dispatch({
                        type: 'SETTLEMENTRESUME_UPDATE_RECEIVE_ACTION',
                        settlementResume: settlementResume
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'SETTLEMENTRESUME_UPDATE_FAIL_ACTION',
                        error: error
                    })
                );

        },
    DeleteSettlementResume:
        (settlementId: number, settlementResumeId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            
            var state = getState();
            var token: string;

            if(state.oidc?.user?.access_token === undefined) {
                dispatch({
                    type: 'SETTLEMENTRESUME_DELETE_FAIL_ACTION',
                    error: { 
                        ErrorCode: 401,
                        ErrorMessage: 'Not authorized',
                        Errors: []                     
                    }
                })
                return;
            } else {
                token = state.oidc.user.access_token;
            };

            dispatch({
                type: 'SETTLEMENTRESUME_DELETE_REQUEST_ACTION',
                settlementResumeId: settlementResumeId
            });

            Services.DeleteSettlementResume(settlementResumeId, token)
                .then((settlementResumeId: number) => {
                    dispatch({
                        type: 'SETTLEMENTRESUME_DELETE_RECEIVE_ACTION',
                        settlementId: settlementId,
                        settlementResumeId: settlementResumeId
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'SETTLEMENTRESUME_DELETE_FAIL_ACTION',
                        error: error
                    })
                );

        }
};
