import { TenantPriceList } from './../../models/Entities/TenantPriceLists/TenantPriceList';
import { Action, Reducer } from 'redux';
import {
  TenantPriceListState,
  TenantPriceListUnloadedState
} from '../states/TenantPriceListState';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.TenantPriceLists.TenantPriceListGetAll_Request_Action
  | Actions.TenantPriceLists.TenantPriceListGetAll_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListGetAll_Fail_Action
  | Actions.TenantPriceLists.TenantPriceListAdd_Request_Action
  | Actions.TenantPriceLists.TenantPriceListAdd_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListAdd_Fail_Action
  | Actions.TenantPriceLists.TenantPriceListSetStartValidity_Request_Action
  | Actions.TenantPriceLists.TenantPriceListSetStartValidity_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListSetStartValidity_Fail_Action
  | Actions.TenantPriceLists.TenantPriceListSetEndValidity_Request_Action
  | Actions.TenantPriceLists.TenantPriceListSetEndValidity_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListSetEndValidity_Fail_Action
  | Actions.TenantPriceLists.TenantPriceListUpdate_Request_Action
  | Actions.TenantPriceLists.TenantPriceListUpdate_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListUpdate_Fail_Action
  | Actions.TenantPriceLists.TenantPriceListDelete_Request_Action
  | Actions.TenantPriceLists.TenantPriceListDelete_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListDelete_Fail_Action;

export const TenantPriceListReducer: Reducer<TenantPriceListState> = (
  state: TenantPriceListState | undefined,
  incomingAction: Action
): TenantPriceListState => {
  if (state === undefined) {
    return TenantPriceListUnloadedState as TenantPriceListState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'TENANT_PRICE_LIST_GET_ALL_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'TENANT_PRICE_LIST_GET_ALL_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined,
        list: action.PriceList
      };
    case 'TENANT_PRICE_LIST_GET_ALL_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'TENANT_PRICE_LIST_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'TENANT_PRICE_LIST_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (item: TenantPriceList) => item.id !== action.PriceList.id
          ),
          action.PriceList
        ]
      };
    case 'TENANT_PRICE_LIST_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'TENANT_PRICE_LIST_SET_START_VALIDITY_REQUEST_ACTION':
      return {
        ...state,
        isSettingValidityStartDate: true,
        SetValidityStartDateSuccess: undefined,
        FailOnSetValidityStartDate: false,
        error: undefined
      };
    case 'TENANT_PRICE_LIST_SET_START_VALIDITY_RECEIVE_ACTION':
      return {
        ...state,
        isSettingValidityStartDate: false,
        SetValidityStartDateSuccess: true,
        FailOnSetValidityStartDate: false,
        error: undefined,
        list: state.list.map((item: TenantPriceList) =>
          item.id === action.TenantPriceList.id
            ? {
                ...item,
                effectiveDateStart: action.TenantPriceList.effectiveDateStart
              }
            : item
        )
      };
    case 'TENANT_PRICE_LIST_SET_START_VALIDITY_FAIL_ACTION':
      return {
        ...state,
        isSettingValidityStartDate: false,
        SetValidityStartDateSuccess: false,
        FailOnSetValidityStartDate: true,
        error: action.error
      };
    case 'TENANT_PRICE_LIST_SET_END_VALIDITY_REQUEST_ACTION':
      return {
        ...state,
        isSettingValidityEndDate: true,
        SetValidityEndDateSuccess: undefined,
        FailOnSetValidityEndDate: false,
        error: undefined
      };
    case 'TENANT_PRICE_LIST_SET_END_VALIDITY_RECEIVE_ACTION':
      return {
        ...state,
        isSettingValidityEndDate: false,
        SetValidityEndDateSuccess: true,
        FailOnSetValidityEndDate: false,
        error: undefined,
        list: state.list.map((item: TenantPriceList) =>
          item.id === action.TenantPriceList.id
            ? {
                ...item,
                effectiveDateEnd: action.TenantPriceList.effectiveDateEnd
              }
            : item
        )
      };
    case 'TENANT_PRICE_LIST_SET_END_VALIDITY_FAIL_ACTION':
      return {
        ...state,
        isSettingValidityEndDate: false,
        SetValidityEndDateSuccess: false,
        FailOnSetValidityEndDate: true,
        error: action.error
      };
    case 'TENANT_PRICE_LIST_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'TENANT_PRICE_LIST_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        error: undefined,
        list: action.TenantPriceList.id
          ? [
              ...state.list.filter(
                (item: TenantPriceList) => item.id !== action.TenantPriceList.id
              ),
              action.TenantPriceList
            ]
          : state.list
      };
    case 'TENANT_PRICE_LIST_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };
    case 'TENANT_PRICE_LIST_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'TENANT_PRICE_LIST_DELETE_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: state.list.filter(
          (item: TenantPriceList) => item.id !== action.TenantPriceListId
        )
      };
    case 'TENANT_PRICE_LIST_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
  }

  return state;
};
