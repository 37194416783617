import { SettlementResume } from "../../../models/Entities/SettlementResume/SettlementResume";
import { ErrorType } from "../../../models/HttpError";
import { AppState } from "../../../redux/reducers";
import { IIconProps } from "@fluentui/react";

// stores
import * as SettlementResumeStore from '../../../redux/middleware/SettlementResumeMiddleware';
import * as SettlementDetailsStore from '../../../redux/middleware/SettlementDetailsMiddleware';


//Entities 
import { Settlement } from "../../../models/Entities/Settlements/Settlement";
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";



interface IProps {
    SettlementId: number;
    SettlementSummaryItemId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    isDeletedSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    failOnDeleting: boolean;
    error: ErrorType | undefined;
    SettlementDetails: SettlementDetail[] | undefined;
    CurrentSummary: SettlementResume | undefined;
    SummaryCurrencies: string[];
    
  }
  
  interface DispatchProps {
    GetSettlementResumeById: typeof SettlementResumeStore.actionCreators.GetSettlementResumeById;
    DeleteSettlementResume: typeof SettlementResumeStore.actionCreators.DeleteSettlementResume;
    UpdateSettlementResume: typeof SettlementResumeStore.actionCreators.UpdateSettlementResume;
    MassExclude: typeof SettlementDetailsStore.actionCreators.MassExclude;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.SettlementResume?.isLoadingOne,
    isUpdating: state.SettlementResume?.isUpdatingOne,
    isUpdatedSuccessfully: state.SettlementResume?.successLoadingOne,
    isLoadingSuccessfully: state.SettlementResume?.successLoadingOne,
    failOnUpdating: state.SettlementResume?.failOnLoadingOne,
    failOnLoading: state.SettlementResume?.failOnLoadingOne,
    isDeleting: state.SettlementResume?.isDeletingOne,
    isDeletedSuccessfully: state.SettlementResume?.successDeletingOne,
    failOnDeleting: state.SettlementResume?.failOnDeletingOne,
    error: state.SettlementResume?.error,
    Settlement: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId ) : undefined,
    CurrentSummary: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.resumes?.find(
        (item: SettlementResume) => item.id === ownProps.SettlementSummaryItemId
    ) : undefined,
    SettlementDetails: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((details: SettlementDetail) => details.settlementResumeId === ownProps.SettlementSummaryItemId ) : undefined,
    SummaryCurrencies: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((details: SettlementDetail) => details.settlementResumeId === ownProps.SettlementSummaryItemId )
    .reduce(
        (acc: string[], current: SettlementDetail) => {
        if (acc.indexOf(current.currency) === -1) {
            acc.push(current.currency);
        }
        return acc;
        },
        []
    )
     : undefined,
  });
  
  export const mapDispatchToProps = {
    ...SettlementResumeStore.actionCreators,
    ...SettlementDetailsStore.actionCreators
  };


 //#region Icons
 export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
 export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
 export const EditIcon: IIconProps = { iconName: 'Edit' };
 export const ViewIcon: IIconProps = { iconName: 'View' };
 export const LinkContractIcon: IIconProps = { iconName: 'Link' };
 export const DeleteIcon: IIconProps = { iconName: 'Trash' };
 export const Exclude: IIconProps = { iconName: 'Share' };
 export const Include: IIconProps = { iconName: 'Download' };
 export const RefreshIcon: IIconProps = { iconName: 'SyncOccurence' };
 export const PatientDetailsIcon: IIconProps = { iconName: 'UserOptional' };