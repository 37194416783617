import { Action, Reducer } from 'redux';
import { ContractAddendumState, ContractAddendumUnloadState } from '../states/ContractAddendum.State';

// actions
import * as Actions from '../actions/';
import { ContractAddendum } from '../../models/Entities/Contracts/ContractAddendum';

type KnownAction = 
| Actions.ContractAddendum.ContractAddendumsGetByContractIdRequestAction
| Actions.ContractAddendum.ContractAddendumsGetByContractIdSuccessAction
| Actions.ContractAddendum.ContractAddendumsGetByContractIdFailureAction
| Actions.ContractAddendum.ContractAddendumGetByIdRequestAction
| Actions.ContractAddendum.ContractAddendumGetByIdSuccessAction
| Actions.ContractAddendum.ContractAddendumGetByIdFailureAction
| Actions.ContractAddendum.ContractAddendumCreateRequestAction
| Actions.ContractAddendum.ContractAddendumCreateSuccessAction
| Actions.ContractAddendum.ContractAddendumCreateFailureAction
| Actions.ContractAddendum.ContractAddendumUpdateRequestAction
| Actions.ContractAddendum.ContractAddendumUpdateSuccessAction
| Actions.ContractAddendum.ContractAddendumUpdateFailureAction
| Actions.ContractAddendum.ContractAddendumDeleteRequestAction
| Actions.ContractAddendum.ContractAddendumDeleteSuccessAction
| Actions.ContractAddendum.ContractAddendumDeleteFailureAction;

export const ContractAddendumReducer: Reducer<ContractAddendumState> = (state: ContractAddendumState | undefined, incomingAction: Action): ContractAddendumState => {

    if (state === undefined) {
        return ContractAddendumUnloadState as ContractAddendumState;
    }

    const action = incomingAction as KnownAction;

    let CurrentAddendum: ContractAddendum | undefined;

    switch (action.type) {
        case 'CONTRACT_ADDENDUMS_GETBYCONTRACTID_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: false,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CONTRACT_ADDENDUMS_GETBYCONTRACTID_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CONTRACT_ADDENDUMS_GETBYCONTRACTID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CONTRACT_ADDENDUM_GETBYID_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: false,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CONTRACT_ADDENDUM_GETBYID_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CONTRACT_ADDENDUM_GETBYID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CONTRACT_ADDENDUM_CREATE_REQUEST':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: false,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CONTRACT_ADDENDUM_CREATE_SUCCESS':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CONTRACT_ADDENDUM_CREATE_FAILURE':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        case 'CONTRACT_ADDENDUM_UPDATE_REQUEST':
            return {
                ...state,
                isUpdatingOne: true,
                successUpdatingOne: false,
                failOnUpdatingOne: false,
                error: undefined
            };
        case 'CONTRACT_ADDENDUM_UPDATE_SUCCESS':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: true,
                failOnUpdatingOne: false,
                error: undefined
            };
        case 'CONTRACT_ADDENDUM_UPDATE_FAILURE':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: false,
                failOnUpdatingOne: true,
                error: action.error
            };
        case 'CONTRACT_ADDENDUM_DELETE_REQUEST':
            return {
                ...state,
                isDeletingOne: true,
                successDeletingOne: false,
                failOnDeletingOne: false,
                error: undefined
            };
        case 'CONTRACT_ADDENDUM_DELETE_SUCCESS':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: true,
                failOnDeletingOne: false,
                error: undefined
            };
        case 'CONTRACT_ADDENDUM_DELETE_FAILURE':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: false,
                failOnDeletingOne: true,
                error: action.error
            };
        default:
            return state;
    }
}
