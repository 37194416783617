import { useEffect, useState } from 'react';
import { Settlement } from '../../../models/Entities/Settlements/Settlement';
import { useNavigate } from 'react-router-dom';
import { useBoolean } from '@fluentui/react-hooks';
import { connect } from 'react-redux';
import { FontIcon, FontSizes, FontWeights, IconButton, Label, MessageBarType, PrimaryButton, SearchBox, Separator, Spinner, Text } from '@fluentui/react';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import SettlementFormComponent from '../SettlementForm/Settlement.Form.Component';


//fucntions
import * as ComponentFunctions from './SettlementList.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

//styles
import './SettlementList.Styles.css';
import { ConfirmationButtonComponent } from '../../../commons/component/ConfirmationButton/ConfirmationButtonComponent';


export const SettlementListComponent = (props: ComponentFunctions.Props) => {

    const [selectedSettlement, setSelectedSettlement] = useState<Settlement | undefined>(undefined);
  
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
      useBoolean(false);
  
    const [searchText, setSearchText] = useState('');
  
    const navigate = useNavigate();

    //useTranslation()
    const [t] = useTranslation();
  
    useEffect(() => {
      props.GetAllSettlements();
    }, []);
  

    //events
    const onViewSettlementEvent = (settlement: Settlement) => {
        navigate(`/settlements/${settlement.id}`);
        //setSelectedSettlement(settlement);
        //openPanel();
    };

    const onRefreshSettlementEvent = (settlement: Settlement) => {
      props.RefreshSettlementById(settlement.id);
    }
  
    const onSearch = (newValue: string) => {
      setSearchText(newValue);
    };

    const onRequestDeleteSettlement = (settlement: Settlement) => {
      props.DeleteSettlementById(settlement.id);
    };

    const handleClosePanel = () => {
      dismissPanel();
    };
  
  
    //#region Subcontent definition
  
    // component searchbox
    const Searchbox = <SearchBox
      placeholder={t('settlementListComponent.searchBox.search')} 
      //onChange={OnSearch}
      underlined={false}
      onChange={(
        event?: React.ChangeEvent<HTMLInputElement> | undefined,
        newValue?: string | undefined
      ) => (newValue ? onSearch(newValue) : undefined)}
      onClear={() => onSearch('')}
    />
  
    // Principal error message
    const PrincipalErrorMessage = props.failOnLoading && (
      <MessageComponent
        message={
          t('settlementListComponent.errorMessage.message')
        }
        subMessage={props.error?.ErrorMessage || ''}
        type={MessageBarType.error}
        itemList={props.error?.Errors || []}
      />
    );
  
    // Loading
    const Loading = props.isLoading && (
      <div>
        <Spinner
          label={t('settlementListComponent.loading.loadingSettlements')} 
          ariaLive="assertive"
          labelPosition="top"
        />
      </div>
    );

    const Controls = (
      <>
        <div className="Controls">
          <div className="Searchbox">{Searchbox}</div>
          <PrimaryButton
            text={t('settlementListComponent.button.newSettlement')}
            className='actionButton'
            iconProps={ComponentFunctions.AddIcon}
            onClick={openPanel}
          />
        </div>
        <Separator />
      </>
    );

    const NoData = (
      <>
        <p className="NoData">{t('settlementListComponent.noData.noSettlements')}</p>
      </>
    )
  
    // Table of content
    const TableOfContent = props.isLoadingSuccessfully && (
    <table className="Table">
      <thead>
        <tr>
          <th style={{width: 1}}></th>
          <th style={{maxWidth:'5em'}}>{t('settlementListComponent.parameters.settlement')}</th>
          <th>{t('settlementListComponent.parameters.invoicedAmount')}</th>
          <th>{t('settlementListComponent.parameters.state')}</th>
          <th className='optionsColumn'>{t('settlementListComponent.parameters.options')}</th>
        </tr>
      </thead>
      <tbody>
        {props.Settlements.length > 0 && props.Settlements.map((item: Settlement) =>
          item.name?.toLowerCase().includes(searchText.toLowerCase()) ? (
            <tr
              key={item.id}
              className="TableBodyRow"
              onClick={() => onViewSettlementEvent(item)}
            >
              <td className={"primary" + ComponentFunctions.GetSettlementStatusClass(item.status)}>
              <FontIcon className={item.status === 2 ? 'withRotation' : ''} iconName={ComponentFunctions.GetSettlementStatusIcon(item.status)} style={{ marginTop: 8,  fontSize: FontSizes.size24}} />
              </td>
              <td>
                <Label style={{fontWeight : FontWeights.bold}}>{item.name?.toUpperCase()}</Label>
                <Text>{item.identifier}</Text>
              </td>
              <td>{item.totals[0]?.totalValue?.toLocaleString('us-US', { style: 'currency', currency: item.currency?.toUpperCase() }) || '0.00'}&nbsp;<small>({item.totals[0]?.currencyCode})</small></td>
              <td>{t(ComponentFunctions.GetSettlementStatusDescripcion(item.status))}</td>
              <td>
                <IconButton
                  iconProps = {ComponentFunctions.PropertiesIcon}
                  title=" "
                  ariaLabel="stack"
                  onClick={(event) => {event.stopPropagation(); onViewSettlementEvent(item)}}
                />
                <IconButton
                  className={item.isRedreshing ? 'withRotation' : ''}
                  iconProps = {ComponentFunctions.RefreshIcon}
                  title=" "
                  ariaLabel="stack"
                  onClick={(event) => {event.stopPropagation(); onRefreshSettlementEvent(item)}}
                />
                <div className='danger'>
                      <ConfirmationButtonComponent
                        ButtonId={"deleteContract" + item.id}
                        ButtonIconName={"Delete"}
                        ButtonText={""}
                        ButtonCssClass={""}
                        ConfirmationButtonText={t('settlementListComponent.actions.delete.buttonConfirm')}
                        ConfirmationButtonCssClass={"deleteButton"}
                        CancelButtonText={t('settlementListComponent.actions.delete.buttonCancel')}
                        CancelButtonCssClass={""}
                        Title={t('settlementListComponent.actions.delete.title')}
                        Description={""}
                        ElementTextHeader={t('settlementListComponent.actions.delete.title') + item.name}
                        ElementTextInformation={t('settlementListComponent.actions.delete.messageOne')}
                        onConfirmation={() => onRequestDeleteSettlement(item)}
                        onCancel={function (): void {
                          throw new Error('Function not implemented.');
                        }}
                        isOperationExecuting={
                          props.isDeleting
                        }
                        isOperationExecutedWithError={
                          props.failOnDeleting
                        }
                        OperationError={props.error}
                      />
                    </div>
              </td>
            </tr>
          ) : null
        )}
        </tbody>
    </table>
    );

  const Forms = (
    <SettlementFormComponent 
    isOpen={isOpen}
    dismissPanel={handleClosePanel}
    openPanel={openPanel} 
    ContractId={props.ContractId}
    PractitionerId={props.PractitionerId}
    ProtocolId={props.ProtocolId}
    MedicalAgreementId={props.MedicalAgreementId}
    MedicalInsuranceId={props.MedicalInsuranceId}
    />
  )
      
   //#endregion
    
   return (
      <>
        {Controls}
        {PrincipalErrorMessage}
        {(props.Settlements?.length > 0 && !props.isLoading) && TableOfContent}
        {Loading}
        {(props.Settlements?.length === 0 && !props.isLoading) && NoData}
        {Forms}
      </>
    );
  };
  
  export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
  )(SettlementListComponent as any);
  