import { ContractAddendum } from "../models/Entities/Contracts/ContractAddendum";
import { IResult } from "../models/HttpResult";

//Functions
import * as Functions from '../functions/common';
import { NewContractAddendum } from "../models/Entities/Contracts/NewContractAddendum";

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/';

// get contract addendum by contract id

export const GetAddendumsByContract = async (contractId: number, token: string): Promise<ContractAddendum[]> => {

    let RequestURL: string = BaseURL + contractId + '/addendums';

    try {
        const Response: ContractAddendum[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractAddendum[]>>;
            }
        })
        .then((data: IResult<ContractAddendum[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// get addendum by id

export const GetAddendumById = async (addendumId: number, token: string): Promise<ContractAddendum> => {

    let RequestURL: string = BaseURL + 'addendums/' + addendumId;

    try {
        const Response: ContractAddendum = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractAddendum>>;
            }
        })
        .then((data: IResult<ContractAddendum>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// create contract addendum

export const CreateAddendum = async (contractId: number, newAddendum: NewContractAddendum, token: string): Promise<ContractAddendum> => {

    let RequestURL: string = BaseURL + contractId + '/addendums';

    try {
        const Response: ContractAddendum = await fetch(RequestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(newAddendum)
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractAddendum>>;
            }
        })
        .then((data: IResult<ContractAddendum>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// update contract addendum

export const UpdateAddendum = async (addendum: ContractAddendum, token: string): Promise<ContractAddendum> => {

    let RequestURL: string = BaseURL + 'addendums/';

    try {
        const Response: ContractAddendum = await fetch(RequestURL, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(addendum)
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractAddendum>>;
            }
        })
        .then((data: IResult<ContractAddendum>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// delete contract addendum

export const DeleteAddendum = async (addendumId: number, token: string): Promise<boolean> => {

    let RequestURL: string = BaseURL + 'addendums/' + addendumId;

    try {
        const Response: boolean = await fetch(RequestURL, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<boolean>>;
            }
        })
        .then((data: IResult<boolean>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// clone contract addendum

export const CloneAddendum = async (addendumId: number, token: string): Promise<ContractAddendum> => {

    let RequestURL: string = BaseURL + 'addendums/clone';

    try {
        const Response: ContractAddendum = await fetch(RequestURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify({ addendumToCloneId: addendumId })
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<ContractAddendum>>;
            }
        })
        .then((data: IResult<ContractAddendum>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
}
