import { Action, Reducer } from 'redux';

// Actions
import * as Actions from '../actions';
import { UserState, UserUnloadedState } from '../states/UserState';

type KnownAction =
    | Actions.Users.UsersGetAllRequestAction
    | Actions.Users.UsersGetAllSuccessAction
    | Actions.Users.UsersGetAllFailureAction;

    export const UserReducer: Reducer<UserState> = (
        state: UserState | undefined,
        incomingAction: Action
    ): UserState => {

        if (state === undefined) {
            return UserUnloadedState as UserState;
          }
        
          const action = incomingAction as KnownAction;

          switch (action.type) {
            case 'USERS_GETALL_REQUEST':
              return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false
              };
            case 'USERS_GETALL_SUCCESS':
              return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                list: action.users
              };
            case 'USERS_GETALL_FAILURE':
              return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
              };
            default:
              return state;
          }

    };