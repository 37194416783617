
import { ContractConceptConcept } from '../models/Entities/Contracts/ContractConceptConcept';
import { IResult } from '../models/HttpResult';
import { NewContractConceptConcept } from '../models/Entities/Contracts/NewContractConceptConcept';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';


const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'contracts/concepts/';

// Get All Concepts of contract
export const GetContractsConceptConcepts = async (ContractConceptId: number, token: string): Promise<ContractConceptConcept[]> => {

    let RequestURL: string = BaseURL + ContractConceptId + '/concepts/';

    try {
        const Response: ContractConceptConcept[] = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConceptConcept[]>>;
            }
        })
        .then((data: IResult<ContractConceptConcept[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};


// Get contract By Id
export const GetContractConceptConceptById = async (id: number, token: string): Promise<ContractConceptConcept> => {

    let RequestURL: string = BaseURL + id;
    
    try {
        const Response: ContractConceptConcept = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConceptConcept>>;
            }
        })
        .then((data: IResult<ContractConceptConcept>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};


// Create Contract concept
export const CreateContractConceptConcept = async (newContractConceptConcept: NewContractConceptConcept, token: string): Promise<ContractConceptConcept> => {
    
    let RequestURL: string = BaseURL + 'concepts/';
    
    try {
        const Response: ContractConceptConcept = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newContractConceptConcept)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConceptConcept>>;
            }
        })
        .then((data: IResult<ContractConceptConcept>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Update Contract Concept
export const UpdateContractConceptConcept = async (contractConceptConcept: ContractConceptConcept, token: string): Promise<ContractConceptConcept> => {
    
    let RequestURL: string = BaseURL + 'concepts/';
    
    try {
        const Response: ContractConceptConcept = await fetch(RequestURL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(contractConceptConcept)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<ContractConceptConcept>>;
            }
        })
        .then((data: IResult<ContractConceptConcept>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Delete Contract Concept
export const DeleteContractConceptConcept = async (id: number, token: string): Promise<void> => {

    let RequestURL: string = BaseURL + 'concepts/' + id;
    
    try {
        const Response: void = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<void>>;
            }
        })
        .then((data: IResult<void>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Delete Contract Concept by relationship
export const DeleteContractConceptConceptByRelationship = async (conceptId: number, relatedConcept: number, token: string): Promise<void> => {

    let RequestURL: string = BaseURL + 'concepts?ContractConceptId=' + conceptId + '&RelatedContractConceptId=' + relatedConcept;
    
    try {
        const Response: void = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<void>>;
            }
        })
        .then((data: IResult<void>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

