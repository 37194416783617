// functions
import { FontSizes, Label, Text } from '@fluentui/react';
import * as ComponentFunctions from './PracitionerProtocolActivity.Functions';

// styles
import './PractitionerProtocolActivity.Styles.css';

// models
import { PractitionerVisit } from '../../../models/Entities/Practitioners/Practitioner';
import moment from 'moment';
import { Tooltip } from '@fluentui/react-components';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import PractitionerProtocolActivityDayComponent from './PractitionerProtocolActivity.Day/PractitionerProtocolActivity.Day.Component';

export const PractitionerProtocolActivityMonth = (props: { month: number, year: number, practitionerId: string, visits: PractitionerVisit[], protocols: Protocol[] }) => {

    const TransformedVisit = props.visits?.map((visit: PractitionerVisit) => {

        //verify if the visit protocol id exist in the protocols array
        if(
            props.protocols?.length > 0 &&
            props.protocols.some((protocol) => protocol.id === visit.protocolId)
        ) {
            const visitDate = visit.visits?.map((visit) => { return visit.date})
            return visitDate;
        } else {
            return [];
        }
    });

    const FinalDates: Date[] = TransformedVisit.flat();
    
    return (
        <div className='ActivityMonthContainer'>
            <Text style={{fontSize: FontSizes.size16}}><strong>{ComponentFunctions.getMonthName(props.month)}</strong>&nbsp;{props.year}</Text>
            <table className='ActivityGrid'>
                <thead>
                    <tr>
                        {ComponentFunctions.getWeekDays().map((day: string) =>
                            <th>{day}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {ComponentFunctions.getWeeksOfMonth(props.month, props.year).map((week: any) =>
                        <tr>
                            {
                                week.map((day: any) =>
                                new Date(day).getMonth() !== props.month ? 
                                <td>
                                    <div className={'ActivityBox inactive'}>
                                    </div> 
                                </td>
                                :
                                <td>
                                   <PractitionerProtocolActivityDayComponent PractitionerId={props.practitionerId} protocolIds={props.protocols.map((protocol) => protocol.id)} day={day} />
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}