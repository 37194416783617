import { Checkbox, TextField, Toggle } from "@fluentui/react";

// models
import { ContractConcept } from "../../../../../../models/Entities/Contracts/ContractConcept";
import { useState } from "react";

export interface IProps {
    concept: ContractConcept;
    isSelected: boolean;
    onCheckboxChange: (checked: boolean, conceptId: number) => void;
}

export const ProtocolConceptItemComponent = (props: IProps) => {
    
    return (<>
        <Checkbox key={'check' + props.concept.id} label={props.concept?.concept.conceptName} defaultChecked={props.isSelected as boolean} onChange={
            (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
                props.onCheckboxChange(checked!, props.concept.id);
            }
        } />
    </>);
}