import { Action, Reducer } from 'redux';

// states
import { ContractCommissionAgentState, ContractCommissionAgentUnloadState } from '../states/ContractCommissionAgentState';

// actions
import * as Actions from '../actions/';

// models
import { ContractCommissionAgent } from '../../models/Entities/Contracts/ContractCommissionAgent';

type KnownAction =
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_Request_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_Response_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetAll_Failure_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetById_Request_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetById_Response_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_GetById_Failure_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_Create_Request_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_Create_Response_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_Create_Failure_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_Update_Request_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_Update_Response_Action
| Actions.ContractCommissionAgent.ContractCommissionAgent_Update_Failure_Action;

export const ContractCommissionAgentReducer: Reducer<ContractCommissionAgentState> = (
    state: ContractCommissionAgentState | undefined, incomingAction: Action): ContractCommissionAgentState => {

    if (state === undefined) {
        return ContractCommissionAgentUnloadState as ContractCommissionAgentState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CONTRACT_COMMISSION_AGENT_GET_ALL_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_GET_ALL_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
                list: action.contractCommissionAgents
            };
        case 'CONTRACT_COMMISSION_AGENT_GET_ALL_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_AGENT_GET_BY_ID_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: undefined,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_GET_BY_ID_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined,
                list: [...state.list?.filter((agent: ContractCommissionAgent) => agent.id !== action.contractCommissionAgent.id), action.contractCommissionAgent]
            };
        case 'CONTRACT_COMMISSION_AGENT_GET_BY_ID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_AGENT_CREATE_REQUEST':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_CREATE_SUCCESS':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                error: undefined,
                list: [...state.list, action.contractCommissionAgent]
            };
        case 'CONTRACT_COMMISSION_AGENT_CREATE_FAILURE':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_AGENT_UPDATE_REQUEST':

            var UpdatingContractCommissionAgent = state.list?.find((agent: ContractCommissionAgent) => agent.id === action.contractCommissionAgent.id);

            if (UpdatingContractCommissionAgent !== undefined) {
                UpdatingContractCommissionAgent = {...UpdatingContractCommissionAgent, isUpdating: true, isUpdatedSuccessfully: undefined, failOnUpdating: false, error: undefined };
            }

            return {
                ...state,
                isUpdatingOne: true,
                successUpdatingOne: undefined,
                failOnUpdatingOne: false,
                error: undefined,
                list: UpdatingContractCommissionAgent ? [...state.list?.filter((agent: ContractCommissionAgent) => agent.id !== UpdatingContractCommissionAgent?.id), UpdatingContractCommissionAgent] : state.list
            };
        case 'CONTRACT_COMMISSION_AGENT_UPDATE_SUCCESS':
            var updatedCommissionAgent = action.contractCommissionAgent;

            if (updatedCommissionAgent !== undefined) {
                updatedCommissionAgent = {...updatedCommissionAgent, isUpdating: false, isUpdatedSuccessfully: true, failOnUpdating: false, error: undefined };
            }

            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: true,
                failOnUpdatingOne: false,
                error: undefined,
                list: updatedCommissionAgent ? [...state.list?.filter((agent: ContractCommissionAgent) => agent.id !== updatedCommissionAgent?.id), updatedCommissionAgent] : state.list
            };
        case 'CONTRACT_COMMISSION_AGENT_UPDATE_FAILURE':

            var failedCommissionAgent = state.list?.find((agent: ContractCommissionAgent) => agent.id === action.contractCommissionAgentId);

            if (failedCommissionAgent !== undefined) {
                failedCommissionAgent = {...failedCommissionAgent, isUpdating: false, isUpdatedSuccessfully: false, failOnUpdating: true, error: action.error };
            }

            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: false,
                failOnUpdatingOne: true,
                error: action.error,
                list: failedCommissionAgent ? [...state.list?.filter((agent: ContractCommissionAgent) => agent.id !== failedCommissionAgent?.id), failedCommissionAgent] : state.list
            };
        default:
            return state;
    }
};