import { MedicalInsurance } from '../models/Entities/MedicalInsurances/MedicalInsurance';
import { IResult } from '../models/HttpResult';
import { NewMedicalInsurance } from '../models/Entities/MedicalInsurances/NewMedicalInsurance';
import { IPagedResult } from '../models/HttpPagedResult';


// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'medicalinsurances/';

export const GetMedicalInsurances = async (
  pageNumber: number,
  pageSize: number,
  searchText: string | undefined
, token: string): Promise<MedicalInsurance[]> => {
  let params: URLSearchParams = Functions.GetQueryStringParams(
    pageNumber,
    pageSize,
    searchText
  );



  let RequestURL: string = BaseURL;

  if (params) RequestURL += '?' + params.toString();

  try {
    const Response: MedicalInsurance[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          Sentry.captureException(response);
          throw response;
        } else {
          return response.json() as Promise<IPagedResult<MedicalInsurance[]>>;
        }
      })
      .then((data: IPagedResult<MedicalInsurance[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetMedicalInsuranceById = async (
  medicalInsuranceId: number
, token: string): Promise<MedicalInsurance> => {
  const RequestURL: string = BaseURL + medicalInsuranceId;

  try {
    const Response: MedicalInsurance = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsurance>>;
        }
      })
      .then((data: IResult<MedicalInsurance>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AddMedicalInsurance = async (
  newMedicalInsurance: NewMedicalInsurance
, token: string): Promise<MedicalInsurance> => {
  const RequestURL: string = BaseURL;

  try {
    const Response: MedicalInsurance = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify(newMedicalInsurance)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsurance>>;
        }
      })
      .then((data: IResult<MedicalInsurance>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const UpdateMedicalInsurance = async (
  medicalInsurance: MedicalInsurance
, token: string): Promise<MedicalInsurance> => {
  const RequestURL: string = BaseURL;
  try {

    const Response: MedicalInsurance = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'PUT',
      body: JSON.stringify(medicalInsurance)
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsurance>>;
        }
      })
      .then((data: IResult<MedicalInsurance>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DeleteMedicalInsurance = async (
  medicalInsuranceId: number
, token: string): Promise<MedicalInsurance> => {
  const RequestURL: string = BaseURL + medicalInsuranceId;

  try {
    const Response: MedicalInsurance = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<MedicalInsurance>>;
        }
      })
      .then((data: IResult<MedicalInsurance>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
