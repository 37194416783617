// models
import { makeStyles } from '@fluentui/react-components';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { ContractStage } from '../../../models/Entities/Contracts/ContractStage';
import { PatientVisit } from '../../../models/Entities/Protocols/PatientVisist';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import { ProtocolPatient } from '../../../models/Entities/Protocols/ProtocolPatient';
import { ErrorType } from '../../../models/HttpError';

// stores
import * as ContractStore from '../../../redux/middleware/ContractMiddleware'
import * as ContractStageStore from '../../../redux/middleware/ContractStageMiddleware'
import * as ProtocolsStore from '../../../redux/middleware/MedicalProtocolMiddleware'
import * as PractitionerStore from '../../../redux/middleware/PractitionerMiddleware'

// state
import { AppState } from '../../../redux/reducers';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';

//#region Props

interface IProps {
    ProtocolId: number;
}

interface ConnectedProps {
    IsLoadingProtocol: boolean;
    LoadedProtocolSuccessfully: boolean;
    ErrorLoadingProtocol: ErrorType | undefined;
    IsLoadingContract: boolean;
    LoadedContractSuccessfully: boolean;
    ErrorLoadingContract: ErrorType | undefined;
    ContractId: number | null;
    Protocol: Protocol | null;
    Contract: Contract | null;
    ProtocolPatients: ProtocolPatient[];
    Stages: ContractStage[];
    Visits: PatientVisit[];
    GetPractitioner: (id: string) => Practitioner | undefined;
}

interface MedicalServicesDispatchProps {
    GetMedicalProtocolById: typeof ProtocolsStore.actionCreators.GetMedicalProtocolById;
    GetProtocolPatients: typeof ProtocolsStore.actionCreators.GetProtocolPatients;
    GetContractById: typeof ContractStore.actionCreators.GetContractById;
    GetAllContractsStage: typeof ContractStageStore.actionCreators.GetAllContractsStage;
    GetPatientVisitByProtocolId: typeof ProtocolsStore.actionCreators.GetPatientVisitByProtocolId;
    GetAllPractitioners: typeof PractitionerStore.actionCreators.GetAllPractitioners;
    GetAllProtocolContractAssociations: typeof ProtocolsStore.actionCreators.GetAllProtocolContractAssociations;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const AuxContractId: number | null =  state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId)?.contractAssociation?.contractId || null;
    return {
        IsLoadingProtocol: state.MedicalProtocols?.isLoadingOne,
        LoadedProtocolSuccessfully: state.MedicalProtocols?.successLoadingOne,
        ErrorLoadingProtocol: state.MedicalProtocols?.error,
        IsLoadingContract: state.Contract?.isLoadingOne,
        LoadedContractSuccessfully: state.Contract?.successLoadingOne,
        ErrorLoadingContract: state.Contract?.error,
        Protocol: state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) || null,
        ContractId: AuxContractId,
        Contract: state.Contract?.list?.find((contract: Contract) => contract.id === AuxContractId) || null,
        ProtocolPatients: state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId)?.patients?.sort(
            (a: ProtocolPatient, b: ProtocolPatient) => a.numberInProtocol.localeCompare(b.numberInProtocol)
        ) || [],
        Stages: state.Contract?.list?.find((contract: Contract) => contract.id === AuxContractId)?.stages || [],
        Visits: state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId)?.visits || [],
        GetPractitioner: (id: string) => { 
            var practitioner = state.Practitioner?.list?.find((practitioner: Practitioner) => practitioner.userId === id);
            return practitioner;
        },
    }
};

export const mapDispatchToProps = {
    ...ContractStore.actionCreators,
    ...ProtocolsStore.actionCreators,
    ...ContractStageStore.actionCreators,
    ...PractitionerStore.actionCreators
};


// component functions

export interface internalStage {
    stageId: number | null;
    stageName: string;
    order: number;
}

export const GetRealStages = (stages: ContractStage[], visist: PatientVisit[]): internalStage[]  => {

    let realStages: internalStage[] = [];

    // get distinct visit by title and return visit title and order
    var distinctVisit: internalStage[] = visist.filter((visit: PatientVisit) => visit.order !== 0).map((visit: PatientVisit) => {
        return {stageId: null, stageName: visit.title, order: visit.order} as internalStage;
    }).filter((value, index, self) => self.findIndex((v) => v.stageName === value.stageName) === index);

    var distinctStage: internalStage[] = stages.map((stage: ContractStage) => {
        return {stageId: stage.id, stageName: stage.stageName, order: stage.stageOrder} as internalStage;
    });

    const mergeVisits: internalStage[] = distinctStage.concat(distinctVisit).filter((value, index, self) => self.findIndex((v) => v.order === value.order) === index);

    var CompleteVisitArray = mergeVisits.length > 0 ? mergeVisits.map((stage: internalStage) => {

        var vistitByStage = visist.filter((visit: PatientVisit) => visit.order === stage.order)[0] || undefined;
        var stageByVisit = stages.filter((currentstage: ContractStage) => currentstage.stageOrder === stage.order)[0] || undefined;
        if(vistitByStage && stageByVisit) {
            return {
                stageId: stage.stageId,
                stageName: stageByVisit.stageName + ' [ ' + vistitByStage.title + ' ]',
                order: stage.order
            } as internalStage;
        } else {
            return {
                stageId: stage.stageId,
                stageName: stage.stageName,
                order: stage.order
            } as internalStage;
        }

    }) : distinctVisit.filter((visit: any) => visit.order !== 0).map((visit: any) => {
        return {
            stageId: null,
            stageName: visit.title,
            order: visit.order
            } as internalStage;

    });

    return CompleteVisitArray.sort((a: internalStage, b: internalStage) => a.order - b.order);
}

// check if a visit is realized
export const IsVisitExist = (visits: PatientVisit[], patientNumberInProtocol: string, stage: internalStage): boolean => {
    return visits.some((visit: PatientVisit) => visit.numberInProtocol.toLowerCase() === patientNumberInProtocol.toLowerCase() && visit.order === stage.order);
}

// return visit status
export const GetVisitStatus = (visits: PatientVisit[], patientNumberInProtocol: string, stage: internalStage): string => {
    const visit = visits.find((visit: PatientVisit) => visit.numberInProtocol.toLowerCase() === patientNumberInProtocol.toLowerCase() && visit.order === stage.order);
    if(visit !== undefined) {
        return GetVisitStatusByVisit(visit.status);
    } else {
        return '';
    }
}

// get visit status by visit
export const GetVisitStatusByVisit = (visitStatus: number): string => {
    switch(visitStatus) {
        case 0: return 'Started';
        case 1: return 'Completed';
        case 2: return 'Skipped';
        case 3: return 'Failed';
        case 4: return 'Changed';
        case 5: return 'AutoCompleted';
        default: return 'Not Realized';
    }
}

export const GetVisitStatusDescription = (status: number): string => {
    switch(status) {
        case 0: return 'Iniciada';
        case 1: return 'Completada';
        case 2: return 'Salteada';
        case 3: return 'Falla de selección';
        case 4: return 'Modificada';
        case 5: return 'Autocompletada';
        default: return 'No realizada';
    }
}

export const getVisit = (visits: PatientVisit[], patientNumberInProtocol: string, stage: internalStage): PatientVisit | undefined => {
    return visits.find((visit: PatientVisit) => visit.numberInProtocol.toLowerCase() === patientNumberInProtocol.toLowerCase() && visit.order === stage.order);
}

// get visit by patient and stage
export const GetVisitByPatientAndStage = (visits: PatientVisit[], patientNumberInProtocol: string, stage: internalStage): PatientVisit | null => {
    return visits.find((visit: PatientVisit) => visit.numberInProtocol.toLowerCase() === patientNumberInProtocol.toLowerCase() && visit.order === stage.order) || null;
}

// styles
export const useStyles = makeStyles({
    contentHeader: {
      marginTop: "0",
    },
});