import * as React from 'react';
import { useEffect } from 'react';
import { ITag, TagPicker } from '@fluentui/react/lib/Pickers';
import { Protocol } from '../../../../models/Entities/Protocols/Protocol';

// ownFunctions
import * as Functions from './ProtocolSelectorFunctions';
import { connect } from 'react-redux';


const ProtocolSelectorComponent = (
  props: Functions.Props
) => {
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<ITag[]>([]);
  const [peopleList, setPeopleList] = React.useState<ITag[]>([]);

  const picker = React.useRef(null);

  useEffect(() => {
    //if ( props.Protocols?.length === 0) {
    props.GetAllMedicalProtocols();
    //}
  }, []);

  const onChangeSelection = (items?: ITag[] | undefined) => {
    if (props.onChangeSelection) {
      props.onChangeSelection(items?.map((protocol: ITag) => {
        return {
          title: protocol.name,
          id: protocol.key
        } as Protocol;
      }));
    }
  };

  const onFilterChanged = (
    filterText: string,
    currentProtocols?: ITag[],
    limitResults?: number
  ): ITag[] => {

    if (filterText) {

      let filteredProtocol: ITag[] =
        filterProtocolByText(filterText);

      let practitionersList = Functions.removeDuplicates(
        filteredProtocol,
        currentProtocols || []
      );

      practitionersList = limitResults
        ? practitionersList.slice(0, limitResults)
        : practitionersList;
      return practitionersList || [];

    } else {
      return [];
    }
  };

  const filterProtocolByText = (
    filterText: string
  ): ITag[] => {

    return props.Protocols?.map((item: Protocol) => {
      return {
        key: item.id,
        name: item.title
      } as ITag;
    }).filter((item: ITag) => {
      return Functions.doesTextStartWith(item.name as string, filterText);
    });
  };

  return (
    <div>
      <TagPicker
        onResolveSuggestions={onFilterChanged}
        onEmptyResolveSuggestions={() => props.Protocols?.map((item: Protocol) => {
          return {
            key: item.id,
            name: item.title
          } as ITag;
        }) || []
        }
        getTextFromItem={Functions.getTextFromItem}
        pickerSuggestionsProps={Functions.suggestionProps}
        className={'ms-PeoplePicker'}
        key={'normal'}
        onValidateInput={Functions.validateInput}
        selectionAriaLabel={'Selected protocol'}
        removeButtonAriaLabel={'Remove'}
        componentRef={picker}
        itemLimit={props.selectionLimit}
        //onInputChange={onInputChange}
        onChange={onChangeSelection}
      />
    </div>
  );
};

export default connect(
  Functions.mapStateToProps,
  Functions.mapDispatchToProps
)(ProtocolSelectorComponent as any);
