import { IIconProps } from "@fluentui/react";
import { ErrorType } from "../../../models/HttpError";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";

// stores
import * as ProtocolStore from '../../../redux/middleware/MedicalProtocolMiddleware';

//State
import { AppState } from "../../../redux/reducers";


interface IProps {
    ProtocolId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    failOnUpdating: boolean;
    failOnLoading: boolean;
    error: ErrorType | undefined;
    Protocol: Protocol | undefined;
  }
  
  interface DispatchProps {
    GetAllMedicalProtocols: typeof ProtocolStore.actionCreators.GetAllMedicalProtocols;
    GetMedicalProtocolById: typeof ProtocolStore.actionCreators.GetMedicalProtocolById;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.Contract?.isLoadingAll,
    isUpdating: state.Contract?.isLoadingAll,
    isUpdatedSuccessfully: state.Contract?.successLoadingAll,
    isLoadingSuccessfully: state.Contract?.successLoadingAll,
    failOnUpdating: state.Contract?.failOnLoadingAll,
    failOnLoading: state.Contract?.failOnLoadingAll,
    isDeleting: state.Contract?.isDeletingOne,
    isDeletedSuccessfully: state.Contract?.successDeletingOne,
    failOnDeleting: state.Contract?.failOnDeletingOne,
    error: state.Contract?.error,
    Protocol: ownProps.ProtocolId ? state.MedicalProtocols?.list?.find((item: Protocol) => item.id === ownProps.ProtocolId ) : undefined
  });
  
  export const mapDispatchToProps = {
    ...ProtocolStore.actionCreators
  };

  /*
    Protocol Status
    _____________________
    Active = 1,
    Inactive = 2,
    Suspended = 3,
    Expired = 4,
    Canceled = 5,
    Terminated = 6
*/

  export const GetProtocolStatusDescripcion = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "No definido";
        case 1:
            return "Activo";
        case 2:
            return "Inactivo";
        case 3:
            return "On Going";
        case 4:
            return "Expirado";
        case 5:
            return "Cancelado";
        case 6:	
            return "Terminado";
        default:
        return "No definido";
    }
};

  export const GetProtocolStatusClass = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "";
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "On Going";
        case 4:
            return "Expired";
        case 5:
            return "Canceled";
        case 6:
            return "Terminated";
        default:
        return "";
  }
};


  //#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };