import { Action, Reducer } from 'redux';

// actions
import * as Actions from '../actions/ContractConceptConcept.Actions';

// functions
import * as Functions from '../../functions/common';
import { ContractConceptConceptState } from '../states/ContractConceptConceptState';
import { ContractConceptUnloadState } from '../states/ContractConceptState';
import { ContractConceptConcept } from '../../models/Entities/Contracts/ContractConceptConcept';


type KnownAction = 
| Actions.ContractConceptConcept_GetAllByContractConceptId_Request_Action
| Actions.ContractConceptConcept_GetAllByContractConceptId_Receive_Action
| Actions.ContractConceptConcept_GetAllByContractConceptId_Fail_Action
| Actions.ContractConceptConcept_UpdateAllByContractConceptId_Request_Action
| Actions.ContractConceptConcept_UpdateAllByContractConceptId_Receive_Action
| Actions.ContractConceptConcept_UpdateAllByContractConceptId_Fail_Action
| Actions.ContractConceptConcept_GetById_Request_Action
| Actions.ContractConceptConcept_Add_Request_Action
| Actions.ContractConceptConcept_Add_Receive_Action
| Actions.ContractConceptConcept_Add_Fail_Action
| Actions.ContractConceptConcept_Update_Request_Action
| Actions.ContractConceptConcept_Update_Receive_Action
| Actions.ContractConceptConcept_Update_Fail_Action
| Actions.ContractConceptConcept_Delete_Request_Action
| Actions.ContractConceptConcept_Delete_Receive_Action
| Actions.ContractConceptConcept_Delete_Fail_Action;

export const ContractConceptConceptReducer: Reducer<ContractConceptConceptState> = (
    
    state: ContractConceptConceptState | undefined, incomingAction: Action): ContractConceptConceptState => {

        if (state === undefined) {
            return ContractConceptUnloadState as ContractConceptConceptState;
        }

        const action = incomingAction as KnownAction;

        switch (action.type) {
            case 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingAll: true,
                    successLoadingAll: false,
                    failOnLoadingAll: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: true,
                    failOnLoadingAll: false,
                    error: undefined,
                    list: action.contractConceptConcepts
                };
            case 'CONTRACTCONCEPTCONCEPT_GET_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION':
                return {
                    ...state,
                    isLoadingAll: false,
                    successLoadingAll: false,
                    failOnLoadingAll: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPTCONCEPT_UPDATE_ALL_BY_CONTRACTCONCEPTID_REQUEST_ACTION':
                return {
                    ...state,
                    isUpdatingAll: true,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPTCONCEPT_UPDATE_ALL_BY_CONTRACTCONCEPTID_RECEIVE_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: true,
                    FailUpdatingAll: false,
                    error: undefined,
                    list: action.contractConceptConcepts
                };
            case 'CONTRACTCONCEPTCONCEPT_UPDATE_ALL_BY_CONTRACTCONCEPTID_FAIL_ACTION':
                return {
                    ...state,
                    isUpdatingAll: false,
                    isUpdatingAllSuccess: false,
                    FailUpdatingAll: true,
                    error: action.error
                };
            case 'CONTRACTCONCEPTCONCEPT_GET_BY_ID_REQUEST_ACTION':
                return {
                    ...state,
                    isLoadingOne: true,
                    successLoadingOne: false,
                    failOnLoadingOne: false,
                    error: undefined
                };
            // case 'CONTRACTCONCEPTCONCEPT_GET_BY_ID_RECEIVE_ACTION':
            //     return {
            //         ...state,
            //         isLoadingOne: false,
            //         successLoadingOne: true,
            //         failOnLoadingOne: false,
            //         error: undefined,
            //         list: Functions.UpdateOrAdd<ContractConceptConcept>(state.list, action.contractConceptConcept)
            //     };
            case 'CONTRACTCONCEPTCONCEPT_ADD_REQUEST_ACTION':
                return {
                    ...state,
                    isAddingNewOne: true,
                    successAddingNewOne: false,
                    failOnAddingNewOne: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPTCONCEPT_ADD_RECEIVE_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: true,
                    failOnAddingNewOne: false,
                    error: undefined,
                    list: [...state.list, action.contractConceptConcept]
                };
            case 'CONTRACTCONCEPTCONCEPT_ADD_FAIL_ACTION':
                return {
                    ...state,
                    isAddingNewOne: false,
                    successAddingNewOne: false,
                    failOnAddingNewOne: true,
                    error: action.error
                };
            // case 'CONTRACTCONCEPTCONCEPT_UPDATE_REQUEST_ACTION':
            //     return {
            //         ...state,
            //         isUpdatingOne: true,
            //         successUpdatingOne: false,
            //         failOnUpdatingOne: false,
            //         error: undefined
            //     };
            // case 'CONTRACTCONCEPTCONCEPT_UPDATE_RECEIVE_ACTION':
            //     return {
            //         ...state,
            //         isUpdatingOne: false,
            //         successUpdatingOne: true,
            //         failOnUpdatingOne: false,
            //         error: undefined,
            //         list: Functions.UpdateOrAdd<ContractConceptConcept>(state.list, action.contractConceptConcept)
            //     };
            // case 'CONTRACTCONCEPTCONCEPT_UPDATE_FAIL_ACTION':
            //     return {
            //         ...state,
            //         isUpdatingOne: false,
            //         successUpdatingOne: false,
            //         failOnUpdatingOne: true,
            //         error: action.error
            //     };
            case 'CONTRACTCONCEPTCONCEPT_DELETE_REQUEST_ACTION':
                return {
                    ...state,
                    isDeletingOne: true,
                    successDeletingOne: false,
                    failOnDeletingOne: false,
                    error: undefined
                };
            case 'CONTRACTCONCEPTCONCEPT_DELETE_RECEIVE_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: true,
                    failOnDeletingOne: false,
                    error: undefined,
                    list: state.list.filter((contractConceptConcept: ContractConceptConcept) => contractConceptConcept.id !== action.contractConceptConceptId)
                };
            case 'CONTRACTCONCEPTCONCEPT_DELETE_FAIL_ACTION':
                return {
                    ...state,
                    isDeletingOne: false,
                    successDeletingOne: false,
                    failOnDeletingOne: true,
                    error: action.error
                };
            default:
                return state;
        }
    }



