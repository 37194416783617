//#region Props

import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractAddendum } from "../../../../models/Entities/Contracts/ContractAddendum";
import { ErrorType } from "../../../../models/HttpError";
import { AppState } from "../../../../redux/reducers";

// stores
import * as ContractsStore from "../../../../redux/middleware/ContractMiddleware";
import * as ContractAddendumsStore from "../../../../redux/middleware/ContractAddendum.Middleware";


interface IProps {
    ContractId: number;
    OnAddendumSelected: (addendum: ContractAddendum) => void;
}

interface ConnectedProps {
    isLoading: boolean;
    isLoadingSuccessfully: boolean | undefined;
    failOnLoading: boolean;
    isCreatingAddendum: boolean;
    isCreatingAddendumSuccessfully: boolean | undefined;
    failOnCreatingAddendum: boolean;
    isGettingAddendums: boolean;
    ObteinedAddendumsSuccessfully: boolean | undefined;
    FailGettingAddendums: boolean;
    error: ErrorType | undefined;
    Contract: Contract | undefined;
    Addendums: ContractAddendum[] | undefined;
}

interface MedicalServicesDispatchProps {
    GetContractById: typeof ContractsStore.actionCreators.GetContractById;
    GetContractAddendumsByContractId: typeof ContractAddendumsStore.actionCreators.GetContractAddendumsByContractId;
    CreateContractAddendum: typeof ContractAddendumsStore.actionCreators.CreateContractAddendum;
    UpdateContractAddendum: typeof ContractAddendumsStore.actionCreators.UpdateContractAddendum;
    DeleteContractAddendum: typeof ContractAddendumsStore.actionCreators.DeleteContractAddendum;
    CloneAddendum: typeof ContractAddendumsStore.actionCreators.CloneAddendum;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const currentAddendums = state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.addendums || [];

    return {
        isLoading: state.Contract?.isLoadingOne,
        isLoadingSuccessfully: state.Contract?.successLoadingOne,
        failOnLoading: state.Contract?.failOnLoadingOne,
        isCreatingAddendum: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.isCloning,
        isCreatingAddendumSuccessfully: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.isClonedSuccessfully,
        failOnCreatingAddendum: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.failOnCloning,
        isGettingAddendums: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.isGettingContractAddendums,
        ObteinedAddendumsSuccessfully: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.isGettingContractAddendumsSuccess,
        FailGettingAddendums: state.Contract?.list?.find(c => c.id === ownProps.ContractId)?.FailGettingContractAddendums,
        error: state.Contract?.error,
        Contract: state.Contract?.list?.find(c => c.id === ownProps.ContractId),
        Addendums: currentAddendums?.sort((a, b) => a.id - b.id)
    }
};

export const mapDispatchToProps = {
    ...ContractsStore.actionCreators,
    ...ContractAddendumsStore.actionCreators
};

//#endregion Props

export const isCurrentAddendum = (addendum: ContractAddendum | undefined) => {
    const today = new Date();
    if (!addendum) return false;
    if (!addendum.contractAddendumExpiryStartDate && !addendum.contractAddendumExpiryEndDate) return false;
    if (addendum.contractAddendumExpiryStartDate && !addendum.contractAddendumExpiryEndDate) {
        return today >= new Date(addendum.contractAddendumExpiryStartDate);
    }
    if (!addendum.contractAddendumExpiryStartDate && addendum.contractAddendumExpiryEndDate) {
        return today <= new Date(addendum.contractAddendumExpiryEndDate);
    }
    return today >= new Date(addendum.contractAddendumExpiryStartDate!) && today <= new Date(addendum.contractAddendumExpiryEndDate!);
}

export const isExpiredAddendum = (addendum: ContractAddendum | undefined) => {
    const today = new Date();
    if (!addendum) return false;
    if (!addendum.contractAddendumExpiryStartDate && !addendum.contractAddendumExpiryEndDate) return false;
    if (addendum.contractAddendumExpiryStartDate && !addendum.contractAddendumExpiryEndDate) {
        return false;
    }
    if (!addendum.contractAddendumExpiryStartDate && addendum.contractAddendumExpiryEndDate) {
        return today > new Date(addendum.contractAddendumExpiryEndDate);
    }
    return today > new Date(addendum.contractAddendumExpiryEndDate!);
}



export const GetContractStatusClass = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "";
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "Suspended";
        case 4:
            return "Expired";
        case 5:
            return "Canceled";
        case 6:
            return "Terminated";
        default:
            return "";
    }
};

export const GetContractStatusDescripcion = (status: number | undefined) => {
    switch (status) {
        case undefined:
            return "Undefined";
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "Suspended";
        case 4:
            return "Expired";
        case 5:
            return "Canceled";
        case 6:
            return "Finished";
        default:
            return "Undefined";
    }
};