import { FontSizes, Separator } from "@fluentui/react"
import { Checkbox, Label, Text, Input, Field, Spinner, MessageBar, MessageBarBody, MessageBarTitle } from "@fluentui/react-components"

// I18Next
import { useTranslation } from 'react-i18next';

// style
import '../ContractStageMultiSelector.Style.css';

// component functions
import * as ComponentFuntions from './ContractStageMultiselectorItem.Functions';
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { ContractConceptPaymentSchedule } from "../../../../../models/Entities/Contracts/ContractConceptPaymentSchedule";
import { NewContractConceptPaymentSchedule } from "../../../../../models/Entities/Contracts/NewContractConceptPaymentSchedule";
import { processSilentRenew } from "redux-oidc";

const ContractStageMultiselectorItemComponent = (props: ComponentFuntions.Props) => {

  // useTranslation
  const [t] = useTranslation();

  // properties
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(1);
  const [SettlementCoeficient, setSetlementCoeficient] = useState<number>(1);
  const [inPersonPrice, setInPersonPrice] = useState<number>(0);
  const [remotePrice, setRemotePrice] = useState<number>(0);
  const [failurePrice, setFailurePrice] = useState<number>(0);

  const [isChanged, setIsChanged] = useState<boolean>(false);

  // useEffect(() => {
  //     setIsChanged(true);
  // }, [isSelected, amount, SettlementCoeficient, inPersonPrice, remotePrice, failurePrice])

  useEffect(() => {
    if (props.CurrentConceptPaymentSchedule) {
      setIsSelected(props.CurrentConceptPaymentSchedule ? true : false);
      setAmount(props.CurrentConceptPaymentSchedule?.amountExpected || 1);
      setSetlementCoeficient(props.CurrentConceptPaymentSchedule?.settlementCoefficient);
      setInPersonPrice(props.CurrentConceptPaymentSchedule?.price);
      setRemotePrice(props.CurrentConceptPaymentSchedule?.remotePrice);
      setFailurePrice(props.CurrentConceptPaymentSchedule?.screeningFailurePrice);
    }
  }, [props.CurrentConceptPaymentSchedule !== undefined])

  useEffect(() => {
    if (props.ContractConceptId) {
      if (props.ConceptSaved && isChanged) {
        const auxPaymentSchedule = {
          id: props.CurrentConceptPaymentSchedule?.id || undefined,
          contractConceptId: props.ContractConceptId || 0,
          contractStageId: props.StageId,
          amountExpected: amount || 0,
          settlementCoefficient: SettlementCoeficient || 0,
          price: inPersonPrice || 0,
          remotePrice: remotePrice || 0,
          screeningFailurePrice: failurePrice || 0,
          followUpPrice: 0,
          currency: props.CurrencyCode
        };
        if (isSelected) {
          if (props.CurrentConceptPaymentSchedule) {
            props.UpdateContractConceptPaymentSchedule(
              props.ContractId,
              props.ContractAddendumId,
              auxPaymentSchedule as ContractConceptPaymentSchedule
            );
          } else {
            props.AddContractConceptPaymenSchedule(
              props.ContractId,
              props.ContractAddendumId,
              auxPaymentSchedule as NewContractConceptPaymentSchedule
            );
          }
        } else {
          if (props.CurrentConceptPaymentSchedule) {
            props.DeleteContractConceptPaymentSchedule(
              props.CurrentConceptPaymentSchedule.id,
              props.CurrentConceptPaymentSchedule.contractConceptId,
              props.ContractAddendumId,
              props.ContractId
            );
          }
        }
      } else {
        props.isSavedItem(props.StageId);
      }
    } 
  }, [props.ConceptSaved, props.ContractConceptId]);

  useEffect(() => {
    props.isSavedItem(props.StageId);
  }, [props.isSavedSuccessfully, props.isDeletedSuccessfully, props.isUpdatedSuccessfully]);

  return (
    <div className={isSelected ? 'StageSelectorContainer Selected' : 'StageSelectorContainer'}>
      <div style={{ width: '15em', marginRight: '1em' }}>
        <div>
          <Checkbox
            label={props.Stage.stageName}
            checked={isSelected}
            onChange={(ev, checked) => {
              setIsSelected(checked.checked as boolean);
              setIsChanged(true);
            }}
          />
          <Text className='badge mini blue'>{props.Stage.isOnSiteMandatory ? t('protocolStageMultiSelector.stagesSelected.inPersonVisit') : t('protocolStageMultiSelector.stagesSelected.remoteVisit')}</Text>
          {props.isSaving && <Spinner size="extra-tiny" />}
        </div>
        <div>
          {props.errorOnSaving && <MessageBar key={'error'} intent={'error'} aria-multiline='true' >
            <MessageBarBody>
              <MessageBarTitle>Error</MessageBarTitle>
              {props.errorOnSaving?.ErrorMessage}
            </MessageBarBody>
          </MessageBar>}
        </div>
      </div>
      {isSelected && <div>
        <Field label={'Cantidad'}>
          <Input
            id="amount"
            type="number"
            style={{ maxWidth: '4em' }}
            step={1}
            defaultValue={amount?.toString()}
            onChange={
              (ev, value) => {
                setAmount(parseInt(value.value));
                setIsChanged(true);
              }
            }
            onFocus={e => e.target.select()}
          />
        </Field>
      </div>}
      {isSelected && <div>
        <Field label={'Coef. Liq.'}>
          <Input
            id="amount"
            type="number"
            style={{ maxWidth: '6em' }}
            step={0.01}
            defaultValue={SettlementCoeficient?.toString()}
            onChange={
              (ev, value) => {
                setSetlementCoeficient(parseFloat(value.value));
                setIsChanged(true);
              }}
            onFocus={e => e.target.select()}
          />
        </Field>
      </div>}
      {isSelected && <div>
        {props.ShowInputValues && (
          <Field label={t('protocolStageMultiSelector.stagesSelected.inPersonPrice')}>
            <Input
              id="inPersonPrice"
              type="number"
              style={{ maxWidth: '10em' }}
              contentAfter={
                <Text size={400} id={'inPersonPrice'}>
                  {props.CurrencyCode}
                </Text>
              }
              defaultValue={(props.CurrentConceptPaymentSchedule?.price || 0).toString()}
              onFocus={e => e.target.select()}
              onChange={(ev, value) => {
                setInPersonPrice(parseFloat(value.value));
                setIsChanged(true);
              }}
            />
          </Field>
        )}
        {props.ShowInputValues && isSelected && (
          <Field label={t('protocolStageMultiSelector.stagesSelected.remotePrice')}>
            <Input
              id="remotePrice"
              type="number"
              style={{ maxWidth: '10em' }}
              defaultValue={(props.CurrentConceptPaymentSchedule?.remotePrice || 0).toString()}
              contentAfter={
                <Text size={400} id={'remotePrice'}>
                  {props.CurrencyCode}
                </Text>
              }
              onFocus={e => e.target.select()}
              onChange={(ev, value) => {
                setRemotePrice(parseFloat(value.value));
                setIsChanged(true);
              }}
            />
          </Field>
        )}
      </div>}
      {props.Stage.failSelectionEnabled && isSelected && props.ShowInputValues && (
        <Field label={t('protocolStageMultiSelector.stagesSelected.selectionFailurePrice')}>
          <Input
            id="failurePrice"
            type="number"
            style={{ maxWidth: '10em' }}
            contentAfter={
              <Text size={400} id={'failurePrice'}>
                {props.CurrencyCode}
              </Text>
            }
            defaultValue={(props.CurrentConceptPaymentSchedule?.screeningFailurePrice || 0).toString()}
            onFocus={e => e.target.select()}
            onChange={(ev, value) => {
              setFailurePrice(parseFloat(value.value));
              setIsChanged(true);
            }}
          />
        </Field>
      )
      }
    </div>
  );
};

export default connect(
  ComponentFuntions.mapStateToProps,
  ComponentFuntions.mapDispatchToProps
)(ContractStageMultiselectorItemComponent as any);