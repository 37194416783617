
import * as React from "react";
import { CommandBar, FocusTrapZone, IButtonProps, ICommandBarItemProps, Label, MessageBarType, Separator } from "@fluentui/react";
import { connect } from "react-redux";
import { useBoolean } from "@fluentui/react-hooks";

// components
import PractitionerConceptItem from "./PractitionerConceptItem/PractitionerConcept.Item.Component";
import PractitionerProtocolConceptFormComponent from "../PractitionerProtocolConceptsForm/PractitionerProtocolConceptForm.Component";

// styles
import './PractitionerConcepts.List.Styles.css';

// functions
import * as ComponentFunctions from './PractitionerConcepts.List.Functions';

// models
import { ContractCommissionAgentConcept } from "../../../../models/Entities/Contracts/ContractCommissionAgentConcept";
import { MessageComponent } from "../../../../commons/component/MessageComponent";



const PractitionerConceptsListComponent = (props: ComponentFunctions.Props) => {

    const [isOpenConceptPanel, { setTrue: openConceptPanel, setFalse: dismissConceptPanel }] =
        useBoolean(false);


    // items command bar 
    const _items: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: 'Agregar concepto',
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Add' },
            disabled: props.disabled,
            onClick: () => openConceptPanel()
        }
    ];

    const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

    const Controls = (
        <>
            <FocusTrapZone>
                <CommandBar
                    items={_items}
                    overflowItems={undefined}
                    overflowButtonProps={overflowProps}
                    farItems={undefined}
                    ariaLabel="Inbox actions"
                    primaryGroupAriaLabel="Email actions"
                    farItemsGroupAriaLabel="More actions"
                />
            </FocusTrapZone>
            <Separator />
        </>
    );

    const Forms = (
        <>
            <PractitionerProtocolConceptFormComponent
                isOpen={isOpenConceptPanel}
                openPanel={openConceptPanel}
                dismissPanel={dismissConceptPanel}
                CommissionAgentId={props.CommissionAgentId}
                CommissionAgentConceptId={0}
                ContractId={props.ContractId}
            />
        </>
    )

    const DisabledInfo = props.disabled && (
        <MessageComponent
            message={"Para especificar los conceptos a liquidar, debe deseleccionar el tilde de 'Contrato asociado'"}
            type={MessageBarType.info}
        />
    );

    return (
        <>
            <Label>Conceptos a Liquidar</Label>
            {Controls}
            {DisabledInfo}
            {props.Concepts?.map((concept: ContractCommissionAgentConcept) =>
                <PractitionerConceptItem disabled={props.disabled} onClick={undefined} CommissionAgentConcept={concept} />)}
            {props.Concepts?.length === 0 && <Label>No hay conceptos coparticipables</Label>}
            {Forms}
        </>
    );
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(PractitionerConceptsListComponent as any);