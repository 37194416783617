import { IconContext } from 'react-icons';
import { MenuItem } from './SideMenuModels';
import { NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';


// Menu Items
import * as MenuSettings from './SideMenuItems';

// Styles
import './SideMenuComponent.css';



export const SideMenuComponent = () => {

  const [t] = useTranslation();

  const Menu = MenuSettings.MenuItems.map((item: MenuItem) => (
    <IconContext.Provider key={item.key} value={{ className: 'menuItemIcon' }}>
      <NavLink key={item.key} to={item.target} className="menuItem">
        {item.icon}
        <Trans i18nKey={item.name} />
      </NavLink>
    </IconContext.Provider>
  ));

  return (
    <div className="sideMenuStickyLeft sideMenuBackground">
      <img
        src={require('../../assets/img/logo_alphacr.png')}
        alt="Ichtys"
      ></img>
      {Menu}
    </div>
  );
};
