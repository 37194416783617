import {
  IconButton,
  IIconProps,
  Label,
  PrimaryButton,
  SearchBox
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useState } from 'react';
import { PageHeader } from '../../commons/component/PageHeader/PageHeader';
import { MedicalInsuranceFormComponent } from '../../components/MedicalInsurance/MedicalInsuranceForm/MedicalInsuranceFormComponent';
import { MedicalInsuranceListComponent } from '../../components/MedicalInsurance/MedicalInsuranceList/MedicalInsuranceListComponent';
import { MedicalInsurancePlanFormComponent } from '../../components/MedicalInsurance/MedicalInsurancePlanForm/MedicalInsurancePlanFormComponent';
import TenantPriceListComponent from '../../components/PriceList/TenantPriceList/TenantPriceList/TenantPriceListComponent';
import { MedicalInsurance } from '../../models/Entities/MedicalInsurances/MedicalInsurance';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const MedicalInsurancesPage = () => {
  const AddIcon: IIconProps = { iconName: 'CirclePlus' };
  const [selectedItem, setSelectedItem] = useState<MedicalInsurance>();
  const [searchText, setSearchText] = useState<string>();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const [
    isOpenPanForm,
    { setTrue: openPanelPlanForm, setFalse: dismissPanelPlanForm }
  ] = useBoolean(false);

  // events
  const HandleEditEvent = (MedicalInsurance: MedicalInsurance) => {
    setSelectedItem(MedicalInsurance);
    openPanel();
  };

  const HandleSearchTextChange = (text: string | undefined) => {
    setSearchText(text);
  };

  const handleClosePanel = () => {
    setSelectedItem(undefined);
    dismissPanel();
    dismissPanelPlanForm();
  };

  // const HandleAddPlanEvent = (MedicalInsurance: MedicalInsurance) => {
  //   setSelectedItem(MedicalInsurance);
  //   openPanelPlanForm();
  // };

  const handleAddClick = () => {
    openPanel();
  };

  //useTranslation()
  const [t] = useTranslation();

  return (
    <>
      <PageHeader title={t('medicalInsurancePage.medicalInsuranceTitle')} />
      <div className="header">
        <h2>{t('medicalInsurancePage.medicalInsuranceListSubtitle')}</h2>
      </div>

      <div className="Section">
        <div className="SectionControls spaceBetween">
          <PrimaryButton
            secondaryText={t('medicalInsurancePage.buttons.addSocialSecurity')}
            onClick={handleAddClick}
            text={t('medicalInsurancePage.buttons.addMedicalCoverage')}
            iconProps={AddIcon}
            className="actionButton"
          />
          <SearchBox
            onChange={(
              event?: React.ChangeEvent<HTMLInputElement> | undefined,
              newValue?: string | undefined
            ) => HandleSearchTextChange(newValue)}
          />
        </div>
        <MedicalInsuranceListComponent
          editEvent={HandleEditEvent}
          searchText={searchText}
        />
        <MedicalInsuranceFormComponent
          isOpen={isOpen}
          dismissPanel={handleClosePanel}
          openPanel={openPanel}
          MedicalInsurance={selectedItem}
        />
        {selectedItem && (
          <MedicalInsurancePlanFormComponent
            isOpen={isOpenPanForm}
            dismissPanel={handleClosePanel}
            openPanel={openPanelPlanForm}
            MedicalInsuranceId={selectedItem.id}
            MedicalInsurancePlan={undefined}
          />
        )}
      </div>

      <div className="header">
        <h2>Listas de precio para atenci&oacute;n particular</h2>
      </div>
      <div className="Section">
        <TenantPriceListComponent />
      </div>
    </>
  );
};
