import { PriceDetails } from './../../models/Entities/PriceList/PriceDetail';
import {
  PriceListDetailsState,
  PriceListDetailsUnloadState
} from '../states/PriceListDetailsState';
import { Action, Reducer } from 'redux';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.PriceLists.PriceListAddPrice_Request_Action
  | Actions.PriceLists.PriceListAddPrice_Receive_Action
  | Actions.PriceLists.PriceListAddPrice_Fail_Action
  | Actions.PriceLists.PriceListUpdatePrice_Request_Action
  | Actions.PriceLists.PriceListUpdatePrice_Receive_Action
  | Actions.PriceLists.PriceListUpdatePrice_Fail_Action
  | Actions.PriceLists.PriceListDeletePrice_Request_Action
  | Actions.PriceLists.PriceListDeletePrice_Receive_Action
  | Actions.PriceLists.PriceListDeletePrice_Fail_Action
  | Actions.PriceLists.PriceListChangePrice_Action
  | Actions.PriceLists.PriceListChangeRemotePrice_Action
  | Actions.PriceLists.PriceListChangeFailPrice_Action
  | Actions.PriceLists.PriceListChangeRandomPrice_Action;

export const PriceListDetailsReducer: Reducer<PriceListDetailsState> = (
  state: PriceListDetailsState | undefined,
  incomingAction: Action
): PriceListDetailsState => {
  if (state === undefined) {
    return PriceListDetailsUnloadState as PriceListDetailsState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    // case 'REQUEST':
    //   return {
    //     ...state,
    //     isLoadingOne: true,
    //     isLoadingOneSuccess: undefined,
    //     FailGettingOne: false,
    //     Error: undefined
    //   };
    // case 'RECEIVE':
    //   return {
    //     ...state,
    //     isLoadingOne: false,
    //     isLoadingOneSuccess: true,
    //     FailGettingOne: false,
    //     Error: undefined,
    //     Selected: action.payload
    //   };
    // case 'FAIL_GET':
    //   return {
    //     ...state,
    //     isLoadingOne: false,
    //     isLoadingOneSuccess: false,
    //     FailGettingOne: true,
    //     Error: action.Error
    //   };
    case 'PRICE_LIST_ADD_PRICE_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'PRICE_LIST_ADD_PRICE_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [...state.list, action.Price]
      };
    case 'PRICE_LIST_ADD_PRICE_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'PRICE_LIST_UPDATE_PRICE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'PRICE_LIST_UPDATE_PRICE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (item: PriceDetails) => item.id !== action.Price.id
          ),
          action.Price
        ].sort(Functions.DynamicSort('itemReferredId'))
      };
    case 'PRICE_LIST_UPDATE_PRICE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };
    case 'PRICE_LIST_DELETE_PRICE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'PRICE_LIST_DELETE_PRICE_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (item: PriceDetails) => item.id !== action.PriceID
          )
        ].sort(Functions.DynamicSort('itemReferredId'))
      };
    case 'PRICE_LIST_DELETE_PRICE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
    case 'PRICE_LIST_CHANGE_PRICE_ACTION':
      return {
        ...state,
        list: [
          ...state.list.filter(
            (item: PriceDetails) => item.id !== action.Price.id
          ),
          { ...action.Price, isChanged: true }
        ]
      };
    case 'PRICE_LIST_CHANGE_REMOTE_PRICE_ACTION':
      var NewRemotePrice = { ...action.Price, isChanged: true }
      console.log('NewRemotePrice', NewRemotePrice)
      console.log('state.list', state.list)
      var newList = [
        ...state.list.filter(
          (item: PriceDetails) => item.id !== action.Price.id
        ),
        NewRemotePrice
      ]
      console.log('newList', newList)
      return {
        ...state,
        list: newList
      };
    case 'PRICE_LIST_CHANGE_FAIL_PRICE_ACTION':
      return {
        ...state,
        list: [
          ...state.list.filter(
            (item: PriceDetails) => item.id !== action.Price.id
          ),
          { ...action.Price, isChanged: true }
        ]
      };
    case 'PRICE_LIST_CHANGE_RANDOM_PRICE_ACTION':
      return {
        ...state,
        list: [
          ...state.list.filter(
            (item: PriceDetails) => item.id !== action.Price.id
          ),
          { ...action.Price, isChanged: true }
        ]
      };
    
  }

  return state;
};
