import { TenantPriceList } from '../../models/Entities/TenantPriceLists/TenantPriceList';
import { CommonState } from '../common/state/commonState';

export interface TenantPriceListState extends CommonState<TenantPriceList> {
  // Adding
  isAddingNewPrice: boolean;
  newPriceAddedSuccess: boolean | undefined;
  failOnAddNewPrice: boolean;

  // updating
  isSavingPrice: boolean;
  priceSavingSuccess: boolean | undefined;
  failOnSavePrice: boolean;

  // validity start
  isSettingValidityStartDate: boolean;
  SetValidityStartDateSuccess: boolean | undefined;
  FailOnSetValidityStartDate: boolean;

  // validity end
  isSettingValidityEndDate: boolean;
  SetValidityEndDateSuccess: boolean | undefined;
  FailOnSetValidityEndDate: boolean;
}

export const TenantPriceListUnloadedState: any = {
  // loading all
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  FailGettingAll: false,

  // loading one
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  FailGettingOne: false,

  // saving
  isSaving: false,
  isSavingSuccess: undefined,
  FailSaving: false,

  // adding
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,

  // deleting
  isDeletingOne: false,
  isDeletingOneSuccess: undefined,
  FailDeletingOne: false,

  isFiltered: false,
  filterCriteria: undefined,

  list: [],
  Selected: undefined,
  Error: undefined,

  // validity start
  isSettingValidityStartDate: false,
  SetValidityStartDateSuccess: undefined,
  FailOnSetValidityStartDate: false,

  // validity end
  isSettingValidityEndDate: false,
  SetValidityEndDateSuccess: undefined,
  FailOnSetValidityEndDate: false,

  // Adding Price
  isAddingNewPrice: false,
  newPriceAddedSuccess: undefined,
  FailOnAddNewPrice: false,

  // saving price
  isSavingPrice: false,
  PriceSavingSuccess: undefined,
  FailOnSavePrice: false
};
