import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import MOMENT from 'react-moment';
import { useBoolean } from "@fluentui/react-hooks";

import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Badge, Label } from "@fluentui/react-components";

// functions
import * as ComponentFunctions from './PractitionerProtocolVisit.List.Functions';

// styles
import './PractitionerProtocolVisit.List.Styles.css';

// models
import { Protocol } from "../../../models/Entities/Protocols/Protocol";
import { CommandBar, FocusTrapZone, FontIcon, FontWeights, IButtonProps, ICommandBarItemProps, Separator } from "@fluentui/react";
import { PractitionerVisit } from "../../../models/Entities/Practitioners/Practitioner";
import { PatientVisit } from "../../../models/Entities/Protocols/PatientVisist";
import ProtocolHeaderComponent from "../../Protocols/ProtocolHeader/ProtocolHeader.Component";
import SettlementFormComponent from "../../Settlements/SettlementForm/Settlement.Form.Component";


const practitionerProtocolVisitList = (props: ComponentFunctions.Props) => {

    const [FilteredProtocols, setFilteredProtocols] = useState<Protocol[]>([]);
    const [isOpenSettlementFormPanel, { setTrue: openSettlementFormPanel, setFalse: dismissSettlementFormPanel }] =
    useBoolean(false);

    const [selectedProtocol, setSelectedProtocol] = useState<number | undefined>(undefined);

    useEffect(() => {
        props.PractitionerId && props.GetPractitionerById(props.PractitionerId);
    }, [props.PractitionerId]);

    useEffect(() => {
        props.GetProtocolsByPractitionerId(props.PractitionerId);
    }, [props.Practitioner !== undefined]);

    useEffect(() => {
        if (props.PractitionerId && props.Protocols.length > 0) {
            var filteredProtocols = props.protocolsIds?.length > 0 ? props.Protocols?.filter((protocol: Protocol) => props.protocolsIds.some((id: number) => id === protocol.id)) : props.Protocols;
            setFilteredProtocols(filteredProtocols);
            filteredProtocols?.forEach((protocol) => {
                props.GetPractitionerProtocolVisits(props.PractitionerId, protocol.id, props.DateFrom, props.DateTo);
            });
        }

    }, [props.Protocols, props.PractitionerId, props.DateFrom, props.DateTo, props.protocolsIds]);

    // events. 
    const onSettle = () => {
        openSettlementFormPanel();
    }

    // items command bar 
    const _items: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: 'Generar liquidación',
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'EntitlementPolicy' },
            onClick: () => onSettle()
        }
    ];

    const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

    const ProtocolControls = (
        <>
            <CommandBar
                items={_items}
                overflowItems={undefined}
                overflowButtonProps={overflowProps}
                farItems={undefined}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
            />

            <Separator />
        </>
    );

    const Forms = (
        <>
            <SettlementFormComponent
                isOpen={isOpenSettlementFormPanel}
                openPanel={openSettlementFormPanel}
                dismissPanel={dismissSettlementFormPanel}
                ProtocolId={selectedProtocol}
                PractitionerId={props.PractitionerId}
            />
        </>
    );

    return (
        <div>
            <Accordion>
                {FilteredProtocols?.map((protocol: Protocol) =>
                    <AccordionItem value={protocol.id}>
                        <AccordionHeader onClick={() => setSelectedProtocol(protocol.id)} icon={
                            <FontIcon iconName="WebAppBuilderFragment" />
                        }><Label style={{ fontWeight: FontWeights.semibold }}>{protocol.title}&nbsp;<Badge appearance="filled">{props.Practitioner?.ProtocolVisits?.find((visits: PractitionerVisit) => visits.protocolId === protocol.id)?.visits?.length || 0}</Badge></Label></AccordionHeader>
                        <AccordionPanel>
                            <div className="VisitPanelContainer">
                                <ProtocolHeaderComponent ProtocolId={protocol.id} />
                                {ProtocolControls}
                                <table className="Table">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Protocolo</th>
                                            <th>Visita</th>
                                            <th>Paciente</th>
                                            <th>Tipo</th>
                                            <th>Tipo</th>
                                            <th>Estado</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.visitis?.find((visits: PractitionerVisit) => visits.protocolId === protocol.id)?.visits?.map((visit: PatientVisit) =>
                                            <tr>
                                                <td>
                                                    <MOMENT date={visit.date} format='DD/MM/YYYY' />
                                                </td>
                                                <td>{protocol.title}</td>
                                                <td>{visit.title}</td>
                                                <td>{visit.numberInProtocol}</td>
                                                <td>{visit.visitType === 0 ? 'Presencial' : 'Remoto'}</td>
                                                <td><Label>{visit.type}</Label></td>
                                                <td>{ComponentFunctions.GetStatusDescription(visit.status)}</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </AccordionPanel>
                    </AccordionItem>
                )}
            </Accordion>
            {Forms}
        </div>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(practitionerProtocolVisitList as any);