//Functions
import * as Functions from '../functions/common';
import { ChartPaymentDate } from '../models/Entities/CashFlow/Payment/ChartPaymentDate';
import { NewPayment } from '../models/Entities/CashFlow/Payment/NewPayment';

// models
import { Payment } from '../models/Entities/CashFlow/Payment/Payment';
import { IResult } from '../models/HttpResult';

// urls
const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'cashflow/payments';

// Get all payments by payer
export const GetPaymentsByPayer = async (token: string, payerIdentifier: string, receiverIdentifier: string | undefined): Promise<Payment[]> => {

    let RequestURL: string = BaseURL + '?payer=' + payerIdentifier;

    if (receiverIdentifier) {
        RequestURL += '&receiver=' + receiverIdentifier;
    }

    try {
        const Response: Payment[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Payment[]>>;
            }
        })
        .then((data: IResult<Payment[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Get all payments by receiver
export const GetPaymentsByReceiver = async (token: string, receiverIdentifier: string, payerIdentifier: string | undefined): Promise<Payment[]> => {

    let RequestURL: string = BaseURL + '?receiver=' + receiverIdentifier;

    if (payerIdentifier) {
        RequestURL += '&payer=' + payerIdentifier;
    }

    try {
        const Response: Payment[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Payment[]>>;
            }
        })
        .then((data: IResult<Payment[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Get payment by id
export const GetPaymentById = async (token: string, paymentId: number): Promise<Payment> => {

    let RequestURL: string = BaseURL + '/' + paymentId;

    try {
        const Response: Payment = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Payment>>;
            }
        })
        .then((data: IResult<Payment>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Create payment
export const CreatePayment = async (token: string, payment: NewPayment): Promise<Payment> => {

    try {
        const Response: Payment = await fetch(BaseURL, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(payment)
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Payment>>;
            }
        })
        .then((data: IResult<Payment>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Update payment
export const UpdatePayment = async (token: string, payment: Payment): Promise<Payment> => {

    try {
        const Response: Payment = await fetch(BaseURL, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(payment)
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Payment>>;
            }
        })
        .then((data: IResult<Payment>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

// Delete payment
export const DeletePayment = async (token: string, paymentId: number): Promise<Payment> => {

    try {
        const Response: Payment = await fetch(BaseURL + '/' + paymentId, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<Payment>>;
            }
        })
        .then((data: IResult<Payment>) => {
            return data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

export enum PaymentType {
    income = 1,
    outcome = 2
}

// Get chart information 
export const GetPaymentsChart = async (paymentType: PaymentType, entityIdentifier: string, relatedEntityIdentifier: string | undefined, startDate: Date, EndDate: Date, token: string): Promise<ChartPaymentDate[]> => {

    let RequestURL: string = BaseURL + '/chart-info?PaymentType=' + paymentType + '&EntityIdentifier=' + entityIdentifier + (relatedEntityIdentifier ? '&RelatedEntityIdentifier=' + relatedEntityIdentifier : '') + '&StartDate=' + startDate.toJSON() + '&EndDate=' + EndDate.toJSON();

    try {
        const Response: ChartPaymentDate[] = await fetch(RequestURL, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json() as Promise<IResult<ChartPaymentDate[]>>;
            }
        })
        .then((data: IResult<ChartPaymentDate[]>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            })
        );

        return Response;
    } catch (error: any) {
        throw error;
    }
};

