import { ITag, Label, TagPicker } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { Contract } from '../../../../models/Entities/Contracts/Contract';
import { connect } from 'react-redux';
import { Spinner } from '@fluentui/react-components';

// own functions
import * as ownFunctions from './ContractSelectorFunctions';


import { use } from 'i18next';



export const ContractsSelectorComponent = (
  props: ownFunctions.Props
) => {

  const onChangeIdTextFieldValue = (items?: ITag[] | undefined) => {
    let ContractsComboSelected: Contract[] = [];
    if (items && items.length > 0) {
      items.forEach(async (item: ITag) => {
        const ContractId = Number(item.key);
        const contract: Contract =
          props.Contracts?.find((contract: Contract) => contract.id === ContractId) as Contract;

        ContractsComboSelected = [
          ...ContractsComboSelected,
          contract
        ];
        setInternalContratsSelected(ContractsComboSelected);
        setContracsOptionsSelected(items);
      });
    } else {
      setInternalContratsSelected([]);
      setContracsOptionsSelected([]);
    }
  };

  const [ContractOptions, setContractOptions] = useState<ITag[]>();
  const [ContractsOptionsSelected, setContracsOptionsSelected] =
    useState<ITag[]>();

  const [InternalContractsSelected, setInternalContratsSelected] =
    useState<Contract[]>([]);

  useEffect(() => {
    ownFunctions.GetContractsOptions(
      props.Contracts as Contract[]
    ).then((options: ITag[]) => {
      setContractOptions(options);
    });
  }, [props.Contracts]);

  useEffect(() => {
    props.onSelectedContract(InternalContractsSelected);
  }, [InternalContractsSelected]);

  useEffect(() => {
    console.log('contratos seleccionados', ContractsOptionsSelected)
    if (props.ContractsSelected && ContractOptions) {
      const auxContractsSelected = ContractOptions.filter(
        (option: ITag) =>
          option.key ===
          props.ContractsSelected?.find((id: number) => id === option.key)
      );
      setContracsOptionsSelected(auxContractsSelected);
    }
  }, [ContractOptions]);

  const filterSuggestedMedicalServices = async (
    filterText: string,
    tagList: ITag[] | undefined
  ): Promise<ITag[]> => {
    return filterText
      ? ContractOptions?.filter(
        (tag) =>
          tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
          !ownFunctions.listContainsTagList(tag, tagList)
      ) || []
      : [];
  };

  return (
    <>
      <Label htmlFor="SnomedOption">Seleccione un Contrato </Label>
      {!props.isLoading && props.isLoadingSuccessfully && <>
        <TagPicker
          removeButtonAriaLabel="Eliminar"
          selectionAriaLabel="Seleccione un servicio"
          onResolveSuggestions={filterSuggestedMedicalServices}
          onEmptyResolveSuggestions={(selectedItems?: ITag[] | undefined) => ContractOptions || []}
          getTextFromItem={ownFunctions.getTextFromItem}
          pickerSuggestionsProps={ownFunctions.pickerSuggestionsProps}
          itemLimit={props.MultiselectEnable ? undefined : 1}
          onChange={onChangeIdTextFieldValue}
          pickerCalloutProps={{ doNotLayer: false }}
          inputProps={{
            id: 'ServicesOption'
          }}
          disabled={props.disabled}
          selectedItems={ContractsOptionsSelected}
        />
        {props.noteText && <small>{props.noteText}</small>}
      </>}
      {props.isLoading && <Spinner size="extra-tiny" label={"Cargando contratos..."} />}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(ContractsSelectorComponent as any);
