import { Languaje } from "../../models/Entities/Settings/Languajes";
import { CommonState } from "../common/state/commonState";


export interface LanguajeState extends CommonState<Languaje> {}

export const LanguajeUnloadedState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,

    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,

    // saving
    isSaving: false,
    isSavingSuccess: undefined,
    FailSaving: false,

    // adding
    isAdding: false,
    isAddingSuccess: undefined,
    FailAdding: false,

    // deleting
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    FailDeletingOne: false,

    isFiltered: false,
    filterCriteria: undefined,

    list: [],
    Selected: undefined,
    Error: undefined,
}