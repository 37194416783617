
// store
import * as ProceduresStore from "../../../redux/middleware/ProceduresMiddleware";
import * as MedicalServiceStore from "../../../redux/middleware/MedicalServicesMiddleware";


// models
import { AvailableMedicalService } from "../../../models/Entities/MedicalServices/AvailableMedicalService";
import { Procedure } from "../../../models/Entities/Procedures/Procedure";
import { ErrorType } from "../../../models/HttpError";
import { AppState } from "../../../redux/reducers";

interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    MedicalServiceId?: number | undefined;
}
  
interface ConnectedProps {
isLoading: boolean;
isLoadingSuccessfully: boolean;
failOnLoading: boolean;
error: ErrorType | undefined;
Procedures: Procedure[];
MedicalService: AvailableMedicalService | undefined;
access_token: string | undefined;
}

interface DispatchProps {
    GetAllProceduresByCriteria: typeof ProceduresStore.actionCreators.GetAllProceduresByCriteria;
    GetMedicalServiceById: typeof MedicalServiceStore.actionCreators.GetMedicalServiceById;
    AddMedicalService: typeof MedicalServiceStore.actionCreators.AddMedicalService;
    UpdateMedicalService: typeof MedicalServiceStore.actionCreators.UpdateMedicalService;

}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
isLoading: state.ContractConcept?.isLoadingAll,
isLoadingSuccessfully: state.ContractConcept?.successLoadingAll,
failOnLoading: state.ContractConcept?.failOnLoadingAll,
error: state.ContractConcept?.error,
procedures: state.Procedures?.list,
MedicalService: state.MedicalServices?.list?.find(x => x.id === ownProps.MedicalServiceId),
access_token: state.oidc?.user?.access_token
});

export const mapDispatchToProps = ({
...ProceduresStore.actionCreators,
...MedicalServiceStore.actionCreators
});