import {
  FontIcon,
  FontSizes,
  IconButton,
  IIconProps,
  PrimaryButton,
  Separator,
  Spinner
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { MedicalInsuranceAgreement } from '../../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement';
import { useNavigate } from 'react-router-dom';
import MOMENT from 'react-moment';
import { useBoolean } from '@fluentui/react-hooks';
import { MedicalInsuranceAgreementFormComponent } from '../MedicalInsuranceAgreementForm/MedicalInsuranceAgreementFormComponent';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// component support functions
import * as ownFunctions from './MedicalInsuranceAgreementListFunctions';

//styles
import './MedicalInsuranceAgreementListComponent.css';
import { ProvidersListPage } from '../../../pages/Providers/ProvidersList/ProvidersListPage';

export const MedicalInsuranceAgreementListComponent = (
  props: ownFunctions.Props
) => {
  const EditIcon: IIconProps = { iconName: 'Edit' };
  const InfoIcon: IIconProps = { iconName: 'DietPlanNotebook' };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const AddIcon: IIconProps = { iconName: 'CirclePlus' };
  const [selectedItem, setSelectedItem] = useState<MedicalInsuranceAgreement>();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const handleClosePanel = () => {
    setSelectedItem(undefined);
    dismissPanel();
  };

  //useTranslation()
  const [t] = useTranslation();

  //events
  const onEditEvent = (agreement: MedicalInsuranceAgreement) => {
    setSelectedItem(agreement);
    openPanel();
  };

  const onAddEvent = () => {
    setSelectedItem(undefined);
    openPanel();
  };

  const onInfoEvent = (agreement: MedicalInsuranceAgreement) => {
    navigate(
      '/insurances/' + props.MedicalInsuranceId + '/agreements/' + agreement.id
    );
  };

  //effects
  useEffect(() => {
    if (props.MedicalInsuranceId) {
      props.RequestAgreements(Number(props.MedicalInsuranceId), 1, 100);
    }
  }, [props.MedicalInsuranceId]);

  const AgreementTable = (
    <>
      {props.loadingSuccess && props.agreements && props.agreements.length > 0 && (
        <>
          <table className="Table">
            <thead>
              <tr>
                <th></th>
                <th>{t('medicalInsuranceAgreementList.props.providerIdentifier')}</th>
                <th>{t('medicalInsuranceAgreementList.props.effectiveDate')}</th>
                <th>{t('medicalInsuranceAgreementList.props.effectiveDateUntil')}</th>
                <th>{t('medicalInsuranceAgreementList.props.state')}Estado</th>
                <th>{t('medicalInsuranceAgreementList.props.options')}Opciones</th>
              </tr>
            </thead>
            <tbody>
              {props.agreements
                ? props.agreements.map((item: MedicalInsuranceAgreement) => (
                  <tr key={item.id} className="TableBodyRow">
                    <td className={
                      'primary agreement ' +
                      ownFunctions.getStatus(item).status
                    }><FontIcon iconName='Certificate' style={{ fontSize: FontSizes.size24, padding: 5 }} /></td>
                    <td>
                      <strong>{item.healthProviderId}</strong>
                    </td>
                    <td>
                      {item.agreementStartDate ? (
                        <MOMENT format="DD/MM/YYYY">
                          {item.agreementStartDate}
                        </MOMENT>
                      ) : (
                        t('medicalInsuranceAgreementList.props.notInformed')
                      )}
                    </td>
                    <td>
                      {item.agreementEndDate ? (
                        <MOMENT format="DD/MM/YYYY">
                          {item.agreementEndDate}
                        </MOMENT>
                      ) : (
                        t('medicalInsuranceAgreementList.props.notInformed')
                      )}
                    </td>
                    <td>{ownFunctions.getStatus(item).message}</td>
                    <td>
                      <IconButton
                        iconProps={EditIcon}
                        title={t('medicalInsuranceAgreementList.props.modify')}
                        ariaLabel="Edit"
                        onClick={() => onEditEvent(item)}
                      />
                      <IconButton
                        iconProps={InfoIcon}
                        title={t('medicalInsuranceAgreementList.props.information')}
                        ariaLabel="Infi"
                        onClick={() => onInfoEvent(item)}
                      />
                    </td>
                  </tr>
                ))
                : null}
            </tbody>
          </table>
        </>
      )}
      {props.loadingSuccess && props.agreements?.length === 0 && (
        <div className="NoData">
          <Separator />
          <label>{t('medicalInsuranceAgreementList.props.registeredAgreements')} </label>
        </div>
      )}
      {props.loading && (
        <div>
          <Spinner
            label={t('medicalInsuranceAgreementList.props.loadingAgreements')}
            ariaLive="assertive"
            labelPosition="top"
          />
        </div>
      )}
    </>
  );

  const AddAgreementForm = (
    <MedicalInsuranceAgreementFormComponent
      isOpen={isOpen}
      dismissPanel={handleClosePanel}
      openPanel={openPanel}
      MedicalInsuranceId={Number(props.MedicalInsuranceId)}
      MedicalInsuranceAgreement={selectedItem}
    />
  );

  return (
    <>
      <PrimaryButton
        secondaryText={t('medicalInsuranceAgreementList.props.addAgreements')}
        onClick={onAddEvent}
        text={t('medicalInsuranceAgreementList.props.addAgreements')}
        iconProps={AddIcon}
        className="actionButton"
      />
      {AgreementTable}
      {AddAgreementForm}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(MedicalInsuranceAgreementListComponent as any);
