import { PractitionerMedicalInsuranceAssociation } from '../../models/Entities/PractitionerAssociations/MedicalInsurances/PractitionerMedicalInsuranceAssociation';
import { Practitioner } from '../../models/Entities/Practitioners/Practitioner';
import { CommonState } from '../common/state/commonState';

export interface PractitionerState extends CommonState<Practitioner> {
  // medical insurance associations
  medicalInsuranceAssociations: PractitionerMedicalInsuranceAssociation[];
  isLoadingAllMedicalInsurances: boolean;
  successLoadingAllMedicalInsurance: boolean | undefined;
  failOnLoadingAllMedicalInsurances: boolean;

  // update all
  isUpdatingAll: boolean;
  UpdatingAllSuccess: undefined | boolean;
  FailUpdatingAll: boolean;

  // adding medical insurance associations
  isAddingOneMedicalInsurance: boolean;
  successAddingOneMedicalInsurance: boolean | undefined;
  failOnAddingOneMedicalInsurance: boolean;

  // deleting medical insurance associations
  isDeletingOneMedicalInsurance: boolean;
  successDeletingOneMedicalInsurance: boolean | undefined;
  failOnDeletingOneMedicalInsurance: boolean;
}

export const PractitionerUnloadState: any = {
  // loading all
  isLoadingAll: false,
  successLoadingAll: undefined,
  failOnLoadingAll: false,

  // update all
  isUpdatingAll: false,
  UpdatingAllSuccess: undefined,
  FailUpdatingAll: false,

  // loading all medical insurances
  medicalInsuranceAssociations: [],
  isLoadingAllMedicalInsurances: false,
  successLoadingAllMedicalInsurance: undefined,
  failOnLoadingAllMedicalInsurances: false,

  // adding medical insurance associations
  isAddingOneMedicalInsurance: false,
  successAddingOneMedicalInsurance: undefined,
  failOnAddingOneMedicalInsurance: false,

  // deleting medical insurance associations
  isDeletingOneMedicalInsurance: false,
  successDeletingOneMedicalInsurance: undefined,
  failOnDeletingOneMedicalInsurance: false
};
