import { PrimaryButton, Separator } from "@fluentui/react";
import { ErrorMessageComponent } from "../../Error/ErrorMessageComponent";
import SettlementTotalItemComponent from "../SettlementTotalItem/SettlementTotalItem.Component";

//functions
import * as ComponentFunctions from './SettlementTotal.Functions';
import { connect } from "react-redux";

////i18Next
import { useTranslation } from 'react-i18next';

const SettlementTotalComponent = (props: ComponentFunctions.Props) => {

    //useTranslation()
    const [t] = useTranslation();

    // actions 
    const Recalculate = (settlementId: number) => {
        props.RecalculateSettlementById(settlementId);
    }

    return (
        <>
            <PrimaryButton
                onClick={() => Recalculate(props.SettlementId)}
                iconProps={{ iconName: props.isRecalculating ? 'sync' : 'Calculator', className: props.isRecalculating ? 'withRotation' : '' }}
                className="actionButton"
                text={t('settlementTotal.button.recalculateButton')}
            />
            <Separator />
            {props.failOnLoading && (props.error || props.entityError) && <ErrorMessageComponent Error={props.error || props.entityError} />}
            {props.Currencies?.map((currency: string) => (
                <>
                    <SettlementTotalItemComponent SettlementId={props.SettlementId} CurrencyCode={currency} />
                    <hr />
               </>
            ))}
        </>
    )
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementTotalComponent as any);