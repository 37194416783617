import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { MedicalInsurance } from '../../../models/Entities/MedicalInsurances/MedicalInsurance';
import { ErrorType } from '../../../models/HttpError';
import { AppState } from '../../../redux/reducers';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import {
  DefaultButton,
  FontSizes,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  mergeStyleSets,
  MessageBarType,
  Modal,
  PrimaryButton,
  Toggle
} from '@fluentui/react';
import { Spinner, Text, Label } from '@fluentui/react';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Store
import * as MedicalInsuranceStore from '../../../redux/middleware/MedicalInsuranceMiddleware';

//styles
import './MedicalInsuranceMultiSelectorComponent.css';

export type SelectedMedicalInsuranceType = {
  medicalInsuranceId: number;
  ReferenceId: number;
};

export interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  SelectedMedicalInsurance: SelectedMedicalInsuranceType[];
  onSelect?: (medicalInsurance: MedicalInsurance) => void;
  onUnselect?: (medicalInsurance: SelectedMedicalInsuranceType) => void;
}

interface ConnectedProps {
  isLoading: boolean;
  loadingSuccess: boolean;
  failOnLoading: boolean;
  error: ErrorType;
  MedicalInsurances: MedicalInsurance[];
}

interface DispatchProps {
  GetAllMedicalInsurances: typeof MedicalInsuranceStore.actionCreators.GetAllMedicalInsurances;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const MedicalInsuranceMultiSelectorComponent = ({
  MedicalInsurances,
  isLoading,
  loadingSuccess,
  failOnLoading,
  error,
  SelectedMedicalInsurance,
  isOpen,
  openPanel,
  dismissPanel,
  onSelect,
  onUnselect,
  GetAllMedicalInsurances
}: Props) => {
  useEffect(() => {
    if (GetAllMedicalInsurances) GetAllMedicalInsurances(1, 200);
  }, [GetAllMedicalInsurances]);

  const HandleCancelForm = () => {
    dismissPanel();
  };

  const OnSelectMedicalInsurance = (medicalInsurance: MedicalInsurance) => {
    if (onSelect) onSelect(medicalInsurance);
  };

  //useTranslation()
  const [t] = useTranslation(); 
  const OnUnselectMedicalInsurance = (
    service: SelectedMedicalInsuranceType | undefined
  ) => {
    if (onUnselect && service) onUnselect(service);
  };

  const optionList = (
    <>
      <MessageComponent
        type={MessageBarType.warning}
        message={
          t('medicalInsuranceMultiSelector.message.medicalInsurance') +
          SelectedMedicalInsurance.length +
          t('medicalInsuranceMultiSelector.message.medicalInsuranceSelected') 
        }
      />

      <div className="SelectionListContainer">
        <ul>
          {MedicalInsurances &&
            MedicalInsurances.map((medicalInsurance: MedicalInsurance) => (
              <li key={medicalInsurance.id}>
                <div className="SelectionList">
                  <Toggle
                    defaultChecked={
                      SelectedMedicalInsurance.find(
                        (service: SelectedMedicalInsuranceType) =>
                          service.medicalInsuranceId === medicalInsurance.id
                      )
                        ? true
                        : false
                    }
                    onChange={(e, checked) =>
                      checked
                        ? OnSelectMedicalInsurance(medicalInsurance)
                        : OnUnselectMedicalInsurance(
                            SelectedMedicalInsurance.find(
                              (service: SelectedMedicalInsuranceType) =>
                                service.medicalInsuranceId ===
                                medicalInsurance.id
                            )
                          )
                    }
                  />
                  <div className="SelectionList-item">
                    <Label style={{ fontSize: FontSizes.medium }}>
                      {medicalInsurance.businessName}
                    </Label>
                    <Text>{medicalInsurance.title}</Text>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );

  // footer
  const onRenderFooterContent = () => {
    return (
      <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'medicalInsuranceMultiSelector.footer.closeButton'}/>
        </DefaultButton>
      </div>
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={dismissPanel}
        isModeless={false}
        containerClassName={contentStyles.container}
        closeButtonAriaLabel="Close"
      >
        <div className={contentStyles.header}>
          <span><Trans i18nKey={'medicalInsuranceMultiSelector.modal.medicalProviders'}/></span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={dismissPanel}
          />
        </div>
        {failOnLoading && (
          <MessageComponent
            message={error.ErrorMessage}
            type={MessageBarType.error}
          />
        )}
        {isLoading ? (
          <div>
            <Spinner
              label={t('medicalInsuranceMultiSelector.modal.loadingMedicalCoverage')} 
              ariaLive="assertive"
              labelPosition="top"
            />
          </div>
        ) : (
          optionList
        )}
        {onRenderFooterContent()}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: AppState, ownprops: IProps) => ({
  isLoading: state.MedicalServices?.isLoadingAll,
  loadingSuccess: state.MedicalServices?.successLoadingAll,
  failOnLoading: state.MedicalServices?.failOnLoadingAll,
  error: state.MedicalServices?.error,
  MedicalInsurances: state.MedicalInsurances?.list
});

const mapDispatchToProps = {
  ...MedicalInsuranceStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalInsuranceMultiSelectorComponent as any);

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10,
    Width: '600px',
    paddindright: '1em'
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '5px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '5px 5px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    bordertop: '4px solid lightgray'
  }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
