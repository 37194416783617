import { FontSizes, Label, Text } from "@fluentui/react";
import { StageCycle } from "../../../../models/Entities/Contracts/StageCycle";

////i18Next
import { useTranslation, Trans } from 'react-i18next';


// styles
import './CycleList.Styles.css';
import { ConfirmationButtonComponent } from "../../../../commons/component/ConfirmationButton/ConfirmationButtonComponent";
import { ErrorType } from "../../../../models/HttpError";

export interface IProps {
    Cycle: StageCycle;
    index: number;
    onEdit: (stage: StageCycle) => void;
    onDelete: (stage: StageCycle) => void;
    deleteProcess: boolean;
    deleteSuccess: boolean | undefined;
    deleteError: boolean;
    Error: ErrorType | undefined;
}



export const CycleItemComponent = (props: IProps) => {
    //useTranslation()
    const [t] = useTranslation();
    return (
        <>
            <div className="CycleListItem" key={props.Cycle.id} onClick={() => props.onEdit(props.Cycle)}>
                <div className="CycleIndex">
                    <Text style={{fontSize: FontSizes.mini}}>Ciclo {props.index + 1}</Text>
                </div>
                <div>
                    <Label style={{fontSize: FontSizes.size14}}>{props.Cycle.cycleName}</Label>
                </div>
                <div className="danger">
                <ConfirmationButtonComponent 
                ButtonId={"deletebranch" + props.Cycle.id} 
                ButtonIconName={"Delete"} 
                ButtonText={""} 
                ButtonCssClass={""} 
                ConfirmationButtonText={t('cycleItem.confirmationButtonComponent.deleteButton')} 
                ConfirmationButtonCssClass={"deleteButton"} 
                CancelButtonText={t('cycleItem.confirmationButtonComponent.cancelButton')} 
                CancelButtonCssClass={""} 
                Title={t('cycleItem.confirmationButtonComponent.deleteCycle')} 
                Description={""} 
                ElementTextHeader={t('cycleItem.confirmationButtonComponent.deleteCycleInformation')+ props.Cycle.cycleName} 
                ElementTextInformation={t('cycleItem.confirmationButtonComponent.operationInfo')} 
                onConfirmation={() =>
                    props.onDelete(props.Cycle)
                  }
                  onCancel={() => undefined}
                  isOperationExecuting={
                    props.deleteProcess
                  }
                  isOperationExecutedWithError={
                    props.deleteError
                  }
                  OperationError={props.Error
                  }
                />
                </div>
            </div>
        </>
    );
};