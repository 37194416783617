import { ProfessionalAvailability } from './../../models/Entities/ProfessionalAvailability/ProfessionalAvailability';
import { Action, Reducer } from 'redux';
import {
  ProfessionalAvailabilityState,
  ProfessionalAvailabilityUnloadedState
} from '../states/ProfessionalAvailabilityState';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetAll_Request_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetAll_Receive_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetAll_Fail_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetById_Request_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetById_Receive_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_GetById_Fail_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Add_Request_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Add_Receive_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Add_Fail_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Update_Request_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Update_Receive_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Update_Fail_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Delete_Request_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Delete_Receive_Action
  | Actions.ProfessionalAvailability.ProfessionalAvailability_Delete_Fail_Action;

export const ProfessionalAvailabilityReducer: Reducer<
  ProfessionalAvailabilityState
> = (
  state: ProfessionalAvailabilityState | undefined,
  incomingAction: Action
): ProfessionalAvailabilityState => {
  if (state === undefined) {
    return ProfessionalAvailabilityUnloadedState as ProfessionalAvailabilityState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'PROFESSIONAL_AVAILABILITY_GETALL_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: false,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'PROFESSIONAL_AVAILABILITY_GETALL_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined,
        list: action.professionalAvailability.sort(Functions.DynamicSort('day'))
      };
    case 'PROFESSIONAL_AVAILABILITY_GETALL_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'PROFESSIONAL_AVAILABILITY_GETBYID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: false,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'PROFESSIONAL_AVAILABILITY_GETBYID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (availability: ProfessionalAvailability) =>
              availability.id !== action.professionalAvailability.id
          ),
          action.professionalAvailability
        ].sort(Functions.DynamicSort('day'))
      };
    case 'PROFESSIONAL_AVAILABILITY_GETBYID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'PROFESSIONAL_AVAILABILITY_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: false,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'PROFESSIONAL_AVAILABILITY_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [...state.list, action.professionalAvailability].sort(
          Functions.DynamicSort('day')
        )
      };
    case 'PROFESSIONAL_AVAILABILITY_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'PROFESSIONAL_AVAILABILITY_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: false,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'PROFESSIONAL_AVAILABILITY_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        error: undefined,
        list: state.list
          .map((item: ProfessionalAvailability) => {
            if (item.id === action.professionalAvailability.id) {
              return action.professionalAvailability;
            }
            return item;
          })
          .sort(Functions.DynamicSort('day'))
      };
    case 'PROFESSIONAL_AVAILABILITY_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };
    case 'PROFESSIONAL_AVAILABILITY_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: false,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'PROFESSIONAL_AVAILABILITY_DELETE_RECEIVE_ACTION':
      let newList: ProfessionalAvailability[] = [];

      try {
        newList = state.list = state.list.filter(
          (item: ProfessionalAvailability) =>
            item.id !== action.professionalAvailabilityId
        );
      } catch (e) {
        newList = state.list;
      }

      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: newList.sort(Functions.DynamicSort('day'))
      };
    case 'PROFESSIONAL_AVAILABILITY_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };

    default:
      return state;
  }
};
