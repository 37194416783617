import React from 'react';
import { ITag, Label, TagPicker } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { AvailableMedicalService } from '../../../../models/Entities/MedicalServices/AvailableMedicalService';

// own functions
import * as ownFunctions from './PractitionerMedicalServiceSelectorFunctions';
import { connect } from 'react-redux';
import { use } from 'i18next';

const PractitionerMedicalServiceSelectorComponent = (
  props: ownFunctions.Props
) => {

  useEffect(() => {
    props.GetAllMedicalServices(1,200, false);
    props.GetMedicalServiceByPractitionerId(props.PractitionerId);
  }, []);

  const onChangeIdTextFieldValue = (items?: ITag[] | undefined) => {
    let MedicalServicesComboSelected: AvailableMedicalService[] = [];
    if (items && items.length > 0) {
      items.forEach((item: ITag) => {
        const MedicalServiceId = Number(item.key);
        const medicalservice: AvailableMedicalService =
          props.MedicalServices.find(
            (service: AvailableMedicalService) => service.id === MedicalServiceId
          ) as AvailableMedicalService;

        MedicalServicesComboSelected = [
          ...MedicalServicesComboSelected,
          medicalservice
        ];
        setInternalMedicalServicesSelected(MedicalServicesComboSelected);
        setMedicalServicesOptionsSelected(items);
      });
    } else {
      setInternalMedicalServicesSelected([]);
      setMedicalServicesOptionsSelected([]);
    }
  };

  const [MedicalServiceOptions, setMedicalServiceOptions] = useState<ITag[]>();
  const [MedicalServicesOptionsSelected, setMedicalServicesOptionsSelected] =
    useState<ITag[]>();

  const [InternalMedicalServicesSelected, setInternalMedicalServicesSelected] =
    useState<AvailableMedicalService[]>([]);

  useEffect(() => {
    setMedicalServiceOptions(ownFunctions.GetMedicalServicesOptions(props.PractitionerMedicalServices, props.MedicalServices));
  }, []);

  useEffect(() => {
    props.onSelectedServices(InternalMedicalServicesSelected);
  }, [InternalMedicalServicesSelected]);

  useEffect(() => {
    if (MedicalServiceOptions) {
      const auxMedicalServicesSelected = MedicalServiceOptions.filter(
        (option: ITag) =>
          option.key === props.MedicalServicesSelected?.find((id: number) => id === option.key)
      );
      setMedicalServicesOptionsSelected(auxMedicalServicesSelected);
    }
  }, [MedicalServiceOptions]);

  useEffect(() => {
    if (props.MedicalServicesSelected) {
      const auxMedicalServicesSelected = props.MedicalServicesSelected.map(
        (id: number) => {
          const medicalService = props.MedicalServices.find(
            (service: AvailableMedicalService) => service.id === id
          ) as AvailableMedicalService;
          return {
            key: medicalService.id,
            name: medicalService.serviceNameReference
          } as ITag;
        }
      );
      setMedicalServicesOptionsSelected(auxMedicalServicesSelected);
    }
  }, []);

  const filterSuggestedMedicalServices = (
    filterText: string,
    tagList: ITag[] | undefined
  ): ITag[] => {
    return filterText
      ? props.MedicalServicesOptions?.filter(
        (tag) =>
          tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
          !ownFunctions.listContainsTagList(tag, tagList)
      ) || []
      : [];
  };

  return (
    <>
      <Label htmlFor="SnomedOption">Seleccione una práctica médica</Label>
      <TagPicker
        removeButtonAriaLabel="Eliminar"
        selectionAriaLabel="Seleccione un servicio"
        onResolveSuggestions={filterSuggestedMedicalServices}
        onEmptyResolveSuggestions={(selectedItems?: ITag[] | undefined) => props.MedicalServicesOptions || []}
        getTextFromItem={ownFunctions.getTextFromItem}
        pickerSuggestionsProps={ownFunctions.pickerSuggestionsProps}
        itemLimit={undefined}
        onChange={onChangeIdTextFieldValue}
        pickerCalloutProps={{ doNotLayer: false }}
        inputProps={{
          id: 'ServicesOption'
        }}
        selectedItems={MedicalServicesOptionsSelected}
      />
      {props.noteText && <small>{props.noteText}</small>}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(PractitionerMedicalServiceSelectorComponent as any);

