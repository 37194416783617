import * as React from 'react';
import {
    ChartHoverCard,
    DonutChart,
    IChartProps,
    IChartDataPoint,
    DataVizPalette,
    getColorFromToken,
    MultiStackedBarChart,
    StackedBarChart,
} from '@fluentui/react-charting';

// styles
import './SettlementMoneyChart.Styles.css';

// functions
import * as ComponentFunctions from './SettlementMoneyChart.Functions';
import { connect } from 'react-redux';
import { Label } from '@fluentui/react';

const SettlementMoneyChartComponent = (props: ComponentFunctions.Props) => {

    const points: IChartDataPoint[] = React.useMemo(() => [
        {
            legend: 'Por cobrar',
            data: props.TotalToSettle || 0,
            color: getColorFromToken(DataVizPalette.color6),
            xAxisCalloutData: 'margen',
            callOutAccessibilityData: { ariaLabel: 'Por cobrar: ' + (props.TotalToSettle || 0).toLocaleString('us-US', { style: 'currency', currency: props.CurrencyCode.toUpperCase() }) },
        }
    ], [props.TotalToSettle, props.CurrencyCode]);

    React.useEffect(() => {
        if((props.TotalOvehead || 0) > 0) {
            points.push({
                legend: 'Gastos generales',
                data: props.TotalOvehead || 0,
                color: getColorFromToken(DataVizPalette.color3),
                xAxisCalloutData: 'Gastos generales',
                callOutAccessibilityData: { ariaLabel: 'Gastos generales: ' + (props.TotalOvehead || 0).toLocaleString('us-US', { style: 'currency', currency: props.CurrencyCode.toUpperCase() }) },
            });
        }
    }, [points, props.CurrencyCode, props.TotalOvehead]);

    React.useEffect(() => {
        if((props.TotalWithholdings || 0) > 0) {
            points.push({
                legend: 'withholding',
                data: props.TotalWithholdings || 0,
                color: getColorFromToken(DataVizPalette.color7),
                xAxisCalloutData: 'Retención',
                callOutAccessibilityData: {
                    ariaLabel: 'Withholding: ' + (props.TotalWithholdings || 0).toLocaleString('es-AR', { style: 'currency', currency: props.CurrencyCode.toUpperCase() })
                },
            });
        }
    }, [points, props.CurrencyCode, props.TotalWithholdings]);

    React.useEffect(() => {
        if((props.TotalCost || 0) > 0) {
            points.push({
                legend: 'Costo',
                data: props.TotalCost || 0,
                color: getColorFromToken(DataVizPalette.color4),
                xAxisCalloutData: 'Costo',
                callOutAccessibilityData: { ariaLabel: 'Costo:' + (props.TotalCost || 0).toLocaleString('es-AR', { style: 'currency', currency: props.CurrencyCode.toUpperCase() }) },
            });
        }
    }, [points, props.CurrencyCode, props.TotalCost]);

     const data: IChartProps = {
        chartTitle: props.CurrencyCode.toUpperCase(),
        chartData: points,
    };

    return (
        <div className='CurrencyCart'>
            {props.ChartType === ComponentFunctions.CharType.Donut && 
            <DonutChart
                data={data}
                innerRadius={60}
                legendsOverflowText={'Total'}
                hideLegend={false}
                height={220}
                width={176}
                valueInsideDonut={((props.TotalToSettle || 0) - (props.TotalWithholdings || 0) || 0)}
                // eslint-disable-next-line react/jsx-no-bind
                onRenderCalloutPerDataPoint={(props: IChartDataPoint | undefined) =>
                    props ? (
                        <ChartHoverCard
                            XValue={props.xAxisCalloutData || ''}
                            Legend={'valor'}
                            YValue={`${props.yAxisCalloutData || props.data?.toLocaleString('es-AR', { style: 'currency', currency: 'USD' })}`}
                            color={getColorFromToken(DataVizPalette.highSuccess)}
                        />
                    ) : null
                }
            />}
            {props.ChartType === ComponentFunctions.CharType.Line &&
            <StackedBarChart 
                data={data}
                width={600}
                enabledLegendsWrapLines={true}
                href={'https://developer.microsoft.com/en-us/'}
                onRenderCalloutPerDataPoint={(props: IChartDataPoint | undefined) =>
                    props ? (
                        <ChartHoverCard
                            XValue={props.xAxisCalloutData || ''}
                            Legend={'valor'}
                            YValue={`${props.yAxisCalloutData || props.data?.toLocaleString('us-US', { style: 'currency', currency: 'USD' })}`}
                            color={getColorFromToken(DataVizPalette.highSuccess)}
                        />
                    ) : null
                }
            />}
        </div>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementMoneyChartComponent as any)
