import { PractitionerMedicalInsuranceAssociation } from './../models/Entities/PractitionerAssociations/MedicalInsurances/PractitionerMedicalInsuranceAssociation';

import { IResult } from '../models/HttpResult';
import { NewPractitionerMedicalInsuranceAssociation } from '../models/Entities/PractitionerAssociations/MedicalInsurances/NewPractitionerMedicalInsuranceAssociation';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'practitioners/';

export const GetAllPractitionerInsurance = async (
  PractitionerId: string
, token: string): Promise<PractitionerMedicalInsuranceAssociation[]> => {
  let RequestURL: string =
    BaseURL + 'aggregator/' + PractitionerId + '/medicalinsurances';

    try {
    const Response: PractitionerMedicalInsuranceAssociation[] = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'GET'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerMedicalInsuranceAssociation[]>
          >;
        }
      })
      .then((data: IResult<PractitionerMedicalInsuranceAssociation[]>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AssociateMedicalInsurance = async (
  MedicalInsuranceAssociation: NewPractitionerMedicalInsuranceAssociation
, token: string): Promise<PractitionerMedicalInsuranceAssociation> => {
  let RequestURL: string = BaseURL + 'aggregator/medicalinsurances';

  try {
    const Response: PractitionerMedicalInsuranceAssociation = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'POST',
        body: JSON.stringify(MedicalInsuranceAssociation)
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerMedicalInsuranceAssociation>
          >;
        }
      })
      .then((data: IResult<PractitionerMedicalInsuranceAssociation>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DisassociateMedicalInsuranceById = async (
  MedicalInsuranceAssociationId: number
, token: string): Promise<PractitionerMedicalInsuranceAssociation> => {
  let RequestURL: string =
    BaseURL + 'associations/medicalinsurance/' + MedicalInsuranceAssociationId;

  try {
    const Response: PractitionerMedicalInsuranceAssociation = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'DELETE'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerMedicalInsuranceAssociation>
          >;
        }
      })
      .then((data: IResult<PractitionerMedicalInsuranceAssociation>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DisassociateMedicalInsuranceByMedicalInsuranceId = async (
  PractitionerId: string,
  MedicalInsuranceId: number
, token: string): Promise<PractitionerMedicalInsuranceAssociation> => {

  let RequestURL: string =
    BaseURL +
    'associations/' +
    PractitionerId +
    '/medicalinsurances/' +
    MedicalInsuranceId;

  try {
    const Response: PractitionerMedicalInsuranceAssociation = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'DELETE'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerMedicalInsuranceAssociation>
          >;
        }
      })
      .then((data: IResult<PractitionerMedicalInsuranceAssociation>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
