import { IIconProps } from "@fluentui/react";
import { ErrorType } from "../../../../models/HttpError";
import { Protocol } from "../../../../models/Entities/Protocols/Protocol";
import { ProtocolPractitionerStudyRole } from "../../../../models/Entities/Protocols/ProtocolPractitionerStudyRol";
import { AppState } from "../../../../redux/reducers";
import { Practitioner } from "../../../../models/Entities/Practitioners/Practitioner";
import { Contract } from "../../../../models/Entities/Contracts/Contract";

// stores
import * as ProtocolPractitionersStore from '../../../../redux/middleware/ProtocolPractitionersMiddleware';
import * as ProtocolStore from '../../../../redux/middleware/MedicalProtocolMiddleware';
import * as ContractConceptStore from '../../../../redux/middleware/ContractConceptMiddleware';
import * as ContractCommisionAgentStore from '../../../../redux/middleware/ContractCommissionAgentMiddleware';
import { ContractCommissionAgent } from "../../../../models/Entities/Contracts/ContractCommissionAgent";
import { ProtocolPractitioner } from "../../../../models/Entities/Protocols/ProtocolPractitioner";

interface IProps {
  ProtocolId: number;
  ContractId: number;
  showParticipation: boolean;
 }

interface ConnectedProps {
    isPractitionerLoading: boolean;
    isProtocolLoading: boolean;
    loadingProtocolSuccessfully: boolean;
    loadingPractitionerSuccessfully: boolean;
    failOnPractitionerLoading: boolean;
    failOnProtocolLoading: boolean;
    errorPractitioner: ErrorType | undefined;
    errorProtocol: ErrorType | undefined;
    Protocol: Protocol;
    Contract: Contract;
    Practitioners: ProtocolPractitioner[];
    GetPractiontioner: (id: string) => Practitioner | undefined;
    GetCommisionAgent: (PractitionerId: string) => ContractCommissionAgent | undefined; 
}

interface MedicalServicesDispatchProps {
  GetAllProtocolPractitioners: typeof ProtocolPractitionersStore.actionCreators.GetAllProtocolPractitioners;
  GetAllProtocolPractitionersStudyTask: typeof ProtocolPractitionersStore.actionCreators.GetAllProtocolPractitionersStudyTask;
  GetMedicalProtocolById: typeof ProtocolStore.actionCreators.GetMedicalProtocolById;
  GetAllContractsConcept: typeof ContractConceptStore.actionCreators.GetAllContractsConcept;
  GetAllContractCommissionAgents: typeof ContractCommisionAgentStore.actionCreators.GetAllContractCommissionAgents;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isPractitionerLoading: state.ProtocolPractitioners?.isLoadingAll,
    isProtocolLoading: state.MedicalProtocols?.isLoadingAll,
    loadingProtocolSuccessfully: state.MedicalProtocols?.successLoadingAll,
    loadingPractitionerSuccessfully: state.ProtocolPractitioners?.successLoadingAll,
    failOnPractitionerLoading: state.ProtocolPractitioners?.failOnLoadingAll,
    failOnProtocolLoading: state.MedicalProtocols?.failOnLoadingAll,
    errorPractitioner: state.ProtocolPractitioners?.error,
    errorProtocol: state.MedicalProtocols?.error,
    Protocol: state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) as Protocol,
    Contract: state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId) as Contract,
    Practitioners: (state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId)?.practitioners?.filter((practitioner: ProtocolPractitioner) => 
       state.Practitioner?.list?.find((practitionerItem: Practitioner) => practitionerItem.userId === practitioner.practitionerUserId)
    ) as ProtocolPractitionerStudyRole[])?.sort((a, b) => a.studyRole.id - b.studyRole.id) as ProtocolPractitionerStudyRole[],
    GetPractiontioner: (id: string) => { 
      var practitioner = state.Practitioner?.list?.find((practitioner: Practitioner) => practitioner.userId === id);
      return practitioner;
    },
    GetCommisionAgent: (PractitionerId: string): ContractCommissionAgent | undefined  => state.Contract?.list?.find((contract: Contract) => contract.id === 
    ownProps.ContractId)?.commissionAgents?.find((commissionAgent: ContractCommissionAgent) => commissionAgent.commissionAgentId === PractitionerId)
});

export const mapDispatchToProps = {
  ...ProtocolPractitionersStore.actionCreators,
  ...ProtocolStore.actionCreators,
  ...ContractConceptStore.actionCreators,
  ...ContractCommisionAgentStore.actionCreators
};

//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };



