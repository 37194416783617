import { AppState } from "../../../../redux/reducers";

// stores
import * as ProtocolPractitionersStore from '../../../../redux/middleware/ProtocolPractitionersMiddleware';
import * as ProtocolStore from '../../../../redux/middleware/MedicalProtocolMiddleware';
import * as ContractStore from '../../../../redux/middleware/ContractMiddleware';
import * as ContractConceptStore from '../../../../redux/middleware/ContractConceptMiddleware';
import * as ContractCommisionAgentStore from '../../../../redux/middleware/ContractCommissionAgentMiddleware';

//models
import { ContractCommissionAgent } from "../../../../models/Entities/Contracts/ContractCommissionAgent";
import { ErrorType } from "../../../../models/HttpError";
import { Protocol } from "../../../../models/Entities/Protocols/Protocol";
import { ProtocolPractitionerStudyRole } from "../../../../models/Entities/Protocols/ProtocolPractitionerStudyRol";
import { Practitioner } from "../../../../models/Entities/Practitioners/Practitioner";
import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { IChoiceGroupOption } from "@fluentui/react";
import { TestImages } from "@fluentui/example-data";

interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  Practitioner: ProtocolPractitionerStudyRole;
  ProtocolId: number;
  ContractId: number;
 }

interface ConnectedProps {
    isLoading: boolean;
    isLoaded: boolean | undefined;
    failOnLoading: boolean;

    isSaving: boolean;
    isSavedSuccessfully: boolean;

    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;

    failOnSaving: boolean;
    failOnUpdating: boolean;

    error: ErrorType | undefined;

    Protocol: Protocol;
    Contract: Contract;

    // agents
    isLoadingAgents: boolean;
    AgentsLoaded: boolean | undefined;

    Agent: ContractCommissionAgent | undefined;
    // Practitioner: Practitioner | undefined;
    GetPractiontioner: (id: string) => Practitioner | undefined; 
}

interface MedicalServicesDispatchProps {
  GetAllContracts: typeof ContractStore.actionCreators.GetAllContracts;  
  GetAllProtocolPractitioners: typeof ProtocolPractitionersStore.actionCreators.GetAllProtocolPractitioners;
  GetAllProtocolPractitionersStudyTask: typeof ProtocolPractitionersStore.actionCreators.GetAllProtocolPractitionersStudyTask;
  GetMedicalProtocolById: typeof ProtocolStore.actionCreators.GetMedicalProtocolById;
  GetAllContractsConcept: typeof ContractConceptStore.actionCreators.GetAllContractsConcept;
  GetContractCommissionAgentById: typeof ContractCommisionAgentStore.actionCreators.GetContractCommissionAgentById;
  CreateContractCommissionAgent: typeof ContractCommisionAgentStore.actionCreators.CreateContractCommissionAgent;
  UpdateContractCommissionAgent: typeof ContractCommisionAgentStore.actionCreators.UpdateContractCommissionAgent;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.ContractCommissionAgent?.isLoadingAll,
    isLoaded: state.ContractCommissionAgent?.successLoadingAll,
    
    isSaving: state.ContractCommissionAgent?.isAddingNewOne,
    isSavedSuccessfully: state.ContractCommissionAgent?.successAddingNewOne,

    isUpdating: state.ContractCommissionAgent?.isUpdatingOne,
    isUpdatedSuccessfully: state.ContractCommissionAgent?.successUpdatingOne,

    failOnLoading: state.ContractCommissionAgent?.failOnLoadingAll,
    error: state.ContractCommissionAgent?.error,

    Protocol: state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ownProps.ProtocolId) as Protocol,
    Contract: state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId) as Contract,
    // agents

    isLoadingAgents: state.ContractCommissionAgent?.isLoadingAll,
    AgentsLoaded: state.ContractCommissionAgent?.successLoadingAll,

    Agent: state.Contract?.list?.find((contract: Contract) => contract.id === ownProps.ContractId)?.commissionAgents?.find((agent: ContractCommissionAgent) => agent.commissionAgentId === ownProps.Practitioner?.practitionerUserId) as ContractCommissionAgent || undefined,

    GetPractiontioner: (id: string) => { 
       var practitioner = state.Practitioner?.list?.find((practitioner: Practitioner) => practitioner.userId === id);
       return practitioner;
    }
});

export const mapDispatchToProps = {
    ...ProtocolPractitionersStore.actionCreators,
    ...ProtocolStore.actionCreators,
    ...ContractStore.actionCreators,
    ...ContractConceptStore.actionCreators,
    ...ContractCommisionAgentStore.actionCreators
};