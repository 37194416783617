import React from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import {
  LoginActions,
  QueryParameterNames,
  ApplicationPaths
} from './ApiAuthorizationConstants';
import { Spinner } from '../Utils/Loaders';
import { useState } from 'react';
export interface IProps {
  action: string;
}

export const Login = (props: IProps): JSX.Element => {
  const [message, setMessage] = useState<string | null>();

  switch (props.action) {
    case LoginActions.Login:
      login(getReturnUrl());
      return (
        <div>
          <Spinner />
        </div>
      );
    // break;
    case LoginActions.LoginCallback:
      processLoginCallback();
      return (
        <div>
          <Spinner />
        </div>
      );
    // break;
    case LoginActions.LoginFailed:
      const params = new URLSearchParams(window.location.search);
      const error = params.get(QueryParameterNames.Message);
      setMessage(error);
      break;
    case LoginActions.Profile:
      redirectToProfile();
      break;
    case LoginActions.Register:
      redirectToRegister();
      break;
    default:
      throw new Error(`Invalid action '${props.action}'`);
  }

  const ReturnAction = () => {
    switch (props.action) {
      case LoginActions.Login:
        return (
          <div>
            <Spinner />
          </div>
        );
      case LoginActions.LoginCallback:
        return (
          <div>
            <Spinner />
          </div>
        );
      case LoginActions.Profile:
      case LoginActions.Register:
        return <div></div>;
      default:
        throw new Error(`Invalid action '${props.action}'`);
    }
  };

  return <>{ReturnAction}</>; //message ? <div>{message}</div> : <>{ReturnAction}</>;
};

const login = async (returnUrl: string) => {
  const state = { returnUrl };
  const result = await authService.signIn(state);
  switch (result.status) {
    case AuthenticationResultStatus.Redirect:
      break;
    case AuthenticationResultStatus.Success:
      await navigateToReturnUrl('/callback?retrunURL=' + returnUrl);
      break;
    case AuthenticationResultStatus.Fail:
      break;
    default:
      throw new Error(`Invalid status result ${result.status}.`);
  }
};

const processLoginCallback = async () => {
  const url = window.location.href;
  const result: any = await authService.completeSignIn(url);
  switch (result.status) {
    case AuthenticationResultStatus.Redirect:
      throw new Error('Should not redirect.');
    case AuthenticationResultStatus.Success:
      await navigateToReturnUrl(getReturnUrl(result.state.returnUrl));
      break;
    case AuthenticationResultStatus.Fail:
      break;
    case AuthenticationResultStatus.RedirectToProfile:
      await navigateToReturnUrl(
        `/admin/users/${result.user.sub}/completeProfile`
      );
      break;
    default:
      throw new Error(
        `Invalid authentication result status '${result.status}'.`
      );
  }
};

const getReturnUrl = (returnUrl?: string) => {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get(QueryParameterNames.ReturnUrl);
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    throw new Error(
      'Invalid return url. The return url needs to have the same origin as the current page.'
    );
  }
  return returnUrl || fromQuery || `${window.location.origin}/`;
};

const redirectToRegister = () => {
  redirectToApiAuthorizationPath(
    `${ApplicationPaths.IdentityRegisterPath}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURI(ApplicationPaths.Login)}`
  );
};

const redirectToProfile = () => {
  redirectToApiAuthorizationPath(ApplicationPaths.Profile);
};

const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
  const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
  window.location.replace(redirectUrl);
};

const navigateToReturnUrl = (returnUrl: string) => {
  window.location.replace(returnUrl);
};
