import { Person } from '../../models/Entities/Person/Person';
import { CommonState } from '../common/state/commonState';

export interface PersonState extends CommonState<Person> {
  // update all
  isUpdatingAll: boolean;
  isUpdatingAllSuccess: undefined | boolean;
  FailUpdatingAll: boolean;

  // Changing status
  isChangingStatus: boolean;
  successChangingStatus: boolean | undefined;
  failOnChangingStatus: boolean;
}

export const PersonUnloadState: any = {
  // loading all
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  FailGettingAll: false,

  // update all
  isUpdatingAll: false,
  isUpdatingAllSuccess: undefined,
  FailUpdatingAll: false,

  // loading one
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  FailGettingOne: false,

  // saving
  isSaving: false,
  isSavingSuccess: undefined,
  FailSaving: false,

  // adding
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,

  isFiltered: false,
  filterCriteria: undefined,

  // changing status
  isChangingStatus: false,
  isChangingStatusSuccess: undefined,
  FailChangingStatus: false,

  list: [],
  Selected: undefined,
  Error: undefined
};