// Functions
import * as Functions from '../functions/common';
import { User } from '../models/Entities/Authentication/User';
import { Languaje } from '../models/Entities/Settings/Languajes';
import { IPagedResult } from '../models/HttpPagedResult';

const BaseURL = process.env.REACT_APP_BILLER_API_ENDPOINT_PRACTITIONERS;

const DefaultLanguaje: Languaje = {
    code: 'es',
    description: 'Español'
}

var DefaultLanguajePromise = new Promise<Languaje>((resolve, reject) => {
    resolve(DefaultLanguaje);
});

export const GetAllUsers = async (
  pageNumber: number,
  pageSize: number,
  searchText: string | undefined, 
  token: string): Promise<User[]> => {
  let params: URLSearchParams = Functions.GetQueryStringParams(
    pageNumber,
    pageSize,
    searchText
  );

  let RequestURL: string = BaseURL || '';

  if (params)
    RequestURL +=
      '?' + params.toString() + '&onlyDoctor=false&includeDisabled=true'; //--> Recupero solo los usuarios sin importar si esta habilitado o no

  try {
    const Response: any[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IPagedResult<User[]>>;
        }
      })
      .then((data: IPagedResult<User[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};

export const GetUserLanguaje = async (): Promise<Languaje> => {
  //const RequestURL: string = BaseURL + '???';
  //const token = await AuthorizeService.getAccessToken();

  try {
    // const Response: Languaje[] = await fetch(RequestURL, {
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //     Authorization: 'Bearer ' + token
    //   }
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw response;
    //     } else {
    //       return response.json() as Promise<IResult<Languaje[]>>;
    //     }
    //   })
    //   .then((data: IResult<Languaje[]>) => {
    //     return data.data;
    //   })
    //   .catch((error: any) =>
    //     error.text().then((body: any) => {
    //       throw Functions.HttpErrorHandler(body, error);
    //     })
    //   );

    // return Response;

    
    return DefaultLanguajePromise.then((data: Languaje) => {
        return data;
    });


  } catch (error: any) {
    throw error;
  }
};