import { CompoundButton, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner, Text } from "@fluentui/react-components";
import { ContractAddendum } from "../../../../models/Entities/Contracts/ContractAddendum";
import moment from "moment";

import { Document24Regular } from "@fluentui/react-icons";

// styles
import './Addendums.Styles.css';
import { use } from "i18next";
import { useEffect, useState } from "react";



export interface AddendumItemProps {
    Addendum: ContractAddendum;
    SelectedAddendum: ContractAddendum | undefined;
    OnSelect: (addendum: ContractAddendum) => void;
}

export const AddendumItemComponent = (props: AddendumItemProps) => {

    const AddendumExpiricy = (props.Addendum?.contractAddendumExpiryStartDate || props.Addendum?.contractAddendumExpiryEndDate) ? (
        <Text>{props.Addendum?.contractAddendumExpiryStartDate ? moment(props.Addendum?.contractAddendumExpiryStartDate).format("DD/MM/YYYY") : 'No informado'} - {props.Addendum?.contractAddendumExpiryEndDate ? moment(props.Addendum?.contractAddendumExpiryEndDate).format("DD/MM/YYYY") : 'No informado'}</Text>
    ) : (
        <Text>Validity not defined</Text>
    );

    return (
        <>
            <CompoundButton
                appearance={props.Addendum.id === props.SelectedAddendum?.id ? "primary" : "secondary"}
                icon={props.Addendum.isGettingContractStages ? <Spinner appearance="inverted" size="small" /> : <Document24Regular />}
                secondaryContent={AddendumExpiricy}
                onClick={() => props.OnSelect(props.Addendum)}
            >
                {props.Addendum.contractAddendumName}
            </CompoundButton>
        </>
    )
}