import { AppState } from "../../../redux/reducers";

// store
import * as PractitionerProtocolVisitsStore from '../../../redux/middleware/PractitionerProtocolVisitsMiddleware';
import * as ProtocolStore from '../../../redux/middleware/MedicalProtocolMiddleware';
import * as PractitionerStore from '../../../redux/middleware/PractitionerMiddleware';

// models
import { ErrorType } from "../../../models/HttpError";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";
import { Practitioner, PractitionerVisit } from "../../../models/Entities/Practitioners/Practitioner";


interface IProps {
    PractitionerId: string;
    protocolsIds: number[];
    DateFrom: Date;
    DateTo: Date;
}
  
interface ConnectedProps {
//status
IsLoading: boolean;
LoadedSuccessfully: boolean;
FailOnLoad: boolean;
ErrorOnLoad: ErrorType;

visitis: PractitionerVisit[];
Protocols: Protocol[];
Practitioner: Practitioner;
}

interface DispatchProps {
    GetPractitionerProtocolVisits: typeof PractitionerProtocolVisitsStore.actionCreators.GetPractitionerProtocolVisits;
    GetProtocolsByPractitionerId: typeof ProtocolStore.actionCreators.GetProtocolsByPractitionerId;
    GetPractitionerById: typeof PractitionerStore.actionCreators.GetPractitionerById;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {
    
    const PractitionerProtocols : Protocol[] = ownProps.PractitionerId && state.Practitioner?.list.find((practitioner: Practitioner) => practitioner.userId === ownProps.PractitionerId)?.Protocols || [] as Protocol[];
    
    return{
        // status
        IsLoading: state.PractitionerSettings?.isLoading,
        LoadedSuccessfully: state.PractitionerSettings?.loadedSuccessfully,
        FailOnLoad: state.PractitionerSettings?.failOnLoad,
        ErrorOnLoad: state.PractitionerSettings?.error,

        Protocols: PractitionerProtocols,
        visitis: state.Practitioner?.list.find((practitioner: Practitioner) => practitioner.userId === ownProps.PractitionerId)?.ProtocolVisits || [],
        Practitioner: state.Practitioner?.list.find((practitioner: Practitioner) => practitioner.userId === ownProps.PractitionerId)
    }
};

export const mapDispatchToProps = {
...PractitionerProtocolVisitsStore.actionCreators,
...ProtocolStore.actionCreators,
...PractitionerStore.actionCreators
};

export const GetStatusDescription = (statusCode: number): string => {
    switch(statusCode) {
        case 0: return 'Iniciada';
        case 1: return 'Completada';
        case 2: return 'Salteada';
        case 3: return 'Falla de selección';
        case 4: return 'Modificada';
        case 5: return 'Auto completada';
        default: return 'No definido';
    }
}