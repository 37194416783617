import { useTranslation } from "react-i18next";
import { useState } from "react";
import { connect } from "react-redux";

// functions 
import * as ownFunctions from './CashFlow.General.Functions';

import type {
  TabValue,
} from "@fluentui/react-components";

// components
import { PageHeader } from "../../commons/component/PageHeader/PageHeader"

import CashflowGeneralComponent from "../../components/CashFlow/General/Cashflow.General.Component";



const CashFlowGeneralPage = (props: ownFunctions.Props) => {

  const [t] = useTranslation();

  const [selectedValue, setSelectedValue] = useState<TabValue>('payments-done');

  return (
    <>
      <PageHeader title={t('Cashflow.title')} />
      <CashflowGeneralComponent EntityIdentifier={props.OwnerId} FixedAssociatedEntityIdentifier={undefined}  />

    </>
  )
}

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(
  CashFlowGeneralPage as any
)