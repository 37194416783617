//models
import { Debt } from "../../models/Entities/CashFlow/Debt/Debt";
import { NewDebt } from "../../models/Entities/CashFlow/Debt/NewDebt";
import { ErrorType } from "../../models/HttpError";


// get all debts as creditor

export const CASHFLOW_DEBT_GETALLAS_CREDITOR_REQUEST = 'CASHFLOW-DEBT_GETALLAS_CREDITOR_REQUEST';
export const CASHFLOW_DEBT_GETALLAS_CREDITOR_SUCCESS = 'CASHFLOW-DEBT_GETALLAS_CREDITOR_SUCCESS';
export const CASHFLOW_DEBT_GETALLAS_CREDITOR_FAILURE = 'CASHFLOW-DEBT_GETALLAS_CREDITOR_FAILURE';

export interface CashFlowDebtGetAllAsCreditorRequestAction {
    type: typeof CASHFLOW_DEBT_GETALLAS_CREDITOR_REQUEST;
}

export interface CashFlowDebtGetAllAsCreditorSuccessAction {
    type: typeof CASHFLOW_DEBT_GETALLAS_CREDITOR_SUCCESS;
    payload: Debt[];
}

export interface CashFlowDebtGetAllAsCreditorFailureAction {
    type: typeof CASHFLOW_DEBT_GETALLAS_CREDITOR_FAILURE;
    error: ErrorType;
}

// get all debts as debtor

export const CASHFLOW_DEBT_GETALLAS_DEBTOR_REQUEST = 'CASHFLOW-DEBT_GETALLAS_DEBTOR_REQUEST';
export const CASHFLOW_DEBT_GETALLAS_DEBTOR_SUCCESS = 'CASHFLOW-DEBT_GETALLAS_DEBTOR_SUCCESS';
export const CASHFLOW_DEBT_GETALLAS_DEBTOR_FAILURE = 'CASHFLOW-DEBT_GETALLAS_DEBTOR_FAILURE';

export interface CashFlowDebtGetAllAsDebtorRequestAction {
    type: typeof CASHFLOW_DEBT_GETALLAS_DEBTOR_REQUEST;
}

export interface CashFlowDebtGetAllAsDebtorSuccessAction {
    type: typeof CASHFLOW_DEBT_GETALLAS_DEBTOR_SUCCESS;
    payload: Debt[];
}

export interface CashFlowDebtGetAllAsDebtorFailureAction {
    type: typeof CASHFLOW_DEBT_GETALLAS_DEBTOR_FAILURE;
    error: ErrorType;
}

// update all debts as creditor

export const CASHFLOW_DEBT_UPDATEALLAS_CREDITOR_REQUEST = 'CASHFLOW-DEBT_UPDATEALLAS_CREDITOR_REQUEST';
export const CASHFLOW_DEBT_UPDATEALLAS_CREDITOR_SUCCESS = 'CASHFLOW-DEBT_UPDATEALLAS_CREDITOR_SUCCESS';
export const CASHFLOW_DEBT_UPDATEALLAS_CREDITOR_FAILURE = 'CASHFLOW-DEBT_UPDATEALLAS_CREDITOR_FAILURE';

export interface CashFlowDebtUpdateAllAsCreditorRequestAction {
    type: typeof CASHFLOW_DEBT_UPDATEALLAS_CREDITOR_REQUEST;
}

export interface CashFlowDebtUpdateAllAsCreditorSuccessAction {
    type: typeof CASHFLOW_DEBT_UPDATEALLAS_CREDITOR_SUCCESS;
    payload: Debt[];
}

export interface CashFlowDebtUpdateAllAsCreditorFailureAction {
    type: typeof CASHFLOW_DEBT_UPDATEALLAS_CREDITOR_FAILURE;
    error: ErrorType;
}

// update all debts as debtor

export const CASHFLOW_DEBT_UPDATEALLAS_DEBTOR_REQUEST = 'CASHFLOW-DEBT_UPDATEALLAS_DEBTOR_REQUEST';
export const CASHFLOW_DEBT_UPDATEALLAS_DEBTOR_SUCCESS = 'CASHFLOW-DEBT_UPDATEALLAS_DEBTOR_SUCCESS';
export const CASHFLOW_DEBT_UPDATEALLAS_DEBTOR_FAILURE = 'CASHFLOW-DEBT_UPDATEALLAS_DEBTOR_FAILURE';

export interface CashFlowDebtUpdateAllAsDebtorRequestAction {
    type: typeof CASHFLOW_DEBT_UPDATEALLAS_DEBTOR_REQUEST;
}

export interface CashFlowDebtUpdateAllAsDebtorSuccessAction {
    type: typeof CASHFLOW_DEBT_UPDATEALLAS_DEBTOR_SUCCESS;
    payload: Debt[];
}

export interface CashFlowDebtUpdateAllAsDebtorFailureAction {
    type: typeof CASHFLOW_DEBT_UPDATEALLAS_DEBTOR_FAILURE;
    error: ErrorType;
}

// get debts by id

export const CASHFLOW_DEBT_GETBYID_REQUEST = 'CASHFLOW-DEBT_GETBYID_REQUEST';
export const CASHFLOW_DEBT_GETBYID_SUCCESS = 'CASHFLOW-DEBT_GETBYID_SUCCESS';
export const CASHFLOW_DEBT_GETBYID_FAILURE = 'CASHFLOW-DEBT_GETBYID_FAILURE';

export interface CashFlowDebtGetbyIdRequestAction {
    type: typeof CASHFLOW_DEBT_GETBYID_REQUEST;
    payload: number;
}

export interface CashFlowDebtGetByIdSuccessAction {
    type: typeof CASHFLOW_DEBT_GETBYID_SUCCESS;
    tenantId: string;
    payload: Debt;
}

export interface CashFlowDebtGetByIdFailureAction {
    type: typeof CASHFLOW_DEBT_GETBYID_FAILURE;
    error: ErrorType;
}

// create debt

export const CASHFLOW_DEBT_CREATE_REQUEST = 'CASHFLOW-DEBT_CREATE_REQUEST';
export const CASHFLOW_DEBT_CREATE_SUCCESS = 'CASHFLOW-DEBT_CREATE_SUCCESS';
export const CASHFLOW_DEBT_CREATE_FAILURE = 'CASHFLOW-DEBT_CREATE_FAILURE';

export interface CashFlowDebtCreateRequestAction {
    type: typeof CASHFLOW_DEBT_CREATE_REQUEST;
    payload: NewDebt;
}

export interface CashFlowDebtCreateSuccessAction {
    type: typeof CASHFLOW_DEBT_CREATE_SUCCESS;
    tenantId: string;
    payload: Debt;
}

export interface CashFlowDebtCreateFailureAction {
    type: typeof CASHFLOW_DEBT_CREATE_FAILURE;
    error: ErrorType;
}

// update debt

export const CASHFLOW_DEBT_UPDATE_REQUEST = 'CASHFLOW-DEBT_UPDATE_REQUEST';
export const CASHFLOW_DEBT_UPDATE_SUCCESS = 'CASHFLOW-DEBT_UPDATE_SUCCESS';
export const CASHFLOW_DEBT_UPDATE_FAILURE = 'CASHFLOW-DEBT_UPDATE_FAILURE';

export interface CashFlowDebtUpdateRequestAction {
    type: typeof CASHFLOW_DEBT_UPDATE_REQUEST;
    tenantId: string;
    payload: Debt;
}

export interface CashFlowDebtUpdateSuccessAction {
    type: typeof CASHFLOW_DEBT_UPDATE_SUCCESS;
    tenantId: string;
    payload: Debt;
}

export interface CashFlowDebtUpdateFailureAction {
    type: typeof CASHFLOW_DEBT_UPDATE_FAILURE;
    debtId: number;
    error: ErrorType;
}

// delete debt

export const CASHFLOW_DEBT_DELETE_REQUEST = 'CASHFLOW-DEBT_DELETE_REQUEST';
export const CASHFLOW_DEBT_DELETE_SUCCESS = 'CASHFLOW-DEBT_DELETE_SUCCESS';
export const CASHFLOW_DEBT_DELETE_FAILURE = 'CASHFLOW-DEBT_DELETE_FAILURE';

export interface CashFlowDebtDeleteRequestAction {
    type: typeof CASHFLOW_DEBT_DELETE_REQUEST;
    payload: number;
}

export interface CashFlowDebtDeleteSuccessAction {
    type: typeof CASHFLOW_DEBT_DELETE_SUCCESS;
    payload: number;
}

export interface CashFlowDebtDeleteFailureAction {
    type: typeof CASHFLOW_DEBT_DELETE_FAILURE;
    debtId: number;
    error: ErrorType;
}

