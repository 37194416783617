import {
  DisassociateMedicalInsuranceById,
  DisassociateMedicalInsuranceByMedicalInsuranceId
} from './../../services/PractitionerMedicalInsurance.Services';
import { AppThunkAction } from '../reducers';
import { NewPractitionerMedicalInsuranceAssociation } from '../../models/Entities/PractitionerAssociations/MedicalInsurances/NewPractitionerMedicalInsuranceAssociation';
import { PractitionerMedicalInsuranceAssociation } from '../../models/Entities/PractitionerAssociations/MedicalInsurances/PractitionerMedicalInsuranceAssociation';

// actions
import * as Actions from '../actions';

// services
import * as Services from '../../services/PractitionerMedicalInsurance.Services';
import * as PractitionerServices from '../../services/Practitioners.Service';
import { GiToken } from 'react-icons/gi';

type KnownAction =
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_RequestAll_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_ReceiveAll_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_FailAll_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_RequestById_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_ReceiveById_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_FailById_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Add_Request_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Add_Receive_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Add_Fail_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Delete_Request_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_DeleteById_Receive_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_DeleteByMedicalInsuranceId_Receive_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Delete_Fail_Action
  | Actions.Practitioner.Practitioners_GetAll_Receive_Action;

export const actionCreators = {
  GetMedicalInsuranceByPractitionerId:
    (practitionerId: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_MEDICAL_INSURANCES_FAIL_ALL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      const PractitionerLoaded: boolean | undefined = state?.Practitioner?.successLoadingAll;
      
      if (!PractitionerLoaded) {
        PractitionerServices.GetPractitioners(1, 1000, undefined, token).then(
          (practitioners) => {
            dispatch({
              type: 'PRACTITIONERS_GETALL_RECEIVE_ACTION',
              practitioners: practitioners
            });

            dispatch({
              type: 'PRACTITIONER_MEDICAL_INSURANCES_REQUEST_ALL_ACTION',
              practitionerId: practitionerId
            });
          }
        );
      } else {
        dispatch({
          type: 'PRACTITIONER_MEDICAL_INSURANCES_REQUEST_ALL_ACTION',
          practitionerId: practitionerId
        });
      }

      Services.GetAllPractitionerInsurance(practitionerId, token)
        .then((practitionerMedicalInsurances) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_INSURANCES_RECEIVE_ALL_ACTION',
            practitionerId: practitionerId,
            practitionerMedicalInsurances: practitionerMedicalInsurances
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_INSURANCES_FAIL_ALL_ACTION',
            error
          });
        });
    },
  AssociateMedicalInsuranceToPractitioner:
    (
      practitionerMedicalInsurance: NewPractitionerMedicalInsuranceAssociation
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_MEDICAL_INSURANCES_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONER_MEDICAL_INSURANCES_ADD_REQUEST_ACTION',
        practitionerMedicalInsurance
      });

      Services.AssociateMedicalInsurance(practitionerMedicalInsurance, token)
        .then(
          (
            practitionerMedicalInsurance: PractitionerMedicalInsuranceAssociation
          ) => {
            dispatch({
              type: 'PRACTITIONER_MEDICAL_INSURANCES_ADD_RECEIVE_ACTION',
              practitionerMedicalInsurance
            });
          }
        )
        .catch((error) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_INSURANCES_ADD_FAIL_ACTION',
            error
          });
        });
    },
  DisassociateMedicalInsuranceById:
    (
      practitionerMedicalInsuranceId: number,
      practitionerId: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }


      dispatch({
        type: 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_REQUEST_ACTION',
        practitionerMedicalInsuranceId: practitionerMedicalInsuranceId
      });

      DisassociateMedicalInsuranceById(practitionerMedicalInsuranceId, token)
        .then(() => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_BY_ID_RECEIVE_ACTION',
            practitionerId: practitionerId,
            practitionerMedicalInsuranceId: practitionerMedicalInsuranceId
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_FAIL_ACTION',
            error
          });
        });
    },
  DisassociateMedicalInsuranceByMedicalInsuranceId:
    (
      MedicalInsuranceId: number,
      PractitionerId: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_REQUEST_ACTION',
        practitionerMedicalInsuranceId: MedicalInsuranceId
      });

      DisassociateMedicalInsuranceByMedicalInsuranceId(
        PractitionerId,
        MedicalInsuranceId,
        token
      )
        .then(() => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_BY_MEDICALINSURANCE_ID_RECEIVE_ACTION',
            practitionerId: PractitionerId,
            MedicalInsuranceId: MedicalInsuranceId
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_FAIL_ACTION',
            error
          });
        });
    }
};
