import { Reducer, Action } from 'redux';
import { SponsorState, SponsorUnloadState } from '../states/SponsorState';

// functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';
import { Sponsor } from '../../models/Entities/Sponsor/Sponsor';
import { SponsorContract } from '../../models/Entities/Sponsor/SponsorContracts';

type KnownAction =
  | Actions.Sponsor.Sponsors_GetAll_Request_Action
  | Actions.Sponsor.Sponsors_GetAll_Receive_Action
  | Actions.Sponsor.Sponsors_GetAll_Fail_Action
  | Actions.Sponsor.Sponsor_GetById_Request_Action
  | Actions.Sponsor.Sponsor_GetById_Receive_Action
  | Actions.Sponsor.Sponsor_GetById_Fail_Action
  | Actions.Sponsor.Sponsor_Add_Request_Action
  | Actions.Sponsor.Sponsor_Add_Receive_Action
  | Actions.Sponsor.Sponsor_Add_Fail_Action
  | Actions.Sponsor.Sponsor_Update_Request_Action
  | Actions.Sponsor.Sponsor_Update_Receive_Action
  | Actions.Sponsor.Sponsor_Update_Fail_Action
  | Actions.Sponsor.Sponsor_Delete_Request_Action
  | Actions.Sponsor.Sponsor_Delete_Receive_Action
  | Actions.Sponsor.Sponsor_Delete_Fail_Action
  | Actions.Sponsor.Sponsor_sync_Request_Action
  | Actions.Sponsor.Sponsor_sync_Receive_Action
  | Actions.Sponsor.Sponsor_sync_Fail_Action
  | Actions.Sponsor.Sponsor_Contract_Association_Request_Action
  | Actions.Sponsor.Sponsor_Contract_Association_Receive_Action
  | Actions.Sponsor.Sponsor_Contract_Association_Fail_Action
  | Actions.Sponsor.Sponsor_Contract_Disassociation_Request_Action
  | Actions.Sponsor.Sponsor_Contract_Disassociation_Receive_Action
  | Actions.Sponsor.Sponsor_Contract_Disassociation_Fail_Action
  | Actions.Sponsor.Sponsor_GetAll_ContractAssociations_Request_Action
  | Actions.Sponsor.Sponsor_GetAll_ContractAssociations_Receive_Action
  | Actions.Sponsor.Sponsor_GetAll_ContractAssociations_Fail_Action;


export const SponsorReducer: Reducer<SponsorState> = (
  state: SponsorState | undefined,
  incomingAction: Action
): SponsorState => {
  if (state === undefined) {
    return SponsorUnloadState as SponsorState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'SPONSORS_GETALL_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'SPONSORS_GETALL_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        list: action.sponsors,
        error: undefined
      };
    case 'SPONSORS_GETALL_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'SPONSOR_GETBYID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'SPONSOR_GETBYID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        list: [
          ...state.list.filter(
            (provider: Sponsor) => provider.id !== action.Sponsor.id
          ),
          action.Sponsor
        ].sort(Functions.DynamicSort('fullName')),
        error: undefined
      };
    case 'SPONSOR_GETBYID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'SPONSOR_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'SPONSOR_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        list: [...state.list, action.Sponsor].sort(
          Functions.DynamicSort('fullName')
        ),
        error: undefined
      };
    case 'SPONSOR_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'SPONSOR_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'SPONSOR_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        list: [
          ...state.list.filter(
            (provider: Sponsor) => provider.id !== action.Sponsor.id
          ),
          action.Sponsor
        ].sort(Functions.DynamicSort('fullName')),
        error: undefined
      };
    case 'SPONSOR_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };

    case 'SPONSOR_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'SPONSOR_DELETE_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        list: [
          ...state.list.filter(
            (provider: Sponsor) => provider.id !== action.SponsorId
          )
        ].sort(Functions.DynamicSort('fullName')),
        error: undefined
      };
    case 'SPONSOR_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
    case 'SPONSOR_SYNC_REQUEST_ACTION':
      return {
        ...state,
        isSyncing: true,
        isSyncingSuccess: undefined,
        FailSyncing: false,
        error: undefined
      };
    case 'SPONSOR_SYNC_RECEIVE_ACTION':
      return {
        ...state,
        isSyncing: false,
        isSyncingSuccess: true,
        FailSyncing: false,
        list: [...action.Sponsors],
        error: undefined
      };
    case 'SPONSOR_SYNC_FAIL_ACTION':
      return {
        ...state,
        isSyncing: false,
        isSyncingSuccess: false,
        FailSyncing: true,
        error: action.error
      };
    case 'SPONSOR_CONTRACT_ASSOCIATION_REQUEST_ACTION':
      return {
        ...state,
        isAssociatingContract: true,
        isAssociatingContractSuccess: undefined,
        FailAssociatingContract: false,
        error: undefined
      };
    case 'SPONSOR_CONTRACT_ASSOCIATION_RECEIVE_ACTION':
      var AuxSponsorAssociate = state.list.find(
        (sponsor: Sponsor) => sponsor.id === action.SponsorContract.sponsorId
      );
      if (AuxSponsorAssociate) {
        AuxSponsorAssociate.contractsAssociated = [...AuxSponsorAssociate.contractsAssociated || [], action.SponsorContract]
      }
      return {
        ...state,
        isAssociatingContract: false,
        isAssociatingContractSuccess: true,
        FailAssociatingContract: false,
        list: [...state.list.filter(
            (sponsor: Sponsor) => sponsor.id !== action.SponsorContract.sponsorId
          ) || [],
          AuxSponsorAssociate
        ].sort(Functions.DynamicSort('fullName')) as Sponsor[],
        error: undefined
      };
    case 'SPONSOR_CONTRACT_ASSOCIATION_FAIL_ACTION':
      return {
        ...state,
        isAssociatingContract: false,
        isAssociatingContractSuccess: false,
        FailAssociatingContract: true,
        error: action.error
      };
    case 'SPONSOR_CONTRACT_DISASSOCIATION_REQUEST_ACTION':
      return {
        ...state,
        isDisassociatingContract: true,
        isDisassociatingContractSuccess: undefined,
        FailDisassociatingContract: false,
        error: undefined
      };
    case 'SPONSOR_CONTRACT_DISASSOCIATION_RECEIVE_ACTION':
      var AuxSponsorDisassociate = state.list.find(
        (sponsor: Sponsor) => sponsor.id === action.SponsorId
      );
      if (AuxSponsorDisassociate) {
        AuxSponsorDisassociate.contractsAssociated = [...AuxSponsorDisassociate.contractsAssociated.filter((contract: SponsorContract) => contract.id !== action.SponsorContractId) || []]
      }
      return {
        ...state,
        isDisassociatingContract: false,
        isDisassociatingContractSuccess: true,
        FailDisassociatingContract: false,
        list: [
          ...state.list.filter(
            (provider: Sponsor) => provider.id !== action.SponsorContractId
          )
        ].sort(Functions.DynamicSort('fullName')),
        error: undefined
      };
    case 'SPONSOR_CONTRACT_DISASSOCIATION_FAIL_ACTION':
      return {
        ...state,
        isDisassociatingContract: false,
        isDisassociatingContractSuccess: false,
        FailDisassociatingContract: true,
        error: action.error
      };
    case 'SPONSOR_GETALL_CONTRACTASSOCIATIONS_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAllContracts: true,
        isLoadingAllContractsSuccess: undefined,
        FailGettingAllContracts: false,
        error: undefined
      };
    case 'SPONSOR_GETALL_CONTRACTASSOCIATIONS_RECEIVE_ACTION':
      var AuxSponsorGetAll = state.list.find(
        (sponsor: Sponsor) => sponsor.id === action.SponsorId
      );
      if (AuxSponsorGetAll) {
        AuxSponsorGetAll.contractsAssociated = action.Contracts
      }
      return {
        ...state,
        isLoadingAllContracts: false,
        isLoadingAllContractsSuccess: true,
        FailGettingAllContracts: false,
        list: [
          ...state.list.filter(
            (provider: Sponsor) => provider.id !== action.Contracts[0].sponsorId
          ),
          AuxSponsorGetAll
        ].sort(Functions.DynamicSort('fullName')) as Sponsor[],
        error: undefined
      };
    case 'SPONSOR_GETALL_CONTRACTASSOCIATIONS_FAIL_ACTION':
      return {
        ...state,
        isLoadingAllContracts: false,
        isLoadingAllContractsSuccess: false,
        FailGettingAllContracts: true,
        error: action.error
      };
    default:
      return state;
  }
};
