import { Avatar, Button, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, Field, Input, Label, OverlayDrawer, Spinner, Tag, TagPicker, TagPickerControl, TagPickerGroup, TagPickerInput, TagPickerList, TagPickerOption, TagPickerProps, Text, Textarea } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { MessageBarType } from "@fluentui/react";

// component functions
import * as ComponentFunctions from './Payment.Form.Function';

// components
import { CurrencySelectorComponent } from "../../../../commons/component/Inputs/CurrencySelector/CurrencySelectorComponent";
import { DateTimeInput } from "../../../../commons/component/Inputs/DateTimeInput/DateTimeInput";
import PersonSelectorComponent from "../../../../commons/component/Inputs/PersonSelector/PersonSelector.Component";
import { MessageComponent } from "../../../../commons/component/MessageComponent";

// models
import { Person } from "../../../../models/Entities/Person/Person";
import { NewPayment } from "../../../../models/Entities/CashFlow/Payment/NewPayment";
import { Payment } from "../../../../models/Entities/CashFlow/Payment/Payment";
import { use } from "i18next";

interface FormValidation {
    success: boolean;
    errors: string[];
}

const PaymentFormComponent = (props: ComponentFunctions.Props) => {

    const styles = ComponentFunctions.useStyles();

    // form status
    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    const auxPerson: Person = {
        personIdentifier: props.OwnerId,
        id: 0,
        isDeleted: false,
        numberOfMarriages: 0,
        personType: 0,
        businessName: "Centro Médico"
    };

    // payment attributes
    const [currency, setCurrency] = useState<string | undefined>();
    const [paymentPayer, setPaymentPayer] = useState<Person | undefined>(props.DefaultPayer);
    const [paymentReceiver, setPaymentReceiver] = useState<Person | undefined>(props.DeFaultReceiver);
    const [paymentAmount, setPaymentAmount] = useState<number>(0);
    const [exhangeRate, setExchangeRate] = useState<number>(1);
    const [paymentDate, setPaymentDate] = useState<Date | undefined>(new Date());
    const [paymentComments, setPaymentComments] = useState<string>('');

    // if payment id is provided, get payment by id
    useEffect(() => {
        if (props.PaymentId && !props.CurrentPayment) {
            props.GetPaymentById(props.PaymentId);
        }
    }, [props.PaymentId]);

    useEffect(() => {
            setPaymentPayer(props.DefaultPayer);
            setPaymentReceiver(props.DeFaultReceiver);
            setPaymentDate(new Date());
    }, [props.DeFaultReceiver, props.DefaultPayer]);

    useEffect(() => {
        if (props.CurrentPayment) {
            setCurrency(props.CurrentPayment.currencyCode);
            setPaymentPayer(props.GetPersonByIdentifier(props.CurrentPayment.payer));
            setPaymentReceiver(props.GetPersonByIdentifier(props.CurrentPayment.receiver));
            setPaymentAmount(props.CurrentPayment.amount);
            setExchangeRate(props.CurrentPayment.exchangeRate);
            setPaymentDate(props.CurrentPayment.imputationDate);
            setPaymentComments(props.CurrentPayment.comments || '');
        } 

        return () => {
            ResetForm();
        }

    }, [props.CurrentPayment]);

    useEffect(() => {
        if (props.isSaved && submittedForm) {
            ResetForm();
            props.onClose();
        }
    }, [props.isSaved]);


    useEffect(() => {
        if (props.failOnSaving) {
            setSubmittedForm(false);
        }
    }, [props.failOnSaving]);

    // form validation

    const ValidateForm = (): boolean => {

        let validationResult: boolean = true;

        let FormValidation: FormValidation = { success: true, errors: [] };
        setFormValidationStatus(FormValidation);

        if (!currency) {
            FormValidation.errors.push("Debe seleccionar una moneda");
            validationResult = false;
        }

        if (!paymentPayer) {
            FormValidation.errors.push("Debe seleccionar un pagador");
            validationResult = false;
        }

        if (!paymentReceiver) {
            FormValidation.errors.push("Debe seleccionar un cobrador");
            validationResult = false;
        }

        if (!paymentAmount) {
            FormValidation.errors.push("Debe ingresar un monto");
            validationResult = false;
        }

        if (!paymentDate) {
            FormValidation.errors.push("Debe ingresar una fecha de pago");
            validationResult = false;
        }
        
        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };

    // reset form
    const ResetForm = () => {
        setSubmittedForm(false);
        setCurrency(undefined);
        setPaymentPayer(undefined);
        setPaymentReceiver(undefined);
        setPaymentAmount(0);
        setPaymentDate(undefined);
        setPaymentComments('');
    };

    //events

    const HandleCancelForm = () => {
        ResetForm();
        props.onClose();
    };
    const HandleSubmitForm = () => {

        setSubmittedForm(true);

        if (ValidateForm()) {
            // submit form
            if (!props.CurrentPayment) {
                const newPayment: NewPayment = {
                    payer: paymentPayer!.personIdentifier!,
                    receiver: paymentReceiver!.personIdentifier!,
                    currencyCode: currency!,
                    amount: paymentAmount!,
                    exchangeRate: exhangeRate!,
                    imputationDate: paymentDate!,
                    paymentDate: paymentDate!,
                    comments: paymentComments                    
                };
                props.CreatePayment(newPayment)
            } else {
                const payment: Payment = {
                    id: props.CurrentPayment.id,
                    payer: paymentPayer!.personIdentifier!,
                    receiver: paymentReceiver!.personIdentifier!,
                    currencyCode: currency!,
                    amount: paymentAmount!,
                    exchangeRate: exhangeRate!,
                    imputationDate: paymentDate!,
                    paymentDate: paymentDate!,
                    comments: paymentComments                    
                };
                props.UpdatePayment(payment);
            }
        } else {
            setSubmittedForm(false);
        }
    };

    const PayerPicker = (
        <PersonSelectorComponent
            personSelected={props.CurrentPayment ? [props.CurrentPayment?.payer] : props.DefaultPayer ? [props.DefaultPayer.personIdentifier] : []}
            selectionLimit={1}
            isRequired={true}
            fieldLabel={"Pagador"}
            CustomAditionalPersonsList={[{
                id: 0,
                businessName: 'Centro Médico',
                personIdentifier: props.OwnerId,
                numberOfMarriages: 0,
                personType: 0,
                isDeleted: false   
            } as Person]}
            onChangeSelection={(selectedPersons: Person[]) => setPaymentPayer(selectedPersons[0])}
        />
    );

    const ReceiverPicker = (
        <PersonSelectorComponent
            personSelected={props.CurrentPayment ? [props.CurrentPayment?.receiver] : props.DeFaultReceiver ? [props.DeFaultReceiver.personIdentifier] : []}
            selectionLimit={1}
            isRequired={true}
            fieldLabel={"Receptor"}
            CustomAditionalPersonsList={[{
                id: 0,
                businessName: 'Centro Médico',
                personIdentifier: props.OwnerId,
                numberOfMarriages: 0,
                personType: 0,
                isDeleted: false                
            } as Person]}
            onChangeSelection={(selectedPersons: Person[]) => setPaymentReceiver(selectedPersons[0])}
        />
    );

    const FormFields = (
        <div className={styles.content}>
            <div className={styles.content}>
                <Field>
                    {props.FormType === ComponentFunctions.FormType.payer ? PayerPicker : ReceiverPicker}
                </Field>
                <Field
                    validationState={((props.FormType === ComponentFunctions.FormType.receiver && paymentReceiver) || (props.FormType === ComponentFunctions.FormType.payer && paymentPayer)) ? "none" : "error"}	
                    validationMessage={submittedForm ? undefined : undefined}
                >
                   {props.FormType === ComponentFunctions.FormType.receiver ? PayerPicker : ReceiverPicker}
                </Field>

            </div>
            <CurrencySelectorComponent
                errorMessage={undefined}
                defaultSelected={currency}
                label="Moneda"
                isRequired={true}
                onCurrencyChange={(selectedCurrency: string | undefined) => setCurrency(selectedCurrency)}
            />
            <Field label={"Monto"} required>
                <Input
                    placeholder="monto del pago"
                    type="number"
                    id={'nmb_paymentamount'}
                    value={paymentAmount?.toString()}
                    step={0.01}
                    contentAfter={
                        <Text id={'nmb_paymentamount'}>
                            {currency}
                        </Text>
                    }
                    onChange={(e) => setPaymentAmount(parseFloat(e.currentTarget.value))}
                />
            </Field>
            <Field label="Cotización a moneda local" required>
                <Input
                    placeholder="Cotización a moneda local"
                    type="number"
                    value={exhangeRate?.toString()}
                    step={0.01}
                    onChange={(e) => setExchangeRate(parseFloat(e.currentTarget.value))}
                />
            </Field>
            <DateTimeInput label={"Fecha de pago"} placeHolder={"Fecha de pago"} selectedDate={paymentDate} onSelectDate={(date: Date | undefined) => setPaymentDate(date) } isSubmitted={submittedForm} isRequired={true} errorMessage={undefined} />
            <Field label="Comentarios">
                <Textarea
                    value={paymentComments || ''}
                    onChange={(e) => setPaymentComments(e.currentTarget.value)}
                />
            </Field>
        </div>
    );

    const Footer = () => {
        return (
            <DrawerFooter>
                <Button onClick={HandleCancelForm} disabled={submittedForm}>Cancelar</Button>
                <Button appearance="primary" onClick={HandleSubmitForm} disabled={submittedForm}>{props.isSaving && <><Spinner size="extra-tiny" />&nbsp;</>}Guardar</Button>
            </DrawerFooter>
        );
    };

    return (
        <div>
            <OverlayDrawer
                position={'end'}
                open={props.isOpen}
            >
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label="Close"
                                icon={<Dismiss24Regular />}
                                onClick={() => HandleCancelForm()}
                            />
                        }
                    >
                        Información de pago
                    </DrawerHeaderTitle>
                    {FormValidationStatus?.success === false && <MessageComponent message={"Verifique la información suministrada"} type={MessageBarType.error} itemList={FormValidationStatus?.errors} />}
                    {props.failOnSaving === true && <MessageComponent message={props.error?.ErrorMessage || 'Ocurrio un error inesperado'} type={MessageBarType.error} itemList={props.error?.Errors} />}
                </DrawerHeader>

                <DrawerBody className='ScrollShadow'>
                    {props.PaymentId ? props.CurrentPayment && FormFields : FormFields}
                </DrawerBody>
                <Footer />
            </OverlayDrawer>
        </div>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(PaymentFormComponent as any);