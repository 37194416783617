import { Action, Reducer } from 'redux';
// actions
import * as Actions from '../actions';

// functions
import * as Functions from '../../functions/common';

// state
import { ContractCommissionAgentConceptState, ContractCommissionAgentConceptUnloadState } from '../states/ContractCommissionAgentConceptState';

//models

type KnownAction =
    Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetAll_ByCommissionAgentId_Request_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetAll_ByCommissionAgentId_Response_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetAll_ByCommissionAgentId_Failure_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetById_Request_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetById_Response_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_GetById_Failure_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Create_Request_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Create_Response_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Create_Failure_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Update_Request_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Update_Response_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Update_Failure_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Delete_Request_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Delete_Response_Action
    | Actions.ContractCommissionAgentConcept.ContractCommissionAgentConcept_Delete_Failure_Action;

export const ContractCommissionAgentConceptReducer: Reducer<ContractCommissionAgentConceptState> = (
    state: ContractCommissionAgentConceptState | undefined, incomingAction: Action): ContractCommissionAgentConceptState => {

    if (state === undefined) {
        return ContractCommissionAgentConceptUnloadState as ContractCommissionAgentConceptState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_ALL_BY_COMMISSION_AGENT_ID_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: undefined,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_ALL_BY_COMMISSION_AGENT_ID_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
                list: action.contractCommissionAgentConcepts
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_ALL_BY_COMMISSION_AGENT_ID_FAILURE':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_BY_ID_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: undefined,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_BY_ID_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_GET_BY_ID_FAILURE':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_CREATE_REQUEST':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: undefined,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_CREATE_SUCCESS':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_CREATE_FAILURE':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_UPDATE_REQUEST':
            return {
                ...state,
                isUpdatingOne: true,
                successUpdatingOne: undefined,
                failOnUpdatingOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_UPDATE_SUCCESS':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: true,
                failOnUpdatingOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_UPDATE_FAILURE':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: false,
                failOnUpdatingOne: true,
                error: action.error
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_DELETE_REQUEST':
            return {
                ...state,
                isDeletingOne: true,
                successDeletingOne: undefined,
                failOnDeletingOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_DELETE_SUCCESS':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: true,
                failOnDeletingOne: false,
                error: undefined
            };
        case 'CONTRACT_COMMISSION_AGENT_CONCEPT_DELETE_FAILURE':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: false,
                failOnDeletingOne: true,
                error: action.error
            };
        default:
            return state;
    }
};