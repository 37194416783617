import { Label, Pivot, PivotItem } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppState } from '../../../redux/reducers';
import { HeaderPersonaComponent } from '../../../commons/component/headers/HeaderPersona/HeaderPersonaComponent';
import { Sponsor } from '../../../models/Entities/Sponsor/Sponsor';
import { PageHeader } from '../../../commons/component/PageHeader/PageHeader';
import ProtocolsListComponent from '../../../components/Protocols/ProtocolsList/ProtocolsList.Component';
import SponsorContractList from '../../../components/Sponsors/SponsorContractsList/SponsorContractList.Component';
import SettlementListComponent  from '../../../components/Settlements/SettlementList/SettlementList.Component';

// Store
import * as SponsorStore from '../../../redux/middleware/SponsorMiddleware';

////i18Next
import { useTranslation } from 'react-i18next';
import CashflowGeneralComponent from '../../../components/CashFlow/General/Cashflow.General.Component';

// component functions
import * as ownFunctions from './SponsorDetails.Functions';
import { withRouter } from '../../../commons/component/withRouterCustom';

export const SponsorDetailsPage = (props: ownFunctions.Props) => {
  

  //useTranslation
  const [t] = useTranslation();

  useEffect(() => {
    props.GetSponsorById(props.SponsorId);
  }, [props.SponsorId]);

  return (
    <>
      <PageHeader title={t('sponsorDetailPage.sponsorDetailTitle')}/>
      <div className="Content">
        <div className="Section">
          {props.Sponsor && (
            <HeaderPersonaComponent
              PersonaName={props.Sponsor?.person?.businessName}
              secondaryText={'Sponsor'}
            />
          )}
        </div>
        <div className="Section">
          {props.Sponsor &&<Pivot>
              <PivotItem headerText={t('sponsorDetailPage.sponsorDetailItem.protocols')}>
                <ProtocolsListComponent SponsorId={props.SponsorId} />
              </PivotItem>
              <PivotItem headerText={t('sponsorDetailPage.sponsorDetailItem.financial')}>
                <CashflowGeneralComponent EntityIdentifier={props.Sponsor?.person?.personIdentifier} FixedAssociatedEntityIdentified={props.OwnerId}  />
              </PivotItem> 
              <PivotItem headerText={t('sponsorDetailPage.sponsorDetailItem.contracts')}>
                <SponsorContractList SponsorId={props.SponsorId}   />
              </PivotItem>
              <PivotItem headerText={t('sponsorDetailPage.sponsorDetailItem.liquidations')}>
                <SettlementListComponent SponsorId={props.SponsorId} />
              </PivotItem>
              {/* <PivotItem headerText={t('sponsorDetailPage.sponsorDetailItem.accountStatus')}>
                <PaymentAccountDetailsComponent />
            </PivotItem> */}
          </Pivot>}
        </div>
      </div>
    </>
  );
};

export default withRouter(connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(SponsorDetailsPage as any));
