import React from 'react';
import {
  Checkbox,
  DayOfWeek,
  DefaultButton,
  Dropdown,
  IconButton,
  IDropdownOption,
  Label,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  Separator,
  Stack,
  TextField
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import { connect } from 'react-redux';
import { DaySelectorComponent } from '../../../commons/component/Inputs/DaySelector/DaySelectorComponent';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';
import { DateTimeInput } from '../../../commons/component/Inputs/DateTimeInput/DateTimeInput';
import { ProfessionalAvailabilityMedicalInsurance } from '../../../models/Entities/ProfessionalAvailability/ProfessionalAvailabilityMedicalInsurance';
import { ProfessionalAvailabilityMedicalService } from '../../../models/Entities/ProfessionalAvailability/ProfessionalAvailabilityMedicalService';
import { ProfessionalAvailability } from '../../../models/Entities/ProfessionalAvailability/ProfessionalAvailability';
import { InternalMedicalInsuranceSelected } from '../../../commons/component/Inputs/MedicalInsuranceSelector/MedicalInsuranceSelectorFunctions';
import PractitionerMedicalServiceSelectorComponent from '../../../commons/component/Inputs/PractitionerMedicalServiceSelector/PractitionerMedicalServiceSelectorComponent';

// own functions
import * as ComponentFunctions from './ProfessionalAvailabilityFormFunctions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';
import PractitionerMedicalInsuranceSelectorComponent from '../../../commons/component/Inputs/PractitionerMedicalInsuranceSelector/PractitionerMedicalInsuranceSelectorComponent';

const ProfessionalAvailabilityFormComponent = (
  props: ComponentFunctions.Props
) => {
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    ComponentFunctions.FormValidation | undefined
  >();

  // Form properties
  const [day, setDay] = useState<DayOfWeek>();
  const [validityStart, setValidityStart] = useState<Date>();
  const [validityEnd, setValidityEnd] = useState<Date>();
  const [availabilityFrom, setAvailabilityFrom] = useState<string>();
  const [availabilityTo, setAvailabilityTo] = useState<string>();
  const [medicalServices, setMedicalServices] =
    useState<ProfessionalAvailabilityMedicalService[]>();
  const [medicalInsurances, setMedicalInsurances] = useState<
    ProfessionalAvailabilityMedicalInsurance[]
  >([]);
  const [location, setLocation] = useState<string>();
  const [practitionerId, setPractitionerId] = useState<string>();
  const [enabledOnSite, setEnabledOnSite] = useState<boolean>();
  const [enableTeleMedicine, setEnableTeleMedicine] = useState<boolean>();
  const [enableParticularAttention, setEnableParticularAttention] =
    useState<boolean>();
  const [attendsOvershift, setAttendsOvershift] = useState<boolean>();
  const [frequency, setFrequency] = useState<number>();
  const [frequencyAmount, setFrequencyAmount] = useState<number>();
  const [firstDateOfFrequency, setFirstDateOfFrequency] = useState<Date>();
  const [appointmentDuration, setAppointmentDuration] = useState<number>();
  const [appointmentFirstTimeDuration, setAppointmentFirstTimeDuration] =
    useState<number>();
  const [isProtocol, setIsProtocol] = useState<boolean>();
  const [isProtocolExclusive, setIsProtocolExclusive] = useState<boolean>();
  const [visitNormalDuration, setVisitNormalDuration] = useState<number>();
  const [visitExtendedDuration, setVisitExtendedDuration] = useState<number>();

  //useTranslation()
  const [t] = useTranslation();

  // effects
  useEffect(() => {
    if (props.ProfessionalAvailability) {
      setDay(props.ProfessionalAvailability.day);
      setValidityStart(props.ProfessionalAvailability.validityStart);
      setValidityEnd(props.ProfessionalAvailability.validityEnd);
      setAvailabilityFrom(props.ProfessionalAvailability.availabilityFrom);
      setAvailabilityTo(props.ProfessionalAvailability.availabilityTo);
      setMedicalServices(props.ProfessionalAvailability.medicalServices);
      setMedicalInsurances(props.ProfessionalAvailability.medicalInsurances);
      setLocation(props.ProfessionalAvailability.location);
      setPractitionerId(props.ProfessionalAvailability.practitionerId);
      setEnabledOnSite(props.ProfessionalAvailability.enabledOnSite);
      setEnableTeleMedicine(props.ProfessionalAvailability.enableTeleMedicine);
      setEnableParticularAttention(
        props.ProfessionalAvailability.enableParticularAttention
      );
      setFrequency(props.ProfessionalAvailability.frequency);
      setFrequencyAmount(props.ProfessionalAvailability.frequencyAmount);
      setFirstDateOfFrequency(
        props.ProfessionalAvailability.firstDateOfFrequency
      );
      setAppointmentDuration(
        props.ProfessionalAvailability.appointmentDuration
      );
      setAppointmentFirstTimeDuration(
        props.ProfessionalAvailability.appointmentFirstTimeDuration
      );
      setIsProtocol(props.ProfessionalAvailability.isProtocol);
      setIsProtocolExclusive(
        props.ProfessionalAvailability.isProtocolExclusive
      );
      setVisitNormalDuration(
        props.ProfessionalAvailability.visitNormalDuration
      );
      setVisitExtendedDuration(
        props.ProfessionalAvailability.visitExtendedDuration
      );
      setAttendsOvershift(props.ProfessionalAvailability.attendsOvershift);
    } else {
      ResetForm();
    }
  }, [props.ProfessionalAvailability]);

  useEffect(() => {
    if (submittedForm && (props.addingSuccess || props.savingSuccess)) {
      ResetForm();
      props.dismissPanel();
    }
  }, [props.addingSuccess, props.savingSuccess, submittedForm]);

  const ResetForm = () => {
    setDay(undefined);
    setValidityStart(undefined);
    setValidityEnd(undefined);
    setAvailabilityFrom(undefined);
    setAvailabilityTo(undefined);
    setMedicalServices([]);
    setMedicalInsurances([]);
    setLocation(undefined);
    setPractitionerId(undefined);
    setEnabledOnSite(undefined);
    setEnableTeleMedicine(undefined);
    setEnableParticularAttention(undefined);
    setFrequency(undefined);
    setFrequencyAmount(undefined);
    setFirstDateOfFrequency(undefined);
    setAppointmentDuration(undefined);
    setAppointmentFirstTimeDuration(undefined);
    setIsProtocol(undefined);
    setIsProtocolExclusive(undefined);
    setVisitNormalDuration(undefined);
    setVisitExtendedDuration(undefined);
    setFormValidationStatus(undefined);
    setAttendsOvershift(undefined);
    setSubmittedForm(false);
  };

  // Events

  const onChangeAvailabilityFromFieldValue = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setAvailabilityFrom(newValue);
  };

  const onChangeAvailabilityToFieldValue = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setAvailabilityTo(newValue);
  };

  const onChangeLocationFieldValue = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setLocation(newValue);
  };

  const onChangeAppointmentDurationFieldValue = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setAppointmentDuration(Number(newValue));
  };

  const onChangeAppointmentFirstTimeDurationFieldValue = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setAppointmentFirstTimeDuration(Number(newValue));
  };

  const onChangeFrequencyFieldValue = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => {
    setFrequency(Number(option?.key));
  };

  const onChangeFrequencyAmountFieldValue = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setFrequencyAmount(Number(newValue));
  };

  const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      var CurrentValues: ProfessionalAvailability;
      if (props.ProfessionalAvailability) {
        CurrentValues = {
          id: props.ProfessionalAvailability.id,
          day: day!,
          practitionerId: props.Practitioner.userId,
          validityStart: validityStart!,
          validityEnd: validityEnd!,
          availabilityFrom: availabilityFrom!,
          availabilityTo: availabilityTo!,
          medicalServices: medicalServices || [],
          medicalInsurances: medicalInsurances || [],
          location: location!,
          enabledOnSite: enabledOnSite!,
          enableTeleMedicine: enableTeleMedicine!,
          attendsOvershift: attendsOvershift!,
          enableParticularAttention: enableParticularAttention!,
          frequency: frequency!,
          frequencyAmount: frequencyAmount!,
          firstDateOfFrequency: firstDateOfFrequency!,
          appointmentDuration: appointmentDuration!,
          appointmentFirstTimeDuration: appointmentFirstTimeDuration!,
          isProtocol: isProtocol!,
          isProtocolExclusive: isProtocolExclusive!,
          visitNormalDuration: visitNormalDuration || 0,
          visitExtendedDuration: visitExtendedDuration || 0
        };
        props.UpdateProfessionalAvailability(CurrentValues);
      } else {
        CurrentValues = {
          day: day!,
          validityStart: validityStart!,
          validityEnd: validityEnd!,
          availabilityFrom: availabilityFrom!,
          availabilityTo: availabilityTo!,
          medicalServices: medicalServices || [],
          medicalInsurances: medicalInsurances || [],
          location: location!,
          practitionerId: props.Practitioner.userId!,
          enabledOnSite: enabledOnSite!,
          enableTeleMedicine: enableTeleMedicine!,
          enableParticularAttention: enableParticularAttention!,
          attendsOvershift: attendsOvershift!,
          frequency: frequency!,
          frequencyAmount: frequencyAmount!,
          firstDateOfFrequency: firstDateOfFrequency!,
          appointmentDuration: appointmentDuration!,
          appointmentFirstTimeDuration: appointmentFirstTimeDuration!,
          isProtocol: isProtocol!,
          isProtocolExclusive: isProtocolExclusive!,
          visitNormalDuration: visitNormalDuration || 0,
          visitExtendedDuration: visitExtendedDuration || 0!
        };
        props.AddProfessionalAvailability(CurrentValues);
      }
    }
  };

  const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
  };

  // footer
  const onRenderFooterContent = () => {
    return (
      <div className={ComponentFunctions.contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'proffesionalAvailabilityForm.footer.cancelButton'}/>
        </DefaultButton>
        <PrimaryButton
          onClick={HandleSaveForm}
          styles={ComponentFunctions.buttonStyles}
          className="ConfirmAction"
        >
          <Trans i18nKey={'proffesionalAvailabilityForm.footer.saveButton'}/>
        </PrimaryButton>
      </div>
    );
  };

  const formFields = (
    <div {...ComponentFunctions.columnProps} className="formColumns">
      <div>
        <Label>{t('proffesionalAvailabilityForm.formFields.attentionHours')}</Label>
        <DaySelectorComponent
          selectedDay={day as DayOfWeek}
          onDaySelectionChanged={(selectedDays: DayOfWeek) =>
            setDay(selectedDays)
          }
        />
        <TextField
          label={t('proffesionalAvailabilityForm.formFields.servesFrom')}
          name="txt_AvailabilityFrom"
          required
          type="time"
          autoComplete="off"
          onChange={onChangeAvailabilityFromFieldValue}
          errorMessage={
            submittedForm === true && availabilityFrom === undefined
              ? t('proffesionalAvailabilityForm.formFields.requiredField')
              : undefined
          }
          value={availabilityFrom}
        />
        <TextField
          label={t('proffesionalAvailabilityForm.formFields.servesUpTo')}
          name="txt_AvailabilityTo"
          required
          type="time"
          autoComplete="off"
          onChange={onChangeAvailabilityToFieldValue}
          errorMessage={
            submittedForm === true && availabilityTo === undefined
              ? t('proffesionalAvailabilityForm.formFields.requiredField')
              : undefined
          }
          value={availabilityTo}
        />

        {!isProtocolExclusive && (
          <>
            <Separator />
            <Label>{t('proffesionalAvailabilityForm.formFields.durationOfAttention')}</Label>
            <TextField
              label={t('proffesionalAvailabilityForm.formFields.commonVisit')} 
              name="txt_AppointmentDuration"
              required
              max={99999}
              maxLength={5}
              type="number"
              autoComplete="off"
              onChange={onChangeAppointmentDurationFieldValue}
              errorMessage={
                submittedForm === true && appointmentDuration === undefined
                  ? t('proffesionalAvailabilityForm.formFields.requiredField')
                  : undefined
              }
              suffix="minutos"
              value={appointmentDuration?.toString() || '0'}
            />
            <TextField
              label={t('proffesionalAvailabilityForm.formFields.firstVisit')}
              name="txt_AppointmentFirstTimeDuration"
              required
              max={99999}
              type="number"
              autoComplete="off"
              onChange={onChangeAppointmentFirstTimeDurationFieldValue}
              errorMessage={
                submittedForm === true &&
                appointmentFirstTimeDuration === undefined
                  ? t('proffesionalAvailabilityForm.formFields.requiredField')
                  : undefined
              }
              suffix="minutos"
              value={appointmentFirstTimeDuration?.toString() || '0'}
            />
          </>
        )}
        <Separator />
        <Label>{t('proffesionalAvailabilityForm.formFields.protocolAttention')}</Label>
        <Stack tokens={ComponentFunctions.stackTokens}>
          <Checkbox
            label={t('proffesionalAvailabilityForm.formFields.protocolVisits')} 
            defaultChecked={isProtocol}
            onChange={(
              ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
              checked?: boolean | undefined
            ) => setIsProtocol(checked || false)}
          />
          {isProtocol && (
            <>
              <Checkbox
                label={t('proffesionalAvailabilityForm.formFields.exclusiveProtocolAttention')}
                defaultChecked={isProtocolExclusive}
                onChange={(
                  ev?:
                    | React.FormEvent<HTMLElement | HTMLInputElement>
                    | undefined,
                  checked?: boolean | undefined
                ) => setIsProtocolExclusive(checked || false)}
              />
              <TextField
                label={t('proffesionalAvailabilityForm.formFields.commonVisit')}
                name="txt_visitNormalDuration"
                required
                max={99999}
                maxLength={5}
                type="number"
                autoComplete="off"
                onChange={(
                  event: React.FormEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                  newValue?: string | undefined
                ) => setVisitNormalDuration(Number(newValue) || 0)}
                errorMessage={
                  submittedForm === true && visitNormalDuration === undefined
                    ? t('proffesionalAvailabilityForm.formFields.requiredField')
                    : undefined
                }
                suffix="minutos"
                value={visitNormalDuration?.toString() || '0'}
              />
              <TextField
                label={t('proffesionalAvailabilityForm.formFields.extendedVisit')}
                name="txt_visitExtendedDuration"
                required
                max={99999}
                type="number"
                autoComplete="off"
                onChange={(
                  event: React.FormEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                  newValue?: string | undefined
                ) => setVisitExtendedDuration(Number(newValue) || 0)}
                errorMessage={
                  submittedForm === true && visitExtendedDuration === undefined
                    ? t('proffesionalAvailabilityForm.formFields.requiredField')
                    : undefined
                }
                suffix="minutos"
                value={visitExtendedDuration?.toString() || '0'}
              />
            </>
          )}
        </Stack>
      </div>
      <div>
        {!isProtocolExclusive && (
          <>
            <Label>{t('proffesionalAvailabilityForm.formFields.attentionCriterion')}</Label>
            <Checkbox
              label={t('proffesionalAvailabilityForm.formFields.particularAttention')}
              defaultChecked={enableParticularAttention}
              onChange={(
                ev?:
                  | React.FormEvent<HTMLElement | HTMLInputElement>
                  | undefined,
                checked?: boolean | undefined
              ) => setEnableParticularAttention(checked || false)}
            />
            <Label>{t('proffesionalAvailabilityForm.formFields.enableOvershifts')}</Label>
            <Checkbox
              label={t('proffesionalAvailabilityForm.formFields.overshiftsEnabled')}
              defaultChecked={attendsOvershift}
              onChange={(
                ev?:
                  | React.FormEvent<HTMLElement | HTMLInputElement>
                  | undefined,
                checked?: boolean | undefined
              ) => setAttendsOvershift(checked || false)}
            />
            <Separator />
            <PractitionerMedicalInsuranceSelectorComponent
              onChangeSelection={(
                medicalInsurances: InternalMedicalInsuranceSelected[]
              ) => {
                setMedicalInsurances(
                  medicalInsurances.map(
                    (medicalInsurance: InternalMedicalInsuranceSelected) => {
                      var entity = {
                        medicalInsuranceId: medicalInsurance.MedicalInsuranceId,
                        medicalInsurancePlanId:
                          medicalInsurance.MedicalInsurancePlanID,
                        professionalAvailabilityId:
                          props.ProfessionalAvailability?.id
                      } as ProfessionalAvailabilityMedicalInsurance;
                      return entity;
                    }
                  ) || []
                );
              }}
              MedicalInsurancesSelected={
                props.ProfessionalAvailability?.medicalInsurances?.map(
                  (
                    professionalAvailabilityMedicalInsurance: ProfessionalAvailabilityMedicalInsurance
                  ) => {
                    return {
                      MedicalInsuranceId:
                        professionalAvailabilityMedicalInsurance.medicalInsuranceId,
                      MedicalInsurancePlanID:
                        professionalAvailabilityMedicalInsurance.medicalInsurancePlanId
                    };
                  }
                ) || []
              }
              MedicalInsuranceNoteText={t('proffesionalAvailabilityForm.formFields.medicalInsuranceNote')}
              MedicalPlanNoteText={t('proffesionalAvailabilityForm.formFields.medicalPlanNote')}
              MedicalInsuranceMultiselectEnable={true}
              MedicalPlansMultiselectEnable={true}
              SelectPlanEnable={true}
              PractitionerId={props.Practitioner.userId}
            />
            <PractitionerMedicalServiceSelectorComponent
              onSelectedServices={(services: AvailableMedicalService[]) => {
                setMedicalServices(
                  services.map((service: AvailableMedicalService) => {
                    return {
                      medicalServiceId: service.id,
                      professionalAvailabilityId:
                        props.ProfessionalAvailability?.id
                    } as ProfessionalAvailabilityMedicalService;
                  }) || []
                );
              }}
              MedicalServicesSelected={
                props.ProfessionalAvailability?.medicalServices.map(
                  (
                    ProfessionalAvailabilityMedicalService: ProfessionalAvailabilityMedicalService
                  ) => ProfessionalAvailabilityMedicalService.medicalServiceId
                ) || []
              }
              noteText={t('proffesionalAvailabilityForm.formFields.serviceNote')}
              MultiselectEnable={true}
              PractitionerId={props.Practitioner.userId}
            />
          </>
        )}

        <TextField
          label={t('proffesionalAvailabilityForm.formFields.placeAttention')}
          name="txt_Location"
          type="text"
          autoComplete="off"
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
          ) => setLocation(newValue)}
          defaultValue={location}
        />

        <Separator />
        <Label>{t('proffesionalAvailabilityForm.formFields.typeAttention')}</Label>
        <Stack tokens={ComponentFunctions.stackTokens}>
          <Checkbox
            label={t('proffesionalAvailabilityForm.formFields.onSiteAttention')}
            defaultChecked={enabledOnSite}
            onChange={(
              ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
              checked?: boolean | undefined
            ) => setEnabledOnSite(checked || false)}
          />

          <Checkbox
            label={t('proffesionalAvailabilityForm.formFields.servesTeleconsultation')}
            defaultChecked={enableTeleMedicine}
            onChange={(
              ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
              checked?: boolean | undefined
            ) => setEnableTeleMedicine(checked || false)}
          />
        </Stack>
      </div>
      <div>
        <Label>{t('proffesionalAvailabilityForm.formFields.frequencyAttention')}</Label>
        <Dropdown
          placeholder={t('proffesionalAvailabilityForm.formFields.select')}
          label={t('proffesionalAvailabilityForm.formFields.type')}
          options={ComponentFunctions.FrequencyOptions}
          onChange={onChangeFrequencyFieldValue}
          errorMessage={
            submittedForm === true && frequency === undefined
              ? t('proffesionalAvailabilityForm.formFields.requiredField')
              : undefined
          }
          selectedKey={frequency ? frequency.toString() : undefined}
        />
        <TextField
          label={t('proffesionalAvailabilityForm.formFields.periodicity')} 
          name="txt_amountFrequency"
          required
          max={99999}
          type="number"
          autoComplete="off"
          onChange={onChangeFrequencyAmountFieldValue}
          errorMessage={
            submittedForm === true && frequencyAmount === undefined
              ? t('proffesionalAvailabilityForm.formFields.requiredField')
              : undefined
          }
          suffix={frequency === 1 ? 'semanas' : 'meses'}
          defaultValue={frequencyAmount?.toString() || '0'}
        />
        <small>
         Cantidad de {frequency === 1 ? 'semanas' : 'meses'} para la proxima
          atencion
        </small>
        <DateTimeInput
          label={t('proffesionalAvailabilityForm.parameters.dateStartPeriodicity')}
          placeHolder={t('proffesionalAvailabilityForm.parameters.selectDate')}
          selectedDate={
            firstDateOfFrequency ? new Date(firstDateOfFrequency) : undefined
          }
          onSelectDate={(date: Date | undefined) => setFirstDateOfFrequency(date)}
          isSubmitted={submittedForm}
          isRequired={false}
          errorMessage={undefined}
        />
        <Separator />
        <Label>{t('proffesionalAvailabilityForm.parameters.validity')}</Label>
        <DateTimeInput
          label={t('proffesionalAvailabilityForm.parameters.from')}
          placeHolder={t('proffesionalAvailabilityForm.parameters.selectDate')}
          selectedDate={validityStart ? new Date(validityStart) : undefined}
          isSubmitted={submittedForm}
          isRequired={false}
          onSelectDate={(date: Date | undefined) => setValidityStart(date)}
          errorMessage={undefined}
        />
        <DateTimeInput
          label={t('proffesionalAvailabilityForm.parameters.until')}
          placeHolder={t('proffesionalAvailabilityForm.parameters.selectDate')}
          selectedDate={validityEnd ? new Date(validityEnd) : undefined}
          isSubmitted={submittedForm}
          isRequired={false}
          onSelectDate={(date: Date | undefined) => setValidityEnd(date)}
          errorMessage={undefined}
        />
      </div>
    </div>
  );

  // form validation

  const ValidateForm = (): boolean => {
    var validationResult: boolean = true;

    var FormValidation: ComponentFunctions.FormValidation = {
      success: true,
      errors: []
    };
    setFormValidationStatus(FormValidation);

    if (day === undefined) {
      validationResult = false;
      FormValidation.errors.push(
        t('proffesionalAvailabilityForm.formValidation.dayAttention')
      );
    }

    if (availabilityFrom === undefined) {
      validationResult = false;
      FormValidation.errors.push(
        t('proffesionalAvailabilityForm.formValidation.informStartAvailability')
      );
    }

    if (availabilityTo === undefined) {
      validationResult = false;
      FormValidation.errors.push(t('proffesionalAvailabilityForm.formValidation.informEndtAvailability'));
    }

    if (props.Practitioner === undefined) {
      validationResult = false;
      FormValidation.errors.push(t('proffesionalAvailabilityForm.formValidation.professionalInform'));
    }

    if (frequency === undefined) {
      validationResult = false;
      FormValidation.errors.push(t('proffesionalAvailabilityForm.formValidation.reportFrequency'));
    }

    if (frequencyAmount === undefined) {
      validationResult = false;
      FormValidation.errors.push(t('proffesionalAvailabilityForm.formValidation.reportFrequencyAmount'));
    }

    if (firstDateOfFrequency === undefined) {
      validationResult = false;
      FormValidation.errors.push(
        t('proffesionalAvailabilityForm.formValidation.informFirstDayFrequency')
      );
    }

    if (!isProtocolExclusive) {
      if (appointmentDuration === undefined) {
        validationResult = false;
        FormValidation.errors.push(t('proffesionalAvailabilityForm.formValidation.appointmentDuration'));
      }

      if (appointmentFirstTimeDuration === undefined) {
        validationResult = false;
        FormValidation.errors.push(
          t('proffesionalAvailabilityForm.formValidation.informDurationFirstAppointment') 
        );
      }
    }

    if (isProtocol) {
      if (visitNormalDuration === undefined) {
        validationResult = false;
        FormValidation.errors.push(
          t('proffesionalAvailabilityForm.formValidation.informDurationProtocolVisit') 
        );
      }

      if (visitExtendedDuration === undefined) {
        validationResult = false;
        FormValidation.errors.push(
          t('proffesionalAvailabilityForm.formValidation.informDurationExtendProtocolVisit') 
        );
      }
    }

    if (validationResult === false) {
      FormValidation.success = false;
      setFormValidationStatus(FormValidation);
    }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      isModeless={false}
      containerClassName={ComponentFunctions.contentStyles.container}
      closeButtonAriaLabel="Close"
    >
      <div className={ComponentFunctions.contentStyles.header}>
        <span>Informacion de disponibilidad del profesional</span>
        <IconButton
          styles={ComponentFunctions.iconButtonStyles}
          iconProps={ComponentFunctions.cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.dismissPanel}
        />
      </div>
      {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {t('proffesionalAvailabilityForm.formValidation.reviewInformation')}
          <ul>
            {FormValidationStatus?.errors.map(
              (Error: string, index: number) => (
                <li key={index}>{Error}</li>
              )
            )}
          </ul>
        </MessageBar>
      )}
      {submittedForm &&
        (props.failOnSaving || props.failOnAdding) &&
        props.error && (
          <MessageComponent
            message={t('proffesionalAvailabilityForm.formValidation.errorMessage')}
            subMessage={props.error.ErrorMessage}
            itemList={props.error.Errors}
            type={MessageBarType.error}
          />
        )}
      <div className={ComponentFunctions.contentStyles.body}>{formFields}</div>
      <Separator />
      {onRenderFooterContent()}
    </Modal>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(ProfessionalAvailabilityFormComponent as any);
