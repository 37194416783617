import { AppThunkAction } from '../reducers';
import { NewPractitionerAssociationsMedicalService } from '../../models/Entities/PractitionerAssociations/MedicalServices/NewPractitionerAssociationsMedicalService';
import { PractitionerAssociationsMedicalService } from '../../models/Entities/PractitionerAssociations/MedicalServices/PractitionerAssociationMedicalService';

// actions
import * as Actions from '../actions';

// services
import * as Services from '../../services/PractitionerMedicalServices.Service';
import * as PractitionerServices from '../../services/Practitioners.Service';

type KnownAction =
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_RequestAll_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_ReceiveAll_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_FailAll_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_RequestById_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_ReceiveById_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_FailById_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Add_Request_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Add_Receive_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Add_Fail_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Delete_Request_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Delete_By_Id_Receive_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Delete_By_Medical_Service_Id_Receive_Action
  | Actions.PractitionerMedicalServiceAssociations.PractitionerMedicalService_Delete_Fail_Action
  | Actions.Practitioner.Practitioners_GetAll_Receive_Action;

export const actionCreators = {
  GetMedicalServiceByPractitionerId:
    (practitionerId: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_MEDICAL_SERVICE_FAIL_ALL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      if (!getState().Practitioner?.successLoadingAll) {
        PractitionerServices.GetPractitioners(1, 1000, undefined, token).then(
          (practitioners) => {
            dispatch({
              type: 'PRACTITIONERS_GETALL_RECEIVE_ACTION',
              practitioners: practitioners
            });

            dispatch({
              type: 'PRACTITIONER_MEDICAL_SERVICE_REQUEST_ALL_ACTION',
              practitionerId: practitionerId
            });
          }
        );
      } else {
        dispatch({
          type: 'PRACTITIONER_MEDICAL_SERVICE_REQUEST_ALL_ACTION',
          practitionerId: practitionerId
        });
      }

      Services.GetAllPractitionerServices(practitionerId, token)
        .then((practitionerMedicalServices) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_SERVICE_RECEIVE_ALL_ACTION',
            practitionerId: practitionerId,
            practitionerMedicalServices
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_SERVICE_FAIL_ALL_ACTION',
            error
          });
        });
    },
  AssociateMedicalServiceToPractitioner:
    (
      newPractitionerMedicalService: NewPractitionerAssociationsMedicalService
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_MEDICAL_SERVICE_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONER_MEDICAL_SERVICE_ADD_REQUEST_ACTION',
        practitionerMedicalService: newPractitionerMedicalService
      });

      Services.AssociateMedicalService(newPractitionerMedicalService, token)
        .then(
          (
            practitionerMedicalService: PractitionerAssociationsMedicalService
          ) => {
            dispatch({
              type: 'PRACTITIONER_MEDICAL_SERVICE_ADD_RECEIVE_ACTION',
              practitionerMedicalService: practitionerMedicalService
            });
          }
        )
        .catch((error) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_SERVICE_ADD_FAIL_ACTION',
            error
          });
        });
    },
  DisassociateMedicalServiceFromPractitionerById:
    (
      practitionerMedicalService: PractitionerAssociationsMedicalService
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_MEDICAL_SERVICE_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONER_MEDICAL_SERVICE_DELETE_REQUEST_ACTION',
        practitionerMedicalServiceId: practitionerMedicalService.id
      });

      Services.DisassociateMedicalServiceById(practitionerMedicalService.id, token)
        .then((practitionerMedicalService) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_SERVICE_DELETE_BY_ID_RECEIVE_ACTION',
            practitionerId: practitionerMedicalService.practitionerId,
            practitionerMedicalServiceId: practitionerMedicalService.id
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_SERVICE_DELETE_FAIL_ACTION',
            error
          });
        });
    },
  DisassociateMedicalServiceFromPractitionerByMedicalServiceId:
    (
      PractitionerId: string,
      MedicalServiceId: number
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_MEDICAL_SERVICE_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONER_MEDICAL_SERVICE_DELETE_REQUEST_ACTION',
        practitionerMedicalServiceId: MedicalServiceId
      });

      Services.DisassociateMedicalServiceByMedicalServiceId(
        PractitionerId,
        MedicalServiceId,
        token
      )
        .then((practitionerMedicalService) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_SERVICE_DELETE_BY_MEDICAL_SERVICE_ID_RECEIVE_ACTION',
            practitionerId: PractitionerId,
            MedicalServiceId: MedicalServiceId
          });
        })
        .catch((error) => {
          dispatch({
            type: 'PRACTITIONER_MEDICAL_SERVICE_DELETE_FAIL_ACTION',
            error
          });
        });
    }
};
