
import React from 'react';
import { connect } from 'react-redux';
import { createTableColumn, TableColumnDefinition, TableCellLayout, DataGrid, DataGridHeader, DataGridRow, DataGridHeaderCell, DataGridBody, DataGridCell } from "@fluentui/react-components";

// functions
import * as Componentfunctions from './SettlementDetailValues.Functions';

// models
import { SettlementDetailConceptValue } from '../../../models/Entities/SettlementDetailConceptValue/SettlementDetailConceptValue';
import { Label } from '@fluentui/react';


export const columns: TableColumnDefinition<SettlementDetailConceptValue>[] = [
    createTableColumn<SettlementDetailConceptValue>({
        columnId: "concept",
        compare: (a, b) => {
            return a.contractConceptName.localeCompare(b.contractConceptName);
        },
        renderHeaderCell: () => {
            return "Concepto";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    {item.contractConceptName}
                </TableCellLayout>
            );
        },
    }),
    createTableColumn<SettlementDetailConceptValue>({
        columnId: "value",
        compare: (a: SettlementDetailConceptValue, b: SettlementDetailConceptValue) => {
            return a.value - b.value
        },
        renderHeaderCell: () => {
            return "Valor";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    <Label>{item.value.toLocaleString('es-AR', { style: 'currency', currency: item.currencyCode.toUpperCase() })}&nbsp;({item.percentValue + '%'})</Label>
                </TableCellLayout>
            );
        },
    })
];



const SettlementDetailValues = (props: Componentfunctions.Props) => {
    return (
        <div>
            <DataGrid
                items={props.CurrentDetail?.conceptValue || []}
                columns={columns}
                sortable
                selectionMode="multiselect"
                getRowId={(item) => item.id}
                focusMode="composite"
                style={{ minWidth: "550px" }}
            >
                <DataGridHeader>
                    <DataGridRow
                        selectionCell={{
                            checkboxIndicator: { "aria-label": "Select all rows" },
                        }}
                    >
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<SettlementDetailConceptValue>>
                    {({ item, rowId }) => (
                        <DataGridRow<SettlementDetailConceptValue>
                            key={rowId}
                            selectionCell={{
                                checkboxIndicator: { "aria-label": "Select row" },
                            }}
                        >
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
        </div>
    )
}

export default connect(
    Componentfunctions.mapStateToProps,
    Componentfunctions.mapDispatchToProps
)(SettlementDetailValues as any);