import { FontSizes, Label, Text } from "@fluentui/react";
import { ContractConcept } from "../../../../models/Entities/Contracts/ContractConcept";

////i18Next
import { useTranslation } from 'react-i18next';

// styles
import './ConceptList.Styles.css';
import { ConfirmationButtonComponent } from "../../../../commons/component/ConfirmationButton/ConfirmationButtonComponent";
import { ErrorType } from "../../../../models/HttpError";


export interface IProps {
    Concept: ContractConcept;
    index: number;
    isDeleting: boolean;
    failonDeleting: boolean;
    error: ErrorType | undefined;
    onEdit?: (stage: ContractConcept) => void;
    onDelete?: (stage: ContractConcept) => void;
}


export const ConceptItemComponent = (props: IProps) => {

    const onSelectStage = (concept: ContractConcept) => {
        if (props.onEdit) {
            props.onEdit(concept);
        }
    }

    //useTranslation()
    const [t] = useTranslation();

    return (
        <>
            <div className="StageListItem" key={props.Concept.id} onClick={() => onSelectStage(props.Concept)}>
                <div>
                    <Label style={{ fontSize: FontSizes.size14 }}>{props.Concept.concept.conceptName}</Label>
                    <Text style={{ fontSize: FontSizes.size12 }}>{props.Concept.concept.conceptDescription}</Text>
                </div>
                <div className="StageListItemOptions">
                    <small>{ }</small>
                </div>
                <div className="danger">
                    <ConfirmationButtonComponent
                        ButtonId={"deletebranch" + props.Concept.id}
                        ButtonIconName={"Delete"}
                        ButtonText={""}
                        ButtonCssClass={""}
                        ConfirmationButtonText={t('conceptItem.confirmationButton.deleteButton')}
                        ConfirmationButtonCssClass={""}
                        CancelButtonText={t('conceptItem.confirmationButton.cancelButton')}
                        CancelButtonCssClass={""}
                        Title={t('conceptItem.confirmationButton.tittle')}
                        Description={""}
                        ElementTextHeader={t('conceptItem.confirmationButton.deleteConcept') + props.Concept.concept.conceptName}
                        ElementTextInformation={t('conceptItem.confirmationButton.textInformation')}
                        onConfirmation={() =>
                            props.onDelete ? props.onDelete(props.Concept) : undefined
                        }
                        onCancel={function (): void {
                            throw new Error('Function not implemented.');
                        }}
                        isOperationExecuting={
                            props.isDeleting
                        }
                        isOperationExecutedWithError={
                            false
                        }
                        OperationError={undefined}
                    />
                </div>
            </div>
        </>
    );
};