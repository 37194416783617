import {
  DefaultButton,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  IStackProps,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  TextField,
  Toggle
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers';
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';

//store
import * as SponsorStore from '../../../redux/middleware/SponsorMiddleware';

// service
import * as SponsorService from '../../../services/Sponsors.Service';
import { Sponsor } from '../../../models/Entities/Sponsor/Sponsor';
import { NewSponsor } from '../../../models/Entities/Sponsor/NewSponsor';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

interface FormValidation {
  success: boolean;
  errors: string[];
}

export interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  Sponsor?: Sponsor;
}

export const SponsorFormComponent = (props: IProps) => {
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();

  const dispatch = useDispatch();

  //state
  const SponsorState = useSelector((appState: AppState) => appState.Sponsor);

  // entity properties
  const [isCompany, setIsCompany] = useState<boolean>(true);
  const [fullName, setFullName] = useState<string>();
  //const [firstName, setFirstName] = useState<string>();
  //const [lastName, setLastName] = useState<string>();

  //useTranslation()
  const [t] = useTranslation();

  // effects
  useEffect(() => {
    if (props.Sponsor) {
      setFullName(props.Sponsor.person.businessName);
      //setFirstName(props.Sponsor.firstName);
      //setLastName(props.Sponsor.lastName);
    } else {
      ResetForm();
    }
  }, [props.Sponsor]);

  // Effects

  useEffect(() => {
    if (
      submittedForm &&
      SponsorState &&
      (SponsorState.successAddingNewOne || SponsorState.successUpdatingOne)
    ) {
      ResetForm();
      props.dismissPanel();
    }
  }, [
    SponsorState?.successAddingNewOne,
    SponsorState?.successUpdatingOne,
    SponsorState,
    submittedForm,
    props
  ]);

  const ResetForm = () => {
    setFullName('');
    //setFirstName('');
    //setLastName('');
  };

  // Events

  const onChangeFullNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setFullName(newValue);
  };

  const onChangeFirstNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    //setFirstName(newValue);
  };

  const onChangeLastNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    //setLastName(newValue);
  };

  const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      let CurrentValues: Sponsor | NewSponsor;
      if (props.Sponsor) {
        //CurrentValues = {
        //  id: props.Sponsor.id,
        //  fullName: fullName!,
          //firstName: '', //firstName!,
          //lastName: '', //lastName!
        //} as Sponsor;
        //dispatch(mapDispatchToProps.UpdateSponsor(CurrentValues as Sponsor));
      } else {
        CurrentValues = {
          fullName: fullName!,
          firstName: '', //firstName!,
          lastName: '', //lastName!
        } as NewSponsor;
        dispatch(mapDispatchToProps.AddSponsor(CurrentValues as NewSponsor));
      }
    }
  };

  const HandleCancelForm = () => {
    ResetForm();

    props.dismissPanel();
  };

  // footer
  const onRenderFooterContent = () => {
    return (
      <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'sponsorForm.buttons.cancelButton'}/>
        </DefaultButton>
        <PrimaryButton
          onClick={HandleSaveForm}
          styles={buttonStyles}
          className="ConfirmAction"
        >
          <Trans i18nKey={'sponsorForm.buttons.saveButton'}/>
        </PrimaryButton>
      </div>
    );
  };

  const formFields = (
    <div {...columnProps}>
      {isCompany && (
        <TextField
          label={t('sponsorForm.formFields.businessName')}
          name="txt_fullName"
          required
          type="text"
          autoComplete="off"
          onChange={onChangeFullNameTextFieldValue}
          errorMessage={
            submittedForm === true && fullName === undefined
              ? t('sponsorForm.formFields.requiredField')
              : undefined
          }
          value={fullName}
        />
      )}
      {/* {!isCompany && (
        <>
          {' '}
          <TextField
            label={t('sponsorForm.formFields.name')}
            name="txt_firstName"
            required
            type="text"
            autoComplete="off"
            onChange={onChangeFirstNameTextFieldValue}
            errorMessage={
              submittedForm === true && firstName === undefined
                ? t('sponsorForm.formFields.requiredField')
                : undefined
            }
            value={firstName}
          />
          <TextField
            label={t('sponsorForm.formFields.lastName')}
            name="txt_lastName"
            required
            type="text"
            autoComplete="off"
            onChange={onChangeLastNameTextFieldValue}
            errorMessage={
              submittedForm === true && lastName === undefined
                ? t('sponsorForm.formFields.requiredField')
                : undefined
            }
            value={lastName}
          />
        </>
      )} */}
      <Toggle
        label={t('sponsorForm.formFields.type')}
        defaultChecked={isCompany}
        onText="Es una empresa"
        offText="Es una empresa"
        onChange={() => setIsCompany(!isCompany)}
      />
    </div>
  );

  // form validation

  const ValidateForm = (): boolean => {
    let validationResult: boolean = true;

    let FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    if ((fullName === undefined || fullName === '') && isCompany) {
      validationResult = false;
      FormValidation.success = false;
      FormValidation.errors.push(t('sponsorForm.formValidation.businessNameRequired'));
    }

    // if ((firstName === undefined || firstName === '') && !isCompany) {
    //   validationResult = false;
    //   FormValidation.success = false;
    //   FormValidation.errors.push(t('sponsorForm.formValidation.nameRequired'));
    // }

    // if ((lastName === undefined || lastName === '') && !isCompany) {
    //   validationResult = false;
    //   FormValidation.success = false;
    //   FormValidation.errors.push(t('sponsorForm.formValidation.lastNameRequired'));
    // }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      isModeless={false}
      containerClassName={contentStyles.container}
      closeButtonAriaLabel="Close"
    >
      <div className={contentStyles.header}>
        <span>Información del Proveedor</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.dismissPanel}
        />
      </div>
      {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {t('sponsorForm.modal.reviewInformation')}
          <ul>
            {FormValidationStatus?.errors.map(
              (Error: string, index: number) => (
                <li key={index}>{Error}</li>
              )
            )}
          </ul>
        </MessageBar>
      )}
      <div className={contentStyles.body}>
        {SponsorState &&
          (SponsorState.failOnAddingNewOne || SponsorState.failOnUpdatingOne) &&
          SponsorState.error && (
            <ErrorMessageComponent Error={SponsorState.error} />
          )}
        {formFields}
      </div>
      {onRenderFooterContent()}
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.Sponsor
});

const mapDispatchToProps = {
  ...SponsorStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsorFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
