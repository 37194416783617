import { ErrorType } from '../../../models/HttpError';
import { ProfessionalAvailability } from './../../../models/Entities/ProfessionalAvailability/ProfessionalAvailability';
import { AppState } from '../../../redux/reducers';
import {
  FontWeights,
  getTheme,
  IDropdownOption,
  IIconProps,
  IStackProps,
  mergeStyleSets
} from '@fluentui/react';

//store
import * as ProfessionalAvailabilityStore from '../../../redux/middleware/ProfessionalAvailabilityMiddleware';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';

export interface FormValidation {
  success: boolean;
  errors: string[];
}

interface OwnProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  Practitioner: Practitioner;
  ProfessionalAvailability: ProfessionalAvailability | undefined;
}

interface StateProps {
  isAdding?: boolean;
  addingSuccess?: boolean;
  failOnAdding?: boolean;
  isSaving?: boolean;
  savingSuccess?: boolean;
  failOnSaving?: boolean;
  error?: ErrorType;
}

interface DispatchProps {
  AddProfessionalAvailability: typeof ProfessionalAvailabilityStore.ActionCreators.AddProfessionalAvailability;
  UpdateProfessionalAvailability: typeof ProfessionalAvailabilityStore.ActionCreators.UpdateProfessionalAvailability;
}

interface CustomEvent {
  onSavedProfessionalAvailability?: (
    availability: ProfessionalAvailability
  ) => void;
}

export type Props = CustomEvent & StateProps & DispatchProps & OwnProps;

export const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    isAdding: state.ProfessionalAvailability?.isAddingNewOne,
    addingSuccess: state.ProfessionalAvailability?.successAddingNewOne,
    failOnAdding: state.ProfessionalAvailability?.failOnAddingNewOne,
    isSaving: state.ProfessionalAvailability?.isUpdatingOne,
    savingSuccess: state.ProfessionalAvailability?.successUpdatingOne,
    failOnSaving: state.ProfessionalAvailability?.failOnUpdatingOne,
    error: state.ProfessionalAvailability?.error
  };
};

export const mapDispatchToProps = {
  ...ProfessionalAvailabilityStore.ActionCreators
};

// Form Styles
export const buttonStyles = {
  root: { marginRight: 8, class: 'ConfirmAction' }
};

export const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
};

export const theme = getTheme();
export const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    display: 'flex',
    flex: '1',
    padding: '0 24px 24px 24px',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
});

export const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};

export const stackTokens = { childrenGap: 10 };

// Icons
export const DetailsIcon: IIconProps = { iconName: 'StackIndicator' };
export const InfoIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const UnlockIcon: IIconProps = { iconName: 'UnlockSolid' };
export const LockIcon: IIconProps = { iconName: 'LockSolid' };
export const EditIcon: IIconProps = { iconName: 'Edit' };
export const DeleteIcon: IIconProps = { iconName: 'Delete' };
export const cancelIcon: IIconProps = { iconName: 'Cancel' };

// Frequency options
export const FrequencyOptions: IDropdownOption[] = [
  { key: '1', text: 'Semanal' },
  { key: '3', text: 'Mensual' }
];
