import { AppThunkAction } from '../reducers';
import { NewTenantPriceList } from '../../models/Entities/TenantPriceLists/NewTenantPriceList';
import { TenantPriceList } from '../../models/Entities/TenantPriceLists/TenantPriceList';
import { ErrorType } from '../../models/HttpError';

// Actions
import * as Actions from '../actions';

// Services
import * as service from '../../services/TenantPriceLists.Services';

type KnownAction =
  | Actions.TenantPriceLists.TenantPriceListGetAll_Request_Action
  | Actions.TenantPriceLists.TenantPriceListGetAll_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListGetAll_Fail_Action
  | Actions.TenantPriceLists.TenantPriceListAdd_Request_Action
  | Actions.TenantPriceLists.TenantPriceListAdd_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListAdd_Fail_Action
  | Actions.TenantPriceLists.TenantPriceListSetStartValidity_Request_Action
  | Actions.TenantPriceLists.TenantPriceListSetStartValidity_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListSetStartValidity_Fail_Action
  | Actions.TenantPriceLists.TenantPriceListSetEndValidity_Request_Action
  | Actions.TenantPriceLists.TenantPriceListSetEndValidity_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListSetEndValidity_Fail_Action
  | Actions.TenantPriceLists.TenantPriceListUpdate_Request_Action
  | Actions.TenantPriceLists.TenantPriceListUpdate_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListUpdate_Fail_Action
  | Actions.TenantPriceLists.TenantPriceListDelete_Request_Action
  | Actions.TenantPriceLists.TenantPriceListDelete_Receive_Action
  | Actions.TenantPriceLists.TenantPriceListDelete_Fail_Action;

export const ActionCreators = {
  GetAllTenantPriceList:
    (): AppThunkAction<KnownAction> => async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'TENANT_PRICE_LIST_GET_ALL_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'TENANT_PRICE_LIST_GET_ALL_REQUEST_ACTION'
      });

      service
        .GetTenantPriceList(token)
        .then((lists: TenantPriceList[]) => {
          dispatch({
            type: 'TENANT_PRICE_LIST_GET_ALL_RECEIVE_ACTION',
            PriceList: lists
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'TENANT_PRICE_LIST_GET_ALL_FAIL_ACTION',
            error: error
          })
        );
    },
  AddTenantPriceList:
    (newTenantPriceList: NewTenantPriceList): AppThunkAction<KnownAction> =>
    async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'TENANT_PRICE_LIST_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'TENANT_PRICE_LIST_ADD_REQUEST_ACTION',
        NewPriceList: newTenantPriceList
      });

      service
        .CreateTenantPriceList(newTenantPriceList, token)
        .then((_newList: TenantPriceList) => {
          dispatch({
            type: 'TENANT_PRICE_LIST_ADD_RECEIVE_ACTION',
            PriceList: _newList
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'TENANT_PRICE_LIST_ADD_FAIL_ACTION',
            error: error
          });
        });
    },
  SetStartValidity:
    (
      tenantPriceListId: number,
      startValidity: Date
    ): AppThunkAction<KnownAction> =>
    async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'TENANT_PRICE_LIST_SET_START_VALIDITY_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'TENANT_PRICE_LIST_SET_START_VALIDITY_REQUEST_ACTION',
        TenantPriceListId: tenantPriceListId,
        StartValidity: startValidity
      });

      service
        .SetPriceListStartValidityDate(tenantPriceListId, startValidity, token)
        .then((_newList: TenantPriceList) => {
          dispatch({
            type: 'TENANT_PRICE_LIST_SET_START_VALIDITY_RECEIVE_ACTION',
            TenantPriceList: _newList
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'TENANT_PRICE_LIST_SET_START_VALIDITY_FAIL_ACTION',
            error: error
          });
        });
    },
  SetEndValidity:
    (
      tenantPriceListId: number,
      endValidity: Date
    ): AppThunkAction<KnownAction> =>
    async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'TENANT_PRICE_LIST_SET_END_VALIDITY_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'TENANT_PRICE_LIST_SET_END_VALIDITY_REQUEST_ACTION',
        TenantPriceListId: tenantPriceListId,
        EndValidity: endValidity
      });

      service
        .SetPriceListEndValidityDate(tenantPriceListId, endValidity, token)
        .then((_newList: TenantPriceList) => {
          dispatch({
            type: 'TENANT_PRICE_LIST_SET_END_VALIDITY_RECEIVE_ACTION',
            TenantPriceList: _newList
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'TENANT_PRICE_LIST_SET_END_VALIDITY_FAIL_ACTION',
            error: error
          });
        });
    },
  UpdateTenantPriceList:
    (tenantPriceList: TenantPriceList): AppThunkAction<KnownAction> =>
    async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'TENANT_PRICE_LIST_UPDATE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'TENANT_PRICE_LIST_UPDATE_REQUEST_ACTION',
        TenantPriceList: tenantPriceList
      });

      service
        .UpdateTenantPriceList(tenantPriceList, token)
        .then((data: TenantPriceList) => {
          dispatch({
            type: 'TENANT_PRICE_LIST_UPDATE_RECEIVE_ACTION',
            TenantPriceList: data
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'TENANT_PRICE_LIST_UPDATE_FAIL_ACTION',
            error: error
          });
        });
    },
  DeleteTenantPriceList:
    (tenantPriceListId: number): AppThunkAction<KnownAction> =>
    async (dispatch: any, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'TENANT_PRICE_LIST_DELETE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'TENANT_PRICE_LIST_DELETE_REQUEST_ACTION',
        TenantPriceListId: tenantPriceListId
      });

      service
        .DeleteTenantPriceList(tenantPriceListId, token)
        .then(() => {
          dispatch({
            type: 'TENANT_PRICE_LIST_DELETE_RECEIVE_ACTION',
            TenantPriceListId: tenantPriceListId
          });
        })
        .catch((error: ErrorType) => {
          dispatch({
            type: 'TENANT_PRICE_LIST_DELETE_FAIL_ACTION',
            error: error
          });
        });
    }
};
