import { Debt } from "../../models/Entities/CashFlow/Debt/Debt";
import { CommonState } from "../common/state/commonState";


export interface CashFlowDebtState extends CommonState<Debt> {
    // updating all
    isUpdatingAll: boolean;
    isUpdatingAllSuccess: boolean|undefined;
    FailUpdatingAll: boolean;

    // AddRange
    isAddingFinishedSuccessfully: boolean | undefined;

     // money owed
     DebtsAsDebtor: Debt[];

     // money to receive
     DebtsAsCreditor: Debt[];
}

export const CashFlowDebtUnloadState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,

    // updating all
    isUpdatingAll: false,
    isUpdatingAllSuccess: undefined,
    FailUpdatingAll: false,

    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,

    // updating
    isUpdatingOne: false,
    successUpdatingOne: undefined,
    failOnUpdatingOne: false,
    
    // adding
    isAddingNewOne: false,
    successAddingNewOne: undefined,
    failOnAddingNewOne: false,

    // deleting
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    FailDeletingOne: false, 

    // AddRange
    isAddingFinishedSuccessfully: undefined,

    // money owed
    DebtsAsDebtor: [],

    // money to receive
    DebtsAsCreditor: []
};