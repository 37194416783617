import {
  IIconProps,
  MessageBarType,
  PrimaryButton,
  Separator,
  Label,
  Text,
  Icon,
  FontSizes
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useState } from 'react';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import { MedicalInsurancePlan } from '../../../models/Entities/MedicalInsurances/MedicalInsurancePlan';
import { MedicalInsurancePlanFormComponent } from '../MedicalInsurancePlanForm/MedicalInsurancePlanFormComponent';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './MedicalInsurancePlan.css';

export interface IProps {
  MedicalInsuranceId: number;
  plans: MedicalInsurancePlan[];
}

export const MedicalInsurancePlanPanel = (_props: IProps) => {
  const AddIcon: IIconProps = { iconName: 'CirclePlus' };
  const ShieldIcon: IIconProps = { iconName: 'ShieldSolid' };

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const [selectedPlan, setSelectedPlan] = useState<MedicalInsurancePlan>();

  //useTranslation() 
  const [t] = useTranslation();

  const plansLayout = _props.plans.map((plan: MedicalInsurancePlan) => (
    <div className="PlanItem">
      <Label
        className="TextWithIcon"
        style={{ fontSize: FontSizes.mediumPlus }}
        onClick={() => HandleEditEvent(plan)}
      >
        <Icon iconName="ShieldSolid" />
        {plan.code} - {plan.title}
      </Label>
      <Text className="" key={plan.id}>
        {plan.description}
      </Text>
    </div>
  ));

  // events
  const HandleEditEvent = (plan: MedicalInsurancePlan) => {
    setSelectedPlan(plan);
    openPanel();
  };

  const HandleClosePanel = () => {
    setSelectedPlan(undefined);
    dismissPanel();
  };

  const handleAddClick = () => {
    openPanel();
  };

  const noPlansLayout = (
    <div className="NoData">
      <Separator />
      <label>{t('medicalInsurancePlanPanel.plans.registeredPlans')}</label>
    </div>
  );

  return (
    <>
      <div className="SectionWidget">
        <PrimaryButton
          secondaryText={t('medicalInsurancePlanPanel.add.addConventions')} 
          onClick={handleAddClick}
          text={t('medicalInsurancePlanPanel.add.addCoveragePlan')} 
          iconProps={AddIcon}
          className="actionButton"
        />
        {_props.plans.length === 0 ? (
          noPlansLayout
        ) : (
          <>
            <Separator />
            {plansLayout}
          </>
        )}
      </div>
      <MedicalInsurancePlanFormComponent
        isOpen={isOpen}
        dismissPanel={HandleClosePanel}
        openPanel={openPanel}
        MedicalInsuranceId={_props.MedicalInsuranceId}
        MedicalInsurancePlan={selectedPlan}
      />
    </>
  );
};
