import { IIconProps, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { PageHeader } from '../../../commons/component/PageHeader/PageHeader';
import SettlementListComponent from '../../../components/Settlements/SettlementList/SettlementList.Component';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const SettlementListPage = () => {
  const AddIcon: IIconProps = { iconName: 'CirclePlus' };

  //useTranslation()
  const [t] = useTranslation();

  //events
  const onEditEvent = () => {};

  const onAddEvent = () => {};

  const onInfoEvent = () => {};

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const handleClosePanel = () => {
    dismissPanel();
  };

  const HandleAddEvent = () => {
    openPanel();
  };

  return (
    <>
      <PageHeader title={t('settlementListPage.settlementListTitle')}/>
      <div className="Section">
        <SettlementListComponent />
      </div>
    </>
  );
};
