import React, { useEffect, useState } from 'react';
import { AppState } from '../../../redux/reducers';
import { useDispatch, useSelector, connect } from 'react-redux';
import {
  DefaultButton,
  IBasePickerSuggestionsProps,
  IIconProps,
  IStackProps,
  ITag,
  Label,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  TagPicker,
  TextField,
  Text,
  Toggle,
  Separator,
  MessageBar,
  MessageBarType,
  Panel,
  IconButton,
  getTheme,
  FontWeights
} from '@fluentui/react';
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';
import { useId } from '@fluentui/react-hooks';

import { NewAvailableMedicalService } from '../../../models/Entities/MedicalServices/NewAvailableMedicalService';

// own functions
import * as ownFunctions from './MedicalServiceForm.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// services
import * as ProceduresService from '../../../services/Procedures.Services';

// models
import { Procedure } from '../../../models/Entities/Procedures/Procedure';

interface FormValidation {
  success: boolean;
  errors: string[];
}

export const MedicalServicesFormComponent = (props: ownFunctions.Props) => {

  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();

  const SnowmedpickerId = useId('snowmed-picker');

  const MedicalServicesState = useSelector(
    (state: AppState) => state.MedicalServices
  );
  const dispatch = useDispatch();

  // MedicalInsurance state
  const [id, setId] = useState<number>();
  const [serviceNameReference, setServiceNameReference] = useState<string>();
  const [available, setAvailable] = useState<boolean>(false);
  const [isLinkedToSnowmed, setIsLinkedToSnowmed] = useState<boolean>(true);
  const [snowmedCode, setSnowmedCode] = useState<number>();
  const [snowmedName, setSnowmedName] = useState<string>();
  const [comments, setComments] = useState<string>();

  //useTranslation() 
  const [t] = useTranslation();
  // effects
  useEffect(() => {
    if (props.MedicalService) {
      setId(props.MedicalService.id);
      setServiceNameReference(props.MedicalService.serviceNameReference);
      setAvailable(props.MedicalService.available);
      setIsLinkedToSnowmed(props.MedicalService.isLinkedToSnowmed);
      setSnowmedCode(props.MedicalService.snowmedCode);
      setComments(props.MedicalService.comments);
      setSnowmedName(props.MedicalService.snowmedName);
    } else {
      ResetForm();
    }
  }, [props.MedicalService]);

  useEffect(() => {
    if (
      submittedForm &&
      MedicalServicesState &&
      (MedicalServicesState.successAddingNewOne ||
        MedicalServicesState.successUpdatingOne)
    ) {
      ResetForm();
      props.dismissPanel();
    }
  }, [
    MedicalServicesState?.successAddingNewOne,
    MedicalServicesState?.successUpdatingOne
  ]);

  const ResetForm = () => {
    setServiceNameReference(undefined);
    setAvailable(false);
    setId(undefined);
    setComments(undefined);
    setIsLinkedToSnowmed(true);
    setSnowmedCode(undefined);
    setSnowmedName(undefined);
    setFormValidationStatus(undefined);
    setSubmittedForm(false);
  };

  // Events

  const onChangeIdTextFieldValue = (items?: ITag[] | undefined) => {
    if (items && items.length > 0) {
      setSnowmedCode(Number(items[0].key));
      setSnowmedName(items[0].name);
      if (serviceNameReference === '' || serviceNameReference === undefined)
        setServiceNameReference(items[0].name);
    }
  };

  const onChangeServiceNameReferenceTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setServiceNameReference(newValue || '');
  };

  const onChangeAvailableToggleValue = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
      setAvailable(checked || false);
    },
    []
  );

  const onChangeIsLinkedToSnowmedToggleValue = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
      setIsLinkedToSnowmed(checked || false);
    },
    []
  );

  const onChangeSnowmedCodeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setSnowmedCode(newValue ? Number(newValue) : undefined);
  };

  const onChangeCommentsTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setComments(newValue || '');
  };

  const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      var CurrentValues: AvailableMedicalService | NewAvailableMedicalService;
      if (props.MedicalService) {
        CurrentValues = {
          id: props.MedicalService.id,
          serviceNameReference: serviceNameReference!,
          isLinkedToSnowmed: isLinkedToSnowmed,
          available: available,
          disableUntilDate: props.MedicalService.disableUntilDate,
          disableComment: props.MedicalService.disableComment,
          snowmedCode: snowmedCode,
          comments: comments,
          snowmedName: snowmedName,
          enablePatientSelfManagement: false,
          serviceProvidedBy3rdParty: false,
          enableforAppointment: true,
          onlyForAdministrativeUse: false
        };
        props.UpdateMedicalService(CurrentValues);
      } else {
        CurrentValues = {
          serviceNameReference: serviceNameReference!,
          isLinkedToSnowmed: isLinkedToSnowmed,
          available: available,
          disableUntilDate: undefined,
          disableComment: undefined,
          snowmedCode: snowmedCode,
          comments: comments,
          snowmedName: snowmedName
        };
        props.AddMedicalService(CurrentValues);
      }
    }
  };

  const HandleCancelForm = () => {
    props.dismissPanel();
    ResetForm();
  };

  // footer
  const onRenderFooterContent = () => {
    return (
      <div>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'medicalServiceForm.footer.cancelButton'} />
        </DefaultButton>
        <PrimaryButton
          onClick={HandleSaveForm}
          styles={buttonStyles}
          className="ConfirmAction"
        >
          <Trans i18nKey={'medicalServiceForm.footer.saveButton'} />
        </PrimaryButton>
      </div>
    );
  };

  const filterSuggestedTags = async (
    filterText: string,
    tagList?: ITag[]
  ): Promise<ITag[]> => {

    if (props.access_token) {
      return await ProceduresService.GetProcedures(filterText, props.access_token).then(
        (procedures: Procedure[]) => {
          return procedures.map((item: Procedure) => {
            return { key: item.id, name: item.name };
          });
        });
    } else {
      return [] as ITag[];
    }
  };

  const formFields = (
    <>
      <>
        <Label>Servicio Médico</Label>
        <Toggle
          label={t('medicalServiceForm.props.requireLinking')}
          inlineLabel
          defaultChecked={isLinkedToSnowmed as boolean}
          onChange={onChangeIsLinkedToSnowmedToggleValue}
        />
      </>
      {isLinkedToSnowmed && (
        <>
          {props.MedicalService && props.MedicalService.isLinkedToSnowmed && (
            <>
              <Label>{t('medicalServiceForm.props.currentLink')}</Label>
              <Text>{props.MedicalService.snowmedCode}</Text> |{' '}
              <Text style={{ textTransform: 'capitalize' }}>
                {props.MedicalService.snowmedName}
              </Text>
            </>
          )}
          <Label htmlFor={SnowmedpickerId}>{t('medicalServiceForm.props.snowmed')}</Label>
          <TagPicker
            removeButtonAriaLabel="Eliminar"
            selectionAriaLabel="Seleccione un servicio"
            onResolveSuggestions={filterSuggestedTags} //filterSugge
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            itemLimit={1}
            onChange={onChangeIdTextFieldValue}
            // this option tells the picker's callout to render inline instead of in a new layer
            pickerCalloutProps={{ doNotLayer: false }}
            inputProps={{
              id: SnowmedpickerId
            }}
          />
          <small>{t('medicalServiceForm.props.enterText')}</small>
          <Separator />
        </>
      )}
      <TextField
        label={t('medicalServiceForm.props.referenceName')}
        name="txt_serviceNameReference"
        required
        multiline
        autoComplete="off"
        onChange={onChangeServiceNameReferenceTextFieldValue}
        errorMessage={
          submittedForm === true && serviceNameReference === undefined
            ? t('medicalServiceForm.props.requiredField')
            : undefined
        }
        value={serviceNameReference || ''}
      />
      <TextField
        label={t('medicalServiceForm.props.comments')}
        name="txt_comentarios"
        multiline
        autoComplete="off"
        onChange={onChangeCommentsTextFieldValue}
        value={comments || ''}
      />
      {props.MedicalService && (
        <>
          <Separator></Separator>
          <Toggle
            label={t('medicalServiceForm.props.serviceEnable')}
            inlineLabel
            defaultChecked={available as boolean}
            onChange={onChangeAvailableToggleValue}
          />
        </>
      )}
    </>
  );

  // form validation

  const ValidateForm = (): boolean => {
    var validationResult: boolean = true;

    var FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    if (isLinkedToSnowmed && snowmedCode === undefined) {
      validationResult = false;
      FormValidation.errors.push(t('medicalServiceForm.formValidation.selectService'));
    }

    if (
      serviceNameReference === undefined ||
      serviceNameReference.length === 0
    ) {
      validationResult = false;
      FormValidation.errors.push(t('medicalServiceForm.formValidation.referenceName'));
    }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  return (
    <Panel
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
      headerText={t('medicalServiceForm.modal.title')}
    >
      {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {t('medicalServiceForm.modal.reviewInformation')}
          <ul>
            {FormValidationStatus?.errors.map(
              (Error: string, index: number) => (
                <li key={index}>{Error}</li>
              )
            )}
          </ul>
        </MessageBar>
      )}
      {submittedForm &&
        MedicalServicesState &&
        (MedicalServicesState.failOnAddingNewOne ||
          MedicalServicesState.failOnUpdatingOne) &&
        MedicalServicesState.error && (
          <ErrorMessageComponent Error={MedicalServicesState.error} />
        )}
      {formFields}
    </Panel>
  );
};


export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(MedicalServicesFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10,
    Width: '500px'
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'servicios sugeridos',
  noResultsFoundText: 'No se encontro servicio'
};

const getTextFromItem = (item: ITag) => item.name;
