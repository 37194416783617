import {
  IconButton,
  IIconProps,
  Label,
  Toggle,
  Text,
  MessageBarType,
  Spinner
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AvailableMedicalService } from '../../../models/Entities/MedicalServices/AvailableMedicalService';
import { MessageComponent } from '../../../commons/component/MessageComponent';

// functions
import * as ComponentFunctions from './MedicalService.Function';
////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Styles
import './MedicalServiceListComponent.css';
import MedicalServiceSettingsFormComponent from '../MedicalServiceSettingsForm/MedicalServiceSettingsFormComponent';
import { ConfirmationButtonComponent } from '../../../commons/component/ConfirmationButton/ConfirmationButtonComponent';

const MedicalServiceListComponent = (props: ComponentFunctions.Props) => {
  const EditIcon: IIconProps = { iconName: 'Edit' };
  const AddIcon: IIconProps = { iconName: 'CirclePlus' };
  const SettingsIcon: IIconProps = { iconName: 'Settings' };
  //useTranslation()
  const [t] = useTranslation();

  const [
    isSettingsFormOpen,
    { setTrue: openSettingsFormPanel, setFalse: dismissSettingsFormPanel }
  ] = useBoolean(false);

  // state variables
  const [selectedMedicalService, setSelectedMedicalService] = useState<
    AvailableMedicalService | undefined
  >(undefined);

  //events
  const onEditEvent = (service: AvailableMedicalService) => {
    if (props.editEvent) props.editEvent(service);
  };

  const onChangeSettings = (service: AvailableMedicalService) => {
    setSelectedMedicalService(service);
    openSettingsFormPanel();
  };

  const onDeletingMedicalService = (service: AvailableMedicalService) => {
    props.DeleteMedicalService(service.id);
  };

  const onChangeStatus = (
    service: AvailableMedicalService,
    enabled: boolean
  ): void => {
    props.ChangeMedicalServiceStatus(service.id, enabled);
  };

  //effects
  useEffect(() => {
    props.GetAllMedicalServices(1, 1000, undefined);
  }, []);

  return (
    <>
      {!props.isLoading && props.MedicalServices?.length === 0 && (
        <MessageComponent
          message={t('medicalServiceList.props.registeredMedicalServices')}
          type={MessageBarType.info}
        />
      )}
      {props.isLoadingSuccessfully && (
        <table className="Table">
          <thead>
            <tr>
              <th>{t('medicalServiceList.props.id')}</th>
              <th>{t('medicalServiceList.props.medicalPractice')}</th>
              <th>{t('medicalServiceList.props.snowmedCode')}</th>
              <th>{t('medicalServiceList.props.state')}</th>
              <th style={{ width: '150px' }}>{t('medicalServiceList.props.state')}</th>
            </tr>
          </thead>
          <tbody>
            {props.MedicalServices.filter((item: AvailableMedicalService) =>
              props.searchCriteria && props.searchCriteria !== ''
                ? item.serviceNameReference.toLocaleLowerCase().includes(props.searchCriteria.toLocaleLowerCase())
                : true
            ).map((item: AvailableMedicalService) => (
              <tr key={item.id} className="TableBodyRow">
                <td className="primary">{item.id}</td>
                <td>
                  <Label className="upper">{item.serviceNameReference}</Label>
                  <Text>{item.comments}</Text>
                </td>
                <td>
                  <Text>
                    {item.isLinkedToSnowmed &&
                    item.snowmedCode &&
                    item.snowmedName
                      ? item.snowmedCode + ' | ' + item.snowmedName
                      : t('medicalServiceList.props.notLinked')}
                  </Text>
                </td>
                <td>
                  <Toggle
                    label=""
                    defaultChecked={item.available as boolean}
                    onText={t('medicalServiceList.props.enable')}
                    offText={t('medicalServiceList.props.disabled')}
                    checked={item.available as boolean}
                    onChanged={(checked: boolean) =>
                      onChangeStatus(item, checked)
                    }
                  />
                </td>
                <td style={{ width: '150px' }}>
                  <IconButton
                    iconProps={EditIcon}
                    title={t('medicalServiceList.props.modify')} 
                    ariaLabel="Edit"
                    onClick={() => onEditEvent(item)}
                  />
                  <IconButton
                    iconProps={SettingsIcon}
                    title={t('medicalServiceList.props.config')}
                    ariaLabel="Settings"
                    onClick={() => onChangeSettings(item)}
                  />
                  <div className="danger">
                    <ConfirmationButtonComponent
                      ButtonId={'MedicalServiceDeleteButton'}
                      ButtonIconName={'Delete'}
                      ButtonText={''}
                      ButtonCssClass={''}
                      ConfirmationButtonText={t('medicalServiceList.confirmationButton.deleteButton')}
                      ConfirmationButtonCssClass={'deleteButton'}
                      CancelButtonText={t('medicalServiceList.confirmationButton.cancelButton')}
                      CancelButtonCssClass={''}
                      Title={t('medicalServiceList.confirmationButton.deleteInformation')}
                      Description={t('medicalServiceList.confirmationButton.descriptionInformation')}
                      ElementTextHeader={t('medicalServiceList.confirmationButton.medicalServiceText')}
                      ElementTextInformation={item.serviceNameReference}
                      onConfirmation={() => onDeletingMedicalService(item)}
                      onCancel={function (): void {
                        throw new Error('Function not implemented.');
                      }}
                      isOperationExecuting={props.isDeleting}
                      isOperationExecutedWithError={
                        props.failOnDeleting || false
                      }
                      OperationError={props.error}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {props.isLoading && (
        <div>
          <Spinner
            label={t('medicalServiceList.props.loadingMedicalServices')} 
            ariaLive="assertive"
            labelPosition="top"
          />
        </div>
      )}
      {selectedMedicalService && (
        <MedicalServiceSettingsFormComponent
          isOpen={isSettingsFormOpen}
          openPanel={openSettingsFormPanel}
          dismissPanel={dismissSettingsFormPanel}
          MedicalServiceId={selectedMedicalService.id}
        />
      )}
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(MedicalServiceListComponent as any);
