import React, { useState } from 'react';
import { AvailableMedicalService } from '../../models/Entities/MedicalServices/AvailableMedicalService';
import {
  DefaultButton,
  IIconProps,
  PrimaryButton,
  SearchBox
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import MedicalServiceListComponent from '../../components/MedicalServices/MedicalServiceList/MedicalServiceListComponent';
import MedicalServicesFormComponent from '../../components/MedicalServices/MedicalServiceForm/MedicalServiceForm.Component';
import { PageHeader } from '../../commons/component/PageHeader/PageHeader';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const MedicalServicesPage = () => {
  const AddIcon: IIconProps = { iconName: 'CirclePlus' };
  const [selectedItemId, setSelectedItemId] = useState<number>();
  const [searchCriteria, setSearchCriteria] = useState<string>();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  //useTranslation()
  const [t] = useTranslation()

  // events
  const HandleEditEvent = (Service: AvailableMedicalService) => {
    setSelectedItemId(Service?.id);
    openPanel();
  };

  const handleClosePanel = () => {
    setSelectedItemId(undefined);
    dismissPanel();
  };

  const handleAddClick = () => {
    openPanel();
  };

  const OnSearch = (
    event?: React.ChangeEvent<HTMLInputElement> | undefined,
    newValue?: string | undefined
  ) => {
    setSearchCriteria(newValue);
  };

  return (
    <>
      <PageHeader title={t('medicalServicesPage.medicalServicesTitle')}/>
      <div className="Section">
        <div className="listHeader">
          <PrimaryButton
            secondaryText={t('medicalServicesPage.button.addMedicalService')}
            onClick={handleAddClick}
            text={t('medicalServicesPage.button.addMedicalService')}
            iconProps={AddIcon}
            className="actionButton"
          />
          <SearchBox
            placeholder={t('medicalServicesPage.searchService')}
            onChange={OnSearch}
            underlined={false}
          />
        </div>
        <MedicalServiceListComponent
          editEvent={HandleEditEvent}
          searchCriteria={searchCriteria}
        />
        <MedicalServicesFormComponent
          isOpen={isOpen}
          dismissPanel={handleClosePanel}
          openPanel={openPanel}
          MedicalServiceId={selectedItemId}
        />
      </div>
    </>
  );
};
