import { PriceList } from './../../models/Entities/PriceList/PriceList';
import { AppThunkAction } from '../reducers';
import { NewPriceList } from '../../models/Entities/PriceList/NewPriceList';
import { ErrorType } from '../../models/HttpError';

// services
import * as PriceServices from '../../services/PriceList.Services';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.PriceLists.PriceListAdd_Request_Action
  | Actions.PriceLists.PriceListAdd_Receive_Action
  | Actions.PriceLists.PriceListAdd_Fail_Action
  | Actions.PriceLists.PriceListUpdate_Request_Action
  | Actions.PriceLists.PriceListUpdate_Receive_Action
  | Actions.PriceLists.PriceListUpdate_Fail_Action
  | Actions.PriceLists.PriceListGetById_Request_Action
  | Actions.PriceLists.PriceListGetById_Receive_Action
  | Actions.PriceLists.PriceListGetById_Fail_Action;

export const actionCreators = {
  AddPriceList:
    (newPriceList: NewPriceList): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRICE_LIST_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRICE_LIST_ADD_REQUEST_ACTION',
        newPriceList: newPriceList
      });

      PriceServices.Add(newPriceList, token)
        .then((price: PriceList) => {
          dispatch({
            type: 'PRICE_LIST_ADD_RECEIVE_ACTION',
            PriceList: price
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRICE_LIST_ADD_FAIL_ACTION',
            error: error
          })
        );
    },
  UpdatePriceList:
    (priceList: PriceList): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRICE_LIST_UPDATE_FAIL_ACTION',
          Error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRICE_LIST_UPDATE_REQUEST_ACTION',
        PriceList: priceList
      });
      PriceServices.Update(priceList, token)
        .then((updatedPriceList: PriceList) => {
          dispatch({
            type: 'PRICE_LIST_UPDATE_RECEIVE_ACTION',
            PriceList: updatedPriceList
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRICE_LIST_UPDATE_FAIL_ACTION',
            Error: error
          })
        );
    },
  GetPriceListById:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRICE_LIST_GET_BY_ID_FAIL_ACTION',
          Error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRICE_LIST_GET_BY_ID_REQUEST_ACTION',
        PriceListID: id
      });
      PriceServices.GetPriceList(id, token)
        .then((priceList: PriceList) => {
          dispatch({
            type: 'PRICE_LIST_GET_BY_ID_RECEIVE_ACTION',
            PriceList: priceList
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRICE_LIST_GET_BY_ID_FAIL_ACTION',
            Error: error
          })
        );
    }
};
