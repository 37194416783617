import { SettlementResume } from "../../../models/Entities/SettlementResume/SettlementResume";
import { ErrorType } from "../../../models/HttpError";
import { AppState } from "../../../redux/reducers";

// stores
import * as SettlementResumeStore from '../../../redux/middleware/SettlementResumeMiddleware';
import * as SettlementDetailsStore from '../../../redux/middleware/SettlementDetailsMiddleware';


//Entities 
import { Settlement } from "../../../models/Entities/Settlements/Settlement";
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";

interface IProps {
    SettlementId: number;
    SettlementSummaryItemId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatedSuccessfully: boolean;
    isLoadingSuccessfully: boolean;
    error: ErrorType | undefined;
    Settlement: Settlement | undefined;
    CurrentSummary: SettlementResume | undefined;
    DetailsCount: number | undefined;
    DetailsPendingCount: number | undefined;
    DetailsExcludedCount: number | undefined;
    DetailsVerifiedCount: number | undefined;
    DetailsToVerificateCount: number | undefined;
  }
  
  interface DispatchProps {
    GetSettlementResumeById: typeof SettlementResumeStore.actionCreators.GetSettlementResumeById;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => ({
    isLoading: state.SettlementResume?.isLoadingOne,
    isUpdating: state.SettlementResume?.isUpdatingOne,
    isUpdatedSuccessfully: state.SettlementResume?.successLoadingOne,
    isLoadingSuccessfully: state.SettlementResume?.successLoadingOne,
    failOnUpdating: state.SettlementResume?.failOnLoadingOne,
    failOnLoading: state.SettlementResume?.failOnLoadingOne,
    isDeleting: state.SettlementResume?.isDeletingOne,
    isDeletedSuccessfully: state.SettlementResume?.successDeletingOne,
    failOnDeleting: state.SettlementResume?.failOnDeletingOne,
    error: state.SettlementResume?.error,
    Settlement: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId ) : undefined,
    CurrentSummary: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.resumes?.find(
        (item: SettlementResume) => item.id === ownProps.SettlementSummaryItemId
    ) : undefined,
    DetailsCount: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((detail: SettlementDetail) => detail.settlementResumeId === ownProps.SettlementSummaryItemId).length : 0,
    DetailsPendingCount: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((detail: SettlementDetail) => detail.settlementResumeId === ownProps.SettlementSummaryItemId && (detail.markOk === false && detail.isExcluded === false )).length : 0,
    DetailsExcludedCount: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((detail: SettlementDetail) => detail.settlementResumeId === ownProps.SettlementSummaryItemId && detail.isExcluded === true).length : 0,
    DetailsVerifiedCount: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((detail: SettlementDetail) => detail.settlementResumeId === ownProps.SettlementSummaryItemId && detail.markOk === true ).length : 0,
    DetailsToVerificateCount: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.filter((detail: SettlementDetail) => detail.settlementResumeId === ownProps.SettlementSummaryItemId && detail.markForReview === true ).length : 0,
  });
  
  export const mapDispatchToProps = {
    ...SettlementResumeStore.actionCreators,
    ...SettlementDetailsStore.actionCreators
  };