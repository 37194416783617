import { connect } from "react-redux";
import React, { useState } from "react";

// functions
import * as ComponentFunctions from './PracitionerProtocolActivity.Functions';
import { useEffect } from "react";
import { PractitionerProtocolActivityMonth } from "./PractitionerProtocolActivityMonth";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";
import { number } from "prop-types";


const PractitionerProtocolActivityComponent = (props: ComponentFunctions.Props) => {

    const [FilteredProtocols, setFilteredProtocols] = useState<Protocol[]>([]);

    useEffect(() => {
        props.PractitionerId && props.GetPractitionerById(props.PractitionerId);
    }, [props.PractitionerId]);

    useEffect(() => {
        props.GetProtocolsByPractitionerId(props.PractitionerId);
    }, [props.Practitioner !== undefined]);

    useEffect(() => {
        var filteredProtocols = props.protocolsIds?.length > 0 ?  props.Protocols?.filter((protocol: Protocol) => props.protocolsIds.some((id: number) => id === protocol.id)) : props.Protocols;
        setFilteredProtocols(filteredProtocols);

        var auxProtocolsIds: number[] = filteredProtocols.map((protocol: Protocol) => protocol.id); 
        console.log('protocols', auxProtocolsIds);

        auxProtocolsIds.length > 0 && props.GetPractitionerProtocolVisitsCount(props.PractitionerId, auxProtocolsIds, props.DateFrom, props.DateTo);

    }, [props.Protocols, props.PractitionerId, props.DateFrom, props.DateTo]);

    // useEffect(() => {
    //     if (props.Practitioner && props.Protocols.length > 0) {
    //         var auxDateFrom = new Date();
    //         var auxDateTo = new Date();
    //         // set auxDateTo to the last day of the month
    //         auxDateTo.setDate(0);

    //         auxDateFrom.setMonth(auxDateFrom.getMonth() - props.Months || 3);
    //         // set auxDateFrom to the first day of the month
    //         auxDateFrom.setDate(1);  
    //     }

    // }, [props.Protocols, props.Months, props.protocolsIds]);


    return (
        <div className="ActivityContainer">
            {ComponentFunctions.getMonthsBetweenDates(props.DateFrom, props.DateTo).map(month =>
                FilteredProtocols && <PractitionerProtocolActivityMonth month={month.month} year={month.year} visits={props.visitis} protocols={FilteredProtocols} practitionerId={props.PractitionerId} />
            )}
        </div>
    );
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(PractitionerProtocolActivityComponent as any);