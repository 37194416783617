import { PractitionerAssociationsMedicalService } from './../models/Entities/PractitionerAssociations/MedicalServices/PractitionerAssociationMedicalService';
import { IResult } from '../models/HttpResult';
import { NewPractitionerAssociationsMedicalService } from '../models/Entities/PractitionerAssociations/MedicalServices/NewPractitionerAssociationsMedicalService';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'practitioners/';

export const GetAllPractitionerServices = async (
  PractitionerId: string
, token: string): Promise<PractitionerAssociationsMedicalService[]> => {
  
  let RequestURL: string =
    BaseURL + 'aggregator/' + PractitionerId + '/medicalservices';

  try {
    const Response: PractitionerAssociationsMedicalService[] = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'GET'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerAssociationsMedicalService[]>
          >;
        }
      })
      .then((data: IResult<PractitionerAssociationsMedicalService[]>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AssociateMedicalService = async (
  MedicalServiceAssociation: NewPractitionerAssociationsMedicalService
, token: string): Promise<PractitionerAssociationsMedicalService> => {
  let RequestURL: string = BaseURL + 'aggregator/medicalservices';

  try {
    const Response: PractitionerAssociationsMedicalService = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'POST',
        body: JSON.stringify(MedicalServiceAssociation)
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerAssociationsMedicalService>
          >;
        }
      })
      .then((data: IResult<PractitionerAssociationsMedicalService>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DisassociateMedicalServiceById = async (
  MedicalServiceAssociationId: number
, token: string): Promise<PractitionerAssociationsMedicalService> => {
  
  let RequestURL: string =
    BaseURL + 'medicalservices/' + MedicalServiceAssociationId;

  try {
    const Response: PractitionerAssociationsMedicalService = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'DELETE'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerAssociationsMedicalService>
          >;
        }
      })
      .then((data: IResult<PractitionerAssociationsMedicalService>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DisassociateMedicalServiceByMedicalServiceId = async (
  PractitionerId: string,
  MedicalServiceId: number
, token: string): Promise<PractitionerAssociationsMedicalService> => {
  let RequestURL: string =
    BaseURL +
    'associations/' +
    PractitionerId +
    '/medicalservices/' +
    MedicalServiceId;

  try {
    const Response: PractitionerAssociationsMedicalService = await fetch(
      RequestURL,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'DELETE'
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerAssociationsMedicalService>
          >;
        }
      })
      .then((data: IResult<PractitionerAssociationsMedicalService>) => {
        return data.data;
      })
      .catch((error: any) => {
        throw error.text
          ? error
              .text()
              .then((body: any) => Functions.HttpErrorHandler(body, error))
          : Functions.HttpErrorHandler(null, error);
      });

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
