import { IDropdownOption } from '@fluentui/react';

export const daysOptions: IDropdownOption[] = [
  { key: '0', text: 'Domingo' },
  { key: '1', text: 'Lunes' },
  { key: '2', text: 'Martes' },
  { key: '3', text: 'Miércoles' },
  { key: '4', text: 'Jueves' },
  { key: '5', text: 'Viernes' },
  { key: '6', text: 'Sábado' }
];
