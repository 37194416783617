import React, { useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { RouteProps } from 'react-router-dom';
import { AppState } from '../../redux/reducers';
import {
  ApplicationPaths,
  QueryParameterNames
} from './ApiAuthorizationConstants';

interface AuthorizeRouteProps extends RouteProps {
  component: React.ComponentType;
}

export const AuthorizeRoute = (props: AuthorizeRouteProps) => {
  const [ready, setReady] = useState(false);

  const UserAuthenticateState = useSelector(
    (state: AppState) => state.oidc
  );

  const { component: Component, ...rest } = props;
  
  const redirectUrl = `${ApplicationPaths.Login}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURI(window.location.href)}`;

  useEffect(() => {
    //alert('pasa por aca');
    if(!UserAuthenticateState?.isLoadingUser && (!UserAuthenticateState?.user || UserAuthenticateState?.user?.expired)) {
      //alert('no autenticado');
      window.location.replace(redirectUrl); 
    } else {
      //alert('esta todo ok');
      setReady(true);
    }
  }, [UserAuthenticateState?.isLoadingUser, UserAuthenticateState?.user?.expired]);

  if (!ready) {
    return <div></div>;
  } else {
    return (UserAuthenticateState?.user && UserAuthenticateState?.user?.expired === false) ? (
      <Component {...props} />
    ) : (
      <></>
    );
  }
};

const mapStateToProps = (state: AppState) => ({
  ...state.oidc
});

export default connect(
  mapStateToProps
)(AuthorizeRoute as any);
