import { AppThunkAction } from '../reducers';


// models
import { NewPractitionerAssociationSettings } from '../../models/Entities/PractitionerAssociations/Settings/NewPractitionerAssociationSettings';
import { PractitionerAssociationSettings } from '../../models/Entities/PractitionerAssociations/Settings/PractitionerAssociationSettings';
import { ErrorType } from '../../models/HttpError';

// Services
import * as services from '../../services/PractitionerSettings.Services';

// actions
import * as Actions from '../actions';


type KnownAction =
  | Actions.PractitionerSettings.PractitionerSettings_Request_Action
  | Actions.PractitionerSettings.PractitionerSettings_Receive_Action
  | Actions.PractitionerSettings.PractitionerSettings_Fail_Action
  | Actions.PractitionerSettings.PractitionerSettings_Add_Request_Action
  | Actions.PractitionerSettings.PractitionerSettings_Add_Receive_Action
  | Actions.PractitionerSettings.PractitionerSettings_Add_Fail_Action
  | Actions.PractitionerSettings.PractitionerSettings_Update_Request_Action
  | Actions.PractitionerSettings.PractitionerSettings_Update_Receive_Action
  | Actions.PractitionerSettings.PractitionerSettings_Update_Fail_Action;

export const actionCreators = {
  GetPractitionerSettings:
    (PractitionerId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_SETTINGS_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONER_SETTINGS_REQUEST_ACTION',
        practitionerId: PractitionerId
      });

      services
        .GetPractitionerSettings(PractitionerId, token)
        .then((practitionerSettings: PractitionerAssociationSettings) => {
          dispatch({
            type: 'PRACTITIONER_SETTINGS_RECEIVE_ACTION',
            practitionerSettings: practitionerSettings
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRACTITIONER_SETTINGS_FAIL_ACTION',
            error: error
          })
        );
    },
  AddPractitionerSettings:
    (
      practitionerSettings: NewPractitionerAssociationSettings
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_SETTINGS_ADD_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONER_SETTINGS_ADD_REQUEST_ACTION',
        practitionerSettings: practitionerSettings
      });

      services
        .AddPractitionerSettings(practitionerSettings, token)
        .then((practitionerSettings: PractitionerAssociationSettings) => {
          dispatch({
            type: 'PRACTITIONER_SETTINGS_ADD_RECEIVE_ACTION',
            practitionerSettings: practitionerSettings
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRACTITIONER_SETTINGS_ADD_FAIL_ACTION',
            error: error
          })
        );
    },

  UpdatePractitionerSettings:
    (
      practitionerSettings: PractitionerAssociationSettings
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {

      var state = getState();
      var token: string;

      if (state.oidc?.user?.access_token === undefined) {
        dispatch({
          type: 'PRACTITIONER_SETTINGS_UPDATE_FAIL_ACTION',
          error: {
            ErrorCode: 401,
            ErrorMessage: 'Not authorized',
            Errors: []
          }
        });
        return;
      } else {
        token = state.oidc.user.access_token;
      }

      dispatch({
        type: 'PRACTITIONER_SETTINGS_REQUEST_ACTION',
        practitionerId: practitionerSettings.practitionerId
      });

      services
        .SavePractitionerSettings(practitionerSettings, token)
        .then((practitionerSettings: PractitionerAssociationSettings) => {
          dispatch({
            type: 'PRACTITIONER_SETTINGS_RECEIVE_ACTION',
            practitionerSettings: practitionerSettings
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'PRACTITIONER_SETTINGS_FAIL_ACTION',
            error: error
          })
        );
    }
};
