import React from 'react';
import { connect } from 'react-redux';
import {
  FontSizes,
  Icon,
  IconButton,
  Label,
  MessageBarType,
  PrimaryButton,
  Separator,
  Spinner,
  Toggle
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useBoolean } from '@fluentui/react-hooks';
import { ProfessionalAvailability } from '../../../models/Entities/ProfessionalAvailability/ProfessionalAvailability';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import ProfessionalAvailabilityFormComponent from '../ProfessionalAvailabilityForm/ProfessionalAvailabilityFormComponent';

// Functions
import * as ComponentFunctions from './ProfessionalAvailabilityListFunctions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Styles
import './ProfessionalAvailabilityList.css';
import { ConfirmationButtonComponent } from '../../../commons/component/ConfirmationButton/ConfirmationButtonComponent';

const ProfessionalAvailabilityListComponent = (
  props: ComponentFunctions.Props
) => {
  const [
    selectedProfessionalAvailability,
    setSelectedProfessionalAvailability
  ] = useState<ProfessionalAvailability>();

  const [ShowExpiredAvailability, setShowExpiredAvailability] = useState(false);

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  //useTranslation();
  const [t] = useTranslation();

  useEffect(() => {
    props.GetAllProfessionalAvailability(props.PractitionerId);
    props.GetAllPractitioners(1, 1000, '');
    props.GetAllMedicalInsurances(1, 1000);
    props.GetAllMedicalServices(1, 1000, undefined);
  }, []);

  // events

  const onAddProfessionalAvailabilityEvent = () => {
    setSelectedProfessionalAvailability(undefined);
    openPanel();
  };

  const onEditProfessionalAvailabilityEvent = (
    availability: ProfessionalAvailability
  ) => {
    setSelectedProfessionalAvailability(availability);
    openPanel();
  };

  const onDeleteProfessionalAvailabilityEvent = (
    availability: ProfessionalAvailability
  ) => {
    props.DeleteProfessionalAvailability(availability.id!);
  };

  const onCancel = () => {
    dismissPanel();
  };

  const filters = (
    <>
      <Toggle label="Mostrar disponibilidad expirada? " defaultChecked={ShowExpiredAvailability} onText="Sí" offText="No" onChange={
        (ev, checked) => checked !== undefined && setShowExpiredAvailability(checked)
      } />
    </>
  );

  // render
  const onRenderCell = ComponentFunctions.GetFilteredAvailability(ShowExpiredAvailability, props.ProfessionalAvailabilityList)?.map(
    (list: ProfessionalAvailability, index) => (
      <tr key={'D' + list.id + index} className="TableBodyRow"
      // onClick={() => onEditProfessionalAvailabilityEvent(list)}
      >
        <td
          className={
            'primary TenantPriceList ' +
            ComponentFunctions.getStatus(list).status
          }
        >
          <Label>{ComponentFunctions.GetDayDescription(list.day)}</Label>
        </td>
        <td>
          {list.medicalInsurances.length > 0 ? (
            <ul>
              {list.medicalInsurances.map((item, index) => (
                <li
                  key={'D' + list.id + 'MI' + item.medicalInsuranceId + index}
                >
                  {ComponentFunctions.GetMedicalInsuranceDescription(
                    item.medicalInsuranceId,
                    item.medicalInsurancePlanId,
                    props.MedicalInsurances
                  )}
                </li>
              ))}
            </ul>
          ) : (
            t('professionalAvailabilityList.getMedicalInsurance.all')
          )}
        </td>
        <td>
          {list.medicalServices.length > 0 ? (
            <ul>
              {list.medicalServices.map((item, index) => (
                <li key={'D' + list.id + 'MS' + item.medicalServiceId + index}>
                  {ComponentFunctions.GetMedicalServiceDescription(
                    item.medicalServiceId,
                    props.MedicalServices
                  )}
                </li>
              ))}
            </ul>
          ) : (
            t('professionalAvailabilityList.getMedicalInsurance.all')
          )}
        </td>
        <td>{list.availabilityFrom}</td>
        <td>{list.availabilityTo}</td>
        <td>{list.location || t('professionalAvailabilityList.getMedicalInsurance.noInformed')}</td>
        <td>
          {list.isProtocolExclusive ? (
            '-'
          ) : list.enableParticularAttention ? (
            <Icon
              iconName="CompletedSolid"
              style={{ color: 'green', fontSize: FontSizes.mediumPlus }}
            />
          ) : (
            <Icon
              iconName="Blocked2Solid"
              style={{ color: 'red', fontSize: FontSizes.mediumPlus }}
            />
          )}
        </td>
        <td>
          {list.attendsOvershift === undefined ? (
            '-'
          ) : list.attendsOvershift ? (
            <Icon
              iconName="CompletedSolid"
              style={{ color: 'green', fontSize: FontSizes.mediumPlus }}
            />
          ) : (
            <Icon
              iconName="Blocked2Solid"
              style={{ color: 'red', fontSize: FontSizes.mediumPlus }}
            />
          )}
        </td>
        <td>
          {list.isProtocolExclusive
            ? '-'
            : (list.appointmentDuration || 0) + ' min'}
        </td>
        <td>
          {list.isProtocolExclusive
            ? '-'
            : (list.appointmentFirstTimeDuration || 0) + ' min'}
        </td>
        <td>
          {list.enabledOnSite ? (
            <Icon
              iconName="CompletedSolid"
              style={{ color: 'green', fontSize: FontSizes.mediumPlus }}
            />
          ) : (
            <Icon
              iconName="Blocked2Solid"
              style={{ color: 'red', fontSize: FontSizes.mediumPlus }}
            />
          )}
        </td>
        <td>
          {list.enableTeleMedicine ? (
            <Icon
              iconName="CompletedSolid"
              style={{ color: 'green', fontSize: FontSizes.mediumPlus }}
            />
          ) : (
            <Icon
              iconName="Blocked2Solid"
              style={{ color: 'red', fontSize: FontSizes.mediumPlus }}
            />
          )}
        </td>
        <td>{list.frequency === 1 ? t('professionalAvailabilityList.frequency.weeklyFrequency') : t('professionalAvailabilityList.frequency.monthlyFrequency')}</td>
        <td>
          Cada {list.frequencyAmount}
          {list.frequency === 1 ? t('professionalAvailabilityList.frequency.weekFrequency') : t('professionalAvailabilityList.frequency.monthFrequency')}
        </td>
        <td>
          {list.firstDateOfFrequency ? (
            <Moment format="DD/MM/YYYY">{list.firstDateOfFrequency}</Moment>
          ) : (
            <span>{t('professionalAvailabilityList.frequency.notDefined')}</span>
          )}
        </td>
        <td>
          {list.isProtocol ? (
            <Icon
              iconName="CompletedSolid"
              style={{ color: 'green', fontSize: FontSizes.mediumPlus }}
            />
          ) : (
            <Icon
              iconName="Blocked2Solid"
              style={{ color: 'red', fontSize: FontSizes.mediumPlus }}
            />
          )}
        </td>
        <td>
          {list.isProtocolExclusive ? (
            <Icon
              iconName="CompletedSolid"
              style={{ color: 'green', fontSize: FontSizes.mediumPlus }}
            />
          ) : (
            <Icon
              iconName="Blocked2Solid"
              style={{ color: 'red', fontSize: FontSizes.mediumPlus }}
            />
          )}
        </td>
        <td>
          {!list.isProtocol ? '-' : (list.visitNormalDuration || 0) + ' min'}
        </td>
        <td>
          {!list.isProtocol ? '-' : (list.visitExtendedDuration || 0) + ' min'}
        </td>
        <td>
          {list.validityStart ? (
            <Moment format="DD/MM/YYYY">{list.validityStart}</Moment>
          ) : (
            <span>{t('professionalAvailabilityList.validity.notDefined')}</span>
          )}
        </td>
        <td>
          {list.validityEnd ? (
            <Moment format="DD/MM/YYYY">{list.validityEnd}</Moment>
          ) : (
            <span>{t('professionalAvailabilityList.validity.notDefined')}</span>
          )}
        </td>
        <td>
          <div>
            <IconButton
              iconProps={ComponentFunctions.EditIcon}
              title={t('professionalAvailabilityList.buttons.editButton')}
              ariaLabel="Information"
              onClick={() => onEditProfessionalAvailabilityEvent(list)}
            />
            <div className="danger">
              <ConfirmationButtonComponent
                ButtonId={'ProfessionalAvailabilityDeleteButton' + list.id}
                ButtonIconName={'Delete'}
                ButtonText={''}
                ButtonCssClass={''}
                ConfirmationButtonText={t('professionalAvailabilityList.buttons.deleteButton')}
                ConfirmationButtonCssClass={'deleteButton'}
                CancelButtonText={t('professionalAvailabilityList.buttons.cancelButton')}
                CancelButtonCssClass={''}
                Title={t('professionalAvailabilityList.buttons.title')}
                Description={t('professionalAvailabilityList.buttons.description')}
                ElementTextHeader={''}
                ElementTextInformation={''}
                onConfirmation={() =>
                  onDeleteProfessionalAvailabilityEvent(list)
                }
                onCancel={onCancel}
                isOperationExecuting={props.isDeleting || false}
                isOperationExecutedWithError={props.failOnDeleting || false}
                OperationError={props.error}
              />
            </div>
            {/* <IconButton
            iconProps={ComponentFunctions.DeleteIcon}
            title="Eliminar"
            ariaLabel="Information"
            onClick={() => onDeleteProfessionalAvailabilityEvent(list)}
          /> */}
          </div>
        </td>
      </tr>
    )
  );

  const TenantPriceListGrid =
    props.ProfessionalAvailabilityList &&
      props.ProfessionalAvailabilityList.length > 0 ? (
      <div className='AvailabilityListContent'>
        <table className="AvailabilityTable Table">
          <thead>
            <tr>
              <th>{t('professionalAvailabilityList.parameters.day')}</th>
              <th style={{ minWidth: 150 }}>{t('professionalAvailabilityList.parameters.medicalCoverage')}</th>
              <th style={{ minWidth: 150 }}>{t('professionalAvailabilityList.parameters.medicalService')}</th>
              <th>{t('professionalAvailabilityList.parameters.scheduleFrom')}</th>
              <th>{t('professionalAvailabilityList.parameters.scheduleUntil')}</th>
              <th>{t('professionalAvailabilityList.parameters.location')}</th>
              <th>{t('professionalAvailabilityList.parameters.privateAttention')}</th>
              <th>{t('professionalAvailabilityList.parameters.overshifts')}</th>
              <th>{t('professionalAvailabilityList.parameters.visitDuration')}</th>
              <th>{t('professionalAvailabilityList.parameters.firstVisitDuration')}</th>
              <th>{t('professionalAvailabilityList.parameters.attention')}</th>
              <th>{t('professionalAvailabilityList.parameters.telemedicine')}</th>
              <th>{t('professionalAvailabilityList.parameters.frequencyAttention')}</th>
              <th>{t('professionalAvailabilityList.parameters.frequencyAmount')}</th>
              <th>{t('professionalAvailabilityList.parameters.startDate')}</th>
              <th>{t('professionalAvailabilityList.parameters.attendsProtocol')}</th>
              <th>{t('professionalAvailabilityList.parameters.exclusiveProtocol')}</th>
              <th>{t('professionalAvailabilityList.parameters.normalVisitDuration')}</th>
              <th>{t('professionalAvailabilityList.parameters.extendedVisitDuration')}</th>
              <th>{t('professionalAvailabilityList.parameters.availabilityEnabledFrom')}</th>
              <th>{t('professionalAvailabilityList.parameters.availabilityEnabledUntil')}</th>
              <th style={{ minWidth: 150 }}></th>
            </tr>
          </thead>
          <tbody>{onRenderCell}</tbody>
        </table>
      </div>
    ) : (
      <div className="NoData">
        <Separator />
        <label>{t('professionalAvailabilityList.professionalAvailability.availabilityNotDefined')}</label>
      </div>
    );

  const Loading = (
    <div>
      <Spinner
        label={t('professionalAvailabilityList.professionalAvailability.loadingAvailability')}
        ariaLive="assertive"
        labelPosition="top"
      />
    </div>
  );

  const ComponentBar = (
    <>
      <div className="SectionControls spaceBetween">
        <PrimaryButton
          secondaryText={t('professionalAvailabilityList.buttons.addAvailability')}
          onClick={onAddProfessionalAvailabilityEvent}
          text={t('professionalAvailabilityList.buttons.addAvailability')}
          iconProps={ComponentFunctions.AddIcon}
          className="actionButton"
        />
        {filters}

      </div>
      <Separator />
    </>
  );

  const FormContent = (
    <ProfessionalAvailabilityFormComponent
      isOpen={isOpen}
      openPanel={() => openPanel()}
      dismissPanel={() => {
        dismissPanel();
        setSelectedProfessionalAvailability(undefined);
      }}
      Practitioner={props.Practitioner}
      ProfessionalAvailability={selectedProfessionalAvailability}
    />
  );

  return (
    <>
      {ComponentBar}
      {props.isGettingAll ? (
        Loading
      ) : props.gettingAllSuccess ? (
        TenantPriceListGrid
      ) : (
        <>
          <MessageComponent
            message={
              t('professionalAvailabilityList.message.errorMessage')
            }
            subMessage={props.error?.ErrorMessage}
            type={MessageBarType.error}
            itemList={props.error?.Errors}
          />
        </>
      )}
      {FormContent}
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(ProfessionalAvailabilityListComponent as any);
