import {
  BaseButton,
  Button,
  FontSizes,
  IconButton,
  Label,
  MessageBarType,
  PrimaryButton,
  SearchBox,
  Spinner
} from '@fluentui/react';
import { MouseEventHandler, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Provider } from '../../../models/Entities/Provider/Provider';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import { useBoolean } from '@fluentui/react-hooks';
import { ProviderFormComponent } from '../ProviderForm/ProviderFormComponent';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// function
import * as ComponentFunctions from './ProvidersListComponentFunctions';
import { ArrowForwardDownPerson24Regular } from '@fluentui/react-icons';

const ProvidersListComponent = (props: ComponentFunctions.Props) => {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState<string>();
  const [selectedProvider, setSelectedProvider] = useState<Provider | undefined>(undefined);

  // show form control 
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  
  //useTranslation()
  const [t] = useTranslation();

  //events
  const onEditEvent = (provider: Provider) => {
    setSelectedProvider(provider);
    openPanel();
  };

  const onAddEvent = () => {
    openPanel();
  };

  const onCLoseForm = () => {
    dismissPanel();
    setSelectedProvider(undefined);
  }

  const onSearch = (newValue: string | undefined) => {
    setSearchText(newValue);
  };

  const onViewProviderEvent = (contract: any) => {
    navigate('/providers/' + contract.id);
  };

  //effects
  useEffect(() => {
    props.GetAllProviders(1, 1000, undefined);
  }, []);

  // content 
  const Forms = (
    <ProviderFormComponent
      isOpen={isOpen}
      openPanel={openPanel}
      dismissPanel={onCLoseForm}
      Provider={selectedProvider}
    />
  )

  return (
    <>
      <div className="SectionControls">
        <PrimaryButton
          secondaryText={t('providerList.buttons.addSuplier')} 
          onClick={onAddEvent}
          text={t('providerList.buttons.addSuplier')}
          iconProps={ComponentFunctions.AddIcon}
          className="actionButton"
        />
        <SearchBox
          placeholder={t('providerList.search')}
          //onChange={OnSearch}
          underlined={false}
          onChange={(
            event?: React.ChangeEvent<HTMLInputElement> | undefined,
            newValue?: string | undefined
          ) => (onSearch(newValue))}
          onClear={() => onSearch('')}
        />
      </div>
      {props.failOnLoadingAll || props.failOnUpdatingAll && (
        <MessageComponent
          message={
            t('providerList.errorMessage') 
          }
          subMessage={props.error!.ErrorMessage}
          type={MessageBarType.error}
          itemList={props.error!.Errors}
        />
      )}
      {searchText && (
        <MessageComponent
          message={
              'Se estan visualizando los médicos que coinciden con la búsqueda: (' +
            searchText +
            ')'
          }
          type={MessageBarType.info}
        />
      )}
      {props.isLoadedAll && (
        <table className="Table">
          <thead>
            <tr>
              <th></th>
              <th>{t('providerList.props.businessName')}</th>
              <th style={{ width: '150px' }}>{t('providerList.props.options')}</th>
            </tr>
          </thead>
          <tbody>
            {props.Providers.filter((provider: Provider) => searchText ? provider.person?.businessName.toLowerCase().includes(searchText.toLowerCase()) : true).map((item: Provider) => (
              <tr
                key={item.id}
                className="TableBodyRow"
                onClick={() => onViewProviderEvent(item)}
              >
                <td><ArrowForwardDownPerson24Regular style={{fontSize: FontSizes.size32}} /></td>
                <td className="primary">
                  <Label>{item.person?.businessName?.toUpperCase()}</Label>
                </td>
                <td style={{ width: '150px' }}>
                  <IconButton
                    iconProps={ComponentFunctions.EditIcon}
                    title={t('providerList.props.buttons.modifyButton')}
                    ariaLabel="Edit"
                    onClick={(event) => {event.stopPropagation(); onEditEvent(item)}}
                  />
                  <IconButton
                    iconProps={ComponentFunctions.AgreementsIcon}
                    title={t('providerList.props.buttons.agreementsButton')}
                    ariaLabel="AddAgreements"
                    onClick={() => onViewProviderEvent(item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {props.isLoadingAll && (
        <div>
          <Spinner
            label={t('providerList.props.loadingSuppliers')} 
            ariaLive="assertive"
            labelPosition="top"
          />
        </div>
      )}
      {Forms}
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(ProvidersListComponent as any);
