import { useEffect, useState } from 'react';
import { Dropdown, Field, Label, Option, OptionOnSelectData, SelectionEvents } from '@fluentui/react-components';

// own Functions
import * as ownFunctions from './CurrencySelectorFunctions';
import { on } from '@fluentui/react';


export interface IProps {
  errorMessage: string | undefined;
  defaultSelected: string | undefined;
  disabled?: boolean;
  label: string;
  isRequired?: boolean;
  onCurrencyChange: (currency: string) => void;
}

export const CurrencySelectorComponent = (props: IProps) => {

  const [selectedOption, setSelectedOption] = useState<string[]>([props.defaultSelected || 'ARS']);
  const [selectedValue, setSelectedValue] = useState<string | undefined>('Pesos Argentinos');

  useEffect(() => {
     selectedOption && props.onCurrencyChange(selectedOption[0]);
  }, [selectedOption]);

  useEffect(() => {
    setSelectedValue(ownFunctions.currencyOptions.find((option: ownFunctions.CurrencyType) => option.key === props.defaultSelected)?.value);
    setSelectedOption([ownFunctions.currencyOptions.find((option: ownFunctions.CurrencyType) => option.key === props.defaultSelected)?.key || 'ARS']);
  }, [props.defaultSelected, ownFunctions.currencyOptions]);

  return (
    <>
      <Field label={props.label || 'Moneda'} required={props.isRequired}>
        {ownFunctions.currencyOptions && selectedValue && selectedOption && <Dropdown
          aria-labelledby={'cmb_currencySelector'}
          placeholder="Seleccione una moneda"
          defaultValue={selectedValue}
          selectedOptions={selectedOption}
          value={selectedValue}
          onOptionSelect={(event: SelectionEvents, data: OptionOnSelectData) => {
            setSelectedOption(data.selectedOptions);
            setSelectedValue(data.optionText);
          }}
        >
          {ownFunctions.currencyOptions.map((option: ownFunctions.CurrencyType) => (
            <Option text={option.value} value={option.key} key={option.key}>
              {option.value}
            </Option>
          ))}
        </Dropdown>}
      </Field>
    </>
  );
};
