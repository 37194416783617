import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormValidation } from '../../../commons/interfaces/FormValidationCommonInterface';
import { NewAgreementServiceDetail } from '../../../models/Entities/AgreementServices/NewAgreementServiceDetail';
import { AgreementServicesDetail } from '../../../models/Entities/AgreementServices/AgreementServicesDetail';
import {
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  IStackProps,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Modal,
  Stack,
  TextField,
  Toggle,
  Dropdown,
  DefaultButton,
  PrimaryButton,
  Separator,
  Text,
  IDropdownOption,
  Label
} from '@fluentui/react';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Functions
import * as ownFunctions from './AgreementServiceDetailComponentFunctions';

// Styles
import './AgreementServiceDetailsFormStyles.css';
import { MessageComponent } from '../../../commons/component/MessageComponent';

export const AgreementServiceDetailFormComponent = (
  props: ownFunctions.Props
) => {
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();

  // Agreement Service Details data
  const [RequiresApproval, setRequiresApproval] = useState<boolean>(false);
  const [HasStop, setHasStop] = useState<boolean>(false);
  const [MaximumAllowed, setMaximumAllowed] = useState<number>();
  const [MaximumAllowedPerPeriod, setMaximumAllowedPerPeriod] =
    useState<number>();
  const [PeriodType, setPeriodType] = useState<number>();
  const [IsActive, setIsActive] = useState<boolean>(false);
  const [RequiresCopay, setRequiresCopay] = useState<boolean>(false);
  const [CopayComments, setCopayComments] = useState<string>();

  //useTranslation()  
  const [t] = useTranslation();

  // effects
  useEffect(() => {
    if (props.SelectedDetails) {
      setRequiresApproval(props.SelectedDetails.requiresApproval);
      setHasStop(props.SelectedDetails.hasStop);
      setMaximumAllowedPerPeriod(props.SelectedDetails.maximumAllowedPerPeriod);
      setMaximumAllowed(props.SelectedDetails.maximumAllowed);
      setPeriodType(props.SelectedDetails.periodType);
      setRequiresCopay(props.SelectedDetails.requiredCopago);
      setCopayComments(props.SelectedDetails.copagoComments);
      setIsActive(props.SelectedDetails.isActive);
    } else {
      ResetForm();
    }
  }, [props.SelectedDetails]);

  useEffect(() => {
    if (submittedForm && (props.SavingSuccess || props.UpdatingSuccess)) {
      ResetForm();
      props.dismissPanel();
    }
  }, [props.SavingSuccess, props.UpdatingSuccess]);

  const ResetForm = () => {
    setRequiresApproval(false);
    setHasStop(false);
    setMaximumAllowed(undefined);
    setMaximumAllowedPerPeriod(undefined);
    setPeriodType(undefined);
    setRequiresCopay(false);
    setCopayComments(undefined);
    setIsActive(false);
  };

  // form validation

  const ValidateForm = (): boolean => {
    let validationResult: boolean = true;

    let FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    if (HasStop === undefined) {
      validationResult = false;
      FormValidation.errors.push(t('agreementServiceForm.parameters.hasStopError'));
    }

    if (
      HasStop &&
      MaximumAllowedPerPeriod === undefined &&
      MaximumAllowed === undefined
    ) {
      validationResult = false;
      FormValidation.errors.push(t('agreementServiceForm.parameters.hasStopErrorService'));
    }

    if (HasStop && PeriodType === undefined) {
      validationResult = false;
      FormValidation.errors.push(t('agreementServiceForm.parameter.hasStopErrorPeriod'));
    }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  // events

  const HandleSubmitForm = () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      let CurrentValues: AgreementServicesDetail | NewAgreementServiceDetail;
      if (props.SelectedDetails) {
        CurrentValues = {
          agreementServiceId: props.SelectedDetails.agreementServiceId,
          id: props.SelectedDetails.id,
          insurancePlanReferenceName:
            props.SelectedDetails.insurancePlanReferenceName,
          insurancePlanId: props.SelectedDetails.insurancePlanId,
          requiresApproval: RequiresApproval,
          hasStop: HasStop,
          maximumAllowed: MaximumAllowed,
          maximumAllowedPerPeriod: MaximumAllowedPerPeriod,
          periodType: PeriodType,
          requiredCopago: RequiresCopay,
          copagoComments: CopayComments,
          isActive: IsActive
        };
        props.UpdateAgreementServiceDetail(CurrentValues);
      } else {
        CurrentValues = {
          agreementServiceId: props.AgreementService!.id,
          insurancePlanId: props.insurancePlanId!,
          insurancePlanReferenceName: props.insurancePlanReferenceName!,
          requiresApproval: RequiresApproval,
          hasStop: HasStop,
          maximumAllowed: MaximumAllowed,
          maximumAllowedPerPeriod: MaximumAllowedPerPeriod,
          periodType: PeriodType,
          requiredCopago: RequiresCopay,
          copagoComments: CopayComments,
          isActive: IsActive
        };
        props.AddAgreementServiceDetail(
          CurrentValues as NewAgreementServiceDetail
        );
      }
    }
  };

  const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
  };

  // form fields
  const formFields = (
    <Stack {...columnProps}>
        <Toggle
          label= {t('agreementServiceForm.formFields.requiredAutorization')}
          onText={t('agreementServiceForm.formFields.onTextAutorization')}
          offText={t('agreementServiceForm.formFields.offTextAutorization')}
          onChange={(
            event: React.MouseEvent<HTMLElement, MouseEvent>,
            checked?: boolean | undefined
          ) => checked !== undefined && setRequiresApproval(checked!)}
          defaultChecked={RequiresApproval}
        />
      <Toggle
        label={t('agreementServiceForm.formFields.stopCoverage')}
        onText={t('agreementServiceForm.formFields.onTextStopCoverage')}
        offText={t('agreementServiceForm.formFields.offTextStopCoverage')}
        onChange={(
          event: React.MouseEvent<HTMLElement, MouseEvent>,
          checked?: boolean | undefined
        ) => checked !== undefined && setHasStop(checked!)}
        defaultChecked={HasStop}
      />
      {HasStop && (
        <>
          <TextField
            label={t('agreementServiceForm.textFields.maxAmount')}
            name="txt_MaximumAllowed"
            type="number"
            required
            autoComplete="off"
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string | undefined
            ) => setMaximumAllowed(newValue ? Number(newValue) : undefined)}
            errorMessage={
              submittedForm === true && HasStop && MaximumAllowed === undefined
                ? t('agreementServiceForm.textFields.requiredField')
                : undefined
            }
            value={MaximumAllowed?.toString() || ''}
          />
          <TextField
            label={t('agreementServiceForm.textFields.amountPeriod')}
            name="txt_MaximumAllowedPerPeriod"
            type="number"
            required
            autoComplete="off"
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string | undefined
            ) =>
              setMaximumAllowedPerPeriod(
                newValue ? Number(newValue) : undefined
              )
            }
            errorMessage={
              submittedForm === true &&
              HasStop &&
              MaximumAllowedPerPeriod === undefined
                ? t('agreementServiceForm.textFields.requiredField')
                : undefined
            }
            value={MaximumAllowedPerPeriod?.toString() || ''}
          />
          <Dropdown
            placeholder= {t('agreementServiceForm.dropdown.typePeriod')}
            label={t('agreementServiceForm.dropdown.coveragePeriod')}
            options={ownFunctions.PeriodTypeOptions}
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              option?: IDropdownOption | undefined,
              index?: number | undefined
            ) => option !== undefined && setPeriodType(Number(option.key))}
            selectedKey={PeriodType?.toString() || undefined}
          />
        </>
      )}
      <Separator />
      <Toggle
        label={t('agreementServiceForm.requires.copayRequiress')}
        onText={t('agreementServiceForm.requires.onTextRequires')}
        offText={t('agreementServiceForm.requires.offTextRequires')}
        onChange={(
          event: React.MouseEvent<HTMLElement, MouseEvent>,
          checked?: boolean | undefined
        ) => checked !== undefined && setRequiresCopay(checked!)}
        defaultChecked={RequiresCopay}
      />
      {RequiresCopay && (
        <TextField
          label={t('agreementServiceForm.requiresCopay.comments')}
          name="txt_CopayComments"
          multiline
          description={t('agreementServiceForm.requiresCopay.description')}
          rows={4}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
          ) => newValue !== undefined && setCopayComments(newValue)}
          value={CopayComments}
        />
      )}
      <Separator />
      <Toggle
        label={t('agreementServiceForm.coverStatus.state')}
        onText={t('agreementServiceForm.coverStatus.stateOn')}
        offText={t('agreementServiceForm.coverStatus.stateOff')}
        onChange={(
          event: React.MouseEvent<HTMLElement, MouseEvent>,
          checked?: boolean | undefined
        ) => checked !== undefined && setIsActive(checked!)}
        defaultChecked={IsActive}
      />
    </Stack>
  );

  // footer
  const onRenderFooterContent = () => {
    return (
      <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
          <Trans i18nKey={'agreementServiceForm.footer.cancelButton'}/>
        </DefaultButton>
        <PrimaryButton
          onClick={HandleSubmitForm}
          styles={buttonStyles}
          className="ConfirmAction"
        >
          <Trans i18nKey={'agreementServiceForm.footer.saveButton'}/>
        </PrimaryButton>
      </div>
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      isModeless={false}
      containerClassName={contentStyles.container}
      closeButtonAriaLabel="Close"
    >
      <div className={contentStyles.header}>
        <span><Trans i18nKey={'agreementServiceForm.footer.informationCover'}/></span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.dismissPanel}
        />
      </div>
      {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {t('agreementServiceForm.footer.checkInformation')}
          <ul>
            {FormValidationStatus?.errors.map(
              (Error: string, index: number) => (
                <li key={index}>{Error}</li>
              )
            )}
          </ul>
        </MessageBar>
      )}
      {(props.failOnSaving || props.failOnUpdating) && (
        <MessageComponent
          message={t('agreementServiceForm.footer.errorRec')}
          type={MessageBarType.error}
          subMessage={props.error.ErrorMessage}
          itemList={props.error.Errors}
        />
      )}
      <div className={contentStyles.body}>
        <div className="PlanDetailsHeader">
          <small>{t('agreementServiceForm.footer.plan')}</small>
          <Label>{props.insurancePlanReferenceName}</Label>
          <Label>
            {props.AgreementService?.medicalService.serviceNameReference}
          </Label>
          <Text>{props.AgreementService?.medicalService.comments}</Text>
        </div>
        <Separator />
        {formFields}
      </div>
      {onRenderFooterContent()}
    </Modal>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
)(AgreementServiceDetailFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px'
  }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};
