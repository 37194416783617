import React from 'react';
import { initializeIcons } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { connect } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import './App.css';
import {
  ApplicationPaths,
  LoginActions,
  LogoutActions
} from './components/api-authorization/ApiAuthorizationConstants';
import { AuthorizeRoute } from './components/api-authorization/AuthorizeRoute';
import { Login } from './components/api-authorization/Login';
import { Logout } from './components/api-authorization/Logout';
import { FooterBarComponent } from './components/FooterBar/FooterBarComponent';
import { SideMenuComponent } from './components/SideMenu/SideMenuComponent';
import { TopBarComponent } from './components/TopBar/TopBarComponent';
import MedicalAgreementsDetailsPage from './pages/MedicalAgreementDetailsPage/AgreementsDetailsPage';
import { AgreementsPage } from './pages/MedicalAgreementDetailsPage/AgreementsPage';
import { MedicalInsurancesPage } from './pages/MedicalInsurances/MedicalInsurancesPage';
import { MedicalServicesPage } from './pages/MedicalServices/MedicalServices.Page';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// Pages
import { PrincipalPage } from './pages/principal/Principal.Page';
import { TenantPriceListDetailsPage } from './pages/TenantPriceLists/TenantPriceListDetailsPage';
import { AppState } from './redux/reducers';
import { useEffect } from 'react';
import MedicalInsuranceDetailPage from './pages/MedicalInsurances/MedicalInsuranceDetailPage';
import { ProtocolsAgreementsPage } from './pages/ProtocolsAgreementsPage/ProtocolsAgreements.Page';
import { SponsorsListPage } from './pages/Sponsors/SponsorsList/SponsorsListPage';
import { ProvidersListPage } from './pages/Providers/ProvidersList/ProvidersListPage';
import { PractitionersListPage } from './pages/Practitioners/PractitionerList/PractitionerListPage';
import PractitionerDetailsPage from './pages/Practitioners/PractitionerDetails/PractitionerDetailsPage';
import ProviderDetailsPage from './pages/Providers/ProviderDetails/ProviderDetailsPage';
import SponsorDetailsPage from './pages/Sponsors/SponsorDetails/SponsorDetailsPage';
import { ContractListPage } from './pages/Contracts/ContractList.Page/ContractList.Page';
import ContractDetailPage from './pages/Contracts/ContractDetails.Page/ContractDetail.Page';
import { SettlementListPage } from './pages/Settlements/SettlementsList/SettlementList.page';
import { SettlementDetailsPage } from './pages/Settlements/SettlementDetails/SettlementDetails.Page';
import { ProtocolDetailPage } from './pages/ProtocolsAgreementsPage/ProtocolDetails/ProtocolDetails.Page';
import CallbackPage from './components/OidcAuth/Callback/CallbackPage.component';
import SignOutCallbackPage from './components/OidcAuth/SignOut/SignOutPage.Component';
import CashFlowGeneralPage from './pages/CashFlow/CashFlow.General.Page';
import i18n from './i18n';

// store
import * as UserSettingStore from './redux/middleware/UserMiddleware';
import * as LanguajesStore from './redux/middleware/LanguajeMiddleware';



initializeIcons();

type LngRet = { [lng: string]: { nativeName: string; }; }

export const App = () => {
  //const navigate = useNavigate();

  const userState = useSelector((state: AppState) => state.oidc);

  const LanguajeState = useSelector((state: AppState) => state.UserSettings?.CurrentUser?.UserLanguaje);

  const settingsState = useSelector((state: AppState) => state.Configuration);

  useEffect(() => {
    //dispatch(LanguajesStore.actionCreators.GetAllLanguajes());
    if (LanguajeState) {
      i18n.changeLanguage(LanguajeState.code);
    }
  }, [LanguajeState]);

  if (settingsState?.isLoading) {
    return <></>;
  }

  Sentry.init({
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],
    tracesSampleRate: 1.0
  });

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <div className='masterContainer'>
      {/* <WithAuth requiredPermission={['MedicalRecords.Create']}>
        <SessionTimeout isAuthenticated={true} logOut={handleLogOut} />
      </WithAuth> */}
      {userState?.user?.expired === false && <SideMenuComponent />}
        <div className="pageContent">
        {userState?.user?.expired === false &&<TopBarComponent />} 
          <div className="content">
            <SentryRoutes>
              <Route
                path="/"
                element={<AuthorizeRoute component={PrincipalPage} />}
              />
              <Route path="/callback" element={<CallbackPage />} />
              <Route
                path="/services"
                element={<AuthorizeRoute component={MedicalServicesPage} />}
              />
              <Route
                path="/insurances"
                element={<AuthorizeRoute component={MedicalInsurancesPage} />}
              />
              <Route
                path="/insurances/:MedicalInsuranceId"
                element={
                  <AuthorizeRoute component={MedicalInsuranceDetailPage} />
                }
              />
              <Route
                path="/insurances/:MedicalInsuranceId/agreements"
                element={<AuthorizeRoute component={AgreementsPage} />}
              />
              <Route
                path="/insurances/:MedicalInsuranceId/agreements/:AgreementId"
                element={
                  <AuthorizeRoute component={MedicalAgreementsDetailsPage} />
                }
              />
              <Route
                path="/tenantpricelist/:TenantPriceListId"
                element={
                  <AuthorizeRoute component={TenantPriceListDetailsPage} />
                }
              />
              <Route
                path="/protocolsagreements/"
                element={<AuthorizeRoute component={ProtocolsAgreementsPage} />}
              />
              <Route
                path="/protocolsagreements/:ProtocolId"
                element={<AuthorizeRoute component={ProtocolDetailPage} />}
              />
              <Route
                path="/providers/"
                element={<AuthorizeRoute component={ProvidersListPage} />}
              />
              <Route
                path="/providers/:ProviderId"
                element={<AuthorizeRoute component={ProviderDetailsPage} />}
              />
              <Route
                path="/sponsors/"
                element={<AuthorizeRoute component={SponsorsListPage} />}
              />
              <Route
                path="/sponsors/:SponsorId"
                element={<AuthorizeRoute component={SponsorDetailsPage} />}
              />
              <Route
                path="/practitioners"
                element={<AuthorizeRoute component={PractitionersListPage} />}
              />
              <Route
                path="/practitioners/:PractitionerId"
                element={<AuthorizeRoute component={PractitionerDetailsPage} />}
              />
              <Route
                path="/contracts"
                element={<AuthorizeRoute component={ContractListPage} />}
              />
              <Route
                path="/contracts/:ContractId"
                element={<AuthorizeRoute component={ContractDetailPage} />}
              />
              <Route
                path="/settlements/"
                element={<AuthorizeRoute component={SettlementListPage} />}
              />
              <Route
                path="/settlements/:SettlementId"
                element={<AuthorizeRoute component={SettlementDetailsPage} />}
              />
              <Route
                path={"/cashflow"}
                element={<AuthorizeRoute component={CashFlowGeneralPage} />}
              />
              <Route
                path={ApplicationPaths.Login}
                element={<Login action={LoginActions.Login}></Login>}
              />
              <Route
                path={ApplicationPaths.LoginFailed}
                element={<Login action={LoginActions.LoginFailed}></Login>}
              />
              <Route
                path={ApplicationPaths.LoginCallback}
                element={<CallbackPage />}
              />
              <Route
                path={ApplicationPaths.Profile}
                element={<Login action={LoginActions.Profile}></Login>}
              />
              <Route
                path={ApplicationPaths.Register}
                element={<Login action={LoginActions.Register}></Login>}
              />
              <Route
                path={ApplicationPaths.LogOut}
                element={<SignOutCallbackPage  />}
              />
              <Route
                path={ApplicationPaths.LogOutCallback}
                element={<AuthorizeRoute component={PrincipalPage} />}
              />
              <Route
                path={ApplicationPaths.LoggedOut}
                element={<Logout action={LogoutActions.LoggedOut}></Logout>}
              />
            </SentryRoutes>
          </div>
          <FooterBarComponent />
        </div>
    </div>
  );
};

const mapDispatchToProps = {
  ...LanguajesStore.actionCreators,
  ...UserSettingStore.ActionCreators
};

export default connect(
  null,
  mapDispatchToProps
)(App as any);


