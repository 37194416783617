
//stores 
import * as ContractsStore from '../../../../redux/middleware/ContractMiddleware';
import * as ContractStageStore from '../../../../redux/middleware/ContractStageMiddleware';
import * as ContractConcept from '../../../../redux/middleware/ContractConceptMiddleware';
import * as ContractAddendumStore from '../../../../redux/middleware/ContractAddendum.Middleware';
import * as ContractConceptScheduleStore from '../../../../redux/middleware/ContractConceptPaymentScheduleMiddleware';

// models
import { AppState } from '../../../../redux/reducers';
import { Contract } from '../../../../models/Entities/Contracts/Contract';
import { ContractStage } from '../../../../models/Entities/Contracts/ContractStage';
import { ContractConceptPaymentSchedule } from '../../../../models/Entities/Contracts/ContractConceptPaymentSchedule';

//#region Props

interface IProps {
  ContractId: number;
  ContractAddendumId: number;
  ContractConceptId? : number | undefined;
  ShowInputValues: boolean;
  ConceptSaved: boolean;
  OnAllSaved: (ready: boolean) => void;
}

interface ConnectedProps {
  Contract: Contract | undefined;
  Stages: ContractStage[];
  PaymentSchedules: ContractConceptPaymentSchedule[];
  
}

interface MedicalServicesDispatchProps {
    GetContractById: typeof ContractsStore.actionCreators.GetContractById;
    GetContractAddendumById: typeof ContractAddendumStore.actionCreators.GetContractAddendumById;
    GetContractConceptById: typeof ContractConcept.actionCreators.GetContractConceptById;
    GetAllContractStagesByAddendumId: typeof ContractStageStore.actionCreators.GetAllContractStagesByAddendumId;
    GetAllContractsConceptPaymentSchedule: typeof ContractConceptScheduleStore.actionCreators.GetAllContractsConceptPaymentSchedule;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const CurrentContract = state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId ) || undefined;
    const CurrentAddendum = CurrentContract?.addendums?.find((item: any) => item.id === ownProps.ContractAddendumId) || undefined;
    const CurrentConcept = CurrentAddendum?.concepts?.find((item: any) => item.id === ownProps.ContractConceptId) || undefined;
    const CurrentStages = CurrentAddendum?.stages || undefined;
    const CurrentConceptPaymentSchedules = CurrentConcept?.paymentSchedule || undefined;

    return {
        Contract: CurrentContract,
        Stages: CurrentStages,
        PaymentSchedules: CurrentConceptPaymentSchedules
    }
};

export const mapDispatchToProps = {
    ...ContractsStore.actionCreators,
    ...ContractAddendumStore.actionCreators,
    ...ContractStageStore.actionCreators,
    ...ContractConcept.actionCreators,
    ...ContractConceptScheduleStore.actionCreators
};

//#endregion Props