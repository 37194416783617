import { IconButton, IIconProps, Label, MessageBarType, SearchBox, Spinner, Toggle, Text, FontWeights, Separator, FontSizes, FontIcon } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';

import { Protocol } from '../../../models/Entities/Protocols/Protocol';
import { MessageComponent } from '../../../commons/component/MessageComponent';

// functions
import * as ComponentFunctions from './ProtocolsList.Function';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// Styles
import './ProtocolsList.Component.css';
import { ContractAssociationFormComponent } from '../../Contracts/Protocols/ContractAssociationForm/ContractAssociationFormComponent';
import { PractitionerDetailsMini } from '../../../commons/component/Practitioner/PractitionerDetails/PractitionerDetails.Mini';
import { ContractAssociationForm } from '../../Contracts/ContractAssociationForm/ContractAssociationForm.Component';
import { Contract } from '../../../models/Entities/Contracts/Contract';

const ProtocolsListComponent = (props: ComponentFunctions.Props) => {

  const [selectedProtocol, setSelectedProtocol] = useState<Protocol | undefined>(undefined);

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();

  //useTranslation()
  const [t] = useTranslation();

  useEffect(() => {
    props.GetAllMedicalProtocols();
    props.GetAllPractitioners(0,1000,undefined);
  }, []);

  //events
 const onViewDetailsEvent = (protocol: Protocol) => {
    navigate(`/protocolsagreements/${protocol.id}`);
  };


  const onAssociateContract = (protocol: Protocol) => {
    setSelectedProtocol(protocol);
    openPanel();
  };

  const onSearch = (newValue: string) => {
    setSearchText(newValue);
  };

  const RequestPractitioner = (practitionerId: string) => {
    console.log(props.GetPractiontioner(practitionerId));
  }

  const HandleContractAssociation = (contract: Contract) => {
    console.log(contract.id);
  }


  //#region Subcontent definition

  // component searchbox
  const Searchbox = <SearchBox
    placeholder={t('protocolList.search')}
    //onChange={OnSearch}
    underlined={false}
    onChange={(
      event?: React.ChangeEvent<HTMLInputElement> | undefined,
      newValue?: string | undefined
    ) => (newValue ? onSearch(newValue) : undefined)}
    onClear={() => onSearch('')}
  />

  // Principal error message
  const PrincipalErrorMessage = props.failOnLoading && (
    <MessageComponent
      message={
        t('protocolList.errorMessage') 
      }
      subMessage={props.error?.ErrorMessage || ''}
      type={MessageBarType.error}
      itemList={props.error?.Errors || []}
    />
  );

  // Loading
  const Loading = props.isLoading && (
    <div>
      <Spinner
        label={t('protocolList.loadingProtocols')} 
        ariaLive="assertive"
        labelPosition="top"
      />
    </div>
  );

  // Table of content
  const TableOfContent = props.isLoadingSuccessfully && (
  <table className="Table">
    <thead>
      <tr>
        <th></th>
        <th style={{maxWidth:'5em'}}>{t('protocolList.parameters.protocol')}</th>
        <th>{t('protocolList.parameters.principalInvestigator')}</th>
        <th>{t('protocolList.parameters.specialty')}</th>
        <th>{t('protocolList.parameters.options')}</th>
      </tr>
    </thead>
    <tbody>
      {props.MedicalProtocols.length > 0 ? props.MedicalProtocols.map((item: Protocol) =>
        item.title.toLowerCase().includes(searchText.toLowerCase()) ? (
          <tr
            key={item.id}
            className="TableBodyRow"
            onClick={() => onViewDetailsEvent(item)}
          >
            <td><FontIcon iconName='WebAppBuilderFragment' style={{ fontSize: FontSizes.size24, padding: 10 }} /></td>
            <td className="primary">
              <Label style={{fontWeight : FontWeights.bold}}>{item.title.toUpperCase()}</Label>
              <Text>{item.fullTitle}</Text>
              <Label>{item.status.title}</Label>
            </td>
            <td><PractitionerDetailsMini practitioner={props.GetPractiontioner(item.principalInvestigatorId)} /></td>
            <td>{item.specialty.description}</td>
            <td>
              <IconButton
                iconProps = {ComponentFunctions.LinkContractIcon}
                title={t('protocolList.button.protocolInformation')} 
                ariaLabel="viewProtocol"
                onClick={() => onViewDetailsEvent(item)}
              />
            </td>
          </tr>
        ) : null
      ): <tr><td>{t('protocolList.parameters.noData')}</td></tr>}
      </tbody>
  </table>
  );
    
 //#endregion
  
 return (
    <>
      <div className='SectionControls'>
        {Searchbox}
      </div>
      <Separator />
      {PrincipalErrorMessage}
      {TableOfContent}
      {Loading}
      <ContractAssociationForm 
            isOpen={isOpen} 
            openPanel={openPanel} 
            dismissPanel={dismissPanel} 
            ContractId={0}
            AssociateContract={HandleContractAssociation}
        />
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(ProtocolsListComponent as any);
