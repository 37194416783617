import {
    DefaultButton,
    FontWeights,
    getTheme,
    IconButton,
    IIconProps,
    ISearchBoxProps,
    IStackProps,
    Label,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Modal,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    PrimaryButton,
    SearchBox,
    Separator,
    TextField,
    Toggle,
    Text,
    FontIcon,
    Checkbox,
    FocusTrapZone,
    CommandBar,
    FontSizes
} from '@fluentui/react';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ErrorMessageComponent } from '../../Error/ErrorMessageComponent';
import { SettlementDetail } from '../../../models/Entities/SettlementDetails/SettlementDetail';
import SettlementDetailForm from '../SettlementDetailForm/SettlementDetailForm.Component';
import SettlementDetailFormReview from '../SettlementDetailsFormReview/SettlementDetailsFormReview.Component';
import MOMENT from 'react-moment';

// components
import SettlementDetailItem from '../SettlementDetailItem/SettlementDetailItem.Component';

// component functions
import * as ComponentFunctions from './SettlementDetailsList.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './SettlementDetailsList.Styles.css';


interface FormValidation {
    success: boolean;
    errors: string[];
}

export const SettlementDetailsListComponet = (props: ComponentFunctions.Props) => {

    const [submittedForm, setSubmittedForm] = useState<boolean>(false);
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    // statistics
    const [totalDetails, setTotalDetails] = useState<number>(0);
    const [totalItemizable, setTotalItemizable] = useState<number>(0);
    const [totalReembolsable, setTotalReembolsable] = useState<number>(0);
    const [totalExcluded, setTotalExcluded] = useState<number>(0);
    const [totalOk, setTotalOK] = useState<number>(0);
    const [totalForReview, setTotalForReview] = useState<number>(0);

    const [showExcluded, setShowExcluded] = useState<boolean>(true);
    const [showOk, setShowOk] = useState<boolean>(true);
    const [showForReview, setShowForReview] = useState<boolean>(true);
    const [showAllwithoutMark, setShowAllwithoutMark] = useState<boolean>(true);
    const [showItemizable, setShowItemizable] = useState<boolean>(true);
    const [showReembolsable, setShowReembolsable] = useState<boolean>(true);

    const onClickShowWithoutMark = () => {
        setShowAllwithoutMark(!showAllwithoutMark);
    }

    const onClickShowExcluded = () => {
        setShowExcluded(!showExcluded);
    }

    const onClickShowOk = () => {
        setShowOk(!showOk);
    }

    const onClickShowForReview = () => {
        setShowForReview(!showForReview);
    }

    const onClickShowItemizable = () => {
        setShowItemizable(!showItemizable);
    }

    const onClickShowReembolsable = () => {
        setShowReembolsable(!showReembolsable);
    }


    const [SelectedItemIds, setSelectedItemIds] = useState<number[]>([]);
    //useTranslation();
    const [t] = useTranslation();

    const handleClosePanel = () => {
        setShowDetailsForm(false);
    };

    const handleClosePanelReview = () => {
        setShowDetailsFormReview(false);
    };

    const handleEditConcept = (detail: SettlementDetail) => {
        setShowDetailsForm(true);
    };

    const handleShowFormReview = (detail: SettlementDetail) => {
        setShowDetailsFormReview(true);
    }

    const [filterCriteria, setFilterCriteria] = useState<string>('');
    const [showDetailsForm, setShowDetailsForm] = useState<boolean>(false);
    const [showDetailsFormReview, setShowDetailsFormReview] = useState<boolean>(false);

    const OnCheckItem = (itemId: number) => {
        if (SelectedItemIds.includes(itemId)) {
            setSelectedItemIds(SelectedItemIds.filter((id: number) => id !== itemId));
        } else {
            setSelectedItemIds([...SelectedItemIds, itemId]);
        }
    }

    //load necesary data
    useEffect(() => {
        if(props.Settlement?.id) {
            props.GetAllSettlementDetails(props.SettlementId)
        }
        
    }, [props.SettlementId]);

    useEffect(() => {
        if (props.SettlementDetails && props.SettlementDetails.length > 0) {
            setTotalDetails(props.SettlementDetails.filter((detail: SettlementDetail) => !detail.markOk && !detail.markForReview && !detail.isExcluded).length);
            setTotalItemizable(props.SettlementDetails.filter((detail: SettlementDetail) => detail.isItemized).length);
            setTotalReembolsable(props.SettlementDetails.filter((detail: SettlementDetail) => detail.isRefundable).length);
            setTotalExcluded(props.SettlementDetails.filter((detail: SettlementDetail) => detail.isExcluded).length);
            setTotalOK(props.SettlementDetails.filter((detail: SettlementDetail) => detail.markOk).length);
            setTotalForReview(props.SettlementDetails.filter((detail: SettlementDetail) => detail.markForReview).length);
        }
    }, [props.SettlementDetails]);

    //useEffect(() => {
    //    props.GetAllSettlementDetails(props.SettlementId)
    //}, [props.Settlement !== undefined, props.Settlement?.details === undefined]);


    const HandleCancelForm = () => {
        setFilterCriteria('');
        props.dismissPanel();
    };

    const HandleChangeExclusionStatus = (detail: SettlementDetail) => {
        props.ChangeExcludeStatus(detail.id, !detail.isExcluded);
    }

    const HandleMarkOkStatus = (detail: SettlementDetail) => {
        props.MarkOk(detail.id, !detail.markOk);
    }

    // footer
    const onRenderFooterContent = () => {
        return (
            <div className={contentStyles.footer}>
                <DefaultButton onClick={HandleCancelForm} className="OtherOption">
                    <Trans i18nKey={'settlementDetailList.footer.cancelButton'} />
                </DefaultButton>
            </div>
        );
    };

    // content

    const Statistics = (
        <>
            <div className='Statistics'>
                <TooltipHost
                    content="Pendientes de revisión"
                    id={'Todos'}
                >
                    <DefaultButton
                        className='totalItems'
                        toggle
                        checked={showAllwithoutMark}
                        text={totalDetails.toString()}
                        allowDisabledFocus
                        onClick={onClickShowWithoutMark}
                    />
                </TooltipHost>
                {/* <TooltipHost
                    content="Itemizables"
                    id={'Todos'}
                >
                    <DefaultButton
                        className='itemizableItems'
                        toggle
                        checked={showItemizable}
                        text={totalItemizable.toString()}
                        allowDisabledFocus
                        onClick={onClickShowItemizable}
                    />
                </TooltipHost>
                <TooltipHost
                    content="Rembolsables"
                    id={'Todos'}
                >
                <DefaultButton
                    className='reembolsableItems'
                    toggle
                    checked={showReembolsable}
                    text={totalReembolsable.toString()}
                    allowDisabledFocus
                    onClick={onClickShowReembolsable}
                />
                </TooltipHost> */}
                <TooltipHost
                    content="Excluidos"
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={'Excluidos'}
                >
                    <DefaultButton
                        className='excluedItems'
                        toggle
                        checked={showExcluded}
                        text={totalExcluded.toString()}
                        allowDisabledFocus
                        onClick={onClickShowExcluded}
                    />
                </TooltipHost>
                <TooltipHost
                    content="Verificados"
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={'Verificados'}
                >
                    <DefaultButton
                        className='okItems'
                        toggle
                        checked={showOk}
                        text={totalOk.toString()}
                        allowDisabledFocus
                        onClick={onClickShowOk}
                    />
                </TooltipHost>
                <TooltipHost
                    content="A revisar"
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={'Para revisar'}
                >
                    <DefaultButton
                        className='reviewItems'
                        toggle
                        checked={showForReview}
                        text={totalForReview.toString()}
                        allowDisabledFocus
                        onClick={onClickShowForReview}
                    />
                </TooltipHost>
            </div>
        </>
    );

    const MultiSelectOptions = SelectedItemIds.length > 0 && (
        <>
            <div className='menurow'>
                <Label className='ItemCounter'>Seleccinados: {SelectedItemIds.length}</Label>
                <Separator vertical />
                <FocusTrapZone disabled={true}>
                    <CommandBar
                        items={ComponentFunctions._items}
                        overflowItems={ComponentFunctions._overflowItems}
                        overflowButtonProps={ComponentFunctions.overflowProps}
                        farItems={ComponentFunctions._farItems}
                        ariaLabel="Inbox actions"
                        primaryGroupAriaLabel="Email actions"
                        farItemsGroupAriaLabel="More actions"
                    />
                </FocusTrapZone>

            </div>
        </>
    )

    const Forms = (
        <>
            <SettlementDetailForm
                isOpen={showDetailsForm}
                dismissPanel={handleClosePanel}
                openPanel={() => undefined}
                SettlementId={props.SettlementId}
                SettlementResumeId={props.SettlementResumeId}
                SettlementDetailId={undefined}
            />
            {/* <SettlementDetailFormReview
                isOpen={showDetailsFormReview}
                dismissPanel={handleClosePanelReview}
                openPanel={() => undefined}
                SettlementId={props.SettlementId}
                SettlementDetailId={undefined}
            /> */}
        </>
    )

    const Controls = (
        <>
            <div className='SectionControls'>
                <PrimaryButton
                    onClick={() => setShowDetailsForm(true)}
                    className="actionButton"
                    text={t('settlementDetailList.buttons.addConcept')}
                />
                <SearchBox
                    placeholder={t('settlementDetailList.search.searchPatient')}
                    onChange={(event?: React.ChangeEvent<HTMLInputElement> | undefined,
                        newValue?: string | undefined
                    ) => (newValue ? setFilterCriteria(newValue) : undefined)
                    }
                    value={filterCriteria}
                    onClear={() => setFilterCriteria('')}
                />
            </div>
            <Separator />
        </>
    );

    const NoData = (
        <div>
            <MessageBar
                messageBarType={MessageBarType.info}
                isMultiline={false}
                onDismiss={() => undefined}
                dismissButtonAriaLabel="Close"
            >
                {props.SettlementResumeId ? t('settlementDetailList.parameters.noData') : 'ingrese un criterio de busqueda'}
            </MessageBar>
        </div>
    );

    // Table of content
    const TableOfContent = props.isLoadingSuccessfully && (props.SettlementResumeId || filterCriteria.length > 0) && (
        <>
            {(props.SettlementDetails && props.SettlementDetails.length) && props.SettlementDetails?.length > 0 ? props.SettlementDetails?.filter((detail: SettlementDetail) =>
                detail.patientNumberInProtocol?.toLowerCase().includes(filterCriteria.toLowerCase()) && (
                    showAllwithoutMark && detail.isExcluded === false && detail.markOk === false && detail.markForReview === false ? true : false ||
                        showExcluded && detail.isExcluded === true ? true : false ||
                            showOk && detail.markOk === true ? true : false ||
                                showForReview && detail.markForReview === true ? true : false //||
                    // showItemizable && detail.isItemisable === true ? true : false ||
                    //     showReembolsable && detail.isReembolsable === true ? true : false
                )

            )?.map((item: SettlementDetail) =>
            (
                <SettlementDetailItem SettlementId={props.SettlementId} SettlementDetailId={item.id} />
            )
            ) : null}
        </>
    );
    const TableOfContentItemizable = props.isLoadingSuccessfully && (
        <>
            {(props.SettlementDetails && props.SettlementDetails.length) && props.SettlementDetails.length > 0 ? props.SettlementDetails?.filter((detail: SettlementDetail) => detail.isItemized && detail.patientNumberInProtocol?.toLowerCase().includes(filterCriteria.toLowerCase()))?.map((item: SettlementDetail) =>
            (
                <SettlementDetailItem SettlementId={props.SettlementId} SettlementDetailId={item.id} />
            )
            ) : null}
        </>
    );
    const TableOfContentRembolzable = props.isLoadingSuccessfully && (
        <>
                {(props.SettlementDetails && props.SettlementDetails.length) && props.SettlementDetails.length > 0 ? props.SettlementDetails?.filter((detail: SettlementDetail) => detail.isRefundable && detail.patientNumberInProtocol?.toLowerCase().includes(filterCriteria.toLowerCase()))?.map((item: SettlementDetail) =>
                (
                    <SettlementDetailItem SettlementId={props.SettlementId} SettlementDetailId={item.id} />
                )
                ) : null}
            </>
    );
    const TableOfContentExcluided = props.isLoadingSuccessfully && (
       <>
                {(props.SettlementDetails && props.SettlementDetails.length) && props.SettlementDetails.length > 0 ? props.SettlementDetails?.filter((detail: SettlementDetail) => detail.isExcluded && detail.patientNumberInProtocol?.toLowerCase().includes(filterCriteria.toLowerCase()))?.map((item: SettlementDetail) =>
                (
                    <SettlementDetailItem SettlementId={props.SettlementId} SettlementDetailId={item.id} />
                )
                ) : null}
           </>
    );


    return (
        <Panel
            isOpen={props.isOpen}
            type={PanelType.large}
            headerText={t('settlementDetailList.panel.detailsLiquidatedItems')}
            onDismiss={props.dismissPanel}
            closeButtonAriaLabel="Close"
            onRenderFooter={onRenderFooterContent}
            isFooterAtBottom={true}
        >
            <div className={contentStyles.body}>
                {props.failOnLoading &&
                    props.error && (
                        <ErrorMessageComponent Error={props.error} />
                    )}
                {Controls}
                <div className='DetailsListContent'>
                    <div>
                        {Statistics}
                        <Pivot>
                            <PivotItem headerText={t('settlementDetailList.panel.summary')}>
                                {MultiSelectOptions}
                                {props.SettlementDetails?.some && TableOfContent}
                            </PivotItem>
                            <PivotItem headerText={t('settlementDetailList.panel.itemizable')}>
                                {MultiSelectOptions}
                                {props.SettlementDetails?.some && TableOfContentItemizable}
                            </PivotItem>
                            <PivotItem headerText={t('settlementDetailList.panel.rembolzable')}>
                                {MultiSelectOptions}
                                {props.SettlementDetails?.some && TableOfContentRembolzable}
                            </PivotItem>
                            <PivotItem headerText={t('settlementDetailList.panel.excluded')}>
                                {MultiSelectOptions}
                                {props.SettlementDetails?.some && TableOfContentExcluided}
                            </PivotItem>
                        </Pivot>
                    </div>
                    {/* {props.SettlementDetails && NoData} */}
                    <div>
                        {Forms}
                    </div>
                </div>
            </div>
        </Panel>
    );
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementDetailsListComponet as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 }
    // styles: {root: {width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderRadius: 10
    },
    header: [
        theme.fonts.mediumPlus,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '0px 12px 12px 12px',
            backgroundColor: 'lightgray',
            borderRadius: '10px 10px 0 0'
        }
    ],
    body: {
        flex: '4 4 auto',
        overflowY: 'auto',
        selectors: {
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 }
        },
        padding: '0 1em 0 0',
        maxHeight: '85vh'
    },
    footer: {
        //flex: '4 4 auto',
        padding: '0 24px 24px 24px'
    }
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};