import { IResult } from '../models/HttpResult';


//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { Person } from '../models/Entities/Person/Person';
import { NewPerson } from '../models/Entities/Person/NewPerson';


const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'persons?PageNumber=1&PageSize=100';


// Get all persons
export const GetPersons = async (
  token: string): Promise<Person[]> => {

  const RequestURL: string = BaseURL;

  try {
    const Response: Person[] = await fetch(RequestURL,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Person[]>>;
        }
      })
      .then((persons: IResult<Person[]>) => {
        return persons.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

// Get person by id
export const GetPersonById = async (
    personId: number
    , token: string): Promise<Person> => {

    const RequestURL: string = BaseURL + '/' + personId;

    try {
        const Response: Person = await fetch(RequestURL,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                } else {
                    return response.json() as Promise<IResult<Person>>;
                }
            })
            .then((person: IResult<Person>) => {
                return person.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Create person
export const CreatePerson = async (
    newPerson: NewPerson
    , token: string): Promise<Person> => {

    const RequestURL: string = BaseURL;

    try {
        const Response: Person = await fetch(RequestURL,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(newPerson)
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                } else {
                    return response.json() as Promise<IResult<Person>>;
                }
            })
            .then((person: IResult<Person>) => {
                return person.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Update person

export const UpdatePerson = async (
    person: Person
    , token: string): Promise<Person> => {

    const RequestURL: string = BaseURL + '/' + person.id;

    try {
        const Response: Person = await fetch(RequestURL,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: JSON.stringify(person)
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                } else {
                    return response.json() as Promise<IResult<Person>>;
                }
            })
            .then((person: IResult<Person>) => {
                return person.data;
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Delete person
export const DeletePerson = async (
    personId: number
    , token: string): Promise<void> => {

    const RequestURL: string = BaseURL + '/' + personId;

    try {
        const Response: void = await fetch(RequestURL,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

// Fix person identifiers
export const FixPersonIdentifiers = async (
    token: string): Promise<void> => {

    const RequestURL: string = BaseURL + '/fix-identifiers';

    try {
        const Response: void = await fetch(RequestURL,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
            })
            .catch((error: any) =>
                error.text().then((body: any) => {
                    throw Functions.HttpErrorHandler(body, error);
                })
            );

        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};