import { StageCycle } from "../../models/Entities/Contracts/StageCycle";
import { CommonState } from "../common/state/commonState";

export interface ContractStageCycleState extends CommonState<StageCycle> {
    // updating all
    isUpdatingAll: boolean;
    isUpdatingAllSuccess: boolean|undefined;
    FailUpdatingAll: boolean;
}

export const ContractStageCycleUnloadState: any = {
    // loading all
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    FailGettingAll: false,

    // updating all
    isUpdatingAll: false,
    isUpdatingAllSuccess: undefined,
    FailUpdatingAll: false,

    // loading one
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    FailGettingOne: false,

    // saving
    isSaving: false,
    isSavingSuccess: undefined,
    FailSaving: false,
    
    // adding
    isAdding: false,
    isAddingSuccess: undefined,
    FailAdding: false,

    // deleting
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    FailDeletingOne: false, 
};