import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractStage } from "../../../../models/Entities/Contracts/ContractStage";
import { StageCycle } from "../../../../models/Entities/Contracts/StageCycle";
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractStageStore from '../../../../redux/middleware/ContractStageMiddleware';
import * as ContracStore from '../../../../redux/middleware/ContractMiddleware';

// common functions
import * as Functions from '../../../../functions/common'

//State
import { AppState } from "../../../../redux/reducers";
import { ContractAddendum } from "../../../../models/Entities/Contracts/ContractAddendum";



interface IProps {
    ContractId: number;
    ContractAddendumId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isLoadingSuccessfully: boolean;
    isDeleting: boolean;
    isDeletedSuccessfully: boolean;
    failOnLoading: boolean;
    failOnDeleting: boolean;
    error: ErrorType | undefined;
    Contract: Contract | undefined;
    Stages: ContractStage[];
    Cycles: StageCycle[];
  }
  
  interface DispatchProps {
    GetAllContractsStage: typeof ContractStageStore.actionCreators.GetAllContractsStage;
    GetAllContractStagesByAddendumId: typeof ContractStageStore.actionCreators.GetAllContractStagesByAddendumId;
    GetContractStageById: typeof ContractStageStore.actionCreators.GetContractStageById;
    GetContractById: typeof ContracStore.actionCreators.GetContractById;
    DeleteContractStage: typeof ContractStageStore.actionCreators.DeleteContractStage;
  }
  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const CurrentAddendum = state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId )?.addendums?.find((item: ContractAddendum) => item.id === ownProps.ContractAddendumId);

    return {
      isLoading: CurrentAddendum?.isGettingContractStages,
      isLoadingSuccessfully: state.ContractStage?.successLoadingAll,
      failOnLoading: state.ContractStage?.failOnLoadingAll,
      AddRangeFinalized: state.ContractStage?.isAddingFinishedSuccessfully,
      isDeleting: state.ContractStage?.isDeletingOne,
      isDeletedSuccessfully: state.ContractStage?.successDeletingOne,
      failOnDeleting: state.ContractStage?.failOnDeletingOne,
      error: state.ContractStage?.error,
      Contract: ownProps.ContractId && state.Contract && state.Contract?.list ? state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId ) : undefined,
      Stages: CurrentAddendum?.stages?.sort(Functions.DynamicSortNumeric('stageOrder')) || [],
      Cycles: ownProps.ContractId && state.Contract && state.Contract?.list ? state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId )?.cycles: []
    }
  };
  
  export const mapDispatchToProps = {
    ...ContractStageStore.actionCreators,
    ...ContracStore.actionCreators
  };