import { ITag, Label, TagPicker } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ContractConcept } from '../../../../models/Entities/Contracts/ContractConcept';

// own functions
import * as ownFunctions from './ContractConceptSelector.Functions';

const ContractConceptSelectorComponent = (props: ownFunctions.Props) => {

  useEffect(() => {
    props.GetAllContractsConcept(props.ContractId);
  }, []);
  
  const onChangeIdTextFieldValue = (items?: ITag[] | undefined) => {
    let ContractConceptsComboSelected: ContractConcept[] = [];
    if (items && items.length > 0) {
      items.forEach(async (item: ITag) => {
        const ContractConceptId = Number(item.key);
        const concept: ContractConcept =
          ownFunctions.GetContractConceptById(ContractConceptId, props.ContractConcepts);

          ContractConceptsComboSelected = [
          ...ContractConceptsComboSelected,
          concept
        ];
        setInternalContratsSelected(ContractConceptsComboSelected);
        setContracConceptsOptionsSelected(items);
      });
    } else {
        setInternalContratsSelected([]);
        setContracConceptsOptionsSelected([]);
    }
  };

  const [ContractOptions, setContractOptions] = useState<ITag[]>();
  const [ContractsOptionsSelected, setContracConceptsOptionsSelected] =
    useState<ITag[]>();

  const [InternalContractsSelected, setInternalContratsSelected] =
    useState<ContractConcept[]>([]);

  useEffect(() => {
    var options = ownFunctions.GetContractConceptsOptions(props.ContractConcepts);
    setContractOptions(options);
  }, [props.ContractConcepts]);

  useEffect(() => {
    props.onSelectedContractConcept(InternalContractsSelected);
  }, [InternalContractsSelected]);

  useEffect(() => {
    if (props.ContractConceptsSelected && ContractOptions) {
      const auxContractsSelected = ContractOptions.filter(
        (option: ITag) =>
          option.key ===
          props.ContractConceptsSelected?.find((id: number) => id === option.key)
      );
      setContracConceptsOptionsSelected(auxContractsSelected);
      var concept = ownFunctions.GetContractConceptById(props.ContractConceptsSelected[0], props.ContractConcepts);
      setInternalContratsSelected([concept]);
    }
  }, [ContractOptions]);

  const filterSuggestedContractConceptServices = async (
    filterText: string,
    tagList: ITag[] | undefined
  ): Promise<ITag[]> => {
    return filterText
      ? ContractOptions?.filter(
          (tag) =>
            tag.name.toLowerCase().includes(filterText.toLowerCase()) &&
            !ownFunctions.listContainsTagList(tag, tagList)
        ) || []
      : [];
  };

  return (
    <>
      <Label htmlFor="SnomedOption">Seleccione un Concepto </Label>
      <TagPicker
        removeButtonAriaLabel="Eliminar"
        selectionAriaLabel="Seleccione un concepto"
        onResolveSuggestions={filterSuggestedContractConceptServices}
        onEmptyResolveSuggestions={(selectedItems?: ITag[] | undefined) => ContractOptions || []}
        getTextFromItem={ownFunctions.getTextFromItem}
        pickerSuggestionsProps={ownFunctions.pickerSuggestionsProps}
        itemLimit={props.MultiselectEnable ? undefined : 1}
        onChange={onChangeIdTextFieldValue}
        pickerCalloutProps={{ doNotLayer: false }}
        inputProps={{
          id: 'ConceptsOption'
        }}
        selectedItems={ContractsOptionsSelected}
      />
      {props.noteText && <small>{props.noteText}</small>}
    </>
  );
};

export default connect(
  ownFunctions.mapStateToProps,
  ownFunctions.mapDispatchToProps
) (ContractConceptSelectorComponent as any);
