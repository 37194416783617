import { DefaultButton, FontSizes, Icon, Label, PrimaryButton, Separator, Spinner } from "@fluentui/react"
import { connect } from "react-redux";
import { useBoolean } from '@fluentui/react-hooks';

import { CycleItemComponent } from "./CycleItem.Component";
import { StageCycle } from "../../../../models/Entities/Contracts/StageCycle";
import { useEffect, useState } from "react";
import CycleFormComponent from "../CycleForm/CycleForm.Component";

// functions
import * as ComponentFunctions from './CycleList.Functions';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

// styles
import './CycleList.Styles.css';
import { ErrorMessageComponent } from "../../../Error/ErrorMessageComponent";

//image 
const emptyResultImage = '../../../../assets/img/empty_state.gif';

export const CycleListComponent = (props: ComponentFunctions.Props) => {

    // Add or eddit cycle
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

    const [selectedCycle, setSelectedCycle] = useState<StageCycle | undefined>(undefined);

    //useTranslation
    const [t] = useTranslation();

    
    // events
    const handleCycleClosePanel = () => {
        dismissPanel();
    };

    const HandleAddEvent = () => {
        openPanel();
    };

    const HandleDeleteEvent = (cycle: StageCycle) => {
        props.DeleteContractStageCycle(cycle);
    }

    const HandleEditEvent = (cycle: StageCycle) => {
        setSelectedCycle(cycle);
        openPanel();
    }



    // efects   
    useEffect(() => {
        if(props.Contract) {
            props.GetContractStageCyclesByAddendumId(props.ContractId, props.ContractAddendumId);
        }
    }, [props.Contract === undefined]);

    // Loading
    const Loading = props.isLoading && (
        <div>
          <Spinner
            label={t('cycleList.loading.loadingCycles')} 
            ariaLive="assertive"
            labelPosition="top"
          />
        </div>
    );

    const ComponentActions = (
        <>
            <PrimaryButton
                secondaryText={t('cycleList.componentActions.addCycle')}
                onClick={HandleAddEvent}
                text={t('cycleList.componentActions.addCycle')}
                iconProps={{ iconName: 'Add' }}
                className="actionButton"
            />
        </>
    )

    const ErrorMessage = props.failOnLoading && props.error && (
        <ErrorMessageComponent Error={props.error} />
    )

    const NoRegistries = (props.Cycles?.length === 0 || props.Cycles?.length === undefined) && (
        <div className="NoDataSection">
            <img
                src={require('../../../../assets/img/empty_state.gif')}
                alt="Ichtys"
                style={{width:'4em', height:'4em', marginBottom:'1em'}}
            ></img>
            <Label>{t('cycleList.noRegistries.message')}</Label>
        </div>
    );

    const Forms = <>
        <CycleFormComponent isOpen={isOpen}
            openPanel={openPanel}
            dismissPanel={handleCycleClosePanel} 
            ContractId={props.ContractId} 
            ContractAddendumId={props.ContractAddendumId}
            ContractStageCycleId={selectedCycle?.id} 
        />
    </>


    return (
        <>
            {ComponentActions}
            {ErrorMessage}
            {props.isLoading? Loading : null}
            {props.Cycles?.map((cycle, index) => (
                <CycleItemComponent 
                    Cycle={cycle} index={index} 
                    onEdit={(cycle: StageCycle) => HandleEditEvent(cycle)}
                    onDelete={(cycle: StageCycle) => HandleDeleteEvent(cycle)}
                    deleteProcess={props.isDeleting}
                    deleteSuccess={props.isDeletedSuccessfully}
                    deleteError={props.failOnDeleting}
                    Error={props.error}
                />
            ))}
            {NoRegistries}
            {Forms}
        </>
    )
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
  )(CycleListComponent as any);
