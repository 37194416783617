import { IIconProps, PrimaryButton } from '@fluentui/react';
import ProtocolsListComponent from '../../components/Protocols/ProtocolsList/ProtocolsList.Component';
import { useBoolean } from '@fluentui/react-hooks';
import { PageHeader } from '../../commons/component/PageHeader/PageHeader';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

export const ProtocolsAgreementsPage = () => {
  const AddIcon: IIconProps = { iconName: 'CirclePlus' };

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const handleClosePanel = () => {
    dismissPanel();
  };

  const HandleAddEvent = () => {
    openPanel();
  };

  //useTranslation()
  const [t] = useTranslation();

  return (
    <>
      <PageHeader title={t('protocolsAgreementsPage.protocolsAgreementsTitle')}/>
      <div className="Section">
        <ProtocolsListComponent />
      </div>
    </>
  );
};
