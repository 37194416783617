import { IResult } from '../models/HttpResult';

//Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { SettlementResume } from '../models/Entities/SettlementResume/SettlementResume';
import { NewSettlementResume } from '../models/Entities/SettlementResume/NewSettlementResume';


const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'settlements/settlements/';

// Get All Settlements
export const GetResumesBySettlementId = async (settlementId: number, token: string): Promise<SettlementResume[]> => {

let RequestURL: string = BaseURL + settlementId + '/resumes';

try {
    const Response: SettlementResume[] = await fetch(RequestURL, {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }
    })
    .then((response) => {
        if (!response.ok) {
        Sentry.captureException(response);
        throw response;
        } else {
        return response.json() as Promise<IResult<SettlementResume[]>>;
        }
    })
    .then((data: IResult<SettlementResume[]>) => {
        return data.data;
    })
    .catch((error: any) =>
        error.text().then((body: any) => {
        throw Functions.HttpErrorHandler(body, error);
        })
    );

    return Response;
} catch (error: any) {
    Sentry.captureException(error);
    throw error;
}
};

// Get contract By Id
export const GetSettlementResumeById = async (id: number, token: string): Promise<SettlementResume> => {

    let RequestURL: string = BaseURL + 'resumes/' + id;
    
    try {
        const Response: SettlementResume = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<SettlementResume>>;
            }
        })
        .then((data: IResult<SettlementResume>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Create Contract
export const CreateSettlementResume = async (newSettlementResume: NewSettlementResume, token: string): Promise<SettlementResume> => {

    let RequestURL: string = BaseURL + 'resumes';
    
    try {
        const Response: SettlementResume = await fetch(RequestURL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(newSettlementResume)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<SettlementResume>>;
            }
        })
        .then((data: IResult<SettlementResume>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
    };

// Update Contract
export const UpdateSettlementResume = async (settlementResume: SettlementResume, token: string): Promise<SettlementResume> => {

    let RequestURL: string = BaseURL + 'resumes/';
    
    try {
        const Response: SettlementResume = await fetch(RequestURL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(settlementResume)
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<SettlementResume>>;
            }
        })
        .then((data: IResult<SettlementResume>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
};

export const DeleteSettlementResume = async (settlementResumeId: number, token: string): Promise<number> => {

    let RequestURL: string = BaseURL + 'resumes/' + settlementResumeId;
    
    try {
        const Response: number = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
        })
        .then((response) => {
            if (!response.ok) {
            Sentry.captureException(response);
            throw response;
            } else {
            return response.json() as Promise<IResult<number>>;
            }
        })
        .then((data: IResult<number>) => {
            return data.data;
        })
        .catch((error: any) =>
            error.text().then((body: any) => {
            throw Functions.HttpErrorHandler(body, error);
            })
        );
    
        return Response;
    } catch (error: any) {
        Sentry.captureException(error);
        throw error;
    }
}
