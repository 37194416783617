import { connect } from 'react-redux';
import { FontSizes, Label, Separator } from '@fluentui/react';
import SettlementMoneyChartComponent from '../SettlementMoneyChart/SettlementMoneyChart.Component';
import { CharType } from '../SettlementMoneyChart/SettlementMoneyChart.Functions';

// functions
import * as ComponentFunctions from './SettlementTotalItem.Functions';


//i18Next
//import { useTranslation } from 'react-i18next';

const SettlementTotalItemComponent = (props: ComponentFunctions.Props) => {

    //useTranslation()
    //const [t] = useTranslation();

    return (
        <>
            {/* <Label>{t('settlementTotal.total')}</Label> */}
            <Label style={{ fontSize: FontSizes.size24 }}>{((props.TotalToSettle || 0) - (props.TotalWithholdings || 0))?.toLocaleString('es-AR', { style: 'currency', currency: props.CurrencyCode?.toUpperCase() }) || '0.00'}</Label>
            {(props.TotalToSettle && props.TotalToSettle > 0) ?
                <>
                    <small>Estadísticas</small>
                    <p>
                        <SettlementMoneyChartComponent SettlementId={props.SettlementId} CurrencyCode={props.CurrencyCode} ChartType={CharType.Donut} />
                    </p>
                </>
                : null}

        </>);

};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementTotalItemComponent as any);