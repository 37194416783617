import React from 'react';
import { Toaster } from '@fluentui/react-components';
import { NotificationComponent } from '../../components/Notification/NotificacionComponent';
import { WelcomeWidgetComponent } from '../../components/WelcomeWidget/WelcomeWidgetComponent';

// styles
import './PrincipalPage.css';

export const PrincipalPage = () => {

  return (
    <div>
      <WelcomeWidgetComponent />
      <div className="PrincipalPageContent">
        <div className="Section"></div>
        <div className="Section">
          <NotificationComponent />
        </div>
      </div>
      <Toaster toasterId={'PrebillerToast'} />
    </div>
  );
};
