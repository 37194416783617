import { PractitionerMedicalInsuranceUnloadState } from './../states/PractitionerMedicalInsuranceState';
import { Reducer, Action } from 'redux';
import { PractitionerMedicalInsuranceState } from '../states/PractitionerMedicalInsuranceState';
import { PractitionerMedicalInsuranceAssociation } from '../../models/Entities/PractitionerAssociations/MedicalInsurances/PractitionerMedicalInsuranceAssociation';

// Functions
import * as Functions from '../../functions/common';

// Actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_RequestAll_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_ReceiveAll_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_FailAll_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_RequestById_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_ReceiveById_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_FailById_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Add_Request_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Add_Receive_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Add_Fail_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Delete_Request_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_DeleteById_Receive_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_DeleteByMedicalInsuranceId_Receive_Action
  | Actions.PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsurances_Delete_Fail_Action;

export const PractitionerMedicalInsuranceAssociationReducer: Reducer<
  PractitionerMedicalInsuranceState
> = (
  state: PractitionerMedicalInsuranceState | undefined,
  incomingAction: Action
): PractitionerMedicalInsuranceState => {
  if (state === undefined) {
    return PractitionerMedicalInsuranceUnloadState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'PRACTITIONER_MEDICAL_INSURANCES_REQUEST_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_RECEIVE_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined,
        list: action.practitionerMedicalInsurances
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_FAIL_ALL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_REQUEST_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_RECEIVE_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (medicalInsurance: PractitionerMedicalInsuranceAssociation) =>
              medicalInsurance.id !== action.practitionerMedicalInsurance.id
          ),
          action.practitionerMedicalInsurance
        ]
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_FAIL_BY_ID_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [...state.list, action.practitionerMedicalInsurance]
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: state.list.filter(
          (medicalInsurance: PractitionerMedicalInsuranceAssociation) =>
            medicalInsurance.id !== action.practitionerMedicalInsuranceId
        )
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_BY_MEDICALINSURANCE_ID_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: state.list.filter(
          (medicalInsurance: PractitionerMedicalInsuranceAssociation) =>
            medicalInsurance.practitionerId !== action.practitionerId &&
            medicalInsurance.medicalInsuranceId !== action.MedicalInsuranceId
        )
      };
    case 'PRACTITIONER_MEDICAL_INSURANCES_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
    default:
      return state;
  }
};
