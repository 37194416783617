import { Action, Reducer } from 'redux';
import {
  AvailableMedicalServiceState,
  AvailableMedicalServicesUnloadState
} from '../states/MedicalServicesState';
import { AvailableMedicalService } from '../../models/Entities/MedicalServices/AvailableMedicalService';

// functions
import * as Functions from '../../functions/common';

// actions
import * as Actions from '../actions';

type KnownAction =
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetAll_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetAll_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetAll_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdateAll_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdateAll_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdateAll_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetById_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetById_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceGetById_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceAdd_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceAdd_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceAdd_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdate_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdate_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceUpdate_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceDelete_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceDelete_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceDelete_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceChangeStatus_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceChangeStatus_Receive_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceChangeStatus_Fail_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceChangeSettings_Request_Action
  | Actions.AvailableMedicalServices.AvailableMedicalServiceChangeSettings_Fail_Action;

export const MedicalServicesReducer: Reducer<AvailableMedicalServiceState> = (
  state: AvailableMedicalServiceState | undefined,
  incomingAction: Action
): AvailableMedicalServiceState => {
  if (state === undefined) {
    return AvailableMedicalServicesUnloadState as AvailableMedicalServiceState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'AVAILABLE_MEDICAL_SERVICE_GET_ALL_REQUEST_ACTION':
      return {
        ...state,
        isLoadingAll: true,
        successLoadingAll: undefined,
        failOnLoadingAll: false,
        error: undefined
      };
    case 'AVAILABLE_MEDICAL_SERVICE_GET_ALL_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: true,
        failOnLoadingAll: false,
        error: undefined,
        list: [...action.Services].sort(
          Functions.DynamicSort('serviceNameReference')
        )
      };
    case 'AVAILABLE_MEDICAL_SERVICE_GET_ALL_FAIL_ACTION':
      return {
        ...state,
        isLoadingAll: false,
        successLoadingAll: false,
        failOnLoadingAll: true,
        error: action.error
      };
    case 'AVAILABLE_MEDICAL_SERVICE_UPDATE_ALL_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingAll: true,
        isUpdatingAllSuccess: undefined,
        FailUpdatingAll: false,
        error: undefined
      };
    case 'AVAILABLE_MEDICAL_SERVICE_UPDATE_ALL_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingAll: false,
        isUpdatingAllSuccess: true,
        FailUpdatingAll: false,
        error: undefined,
        list: [...action.Services].sort(
          Functions.DynamicSort('serviceNameReference')
        )
      };
    case 'AVAILABLE_MEDICAL_SERVICE_UPDATE_ALL_FAIL_ACTION':
      return {
        ...state,
        isUpdatingAll: false,
        isUpdatingAllSuccess: false,
        FailUpdatingAll: true,
        error: action.error
      };
    case 'AVAILABLE_MEDICAL_SERVICE_DELETE_REQUEST_ACTION':
      return {
        ...state,
        isDeletingOne: true,
        successDeletingOne: undefined,
        failOnDeletingOne: false,
        error: undefined
      };
    case 'AVAILABLE_MEDICAL_SERVICE_DELETE_RECEIVE_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: true,
        failOnDeletingOne: false,
        error: undefined,
        list: state.list.filter(
          (item: AvailableMedicalService) =>
            item.id !== action.AvailableMedicalServiceId
        )
      };
    case 'AVAILABLE_MEDICAL_SERVICE_DELETE_FAIL_ACTION':
      return {
        ...state,
        isDeletingOne: false,
        successDeletingOne: false,
        failOnDeletingOne: true,
        error: action.error
      };
    case 'AVAILABLE_MEDICAL_SERVICE_GET_BY_ID_REQUEST_ACTION':
      return {
        ...state,
        isLoadingOne: true,
        successLoadingOne: undefined,
        failOnLoadingOne: false,
        error: undefined
      };
    case 'AVAILABLE_MEDICAL_SERVICE_GET_BY_ID_RECEIVE_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: true,
        failOnLoadingOne: false,
        error: undefined,
        list: [
          ...(state.list.filter(
            (item: AvailableMedicalService) =>
              item.id !== action.AvailableMedicalService.id
          ) || []),
          action.AvailableMedicalService
        ]
      };
    case 'AVAILABLE_MEDICAL_SERVICE_GET_BY_ID_FAIL_ACTION':
      return {
        ...state,
        isLoadingOne: false,
        successLoadingOne: false,
        failOnLoadingOne: true,
        error: action.error
      };
    case 'AVAILABLE_MEDICAL_SERVICE_ADD_REQUEST_ACTION':
      return {
        ...state,
        isAddingNewOne: true,
        successAddingNewOne: undefined,
        failOnAddingNewOne: false,
        error: undefined
      };
    case 'AVAILABLE_MEDICAL_SERVICE_ADD_RECEIVE_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: true,
        failOnAddingNewOne: false,
        error: undefined,
        list: [...state.list, action.AvailableMedicalService].sort(
          Functions.DynamicSort('serviceNameReference')
        )
      };
    case 'AVAILABLE_MEDICAL_SERVICE_ADD_FAIL_ACTION':
      return {
        ...state,
        isAddingNewOne: false,
        successAddingNewOne: false,
        failOnAddingNewOne: true,
        error: action.error
      };
    case 'AVAILABLE_MEDICAL_SERVICE_UPDATE_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'AVAILABLE_MEDICAL_SERVICE_UPDATE_RECEIVE_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: true,
        failOnUpdatingOne: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (item: AvailableMedicalService) =>
              item.id !== action.AvailableMedicalService.id
          ),
          action.AvailableMedicalService
        ].sort(Functions.DynamicSort('serviceNameReference'))
      };
    case 'AVAILABLE_MEDICAL_SERVICE_UPDATE_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };
    case 'AVAILABLE_MEDICAL_SERVICE_CHANGE_STATUS_REQUEST_ACTION':
      return {
        ...state,
        isChangingStatus: true,
        successChangingStatus: undefined,
        failOnChangingStatus: false,
        error: undefined
      };
    case 'AVAILABLE_MEDICAL_SERVICE_CHANGE_STATUS_RECEIVE_ACTION':
      return {
        ...state,
        isChangingStatus: false,
        successChangingStatus: true,
        failOnChangingStatus: false,
        error: undefined,
        list: [
          ...state.list.filter(
            (item: AvailableMedicalService) =>
              item.id !== action.medicalServiceId
          ),
          {
            ...state.list.find(
              (item: AvailableMedicalService) =>
                item.id === action.medicalServiceId
            ),
            available: action.status
          } as AvailableMedicalService
        ].sort(Functions.DynamicSort('serviceNameReference'))
      };
    case 'AVAILABLE_MEDICAL_SERVICE_CHANGE_STATUS_FAIL_ACTION':
      return {
        ...state,
        isChangingStatus: false,
        successChangingStatus: false,
        failOnChangingStatus: true,
        error: action.error
      };
    case 'AVAILABLE_MEDICAL_SERVICE_CHANGE_SETTINGS_REQUEST_ACTION':
      return {
        ...state,
        isUpdatingOne: true,
        successUpdatingOne: undefined,
        failOnUpdatingOne: false,
        error: undefined
      };
    case 'AVAILABLE_MEDICAL_SERVICE_CHANGE_SETTINGS_FAIL_ACTION':
      return {
        ...state,
        isUpdatingOne: false,
        successUpdatingOne: false,
        failOnUpdatingOne: true,
        error: action.error
      };
  }

  return state;
};
