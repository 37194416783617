import { AgreementService } from '../../models/Entities/AgreementServices/AgreementServices';
import { CommonState } from '../common/state/commonState';

export interface AgreementServicesState extends CommonState<AgreementService> {
  isChangingStatus: boolean;
  changeStatusSuccess: boolean | undefined;
  failOnChangingStatus: boolean;
}

export const AgreementServicesUnloadedState: any = {
  // loading all
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  FailGettingAll: false,

  // loading one
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  FailGettingOne: false,

  // saving
  isSaving: false,
  isSavingSuccess: undefined,
  FailSaving: false,

  // adding
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,

  // deleting
  isDeletingOne: false,
  successDeletingOne: undefined,
  failOnDeletingOne: false,

  isFiltered: false,
  filterCriteria: undefined,

  list: [],
  Error: undefined,

  // changing status
  isChangingStatus: false,
  changeStatusSuccess: undefined,
  failOnChangingStatus: false
};
