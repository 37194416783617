import { AppState } from '../../../redux/reducers';
import { connect } from 'react-redux';
import { FontIcon, FontSizes, Label, MessageBarType, SearchBox, Spinner } from '@fluentui/react';

// Store
import * as PractitionerStore from '../../../redux/middleware/PractitionerMiddleware';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import { ErrorType } from '../../../models/HttpError';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, IIconProps } from '@fluentui/react';
import { MessageComponent } from '../../../commons/component/MessageComponent';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

interface IDispatchProps {
  GetAllPractitioners: typeof PractitionerStore.actionCreators.GetAllPractitioners;
}

interface IConnectedProps {
  practitioners: Practitioner[];
  isLoading: boolean;
  isLoadingSuccess: boolean;
  failOnLoading: boolean;
  error: ErrorType;
}

type IProps = IDispatchProps & IConnectedProps;

export const PractitionerListComponent = ({
  practitioners,
  isLoading,
  isLoadingSuccess,
  failOnLoading,
  error,
  GetAllPractitioners
}: IProps) => {
  const AgreementsIcon: IIconProps = { iconName: 'StackIndicator' };

  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();

  //useTranslate();
  const [t] = useTranslation();

  //events
  const onViewPratitionerEvent = (practitioner: Practitioner) => {
    navigate('/practitioners/' + practitioner.userId);
  };

  const onSearch = (newValue: string) => {
    setSearchText(newValue);
  };

  //effects
  useEffect(() => {
    GetAllPractitioners(1, 1000, '');
  }, []);

  return (
    <>
      <div className="SectionControls">
        <SearchBox
          placeholder={t('practitionerList.parameters.search')}
          //onChange={OnSearch}
          underlined={false}
          onChange={(
            event?: React.ChangeEvent<HTMLInputElement> | undefined,
            newValue?: string | undefined
          ) => (newValue ? onSearch(newValue) : undefined)}
          onClear={() => onSearch('')}
        />
      </div>
      {failOnLoading && (
        <MessageComponent
          message={
            t('practitionerList.parameters.errorMessage')
          }
          subMessage={error.ErrorMessage}
          type={MessageBarType.error}
          itemList={error.Errors}
        />
      )}
      {searchText && (
        <MessageComponent
          message={
             'Se estan visualizando los médicos que coinciden con la búsqueda: (' +
            searchText +
            ')'
          }
          type={MessageBarType.info}
        />
      )}
      {isLoadingSuccess && (
        <table className="Table">
          <thead>
            <tr>
              <th></th>
              <th>{t('practitionerList.loadingSucces.doctor')} </th>
              <th>{t('practitionerList.loadingSucces.registration')}</th>
              <th>{t('practitionerList.loadingSucces.especialty')}</th>
              <th>{t('practitionerList.loadingSucces.options')}</th>
            </tr>
          </thead>
          <tbody>
            {practitioners.map((item: Practitioner) =>
              item.fullName.toLowerCase().includes(searchText.toLowerCase()) ? (
                <tr
                  key={item.userId}
                  className="TableBodyRow"
                  onClick={() => onViewPratitionerEvent(item)}
                >
                  <td><FontIcon iconName='UserOptional' style={{ fontSize: FontSizes.size24, padding: 10 }} /></td>
                  <td className="primary">
                    <Label>{item.fullName.toUpperCase()}</Label>
                    {item.active ? (
                      <small>{t('practitionerList.loadingSucces.enable')}</small>
                    ) : (
                      <small>{t('practitionerList.loadingSucces.disabled')}</small>
                    )}
                  </td>
                  <td>{item.registrationNumber}</td>
                  <td>{item.speciality}</td>
                  <td>
                    <IconButton
                      iconProps={AgreementsIcon}
                      title={t('practitionerList.loadingSucces.information')}
                      ariaLabel="Información"
                      onClick={() => onViewPratitionerEvent(item)}
                    />
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      )}
      {isLoading && (
        <div>
          <Spinner
            label={t('practitionerList.loadingSucces.medicalProfessionals')}
            ariaLive="assertive"
            labelPosition="top"
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    practitioners: state.Practitioner?.list,
    isLoading: state.Practitioner?.isLoadingAll,
    isLoadingSuccess: state.Practitioner?.successLoadingAll,
    failOnLoading: state.Practitioner?.failOnLoadingAll,
    error: state.Practitioner?.error
  };
};

const mapDispatchToProps = {
  ...PractitionerStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PractitionerListComponent as any);
