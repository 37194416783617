import { NewPractitionerMedicalInsuranceAssociation } from './../models/Entities/PractitionerAssociations/MedicalInsurances/NewPractitionerMedicalInsuranceAssociation';
import { IResult } from './../models/HttpResult';
import { IPagedResult } from '../models/HttpPagedResult';
import { Practitioner } from '../models/Entities/Practitioners/Practitioner';
import { PractitionerMedicalInsuranceAssociation } from '../models/Entities/PractitionerAssociations/MedicalInsurances/PractitionerMedicalInsuranceAssociation';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { ContractPractitionerAssociation } from '../models/Entities/Contracts/ContractPractitionerAssociation';

const BaseURL = process.env.REACT_APP_BILLER_API_ENDPOINT_PRACTITIONERS;
const ContractBaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'practitioners/contracts/';

export const GetPractitioners = async (
  pageNumber: number,
  pageSize: number,
  searchText: string | undefined
, token: string): Promise<Practitioner[]> => {
  let params: URLSearchParams = Functions.GetQueryStringParams(
    pageNumber,
    pageSize,
    searchText
  );

  let RequestURL: string = BaseURL || '';

  if (params)
    RequestURL +=
      '?' + params.toString() + '&onlyDoctor=true&includeDisabled=true'; //--> Recupero solo los medicos sin importar si esta habilitado o no

  try {
    const Response: any[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IPagedResult<Practitioner[]>>;
        }
      })
      .then((data: IPagedResult<Practitioner[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetPractitionerMedicalInsurancesAssociated = async (
  practitionerId: string
, token: string): Promise<PractitionerMedicalInsuranceAssociation[]> => {

  let RequestURL: string = BaseURL || '';

  RequestURL +=
    'practitioners/associations/' + practitionerId + '/medicalinsurances';

  try {
    const Response: PractitionerMedicalInsuranceAssociation[] = await fetch(
      RequestURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerMedicalInsuranceAssociation[]>
          >;
        }
      })
      .then((data: IResult<PractitionerMedicalInsuranceAssociation[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const AddPractitionerMedicalInsuranceAssociation = async (
  MedicalInsuranceAssociation: NewPractitionerMedicalInsuranceAssociation
, token: string): Promise<PractitionerMedicalInsuranceAssociation> => {

  let RequestURL: string = BaseURL || '';

  RequestURL += 'practitioners/associations/medicalinsurances/';

  try {
    const Response: PractitionerMedicalInsuranceAssociation = await fetch(
      RequestURL,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(MedicalInsuranceAssociation)
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<
            IResult<PractitionerMedicalInsuranceAssociation>
          >;
        }
      })
      .then((data: IResult<PractitionerMedicalInsuranceAssociation>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DeletePractitionerMedicalInsuranceAssociation = async (
  MedicalInsuranceAssociationId: number
, token: string): Promise<boolean> => {

  let RequestURL: string = BaseURL || '';

  RequestURL +=
    'practitioners/associations/medicalinsurances/' +
    MedicalInsuranceAssociationId;

  try {
    const Response: boolean = await fetch(RequestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<boolean>>;
        }
      })
      .then((data: IResult<boolean>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

// get practitioner contracts
export const GetPractitionerContracts = async (practitionerId: string, token: string): Promise<ContractPractitionerAssociation> => {

  let RequestURL: string = ContractBaseURL || '';

  RequestURL += practitionerId + '/contracts';
  alert('llama aca');
  try {
    const Response: ContractPractitionerAssociation = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ContractPractitionerAssociation>>;
        }
      })
      .then((data: IResult<ContractPractitionerAssociation>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};
