import { connect } from 'react-redux';
import { Separator, Text } from '@fluentui/react';


// Styles
import './SettlementSummaryStatistic.css';

// Functions
import * as ComponentFunctions from './SettlementSummaryStatistic.Functions';

const SettlementSummaryStatistic = (props: ComponentFunctions.Props) => {

    return (
        <div className='SummaryStatisticComponent'>
            <div className='badget'>
                <Text><strong>{props.DetailsCount}</strong></Text>
            </div>
            <Separator vertical />
            <div className='badget red'>
                <Text>{props.DetailsExcludedCount}</Text>
            </div>
            <div className='badget gray'>
                <Text>{props.DetailsPendingCount}</Text>
            </div>
            <div className='badget green'>
                <Text>{props.DetailsVerifiedCount}</Text>
            </div>
            <div className='badget yellow'>
                <Text>{props.DetailsToVerificateCount}</Text>
            </div>
        </div>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementSummaryStatistic as any);